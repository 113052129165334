import { useGlobals } from "../../contexts/globalContexts";

import HeaderBird from "../../img/green_header_bird.svg";
import HeaderReceipt from "../../img/reciept.svg";
import HeaderReceiptArabic from "../../img/reciept-top-arabic.svg";
import HeaderPrint from "../../img/print.svg";
import HeaderPrintArabic from "../../img/print-top-arabic.svg";
import HeaderDownload from "../../img/download.svg";
import HeaderDownloadArabic from "../../img/download-top-arabic.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";
import { useTranslation } from "react-i18next";
import { locale } from "moment";

function BookingConfirmation({ passengerInfo, generatePDF, printDocument }) {
  const { BookingRef } = useParams();

  const {
    hepstarPolicyNumber,
    createBookingApiData,
    bookingDetails,
    bookingId,
    bookingRef,
    lang,
    locale,
    setLocale,
  } = useGlobals();

  const print = () => {
    window.print();
  };
  let LocaleValue;

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", "background-image:none");
    document
      .getElementsByTagName("main")[0]
      .setAttribute("className", "page search_results");
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    setLocale(localStorage.getItem("locale"));
  }, []);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        navigate(`/en/${localePath[2]}/${localePath[3]}`);
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");
        setLocale("ar");
        navigate(`/ar/${localePath[2]}/${localePath[3]}`);
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, []);


  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div>
      <div className="container-fluid bg-green dps-none">
        <div className="container">
          <div className="row ">
            <div className="col-5 ">
              <p className="font-24 font-semibold text-white">
                {t("Your Flight Booking Is Confirmed")}
              </p>

              <div className="row mt-3">
                <div className="col-6">
                  <p className="font-12 text-white">
                    {t("Booking Reference No")}. {BookingRef}
                  </p>
                </div>

                <div
                  className="col-6"
                  style={
                    locale === "ar"
                      ? { marginTop: "-20px" }
                      : { marginTop: "0px" }
                  }
                >
                  <p className="font-12 text-white">
                    <span className="underlined-text print_none">
                      {t("Send Email")}
                    </span>
                    <br />
                    <span>
                      PNR : {passengerInfo ? passengerInfo.GdsPnr : null}{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-2 text-center">
              <img src={HeaderBird} className="header-bird" alt="header-bird" />
            </div>

            <div className="col-5 text-center print_none">
              <img
                src={locale === "en" ? HeaderReceipt : HeaderReceiptArabic}
                className={
                  locale === "ar"
                    ? "green-section-images  cursor-pointer"
                    : "green-section-images mr-2 cursor-pointer"
                }
                onClick={printDocument}
                alt="print-doc"
              />
              <img
                src={locale === "en" ? HeaderPrint : HeaderPrintArabic}
                className={
                  locale === "ar"
                    ? "green-section-images cursor-pointer"
                    : "green-section-images mr-2 cursor-pointer"
                }
                onClick={printDocument}
                alt="receipt"
              />
              <img
                src={locale === "en" ? HeaderDownload : HeaderDownloadArabic}
                className="green-section-images cursor-pointer"
                onClick={generatePDF}
                alt="pdf"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmation;
