import { useGlobals } from "../../contexts/globalContexts";

import TravelBag from "../../img/travel-bag-1.svg";
import TravelBagDark from "../../img/d/baggage.svg";
import GreenRightArrow from "../../img/green-right-arrow.svg";
import AirlineLogo from "../../img/airline-logo.svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function BaggageInformation({ passengerInfo }) {
  const { bookingDetails, lang, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <div className="row mt-5">
        <div className="col-12 text-center">
          <img src={TravelBag} className="light" alt="travel-bag-light" />
          <img src={TravelBagDark} className="dark" alt="travel-bag-dark" />
          {/* <p className="font-12 dls-none opacity-5" dir="ltr">
            30 Kg — 30 Kg
          </p> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center mt-4">
          <p className="font-18">{t("Baggage Information")} </p>
        </div>
      </div>

      <div className="row bg-grey mt-4">
        <div className="col-lg-5 col-sm-4 col-5 padding-x-5 col-padding">
          <p className="font-16 mob-font-13 dps-none">
            <span className="font-bold font-18 mob-font-13">
              {passengerInfo?.Leg[0].Segements[0].departureAirport.cityName}{" "}
              {passengerInfo?.Leg[0].Segements[0].departureAirport.cityCode}
            </span>{" "}
            —{" "}
            <span className="font-bold font-18 mob-font-13">
              {bookingDetails.TripType === "RoundTrip" &&
                passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                  .cityName}{" "}
              {bookingDetails.TripType === "OneWay" &&
                passengerInfo?.Leg[0]?.Segements[
                  passengerInfo?.Leg[0].Segements.length - 1
                ].arrivalAirport.cityName}{" "}
              {bookingDetails.TripType === "RoundTrip" &&
                passengerInfo?.Leg[1]?.Segements[0]?.departureAirport.cityCode}
              {bookingDetails.TripType === "OneWay" &&
                passengerInfo?.Leg[0]?.Segements[
                  passengerInfo?.Leg[0].Segements.length - 1
                ].arrivalAirport.cityCode}
            </span>
          </p>

          <div className="row dls-none print_none">
            <div className="col-sm-6 col-5">
              <p className="font-13"></p>
              <p className="font-13 font-bold">
                {passengerInfo?.Leg[0].Segements[0].departureAirport.cityName}{" "}
                {passengerInfo?.Leg[0].Segements[0].departureAirport.cityCode}
              </p>
            </div>

            <div className="col-sm-1 col-1">
              <img src={GreenRightArrow} alt="green-arrow-right" />
            </div>

            <div className="col-sm-6 col-4">
              <p className="font-13 font-bold ">
                {bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityName}{" "}
                {bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                    .cityCode}
                {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityCode}
              </p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4 col-sm-12 mob-text-center">
              <img
                src={bookingDetails.GoingAirlineLogo}
                style={{ height: "60px", borderRadius: "10px" }}
                alt="going-airline"
              />
            </div>

            <div className="col-lg-8 mt-2 col-sm-12">
              <p className="font-bold font-13 mob-font-12">
                {t("Baggage Allowance")}
              </p>
              {passengerInfo && passengerInfo.passengers[0].baggage ? (
                // <p className="font-13 mt-2">
                //   {passengerInfo.passengers[0].baggage !== null
                //     ? `${passengerInfo.passengers[0].baggage[0].pieceCount} ${t(
                //         "Pieces"
                //       )}`
                //     : ""}{" "}
                //   <br />
                //   {passengerInfo.passengers[0].baggage &&
                //   passengerInfo.passengers[0].baggage[0].weight > 0
                //     ? `${passengerInfo.passengers[0].baggage[0].weight} KG`
                //     : null}
                // </p>
                <p className="font-12 mt-2">
                  {passengerInfo && passengerInfo?.passengers[0]?.baggage[0]?.provisionType === "A" 
                  &&
                  passengerInfo?.passengers[0]?.baggage[0]?.pieceCount !== 0 || passengerInfo?.passengers[0]?.baggage[0]?.pieceCount !== null
                  ?
                  passengerInfo?.passengers[0]?.baggage[0]?.pieceCount
                  :
                  null
                  } {' '}
                  {passengerInfo && passengerInfo?.passengers[0]?.baggage[0]?.provisionType === "A" 
                  &&
                  passengerInfo?.passengers[0]?.baggage[0]?.pieceCount !== 0 || passengerInfo?.passengers[0]?.baggage[0]?.pieceCount !== null
                  ?
                  passengerInfo?.passengers[0]?.baggage[0]?.pieceCount > 1 ? t("Pieces") + " Checkin Baggage" : t('Piece') + " Checkin Baggage"
                  :
                  null
                  }
                 

                </p>
              ) : null}
            </div>
          </div>
        </div>

        {bookingDetails.TripType === "RoundTrip" && (
          <div className="col-lg-1 col-sm-1 col-1 border-right-dashed text-center"></div>
        )}

        {bookingDetails.TripType === "RoundTrip" && (
          <div className="col-lg-5 col-sm-4 col-5 padding-x-5 col-padding">
            <p className="font-16 mob-font-13 dps-none">
              <span className="font-bold font-18 mob-font-13">
                {/* {bookingDetails.ReturnDepartureAirport} */}
                {bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityName}{" "}
                {bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                    .cityCode}
                {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityCode}
              </span>{" "}
              —
              <span className="font-bold font-18 mob-font-13">
                {passengerInfo?.Leg[0].Segements[0].departureAirport.cityName}{" "}
                {passengerInfo?.Leg[0].Segements[0].departureAirport.cityCode}
              </span>
            </p>

            <div className="row print_none dls-none">
              <div className="col-sm-5 col-5">
                <p className="font-13 font-bold">
                  {bookingDetails.TripType === "RoundTrip" &&
                    passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                      .cityName}{" "}
                  {bookingDetails.TripType === "OneWay" &&
                    passengerInfo?.Leg[0]?.Segements[
                      passengerInfo?.Leg[0].Segements.length - 1
                    ].arrivalAirport.cityName}{" "}
                  {bookingDetails.TripType === "RoundTrip" &&
                    passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                      .cityCode}
                  {bookingDetails.TripType === "OneWay" &&
                    passengerInfo?.Leg[0]?.Segements[
                      passengerInfo?.Leg[0].Segements.length - 1
                    ].arrivalAirport.cityCode}
                </p>
              </div>

              <div className="col-sm-1 col-2">
                <img src={GreenRightArrow} alt="mob-green-right-arrow" />
              </div>

              <div className="col-sm-5 col-4">
                <p className="font-13"></p>
                <p className="font-13 font-bold ">
                  {passengerInfo?.Leg[0].Segements[0].departureAirport.cityName}{" "}
                  {passengerInfo?.Leg[0].Segements[0].departureAirport.cityCode}
                </p>
              </div>
            </div>

            <div className="row mt-4 pb-5">
              <div className="col-lg-4 col-sm-12 mob-text-center">
                <img
                  src={bookingDetails.ReturnAirlineLogo}
                  style={{ height: "60px", borderRadius: "10px" }}
                  alt="return-airline-logo"
                />
              </div>

              <div className="col-lg-8 mt-2 col-sm-12">
              <p className="font-bold font-13 mob-font-12">
                {t("Baggage Allowance")}
              </p>
              <p className="font-12 mt-2">
                  {passengerInfo && passengerInfo?.passengers[0]?.baggage[1]?.provisionType === "A" 
                  &&
                  passengerInfo?.passengers[0]?.baggage[1]?.pieceCount !== 0 || passengerInfo?.passengers[0]?.baggage[1]?.pieceCount !== null
                  ?
                  passengerInfo?.passengers[0]?.baggage[1]?.pieceCount
                  :
                  null
                  } {' '}
                  {passengerInfo && passengerInfo?.passengers[0]?.baggage[1]?.provisionType === "A" 
                  &&
                  passengerInfo?.passengers[0]?.baggage[1]?.pieceCount !== 0 || passengerInfo?.passengers[0]?.baggage[1]?.pieceCount !== null
                  ?
                  passengerInfo?.passengers[0]?.baggage[1]?.pieceCount > 1 ? t("Pieces") + " Checkin Baggage" : t('Piece') + " Checkin Baggage"
                  :
                  null
                  }
                  <br />

                 {passengerInfo && passengerInfo?.passengers[0]?.baggage[2]?.provisionType === "B" 
                  &&
                  passengerInfo?.passengers[0]?.baggage[2]?.pieceCount > 0 
                  ?
                  passengerInfo?.passengers[0]?.baggage[2]?.pieceCount
                  :
                  passengerInfo?.passengers[0].baggage[2]?.weight > 0 ?
                  passengerInfo?.passengers[0].baggage[2]?.weight + ' ' + passengerInfo?.passengers[0].baggage[2]?.unit + " Hand Carry Baggage" : null
                  } {' '}
                  {passengerInfo && passengerInfo?.passengers[0]?.baggage[2]?.provisionType === "B" 
                  &&
                  passengerInfo?.passengers[0]?.baggage[2]?.pieceCount > 0 
                  ?
                  passengerInfo?.passengers[0]?.baggage[2]?.pieceCount > 1 ? t("Pieces") + " Hand Carry Baggage" : t('Piece') + " Hand Carry Baggage"
                  :
                  null
                  }
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BaggageInformation;
