import { useEffect, useState } from "react";
import "../../css/my_bookings.css";
import "../../css/refrence_prompt.css";
import Countries from "../../Countries.json";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import "../../css/dialog_modal.css";
import { isMobile } from "react-device-detect";
import { useGlobals } from "../../contexts/globalContexts";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";

function GetBookingRef() {
  const { siteURLS, lang, locale, setLocale } = useGlobals();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  const [bookingRef, setBookingRef] = useState("ELFDOHYC638082432776027915");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const [codeUpdatedValue, setCodeUpdatedValue] = useState("+966");

  const [modalMsg, setModalMsg] = useState(false);
  const viewBooking = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/GetMyBookings`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: bookingRef,
        CountryCode: "",
        ContactNumber: "",
        Email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.httpStatusCode === 200) {
          navigate(`/${locale}/booking_details/${bookingRef}`);
        } else {
          setIsOpen(true);
          setModalMsg(true);
        }
      });
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    height: "200px",
    width: "200px",
    margin: "0 auto",
    border: "0px",
    zIndex: 9999,
  };

  const checkSelectedVal = (code) => {
    if (code === "AF") {
      setCodeUpdatedValue("+93");
    } else if (code === "AX") {
      setCodeUpdatedValue("+358");
    } else if (code === "AL") {
      setCodeUpdatedValue("+355");
    } else if (code === "DZ") {
      setCodeUpdatedValue("+213");
    } else if (code === "AS") {
      setCodeUpdatedValue("+1684");
    } else if (code === "AD") {
      setCodeUpdatedValue("+376");
    } else if (code === "AO") {
      setCodeUpdatedValue("+244");
    } else if (code === "AI") {
      setCodeUpdatedValue("+1264");
    } else if (code === "AQ") {
      setCodeUpdatedValue("+672");
    } else if (code === "AG") {
      setCodeUpdatedValue("+1268");
    } else if (code === "AR") {
      setCodeUpdatedValue("+54");
    } else if (code === "AW") {
      setCodeUpdatedValue("+297");
    } else if (code === "AU") {
      setCodeUpdatedValue("+61");
    } else if (code === "AT") {
      setCodeUpdatedValue("+43");
    } else if (code === "AZ") {
      setCodeUpdatedValue("+994");
    } else if (code === "BS") {
      setCodeUpdatedValue("+1242");
    } else if (code === "BH") {
      setCodeUpdatedValue("+973");
    } else if (code === "BD") {
      setCodeUpdatedValue("+880");
    } else if (code === "BB") {
      setCodeUpdatedValue("+1246");
    } else if (code === "BY") {
      setCodeUpdatedValue("+375");
    } else if (code === "BE") {
      setCodeUpdatedValue("+32");
    } else if (code === "BZ") {
      setCodeUpdatedValue("+501");
    } else if (code === "KH") {
      setCodeUpdatedValue("+855");
    } else if (code === "CM") {
      setCodeUpdatedValue("+237");
    } else if (code === "CA") {
      setCodeUpdatedValue("+1");
    } else if (code === "CV") {
      setCodeUpdatedValue("+238");
    } else if (code === "KY") {
      setCodeUpdatedValue("+345");
    } else if (code === "CF") {
      setCodeUpdatedValue("+236");
    } else if (code === "CL") {
      setCodeUpdatedValue("+56");
    } else if (code === "CN") {
      setCodeUpdatedValue("+86");
    } else if (code === "CX") {
      setCodeUpdatedValue("+61");
    } else if (code === "CO") {
      setCodeUpdatedValue("+57");
    } else if (code === "KM") {
      setCodeUpdatedValue("+269");
    } else if (code === "CG") {
      setCodeUpdatedValue("+242");
    } else if (code === "CK") {
      setCodeUpdatedValue("+682");
    } else if (code === "CR") {
      setCodeUpdatedValue("+506");
    } else if (code === "HR") {
      setCodeUpdatedValue("+385");
    } else if (code === "CU") {
      setCodeUpdatedValue("+53");
    } else if (code === "CY") {
      setCodeUpdatedValue("+357");
    } else if (code === "DK") {
      setCodeUpdatedValue("+45");
    } else if (code === "DM") {
      setCodeUpdatedValue("+1767");
    } else if (code === "EC") {
      setCodeUpdatedValue("+593");
    } else if (code === "EG") {
      setCodeUpdatedValue("+20");
    } else if (code === "ER") {
      setCodeUpdatedValue("+291");
    } else if (code === "EE") {
      setCodeUpdatedValue("+372");
    } else if (code === "ET") {
      setCodeUpdatedValue("+251");
    } else if (code === "FO") {
      setCodeUpdatedValue("+298");
    } else if (code === "FJ") {
      setCodeUpdatedValue("+679");
    } else if (code === "FR") {
      setCodeUpdatedValue("+33");
    } else if (code === "PF") {
      setCodeUpdatedValue("+689");
    } else if (code === "GA") {
      setCodeUpdatedValue("+241");
    } else if (code === "GM") {
      setCodeUpdatedValue("+220");
    } else if (code === "GE") {
      setCodeUpdatedValue("+995");
    } else if (code === "DE") {
      setCodeUpdatedValue("+49");
    } else if (code === "GH") {
      setCodeUpdatedValue("+233");
    } else if (code === "GI") {
      setCodeUpdatedValue("+350");
    } else if (code === "GR") {
      setCodeUpdatedValue("+30");
    } else if (code === "GL") {
      setCodeUpdatedValue("+299");
    } else if (code === "GD") {
      setCodeUpdatedValue("+1473");
    } else if (code === "GY") {
      setCodeUpdatedValue("+595");
    } else if (code === "HT") {
      setCodeUpdatedValue("+509");
    } else if (code === "HN") {
      setCodeUpdatedValue("+504");
    } else if (code === "HK") {
      setCodeUpdatedValue("+852");
    } else if (code === "HU") {
      setCodeUpdatedValue("+36");
    } else if (code === "IS") {
      setCodeUpdatedValue("+354");
    } else if (code === "IN") {
      setCodeUpdatedValue("+91");
    } else if (code === "ID") {
      setCodeUpdatedValue("+62");
    } else if (code === "IR") {
      setCodeUpdatedValue("+98");
    } else if (code === "IQ") {
      setCodeUpdatedValue("+964");
    } else if (code === "IE") {
      setCodeUpdatedValue("+353");
    } else if (code === "IL") {
      setCodeUpdatedValue("+972");
    } else if (code === "IT") {
      setCodeUpdatedValue("+39");
    } else if (code === "JM") {
      setCodeUpdatedValue("+1876");
    } else if (code === "JP") {
      setCodeUpdatedValue("+81");
    } else if (code === "JE") {
      setCodeUpdatedValue("+44");
    } else if (code === "JO") {
      setCodeUpdatedValue("+962");
    } else if (code === "KZ") {
      setCodeUpdatedValue("+77");
    } else if (code === "KE") {
      setCodeUpdatedValue("+254");
    } else if (code === "KP") {
      setCodeUpdatedValue("+850");
    } else if (code === "KQ") {
      setCodeUpdatedValue("+965");
    } else if (code === "KG") {
      setCodeUpdatedValue("+996");
    } else if (code === "LV") {
      setCodeUpdatedValue("+371");
    } else if (code === "LB") {
      setCodeUpdatedValue("+961");
    } else if (code === "LR") {
      setCodeUpdatedValue("+231");
    } else if (code === "LU") {
      setCodeUpdatedValue("+352");
    } else if (code === "MO") {
      setCodeUpdatedValue("+853");
    } else if (code === "MW") {
      setCodeUpdatedValue("+265");
    } else if (code === "MY") {
      setCodeUpdatedValue("+60");
    } else if (code === "MV") {
      setCodeUpdatedValue("+960");
    } else if (code === "ML") {
      setCodeUpdatedValue("+223");
    } else if (code === "MT") {
      setCodeUpdatedValue("+356");
    } else if (code === "MX") {
      setCodeUpdatedValue("+52");
    } else if (code === "MD") {
      setCodeUpdatedValue("+373");
    } else if (code === "MC") {
      setCodeUpdatedValue("+377");
    } else if (code === "MN") {
      setCodeUpdatedValue("+976");
    } else if (code === "ME") {
      setCodeUpdatedValue("+382");
    } else if (code === "MA") {
      setCodeUpdatedValue("+212");
    } else if (code === "MM") {
      setCodeUpdatedValue("+95");
    } else if (code === "NA") {
      setCodeUpdatedValue("+264");
    } else if (code === "NP") {
      setCodeUpdatedValue("+977");
    } else if (code === "NL") {
      setCodeUpdatedValue("+31");
    } else if (code === "NZ") {
      setCodeUpdatedValue("+64");
    } else if (code === "NE") {
      setCodeUpdatedValue("+227");
    } else if (code === "NG") {
      setCodeUpdatedValue("+234");
    } else if (code === "NO") {
      setCodeUpdatedValue("+47");
    } else if (code === "OM") {
      setCodeUpdatedValue("+968");
    } else if (code === "PK") {
      setCodeUpdatedValue("+92");
    } else if (code === "PW") {
      setCodeUpdatedValue("+680");
    } else if (code === "PS") {
      setCodeUpdatedValue("+970");
    } else if (code === "PA") {
      setCodeUpdatedValue("+507");
    } else if (code === "PY") {
      setCodeUpdatedValue("+595");
    } else if (code === "PH") {
      setCodeUpdatedValue("+63");
    } else if (code === "PL") {
      setCodeUpdatedValue("+48");
    } else if (code === "PT") {
      setCodeUpdatedValue("+351");
    } else if (code === "QA") {
      setCodeUpdatedValue("+974");
    } else if (code === "RO") {
      setCodeUpdatedValue("+40");
    } else if (code === "RU") {
      setCodeUpdatedValue("+7");
    } else if (code === "WS") {
      setCodeUpdatedValue("+685");
    } else if (code === "SA") {
      setCodeUpdatedValue("+966");
    } else if (code === "SN") {
      setCodeUpdatedValue("+221");
    } else if (code === "RS") {
      setCodeUpdatedValue("+381");
    } else if (code === "SG") {
      setCodeUpdatedValue("+65");
    } else if (code === "SO") {
      setCodeUpdatedValue("+252");
    } else if (code === "ZA") {
      setCodeUpdatedValue("+27");
    } else if (code === "ES") {
      setCodeUpdatedValue("+34");
    } else if (code === "LK") {
      setCodeUpdatedValue("+94");
    } else if (code === "SD") {
      setCodeUpdatedValue("+249");
    } else if (code === "SE") {
      setCodeUpdatedValue("+46");
    } else if (code === "CH") {
      setCodeUpdatedValue("+41");
    } else if (code === "SY") {
      setCodeUpdatedValue("+963");
    } else if (code === "TW") {
      setCodeUpdatedValue("+886");
    } else if (code === "TJ") {
      setCodeUpdatedValue("+992");
    } else if (code === "TH") {
      setCodeUpdatedValue("+66");
    } else if (code === "TN") {
      setCodeUpdatedValue("+216");
    } else if (code === "TR") {
      setCodeUpdatedValue("+90");
    } else if (code === "TM") {
      setCodeUpdatedValue("+993");
    } else if (code === "UG") {
      setCodeUpdatedValue("+256");
    } else if (code === "UA") {
      setCodeUpdatedValue("+380");
    } else if (code === "AE") {
      setCodeUpdatedValue("+971");
    } else if (code === "GB") {
      setCodeUpdatedValue("+44");
    } else if (code === "US") {
      setCodeUpdatedValue("+1");
    } else if (code === "UZ") {
      setCodeUpdatedValue("+998");
    } else if (code === "VN") {
      setCodeUpdatedValue("+84");
    } else if (code === "YE") {
      setCodeUpdatedValue("+967");
    } else if (code === "ZM") {
      setCodeUpdatedValue("+260");
    } else if (code === "ZW") {
      setCodeUpdatedValue("+263");
    } else {
      setCodeUpdatedValue("+966");
    }
  };

  const showSelectedLabel = true;
  const showSecondarySelectedLabel = true;
  const showOptionLabel = true;
  const showSecondaryOptionLabel = true;
  const searchable = true;

  const customLabels = {
    AF: { secondary: "+93", primary: "Afghanistan" },
    AX: { secondary: "+358", primary: "Aland Islands" },
    AL: { secondary: "+355", primary: "Albania" },
    DZ: { secondary: "+213", primary: "Algeria" },
    AS: { secondary: "+1684", primary: "AmericanSamoa" },
    AD: { secondary: "+376", primary: "Andorra" },
    AO: { secondary: "+244", primary: "Angola" },
    AI: { secondary: "+1264", primary: "Anguilla" },
    AQ: { secondary: "+672", primary: "Antarctica" },
    AG: { secondary: "+1268", primary: "Barbuda" },
    AR: { secondary: "+54", primary: "Argentina" },
    AM: { secondary: "+374", primary: "Armenia" },
    AW: { secondary: "+297", primary: "Aruba" },
    AU: { secondary: "+61", primary: "Australia" },
    AT: { secondary: "+43", primary: "Austria" },
    AZ: { secondary: "+994", primary: "Azerbaijan" },
    BS: { secondary: "+1242", primary: "Bahamas" },
    BH: { secondary: "+973", primary: "Bahrain" },
    BD: { secondary: "+880", primary: "Bangladesh" },
    BB: { secondary: "+1246", primary: "Barbados" },
    BY: { secondary: "+375", primary: "Belarus" },
    BE: { secondary: "+32", primary: "Belgium" },
    BZ: { secondary: "+229", primary: "Belize" },
    BM: { secondary: "+1441", primary: "Bermuda" },
    BT: { secondary: "+975", primary: "Bhtuan" },
    BA: { secondary: "+387", primary: "Bosnia" },
    BW: { secondary: "+267", primary: "Botswana" },
    BR: { secondary: "+55", primary: "Brazil" },
    BN: { secondary: "+673", primary: "Brunei" },
    BG: { secondary: "+359", primary: "Bulgaria" },
    BF: { secondary: "+226", primary: "Burkina" },
    BI: { secondary: "+257", primary: "Burundi" },
    KH: { secondary: "+855", primary: "Cambodia" },
    CM: { secondary: "+237", primary: "Cameroon" },
    CA: { secondary: "+1", primary: "Canada" },
    CV: { secondary: "+238", primary: "Cape verde" },
    KY: { secondary: "+345", primary: "Cayman Islands" },
    TD: { secondary: "+235", primary: "Chad" },
    CL: { secondary: "+56", primary: "Chile" },
    CN: { secondary: "+86", primary: "China" },
    CX: { secondary: "+61", primary: "Chirstmas Island" },
    CO: { secondary: "+57", primary: "Colombia" },
    KM: { secondary: "+269", primary: "Comoros" },
    CG: { secondary: "+242", primary: "Congo" },
    CK: { secondary: "+682", primary: "Cook Islands" },
    CR: { secondary: "+506", primary: "Costa Rica" },
    HR: { secondary: "+385", primary: "Croatia" },
    CU: { secondary: "+53", primary: "Cuba" },
    CY: { secondary: "+357", primary: "Cyprus" },
    DK: { secondary: "+45", primary: "Denmark" },
    DM: { secondary: "+1767", primary: "Dominica" },
    EC: { secondary: "+593", primary: "Ecuador" },
    EG: { secondary: "+20", primary: "Egypt" },
    ER: { secondary: "+291", primary: "Eritrea" },
    EE: { secondary: "+372", primary: "Estonia" },
    ET: { secondary: "+251", primary: "Ethopia" },
    FO: { secondary: "+298", primary: "Farore Islands" },
    FJ: { secondary: "+679", primary: "Fijji" },
    FI: { secondary: "+358", primary: "Finland" },
    FR: { secondary: "+33", primary: "France" },
    PF: { secondary: "+689", primary: "French Polynesia" },
    GA: { secondary: "+241", primary: "Gabon" },
    GM: { secondary: "+220", primary: "Gambia" },
    GE: { secondary: "+995", primary: "Gerogia" },
    DE: { secondary: "+49", primary: "Germany" },
    GH: { secondary: "+233", primary: "Ghana" },
    GI: { secondary: "+350", primary: "Gibraltar" },
    GR: { secondary: "+30", primary: "Greece" },
    GL: { secondary: "+299", primary: "GreenLand" },
    GD: { secondary: "+1473", primary: "Grenada" },
    GY: { secondary: "+595", primary: "Guyana" },
    HT: { secondary: "+509", primary: "Hiati" },
    HN: { secondary: "+504", primary: "Honduras" },
    HK: { secondary: "+852", primary: "Hong Kong" },
    HU: { secondary: "+36", primary: "Hungary" },
    IS: { secondary: "+354", primary: "Iceland" },
    IN: { secondary: "+91", primary: "India" },
    ID: { secondary: "+62", primary: "Indonesia" },
    IR: { secondary: "+98", primary: "Iran" },
    IQ: { secondary: "+964", primary: "Iraq" },
    IE: { secondary: "+353", primary: "Ireland" },
    IL: { secondary: "+972", primary: "Israel" },
    IT: { secondary: "+39", primary: "Italy" },
    JM: { secondary: "+1876", primary: "Jamaica" },
    JP: { secondary: "+81", primary: "Japan" },
    JE: { secondary: "+44", primary: "Jersey" },
    JO: { secondary: "+962", primary: "Jordan" },
    KZ: { secondary: "+77", primary: "Kazakhstan" },
    KE: { secondary: "+254", primary: "kenya" },
    KP: { secondary: "+850", primary: "Korea" },
    KW: { secondary: "+965", primary: "Kuwait" },
    KG: { secondary: "+996", primary: "Kyrgyzstan" },
    LV: { secondary: "+371", primary: "Latvia" },
    LB: { secondary: "+961", primary: "Lebanon" },
    LR: { secondary: "+231", primary: "Liberiya" },
    LU: { secondary: "+352", primary: "Luxembourg" },
    MO: { secondary: "+853", primary: "Macao" },
    MW: { secondary: "+265", primary: "Malawi" },
    MY: { secondary: "+60", primary: "Malaysia" },
    MV: { secondary: "+960", primary: "Maldives" },
    ML: { secondary: "+223", primary: "Mali" },
    MT: { secondary: "+356", primary: "Malta" },
    MD: { secondary: "+373", primary: "Moldava" },
    MX: { secondary: "+52", primary: "Mexico" },
    MC: { secondary: "+377", primary: "Monaco" },
    MN: { secondary: "+976", primary: "Mongolia" },
    ME: { secondary: "+382", primary: "Montenegro" },
    MA: { secondary: "+212", primary: "Morocco" },
    MM: { secondary: "+95", primary: "Myanmar" },
    NA: { secondary: "+264", primary: "Namibia" },
    NP: { secondary: "+977", primary: "Nepal" },
    NL: { secondary: "+31", primary: "Netherlands" },
    NZ: { secondary: "+64", primary: "New Zealand" },
    NE: { secondary: "+227", primary: "Niger" },
    NG: { secondary: "+234", primary: "Nigeria" },
    NO: { secondary: "+47", primary: "Norway" },
    OM: { secondary: "+968", primary: "Oman" },
    PK: { secondary: "+92", primary: "Pakistan" },
    PW: { secondary: "+680", primary: "Palau" },
    AW: { secondary: "+970", primary: "Palestin" },
    PA: { secondary: "+507", primary: "Panama" },
    PY: { secondary: "+595", primary: "Paraguay" },
    PH: { secondary: "+63", primary: "Philipinnes" },
    PL: { secondary: "+48", primary: "Poland" },
    PT: { secondary: "+351", primary: "Portugal" },
    QA: { secondary: "+974", primary: "Qatar" },
    RO: { secondary: "+40", primary: "Romania" },
    RU: { secondary: "+7", primary: "Russia" },
    WS: { secondary: "+685", primary: "Samoa" },
    SA: { secondary: "+966", primary: "Saudi Arabia" },
    SN: { secondary: "+221", primary: "Senegal" },
    RS: { secondary: "+381", primary: "Serbia" },
    SG: { secondary: "+65", primary: "Singapore" },
    SO: { secondary: "+252", primary: "Somalia" },
    ZA: { secondary: "+27", primary: "South Africa" },
    ES: { secondary: "+34", primary: "Spain" },
    LK: { secondary: "+94", primary: "Sri Lanka" },
    SD: { secondary: "+249", primary: "Sudan" },
    SE: { secondary: "+46", primary: "Swedan" },
    CH: { secondary: "+41", primary: "Switzerland" },
    SY: { secondary: "+963", primary: "Syria" },
    TW: { secondary: "+886", primary: "Taiwan" },
    TJ: { secondary: "+992", primary: "Tajiksatn" },
    TH: { secondary: "+66", primary: "Thailand" },
    TN: { secondary: "+216", primary: "Tunisia" },
    TR: { secondary: "+90", primary: "Turkey" },
    TM: { secondary: "+993", primary: "Turkmenistan" },
    UG: { secondary: "+256", primary: "Uganda" },
    UA: { secondary: "+380", primary: "Ukarina" },
    AE: { secondary: "+971", primary: "United Arab Emirates" },
    GB: { secondary: "+44", primary: "United Kingdom" },
    US: { secondary: "+1", primary: "United States" },
    UZ: { secondary: "+998", primary: "Uzbekistan" },
    VN: { secondary: "+84", primary: "Vitenam" },
    YM: { secondary: "+967", primary: "Yemen" },
    ZM: { secondary: "+297", primary: "Zambia" },
    ZW: { secondary: "+263", primary: "Zimbawbe" },
  };

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        document.documentElement.setAttribute("lang", "en");
        // navigate(`/en/my_bookings`);
      } else {
        changeLanguage("ar");

        setLocale("ar");
        document.documentElement.setAttribute("lang", "ar");
        // navigate(`/ar/my_bookings`);
      }
    }, 500);
  }, []);

  return (
    <div className="refrence-prompt">
      {modalMsg && (
        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          {
            <div className="msg">
              <p>{t("Unable to find details")}</p>
            </div>
          }

          <div className="row bottom_align1">
            <div className="col-lg-12 text-center">
              <button
                onClick={() => {
                  closeModal();
                }}
                className="button "
                aria-label="Back"
              >
                {t("Go Back Button")}
              </button>
            </div>
          </div>
        </ReactModal>
      )}
      <div className="container ">
        <div className="row top_bg">
          <div
            className={
              locale === "ar"
                ? "col-md-12 col-lg-12 title_header_arabic"
                : "col-md-12 col-lg-12 title_header"
            }
          >
            <h1 className="h1">{t("Manage Bookings")}</h1>
          </div>
        </div>
      </div>
      <hr className="line_break" />
      <div
        className="container mt-5 px-10"
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <main className="container">
              <form className="form-border">
                <div className="row heading">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <h1>{t("Traveler Information")}</h1>
                    <p> {t("Already Account Statement")} </p>
                  </div>
                </div>
                <div className="row booking_ref">
                  <div className="col-sm-12 col-md-12 col-lg-5 mb-4">
                    <label className="booking-label" htmlFor="booking-label">
                      {t("Booking Reference Number")}*
                    </label>
                    <br />
                    <input
                      type="text”"
                      id="booking-ref-input"
                      className="text-input"
                      placeholder={t("Booking Reference Number")}
                      onChange={(e) => setBookingRef(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="row email">
                  <div className="col-sm-12 col-md-12 col-lg-5 mb-4">
                    <label className="email-label" htmlFor="email-label">
                      {t("Email")}
                    </label>
                    <br />
                    <input
                      type="email"
                      id="email-input"
                      className="text-input"
                      placeholder={t("Email")}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-2 text-center or">
                    <h1> {t("OR")} </h1>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 mb-4">
                    <label className="contact-label”" htmlFor="contact-label">
                      {t("Contact Number Label")}
                    </label>
                    <br />
                    <div className="input-group ">
                      <ReactFlagsSelect
                        id="code"
                        placeholder={codeUpdatedValue}
                        className="countryCode select"
                        selected={codeUpdatedValue}
                        onSelect={(e) => checkSelectedVal(e)}
                        showSelectedLabel={showSelectedLabel}
                        showSecondarySelectedLabel={showSecondarySelectedLabel}
                        showOptionLabel={showOptionLabel}
                        showSecondaryOptionLabel={showSecondaryOptionLabel}
                        customLabels={customLabels}
                        searchable={searchable}
                      />
                      <input
                        type="number"
                        placeholder={t("Your Contact Number")}
                        className={
                          locale === "ar"
                            ? "text-input left-simple-border-arabic number"
                            : "text-input left-simple-border number"
                        }
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-12 col-md-12 col-lg-3 mb-4">
                    <button
                      aria-label="View Booking"
                      className="button"
                      onClick={viewBooking}
                    >
                      {" "}
                      {t("View Booking")}
                    </button>
                  </div>
                </div>
              </form>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GetBookingRef;
