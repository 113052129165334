import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
function Language() {
  const { lang, selectLanguage, locale, setLocale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <Fragment>
      <li>
        <div className="language" style={{ overflow: "hidden" }}>
          <a
            href="/"
            data-lang="en"
            style={{
              backgroundColor:
                document.documentElement.lang === "en"
                  ? "rgb(205, 251, 123, 0.1)"
                  : "",
            }}
            onClick={selectLanguage}
          >
            {t(`English`)}
          </a>
          <a
            href="/"
            data-lang="ar"
            style={{
              backgroundColor:
                document.documentElement.lang === "ar"
                  ? "rgb(205, 251, 123, 0.1)"
                  : "",
            }}
            onClick={selectLanguage}
          >
            العربية
          </a>
        </div>
      </li>
    </Fragment>
  );
}

export default Language;
