import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function RenderAirportsList({
  fromAirport,
  toAirport,
  airport,
  setAirportCodes,
}) {
  const { lang, locale } = useGlobals();
  // const { t, i18n, ready } = useTranslation();


  return (
    <li
      key={airport.id}
      onClick={(e) => setAirportCodes(e)}
      data-fromcode={airport.iata_Code}
      data-tocode={airport.iata_Code}
      data-text={
        locale === "en"
          ? 
            airport.fullName
          : airport.fullNameAr
      }
    >
      <strong>
        {locale === "en" ? airport.name : airport.nameAr}
      </strong>
      {locale === "en" ? (
        <p>
          {airport.fullName}
        </p>
      ) : (
        <p>
          {airport.fullNameAr}
        </p>
      )}
      <span className={locale === "ar" ? "airport_code_arabic" : ""}>
        {airport.iata_Code}
      </span>
    </li>
  );
}

export default RenderAirportsList;
