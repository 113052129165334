import { useGlobals } from "../../contexts/globalContexts";

import AirlineLogo from "../../img/airline-logo.svg";
import Clock from "../../img/clock-2.svg";
import ClockDark from "../../img/d/clock-2.svg";
import StopsLine from "../../img/stops-line.svg";
import StopsLineDark from "../../img/d/stops-line.svg";
import MobStopsLine from "../../img/mob-city-stops.svg";
import MobStopsLineDark from "../../img/d/city-stops-mob.svg";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function ArrivalDetails({ passengerInfo }) {
  const { bookingDetails, lang, locale } = useGlobals();

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date?.slice(0, -1)?.split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    return format(new Date(getTheDate(date)), "ccc, PP");
  };

  const getTheStops = (s, n) => {
    let stopsArr = [];
    for (let i = 0; i < s.length; i++) {
      stopsArr.push(s[i].arrivalAirport.airportCode);
    }
    stopsArr.splice(n);
    let showStops = stopsArr.map((stopsAr, index) => {
      return <span key={index}>{stopsAr}</span>;
    });
    return showStops;
  };

  const getAllTheStops = (s, n) => {
    let stopsArr = [];
    for (let i = 0; i < s.length; i++) {
      stopsArr.push([
        {
          arrival: s[i].arrivalAirport.airportCode,
          departure: s[i].departureAirport.airportCode,
        },
      ]);
    }
    stopsArr.splice(n);
    let showStops = stopsArr.map((stopsAr, index) => {
      return (
        <span key={index}>
          {stopsAr.map((stopsA, index) => {
            return <div key={index}>{stopsA.arrival}</div>;
          })}
        </span>
      );
    });
    return showStops;
  };

  const getTotalDuration = (s) => {
    let flight = [];
    let totalTime = [];
    for (let i = 0; i < s?.length; i++) {
      flight.push(s[i]?.flights[0].elapsedTime);
    }
    for (let j = 0; j < flight?.length; j++) {
      totalTime.push(flight[j]);
    }
    return convertMinsToHrsMins(totalTime.reduce((a, b) => a + b, 0));
  };

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className="col-12 print_mt_0 text-center mt-4">
          <p className="font-18">
            {locale === "ar" ? (
              <span className="font-18">
                {bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[0]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                <span className="font-bold">{bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[0]?.Segements[0]?.departureAirport
                    .cityCode}</span>
                {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityName}{" "}
                <span className="font-bold">{bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityCode}{" "}</span>
                —{" "}
                {passengerInfo &&
                  passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                <span className="font-bold">{passengerInfo?.Leg[1]?.Segements[0]?.departureAirport.cityCode}</span>
              </span>
            ) : (
              <span className="font-18">
                {passengerInfo &&
                  passengerInfo?.Leg[1]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                <span className="font-bold">{passengerInfo?.Leg[1]?.Segements[0]?.departureAirport.cityCode}</span>
                —{" "}
                {bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[0]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                <span className="font-bold">{bookingDetails.TripType === "RoundTrip" &&
                  passengerInfo?.Leg[0]?.Segements[0]?.departureAirport
                    .cityCode}</span>
                {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityName}{" "}
             <span className="font-bold">   {bookingDetails.TripType === "OneWay" &&
                  passengerInfo?.Leg[0]?.Segements[
                    passengerInfo?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityCode}</span>
              </span>
            )}
          </p>
        </div>
      </div>

      <div className="row print_mt_0 bg-grey mt-4 dls-none pt-3 pb-3">
        <div className="col-sm-12">
          <div className="row ">
            <div className="col-sm-3 col-2">
              <img
                src={bookingDetails.ReturnAirlineLogo}
                className="mob-height-img"
                alt="return-airline-logo"
              />
            </div>

            <div className="col-sm-8 print_none col-4 vertically-align-center ">
              <p className="font-13">{t("Flight Number")}</p>
              <p className="font-bold font-13">
                {
                  passengerInfo?.Leg[1]?.Segements[0].flights[0].carrier
                    .operating
                }{" "}
                - {bookingDetails.ReturnFlightNo}
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-4">
              <p className="font-12 text-green-mob font-bold">
                {bookingDetails.ReturnDepartureAirport}
              </p>
              <p className="font-10">{bookingDetails.ReturnDepartureDate}</p>
              <p className="font-10">
                {String(bookingDetails.ReturnDepartureTime).substring(0, 5)}
              </p>
              <p className="font-10">
                ({bookingDetails.ReturnDepartureAirport})
              </p>
              <p className="font-10">
                {t("Terminal")} : {bookingDetails.ReturnDepartureTerminal}
              </p>
            </div>

            <div className="col-4 text-center vertically-align-center ">
              <img src={Clock} className="light" alt="clock-light" />
              <img src={ClockDark} className="dark" alt="clock-dark" />

              <div className="duration_and_stops mt-5">
                <div className="duration">
                  <div className="font-8 total_duration_stopss" dir="ltr">
                    <br />
                    {getTotalDuration(passengerInfo?.Leg[1]?.Segements)} -{" "}
                    {passengerInfo?.Leg[1].NoOfStops} {t("Stops")}
                  </div>
                </div>
                <div className="stops font-11">
                  <span
                    style={
                      locale === "ar"
                        ? { margin: "0px" }
                        : { margin: "0 3.5px" }
                    }
                  >
                    &nbsp;
                  </span>
                  {passengerInfo?.Leg[1].NoOfStops >= 1
                    ? getAllTheStops(
                        passengerInfo?.Leg[1].Segements,
                        passengerInfo?.Leg[1].NoOfStops
                      )
                    : null}
                  <span
                    style={
                      locale === "ar"
                        ? { margin: "0px" }
                        : { margin: "0 3.5px" }
                    }
                  >
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>

            <div className="col-4 text-right">
              <p className="font-12 text-green-mob font-bold">
                {bookingDetails?.ReturnArrivalAirport}
              </p>
              <p className="font-10">
                {bookingDetails?.TripType === "RoundTrip" &&
                  getFormattedDate(
                    passengerInfo?.Leg[1]?.Segements[
                      passengerInfo?.Leg[1]?.Segements?.length - 1
                    ]?.flights[
                      passengerInfo?.Leg[1]?.Segements[0]?.flights?.length - 1
                    ]?.arrivalDateTime?.replace(/\s/g, "")
                  ).substring(4, 17)}
                {bookingDetails.TripType === "OneWay" &&
                  getFormattedDate(
                    passengerInfo?.Leg[1]?.Segements[
                      passengerInfo?.Leg[1]?.Segements?.length - 1
                    ]?.flights[
                      passengerInfo?.Leg[1]?.Segements[0]?.flights?.length - 1
                    ]?.arrivalDateTime?.replace(/\s/g, "")
                  ).substring(4, 17)}
              </p>
              <p className="font-10">
                {String(bookingDetails.ReturnArrivalTime).substring(0, 5)}
              </p>
              <p className="font-10">({bookingDetails.ReturnArrivalAirport})</p>
              <p className="font-10">
                {t("Terminal")} : {bookingDetails.ReturnArrivalTerminal}
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 text-center">
              <p className="font-bold text-green-mob font-12">
                {t("Class of travel")}
              </p>
              <p className="font-12">
                {passengerInfo?.Leg[1].Segements[0].cabinCode}
              </p>
            </div>

            <div className="col-6 text-center">
              <p className="font-bold text-green-mob font-12">
                {t("Aircraft Type")}
              </p>
              <p className="font-12">
                {bookingDetails.ReturnAirline} {bookingDetails.ReturnFlightNo}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-grey mt-4 dps-none print_none">
        <div className="col-3 bg-grey-padding">
          <img
            src={bookingDetails.ReturnAirlineLogo}
            style={{ height: "50px", borderRadius: "10px" }}
            alt="going-airline-logo"
          />
          <p className="font-11 mt-2">
            {bookingDetails.ReturnAirline} {t("Airlines")}
          </p>
          <p className="font-10">{t("Flight Number")}</p>
          <p className="font-bold font-11">
            {passengerInfo?.Leg[1]?.Segements[0]?.flights[0].carrier.operating}{" "}
            - {bookingDetails.ReturnFlightNo}
          </p>
        </div>

        <div className="col-2 text-right col-padding">
          <p className="font-18 font-bold">
            {passengerInfo?.Leg[1]?.Segements[0]?.departureAirport.airportCode}
          </p>
          <p className="font-18 mt-2">
            {String(bookingDetails.ReturnDepartureTime).substring(0, 5)}
          </p>
          <br />
          <p className="font-bold font-11">
            {passengerInfo?.Leg[1]?.Segements[0]?.departureAirport.airportName}{" "}
            , {t("Terminal")} {bookingDetails.ReturnDepartureTerminal}
          </p>
          <p className="font-10">
            {String(bookingDetails.ReturnDepartureDate).substring(0, 12)}
          </p>
        </div>

        <div className="col-3 text-center mt-2 col-padding">
          <img src={Clock} className="light" alt="mob-clock-light" />
          <img src={ClockDark} className="dark" alt="mob-clock-dark" />

          <div className="duration_and_stops mt-5">
            <div className="duration">
              <div className="font-11 total_duration_stopss" dir="ltr">
                <br />
                {getTotalDuration(passengerInfo?.Leg[1]?.Segements)} -{" "}
                {passengerInfo?.Leg[1].NoOfStops} {t("Stops")}
              </div>
            </div>
            <div className="stops font-11">
              <span
                style={
                  locale === "ar" ? { margin: "0px" } : { margin: "0 3.5px" }
                }
              >
                &nbsp;
              </span>
              {passengerInfo?.Leg[1].NoOfStops >= 1
                ? getAllTheStops(
                    passengerInfo?.Leg[1].Segements,
                    passengerInfo?.Leg[1].NoOfStops
                  )
                : null}
              <span
                style={
                  locale === "ar" ? { margin: "0px" } : { margin: "0 3.5px" }
                }
              >
                &nbsp;
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            locale === "ar"
              ? "col-2 col-padding"
              : "col-2 border-right-dashed col-padding"
          }
        >
          <p className="font-18 font-bold">
            {bookingDetails.TripType === "RoundTrip" &&
              passengerInfo?.Leg[0]?.Segements[0]?.departureAirport.airportCode}
            {bookingDetails.TripType === "OneWay" &&
              passengerInfo?.Leg[0]?.Segements[
                passengerInfo?.Leg[0].Segements.length - 1
              ].arrivalAirport.airportCode}
          </p>
          <p className="font-18 mt-2">
            {String(bookingDetails.ReturnArrivalTime).substring(0, 5)}
          </p>
          <br />
          <p className="font-bold">
            {bookingDetails.TripType === "RoundTrip" &&
              passengerInfo?.Leg[0]?.Segements[0]?.departureAirport.airportName}
            {bookingDetails.TripType === "OneWay" &&
              passengerInfo?.Leg[0]?.Segements[
                passengerInfo?.Leg[0].Segements.length - 1
              ].arrivalAirport.airportName}
            <br />
            {t("Terminal")} {bookingDetails.ReturnArrivalTerminal}
          </p>
          <p className="font-10">
            {bookingDetails.TripType === "RoundTrip" &&
              getFormattedDate(
                passengerInfo.Leg[1].Segements[
                  passengerInfo.Leg[1].Segements.length - 1
                ].flights[
                  passengerInfo.Leg[1].Segements[0].flights.length - 1
                ].arrivalDateTime.replace(/\s/g, "")
              ).substring(4, 17)}
            {bookingDetails.TripType === "OneWay" &&
              getFormattedDate(
                passengerInfo.Leg[1].Segements[
                  passengerInfo.Leg[1].Segements.length - 1
                ].flights[
                  passengerInfo.Leg[1].Segements[0].flights.length - 1
                ].arrivalDateTime.replace(/\s/g, "")
              ).substring(4, 17)}
          </p>
        </div>

        <div
          className={
            locale === "ar" ? "border-right-dashed col-2 pl-1" : "col-2 pl-1"
          }
        >
          <p className="font-11">{t("Class")}</p>
          <p className="font-11 font-bold">
            {passengerInfo?.Leg[1].Segements[0].cabinCode}
          </p>
          <br />
          <p className="font-10">{t("Aircraft Type")}</p>
          <p className="font-11 font-bold">
            {" "}
            {`${passengerInfo?.Leg[1]?.Segements[0]?.flights[0].carrier.operating} ${passengerInfo?.Leg[1]?.Segements[0].flights[0].carrier.operatingFlightNumber}`}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArrivalDetails;
