import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import "../../css/top_deals.css";
import '../../css/dealspage.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import About from "../About/About";

function DealsPage() {

    const { lang, locale } = useGlobals();
    const { t, i18n, ready } = useTranslation();

    const changeLanguage = (locale) => {
        i18n.changeLanguage(locale);
    };

    let localePath;
    localePath = window.location.pathname.split("/");
    const [allTopDeals, setAllTopDeals] = useState([]);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_PROMOTIONS}/CMS/GetPromotions`)
            .then((response) => response.json())
            .then((data) => {
                setAllTopDeals(data.responseModel)
            })
    }, [])

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.backgroundImage = 'none';
        window.scrollTo(0, 0);
    }, []);

    const [activeTab, setActiveTab] = useState("tab1");

    const toggleAllDeals = () => {
        setActiveTab("tab1"); //All Deals
    }
    const toggleFlightDeals = () => {
        setActiveTab("tab2"); //Flight Deals
    }
    const toggleHotelDeals = () => {
        setActiveTab("tab3"); //Hotel Deals
    }

    return (
        <div className='deals_page'>
            <ul className="nav">
                <li className={activeTab === "tab1" ? "active" : ""} onClick={toggleAllDeals}>All Deals</li>
                <li className={activeTab === "tab2" ? "active" : ""} onClick={toggleFlightDeals}>Flight Deals</li>
                <li className={activeTab === "tab3" ? "active" : ""} onClick={toggleHotelDeals}>Hotel Deals</li>
            </ul>
            <div className="container top_deals">
                <div className={activeTab === "tab1" ? "active" : ''}>
                    {allTopDeals.map((allTopDeal) => (

                        <Link key={allTopDeal.id} to={`/${locale}/deal_details/${allTopDeal.id}`}>
                            <div className="deal_image">
                                {locale === 'en' ? <img src={allTopDeal.tileImageURL} /> : <img src={allTopDeal.tileImageURLArabic} />}
                            </div>
                            <div className="deal_text">
                                <strong>{locale === 'en' ? allTopDeal.tileTitle : allTopDeal.tileTitleArabic}</strong>
                                <p>{locale === 'en' ? allTopDeal.tileSubTitle : allTopDeal.tileSubTitleArabic}</p>
                            </div>
                        </Link>

                    ))}
                </div>
                <div className={activeTab === "tab2" ? "active" : ''}>
                    {/* {allTopDeals.map((allTopDeal) => (

                        <Link key={allTopDeal.id} to={`/${locale}/deal_details/${allTopDeal.id}`}>
                            <div className="deal_image">
                                {locale === 'en' ? <img src={allTopDeal.tileImageURL} /> : <img src={allTopDeal.tileImageURLArabic} />}
                            </div>
                            <div className="deal_text">
                                <strong>{locale === 'en' ? allTopDeal.tileTitle : allTopDeal.tileTitleArabic}</strong>
                                <p>{locale === 'en' ? allTopDeal.tileSubTitle : allTopDeal.tileSubTitleArabic}</p>
                            </div>
                        </Link>

                    ))} */}
                    <p className="tab_unavailable">Currently, No Hotel Deals Are Available.</p>
                </div>
                <div className={activeTab === "tab3" ? "active" : ''}>
                    {/* {allTopDeals.map((allTopDeal) => (

                        <Link key={allTopDeal.id} to={`/${locale}/deal_details/${allTopDeal.id}`}>
                            <div className="deal_image">
                                {locale === 'en' ? <img src={allTopDeal.tileImageURL} /> : <img src={allTopDeal.tileImageURLArabic} />}
                            </div>
                            <div className="deal_text">
                                <strong>{locale === 'en' ? allTopDeal.tileTitle : allTopDeal.tileTitleArabic}</strong>
                                <p>{locale === 'en' ? allTopDeal.tileSubTitle : allTopDeal.tileSubTitleArabic}</p>
                            </div>
                        </Link>

                    ))} */}
                    <p className="tab_unavailable">Currently, No Hotel Deals Are Available.</p>
                </div>
            </div>
            <About />
        </div>
    )
}
export default DealsPage;