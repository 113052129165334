import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import { isMobile } from "react-device-detect";

function HyperStarApi({ hepstarDetails, callHepstarPassengers }) {
  const {
    hepstarData,
    setShowHepar,
    isChecked,
    setIsChecked,
    siteURLS,
    isLoading,
    setHepstarData,
    setIsloading,
    hepstarCheckDisbale,
    hepstarCalled,
    passengerAdded,
    setPassengerAdded,
    setHepstarCalled,
    setNoThanks,
    setHepstarDetails,
    item1,
    setItem1,
    item2,
    setItem2,
    item3,
    setItem3,
    item4,
    setItem4,
    item5,
    setItem5,
    checkedValue,
    setCheckedValue,
    totalPass,
    noThanks,
    buyHepstar,
    setBuyHepstar,
    isHepstarChecked,
    setIsHepstarChecked,
    lang,
    locale,
    utmMed
  } = useGlobals();

  const { BookingRef } = useParams();

  const callHepstar = (val) => {
    if(utmMed !== "meta"){
      // console.log('hyper')
      setIsloading(true);
      fetch(`${process.env.REACT_APP_IN_URL}/Insurance/GetPrice`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BookingRef: BookingRef,
          IsBuy: val,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (val === true) {
            setBuyHepstar(true);
            setHepstarCalled(true);
            setNoThanks(false);
          } else {
            setBuyHepstar(false);
            setHepstarCalled(false);
            setNoThanks(true);
          }
  
          setIsloading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const callHepstarFun = (e) => {
    setIsHepstarChecked(true);
    let val = e.target.value;
    if (val === "Yes") {
      setBuyHepstar(true);
      setHepstarCalled(true);
      setNoThanks(false);
    } else {
      setBuyHepstar(false);
      setHepstarCalled(false);
      setNoThanks(true);
    }
  };

  useEffect(() => {
    if (
      totalPass &&
      totalPass.IsInsured === true &&
      totalPass.PassengerDetails[0].givenName !== null
    ) {
      setHepstarCalled(true);
    } else {
      setBuyHepstar(false);
      setHepstarCalled(false);
    }
  }, []);

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <div className="row mt-3">
        <div className="col-lg-1"></div>
        <div className="col-sm-12 col-md-12 col-lg-11 form-border-dd myDIV">
          <div className="row">
            <div className="col-sm-5 col-lg-12 col-md-12 ">
              <h2 className="font-15 font-bold">
                {hepstarDetails && hepstarDetails.text
                  ? hepstarDetails.text
                  : "Recommended"}{" "}
                <i className="fa fa-umbrella"></i>
              </h2>
            </div>

            <div className="col-sm-5 col-md-6 col-lg-6 mt-3">
              <p className="font-13 font-bold"> </p>
              <ol className="mt-2 px-2">
                <li className="font-13">{item1 && `1. ${item1}`}</li>
                <li className="font-13">{item2 && `2. ${item2}`}</li>
                <li className="font-13">{item3 && `3. ${item3}`}</li>
                <li className="font-13">{item4 && `4. ${item4}`}</li>
                <li className="font-13">{item5 && `5. ${item5}`}</li>
              </ol>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <ul
                className="d-flex"
                style={isMobile ? { flexWrap: "wrap" } : { flexWrap: "nowrap" }}
              >
                <li className="mr-2">
                  <label className="form_checkbox label">
                    <span
                      style={
                        locale === "ar"
                          ? { marginRight: "35px" }
                          : { marginRight: "0px" }
                      }
                    >
                      {t("No Hepstar")}
                    </span>
                    <input
                      type="radio"
                      name="hepstar"
                      checked={noThanks}
                      value="No"
                      onChange={(e) => callHepstar(false)}
                      // defaultChecked
                    />
                    <span
                      className="checkmark"
                      style={locale === "ar" ? { right: "0" } : { left: "0" }}
                    ></span>
                  </label>
                </li>

                <li className="mr-2">
                  <label className="form_checkbox label">
                    <span
                      style={
                        locale === "ar"
                          ? { marginRight: "35px" }
                          : { marginRight: "0px" }
                      }
                    >
                      {t("Yes Buy Hepstar")}
                    </span>
                    <input
                      type="radio"
                      name="hepstar"
                      checked={hepstarCalled}
                      value="Yes"
                      onChange={(e) => callHepstar(true)}
                    />
                    <span
                      className="checkmark"
                      style={locale === "ar" ? { right: "0" } : { left: "0" }}
                    ></span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
              {noThanks === true || hepstarCalled === true ? null : (
                <p>{t("Hepstar Note")}.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HyperStarApi;
