import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function Airlines() {
  const { searchResults, resetAllFiltersApply, lang, locale, filtersArray, array, setArray } = useGlobals();
  const [airlines, setAirlines] = useState([]);
  const [filteredList, setFilteredList] = new useState(airlines);

  const makeAirlinesPriceTabs = () => {
    if (searchResults) {
      setAirlines([
        ...new Set(
          searchResults.map(
            (data) =>
              data.Itineraries[0].Leg[0].Segements[0].flights[0].carrier
                .marketingFlightName
          )
        ),
      ]);
    }
  };


  // console.log(array);

  const makeAirlineSelection = (e) => {
    let selectedPrice = e.currentTarget.dataset.aprice;
    let selectedAirline = e.currentTarget.dataset.aprice;
  };

  useEffect(() => {
    makeAirlinesPriceTabs();
  }, [searchResults]);

  const [checkedValue, setCheckedValue] = useState([]);

  const handleChange = (e) => {
    if (e.target.checked === true) {
      if (array !== undefined && !array.includes(e.target.value)) {
        setArray((oldArray) => [...oldArray, e.target.value]);
      }
    }

    if (e.target.checked === false) {
      if (array !== undefined && array.includes(e.target.value)) {
        setArray(array.filter((item) => item !== e.target.value));
      }
    }
  };

  useEffect(() => {
    if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
       
    document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
      // console.log(itineraries);
      if(isMobile){
        // itineraries.classList.remove('show_all');
        // itineraries.classList.add('hidee');
        // if(itineraries.parentElement){
        //   itineraries.parentElement.classList.add('hideee');
        // }
    
      if (array?.length > 0) {
        if (array.includes(itineraries.dataset.airline)) {
          itineraries.classList.remove("hidee");
          itineraries.classList.add('show_all');
          if(itineraries.parentElement){
            itineraries.parentElement.classList.remove('hideee');
          }
          // console.log('here');
        }  
        else {
          itineraries.classList.add("hidee");
          // console.log('there');
          if(itineraries.parentElement){
            itineraries.parentElement.classList.add('hideee');
          }
        }
      }
      }
      if(!isMobile){

    
      if (array?.length > 0) {
        if (array.includes(itineraries.dataset.airline)) {
          itineraries.classList.add("show_all");
          itineraries.classList.remove("hide");
        }  
        else {
          itineraries.classList.remove("show_all");
          itineraries.classList.add("hide");
        }
      }
      }
    });
  }
  }, [array]);

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedList = [...airlines];
    updatedList = updatedList.filter((item) => {
      return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(updatedList);
  };

  const resetFunction = () => {
    setArray([]);
    setAirlines("");
    setTimeout(() => {
      setAirlines([
        ...new Set(
          searchResults.map(
            (data) =>
              data.Itineraries[0].Leg[0].Segements[0].flights[0].carrier
                .marketingFlightName
          )
        ),
      ]);
    }, 100);
  };

  useEffect(() => {
    resetFunction();
  }, [resetAllFiltersApply]);

  const { t, i18n, ready } = useTranslation();

  return (
    <div className="filter_section airlines" style={{ paddingBottom: "30px" }}>
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6">
          <strong className={searchResults?.length > 0 ? null : "skeleton"}>
            {t("Airlines")}
          </strong>
        </div>
        {array?.length > 0 ? (
          <div className="col-lg-6 text-right reset">
            <strong onClick={resetFunction}>{t("Reset")}</strong>
          </div>
        ) : null}
      </div>
      {searchResults?.length > 0 ? (
        <input
          type="search"
          dir="ltr"
          placeholder={t("Search")}
          name="airlines_filter"
          id="airlines_filter"
          onChange={filterBySearch}
        />
      ) : null}

      {searchResults?.length > 0 ? (
        filteredList.length > 0 ? (
          <ul className="airline-list">
            {filteredList.map((item, index) => (
              <li
                key={item.ItineraryId + "_" + index}
                // onClick={(e) => makeAirlineSelection(e)}
                data-aprice={item}
                data-acode={item}
              >
                <div className={item.ItineraryId}>
                  <div className="airlines">
                    <label
                      className={
                        locale === "ar"
                          ? "form_checkbox_arabic"
                          : "form_checkbox"
                      }
                    >
                      {item}
                      <input
                        type="checkbox"
                        value={item}
                        onChange={(e) => handleChange(e)}
                      />
                      <span
                        className={
                          locale === "ar" ? "checkmark_arabic" : "checkmark"
                        }
                      />
                    </label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="airline-list">
            {airlines &&
              airlines?.map((airline, index) => {
                return (
                  <li
                    key={index}
                    // onClick={(e) => makeAirlineSelection(e)}
                    data-aprice={airline}
                    data-acode={airline}
                  >
                    <div className={airline?.ItineraryId}>
                      <div className="airlines">
                        <label
                          className={
                            locale === "ar"
                              ? "form_checkbox_arabic"
                              : "form_checkbox"
                          }
                        >
                          {airline}
                          <input
                            type="checkbox"
                            value={airline}
                            onChange={(e) => handleChange(e)}
                          />
                          <span
                            className={
                              locale === "ar" ? "checkmark_arabic" : "checkmark"
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        )
      ) : (
        <ul>
          <li className={searchResults?.length > 0 ? null : "skeleton"}>
            <label className="form_checkbox">
              Emirates
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
          <li className={searchResults?.length > 0 ? null : "skeleton"}>
            <label className="form_checkbox">
              Flynas
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
          <li className={searchResults?.length > 0 ? null : "skeleton"}>
            <label className="form_checkbox">
              Egypt Air
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
          <li className={searchResults?.length > 0 ? null : "skeleton"}>
            <label className="form_checkbox">
              Ethiopian Airlines
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
          <li className={searchResults?.length > 0 ? null : "skeleton"}>
            <label className="form_checkbox">
              Salam Air
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Airlines;
