import '../../css/dealspage.css'
// import '../../css/homepage.css'
import dealImg from "../../img/deal_img.png"
import discount from "../../img/discount.svg"
import coupon from "../../img/coupon.svg"
import calendar from "../../img/calendar.svg"
import applicable from "../../img/applicable_on.svg"
import affordable from "../../img/affordable_travel.svg"
import secure from "../../img/secure_payments.svg"
import customer from "../../img/customer_support.svg"


import { useEffect } from 'react';
import Searchform from '../SearchForm/SearchForm';

function DealsPageTwo() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (

        <div className='deals_page'>
            <section className="container-fluid home_page">
                <div className="container home_page_text">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <hgroup>
                                <h1> Explore Hot Deals </h1>
                                <p>Eilago’s innovative technology helps travelers save up to 40% every time they travel. See
                                    why
                                    millions of travelers are using Eilago to book flights, hotels, and cars.</p>
                            </hgroup>
                        </div>
                    </div>
                </div>
                <Searchform />
            </section>

            <div className="container">

                <div className="row">

                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="dealImg">
                            <img src={dealImg} alt="" />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4">
                        <div className="dealPoints">
                            <ul>
                                <li>
                                    <div className="offerIcon">
                                        <img src={discount} alt="" />
                                    </div>
                                    <div className="offerBrief">
                                        <p> Discount <span className="text_bold"> 35 % Off </span> </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="offerIcon">
                                        <img src={coupon} alt="" />
                                    </div>
                                    <div className="offerBrief">
                                        <p>
                                            Coupon code<br />
                                            <span className="txtB2" id="cpy">ELSUMD</span>
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="offerIcon">
                                        <img src={calendar} alt="" />
                                    </div>
                                    <div className="offerBrief">
                                        <p>
                                            Booking Period<br />
                                            <span className="txtB2" id="cpy">Dec 9, 2022</span>
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="offerIcon">
                                        <img src={applicable} alt="" />
                                    </div>
                                    <div className="offerBrief">
                                        <p>
                                            Applicable On<br />
                                            <span className="txtB2" id="cpy">Flight Bookings</span>
                                        </p>
                                    </div>
                                </li>
                            </ul>

                        </div>

                    </div>

                </div>

                <div className="row dealList">
                    <div className="col-md-12 dealDetail">
                        <div className="padBox">
                            <h4> Eilago Summer Deal</h4>
                            <ul className="listing">
                                <li> • To avail the offer, customer must enter E-Coupon <strong> MMTFED </strong> in the
                                    E-Coupon field for Domestic Hotels</li>
                                <li> • This offer is valid on all Mondays from 1st April to 24th June 2019 between 0000
                                    hours and 2359 hours</li>
                                <li> • All offers are valid for bookings made on MakeMyTrip’s website, Mobile site, Android
                                    & iOS App </li>
                                <li> • The offer is valid for 1 booking per card per MakeMyTrip id during the entire offer
                                    period</li>
                                <li> • For all offer categories, this offer cannot be clubbed with any other offer</li>
                                <li> • A customer can make max 5 bookings in last 30 days using any eligible offer code on
                                    Hotel bookings</li>
                                <li> • A customer can make max 3 bookings in a day using any eligible offer code on Hotel
                                    bookings</li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row booking_process">
                    <div className="col-sm-12 col-md-4 col-lg-4 afford_travel">
                        <div className="img">
                            <img src={affordable} alt="" />
                        </div>

                        <div className="briefText">
                            <ul className="brief">
                                <li>
                                    <h3>Affordable Travel</h3>
                                    <p> Save big on your Eilago bookings <br />
                                        with exciting travel deals and offers</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 secure_payment">
                        <div className="img">
                            <img src={secure} alt="" />
                        </div>

                        <div className="briefText">
                            <ul className="brief">
                                <li>
                                    <h3>Secure payments</h3>
                                    <p> We Use The Latest Technology <br />
                                        to Secure Your Privacy</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 customer_support">
                        <div className="img">
                            <img src={customer} alt="" />
                        </div>

                        <div className="briefText">
                            <ul className="brief">
                                <li>
                                    <h3> Customer Support</h3>
                                    <p>  Multilingual (English & Arabic) <br />
                                        customer support service</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row terms-cond">
                    <div className="col-md-12 termsDetail">
                        <div className="padBox">
                            <h4> Terms And Conditions </h4>
                            <ul className="listing">
                                <li> • If the customer doesn't receive the discount, he/she must raise the claim at support within 3 months from the booking date. In the event the Cardholder fails to do so, he/she will not be eligible for the discount amount</li>
                                <li> • This offer is NOT applicable on Federal Bank’s corporate cards</li>
                                <li> • Add-on cards will be treated as separate cards </li>
                                <li> • This offer cannot be clubbed with any other offer of MakeMyTrip</li>
                                <li> • MakeMyTrip reserves the right, at any time, without prior notice and liability and without assigning any reason whatsoever, to add/alter/modify/change or vary all of these terms and conditions or to replace, wholly or in part, this offer by another offer, whether similar to this Offer or not, or to extend or withdraw it altogether</li>
                                <li> • For any card related claims, the customer shall approach the Bank and MakeMyTrip shall not entertain any such claims</li>
                                <li> • MakeMyTrip is the sole authority for interpretation of these terms</li>
                                <li> • In the event of any misuse or abuse of the offer by the customer or travel agent, MakeMyTrip reserves the right to deny the offer or cancel the booking</li>
                                <li> • In no event the entire liability of MakeMyTrip under this offer shall exceed the amount of promotional discount under this offer</li>
                                <li> • MakeMyTrip shall not be liable to for any indirect, punitive, special, incidental or consequential damages arising out of or in connection with the offer</li>
                                <li> • Disputes, if any, arising out of or in connection with this offer shall be subject to the exclusive jurisdiction of the competent courts in Delhi </li>
                                <li> • User Agreement and Privacy Policy at MakeMyTrip website shall apply </li>
                                <li> • Customer’s, who are Travel Agents by occupation, are barred from making bookings for their customer’s and MakeMyTrip reserves the right to deny the offer against such bookings or to cancel such bookings. For such cases, MakeMyTrip shall not refund the booking amount. </li>

                            </ul>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
export default DealsPageTwo;