
import { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { DateRange, Calendar } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import SpinnerLoader from "../Spinner/SpinnerLoader";
import localeAR from "../../locale/ar";
import localeEN from "../../locale/en";

import { useGlobals } from "../../contexts/globalContexts";
import { useNavigate } from "react-router-dom";

import "react-date-range/dist/styles.css";

import "../../css/radio_button.css";
import "../../css/toggle_switch.css";
import "../../css/passenger_class.css";
import "../../css/top_searches.css";

import "../../css/search_form.css";
import "../../css/date_range.css";

import searchIcon from "../../img/search_icon_white.svg";
import toggleIcon from "../../img/toggle_to_from.svg";
import Passengers from "../Passengers/Passengers";
import RenderAirportsList from "../SelectAirport/RenderAirportsList";
import { useTranslation } from "react-i18next";

function Searchform({ isDisabled }) {
  const navigate = useNavigate();

  const {
    lang,
    createLeg,
    directFlight,
    flexibleDates,
    cabin,
    createCabin,
    passengers,
    createPassengers,
    tripType,
    legs,
    setLegs,
    setSearchId,
    setSearchResults,
    setTripType,
    setDirectFlight,
    setSearchType,
    dates,
    setDates,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    onewaydate,
    setOneWayDate,
    fromAirport,
    setFromAirport,
    toAirport,
    setToAirport,
    from,
    setFrom,
    to,
    setTo,
    priceFilter,
    setPriceFilter,
    siteURLS,
    searchId,
    setSearchedData,
    setPassengers,
    adults,
    setAdults,
    children,
    setChildren,
    infants,
    setInfants,
    isLoading,
    setIsloading,
    showErrorPage,
    setShowErrorPage,
    locale, currency, setFromAirportValue, setToAirportValue
  } = useGlobals();
  



  useEffect(() => {
    if(tripType === 0){
        // setOneWayDate(fromDate);
    }
    // console.log(dates)
  }, [tripType]);






  const [fromAirportBox, setFromAirportBox] = useState(false);
  const [toAirportBox, setToAirportBox] = useState(false);

  const [dateBox, setDateBox] = useState(false);
  const [passengerBox, setPassengerBox] = useState(false);

  const [airports, setAirports] = useState([]);

  const abortControllerRef = useRef(null);
  useEffect(() => {
    return () => {
      // Cleanup function to abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);
  const openFromAirportBox = (e) => {
    setAirports([]);
    setToAirportBox(false);
    setFromAirportBox(true);
    setDateBox(false);
    if (isMobile) {
      window.setTimeout(
        () => document.getElementById("from_mobile").focus(),
        0
      );
    }
  };

  const openToAirportBox = () => {
    setAirports([]);
    setFromAirportBox(false);
    setToAirportBox(true);
    setDateBox(false);
    if (isMobile) {
      window.setTimeout(() => document.getElementById("to_mobile").focus(), 1);
    }
  };

  const openPassengerBox = () => {
    setPassengerBox(true);
    setDateBox(false);
    setFromAirportBox(false);
    setToAirportBox(false);
  };

  const addAdults = () => {
    if (infants + adults + children <= 8) {
      setAdults(adults + 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  };
  const removeAdults = () => {
    if (adults !== 1) {
      setAdults(adults - 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  };
  const addChildren = () => {
    if (infants + adults + children <= 8) {
      setChildren(children + 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  };
  const removeChildren = () => {
    if (children !== 0) {
      setChildren(children - 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  };
  const addInfants = () => {
    if (infants + adults + children <= 8 && infants < adults) {
      setInfants(infants + 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  };
  const removeInfants = () => {
    if (infants !== 0) {
      setInfants(infants - 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  };

  useEffect(() => {
    if (infants > adults) {
      setInfants(infants - 1);
      setPassengers({
        adultCount: adults,
        childrenCount: children,
        infantsCount: infants,
      });
    }
  }, [adults, infants]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPassengerBox(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const openDateBox = () => {
    setDateBox(true);
  };

  const closeDateBox = () => {
    setDateBox(false);
    openPassengerBox()
  };



  const [changeDate , setChangeDate] = useState(fromDate);


  useEffect(() => {

    createLeg(fromAirport, toAirport, dates, tripType, onewaydate);

      if (tripType === 1) {
        setOneWayDate(dates[0].startDate);
        // if(onewaydate){
        //   setFromDate(onewaydate)
        // }
        // else{
          setFromDate(dates[0].startDate);
        // }
        if(dates[0]?.endDate){
        setToDate(dates[0]?.endDate);
        }
        if(!dates[0]?.endDate){
          document.getElementById('to_date').value = String(new Date(addDays(fromDate, 1))).substring(0,15);
          setChangeDate(new Date(addDays(fromDate, 1)));
        }

        // else{
          document.getElementById("from_date").value = String(
            dates[0].startDate
          ).substring(0, 15);
        // }

      } 
      if (tripType === 0) {
        // if(window.location.pathname !== "/"){
        //   // setOneWayDate(fromDate);
        //   // setFromDate(onewaydate)
        //   setFromDate(dates[0].startDate);
        //   setOneWayDate(dates[0].startDate);

        // }
        // else{
        //   if(dates[0].startDate){
        //     // setFromDate(dates[0].startDate);
        //     // setOneWayDate(dates[0].startDate);
        //     // setFromDate(onewaydate);
        //     setFromDate(dates[0].startDate);
        //     setOneWayDate(dates[0].startDate);
        //   }
        //   else{
        //   setFromDate(onewaydate);
        //   // console.log('one');
        //   }
        // }
       
        // if(window.location.pathname !== "/"){
        //     document.getElementById("from_date").value = String(onewaydate).substring(
        //       0,
        //       15
        //     );
        //   }
        // else{
        // document.getElementById("from_date").value = String(onewaydate).substring(
        //   0,
        //   15
        // );
        // }
        // setFromDate(String(onewaydate).substring(0,15))

        if(onewaydate){
          setFromDate(onewaydate)
        }
        else{
          setFromDate(dates[0].startDate);
        }
          document.getElementById("from_date").value = String(
            onewaydate
          ).substring(0, 15);


        if(dates[0]?.endDate){
          setToDate(dates[0]?.endDate);
          }
          if(!dates[0]?.endDate){
            document.getElementById('to_date').value = fromDate;
            setChangeDate(new Date(addDays(fromDate, 1)));
          }
          
          
      }
    
    createPassengers(adults, children, infants);
    createCabin(cabin);
  }, [
    from,
    to,
    fromAirport,
    toAirport,
    toDate,
    fromDate,
    dates,
    adults,
    children,
    infants,
    cabin,
    priceFilter,
    tripType,
    onewaydate,
  ]);



  const clearOnFocus = (e) => {
    e.target.value = "";
  };

  const getAirports = async (e) => {  
    // Abort any pending requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new abort controller
    const newAbortController = new AbortController();

    abortControllerRef.current = newAbortController;
    // Call onSearch with new search term and abort controller  
    

      const getResultsFrom = `${process.env.REACT_APP_STATIC_CONTENT_URL}/Airports/Search`;
      fetch(getResultsFrom, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Value: e.target.value,
          IndexList: "eilago-airports-638575106964201777",
          Local:"en",
          From:0,
          Size:10
        }),
        signal: newAbortController.signal,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.responseModel);  
          setAirports(data.responseModel);
          e.target.classList.remove("has_error");
        })
        .catch((err) => {
          console.error(err);          
        });
   


    // const res = await fetch(
    //   //`${process.env.REACT_APP_STATIC_CONTENT_URL}/Data/GetAirport?value=${e.target.value}&culture=1&size=10`      
    //   `${process.env.REACT_APP_STATIC_CONTENT_URL}/Airports/Search/GetAirport?value=${e.target.value}&culture=1&size=10`
    // );
    // const data = await res.json();
    // console.log(data.responseModel);
    // //setAirports(data.hits.hits);
    // setAirports(data.responseModel);
    // e.target.classList.remove("has_error");
    
    if(!isMobile){
      if(window.location.pathname !== "/" && window.location.pathname !== "/ar"){
        if(document.activeElement.id === "from"){

          if(document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.from_input > div')){
          document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.from_input > div').style.display = "block";
           }
          }
           if(document.activeElement.id === "to"){
            if(document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.to_input > div')){
          document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.to_input > div').style.display = "block";
           }
          }
      }
     
    if(window.location.pathname === "/" && window.location.pathname === "/ar"){
      if(document.activeElement.id === "from"){

        // if(document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.from_input > div")){
        document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.from_input > div").style.display = "block";
      // }
    }
      if(document.activeElement.id === "to"){
  
        // if(document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.to_input > div")){
        document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.to_input > div").style.display = "block";
      // }
    }
    }
   }
  
   
  };

  useEffect(() => {
   if(!isMobile){
   if(window.location.pathname !== "/" && window.location.pathname !== "/deal_one" && window.location.pathname !== "/ar"){
    if(document.activeElement.id !== "from"){
      if(document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.from_input > div')){
      document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.from_input > div').style.display = "none";
       }
      }
       if(document.activeElement.id !== "to"){
        if(document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.to_input > div')){
      document.querySelector('#root > section.search_form_container.container > section > ul.search_form_inputs > li.to_input > div').style.display = "none";
       }}
   }
    if(window.location.pathname === "/" && window.location.pathname === "/ar"){
      if(fromAirport && document.activeElement !== "from"){
        // if(document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.from_input > div")){
        document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.from_input > div").style.display = "none";
        // }
      }
      if(toAirport && document.activeElement !== "to"){
        // if(document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.to_input > div")){
        document.querySelector("#root > section.container-fluid.home_page > section > section > ul.search_form_inputs > li.to_input > div").style.display = "none";
      }
    }
    //  }
   }

  }, [fromAirport, toAirport]);



  const setAirportCodes = (e) => {
    if (fromAirportBox) {
      setFromAirport(e.currentTarget.dataset.fromcode);
      document.getElementById("from").value = e.currentTarget.dataset.text;
      setFrom(e.currentTarget.dataset.text);
      setFromAirportValue(e.currentTarget.dataset.text);

      document.getElementById("from_mobile").value = ''
      document.getElementById("from_mobile").nextSibling.html = ''
      setAirports([]);
      setFromAirportBox(false);
      setToAirportBox(true);
      window.setTimeout(() => document.getElementById("to_mobile").focus(), 1);
    }
    if (toAirportBox) {
      setFromAirportBox(false);
      setToAirport(e.currentTarget.dataset.tocode);
      document.getElementById("to").value = e.currentTarget.dataset.text;
      setTo(e.currentTarget.dataset.text);
      setToAirportValue(e.currentTarget.dataset.text);

      document.getElementById("to_mobile").value = ''
      document.getElementById("to_mobile").nextSibling.html = ''
      setAirports([]);
      setFromAirportBox(false);
      setToAirportBox(false);
      openDateBox(true)
    }
    // setFromAirportBox(false);
    // setToAirportBox(false);
  };

  

  const changeTripType = (e) => {
    if (e.target.checked) {
      setTripType(1)
    } else {
      setTripType(0)
    }
  };

  const changeTripTypeMobile = (e) => {
    if (e.target.id === "rt") {
      setDates([
        {
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 2),
          key: 'selection'
        },
      ])
      setTripType(1);
      // setPassengerBox(false)
    }
    if (e.target.id === "ow") {
      setTripType(0);
      // setPassengerBox(false)
    }
  };

  const changeDirectFlight = (e) => {
    if (e.target.checked === true) {
      setDirectFlight(true);
    } else {
      setDirectFlight(false);
    }
  };

  const renderedAirports = airports.map((airport) => {
    return (
      <RenderAirportsList
        setAirportCodes={(e) => setAirportCodes(e)}
        fromAirport={fromAirport}
        toAirport={toAirport}
        airport={airport}
        key={airport.id}
      />
    );
  });

  let path = window.location.pathname.split("/");

  let holdSearchId;
  const createSearchId = () => {
    const getIdFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/Search`;

    let payloadToGetSearchId = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        directFlight: directFlight,
        flexibleDates: flexibleDates,
        preferedAirLine: "",
        tripType: tripType,
        partner: 2,
        device: 1,
        cabin: cabin,
        passengers: passengers,
        locale: locale.toUpperCase(),
        currencyCode: currency,
        legs: legs,
        sessions: {
          id: "0",
          userType: "Visitor",
          userID: "0",
        },
        utmParameters: {
          source: isMobile ? "MobileWeb" : "Web",
          medium: "Eilago",
          campaign: "Direct",
          term:
            tripType === 1
              ? legs[0].departureCode +
                "," +
                legs[0].arrivalCode +
                "-" +
                legs[1].departureCode +
                "," +
                legs[1].arrivalCode
              : legs[0].departureCode + "," + legs[0].arrivalCode,
          content: "website",
        },
      }),
    };

    setIsloading(true);
    fetch(getIdFrom, payloadToGetSearchId)
      .then((response) => response.json())
      .then((data) => {
        holdSearchId = data.responseModel.searchId;
        setSearchId(data.responseModel.searchId);

        if (path[2] === "search_results") {
          navigate(`/${locale}/search_results/${holdSearchId}`);
          setTimeout(() => {
            //http://0.0.0.0:8080/
            const getResultsFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/GetResults`;
            // const getResultsFrom = `http://localhost:3000/sample.json`
            fetch(getResultsFrom, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                SearchId: holdSearchId,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                setIsloading(false);
                if (data.responseModel.pricingGroups) {
                  setSearchType(data.responseModel.SearchType.Type);
                  setSearchId(holdSearchId);
                }
                if (data.responseModel.pricingGroups) {
                  let resultsArray = [];
                  for (
                    let i = 0;
                    i < data.responseModel.pricingGroups.length;
                    i++
                  ) {
                    resultsArray.push(data.responseModel.pricingGroups[i]);
                  }
                  setSearchResults(resultsArray);
                } else {
                  setTimeout(() => {
                    //http://0.0.0.0:8080/
                    const getResultsFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/GetResults`;
                    // const getResultsFrom = `http://localhost:3000/sample.json`
                    fetch(getResultsFrom, {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        SearchId: holdSearchId,
                      }),
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        setIsloading(false);
                        if (data.responseModel.pricingGroups) {
                          setSearchType(data.responseModel.SearchType.Type);
                          setSearchId(holdSearchId);
                        }
                        if (data.responseModel.pricingGroups) {
                          let resultsArray = [];
                          for (
                            let i = 0;
                            i < data.responseModel.pricingGroups.length;
                            i++
                          ) {
                            resultsArray.push(
                              data.responseModel.pricingGroups[i]
                            );
                          }
                          setSearchResults(resultsArray);
                        } else {
                          setShowErrorPage(true);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                        setShowErrorPage(true);
                      });
                  }, 6500);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }, 6500);
        } else {
          navigate(`/${locale}/search_results/${holdSearchId}`);
          setIsloading(false);
        }
      });
  };

  let searchBtn = () => {
    setFromAirportBox(false);
    setToAirportBox(false);
    setDateBox(false);
    setPassengerBox(false);
    setSearchResults([]);
    createSearchId();
    // navigate(`/search_results/${searchId}`);
  };

  const clearInput = (e) => {
    e.target.nextSibling.value = "";
    
    if (e.target.nextSibling.id === "from") {
      setFrom("");
      document.getElementById("from").focus();
      document.getElementById("from").value = "";
      document.getElementById("from").click();
    }
    if (e.target.nextSibling.id === "to") {
      setTo("");
      document.getElementById("to").focus();
      document.getElementById("to").value = "";
      document.getElementById("to").click();
    }
  };

  useEffect(() => {
    document.getElementsByClassName('search_options_list').style = "";  
    document.getElementsByClassName('is_open').style = "";  
  }, [airports]);


  const validateFromTo = () => {
    if (from === "" || to === "") {
      alert(
        `${to === "" ? "Destination" : ""} ${
          from === "" ? "and departure are" : "is"
        } missing. Please select a valid airport code or city.`
      );
    }
    if (from === "") {
      document.getElementById("from").classList.add("has_error");
    }
    if (to === "") {
      document.getElementById("to").classList.add("has_error");
    }
    if (from === to) {
      alert(
        `Destination and departure locations are the same. Please select a valid airport code or city.`
      );
      document.getElementById("from").classList.add("has_error");
      document.getElementById("to").classList.add("has_error");
    }
  };

  const sameLocations = (e) => {
    alert(`Departure and destination can't be same.`);
  };

  const toggler = () => {
    let initLocationNames = [];
    let flipLocationNames = [];
    let initLocationCodes = [];
    let flipLocationCodes = [];
    if (from !== "" && to !== "") {
      initLocationNames.push(from, to);
      flipLocationNames = initLocationNames.reverse();
      document.getElementById("from").value = flipLocationNames[0];
      document.getElementById("to").value = flipLocationNames[1];
      setFrom(flipLocationNames[0]);
      setTo(flipLocationNames[1]);

      initLocationCodes.push(fromAirport, toAirport);
      flipLocationCodes = initLocationCodes.reverse();
      setFromAirport(flipLocationCodes[0]);
      setToAirport(flipLocationCodes[1]);
    }
  };

  const { t, i18n, ready } = useTranslation();
  let localeValue;

  let localePath;
  useEffect(() => {
  localePath = window.location.pathname;
  }, [])

  useEffect(() => {
  if(!isMobile){
    if(fromAirportBox === true){
      // document.getElementById('from')
    }
  }
  }, [airports]);

  return (
    <>
      {isMobile ? (
        <section className="mobile_options">
          <ul>
            <li>
              <button
                type="button"
                className={tripType === 1 ? "active" : null}
                id="rt"
                onClick={(e) => {
                  changeTripTypeMobile(e);
                }}
                aria-label="Round Trip"
              >
                {t("Round Trip")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className={tripType === 0 ? "active" : null}
                id="ow"
                onClick={(e) => {
                  changeTripTypeMobile(e);
                }}
                aria-label="One Way"
              >
                {t("One Way")}
              </button>
            </li>
          </ul>
        </section>
      ) : null}



      <section
        className="search_form_container container"
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <section className="search_form">
          <ul className="search_form_inputs">
            <li className="from_input">
              <label
                htmlFor="from"
                style={
                  locale === "ar" ? { marginLeft: "76%" } : { marginLeft: "0" }
                }
              >
                {t("FromLabel")}
              </label>

              {isMobile ? (
                ""
              ) : (
                <img
                  src="../../img/close.svg"
                  alt="remove"
                  style={
                    from === "" ? { display: "none" } : { display: "block" }
                  }
                  className="clear_input"
                  onClick={(e) => clearInput(e)}
                />
              )}
              <input
                autoComplete="off"
                type="text"
                placeholder={t("From")}
                className={locale === "ar" ? "from_arabic" : ""}
                id="from"
                onClick={(e) => openFromAirportBox(e)}
                onChange={(e) => getAirports(e)}
                defaultValue={from}
                
              />

              <div
                className={
                  fromAirportBox
                    ? "search_options_list is_open"
                    : "search_options_list"
                }
              >
                <button
                  className="big_back_button"
                  onClick={() => {
                    setFromAirportBox(false);
                  }}
                  aria-label="Back"
                />
                <h2>{t('Traveling From')}</h2>
                {/* this is for mobile */}
                <input
                  autoComplete="off"
                  type="text"
                  placeholder={t("From")}
                  id="from_mobile"
                  onChange={(e) => getAirports(e)}
                  onFocus={(e) => clearOnFocus(e)}
                  defaultValue={from}
                />
                <ul>
                  {airports ? (
                    renderedAirports
                  ) : (
                    <>
                      <li>
                        <strong>Dhaka</strong>
                        <p>Dhaka, Bangladesh — Zia International Airport</p>
                        <span>DAC</span>
                      </li>
                      <li>
                        <strong>Jeddah</strong>
                        <p>Jeddah, Saudi Arabia — King Abdulaziz Intl. </p>
                        <span>JED</span>
                      </li>
                      <li>
                        <strong>Riyadh</strong>
                        <p>Riyadh, Saudi Arabia — King Khaled Intl. </p>
                        <span>RAD</span>
                      </li>
                      <li>
                        <strong>Madinah</strong>
                        <p>Madinah, Saudi Arabia — Mohammad Bin Abdulaziz</p>
                        <span>MAD</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>
            <li
              style={
                locale === "ar" && !isMobile
                  ? { marginRight: "-11px" }
                  : !isMobile
                  ? { marginLeft: "-24px" }
                  : { marginLeft: "0px" }
              }
              className={
                locale === "ar" && isMobile
                  ? "togglerArabic toggler"
                  : "toggler"
              }
            >
              <button className="toggle_to_from" aria-label="Toggle">
                <img src={toggleIcon} alt="" onClick={toggler} />
              </button>
            </li>
            <li className="to_input">
              <label
                htmlFor="To"
                style={
                  locale === "ar" ? { marginLeft: "76%" } : { marginLeft: "0" }
                }
              >
                {t("ToLabel")}
              </label>

              {isMobile ? (
                ""
              ) : (
                <img
                  src="../../img/close.svg"
                  alt="remove"
                  style={to === "" ? { display: "none" } : { display: "block" }}
                  className="clear_input"
                  onClick={(e) => clearInput(e)}
                />
              )}
              <input
                autoComplete="off"
                type="text"
                placeholder={t("To")}
                id="to"
                className={locale === "ar" ? "to_arabic" : ""}
                onClick={openToAirportBox}
                onChange={(e) => (toAirportBox ? getAirports(e) : null)}
                defaultValue={to}
              />
              <div
                className={
                  toAirportBox
                    ? "search_options_list is_open"
                    : "search_options_list"
                }
              >
                <button
                  className="big_back_button"
                  aria-label="Back"
                  onClick={() => {
                    setToAirportBox(false);
                  }}
                />
                <h2>{t('Traveling To')}</h2>
                {/* this is for mobile */}
                <input
                  autoComplete="off"
                  type="text"
                  placeholder={t("To")}
                  id="to_mobile"
                  onChange={(e) => (toAirportBox ? getAirports(e) : null)}
                  onFocus={(e) => clearOnFocus(e)}
                  defaultValue={to}
                />
                <ul>
                  {airports ? (
                    renderedAirports
                  ) : (
                    <>
                      <li>
                        <strong>Dhaka</strong>
                        <p>Dhaka, Bangladesh — Zia International Airport</p>
                        <span>DAC</span>
                      </li>
                      <li>
                        <strong>Jeddah</strong>
                        <p>Jeddah, Saudi Arabia — King Abdulaziz Intl. </p>
                        <span>JED</span>
                      </li>
                      <li>
                        <strong>Riyadh</strong>
                        <p>Riyadh, Saudi Arabia — King Khaled Intl. </p>
                        <span>RAD</span>
                      </li>
                      <li>
                        <strong>Madinah</strong>
                        <p>Madinah, Saudi Arabia — Mohammad Bin Abdulaziz</p>
                        <span>MAD</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>

            {locale === "en" ? (
              <>
                <li
                  className={
                    tripType === 0 ? "departure_date oneway" : "departure_date"
                  }
                >
                  <label
                    htmlFor="from_date"
                    style={
                      locale === "ar"
                        ? { marginLeft: "52%" }
                        : { marginLeft: "0" }
                    }
                  >
                    {t("Departure")}
                  </label>
                  {/* setFromDate, setToDate, */}
                  {tripType === 0 ? (
                    <input
                      defaultValue={String(onewaydate).substring(0, 15)}
                      autoComplete="off"
                      type="text"
                      placeholder={t("Departure")}
                      id="from_date"
                      onClick={() => setDateBox(true)}
                      readOnly
                      style={!isMobile ? {height:"66px"} : {height:"48px", borderRight:"none", width:"100%", fontSize:"12px"}}
                    />
                  ) : (
                    <input
                      defaultValue={String(fromDate).substring(0, 15)}
                      autoComplete="off"
                      type="text"
                      placeholder={t("Departure")}
                      id="from_date"
                      onClick={() => setDateBox(true)}
                      readOnly
                    />
                  )}
                  <div
                    className={dateBox ? "date_range is_open" : "date_range"}
                  >
                    <button
                      aria-label="Close"
                      className="big_close_button"
                      onClick={closeDateBox}
                    ></button>
                    {/* onewaydates, setOneWayDates */}
                    {tripType === 0 ? (
                      <Calendar
                        minDate={new Date(new Date().getTime() + 86400000)}
                        onChange={(item) => setOneWayDate(item)}
                        date={onewaydate}
                        color="#63AF37"
                        locale={locale === "ar" ? localeAR : localeEN}
                      />
                    ) : (
                      <DateRange
                        editableDateInputs={false}
                        locale={locale === "ar" ? localeAR : localeEN}
                        minDate={new Date(new Date().getTime() + 86400000)}
                        onChange={(item) => {
                          setDates([item.selection]);
                        }}
                        moveRangeOnFirstSelection={false}
                        showSelectionPreview={true}
                        ranges={dates}
                        months={2}
                        direction="horizontal"
                        rangeColors={["#63AF37", "#000", "#000"]}
                        color={"#FFFFFF"}
                      />
                    )}
                    <button
                      aria-label="Apply"
                      className="apply_button"
                      onClick={closeDateBox}
                    >
                      {t("Apply Btn")}
                    </button>
                  </div>
                </li>

                <li
                  className="return_date"
                  style={
                    isMobile && tripType === 0
                      ? {
                          height: "43px",
                          border: "1px solid #cdd5dd",
                          borderLeft: "none",
                          // borderRight:"none",

                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                        }
                      : { height: "auto" }
                  }
                >
                  {tripType === 1 ? (
                    <>
                      <label htmlFor="to_date">{t("Return")}</label>
                      <input
                        readOnly
                        // defaultValue={String(toDate).substring(0, 15)}
                        autoComplete="off"
                        type="text"
                        placeholder={t("Return")}
                        id="to_date"
                        onClick={openDateBox}
                        defaultValue={toDate ? String(toDate).substring(0, 15) : String(changeDate).substring(0,15)} 
                      />
                    </>
                  ) : (
                    <>
                      <label></label>
                      <input
                        readOnly
                        type="text"
                        id="to_date"
                        style={{ opacity: "0" }}
                        onClick={openDateBox}
                        // defaultValue={toDate ? String(toDate).substring(0, 15) : changeDate}
                      />
                    </>
                  )}
                </li>
              </>
            ) : (
              <>
                {tripType === 1 ? (
                  <>
                    <li className={tripType === 1 && "return_date"}>
                      <label
                        htmlFor="from_date"
                        style={
                          locale === "ar"
                            ? { marginLeft: "48%" }
                            : { marginLeft: "0" }
                        }
                      >
                        {t("Departure")}
                      </label>
                      {/* setFromDate, setToDate, */}
                      {tripType === 0 ? (
                        <input
                          defaultValue={String(onewaydate).substring(0, 15)}
                          autoComplete="off"
                          type="text"
                          placeholder={t("Departure")}
                          id="from_date"
                          onClick={() => setDateBox(true)}
                          readOnly
                        />
                      ) : (
                        <input
                          defaultValue={String(fromDate).substring(0, 15)}
                          autoComplete="off"
                          type="text"
                          placeholder={t("Departure")}
                          id="from_date"
                          onClick={() => setDateBox(true)}
                          readOnly
                        />
                      )}
                      <div
                        className={
                          dateBox ? "date_range is_open" : "date_range"
                        }
                      >
                        <button
                          aria-label="Close"
                          className="big_close_button"
                          onClick={closeDateBox}
                        ></button>
                        {/* onewaydates, setOneWayDates */}
                        {tripType === 0 ? (
                          <Calendar
                            minDate={new Date(new Date().getTime() + 86400000)}
                            onChange={(item) => setOneWayDate(item)}
                            date={onewaydate}
                            color="#63AF37"
                            locale={locale === "ar" ? localeAR : localeEN}
                          />
                        ) : (
                          <DateRange
                            editableDateInputs={false}
                            locale={locale === "ar" ? localeAR : localeEN}
                            minDate={new Date(new Date().getTime() + 86400000)}
                            onChange={(item) => {
                              setDates([item.selection]);
                            }}
                            moveRangeOnFirstSelection={false}
                            showSelectionPreview={true}
                            ranges={dates}
                            months={2}
                            direction="horizontal"
                            rangeColors={["#63AF37", "#000", "#000"]}
                            color={"#FFFFFF"}
                          />
                        )}
                        <button
                          aria-label="Apply"
                          className="apply_button"
                          onClick={closeDateBox}
                        >
                          {t("Apply Btn")}
                        </button>
                      </div>
                    </li>
                    <li className="departure_date">
                      {tripType === 1 ? (
                        <>
                          <label
                            htmlFor="to_date"
                            style={
                              locale === "ar"
                                ? { marginLeft: "53%" }
                                : { marginLeft: "0" }
                            }
                          >
                            {t("Return")}
                          </label>
                          <input
                            readOnly
                            // defaultValue={toDate ? String(toDate).substring(0, 15) : changeDate}
                            defaultValue={toDate ? String(toDate).substring(0, 15) : String(changeDate).substring(0,15)} 
                            autoComplete="off"
                            type="text"
                            placeholder={t("Return")}
                            id="to_date"
                            onClick={openDateBox}
                            
                          />
                        </>
                      ) : (
                        ` `
                      )}
                    </li>
                  </>
                ) : (
                  <>
                    <li className={tripType === 0 && "one_way return_date"}>
                      <label
                        htmlFor="from_date"
                        style={
                          locale === "ar"
                            ? { marginLeft: "48%" }
                            : { marginLeft: "0" }
                        }
                      >
                        {t("Departure")}
                      </label>
                      {/* setFromDate, setToDate, */}
                      {tripType === 0 ? (
                        <input
                          defaultValue={String(onewaydate).substring(0, 15)}
                          autoComplete="off"
                          type="text"
                          placeholder={t("Departure")}
                          id="from_date"
                          onClick={() => setDateBox(true)}
                          readOnly
                          
                        />
                      ) : (
                        <input
                          defaultValue={String(fromDate).substring(0, 15)}
                          autoComplete="off"
                          type="text"
                          placeholder={t("Departure")}
                          id="from_date"
                          onClick={() => setDateBox(true)}
                          readOnly
                        />
                      )}
                      <div
                        className={
                          dateBox ? "date_range is_open" : "date_range"
                        }
                        style={
                          isMobile
                            ? { marginLeft: "0px" }
                            : { width: "21vw", marginLeft: "-160px" }
                        }
                      >
                        <button
                          aria-label="Close"
                          className="big_close_button"
                          onClick={closeDateBox}
                        ></button>
                        {/* onewaydates, setOneWayDates */}
                        {tripType === 0 ? (
                          <Calendar
                            minDate={new Date(new Date().getTime() + 86400000)}
                            onChange={(item) => setOneWayDate(item)}
                            date={onewaydate}
                            color="#63AF37"
                            locale={locale === "ar" ? localeAR : localeEN}
                          />
                        ) : (
                          <DateRange
                            editableDateInputs={false}
                            locale={locale === "ar" ? localeAR : localeEN}
                            minDate={new Date(new Date().getTime() + 86400000)}
                            onChange={(item) => {
                              setDates([item.selection]);
                            }}
                            moveRangeOnFirstSelection={false}
                            showSelectionPreview={true}
                            ranges={dates}
                            months={2}
                            direction="horizontal"
                            rangeColors={["#63AF37", "#000", "#000"]}
                            color={"#FFFFFF"}
                          />
                        )}
                        <button
                          aria-label="Apply"
                          className="apply_button"
                          onClick={closeDateBox}
                        >
                          {t("Apply Btn")}
                        </button>
                      </div>
                    </li>

                    <li
                      className="departure_date"
                      style={
                        isMobile && tripType === 0
                          ? {
                              height: "48px",
                              border: "1px solid #cdd5dd",
                              borderRight: "none",
                              borderTopLeftRadius: "5px",
                              borderBottomLeftRadius: "5px",
                              backgroundImage : "none",
                            }
                          : { height: "auto" }
                      }
                    >
                      {tripType === 1 ? (
                        <>
                          <label
                            htmlFor="to_date"
                            style={
                              locale === "ar"
                                ? { marginLeft: "53%" }
                                : { marginLeft: "0" }
                            }
                          >
                            {t("Return")}
                          </label>
                          <input
                            readOnly
                            defaultValue={toDate ? String(toDate).substring(0, 15) : changeDate}
                            autoComplete="off"
                            type="text"
                            placeholder={t("Return")}
                            id="to_date"
                            onClick={openDateBox}
                          />
                        </>
                      ) : (
                        <>
                          <label></label>
                          <input
                            type="text"
                            readOnly
                            id="from_date"
                            onClick={() => setDateBox(true)}
                            style={{ opacity: "0", height: "48px" }}
                          />
                        </>
                      )}
                    </li>
                  </>
                )}
              </>
            )}

            <Passengers
              adults={adults}
              children={children}
              infants={infants}
              addAdults={addAdults}
              addChildren={addChildren}
              addInfants={addInfants}
              removeAdults={removeAdults}
              removeChildren={removeChildren}
              removeInfants={removeInfants}
              useOutsideAlerter={useOutsideAlerter}
              passengerBox={passengerBox}
              setPassengerBox={setPassengerBox}
              openPassengerBox={openPassengerBox}
              cabin={cabin}
              createCabin={createCabin}
            />
            <li className={locale === "ar" ? "last_item_arabic" : ""}>
              {isLoading ? (
                <div>
                  <SpinnerLoader className="search_btn" />
                </div>
              ) : from !== "" && to !== "" ? (
                <button
                  aria-label="Search"
                  onClick={
                    fromAirport === toAirport
                      ? (e) => sameLocations(e)
                      : searchBtn
                  }
                  type="submit"
                  className={
                    fromAirport === toAirport
                      ? "search_btn disabled"
                      : "search_btn"
                  }
                >
                  <img src={searchIcon} alt="" />
                  <span>{t("Search Flights")}</span>
                </button>
              ) : (
                <button
                  type="submit"
                  aria-label="Search"
                  className={
                    locale === "ar"
                      ? "search_btn_arabic search_btn disabled"
                      : "search_btn disabled"
                  }
                  onClick={validateFromTo}
                  id="SearchBtn"
                  disabled={fromAirport === toAirport ? "disabled" : null}
                  title={
                    from === "" || to === ""
                      ? "Please select Enter a valid airport code or city."
                      : null
                  }
                >
                  <img src={searchIcon} alt="search icon" />
                  <span>{t("Search Flights")}</span>
                </button>
              )}
            </li>
          </ul>
          <ul className="search_form_options">
           {localePath === "/" || localePath === "/ar" ?
             <li>
             <label className="toggle_switch">
               {tripType === 1 ? `${t("Round Trip")}` : `${t("One Way")}`}

               <input
                 onClick={(e) => changeTripType(e)}
                 autoComplete="off"
                 type="checkbox"
                 defaultChecked={tripType === 1 ? "checked" : null}
                 defaultValue={1}
               />

               <span className="toggle_switch_checkmark" />
             </label>
           </li>
           :
           <li>
             <label className="toggle_switch">
               {tripType === 1 ? `${t("Round Trip")}` : `${t("One Way")}`}

               <input
                 onClick={(e) => changeTripType(e)}
                 autoComplete="off"
                 type="checkbox"
                 defaultChecked={tripType === 1 ? "checked" : null}
                 defaultValue={tripType}
               />

               <span className="toggle_switch_checkmark" />
             </label>
           </li>
           }

            <li>
              <label className="toggle_switch">
                {t("Non Stop Flights")}
                <input
                  onClick={(e) => changeDirectFlight(e)}
                  autoComplete="off"
                  type="checkbox"
                  defaultChecked={directFlight === true ? "checked" : null}
                  defaultValue={false}
                />
                <span className="toggle_switch_checkmark" />
              </label>
            </li>
          </ul>
        </section>
      </section>
    </>
  );
}

export default Searchform;