import { useGlobals } from "../../contexts/globalContexts";

import HeaderBird from "../../img/green_header_bird.svg";
import HeaderReceipt from "../../img/reciept.svg";
import HeaderPrint from "../../img/print.svg";
import HeaderDownload from "../../img/download.svg";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";

function BookingConfirmation({ passengerInfo, generatePDF, printDocument }) {
  const { BookingRef } = useParams();

  const {
    hepstarPolicyNumber,
    createBookingApiData,
    bookingDetails,
    bookingId,
    bookingRef,
  } = useGlobals();

  const print = () => {
    window.print();
  };

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", "background-image:none");
    document
      .getElementsByTagName("main")[0]
      .setAttribute("className", "page search_results");
  }, []);

  return (
    <div>
      <div className="container-fluid bg-green dps-none">
        <div className="container">
          <div className="row ">
            <div className="col-5 ">
              <p className="font-24 font-semibold text-white">
                Your Flight Booking Is Confirmed
              </p>

              <div className="row mt-3">
                <div className="col-6">
                  <p className="font-12 text-white">
                    Booking Reference No. {BookingRef}
                  </p>
                </div>

                <div className="col-6">
                  <p className="font-12 text-white">
                    <span className="underlined-text print_none">
                      Send Booking Confirmation To My Email
                    </span>
                    <br />
                    <span>
                      PNR : {passengerInfo ? passengerInfo.GdsPnr : null}{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-2 text-center">
              <img src={HeaderBird} className="header-bird" alt="header-bird" />
            </div>

            <div className="col-5 text-center print_none">
              <img
                src={HeaderReceipt}
                className="green-section-images mr-2 cursor-pointer"
                onClick={printDocument}
                alt="print-doc"
              />
              <img
                src={HeaderPrint}
                className="green-section-images mr-2 cursor-pointer"
                onClick={printDocument}
                alt="receipt"
              />
              <img
                src={HeaderDownload}
                className="green-section-images cursor-pointer"
                onClick={generatePDF}
                alt="pdf"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmation;
