import { useGlobals } from "../../contexts/globalContexts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function DurationSorting() {
  const { durationSorting, setDurationSorting, lang } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <ul>
        <li>
          <label className="form_radio">
            {t("Long")}
            <input
              type="radio"
              name="duration"
              onChange={() => setDurationSorting("Long")}
              defaultChecked
              value={"Long"}
            />
            <span className="checkmark" />
          </label>
        </li>
        <li>
          <label className="form_radio">
            {t("Short")}
            <input
              type="radio"
              name="duration"
              onChange={() => setDurationSorting("Short")}
              value={"Short"}
            />
            <span className="checkmark" />
          </label>
        </li>
      </ul>
    </div>
  );
}

export default DurationSorting;
