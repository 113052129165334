import { React, useEffect, useState } from "react";
import "../../css/flight_confirmation.css";
import { isMobile } from "react-device-detect";
import TopSteps from "./TopSteps";
import BookingConfirmation from "./BookingConfirmation";
import MobileBookigConfirmation from "./MobileBookingConfirmation";
import Sidebar from "./Sidebar";
import MobileIcons from "./MobileIcons";
import WebIcons from "./WebIcons";
import PassengerDetails from "./PassengerDetails";
import BaggageInformation from "./BaggageInformation";
import AirplaneDepartureIcon from "./AirplaneDepartureIcon";
import DepartureDetails from "./DepartureDetails";
import AirplaneArrivalIcon from "./AirplaneArrivalIcon";
import ArrivalDetails from "./ArrivalDetails";
import BackToTopButton from "../BackToTopButton";
import { useGlobals } from "../../contexts/globalContexts";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { format } from "date-fns";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import "../../css/search_result_snippet.css";
import "../../css/search_results.css";
import { locale } from "moment/moment";

function FlightConfirmation() {
  const { BookingRef } = useParams();
  const {
    bookingDetails,
    isLoading,
    siteURLS,
    setIsloading,
    setBookingDetails,
    pricingInfo,
    setPricingInfo,
    isOpenPage,
    setIsOpenPage,
    setDiscountedAmount,
    lang,
    locale,
    setCurr,
  } = useGlobals();

  const navigate = useNavigate();

  const [passengerInfo, setPassengerInfo] = useState();

  const [passengerCount, setPassengerCount] = useState();

  const [pixelImg, setPixelImg] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }
    document.title = "Booking Confirmation";
  }, []);

  const [hideTopSection, setHideTopSection] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("booking_details")) {
      document.title = "Booking Details";
      setHideTopSection(true);
    }
    if (window.history.length < 3) {
      navigate("/");
    }
  }, []);

  const [click_id, setClickId] = useState();
  const [totalPriceForPixel, setTotalPriceForPixel] = useState();
  const [passengerQty, setPassengerQty] = useState();
  const [currency, setCurrency] = useState();
  const [route, setRoute] = useState();

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return dt;
  };

  let adultQty = "";
  let childQty = "";
  let infantsQty = "";
  let adultPrice = "";
  let childPrice = "";
  let infantPrice = "";
  let adultTax = "";
  let infantTax = "";
  let childTax = "";
  let price = "";


  const calcTotalTax = (psngrArray) => {
    let totalTax = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      totalTax += psngrArray[i].price.totalTaxAmount;
    }
    return Number(totalTax).toFixed(2);
  };

  const calcTotalFare = (psngrArray) => {
    let totalFare = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      totalFare += psngrArray[i]?.price?.totalFareAmount;
    }
    return Number(totalFare).toFixed(2);
  };

  const calcTotalDiscount = (psngrArray) => {
    let totalDiscount = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      if(psngrArray[i]?.RevenueModel?.Discount){
      totalDiscount += psngrArray[i]?.RevenueModel?.Discount[0]?.DiscountAmount;
      }
    }
    return Number(totalDiscount).toFixed(2);
  };

  useEffect(() => {
    setIsloading(true);
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/GetItineraryDetails`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: BookingRef,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCurr(data.responseModel.passengers[0].price.currency);
        let itinerary = data.responseModel;
        // console.log(data);

        setIsloading(false);

        let pathName = "";

        let source = "";

        source = data?.responseModel?.utmParams?.source;
        setClickId(data?.responseModel?.utmParams?.clickId);

        setTotalPriceForPixel(data?.responseModel?.TotalPrice);
        setPassengerQty(adultQty + childQty + infantsQty);
        setCurrency(data?.responseModel?.passengers[0]?.price?.currency);
        setRoute(data?.responseModel?.utmParams?.term?.replace(",", "-"));
  

        window.dataLayer = window.dataLayer || [];

        window. dataLayer.push({

          event: 'purchase',
      
          ecommerce: {
      
            items: [
      
              {
      
                item_name: 'Flight Ticket',
      
                item_id: BookingRef,
      
                item_category: 'Flight',

                item_price: calcTotalFare(data?.responseModel?.passengers) * data?.responseModel?.passengers[0].qty,
      
                // price: data?.responseModel?.TotalPrice,
                discount: calcTotalDiscount(data?.responseModel?.passengers),
      
                quantity: data?.responseModel?.passengers[0]?.qty
      
              }
      
            ],
      
            currency: process.env.REACT_APP_CURRENCY,

            language: locale,
      
            purchase: {
      
              transaction_id: BookingRef,
      
              affiliation: data?.responseModel?.Leg[0].Segements[0].flights[0].carrier.operating,
      
              value: data?.responseModel?.TotalPrice,
      
              tax: calcTotalTax(data?.responseModel.passengers) * data?.responseModel?.passengers[0].qty
      
            }
      
          },
      
          transaction_id: BookingRef,
      
          affiliation: data?.responseModel?.Leg[0].Segements[0].flights[0].carrier.operating,
      
          flight_id: 'FLIGHT001',

          origin: data?.responseModel?.Leg[0]?.Segements[0]?.departureAirport?.cityName,

          destination: data?.responseModel?.Leg[0]?.Segements[data?.responseModel.Leg[0].Segements?.length - 1]?.arrivalAirport?.cityName,
      
          arrival_date: data?.responseModel?.Leg[0].departureDate,
      
          departure_date: data?.responseModel?.Leg[data?.responseModel?.Leg.length - 1].departureDate,

        });



        //  console.log(window.dataLayer);
{/* <script language="javascript" type="text/javascript"> */}

// </script>

  

        switch (source) {
          case "Wego":
            if (click_id !== null) {
              if (window.location.hostname === "sa.eilago.com") {
                setPixelImg(
                  `https://secure.wego.com/analytics/v2/conversions?conversion_id=c-wego-eilago.com&click_id=${data?.responseModel?.utmParams?.clickId}&comm_currency_code=${data?.responseModel?.passengers[0]?.price?.currency}&bv_currency_code=${data?.responseModel?.passengers[0]?.price?.currency}&transaction_id=${BookingRef}&commission=0&total_booking_value=${data?.responseModel?.TotalPrice}&status=confirmed`
                );
              } else {
                setPixelImg(
                  `https://secure.wego.com/analytics/v2/conversions?conversion_id=c-uat-wego-eilago.com&click_id=${data?.responseModel?.utmParams?.clickId}&comm_currency_code=${data?.responseModel?.passengers[0]?.price?.currency}&bv_currency_code=${data?.responseModel?.passengers[0]?.price?.currency}&transaction_id=${BookingRef}&commission=0&total_booking_value=${data?.responseModel?.TotalPrice}&status=confirmed`
                );
              }
            }
            break;
          case "Skyscanner":
            if (click_id !== null) {
            }
            break;
          case "EasyHop":
            if (click_id !== null) {
              callEasyHopPixel();
            }
            break;
          case "Travolic":
            if (click_id !== null) {
              setPixelImg(
                `https://api.travolic.com/tr/flights/eilago/pixel/?redirectID=${data?.responseModel?.utmParams?.clickId}&price=${data?.responseModel?.TotalPrice}&currency=${data?.responseModel?.passengers[0]?.price?.currency}&confirmation=${BookingRef}`
              );
            }
            break;
        }

        if (itinerary && itinerary?.passengers[0]) {
          adultQty = itinerary?.passengers[0].qty;
          adultPrice =
            adultQty * itinerary?.passengers[0]?.price?.totalFareAmount;
          adultTax = itinerary?.passengers[0]?.price?.totalTaxAmount * adultQty;
        }
        if (itinerary && itinerary?.passengers[1]?.type === "CNN") {
          childQty = itinerary?.passengers[1]?.qty;
          childPrice =
            childQty * itinerary?.passengers[1]?.price?.totalFareAmount;
          childTax = itinerary?.passengers[1]?.price?.totalTaxAmount * childQty;
        }
        if (itinerary && itinerary?.passengers[1]?.type === "INF") {
          infantsQty = itinerary?.passengers[1]?.qty;
          infantPrice =
            infantsQty * itinerary?.passengers[1]?.price?.totalFareAmount;
          infantTax =
            itinerary?.passengers[1]?.price?.totalTaxAmount * infantsQty;
        }
        if (itinerary && itinerary?.passengers[2]?.type === "INF") {
          infantsQty = itinerary?.passengers[2]?.qty;
          infantPrice =
            infantsQty * itinerary?.passengers[2]?.price?.totalFareAmount;
          infantTax =
            itinerary?.passengers[2]?.price?.totalTaxAmount * infantsQty;
        }

        setPricingInfo({
          adultQty: adultQty,
          adultPrice: adultPrice,
          adultTax: adultTax,
          childQty: childQty,
          childPrice: childPrice,
          childTax: childTax,
          infantsQty: infantsQty,
          infantPrice: infantPrice,
          infantTax: infantTax,
          price: adultPrice + childPrice + infantPrice,
          taxPrice: adultTax + childTax + infantTax,
        });

        if (data?.responseModel.passengers[0].RevenueModel.Discount !== null) {
          setDiscountedAmount(
            data?.responseModel?.passengers[0]?.RevenueModel?.Discount[0]
              ?.DiscountAmount * data.responseModel.passengers.qty
          );
        } else {
          setDiscountedAmount(0);
        }
        

        if (itinerary?.Leg[1]) {
          setBookingDetails({
            ItineraryId: itinerary.ItineraryId,
            itineraryKey: itinerary.ItineraryKey,
            GoingDepartureAirport:
              itinerary.Leg[0].Segements[0].departureAirport.airportName,
            goingAirline:
              itinerary.Leg[0].Segements[0].flights[0].carrier.operating,
            goingDepartureTime:
              itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                " "
              )[0],
            goingDepartureTerminal:
              itinerary.Leg[0].Segements[0].departureAirport.Terminal,
            goingDepartureDate: getFormattedDate(
              itinerary?.Leg[0]?.Segements[0]?.flights[0]?.departureDateTime
                ?.split(" ")[0]
                .substring(0, 10)
            ),
            goingArrivalAirport:
              itinerary.Leg[1].Segements[0].departureAirport.airportName,
            goingArrivalTime:
              itinerary.Leg[1].Segements[0].flights[0].arrivalTime.split(
                " "
              )[0],
            goingArrivalTerminal:
              itinerary.Leg[1].Segements[0].departureAirport.Terminal,
            ReturnDepartureAirport:
              itinerary.Leg[1].Segements[0].departureAirport.airportName,
            ReturnAirline:
              itinerary.Leg[1].Segements[0].flights[0].carrier.operating,
            ReturnDepartureDate: getFormattedDate(
              itinerary.Leg[1].departureDate
            ),
            ReturnDepartureTime:
              itinerary.Leg[1].Segements[0].flights[0].departureTime.split(
                " "
              )[0],
            ReturnDepartureTerminal:
              itinerary.Leg[1].Segements[0].departureAirport.Terminal,
            ReturnArrivalAirport:
              itinerary.Leg[1].Segements[itinerary.Leg[1].Segements.length - 1]
                .arrivalAirport.airportName,
            ReturnArrivalTime:
              itinerary.Leg[1].Segements[
                itinerary.Leg[1].Segements.length - 1
              ].flights[0].arrivalTime.split(" ")[0],
            ReturnArrivalTerminal:
              itinerary.Leg[1].Segements[itinerary.Leg[1].Segements.length - 1]
                .arrivalAirport.Terminal,
            ReturnArrivalDate: getFormattedDate(itinerary.Leg[1].departureDate),
            price: itinerary.passengers[0].price.totalPrice,
            taxPrice: itinerary.passengers[0].pqprice.totalTaxAmount,
            baseFareAmount: itinerary.passengers[0].pqprice.baseFareAmount,
            GoingStop: itinerary.Leg[0].NoOfStops,
            ReturnStop: itinerary.Leg[1].NoOfStops,
            TripType: "RoundTrip",
            Passengers: itinerary.passengers[0].qty,
            GoingAirlineLogo:
            `${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier.operating}.png`,
            GoingFlightNo:
              itinerary.Leg[0].Segements[0].flights[0].carrier
                .operatingFlightNumber,
            ReturnFlightNo:
              itinerary.Leg[1].Segements[0].flights[0].carrier
                .operatingFlightNumber,
            ReturnAirlineLogo:
            `${window.location.origin}/img/airlines/${itinerary.Leg[1].Segements[0].flights[0].carrier.operating}.png`,
            goingDepartureCountry:
              itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
            goingArrivalCountry:
              itinerary.Leg[0].Segements[0].departureAirport.countryCode,
            ReturnDepartureCountry:
              itinerary.Leg[1].Segements[0].departureAirport.countryCode,
            ReturnArrivalCountry:
              itinerary.Leg[1].Segements[0].arrivalAirport.countryCode,
            ReturnTotalTime: convertMinsToHrsMins(
              itinerary.Leg[1].Segements[itinerary.Leg[1].Segements.length - 1]
                .flights[0].elapsedTime
            ),
            GoingTotalTime: convertMinsToHrsMins(
              itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1]
                .flights[0].elapsedTime
            ),
          });
        } else {
          setBookingDetails({
            ItineraryId: itinerary?.ItineraryId,
            itineraryKey: itinerary.ItineraryKey,
            GoingDepartureAirport:
              itinerary.Leg[0].Segements[0].arrivalAirport.airportName,
            goingAirline:
              itinerary.Leg[0].Segements[0].flights[0].carrier.operating,
            goingDepartureTime:
              itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                " "
              )[0],
            goingDepartureTerminal:
              itinerary.Leg[0].Segements[0].departureAirport.Terminal,
            goingDepartureDate: getFormattedDate(
              itinerary.Leg[0].departureDate
            ),
            goingArrivalAirport:
              itinerary.Leg[0].Segements[0].departureAirport.airportName,
            goingArrivalTime:
              itinerary.Leg[0].Segements[0].flights[0].arrivalTime.split(
                " "
              )[0],
            goingArrivalTerminal:
              itinerary.Leg[0].Segements[0].departureAirport.Terminal,
            price: itinerary.passengers[0].price.totalPrice,
            taxPrice: itinerary.passengers[0].pqprice.totalTaxAmount,
            baseFareAmount: itinerary.passengers[0].pqprice.baseFareAmount,
            GoingStop: itinerary.Leg[0].NoOfStops,
            TripType: "OneWay",
            Passengers: itinerary.passengers[0].qty,
            GoingAirlineLogo:
            `${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier.operating}.png`,
            GoingFlightNo:
              itinerary.Leg[0].Segements[0].flights[0].carrier
                .operatingFlightNumber,
            goingDepartureCountry:
              itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
            goingArrivalCountry:
              itinerary.Leg[0].Segements[0].departureAirport.countryCode,
            GoingTotalTime: convertMinsToHrsMins(
              itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1]
                .flights[0].elapsedTime
            ),
          });
        }
        if (data) {
          // console.log(itinerary);
          setPassengerInfo(itinerary);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const callEasyHopPixel = () => {
    fetch("https://stageapi.easyhop.com/v1/Tracking/Conversions", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        partner_id: "EILAGO_com",
        click_Id: click_id,
        transaction_id: BookingRef,
        commission: 0,
        total_booking_value: totalPriceForPixel,
        comm_currency_code: currency,
        bv_currency_code: currency,
        route_info: route,
        pax: passengerQty,
      }),
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  const inputRef = useRef();
  const printDocument = useReactToPrint({
    content: () => inputRef.current,
  });

  var doc = new jsPDF();

  const generatePDF = () => {
    if(document.getElementById('backBtnID')){
    document.getElementById('backBtnID').style.display = "none";
    }
    let pdfHeight;
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme:dark)").matches
    ) {
      document.getElementById("flight_confirmation").style.backgroundColor =
        "#374b5f";
    }
    document.getElementById("passengerDetail").style.marginBottom = "100%";
    document.getElementById("Web_icons").style.display = "none";
    if(document.getElementById("backBtnID")){
    document.getElementById("backBtnID").style.display = "none";
    }

    htmlToImage
      .toPng(document.getElementById("flight_confirmation"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        // pdfHeight = pdfHeight;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${BookingRef}.pdf`);
      });

    setTimeout(() => {
      document.getElementById("passengerDetail").style.marginBottom = "0%";
      document.getElementById("Web_icons").style.display = "block";
      if(document.getElementById("backBtnID")){
      document.getElementById("backBtnID").style.display = "block";
      }
      document.getElementsByTagName("body")[0].style.backgroundImage = "none";
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme:dark)").matches
      ) {
        document.getElementById("flight_confirmation").style.backgroundColor =
          "transparent";
      }
    }, 1000);
    setTimeout(() => {
      if(document.getElementById("backBtnID")){
      document.getElementById('backBtnID').style.display = "none";
      }
    } ,3000)

    
  };

  const [cookieVal , setCookieVal] = useState();
  useEffect(() => {
    // getCookie('omg');
    setCookieVal('omg');
  }, [])

  function getCookie(cValue) {
    var name = cValue + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return c.substring(1);
}
function eraseCookie(name) {
  document.cookie = name + "=" + name + ";expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=; path=/";
}

  useEffect(() => {
      var ssSessionID = getCookie('omg');
      
      if(ssSessionID) {
      // var ssUrl = `https://track.omguk.com/e/si/?APPID=${BookingRef}&MID=2341775&PID=54917&Status=${'Done'}&VCODE=${passengerInfo?.TotalPrice}&CUR=${process.env.REACT_APP_CURRENCY}&EX1=${passengerInfo?.Leg[0]?.Segements[0]?.departureAirport?.countryName === passengerInfo?.Leg[0]?.Segements[passengerInfo?.Leg[0].Segements?.length - 1]?.arrivalAirport?.cityName ? "Domestic" : "Inernational"}`;
      var ssUrl = `https://track.omguk.com/2341775/e/ss/?APPID=${BookingRef}&MID=2341775&PID=54917&Status=1&VCODE=&CUR=${process.env.REACT_APP_CURRENCY}&EX1=${passengerInfo?.Leg[0]?.Segements[0]?.departureAirport?.countryName === passengerInfo?.Leg[0]?.Segements[passengerInfo?.Leg[0].Segements?.length - 1]?.arrivalAirport?.cityName ? "Domestic" : "Inernational"}&transactionValue=${passengerInfo?.TotalPrice}`
     console.log(ssUrl);
      var oJS;
      oJS = document.createElement('script');
      oJS.src = ssUrl;
      document.body.appendChild(oJS);
      }
      eraseCookie("omg");
  }, [passengerInfo, cookieVal]);

 

  return (
    <div
      className="flight_confirmation"
      ref={inputRef}
      dir={locale === "ar" ? "rtl" : "ltr"}
    >
      <img
        src={pixelImg}
        className="pixel"
        alt="pixel"
        width="1"
        height="1"
        border="0"
        style={{ display: "none" }}
      />

      <main className="print_page" id="flight_confirmation">
        {hideTopSection === false ? (
          <div>
            <TopSteps className="top_steps" />

            <BookingConfirmation
              passengerInfo={passengerInfo}
              generatePDF={generatePDF}
              printDocument={printDocument}
            />

            <MobileBookigConfirmation
              generatePDF={generatePDF}
              printDocument={printDocument}
            />
          </div>
        ) : (
          <hr className="line_break" />
        )}

        {isLoading ? <div className="loader"></div> : null}

        <div className="container">
          <div className="row mt-3 column-gap">
            <div className="col-sm-12 col-lg-8 col-md-12 mt-4">
              <AirplaneDepartureIcon />

              <DepartureDetails passengerInfo={passengerInfo} />

              {bookingDetails.TripType === "RoundTrip" && (
                <div>
                  <AirplaneArrivalIcon />

                  <ArrivalDetails passengerInfo={passengerInfo} />
                </div>
              )}

              <BaggageInformation passengerInfo={passengerInfo} />

              <PassengerDetails
                passengerInfo={passengerInfo}
                passengerCount={passengerCount}
                pricingInfo={pricingInfo}
              />

              <WebIcons
                generatePDF={generatePDF}
                printDocument={printDocument}
              />
            </div>

            <div className=" col-sm-12 col-lg-3 col-md-12 mt-4">
              <Sidebar
                pricingInfo={pricingInfo}
                passengerInfo={passengerInfo}
              />
            </div>
          </div>
        </div>

        <BackToTopButton />
      </main>
    </div>
  );
}

export default FlightConfirmation;
