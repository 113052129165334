import {React} from "react"
import SpinnerImg from "../../Components/Spinner/SpinnerImg.svg"

const SpinnerLoader = () => {
    return(
        <div>
            <img src={SpinnerImg} className="spinner " />
        </div>
    )
}
export default SpinnerLoader;