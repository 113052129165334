import "../../css/faq_page.css";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useGlobals } from "../../contexts/globalContexts";
import { useTranslation } from "react-i18next";

function FaqPage() {
  const { lang, locale, setLocale } = useGlobals();
  const navigate = useNavigate();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add("sub_page");
    document.getElementById("root").classList.add("page");
    if(!isMobile){
    document.getElementById("root").classList.add("search_results");
    }
  }, []);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        // navigate(`/en/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");

        setLocale("ar");
        // navigate(`/ar/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, []);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div>
      <div className="row top_bg">
        <div
          className={
            locale === "ar"
              ? "col-md-12 col-lg-12 title_header_arabic"
              : "col-md-12 col-lg-12 title_header"
          }
        >
          <h1>{t("FAQ")}</h1>
        </div>
        <hr className="line_break" />
      </div>

      <div
        className="container mt-5 px-10"
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-8  dls-mb-90">
            <div className="Col-sm-12 col-md-12 col-lg-12 search_bar">
              <input
                type="search"
                id="search"
                style={
                  locale === "ar"
                    ? { paddingRight: "35px" }
                    : { paddingRight: "0px" }
                }
                className="search"
                placeholder={t("Enter Your Question")}
              />
            </div>

            <div className="accordian">
              <div className="div">
                <input
                  type="checkbox"
                  name="faq_accordian"
                  id="faq1"
                  className="faq_input"
                />
                <label
                  htmlFor="faq1"
                  style={
                    locale === "ar"
                      ? { paddingRight: "35px" }
                      : { paddingRight: "0px" }
                  }
                  className="faq_label"
                >
                  {t("Booking Confirmation")}
                </label>
                <div className="faq_content">
                  <p>
                    {t("Booking Confirmation Point 1")} <br />
                    <br />
                    {t("Booking Confirmation Point 2")}
                  </p>
                </div>
              </div>

              <div className="div">
                <input
                  type="checkbox"
                  name="faq_accordian"
                  id="faq2"
                  className="faq_input"
                />
                <label
                  htmlFor="faq2"
                  style={
                    locale === "ar"
                      ? { paddingRight: "35px" }
                      : { paddingRight: "0px" }
                  }
                  className="faq_label"
                >
                  {t("Non-stop Flight")}{" "}
                </label>
                <div className="faq_content">
                  <p>
                    {t("Non-stop Flight Point 1")}
                    <br />
                    <br />

                    {t("Non-stop Flight Point 2")}
                  </p>
                </div>
              </div>

              <div className="div">
                <input
                  type="checkbox"
                  name="faq_accordian"
                  id="faq3"
                  className="faq_input"
                />
                <label
                  htmlFor="faq3"
                  style={
                    locale === "ar"
                      ? { paddingRight: "35px" }
                      : { paddingRight: "0px" }
                  }
                  className="faq_label"
                >
                  {" "}
                  {t("Airport assistance")}{" "}
                </label>
                <div className="faq_content">
                  <p>
                    {t("Airport assitance Point 1")}
                    <br />
                    <br />
                    {t("Airport assitance Point 2")} <br />
                    <br />
                    {t("Airport assitance Point 3")}
                    <br />
                    <br />
                    {t("Airport assitance Point 4")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-sm-12 col-md-4 col-lg-4  side-nav"
            dir={locale === "ar" ? "rtl" : "ltr"}
          >
            <nav className="side_nav">
              <p>{t("Self-Help")}</p>
              <ul>
                <li className="li_options">
                  <Link to={`/${locale}/about_us`}> {t("About Us")} </Link>
                </li>
                <li className="li_options">
                  <Link to={`/${locale}/contact_us`}> {t("Contact Us")} </Link>
                </li>
                <li className="li_options active">
                  <Link to={`/${locale}/faq`}> {t("FAQ")} </Link>
                </li>
                <li className="li_options">
                  <Link to={`/${locale}/terms_conditions`}>
                    {" "}
                    {t("Terms Conditions")}{" "}
                  </Link>
                </li>
                <li className="li_options">
                  <Link to={`/${locale}/privacy_policy`}>
                    {" "}
                    {t("Privacy Policy")}{" "}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
