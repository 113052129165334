import Sa from "../img/sa.svg";
import Bh from "../img/bh.svg";
import Pk from "../img/pk.svg";
import LogoLight from "../img/eilago_logo_dark.svg";
import LogoDark from "../img/d/eilago_logo_dark.svg";

import "../css/no_flights_found.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobals } from "../contexts/globalContexts";
import { useTranslation } from "react-i18next";

function ErrorPage() {
  const { paymentError, bookingErrorMsg, lang, locale, setLocale } =
    useGlobals();
  const { BookingRef } = useParams();

  const [showDropDown, setShowDropDown] = useState(false);

  const showPhoneNumbers = (e) => {
    e.preventDefault();
    setShowDropDown((current) => !current);
  };

  const openEmail = () => {
    window.location = "mailto:support@eilago.com";
  };

  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");

        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");

        setLocale("ar");

        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, []);

  const goHome = () => {
    if (localePath[1] === "en") {
      navigate("/");
    } else {
      navigate("/ar");
    }
  };

  return (
    <div className="search_has_error">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <img src={LogoDark} alt="" className="dark" onClick={goHome} style={{cursor:"pointer"}} />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <img src={LogoLight} alt="" className="light" onClick={goHome} style={{cursor:"pointer"}}  />
          </div>
        </div>
        <div className="row content">
          <div className="col-12 col-sm-12 col-md-12 content_top">
            <p>
              {" "}
              {paymentError} <br />
              <br />
              {paymentError && (
                <span style={{ fontSize: "16px" }}>{bookingErrorMsg}</span>
              )}
              <br />
              <span style={{ fontSize: "16px" }} dir="auto">
                {t("Reference No")}: {BookingRef}
              </span>
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 sad_bird">
              <img src="../img/sad_bird.svg" alt="" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 content_mid"></div>
        </div>
        <div className="row">
          <div className="container pl-0 pr-0 has_buttons">
            <div className="col-12 col-sm-12 col-md-12 sorry ml-0 mr-0">
              <p> {t("Reach Us")} </p>
            </div>
            <div className="row ml-0 mr-0">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 phone-btn pl-0 pr-0">
                <button
                  aria-label="call +966 11520 4955"
                  onClick={
                    isMobile
                      ? (e) => {
                          showPhoneNumbers(e);
                        }
                      : () => {}
                  }
                >
                  <span className="ph_btn_text">+966 11520 4955</span>
                </button>
                <ul className={showDropDown ? "show_numbers" : ""}>
                  <li>
                    <a href="tel:+966 11520 4955">
                      <span>
                        <img src={Sa} alt="" />
                      </span>
                      <span>
                        <small>Saudi Arabia</small>
                        <small>+966 11520 4955</small>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+973 1330 2121">
                      <span>
                        <img src={Bh} alt="" />
                      </span>
                      <span>
                        <small>Bahrain</small>
                        <small>+973 1330 2121</small>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+92 5127 28821">
                      <span>
                        <img src={Pk} alt="" />
                      </span>
                      <span>
                        <small>Pakistan</small>
                        <small>+92 5127 28821</small>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 email-btn pl-0 pr-0">
                <button
                  aria-label="email support@eilago.com"
                  type="button"
                  onClick={() => {
                    openEmail();
                  }}
                >
                  <span className="email_btn_text"> support@eilago.com </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12 bg_cloud_center" />
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
