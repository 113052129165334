import React from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import "../../css/cancel_any_time.css";
import HomePageSS from "../../img/homepage_mobile.svg";
import HomePageSSLight from "../../img/home_page_light.svg";
import FligtDetailsLight from "../../img/flight_details_light.svg";
import HepstarLight from "../../img/hepstar_mobile_light.svg";
import FlightDetails from "../../img/flight_details_mobile.svg";
import HepstarSS from "../../img/hepstar_mobile.svg";
import LightBulb from "../../img/light-bulb.svg";
import GoldCoin from "../../img/gold-coin.svg";
import Rocket from "../../img/rocket.svg";
import PinkBorder from "../../img/Rectangle 1317.svg";
import GoldBorder from "../../img/Rectangle 1316.svg";
import GreenBorder from "../../img/Rectangle 1315.svg";
import HeadingBorder from "../../img/Path 6409.svg";

const CancelAnyTime = () => {
  const { lang, locale, setLocale } = useGlobals();
  const navigate = useNavigate();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }
  }, []);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        navigate(`/en/${localePath[2]}`);
        // document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");

        setLocale("ar");
        document.documentElement.setAttribute("lang", "ar");
        // navigate(`/ar/${localePath[2]}`);
      }
    }, 500);
  }, []);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div className="cancel_any_time" dir={locale === "ar" ? "rtl" : "ltr"}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-5">
            <h2>{t("Cancel Anytime Heading")}</h2>
            <img src={HeadingBorder} className="Heading_border" />

            <p>{t("Top Description")}</p>
            <button className="cancel_btn" onClick={(e) => e.preventDefault()}>
              {t("Cancel Btn")}
            </button>
            <br />
            <small>{t("Exclusively available on Eilago")}</small>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-7 bg-confetti">
            <img src={HomePageSS} className="text_right dark" />
            <img src={HomePageSSLight} className="text_right light" />
          </div>
        </div>
      </div>

      <div className="container_fluid container2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text_center">
              <h4>{t("HOW IT WORKS")}</h4>
              <h1>{t("Cancel Anytime Heading")}</h1>
              <img src={HeadingBorder} className="Heading_border" />
            </div>
          </div>

          <div className="row col_gap">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <svg
                  width="72.237"
                  height="50.998"
                  viewBox="0 0 72.237 50.998"
                  className="svg"
                >
                  <g transform="translate(-55.499 -1831)">
                    <path
                      className="a"
                      d="M25.5,0A25.5,25.5,0,0,0,.009,26.173C.367,40.086,12.155,51,26.073,51H37.546A25.517,25.517,0,0,0,61.9,33.093L72.236.023A.017.017,0,0,0,72.219,0Z"
                      transform="translate(55.499 1831)"
                    />
                    <text
                      className={locale === "ar" ? "b_arabic b" : "b"}
                      transform="translate(76.748 1864)"
                    >
                      <tspan x={0} y={0} className="b">
                        {locale === "ar" ? "١" : "01."}
                      </tspan>
                    </text>
                  </g>
                </svg>
                <br />
                <p>
                  {t("Card1Text1")}
                  <br /> {t("Card1Text2")}
                </p>
                <img src={HomePageSS} className="img_height dark" />
                <img src={HomePageSSLight} className="img_height light" />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <svg
                  width="72.237"
                  height="50.998"
                  viewBox="0 0 72.237 50.998"
                  className="svg"
                >
                  <g transform="translate(-55.499 -1831)">
                    <path
                      className="a"
                      d="M25.5,0A25.5,25.5,0,0,0,.009,26.173C.367,40.086,12.155,51,26.073,51H37.546A25.517,25.517,0,0,0,61.9,33.093L72.236.023A.017.017,0,0,0,72.219,0Z"
                      transform="translate(55.499 1831)"
                    />
                    <text
                      className={locale === "ar" ? "b_arabic b" : "b"}
                      transform="translate(76.748 1864)"
                    >
                      <tspan x={0} y={0} className="b">
                        {locale === "ar" ? "٢" : "02."}
                      </tspan>
                    </text>
                  </g>
                </svg>
                <br />
                <p>
                  {t("Card2Text1")}
                  <br />
                  {t("Card2Text2")}
                </p>
                <img src={FlightDetails} className="img_height dark" />
                <img src={FligtDetailsLight} className="img_height light" />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <svg
                  width="72.237"
                  height="50.998"
                  viewBox="0 0 72.237 50.998"
                  className="svg"
                >
                  <g transform="translate(-55.499 -1831)">
                    <path
                      className="a"
                      d="M25.5,0A25.5,25.5,0,0,0,.009,26.173C.367,40.086,12.155,51,26.073,51H37.546A25.517,25.517,0,0,0,61.9,33.093L72.236.023A.017.017,0,0,0,72.219,0Z"
                      transform="translate(55.499 1831)"
                    />
                    <text
                      className={locale === "ar" ? "b_arabic b" : "b"}
                      transform="translate(76.748 1864)"
                    >
                      <tspan x={0} y={0} className="b">
                        {locale === "ar" ? "٣" : "03."}
                      </tspan>
                    </text>
                  </g>
                </svg>
                <br />
                <p>
                  {t("Card3Text1")}
                  <br />
                  {t("Card3Text2")}
                </p>
                <img src={HepstarSS} className="img_height dark" />
                <img src={HepstarLight} className="img_height light" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text_center mb_120">
              <button
                className="cancel_btn"
                onClick={(e) => e.preventDefault()}
              >
                {t("Cancel Btn")}
              </button>
              <br />
              <small>{t("Exclusively available on Eilago")}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelAnyTime;
