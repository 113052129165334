import HeaderBird from "../../img/green_header_bird.svg";
import ReceiptBlack from "../../img/receipt-black.svg";
import ReceiptWhite from "../../img/receipt-white.svg";
import PrintBlack from "../../img/print-black.svg";
import PrintWhite from "../../img/d/print-white.svg";
import DownloadBlack from "../../img/download-black.svg";
import DownloadWhite from "../../img/d/download-white.svg";
import DownloadWhiteAr from "../../img/download_white_ar.svg";
import PrintWhiteAr from "../../img/print_white_ar.svg";
import ReceiptWhiteAr from "../../img/receipt_white_arabic.svg";
import DownloadDarkAr from "../../img/d/download_d_ar.svg";
import PrintDarkAr from "../../img/d/print_d_ar.svg";
import ReceiptDarkAr from "../../img/d/receipt_d_ar.svg";
import { useGlobals } from "../../contexts/globalContexts";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function MobileBookigConfirmation({ generatePDF, printDocument }) {
  const { BookingRef } = useParams();
  const { createBookingApiData, bookingId, lang, locale } = useGlobals();

  const print = () => {
    window.print();
  };

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <div
        className="container dls-none print_none"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <div className="row bg-green-mob">
          <div className="col-sm-12 text-center">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <img src={HeaderBird} className="header-bird" />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-center">
                <p className="congrats font-18">{t("Congratulations")}!</p>
              </div>

              <div className="col-sm-12 mt-2">
                <p className="font-14">
                  {t("Your Flight Booking Is Confirmed")}
                </p>
              </div>

              <div className="col-sm-12 mt-3">
                <p className="font-14">
                  {t("Booking Reference No")} :{BookingRef}
                </p>
              </div>

              <div className="col-sm-12 mt-3">
                <p className="underlined-text">{t("Send Email")}</p>
              </div>

              <div className="col-sm-12 mt-3 d-flex">
                <img
                  src={locale === "ar" ? ReceiptWhiteAr : ReceiptBlack}
                  className="green-section-images light"
                  onClick={printDocument}
                />
                <img
                  src={locale === "ar" ? ReceiptDarkAr : ReceiptWhite}
                  className="dark"
                  onClick={printDocument}
                />
                <img
                  src={locale === "ar" ? PrintWhiteAr : PrintBlack}
                  className="green-section-images light"
                  onClick={printDocument}
                />
                <img
                  src={locale === "ar" ? PrintDarkAr : PrintWhite}
                  className="dark"
                  onClick={printDocument}
                />
                <img
                  src={locale === "ar" ? DownloadWhiteAr : DownloadBlack}
                  className="green-section-images light"
                  onClick={generatePDF}
                />
                <img
                  src={locale === "ar" ? DownloadDarkAr : DownloadWhite}
                  className="dark"
                  onClick={generatePDF}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileBookigConfirmation;
