import UserPic from "../../img/user_dp.svg";
import UserPicDark from "../../img/d/user_dp.svg";
import Person from "../../img/person.svg";
import { useGlobals } from "../../contexts/globalContexts";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function PassengerDetails({passengerInfo, passengerCount, pricingInfo}) {
  const { passengerData, contactDetails, lang, locale} = useGlobals();


let totalPassengers = Number(pricingInfo.adultQty) + Number(pricingInfo.childQty) + Number(pricingInfo.infantsQty);


let count
if(passengerInfo){
  count = passengerInfo.passengers[0].qty;
}

 let tmp = [];
 for (let i=0; i < count ; i++){
  tmp.push(i);
 }

 let adultText = "Adult";
 let childText = "Child";
 let infantText = "Infant";


 let docText = "Passport Expiry"

 let adultArr = [];
let childArr = [];
let infantArr = [];

for(let i =1 ; i<=pricingInfo?.adultQty ; i++){
  adultArr.push(i);
}

for(let i=1; i<= pricingInfo?.childQty ; i++){
  childArr.push(i);
}

for(let i= pricingInfo?.infantsQty; i >= 1; i--){
  infantArr.push(i);

}



const { t, i18n, ready } = useTranslation();




  return (
    <div>

     <div id="passengerDetail">
  <div className="row print_mt_0 mt-5">
     <div className="col-12 text-center">
       <img src={UserPic} className="light mob-user-img" />
       <img src={UserPicDark} className="dark mob-user-img" />
     </div>
   </div>

   <div className="row">
     <div className="col-12 text-center mt-2">
       <p className="font-18">
         {t('Passenger Information')}{" "}
       </p>
     </div>
   </div>

   {passengerInfo ?  
passengerInfo?.PassengerDetails.map((data, i) =>{
  return(
    <div className="row form-border dps-none mt-3">
    <div className="col-lg-12 col-sm-12 px-3">
      <div className="row">
      <div className="col-lg-1 col-sm-1">
           <img src={Person} />
         </div>

        <div className="col-lg-6 col-sm-6 ">
          <div className="row bg-slategrey">
            <div className="col-lg-8 col-sm-8">
              <p className="font-16 font-bold mob-font-13">
               {passengerInfo ? `${data.givenName} ${data.surName}` : ""}
              </p>
            </div>

            <div className={locale === "ar" ? "col-lg-4 col-sm-4 text-right-arabic " : "col-lg-4 col-sm-4 text-right "}>

                          <p className="font-16 mob-font-13">
                            {data.type === "ADT" && t('Adult')}{" "}
                            {data.type === "ADT" && adultArr.map((data, ind) =>{
                                return(
                                  <span>
                                    {i === ind && 
                                    i+1
                                    }
                                  </span>
                                )
                              })}
                            {data.type === "CNN" && t('Child')}{" "}
                            {data.type === "CNN" && childArr.slice(0,1).map((data, ind) =>{
                                return(
                                  <span>
                                    {
                                  ((String(`${(i+1) - (pricingInfo.adultQty)}`)))
                                    
                                    }
                                  </span>
                                )
                              })}
                            {data.type === "C09" && t('Child')}{" "}
                            {data.type === "C09" && childArr.slice(0,1).map((data, ind) =>{
                                return(
                                  <span>
                                    {
                                  ((String(`${(i+1) - (pricingInfo.adultQty)}`)))
                                    
                                    }
                                  </span>
                                )
                              })}
                            {data.type === "INF" && t('Infant')}{" "}
                            {data.type === "INF" && infantArr.slice(0,1).map((data, ind) =>{
                                return(
                                  <span>
                                    {
                                  ((String(`${(i+1) - (pricingInfo.adultQty + pricingInfo.childQty)}`)))
                                    
                                    }
                                  </span>
                                )
                              })}
                          </p>

            </div>
          </div>
        </div>

        <div className={locale === "ar" ? "col-lg-5 col-sm-5 text-right-arabic mt-2" : "col-lg-5 col-sm-5 text-right mt-2"}>
          <p className="font-bold font-16 mob-font-13">
            {t('Ticket')} # {data.tickectNumber ? data.tickectNumber : t('N/A') }
          </p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <p className="font-18 font-bold ">{t('Passport Details')}</p>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-5">
          <p className="font-16">

            <span className="font-bold">
                          {
                          data.tblPassengerDocument?.type === "I"
                          ? (docText = t('CNIC'))
                          : (docText = t('Passport No'))
                          }
                          : {" "}
                        </span>
            {passengerInfo && data.tblPassengerDocument ? data.tblPassengerDocument.number : null}
          </p>
        </div>

        <div className="col-7">
          <p className="font-16">
            <span className="font-bold font-16">{t('Expiry Date')}:</span>{" "}
            {passengerInfo ?  String(data?.tblPassengerDocument?.expirationDate)?.split('T')[0] : null}

           </p>
        </div>
      </div>
    </div>
  </div>
  )
})
:
 null 
}


   <div className="row form-border dps-none mt-4">
     <div className="col-12">
       <div className="row">
         <div className="col-12">
           <p className="font-18 font-bold ">{t('Contact Details')}</p>
         </div>
       </div>

       <div className="row mt-2">
         <div className="col-5">
           <p className="font-16">
             <span className="font-bold">{t('Email')}: </span> 
             {passengerInfo ? passengerInfo.BookingContact.email : null}
           </p>
         </div>

         <div className="col-7">
           <p className="font-16">
             <span className="font-bold">{t('Phone Number')}:</span> {" "}
             {
              locale === "ar" ?
              <span>{passengerInfo ? `${passengerInfo.BookingContact.countryCode.substring(1,5)}${passengerInfo.BookingContact.phone}+` : null }</span>
              :
              <span>{passengerInfo ? `${passengerInfo.BookingContact.countryCode}${passengerInfo.BookingContact.phone}` : null }</span>
             }
           </p>
         </div>
       </div>
     </div>
   </div>

   {
passengerInfo && passengerInfo.PassengerDetails ?
passengerInfo?.PassengerDetails.map((data, i) => {
   return (
    <div className="row form-border-mob mt-3 dls-none pt-3 pb-2 print_none">
     <div className="col-sm-1 col-2">
       <img src={Person} style={{ height: "34px" }} />
     </div>
     <div className="col-sm-6 col-6">
       <p className="font-bold font-13">
       {passengerInfo ? `${data?.givenName} ${data?.surName}` : ""}
       </p>
     </div>

     <div className={locale === "ar" ? "col-3 col-sm-4 text-right-arabic" : "col-3 col-sm-4 text-right"}>
       <p className="font-bold font-13">{t('Ticket')} #</p>
       <p>{data?.tickectNumber ? data.tickectNumber : t('N/A')}</p>
     </div>

     <div className="col-12 mt-4 px-3">
       <p className="font-bold font-16">{t('Contact Details')}</p>
     </div>

     <div className="col-12 px-3 mt-3">
       <p className="font-13">
         {" "}
         <span className="font-bold font-13">{t('Phone Number')} :</span> 
         <span dir="ltr">{passengerInfo?.BookingContact.countryCode} {passengerInfo?.BookingContact.phone}</span>
       </p>
     </div>

     <div className="col-12 mt-3 px-3">
       <p className="font-bold font-16">{t('Passport Details')}</p>
     </div>

     <div className="col-12 px-3 mt-3">
       <p className="font-13">
         {" "}

         <span className="font-bold">
                          {
                          data.tblPassengerDocument?.type === "I"
                          ? (docText = t('National Identity Card'))
                          : (docText = t('Passport No'))
                          }

                        </span>
         {passengerInfo ? data?.tblPassengerDocument?.number : null}
       </p>
       <p className="font-13">
         <span className="font-bold font-13">{t('Expiry Date')}</span> :{" "}

         {data?.tblPassengerDocument?.expirationDate?.substring(0,10)}
       </p>
     </div>
   </div>
   )
   })
   :
   null
   }
  </div>

    </div>
  );
}

export default PassengerDetails;