import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
import "../../css/why.css";

import WhyOne from "../../img/why_1.svg";
import WhyTwo from "../../img/why_2.svg";
import WhyThree from "../../img/why_3.svg";

function Why() {
  const { lang, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <section className="container-fluid why">
      <div className="row">
        <div className="col-12">
          <h2>{t("Why Eilago")}</h2>
          <p>{t("Why Eilago Description")}</p>
        </div>
      </div>
      <div className="container" dir={locale === "ar" ? "rtl" : "ltr"}>
        <div className="row">
          <div className=" col-12 col-md-4 col-lg-4">
            <div className="why_col">
              <img src={WhyOne} alt="" />
              <div className="row">
                <div className="col-4">
                  <svg
                    width="72.237"
                    height="50.998"
                    viewBox="0 0 72.237 50.998"
                  >
                    <g transform="translate(-55.499 -1831)">
                      <path
                        className="a"
                        d="M25.5,0A25.5,25.5,0,0,0,.009,26.173C.367,40.086,12.155,51,26.073,51H37.546A25.517,25.517,0,0,0,61.9,33.093L72.236.023A.017.017,0,0,0,72.219,0Z"
                        transform="translate(55.499 1831)"
                      />
                      <text
                        className={lang === "ar" ? "b_arabic b" : "b"}
                        transform="translate(76.748 1864)"
                      >
                        <tspan x={0} y={0}>
                          {locale === "ar" ? "١" : "01."}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div className="col-8">
                  <strong>{t("Eilago Coins")}</strong>
                  <p
                    style={
                      locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {t("Eilago Coins Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-12  col-md-4 col-lg-4">
            <div className="why_col">
              <img src={WhyTwo} alt="" />
              <div className="row">
                <div className="col-4">
                  <svg
                    width="72.237"
                    height="50.998"
                    viewBox="0 0 72.237 50.998"
                  >
                    <g transform="translate(-55.499 -1831)">
                      <path
                        className="a"
                        d="M25.5,0A25.5,25.5,0,0,0,.009,26.173C.367,40.086,12.155,51,26.073,51H37.546A25.517,25.517,0,0,0,61.9,33.093L72.236.023A.017.017,0,0,0,72.219,0Z"
                        transform="translate(55.499 1831)"
                      />
                      <text
                        className={lang === "ar" ? "b_arabic b" : "b"}
                        transform="translate(76.748 1864)"
                      >
                        <tspan x={0} y={0}>
                          {locale === "ar" ? "٢" : "02."}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div className="col-8">
                  <strong>{t("Best Rates")}</strong>
                  <p
                    style={
                      locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {t("Best Rates Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-12  col-md-4 col-lg-4">
            <div className="why_col">
              <img src={WhyThree} alt="" />
              <div className="row">
                <div className="col-4">
                  <svg
                    width="72.237"
                    height="50.998"
                    viewBox="0 0 72.237 50.998"
                  >
                    <g transform="translate(-55.499 -1831)">
                      <path
                        className="a"
                        d="M25.5,0A25.5,25.5,0,0,0,.009,26.173C.367,40.086,12.155,51,26.073,51H37.546A25.517,25.517,0,0,0,61.9,33.093L72.236.023A.017.017,0,0,0,72.219,0Z"
                        transform="translate(55.499 1831)"
                      />
                      <text
                        className={lang === "ar" ? "b_arabic b" : "b"}
                        transform="translate(76.748 1864)"
                      >
                        <tspan x={0} y={0}>
                          {locale === "ar" ? "٣" : "03."}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div className="col-8">
                  <strong>{t("Flexible Payments")}</strong>
                  <p
                    style={
                      locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {t("Flexible Payments Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Why;
