import { useEffect, useState } from "react";
import { useGlobals } from "../../contexts/globalContexts";

import "../../css/search_airlines.css";
function SearchResultsAirlines({ searchResults }) {
  const { currency, lang, locale } = useGlobals();
  const [airlines, setAirlines] = useState([]);

  const makeAirlinesPriceTabs = () => {
    let airlinesPriceArrayUnsorted = [];
    let airlinesPriceArray = [];
    if (searchResults.length !== 0) {
      for (let i = 0; i < searchResults.length; i++) {
        airlinesPriceArrayUnsorted.push({
          ItineraryId: searchResults[i].Itineraries[0].ItineraryId,
          airlineCode:
            searchResults[i].Itineraries[0].Leg[0].Segements[0].flights[0]
              .carrier.marketing,
          airlinePrice: searchResults[i].price,
        });
      }
      airlinesPriceArrayUnsorted
        .filter((value, index, self) => {
          return (
            self.findIndex((v) => v.airlineCode === value.airlineCode) === index
          );
        })
        .map((ele) => {
          airlinesPriceArray.push({
            ItineraryId: ele.ItineraryId,
            airlineCode: ele.airlineCode,
            airlinePrice: ele.airlinePrice,
          });
        });
      setAirlines(airlinesPriceArray);
    }
  };

  const makeAirlineSelection = (e) => {
    let selectedPrice = e.currentTarget.dataset.aprice;
    let selectedAirline = e.currentTarget.dataset.aprice;

    var searchAirlinesList = document.querySelectorAll(
      ".search_airlines ul li"
    );

    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
        itineraries.classList.add("hide");
        if (
          itineraries.dataset.price !== selectedPrice &&
          itineraries.dataset.airline !== selectedAirline
        ) {
          itineraries.classList.toggle("hide");
        }
      });
    } else {
      for (let i = 0; i < searchAirlinesList.length; i++) {
        searchAirlinesList[i].classList.remove("active");
      }
      e.currentTarget.classList.add("active");
      document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          itineraries.classList.remove("hide");
          if (
            itineraries.dataset.price !== selectedPrice &&
            itineraries.dataset.airline !== selectedAirline
          ) {
            itineraries.classList.toggle("hide");
          }
        });
      });
    }
  };

  useEffect(() => {
    makeAirlinesPriceTabs();
  }, [searchResults]);

  return (
    <div
      className={
        locale === "ar"
          ? "search_airlines_arabic container pr-0"
          : "search_airlines container pr-0"
      }
    >
      {searchResults?.length !== 0 ? (
        <ul>
          {searchResults
            ? airlines.map((airline, index) => {
                return (
                  <li
                    key={airline.ItineraryId + "_" + index}
                    onClick={(e) => makeAirlineSelection(e)}
                    data-aprice={airline.airlinePrice}
                    data-acode={airline.airlineCode}
                  >
                    <div className={airline.ItineraryId}>
                      <div className="airlines">
                        <div
                          className="airline_logo"
                          style={{
                            backgroundImage:
                            `url(${window.location.origin}/img/airlines/${airline.airlineCode}.png)`,
                          }}
                        ></div>
                        <div className="airlines_name_price">
                          <span>{airline.airlineCode}</span>
                          <strong>
                            {currency} <b>{airline.airlinePrice}</b>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            : null}
        </ul>
      ) : (
        <ul>
          <li className="skeleton">
            <div className="airlines">
              <div className="airline_logo"> &nbsp; </div>
            </div>
            <div className="airlines_name_price">
              <span> &nbsp; </span>
              <strong> &nbsp; </strong>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}

export default SearchResultsAirlines;
