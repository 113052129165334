import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../css/capture_payment.css";
import { useGlobals } from "../contexts/globalContexts";

// Make an API call to TAP pay charges API with tap_id. This page should take tap_id in get method
//https://api.tap.company/v2/charges/tap_id
function CapturePayment() {
  const navigate = useNavigate();
  const { BookingRefNum, locale } = useParams();

  const {
    siteURLS,
    bookingRef,
    setPaymentError,
    bookingErrorMsg,
    setBookingErrorMsg,
    lang,
    provideID,
  } = useGlobals();

  const search = useLocation().search;


  const [providerID, setProviderId] = useState();
  const [BookingRef, setBookingRef] = useState();
  const [chargeId , setChargeID] = useState();

useEffect(() => {
  if(new URLSearchParams(search).get("providerID")){
    setProviderId(Number(new URLSearchParams(search).get("providerID")));
  }
  if(new URLSearchParams(search).get("tap_id")){
    _id = new URLSearchParams(search).get("tap_id");
    setChargeID(new URLSearchParams(search).get("tap_id"));
  }
  else{
    setProviderId(1);
  }
}, [])

const [callAPI , setCallAPI] = useState(false);

// http://localhost:3000/capturepayment/en?BookingRef=ELFD559990&providerID=1&tap_id=chg_TS01A4720231136Zj890606360
useEffect(() => {
  if (providerID === 1) {
    setChargeID(new URLSearchParams(search).get("tap_id"));
  } 
  else {
    setChargeID(new URLSearchParams(search).get("charge_id"));
  }
  setBookingRef(new URLSearchParams(search).get("BookingRef"))
  if(providerID && chargeId){
    setCallAPI(true);
  }

}, [providerID, chargeId]);

  let _id;
 
  const [isLoading, setIsloading] = useState(false);

  const [errorMsg, setErrorMsg] = useState();
  let Locale;



  useEffect(() => {
    fixSearchPage();
    
    if(callAPI === true){
        setIsloading(true);
    fetch(`${process.env.REACT_APP_BOOKING_URL}/Booking/CreateBooking`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        PaymentID: chargeId,
        ProviderID: providerID,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.hasError === false) {
          let booking_ref = data.responseModel.bookingRef;
    
          navigate(
            `/${locale}/flight_confirmation/${data.responseModel.bookingRef}`
          );
        } else if (data.hasError === true && data.httpStatusCode === 500) {
          setPaymentError(data?.error?.errorCode);
          setBookingErrorMsg(data?.error?.errorCode);
          if(locale){
            navigate(`/${locale}/error_page/${BookingRef}`);
          }
          else{
            navigate(`/en/error_page/${BookingRef}`);
          }
        } else {
          setBookingErrorMsg(data?.error.errors[1] && data?.error?.errors[1]);
          setPaymentError(data?.error.message);
          if(locale){
            navigate(`/${locale}/error_page/${BookingRef}`);
          }
          else{
            navigate(`/en/error_page/${BookingRef}`);
          }
        }
        setIsloading(false);
        localStorage.clear();
      })
      .catch((err) => console.log(err));
    }
  }, [callAPI]);

  const fixSearchPage = () => {
    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", "background-image:none");
    document
      .getElementsByTagName("main")[0]
      .setAttribute("class", "page search_results");
  };

  return (
    <div>
      <main>
        <hr className="hr" />
        {isLoading ? (
          <div className="loader__Overlay">
            <div className="loader"></div>
          </div>
        ) : null}
        <p>
          Please wait while we process your booking! <br />
        </p>
      </main>
    </div>
  );
}

export default CapturePayment;
