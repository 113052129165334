import { useGlobals } from "../../contexts/globalContexts";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";
import SmallBird from "../../img/small_bird.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SearchResultSnippet({
  searchResult,
  showFlightDetails,
  getItineraries,
}) {
  const {
    searchType,
    setBookingDetails,
    searchId,
    bookingRef,
    setBookingRef,
    isLoading,
    setIsloading,
    noBookingsMsg,
    setNoBookingsMsg,
    siteURLS,
    lang,
    locale,
    setLocale,
    segArray,
    setSegArray,
    resetAllFiltersApply,
    resetAllFilters, filtersArray
  } = useGlobals();

  let navigate = useNavigate();

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: 'short',
    });
    return dt;

    return date;
  };

  const getTheStops = (s, n) => {
    let stopsArr = [];
    for (let i = 0; i < s.length; i++) {
      stopsArr.push(s[i].arrivalAirport.airportCode);
    }
    stopsArr.splice(n);
    let showStops = stopsArr.map((stopsAr, index) => {
      return <span key={index}>{stopsAr}</span>;
    });
    return showStops;
  };

  const getAllTheStops = (s, n) => {
    let stopsArr = [];
    for (let i = 0; i < s.length; i++) {
      stopsArr.push([
        {
          arrival: s[i].arrivalAirport.airportCode,
          departure: s[i].departureAirport.airportCode,
        },
      ]);
    }
    stopsArr.splice(n);
    let showStops = stopsArr.map((stopsAr, index) => {
      return (
        <span key={index}>
          {stopsAr.map((stopsA, index) => {
            return <div key={index}>{stopsA.arrival}</div>;
          })}
        </span>
      );
    });
    return showStops;
  };

  const getTotalDuration = (s) => {
    let flight = [];
    let totalTime = [];
    for (let i = 0; i < s.length; i++) {
      flight.push(s[i].flights[0].elapsedTime);
    }
    for (let j = 0; j < flight.length; j++) {
      totalTime.push(flight[j]);
    }
    return convertMinsToHrsMins(totalTime.reduce((a, b) => a + b, 0));
  };

  let BookingRef = "";

  const BookNow = (val) => {


    document.body.classList.remove("stop_overflow");
    document.getElementById("root").classList.remove("search_results");
    document.getElementById("root").classList.add("passenger_information");
    setIsloading(true);


    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/SaveItinerary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SearchId: searchId,
        ItineraryKey: val,
        UtmParams: {
          source: isMobile ? "MobileWeb" : "Web",
          medium: "Eilago",
          campaign: "Direct",
          term: "Updated",
          content: "website",
          ClickId: "",
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.responseModel.bookingRef) {
          setBookingRef(data.responseModel.bookingRef);
          BookingRef = data.responseModel.bookingRef;
        }
        setIsloading(false);
        navigate(`/${locale}/passenger_information/${BookingRef}`);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (noBookingsMsg) {
      setTimeout(() => {
        setNoBookingsMsg(false);
      }, 3000);
    }
  }, []);

  const calcPassengersQty = (psngrArray) => {
    let totalPsngrs = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      totalPsngrs += psngrArray[i].qty;
    }
    return totalPsngrs;
  };

  const makeItiniraryArray = () => {
    let bigItinArr = [];
    searchResult.Itineraries.map((iten) => {
      for (let i = 0; i < iten.Leg.length; i++) {
        for (let j = 0; j < iten.Leg[i].Segements.length; j++) {
          bigItinArr.push({
            arrival_next_day: [
              iten.Leg[i].Segements[j].flights[0].arrivalDateAdjustment,
            ],
          });
        }
      }
    });
    setSegArray(bigItinArr);
  };

  useEffect(() => {
    makeItiniraryArray();
  }, [searchResult]);

  let totSegs;
  useEffect(() => {
    let segsArr = [];
    for (let i = 0; i <= segArray?.length; i++) {
      totSegs = Number(segArray[i]?.arrival_next_day);
      totSegs += Number(totSegs);
    }
  }, [segArray]);

  const { t, i18n, ready } = useTranslation();

  const [showAllVal, setShowAllVal] = useState(false);

  const moreOptionValue = (e) => {
    if (e.target.parentNode.parentNode.classList[3] === "show_all") {
      setShowAllVal(true);
    } else {
      setShowAllVal(false);
    }
  };








  useEffect(() => {
    if(resetAllFilters === true){
      setShowAllVal(false);
    }
  }, [resetAllFilters])





  let date1;
  let date2;
  let diffDays;

  const getDiffDays = (date1, date2) => {
    diffDays = parseInt((new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24), 10);
    return diffDays;
  }

  return (
    <div>
      {isLoading ? <div className="loader"></div> : null}

      {noBookingsMsg ? (
        <div className="card">
          <h2>No Record Found</h2>
        </div>
      ) : null}
      <div
        className={
          searchType === "OneWay"
            ? searchResult.Itineraries.length > 1
              ? "search_result_snippet multiple oneWay"
              : "search_result_snippet oneWay"
            : searchResult.Itineraries.length > 1
              ? "search_result_snippet multiple roundTrip"
              : "search_result_snippet"
        }
        id={searchResult.groupKey}
        data-triptype={searchType === "OneWay" ? "oneway" : ""}
        data-price={searchResult.price}
        data-airline={
          searchResult.Itineraries[0].Leg[0].Segements[0].flights[0].carrier
            .marketingFlightName
        }
        data-stops={
          searchResult.Itineraries[0].Leg.length !== 2
            ? searchResult.Itineraries[0].Leg[0].NoOfStops
            : searchResult.Itineraries[0].Leg[0].NoOfStops !== "0" ||
              searchResult.Itineraries[0].Leg[1].NoOfStops !== "0"
              ? Number(searchResult.Itineraries[0].Leg[0].NoOfStops) +
              Number(searchResult.Itineraries[0].Leg[1].NoOfStops) / 2
              : 0
        }
        data-stops-same={
          searchResult.Itineraries[0].Leg.length === 1
            ? searchResult.Itineraries[0].Leg[0].NoOfStops
            : 
            // searchResult.Itineraries[0].Leg[0].NoOfStops !== "0" &&
            //   searchResult.Itineraries[0].Leg[1].NoOfStops !== "0" &&
            Number(searchResult.Itineraries[0].Leg[0].NoOfStops) ===
            Number(searchResult.Itineraries[0].Leg[1].NoOfStops)
              && Number(searchResult.Itineraries[0].Leg[0].NoOfStops)
              // : 0
        }
        data-refundable={
          searchResult.Itineraries[0].passengers[0].refundability ===
            "REFUNDABLE"
            ? "ref"
            : 0
        }
        data-departure-time={
          searchType === "OneWay"
            ? searchResult.Itineraries[0].Leg[0].Segements[0].flights[0].departureTime.split(
              " "
            )[0]
            : searchResult.Itineraries[0].Leg[0].Segements[0].flights[0].departureTime.split(
              " "
            )[0]
        }
        data-arrival-time={
          searchType === "OneWay"
            ? searchResult.Itineraries[0].Leg[0].Segements[searchResult.Itineraries[0].Leg[0].Segements.length - 1].flights[0].arrivalTime.split(
              " "
            )[0]
            : searchResult.Itineraries[0].Leg[0].Segements[searchResult.Itineraries[0].Leg[0].Segements.length - 1].flights[0].arrivalTime.split(
              " "
            )[0]
        }
      >
        {searchResult.Itineraries.map((itinerary, index) => {
          return (
            <div
              className="itinerary"
              data-departure-timee={
                searchType === "OneWay"
                  ? itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                    " "
                  )[0]
                  : itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                    " "
                  )[0]
              }
              data-arrival-timee={
                searchType === "OneWay"
                  ? itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1].flights[0].arrivalTime.split(
                    " "
                  )[0]
                  : itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1].flights[0].arrivalTime.split(
                    " "
                  )[0]
              }
              key={itinerary.ItineraryId}
              onClick={
                isMobile
                  ? () => {
                    showFlightDetails(itinerary);
                    if (searchType === "RoundTrip") {
                      setBookingDetails({
                        ItineraryId: itinerary.ItineraryId,
                        itineraryKey: itinerary.ItineraryKey,
                        GoingDepartureAirport:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .airportCode,
                        goingAirline:
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .marketing,
                        goingDepartureTime:
                          itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                            " "
                          )[0],
                        goingDepartureTerminal:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .Terminal,
                        goingDepartureDate: getFormattedDate(
                          itinerary.Leg[0].departureDate
                        ),
                        goingArrivalAirport:
                          itinerary.Leg[1].Segements[0].departureAirport
                            .airportCode,
                        goingArrivalTime:
                          itinerary.Leg[1].Segements[0].flights[0].arrivalTime.split(
                            " "
                          )[0],
                        goingArrivalDate: getFormattedDate(
                          itinerary.Leg[1].arrivalDate),
                        goingArrivalTerminal:
                          itinerary.Leg[1].Segements[0].departureAirport
                            .Terminal,
                        ReturnDepartureAirport:
                          itinerary.Leg[1].Segements[0].departureAirport
                            .airportCode,
                        ReturnAirline:
                          itinerary.Leg[1].Segements[0].flights[0].carrier
                            .marketing,
                        ReturnDepartureDate: getFormattedDate(
                          itinerary.Leg[1].departureDate
                        ),
                        ReturnDepartureTime:
                          itinerary.Leg[1].Segements[0].flights[0].departureTime.split(
                            " "
                          )[0],
                        ReturnDepartureTerminal:
                          itinerary.Leg[1].Segements[0].departureAirport
                            .Terminal,
                        ReturnArrivalAirport:
                          itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].arrivalAirport.airportCode,
                        ReturnArrivalTime:
                          itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].flights[0].arrivalTime.split(" ")[0],
                        ReturnArrivalTerminal:
                          itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].arrivalAirport.Terminal,
                        ReturnArrivalDate: getFormattedDate(
                          itinerary.Leg[1].departureDate
                        ),
                        price: itinerary.TotalPrice,
                        taxPrice:
                          itinerary.passengers[0].price.totalTaxAmount,
                        baseFareAmount:
                          itinerary.passengers[0].price.baseFareAmount,
                        GoingStop: itinerary.Leg[0].NoOfStops,
                        ReturnStop: itinerary.Leg[1].NoOfStops,
                        TripType: "RoundTrip",
                        Passengers: itinerary.passengers[0].qty,
                        GoingAirlineLogo:
                          "img/airlines/" +
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .marketing +
                          ".png",
                        GoingFlightNo:
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .operatingFlightNumber,
                        ReturnFlightNo:
                          itinerary.Leg[1].Segements[0].flights[0].carrier
                            .operatingFlightNumber,
                        ReturnAirlineLogo:
                          "img/airlines/" +
                          itinerary.Leg[1].Segements[0].flights[0].carrier
                            .marketing +
                          ".png",
                        goingDepartureCity:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .cityCode,
                        goingArrivalCity:
                          itinerary.Leg[0].Segements[0].arrivalAirport
                            .cityCode,
                        ReturnDepartureCity:
                          itinerary.Leg[1].Segements[0].departureAirport
                            .cityCode,
                        ReturnArrivalCity:
                          itinerary.Leg[1].Segements[0].arrivalAirport
                            .cityCode,
                        goingDepartureCountry:
                          itinerary.Leg[0].Segements[0].arrivalAirport
                            .countryCode,
                        goingArrivalCountry:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .countryCode,
                        ReturnDepartureCountry:
                          itinerary.Leg[1].Segements[0].departureAirport
                            .countryCode,
                        ReturnArrivalCountry:
                          itinerary.Leg[1].Segements[0].arrivalAirport
                            .countryCode,
                        ReturnTotalTime: convertMinsToHrsMins(
                          itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].flights[0].elapsedTime
                        ),
                        GoingTotalTime: convertMinsToHrsMins(
                          itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].flights[0].elapsedTime
                        ),
                        baggageProvision: itinerary.passengers.map(
                          (passenger) => {
                            return passenger.baggage.map((baggag, index) => {
                              return baggag.provisionType
                                ? baggag.provisionType
                                : null;
                            });
                          }
                        ),
                        baggageWeight: itinerary.passengers.map(
                          (passenger) => {
                            return passenger.baggage.map((baggag, index) => {
                              return baggag.weight ? baggag.weight : null;
                            });
                          }
                        ),
                      });
                    } else {
                      setBookingDetails({
                        ItineraryId: itinerary.ItineraryId,
                        itineraryKey: itinerary.ItineraryKey,
                        GoingDepartureAirport:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .airportCode,
                        goingAirline:
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .marketing,
                        goingDepartureTime:
                          itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                            " "
                          )[0],
                        goingDepartureTerminal:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .Terminal,
                        goingDepartureDate: getFormattedDate(
                          itinerary.Leg[0].departureDate
                        ),
                        goingArrivalAirport:
                          itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].arrivalAirport.airportCode,
                        goingArrivalTime:
                          itinerary.Leg[0].Segements[0].flights[0].arrivalTime.split(
                            " "
                          )[0],
                        goingArrivalTerminal:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .Terminal,
                        price: itinerary.TotalPrice,
                        GoingStop: itinerary.Leg[0].NoOfStops,
                        TripType: "OneWay",
                        Passengers: itinerary.passengers[0].qty,
                        GoingAirlineLogo:
                          "url(img/airlines/" +
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .marketing +
                          ".png)",
                        GoingFlightNo:
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .operatingFlightNumber,
                        goingDepartureCountry:
                          itinerary.Leg[0].Segements[0].arrivalAirport
                            .countryCode,
                        goingDepartureCountry:
                          itinerary.Leg[0].Segements[0].arrivalAirport
                            .countryCode,
                        goingArrivalCountry:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .countryCode,
                        goingArrivalCountry:
                          itinerary.Leg[0].Segements[0].departureAirport
                            .countryCode,
                        GoingTotalTime: convertMinsToHrsMins(
                          itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].flights[0].elapsedTime
                        ),
                        taxPrice:
                          itinerary.passengers[0].price.totalTaxAmount,
                        baggageProvision: itinerary.passengers.map(
                          (passenger) => {
                            return passenger.baggage.map((baggag, index) => {
                              return baggag.provisionType
                                ? baggag.provisionType
                                : null;
                            });
                          }
                        ),
                        baggageWeight: itinerary.passengers.map(
                          (passenger) => {
                            return passenger.baggage.map((baggag, index) => {
                              return baggag.weight ? baggag.weight : null;
                            });
                          }
                        ),
                      });
                    }
                  }
                  : () => { }
              }
            >
              <div className="badges_and_promos">
                <div className="badges">
                  {itinerary.FastestFlight === true ? (
                    <span className="badge fast">{t("Fastest")}</span>
                  ) : null}
                  {itinerary.CheapestFlight === true ? (
                    <span className="badge cheap">{t("Cheapest")}</span>
                  ) : null}
                  {itinerary.passengers[0].refundability === "REFUNDABLE" ? (
                    <span className="badge refundable">{t("Refundable")}</span>
                  ) : null}
                </div>
                {itinerary.passengers[0].RevenueModel.Discount.length !== 0 &&
                  itinerary.passengers[0].RevenueModel.Discount[0]
                    .IsPriceDropSavings === true ? (
                  <div
                    className={
                      locale === "ar"
                        ? "promo_arabic promo_clicked_arabic"
                        : "promo promo_clicked"
                    }
                  >
                    <button type="button">
                      {itinerary.passengers[0].RevenueModel.Discount.length !=
                        0 &&
                        itinerary.passengers[0].RevenueModel.Discount[0]
                          .IsStrikeThrough === true
                        ? itinerary.passengers[0].RevenueModel.Discount[0]
                          .DiscountName
                        : null}
                    </button>
                    <div
                      className={
                        locale === "ar"
                          ? "price_drop_savings_arabic"
                          : "price_drop_savings"
                      }
                    >
                      <div
                        className={locale === "ar" ? "avatar_arabic" : "avatar"}
                      >
                        <img
                          src={SmallBird}
                          width="48px"
                          height="48px"
                          className="small_bird"
                          alt=""
                        />
                      </div>
                      <div
                        className={
                          locale === "ar" ? "promo_text_arabic" : "promo_text"
                        }
                      >
                        <p>
                          {itinerary.passengers[0].RevenueModel.Discount
                            .length != 0 &&
                            itinerary.passengers[0].RevenueModel.Discount[0]
                              .IsPriceDropSavings === true
                            ? itinerary.passengers[0].RevenueModel.Discount[0]
                              .DiscountDescription
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flight_row">
                <div className="flight_row_col">
                  <div className="leg">
                    <div
                      className={
                        itinerary.Leg[0].Segements[0].flights.length > 1
                          ? "airline multiple"
                          : "airline"
                      }
                    >
                      <div
                        className="airline_logo"
                        style={{
                          backgroundImage:
                            `url(${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing}.png)`,
                        }}
                      ></div>
                      <div className="airline_name">
                        {
                          itinerary.Leg[0].Segements[0].flights[0].carrier
                            .marketingFlightName
                        }
                      </div>
                    </div>
                    <div
                      style={
                        locale === "ar" && !isMobile
                          ? { textAlign: "left" }
                          : { textAlign: "right" }
                      }
                      className={
                        locale === "ar"
                          ? "the_airport the_airport_alignment"
                          : "the_airport the_airport_alignment"
                      }
                    >
                      <div
                        className={
                          locale === "ar"
                            ? "the_airport_code"
                            : "the_airport_code"
                        }
                      >
                        
                        {itinerary.Leg[0].Segements[0].flights[0].departureTime
                          .split(" ")[0]
                          .substring(0, 5)}
                      </div>
                      <div
                        className={locale === "ar" ? "the_time" : "the_time"}
                      >
                        {
                          itinerary.Leg[0].Segements[0].departureAirport
                            .airportCode
                        }
                      </div>
                      <div
                        className={locale === "ar" ? "the_info" : "the_info"}
                      >
                        <strong>
                          {t("Terminal")}{" "}
                          {
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal
                          }
                        </strong>
                        <span>
                          {getFormattedDate(itinerary.Leg[0].departureDate)}
                        </span>
                      </div>
                    </div>
                    <div
                      className="duration_and_stops"
                      style={
                        isMobile && locale === "ar"
                          ? { marginRight: "-5px" }
                          : { marginRight: "0px", marginLeft: "-8px" }
                      }
                    >
                      <div className="duration">
                        <svg viewBox="0 0 13.109 12.887">
                          <g transform="translate(0 0.25)">
                            <g transform="translate(0.414)">
                              <circle
                                className="a"
                                cx="6.194"
                                cy="6.194"
                                r="6.194"
                              />
                              <path
                                className="a"
                                d="M11.5,6.5V9.462l3.231,2.962"
                                transform="translate(-5.576 -3.269)"
                              />
                            </g>
                            ``
                            <g transform="translate(0 0.202)">
                              <rect
                                className="b"
                                width="13.109"
                                height="12.173"
                                transform="translate(0 0)"
                              />
                            </g>
                          </g>
                        </svg>
                        <div className="total_duration_stops">
                          {getTotalDuration(itinerary.Leg[0].Segements)} -{" "}
                          {itinerary.Leg[0].NoOfStops} {t("Stops")}
                        </div>
                      </div>

                      <div className={locale === "ar" ? "stops" : "stops"}>
                        <span>&nbsp;</span>
                        {searchType === "RoundTrip" &&
                          itinerary.Leg[0].NoOfStops >= 1
                          ? getAllTheStops(
                            itinerary.Leg[0].Segements,
                            itinerary.Leg[0].NoOfStops
                          )
                          : null}
                        {searchType === "OneWay" &&
                          itinerary.Leg[0].NoOfStops >= 1
                          ? getAllTheStops(
                            itinerary.Leg[0].Segements,
                            itinerary.Leg[0].NoOfStops
                          )
                          : null}
                        <span>&nbsp;</span>
                      </div>
                    </div>
                    <div
                      className="the_airport"
                      style={
                        locale === "ar" && !isMobile
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                    >
                      {getDiffDays(getFormattedDate(itinerary.Leg[0].departureDate), getFormattedDate(itinerary.Leg[0].Segements[
                        itinerary.Leg[0].Segements.length - 1
                      ].flights[
                        itinerary.Leg[0].Segements[0].flights.length - 1
                      ].arrivalDateTime.replace(/\s/g, ""))) > 0

                        &&
                        <span
                          className={
                            locale === "ar"
                              ? "nextDayArrival_arabic"
                              : "nextDayArrival"
                          }
                          // title={t("Arrival_1_Day")}
                          title={`Arrival after ${getDiffDays(getFormattedDate(itinerary.Leg[0].departureDate), getFormattedDate(itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].flights[
                            itinerary.Leg[0].Segements[0].flights.length - 1
                          ].arrivalDateTime.replace(/\s/g, "")))} Day`}
                        >
                          {locale === "en" && "+"}
                          {getDiffDays(getFormattedDate(itinerary.Leg[0].departureDate), getFormattedDate(itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].flights[
                            itinerary.Leg[0].Segements[0].flights.length - 1
                          ].arrivalDateTime.replace(/\s/g, "")))}
                          {locale === "ar" && "+"}
                        </span>
                      }




                      {/* {itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1].flights[0].arrivalDateAdjustment >= 1 ? <span className="nextDayArrival" title={'Arrival after + ' + itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1].flights[0].arrivalDateAdjustment + ' Day'}>+{itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1].flights[0].arrivalDateAdjustment}</span> : ''} */}
                      <div className="the_airport_code">
                      {searchType === "RoundTrip" &&
                          itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].flights[0].arrivalTime
                            .split(" ")[0]
                            .substring(0, 5)}
                        {searchType === "OneWay" &&
                          itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].flights[0].arrivalTime
                            .split(" ")[0]
                            .substring(0, 5)}
                      </div>
                      <div className="the_time">
                        

                      {searchType === "RoundTrip" &&
                          itinerary.Leg[1].Segements[0].departureAirport
                            .airportCode}
                        {searchType === "OneWay" &&
                          itinerary.Leg[0].Segements[
                            itinerary.Leg[0].Segements.length - 1
                          ].arrivalAirport.airportCode}

                      </div>
                      <div className="the_info">
                        <strong>
                          {t("Terminal")}{" "}
                          {searchType === "RoundTrip" &&
                            itinerary.Leg[1].Segements[0].departureAirport
                              .Terminal}
                          {searchType === "OneWay" &&
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal}
                        </strong>
                        <span>
                          {searchType === "RoundTrip" &&
                            getFormattedDate(
                              itinerary.Leg[0].Segements[
                                itinerary.Leg[0].Segements.length - 1
                              ].flights[
                                itinerary.Leg[0].Segements[0].flights.length - 1
                              ].arrivalDateTime.replace(/\s/g, "")
                            )}
                          {searchType === "OneWay" &&
                            getFormattedDate(
                              itinerary.Leg[0].Segements[
                                itinerary.Leg[0].Segements.length - 1
                              ].flights[
                                itinerary.Leg[0].Segements[0].flights.length - 1
                              ].arrivalDateTime.replace(/\s/g, "")
                            )}
                        </span>
                      </div>
                    </div>
                  </div>

                  {itinerary.Leg.length > 1 ? (
                    <div className="leg">
                      <div
                        className={
                          itinerary.Leg[0].Segements[0].flights.length > 1
                            ? "airline multiple"
                            : "airline"
                        }
                      >
                        <div
                          className="airline_logo"
                          style={{
                            backgroundImage:
                              `url(${window.location.origin}/img/airlines/${itinerary.Leg[1].Segements[0].flights[0].carrier
                                .marketing}.png)`,
                          }}
                        ></div>
                        <div className="airline_name">
                          {
                            itinerary.Leg[1].Segements[0].flights[0].carrier.marketingFlightName
                          }
                        </div>
                      </div>
                      <div
                        style={
                          locale === "ar"
                            ? { textAlign: "left" }
                            : { textAlign: "right" }
                        }
                        className={
                          locale === "ar"
                            ? "the_airport the_airport_arabic_mr"
                            : "the_airport"
                        }
                      >
                        <div className="the_airport_code">
                          {itinerary.Leg[1].Segements[0].flights[0].departureTime
                            .split(" ")[0]
                            .substring(0, 5)}

                        </div>
                        <div className="the_time">
                          {
                            itinerary.Leg[1].Segements[0].departureAirport
                              .airportCode
                          }
                        </div>
                        <div className="the_info">
                          <strong>
                            {t("Terminal")}{" "}
                            {
                              itinerary.Leg[1].Segements[0].departureAirport
                                .Terminal
                            }
                          </strong>
                          <span>
                            {getFormattedDate(itinerary.Leg[1].departureDate)}
                          </span>
                        </div>
                      </div>
                      <div className="duration_and_stops">
                        <div className="duration">
                          <svg viewBox="0 0 13.109 12.887">
                            <g transform="translate(0 0.25)">
                              <g transform="translate(0.414)">
                                <circle
                                  className="a"
                                  cx="6.194"
                                  cy="6.194"
                                  r="6.194"
                                />
                                <path
                                  className="a"
                                  d="M11.5,6.5V9.462l3.231,2.962"
                                  transform="translate(-5.576 -3.269)"
                                />
                              </g>
                              <g transform="translate(0 0.202)">
                                <rect
                                  className="b"
                                  width="13.109"
                                  height="12.173"
                                  transform="translate(0 0)"
                                />
                              </g>
                            </g>
                          </svg>
                          <div className="total_duration_stops">
                            {getTotalDuration(itinerary.Leg[1].Segements)} -{" "}
                            {itinerary.Leg[1].NoOfStops} {t("Stops")}
                          </div>
                        </div>
                        <div className={locale === "ar" ? "stops" : "stops"}>
                          <span>&nbsp;</span>
                          {itinerary.Leg[1].NoOfStops >= 1
                            ? getAllTheStops(
                              itinerary.Leg[1].Segements,
                              itinerary.Leg[1].NoOfStops
                            )
                            : null}
                          <span>&nbsp;</span>
                        </div>
                      </div>
                      <div
                        style={
                          locale === "ar" && !isMobile
                            ? { textAlign: "right" }
                            : { textAlign: "left" }
                        }
                        className={
                          locale === "ar" ? "the_airport" : "the_airport"
                        }
                      >
                        {/* 
                        {(parseInt(getFormattedDate(
                          itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].flights[
                            itinerary.Leg[1].Segements[0].flights.length - 1
                          ].arrivalDateTime.replace(/\s/g, "")
                        ) - getFormattedDate(itinerary.Leg[1].departureDate)/ (1000 * 60 * 60 * 24), 10))

                        } */}

                        {getDiffDays(getFormattedDate(itinerary.Leg[1].departureDate), getFormattedDate(
                          itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].flights[
                            itinerary.Leg[1].Segements[0].flights.length - 1
                          ].arrivalDateTime.replace(/\s/g, "")
                        )) > 0 &&
                          <span
                            className={
                              locale === "ar"
                                ? "nextDayArrival_arabic"
                                : "nextDayArrival"
                            }
                            // title={t("Arrival_1_Day")}
                            title={`Arrival after ${getDiffDays(getFormattedDate(itinerary.Leg[1].departureDate), getFormattedDate(
                              itinerary.Leg[1].Segements[
                                itinerary.Leg[1].Segements.length - 1
                              ].flights[
                                itinerary.Leg[1].Segements[0].flights.length - 1
                              ].arrivalDateTime.replace(/\s/g, "")
                            ))} Day `}
                          >
                            {locale === "en" && "+"}
                            {
                              getDiffDays(getFormattedDate(itinerary.Leg[1].departureDate), getFormattedDate(
                                itinerary.Leg[1].Segements[
                                  itinerary.Leg[1].Segements.length - 1
                                ].flights[
                                  itinerary.Leg[1].Segements[0].flights.length - 1
                                ].arrivalDateTime.replace(/\s/g, "")
                              ))
                            }
                            {locale === "ar" && "+"}
                          </span>
                        }



                        <div className="the_airport_code">
                          {itinerary.Leg[1].Segements[
                            itinerary.Leg[1].Segements.length - 1
                          ].flights[0].arrivalTime
                            .split(" ")[0]
                            .substring(0, 5)}
                        </div>
                        <div className="the_time">
                          {
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].arrivalAirport.airportCode
                          }

                        </div>
                        <div className="the_info">
                          <strong>
                            {t("Terminal")}{" "}
                            {
                              itinerary.Leg[1].Segements[
                                itinerary.Leg[1].Segements.length - 1
                              ].arrivalAirport.Terminal
                            }
                          </strong>
                          <span>
                            {searchType === "RoundTrip" &&
                              getFormattedDate(
                                itinerary.Leg[1].Segements[
                                  itinerary.Leg[1].Segements.length - 1
                                ].flights[
                                  itinerary.Leg[1].Segements[0].flights.length -
                                  1
                                ].arrivalDateTime.replace(/\s/g, "")
                              )}
                            {searchType === "OneWay" &&
                              getFormattedDate(
                                itinerary.Leg[1].Segements[
                                  itinerary.Leg[1].Segements.length - 1
                                ].flights[
                                  itinerary.Leg[1].Segements[0].flights.length -
                                  1
                                ].arrivalDateTime.replace(/\s/g, "")
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="flight_row_col">
                  <div className="price">
                    {itinerary.passengers[0].refundability === "REFUNDABLE" ? (
                      <div className="refundable">{t("Refundable")}</div>
                    ) : null}
                    {itinerary.passengers[0].RevenueModel.Discount.length !=
                      0 &&
                      itinerary.passengers[0].RevenueModel.Discount[0]
                        .IsStrikeThrough === true ? (
                      <div className="the_old_price">
                        {itinerary.passengers[0].price.currency}{" "}
                        {itinerary.OriginalPrice}
                      </div>
                    ) : null}
                    <div className="the_price">
                      <sup>
                        {searchResult.priceInformation.baseFareCurrency}
                      </sup>{" "}
                      {itinerary.TotalPrice}
                    </div>
                    {itinerary.passengers[0].fareComponents[0].fareSegment
                      .seatsAvailable ? (
                      <div className="seatsAvailable">
                        {
                          itinerary.passengers[0].fareComponents[0].fareSegment
                            .seatsAvailable
                        }{" "}
                        Seats Available
                      </div>
                    ) : null}
                    <br />
                    <small>
                      {t("Price for")} {calcPassengersQty(itinerary.passengers)}{" "}
                      {t("Person")}
                      {calcPassengersQty(itinerary.passengers) > 1 ? "s" : null}
                    </small>
                  </div>
                </div>
              </div>
              {searchType === "OneWay" ? (
                <div className="flight_row"></div>
              ) : null}
              <div className="desk_actions">
                <div className="flight_details">
                  {searchType === "RoundTrip" ? (
                    <button
                      type="button"
                      onClick={() => {
                        showFlightDetails(itinerary);
                        setBookingDetails({
                          ItineraryId: itinerary.ItineraryId,
                          itineraryKey: itinerary.ItineraryKey,
                          GoingDepartureAirport:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .airportCode,
                          goingAirline:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing,
                          goingDepartureTime:
                            itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                              " "
                            )[0],
                          goingDepartureTerminal:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal,
                          goingDepartureDate: getFormattedDate(
                            itinerary.Leg[0].departureDate
                          ),
                          goingArrivalAirport:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .airportCode,
                          goingArrivalTime:
                            itinerary.Leg[1].Segements[0].flights[0].arrivalTime.split(
                              " "
                            )[0],
                          goingArrivalTerminal:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .Terminal,
                          ReturnDepartureAirport:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .airportCode,
                          ReturnAirline:
                            itinerary.Leg[1].Segements[0].flights[0].carrier
                              .marketing,
                          ReturnDepartureDate: getFormattedDate(
                            itinerary.Leg[1].departureDate
                          ),
                          ReturnDepartureTime:
                            itinerary.Leg[1].Segements[0].flights[0].departureTime.split(
                              " "
                            )[0],
                          ReturnDepartureTerminal:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .Terminal,
                          ReturnArrivalAirport:
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].arrivalAirport.airportCode,
                          ReturnArrivalTime:
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].flights[0].arrivalTime.split(" ")[0],
                          ReturnArrivalTerminal:
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].arrivalAirport.Terminal,
                          ReturnArrivalDate: getFormattedDate(
                            itinerary.Leg[1].departureDate
                          ),
                          price: itinerary.TotalPrice,
                          taxPrice:
                            itinerary.passengers[0].price.totalTaxAmount,
                          baseFareAmount:
                            itinerary.passengers[0].price.baseFareAmount,
                          GoingStop: itinerary.Leg[0].NoOfStops,
                          ReturnStop: itinerary.Leg[1].NoOfStops,
                          TripType: "RoundTrip",
                          Passengers: itinerary.passengers[0].qty,
                          GoingAirlineLogo:
                            "img/airlines/" +
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing +
                            ".png",
                          GoingFlightNo:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .operatingFlightNumber,
                          ReturnFlightNo:
                            itinerary.Leg[1].Segements[0].flights[0].carrier
                              .operatingFlightNumber,
                          ReturnAirlineLogo:
                            "img/airlines/" +
                            itinerary.Leg[1].Segements[0].flights[0].carrier
                              .marketing +
                            ".png",
                          goingDepartureCity:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .cityCode,
                          goingArrivalCity:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .cityCode,
                          ReturnDepartureCity:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .cityCode,
                          ReturnArrivalCity:
                            itinerary.Leg[1].Segements[0].arrivalAirport
                              .cityCode,
                          goingDepartureCountry:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .countryCode,
                          goingArrivalCountry:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .countryCode,
                          ReturnDepartureCountry:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .countryCode,
                          ReturnArrivalCountry:
                            itinerary.Leg[1].Segements[0].arrivalAirport
                              .countryCode,
                          ReturnTotalTime: convertMinsToHrsMins(
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].flights[0].elapsedTime
                          ),
                          GoingTotalTime: convertMinsToHrsMins(
                            itinerary.Leg[0].Segements[
                              itinerary.Leg[0].Segements.length - 1
                            ].flights[0].elapsedTime
                          ),
                          baggageProvision: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.provisionType
                                  ? baggag.provisionType
                                  : null;
                              });
                            }
                          ),
                          baggageWeight: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.weight ? baggag.weight : null;
                              });
                            }
                          ),
                        });
                      }}
                    >
                      {t("Flight Details")}?
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        showFlightDetails(itinerary);
                        setBookingDetails({
                          ItineraryId: itinerary.ItineraryId,
                          itineraryKey: itinerary.ItineraryKey,
                          GoingDepartureAirport:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .airportCode,
                          goingAirline:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing,
                          goingDepartureTime:
                            itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                              " "
                            )[0],
                          goingDepartureTerminal:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal,
                          goingDepartureDate: getFormattedDate(
                            itinerary.Leg[0].departureDate
                          ),
                          goingArrivalAirport:
                            itinerary.Leg[0].Segements[
                              itinerary.Leg[0].Segements.length - 1
                            ].arrivalAirport.airportCode,
                          goingArrivalTime:
                            itinerary.Leg[0].Segements[0].flights[0].arrivalTime.split(
                              " "
                            )[0],
                          goingArrivalTerminal:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal,
                          price: itinerary.TotalPrice,
                          GoingStop: itinerary.Leg[0].NoOfStops,
                          TripType: "OneWay",
                          Passengers: itinerary.passengers[0].qty,
                          GoingAirlineLogo:
                            "url(img/airlines/" +
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing +
                            ".png)",
                          GoingFlightNo:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .operatingFlightNumber,
                          goingDepartureCountry:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .countryCode,
                          goingDepartureCountry:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .countryCode,
                          goingArrivalCountry:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .countryCode,
                          goingArrivalCountry:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .countryCode,
                          GoingTotalTime: convertMinsToHrsMins(
                            itinerary.Leg[0].Segements[
                              itinerary.Leg[0].Segements.length - 1
                            ].flights[0].elapsedTime
                          ),
                          taxPrice:
                            itinerary.passengers[0].price.totalTaxAmount,
                          baggageProvision: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.provisionType
                                  ? baggag.provisionType
                                  : null;
                              });
                            }
                          ),
                          baggageWeight: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.weight ? baggag.weight : null;
                              });
                            }
                          ),
                        });
                      }}
                    >
                      {" "}
                      {t("Flight Details")}?{" "}
                    </button>
                  )}
                </div>
                {itinerary.passengers[0].baggage[0].provisionType === "A" ? (
                  <div className="baggage_info">
                    {itinerary.passengers[0].baggage[0].provisionType ===
                      "A" ? (
                      <svg viewBox="0 0 24 24">
                        <path
                          className="a"
                          d="M18.5,8.5a2.005,2.005,0,0,0-2-2h-9a2.005,2.005,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h9a2.006,2.006,0,0,0,2-2Z"
                        />
                        <line
                          className="a"
                          y2="1"
                          transform="translate(8.5 22.5)"
                        />
                        <line
                          className="a"
                          y2="1"
                          transform="translate(15.5 22.5)"
                        />
                        <line
                          className="a"
                          y1="6"
                          transform="translate(13.5 0.5)"
                        />
                        <line
                          className="a"
                          y1="6"
                          transform="translate(10.5 0.5)"
                        />
                        <line
                          className="a"
                          x2="6"
                          transform="translate(9 0.5)"
                        />
                        <line
                          className="a"
                          x2="7"
                          transform="translate(8.5 9.5)"
                        />
                        <line
                          className="a"
                          y2="1"
                          transform="translate(9.5 9.5)"
                        />
                        <path
                          className="a"
                          d="M15.5,18.5a1,1,0,0,1-1,1h-5a1,1,0,0,1-1-1v-3h7Z"
                        />
                        <rect className="b" width="24" height="24" />
                      </svg>
                    ) : (
                      <svg viewBox="0 0 24 24">
                        <path
                          className="a"
                          d="M18.5,8.5a2.005,2.005,0,0,0-2-2h-9a2.005,2.005,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h9a2.006,2.006,0,0,0,2-2Z"
                        />
                        <line
                          className="a"
                          y2="1"
                          transform="translate(8.5 22.5)"
                        />
                        <line
                          className="a"
                          y2="1"
                          transform="translate(15.5 22.5)"
                        />
                        <line
                          className="a"
                          y1="6"
                          transform="translate(13.5 0.5)"
                        />
                        <line
                          className="a"
                          y1="6"
                          transform="translate(10.5 0.5)"
                        />
                        <line
                          className="a"
                          x2="6"
                          transform="translate(9 0.5)"
                        />
                        <line
                          className="a"
                          x2="7"
                          transform="translate(8.5 9.5)"
                        />
                        <line
                          className="a"
                          y2="1"
                          transform="translate(9.5 9.5)"
                        />
                        <path
                          className="a"
                          d="M15.5,18.5a1,1,0,0,1-1,1h-5a1,1,0,0,1-1-1v-3h7Z"
                        />
                        <rect className="b" width="24" height="24" />
                      </svg>
                    )}
                    {
                      itinerary.passengers[0].baggage[0].provisionType === "A"
                        &&
                        itinerary.passengers[0].baggage[0].pieceCount > 0
                        ||
                        itinerary.passengers[0].baggage[0].provisionType === "A" &&
                        itinerary.passengers[0].baggage[0].weight > 0
                        ? (
                          <b>{t("CheckIn Baggage")}</b>
                        ) : (
                          <b>{t("Hand Carry Baggage")}</b>
                        )}
                  </div>
                ) : null}
                {/* <div className="coupon_info">
                <p>
                  Save up to <strong>SAR 6</strong> on Flights, Use Code{" "}
                  <strong>SUMMER</strong>
                </p>
              </div> */}
                <div className="cta">
                  {searchType === "RoundTrip" ? (
                    <button
                      type="button"
                      onClick={() => {
                        setBookingDetails({
                          ItineraryId: itinerary.ItineraryId,
                          itineraryKey: itinerary.ItineraryKey,
                          GoingDepartureAirport:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .airportCode,
                          goingAirline:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing,
                          goingDepartureTime:
                            itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                              " "
                            )[0],
                          goingDepartureTerminal:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal,
                          goingDepartureDate: getFormattedDate(
                            itinerary.Leg[0].departureDate
                          ),
                          goingArrivalAirport:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .airportCode,
                          goingArrivalTime:
                            itinerary.Leg[1].Segements[0].flights[0].arrivalTime.split(
                              " "
                            )[0],
                          goingArrivalTerminal:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .Terminal,
                          ReturnDepartureAirport:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .airportCode,
                          ReturnAirline:
                            itinerary.Leg[1].Segements[0].flights[0].carrier
                              .marketing,
                          ReturnDepartureDate: getFormattedDate(
                            itinerary.Leg[1].departureDate
                          ),
                          ReturnDepartureTime:
                            itinerary.Leg[1].Segements[0].flights[0].departureTime.split(
                              " "
                            )[0],
                          ReturnDepartureTerminal:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .Terminal,
                          ReturnArrivalAirport:
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].arrivalAirport.airportCode,
                          ReturnArrivalTime:
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].flights[0].arrivalTime.split(" ")[0],
                          ReturnArrivalTerminal:
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].arrivalAirport.Terminal,
                          ReturnArrivalDate: getFormattedDate(
                            itinerary.Leg[1].departureDate
                          ),
                          price: itinerary.TotalPrice,
                          taxPrice:
                            itinerary.passengers[0].price.totalTaxAmount,
                          baseFareAmount:
                            itinerary.passengers[0].price.baseFareAmount,
                          GoingStop: itinerary.Leg[0].NoOfStops,
                          ReturnStop: itinerary.Leg[1].NoOfStops,
                          TripType: "RoundTrip",
                          Passengers: itinerary.passengers[0].qty,
                          GoingAirlineLogo:
                            "img/airlines/" +
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing +
                            ".png",
                          GoingFlightNo:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .operatingFlightNumber,
                          ReturnFlightNo:
                            itinerary.Leg[1].Segements[0].flights[0].carrier
                              .operatingFlightNumber,
                          ReturnAirlineLogo:
                            "img/airlines/" +
                            itinerary.Leg[1].Segements[0].flights[0].carrier
                              .marketing +
                            ".png",
                          goingDepartureCountry:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .countryCode,
                          goingArrivalCountry:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .countryCode,
                          ReturnDepartureCountry:
                            itinerary.Leg[1].Segements[0].departureAirport
                              .countryCode,
                          ReturnArrivalCountry:
                            itinerary.Leg[1].Segements[0].arrivalAirport
                              .countryCode,
                          ReturnTotalTime: convertMinsToHrsMins(
                            itinerary.Leg[1].Segements[
                              itinerary.Leg[1].Segements.length - 1
                            ].flights[0].elapsedTime
                          ),
                          GoingTotalTime: convertMinsToHrsMins(
                            itinerary.Leg[0].Segements[
                              itinerary.Leg[0].Segements.length - 1
                            ].flights[0].elapsedTime
                          ),
                          baggageProvision: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.provisionType
                                  ? baggag.provisionType
                                  : null;
                              });
                            }
                          ),
                          baggageWeight: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.weight ? baggag.weight : null;
                              });
                            }
                          ),
                        });
                        BookNow(itinerary.ItineraryKey);
                      }}
                    >
                      {t("Book Now Button")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        setBookingDetails({
                          ItineraryId: itinerary.ItineraryId,
                          itineraryKey: itinerary.ItineraryKey,
                          GoingDepartureAirport:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .airportCode,
                          goingAirline:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing,
                          goingDepartureTime:
                            itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                              " "
                            )[0],
                          goingDepartureTerminal:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal,
                          goingDepartureDate: getFormattedDate(
                            itinerary.Leg[0].departureDate
                          ),
                          goingArrivalAirport:
                            itinerary.Leg[0].Segements[
                              itinerary.Leg[0].Segements.length - 1
                            ].arrivalAirport.airportCode,
                          goingArrivalTime:
                            itinerary.Leg[0].Segements[0].flights[0].arrivalTime.split(
                              " "
                            )[0],
                          goingArrivalTerminal:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .Terminal,
                          price: itinerary.TotalPrice,
                          GoingStop: itinerary.Leg[0].NoOfStops,
                          TripType: "OneWay",
                          Passengers: itinerary.passengers[0].qty,
                          GoingAirlineLogo:
                            "url(img/airlines/" +
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .marketing +
                            ".png)",
                          GoingFlightNo:
                            itinerary.Leg[0].Segements[0].flights[0].carrier
                              .operatingFlightNumber,
                          goingDepartureCountry:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .countryCode,
                          goingDepartureCountry:
                            itinerary.Leg[0].Segements[0].arrivalAirport
                              .countryCode,
                          goingArrivalCountry:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .countryCode,
                          goingArrivalCountry:
                            itinerary.Leg[0].Segements[0].departureAirport
                              .countryCode,
                          taxPrice:
                            itinerary.passengers[0].price.totalTaxAmount,
                          GoingTotalTime: convertMinsToHrsMins(
                            itinerary.Leg[0].Segements[
                              itinerary.Leg[0].Segements.length - 1
                            ].flights[0].elapsedTime
                          ),
                          baggageProvision: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.provisionType
                                  ? baggag.provisionType
                                  : null;
                              });
                            }
                          ),
                          baggageWeight: itinerary.passengers.map(
                            (passenger) => {
                              return passenger.baggage.map((baggag, index) => {
                                return baggag.weight ? baggag.weight : null;
                              });
                            }
                          ),
                        });
                        BookNow(itinerary.ItineraryKey);
                      }}
                    >
                      {t("Book Now Button")}
                    </button>
                  )}
                </div>
              </div>

              {isMobile ? (
                <div className="price_row">
                  <div className="badges">
                    {itinerary.passengers[0].refundability === "REFUNDABLE" ? (
                      <span className="refundable">{t("Refundable")}</span>
                    ) : (
                      <span className="refundable">{t("Non-Refundable")}</span>
                    )}
                    <div
                      className="baggage"
                      style={
                        locale === "ar" && isMobile
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                    >
                      {itinerary.passengers[0].baggage[0].provisionType ===
                        "A" ? (
                        <svg viewBox="0 0 24 24">
                          <path
                            className="a"
                            d="M18.5,8.5a2.005,2.005,0,0,0-2-2h-9a2.005,2.005,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h9a2.006,2.006,0,0,0,2-2Z"
                          />
                          <line
                            className="a"
                            y2="1"
                            transform="translate(8.5 22.5)"
                          />
                          <line
                            className="a"
                            y2="1"
                            transform="translate(15.5 22.5)"
                          />
                          <line
                            className="a"
                            y1="6"
                            transform="translate(13.5 0.5)"
                          />
                          <line
                            className="a"
                            y1="6"
                            transform="translate(10.5 0.5)"
                          />
                          <line
                            className="a"
                            x2="6"
                            transform="translate(9 0.5)"
                          />
                          <line
                            className="a"
                            x2="7"
                            transform="translate(8.5 9.5)"
                          />
                          <line
                            className="a"
                            y2="1"
                            transform="translate(9.5 9.5)"
                          />
                          <path
                            className="a"
                            d="M15.5,18.5a1,1,0,0,1-1,1h-5a1,1,0,0,1-1-1v-3h7Z"
                          />
                          <rect className="b" width="24" height="24" />
                        </svg>
                      ) : (
                        <svg viewBox="0 0 24 24">
                          <path
                            className="a"
                            d="M18.5,8.5a2.005,2.005,0,0,0-2-2h-9a2.005,2.005,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h9a2.006,2.006,0,0,0,2-2Z"
                          />
                          <line
                            className="a"
                            y2="1"
                            transform="translate(8.5 22.5)"
                          />
                          <line
                            className="a"
                            y2="1"
                            transform="translate(15.5 22.5)"
                          />
                          <line
                            className="a"
                            y1="6"
                            transform="translate(13.5 0.5)"
                          />
                          <line
                            className="a"
                            y1="6"
                            transform="translate(10.5 0.5)"
                          />
                          <line
                            className="a"
                            x2="6"
                            transform="translate(9 0.5)"
                          />
                          <line
                            className="a"
                            x2="7"
                            transform="translate(8.5 9.5)"
                          />
                          <line
                            className="a"
                            y2="1"
                            transform="translate(9.5 9.5)"
                          />
                          <path
                            className="a"
                            d="M15.5,18.5a1,1,0,0,1-1,1h-5a1,1,0,0,1-1-1v-3h7Z"
                          />
                          <rect className="b" width="24" height="24" />
                        </svg>
                      )}

                      {itinerary.passengers[0].baggage[0].provisionType ===
                        "A" ? (
                        <b>{t("CheckIn Baggage")}</b>
                      ) : (
                        <b>{t("Hand Carry Baggage")}</b>
                      )}
                    </div>
                  </div>
                  <div
                    className="price"
                    style={
                      locale === "ar" && isMobile
                        ? { textAlign: "left" }
                        : { textAlign: "right" }
                    }
                  >
                    {itinerary.passengers[0].RevenueModel.Discount.length !=
                      0 ? (
                      <div className="price_striken">
                        {itinerary.passengers[0].price.currency}{" "}
                        {itinerary.OriginalPrice}
                      </div>
                    ) : null}
                    <div className="price_now">
                      {itinerary.passengers[0].price.currency}{" "}
                      {itinerary.TotalPrice}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}

        {searchResult.Itineraries.length > 1 && !isMobile ? (
          <div className="more_options">
            <button
              type="button"
              onClick={(e) => {
                e.target.parentNode.parentNode.classList.toggle("show_all");
                moreOptionValue(e);
              }}
            >
              {" "}
              {locale === "en" && <span> + </span>}
              {searchResult.Itineraries.length - 1}{" "}
              {locale === "ar" && <span> + </span>}
              {t("more options at same price")}

            </button>
          </div>
        ) : null}

        {searchResult.Itineraries.length > 1 &&
          isMobile &&
          searchType === "RoundTrip" ? (
          <div
            className={
              showAllVal === true
                ? "more_options more_options_true"
                : "more_options"
            }
          >
            <button
              type="button"
              onClick={(e) => {
                e.target.parentNode.parentNode.classList.toggle("show_all");
                moreOptionValue(e);
              }}
            >
              {" "}
              {<span style={{ color: "white" }}> + </span>}
              {searchResult.Itineraries.length - 1}{" "}
              {t("more options at same price")}{" "}
            </button>
          </div>
        ) : null}

        {searchResult.Itineraries.length > 1 &&
          isMobile &&
          searchType === "OneWay" ? (
          <div
            className={
              showAllVal === true
                ? "more_options more_options_true_oneway"
                : "more_options more_options_one"
            }
          >
            <button
              type="button"
              onClick={(e) => {
                e.target.parentNode.parentNode.classList.toggle("show_all");
                moreOptionValue(e);
              }}
            >
              {" "}
              {<span style={{ color: "white" }}> + </span>}
              {searchResult.Itineraries.length - 1}{" "}
              {t("more options at same price")}{" "}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SearchResultSnippet;
