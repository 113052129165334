import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function ArrivalSorting() {
  const { searchResults, setArrivalSorting, lang, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <ul>
        <li>
          <label className="form_radio">
            {t("Earliest")}
            <input
              type="radio"
              name="arrival"
              onChange={() => setArrivalSorting("Early")}
              defaultChecked
            />
            <span className="checkmark" />
          </label>
        </li>
        <li>
          <label className="form_radio">
            {t("Latest")}
            <input
              type="radio"
              name="arrival"
              onChange={() => setArrivalSorting("Late")}
            />
            <span className="checkmark" />
          </label>
        </li>
      </ul>
    </div>
  );
}

export default ArrivalSorting;
