import LogoLight from "../../img/eilago_logo_dark.svg";
import LogoDark from "../../img/d/eilago_logo_dark.svg";
import { Link, Router, Routes, useNavigate } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import { useEffect } from "react";
function Logo() {
  const {
    setShowHepar,
    setFromDate,
    setToDate,
    setFromAirport,
    setToAirport,
    setFrom,
    setTo,
    setPassengers,
    setAdults,
    setChildren,
    setInfants,
    showHepar,
    setInsurance,
    insurance,
    setHepstarCalled,
    setHepstarDetails,
    setIsHepstarChecked,
    setBuyHepstar,
    hepstarResult,
    setHepstarResult,
    locale,
    setLocale,
    setNoThanks,
    fromAirport, toAirport, from, to, fromAirportValue, toAirportValue, utmMed
  } = useGlobals();

  let localePath;
  localePath = window.location.pathname.split("/");

  const navigate = useNavigate();

  const clearLocalStorage = () => {
    setShowHepar(false);
    setInsurance(false);
    setHepstarCalled(false);
    setHepstarDetails();
    setIsHepstarChecked(false);
    setBuyHepstar(false);
    setHepstarResult(false);
    setNoThanks(false);
    setFrom(fromAirportValue);
    setTo(toAirportValue);

    if(utmMed === "meta"){
      if (localePath[1] === "ar") {
        window.location.href = "/ar"
        setLocale("ar");
        document.documentElement.setAttribute("lang", "ar");
      } else {
      //   navigate("/");
        setLocale("en");
  
        // navigate("/");
        window.location.href = "/";
        document.documentElement.setAttribute("lang", "en");
      }
    }

    else{
    if (localePath[1] === "ar") {
      navigate("/ar");
      setLocale("ar");
      document.documentElement.setAttribute("lang", "ar");
    } else {
    //   navigate("/");
      setLocale("en");

      navigate("/");
      document.documentElement.setAttribute("lang", "en");
    }
  }
}

  return (
    <span
      className="logo"
      onClick={clearLocalStorage}
      style={{ cursor: "pointer" }}
    >
      <img src={LogoLight} alt="" className="light" />
      <img src={LogoDark} alt="" className="dark" />
    </span>
  );
}

export default Logo;
