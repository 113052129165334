import HeaderBird from "../../img/green_header_bird.svg";
import ReceiptBlack from "../../img/receipt-black.svg";
import ReceiptWhite from "../../img/receipt-white.svg";
import PrintBlack from "../../img/print-black.svg";
import PrintWhite from "../../img/d/print-white.svg";
import DownloadBlack from "../../img/download-black.svg";
import DownloadWhite from "../../img/d/download-white.svg";
import { useGlobals } from "../../contexts/globalContexts";
import { useParams } from "react-router-dom";

function MobileBookigConfirmation({ generatePDF, printDocument }) {
  const { BookingRef } = useParams();
  const { createBookingApiData, bookingId } = useGlobals();

  const print = () => {
    window.print();
  };
  return (
    <div>
      <div
        className="container dls-none print_none"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <div className="row bg-green-mob">
          <div className="col-sm-12 text-center">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <img src={HeaderBird} className="header-bird" />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-center">
                <p className="congrats font-18">Congratulations!</p>
              </div>

              <div className="col-sm-12 mt-2">
                <p className="font-14">Your flight booking is confirmed</p>
              </div>

              <div className="col-sm-12 mt-3">
                <p className="font-14">Booking reference :{BookingRef}</p>
              </div>

              <div className="col-sm-12 mt-3">
                <p className="underlined-text">
                  Send booking confirmation to my email
                </p>
              </div>

              <div className="col-sm-12 mt-3 d-flex">
                <img
                  src={ReceiptBlack}
                  className="green-section-images light"
                  onClick={printDocument}
                />
                <img
                  src={ReceiptWhite}
                  className="dark"
                  onClick={printDocument}
                />
                <img
                  src={PrintBlack}
                  className="green-section-images light"
                  onClick={printDocument}
                />
                <img
                  src={PrintWhite}
                  className="dark"
                  onClick={printDocument}
                />
                <img
                  src={DownloadBlack}
                  className="green-section-images light"
                  onClick={printDocument}
                />
                <img
                  src={DownloadWhite}
                  className="dark"
                  onClick={printDocument}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileBookigConfirmation;
