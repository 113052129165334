import "../../css/about_us.css";
import { useEffect } from "react";

import DestOne from "../../img/dest_one.svg";
import DestTwo from "../../img/dest_two.svg";
import DestThree from "../../img/dest_three.svg";
import DestFour from "../../img/dest_4.svg";
import DestFive from "../../img/dest_five.svg";
import DestSix from "../../img/dest_six.svg";
import DestSeven from "../../img/dest_seven.svg";
import DestEight from "../../img/dest_eight.svg";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
import { useNavigate } from "react-router-dom";

function AboutUsPage() {
  const { lang, locale, setLocale } = useGlobals();
  const navigate = useNavigate();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add("sub_page");
    document.getElementById("root").classList.add("page");
    document.getElementById("root").classList.add("search_results");
  }, []);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        document.documentElement.setAttribute("lang", "en");
        // navigate(`/en/${localePath[2]}`);
      } else {
        changeLanguage("ar");

        setLocale("ar");
        document.documentElement.setAttribute("lang", "ar");
        // navigate(`/ar/${localePath[2]}`);
      }
    }, 500);
  }, []);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div>
      <div className="row top_bg">
        <div
          className={
            locale === "ar"
              ? "col-md-12 col-lg-12 title_header_arabic"
              : "col-md-12 col-lg-12 title_header"
          }
        >
          <h1> {t("About Us")} </h1>
        </div>
        <hr className="line_break" />
      </div>

      <section className="container-fluid about_us">
        <div className="container">
          <div className="row heading">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
              <h2>
                {" "}
                {t("Our Vision")} &mdash; {t("Heading")}
              </h2>
            </div>
          </div>

          <div className="row content">
            <div className=" col-md-2 col-lg-2 mb-4"></div>
            <div className="col-sm-8 col-md-8 col-lg-8 mb-5">
              <p>{t("About Eilago Description Section1")}</p>
            </div>

            <div className=" col-md-2 col-lg-2 mb-4"></div>
          </div>
        </div>

        <div className="row office_destinations">
          <div className="col-1 col-md-1 col-lg-1 "></div>
          <div className="col-2 col-md-2 col-lg-2 ">
            <img src={DestOne} alt="" />
          </div>
          <div className="col-2 col-md-2 col-lg-2 ">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img src={DestTwo} alt="" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img src={DestThree} alt="" />
              </div>
            </div>
          </div>

          <div className="col-2 col-md-2 col-lg-2 ">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 dest_5">
                <img src={DestFour} alt="" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img src={DestFive} alt="" />
              </div>
            </div>
          </div>

          <div className="col-2 col-md-2 col-lg-2 ">
            <img src={DestSix} alt="" />
          </div>
          <div className="col-2 col-md-2 col-lg-2 ">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img src={DestSeven} alt="" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img src={DestEight} alt="" />
              </div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1 "></div>
        </div>

        <div className="row office_destinations_mobile">
          <div className="col-12">
            <img className="destinations" src={DestTwo} alt="" />
            <img className="destinations" src={DestThree} alt="" />
            <img className="destinations" src={DestFour} alt="" />
            <img className="destinations" src={DestFive} alt="" />
            <img className="destinations" src={DestSeven} alt="" />
            <img className="destinations" src={DestEight} alt="" />
            <img className="destinations" src={DestTwo} alt="" />
            <img className="destinations" src={DestFive} alt="" />
          </div>
          <div className="col-sm-6"></div>
        </div>
      </section>
    </div>
  );
}
export default AboutUsPage;
