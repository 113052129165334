import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
import parse from 'html-react-parser';
import '../../css/deals_detail_page.css'
import Searchform from '../SearchForm/SearchForm';

function DealsDetailPage() {
    const { deal_id } = useParams();


    const { lang, locale, setLocale } = useGlobals();
    const { t, i18n, ready } = useTranslation();

    const changeLanguage = (locale) => {
        i18n.changeLanguage(locale);
    };

    let localePath;
    localePath = window.location.pathname.split("/");


    const [dealDetails, setDealDetails] = useState([]);

    const getFormattedDate = (date) => {
        let dt = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          
        });
        return dt;
    
        return date;
      };

    useEffect(() => {

        fetch(`${process.env.REACT_APP_PROMOTIONS}/CMS/GetPromotionDetails?id=${deal_id}`)
            .then((response) => response.json())
            .then((data) => {
                let deals = []
                console.log(data.responseModel)
                setDealDetails(data?.responseModel)
            })


    }, [])
    useEffect(() => {
        document.getElementsByTagName("body")[0].style.backgroundImage = 'none';
        console.log(deal_id);
        window.scrollTo(0, 0);
    document.body.classList.add("sub_page");
    document.getElementById("root").classList.add("page");
    if(!isMobile){
    document.getElementById("root").classList.add("search_results");
    }
    }, []);

    useEffect(() => {
        setTimeout(() => {
          if (localePath[1] === "en") {
            changeLanguage("en");
            setLocale("en");
            document.documentElement.setAttribute("lang", "en");
          } else {
            changeLanguage("ar");
            setLocale("ar");
            document.documentElement.setAttribute("lang", "ar");
          }
        }, 500);
      }, []);
    
      useEffect(() => {
        document.getElementsByClassName("language")[0].style.display = "none";
      }, []);


    return (

        <div className='deals_detail_page'>
            
                    {(locale === 'en' && isMobile) ? <img src={dealDetails?.tileImageURL}   /> : null}
                    {(locale === 'ar' && isMobile) ? <img src={dealDetails?.tileImageURLArabic}   /> : null}
                    
                    {locale === 'en' && !isMobile ? <img src={dealDetails?.imageURL} style={{width:"100vw"}}  /> : null}
                    {locale === 'ar' && !isMobile ? <img src={dealDetails?.imageURLArabic} style={{width:"100vw"}}  /> : null}
            
            <section className="container-fluid home_page">
                <Searchform />
            </section>

            <div className="container">
                <div className="row deal_detail_text">
                    <div className="col col-12 col-md-12 col-lg-12">
                        {dealDetails?.length !== 0 && locale === 'en' ? <h2> {dealDetails?.headingText && parse(dealDetails?.headingText)} </h2> : null}
                        {dealDetails?.length !== 0 && locale === 'ar' ? <h2> {dealDetails?.headingTextArabic && parse(dealDetails?.headingTextArabic)} </h2> : null}
                        {dealDetails?.length !== 0 && locale === 'en' ? dealDetails?.promotionDetails && parse(dealDetails?.promotionDetails) : null}
                        {dealDetails?.length !== 0 && locale === 'ar' ? dealDetails?.promotionDetailsArabic && parse(dealDetails?.promotionDetailsArabic) : null}
                    </div>
                </div>
            </div>
            <section className="container-fluid deal_detail_table_parent">

                <div className="container"><div className="row">
                    <div className="col col-12 col-md-12 col-lg-12 deal_detail_table">
                        <div className="deal_detail_bird_pointing">
                            <img src="../../img/db_1.svg" alt="" />
                        </div>
                        <div className="the_table">
                            <table>
                                <thead>
                                    <tr>
                                        <td>{t('Card Type')}</td><td>{t('Coupon Code')}</td><td>{t('Offer Validity')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t('Credit Cards')}</td><td>{dealDetails?.couponCode}</td><td>{getFormattedDate(dealDetails?.bookingPeriodStartDate)} - {getFormattedDate(dealDetails?.bookingPeriodEndDate)}</td>
                                    </tr>
                                    {dealDetails?.cardOption > 1 ? 
                                    <tr>
                                        <td>{t('Debit Cards')}</td><td>{dealDetails?.couponCode}</td><td>{getFormattedDate(dealDetails?.bookingPeriodStartDate)} - {getFormattedDate(dealDetails?.bookingPeriodEndDate)}</td>
                                    </tr> : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="how_to_avail">
                {dealDetails?.length !== 0 && locale === 'en' ? dealDetails?.availabilityOption && parse(dealDetails?.availabilityOption) : ''}
                {dealDetails?.length !== 0 && locale === 'ar' ? dealDetails?.availabilityOptionArabic && parse(dealDetails?.availabilityOptionArabic) : ''}
            </div>
            <div className="tnc">
                {dealDetails?.length !== 0 && locale === 'en' ? dealDetails?.termsAndConditions && parse(dealDetails?.termsAndConditions) : ''}
                {dealDetails?.length !== 0 && locale === 'ar' ? dealDetails?.termsAndConditionsArabic && parse(dealDetails?.termsAndConditionsArabic) : ''}
            </div>

        </div>
    )
}
export default DealsDetailPage;