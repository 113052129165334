import "../../css/breadcrumbs.css";
import { useGlobals } from "../../contexts/globalContexts";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
function BreadCrumbs({ fromAirport, toAirport }) {
  const { tripType, lang, setLocale, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  let localePath = window.location.pathname.split("/");
  useEffect(() => {
    if (localePath[1] === "en") {
      setLocale("en");
    } else {
      setLocale("ar");
    }
  }, []);

  return (
    <nav
      className={
        locale === "ar"
          ? "breadcrumbs-arabic container-fluid"
          : "breadcrumbs container-fluid"
      }
    >
      <ul className="container">
        <li>
          <a href="#">{t("Home")}</a>
        </li>
        <li>
          <a href="#">{t("Flights")}</a>
        </li>

        {fromAirport !== "" || toAirport !== "" ? (
          <li>
            <a href="#">
              {fromAirport} {tripType === 1 ? " ⇆ " : " → "} {toAirport}
            </a>
          </li>
        ) : null}
      </ul>
    </nav>
  );
}
export default BreadCrumbs;
