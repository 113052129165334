import { Fragment, useState, useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";

import { GlobalContext } from "../../contexts/globalContexts";

import Sa from "../../img/sa.svg";
import Eg from "../../img/eg.svg";
import Kw from "../../img/kw.svg";
import Bh from "../../img/bh.svg";
import Ae from "../../img/ae.svg";
import Pk from "../../img/pk.svg";
import Us from "../../img/us.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Currency() {
  const { selectCurrency, lang, locale } = useContext(GlobalContext);
  const [openCurrencyBox, setOpenCurrencyBox] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const showDropDownMobile = (e) => {
    if (isMobile) {
      e.preventDefault();
      setOpenCurrencyBox((prevCheck) => !prevCheck);
    }
  };

  const { t, i18n, ready } = useTranslation();
  let pathName = window.location.pathname;

  const navigate = useNavigate();

  const openSite = (e) => {
    if (pathName === "/ar") {
      window.location.href = `https://${e}.eilago.com/ar`;
    } else {
      window.location.href = `https://${e}.eilago.com/`;
    }
  };

  return (
    <Fragment>
      <li
        className="header_dropdown"
        onClick={
          isMobile
            ? (e) => {
                showDropDownMobile(e);
              }
            : () => {}
        }
      >
        <a
          //   id="selected_currency"
          //   onClick={selectCurrency}
          //   href="#"
          data-currency={window.location.host === "sa.eilago.com" || window.location.host === "uatui.eilago.com" || window.location.host === "www.eilago.com"  ? "SAR" : window.location.host === "ae.eilago.com" || window.location.host === "uatae.eilago.com" ? "AED" : "BHD"}
        >
          <span>
            <img
              src={window.location.host === "sa.eilago.com" || window.location.host === "uatui.eilago.com" || window.location.host === "www.eilago.com"   ? Sa : window.location.host === "ae.eilago.com" || window.location.host === "uatae.eilago.com" ? Ae : Bh}
              alt=""
              style={
                locale === "ar" && isMobile
                  ? { marginLeft: "10px" }
                  : { marginLeft: "0px" }
              }
            />
          </span>
            <span>{process.env.REACT_APP_CURRENCY}</span>
        </a>
        <ul className={openCurrencyBox ? "is_open" : null}>
          {window.location.host === "www.eilago.com" ||
          window.location.host === "sa.eilago.com" ||
          window.location.host === "ae.eilago.com" ||
          window.location.host === "bh.eilago.com" ? (
            <>
              <li onClick={() => openSite("sa")}>
                <a onClick={selectCurrency} href="#" data-currency="SAR">
                  <span>
                    <img src={Sa} alt="" />
                  </span>
                  <span>SAR</span>
                </a>
              </li>
              <li onClick={() => openSite("ae")}>
                <a onClick={selectCurrency} href="#" data-currency="AED">
                  <span>
                    <img src={Ae} alt="" />
                  </span>
                  <span>AED</span>
                </a>
              </li>
              <li onClick={() => openSite("bh")} style={locale === "ar" ? {marginRight:"-0px"} : {marginRight:"0px"}}>
                <a onClick={selectCurrency} href="#" data-currency="BHD">
                  <span>
                    <img src={Bh} alt="" />
                  </span>
                  <span>BHD</span>
                </a>
              </li>
            </>
          ) : (
            <>
              <li onClick={() => openSite("uatui")}>
                <a onClick={selectCurrency} href="#" data-currency="SAR">
                  <span>
                    <img src={Sa} alt="" />
                  </span>
                  <span>SAR</span>
                </a>
              </li>
              <li onClick={() => openSite("uatae")}>
                <a onClick={selectCurrency} href="#" data-currency="AED">
                  <span>
                    <img src={Ae} alt="" />
                  </span>
                  <span>AED</span>
                </a>
              </li>
              <li onClick={() => openSite("uatbh")} style={locale === "ar" ? {marginRight:"-0px"} : {marginRight:"0px"}}>
                <a onClick={selectCurrency} href="#" data-currency="BHD">
                  <span>
                    <img src={Bh} alt="" />
                  </span>
                  <span>BHD</span>
                </a>
              </li>
            </>
          )}

          {/* <li><a onClick={selectCurrency} href="#" data-currency="PKR">
                        <span><img src={Pk} alt="" /></span>
                        <span>PKR</span>
                    </a></li> */}
        </ul>
      </li>
    </Fragment>
  );
}

export default Currency;
