import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobals } from "../contexts/globalContexts";
import "../css/capture_payment.css";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
//SearchId=FLKVNQVO638071366629955414&ItineraryKey=SB_G1_I1&currency=SAR&utm_source=travolic&utm_medium=meta&utm_campaign=redirect_JED-DXB&utm_term=&metaType=6
function ProcessBooking() {
  const navigate = useNavigate();

  const {
    siteURLS,
    bookingRef,
    setPaymentError,
    lang,
    setLang,
    locale,
    setLocale,
    setUtmMed,
    setTripType
  } = useGlobals();

  const search = useLocation().search;
  const search_id = new URLSearchParams(search).get("SearchId");
  const itinerary_key = new URLSearchParams(search).get("ItineraryKey");
  const utm_source = new URLSearchParams(search).get("utm_source");
  // const utm_medium = new URLSearchParams(search).get("utm_medium");
  let utm_medium;
  if(utm_source === "Web"){
    utm_medium = "Eilago";
  }
  else{
    utm_medium = new URLSearchParams(search).get("utm_medium");
  }
  const utm_campaign = new URLSearchParams(search).get("utm_campaign");
  const utm_term = new URLSearchParams(search).get("utm_term");
  let Locale = new URLSearchParams(search).get("Locale");

  Locale = Locale.toLocaleLowerCase();

  let content = "https//:www.Eilago.com";
  let click_id = "";

  if (utm_source === "Wego") {
    click_id = new URLSearchParams(search).get("wego_click_id");
  }
  if (utm_source === "Skyscanner") {
    click_id = new URLSearchParams(search).get("redirect_id");
  }
  if (utm_source === "EasyHop") {
    click_id = new URLSearchParams(search).get("click_id");
  }
  if (utm_source === "Travolic") {
    click_id = new URLSearchParams(search).get("redirectID");
  }

  const [isLoading, setIsloading] = useState(false);

  const [modalMsg, setModalMsg] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);

  const [eilagoSearchID , setEilagoSearchID] = useState();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    height: "200px",
    width: "200px",
    margin: "0 auto",
    zIndex: 9999,
  };

  const selectLanguage = () => {
    setLocale(Locale);
  };

  let holdSearchId;

  useEffect(() => {
    document.title = `Eilago 2.0 | ${utm_source} `;
    setIsloading(true);

    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/SaveItinerary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SearchId: search_id,
        ItineraryKey: itinerary_key,
        UtmParams: {
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: content,
          ClickId: click_id,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLocale(Locale);
        setUtmMed('meta');
        
        if(data.httpStatusCode !== 200){
        const getIdFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/Search`;
      {
        let payloadToGetSearchId = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            directFlight: data?.requestModel?.directFlight,
            flexibleDates: data?.requestModel?.flexibleDates,
            preferedAirLine: "",
            tripType: data?.requestModel?.tripType,
            partner: 2,
            device: data?.requestModel?.device,
            cabin: data?.requestModel?.cabin,
            passengers: data?.requestModel?.passengers,
            locale: locale.toUpperCase(),
            currencyCode: data?.requestModel?.currencyCode,
            legs: data?.requestModel?.legs,
            sessions: {
              id: "0",
              userType: "Visitor",
              userID: "0",
            },
            utmParameters: {
              source: isMobile ? "MobileWeb" : "Web",
              medium: "Eilago",
              campaign: "Direct",
              term:
              data?.requestModel?.tripType === 1
                ? data?.requestModel?.legs[0]?.departureCode +
                  "," +
                  data?.requestModel?.legs[0]?.arrivalCode +
                  "-" +
                  data?.requestModel?.legs[1]?.departureCode +
                  "," +
                  data?.requestModel?.legs[1]?.arrivalCode
                : data?.requestModel?.legs[0]?.departureCode +
                  "," +
                  data?.requestModel?.legs[0]?.arrivalCode,
            content: "website",
            },
          }),
        };
        setIsloading(true);
        fetch(getIdFrom, payloadToGetSearchId)
          .then((response) => response.json())
          .then((data) => {
            holdSearchId = data.responseModel.searchId;
            setEilagoSearchID(data.responseModel.searchId);
            // console.log('Data', data);
          })
      }
    }

        if (data.responseModel.bookingRef) {
          let BookingRef = data.responseModel.bookingRef;
          console.log(data.responseModel);
          navigate(`/${Locale}/passenger_information/${BookingRef}/${search}`);
          setIsloading(false);
        } else {
          setIsOpen(true);
          setModalMsg(data.error.errors[0]);
          // navigate(`/${lang}`);
          // goBackFunction();
        }
      })
      .catch((err) => {
        setIsOpen(true);
        setModalMsg("Internal Server Error");
        // setModalMsg(err.Message);
        console.log(err);
      });
  }, []);



  const goBackFunction = () => {
    if(eilagoSearchID){

      navigate(`/${locale}/search_results/${eilagoSearchID}`);
    }
    else{
      navigate(`/${locale}`);
    }
  }
  

  return (
    <div>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        {
          <div className="msg">
            <p>{modalMsg}</p>
          </div>
        }

        <div className="row bottom_align1">
          <div className="col-lg-12 text-center">
            <button
              onClick={() => {
                closeModal();
                // navigate("/");
                goBackFunction()
              }}
              className="button "
              aria-label="close"
            >
              Close
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default ProcessBooking;
