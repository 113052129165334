import { useGlobals } from "../../contexts/globalContexts";
import { Link } from "react-router-dom";

import MoreInfoIcon from "../../img/more-info-icon.svg";
import MoreInfoIconDark from "../../img/d/more-info-icon.svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import MobArrowDownLight from "../../img/arrow_down_black.svg";
import MobileArrowDown from "../../img/arrow_down.svg";
import MobArrowUpLight from "../../img/arrow-top-light.svg";
import MobArrowUpDark from "../../img/arrow-top-dark.svg";
import validator from "validator";
import CloseBtn from "../../img/close-btn.svg";
import DarkCloseBtn from "../../img/Group 7713.svg";

function Footer({ makePayment, totalPassengers,emailError , emailValue, numberValue,totalPass, pricingInfo, showHepar,
  email, number
 }) {
  const {
    bookingDetails,
    totalCharges,
    moreInfoToggle,
    setMoreInfoToggle,
    adultQty,
    childQty,
    infantsQty,
    lang,
    locale,currency,  hepstarCalled,
    setHepstarCalled,
    discountedAmount, taxPrice , hepstarData
  } = useGlobals();

  const InfoBox = () => {
    if (moreInfoToggle === false) {
      setMoreInfoToggle(true);
    } else {
      setMoreInfoToggle(false);
    }
  };

  let styling = "";
  if (window.location.pathname.split("/")[2] === "payment_details") {
    styling = {
      marginTop: "0px",
    };
  } else {
    styling = {
      marginTop: "80px",
    };
  }

  const { t, i18n, ready } = useTranslation();


  const makePaymentButton = () => {

  }


  const calcTotalFare = (psngrArray) => {
    let totalfare = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      totalfare += psngrArray[i].price.totalFareAmount;
    }
    return Number(totalfare).toFixed(2);
  };
  
  const calcTotalTax = (psngrArray) => {
    let totalTax = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      totalTax += psngrArray[i].price.totalTaxAmount;
    }
    return Number(totalTax).toFixed(2);
  };
  

  return (
    <div style={styling} dir={locale === "en" ? "ltr" : "rtl"}>
      <div className="container-fluid mob-sticky-footer pb-3 dls-none">
        <div className="row">
        {moreInfoToggle === true ? (
            // <div className="loader__Overlay_sideba " dir={locale === "en" ? "ltr" : "rtl"}>
            //   <div
            //     className="row bg-skyblue dls-none"
            //     style={{
            //       paddingTop: "10px",
            //       paddingBottom: "20px",
            //       paddingLeft: "10px",
            //       paddingRight: "10px",
            //     }}
            //   >
            //     <div className="col-sm-12 col-md-12 col-12 col-lg-12 p-3">
            //       <h6 className="font-12 font-bold dark-clr-black ">
            //         {t("Base Fare")}
            //       </h6>
            //     </div>

            //     <div className="col-sm-7 col-md-6 col-lg-6">
            //       <p className="font-12 mob-font-11 dark-clr-black ">
            //         {totalPass?.passengers.map((data, i) => {
            //           return (
            //             <p
            //               className="font-12 mob-font-11 dark-clr-black "
            //               key={i + 1}
            //             >
            //               {data.type === "ADT" &&
            //                 `${t("Adult")}(s) x ${data.qty}`}
            //               {data.type === "CNN" &&
            //                 `${t("Child")}(s) x ${data.qty}`}
            //               {data.type === "INF" &&
            //                 `${t("Infant")}(s) x ${data.qty}`}
            //             </p>
            //           );
            //         })}
            //       </p>
            //     </div>

            //     <div
            //       className={
            //         locale === "ar"
            //           ? "col-sm-5 col-md-6 col-lg-6 text-right-arabic"
            //           : "col-sm-5 col-md-6 col-lg-6 text-right"
            //       }
            //     >
            //       <p className="font-12 mob-font-11 mob-top-22 dark-clr-black">
            //         {currency} {pricingInfo?.adultPrice}
            //         <br />
            //         {pricingInfo?.childQty > 0 && pricingInfo?.childPrice
            //           ? `${currency} ${pricingInfo.childPrice}`
            //           : ""}
            //         {pricingInfo?.childQty > 0 && pricingInfo?.childPrice ? (
            //           <br />
            //         ) : (
            //           ""
            //         )}
            //         {pricingInfo?.infantsQty > 0 && pricingInfo?.infantPrice
            //           ? `${currency} ${pricingInfo?.infantPrice}`
            //           : ""}
            //       </p>
            //     </div>

            //     <div className="col-sm-6 col-md-6 col-lg-6 ">
            //       <p className="font-12 mob-font-11 dark-clr-black">
            //         {t("Taxes & Fees")}
            //       </p>
            //     </div>

            //     <div
            //       className={
            //         locale === "ar"
            //           ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
            //           : "col-sm-6 col-md-6 col-lg-6 text-right"
            //       }
            //     >
            //       <p className="font-12 mob-font-11 mob-top-22 dark-clr-black">
            //         {currency} {taxPrice}
            //       </p>
            //     </div>

            //     {hepstarCalled === true ? (
            //       <div>
            //         <div className="col-sm-6 col-md-6 col-lg-6 text-orange">
            //           <p className="font-12 mob-font-11  text-orange">
            //             {t("Cancellation Charges")}
            //           </p>
            //         </div>

            //         <div
            //           className={
            //             locale === "ar"
            //               ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
            //               : "col-sm-6 col-md-6 col-lg-6 text-right"
            //           }
            //         >
            //           <p className="font-12 mob-font-11 mob-top-22 text-orange ">
            //             {currency} {hepstarData.totalHepstarAmount}
            //           </p>
            //         </div>
            //       </div>
            //     ) : null}
            //   </div>

            //   {discountedAmount && discountedAmount > 0 ? (
            //     <div>
            //       <div className="row px-3 bg-skyblue dps-none ">
            //         <div className="col-sm-12 border-bottom "></div>
            //       </div>
            //       <div className="row px-1 py-2 bg-skyblue ">
            //         <div className="col-sm-6 col-md-6 col-lg-6">
            //           <p className="font-bold text-orange">{t("Discount")}</p>
            //         </div>

            //         <div
            //           className={
            //             locale === "ar"
            //               ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
            //               : "col-sm-6 col-md-6 col-lg-6 text-right"
            //           }
            //         >
            //           <p className="font-bold text-orange mob-top-22">
            //             {currency} {Number(discountedAmount).toFixed(2)}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //   ) : null}
            // </div>
            <div className="booking_summary">
            <div className="row">
              <div className="col-sm-2 col-2">
                <img src={CloseBtn} className="light" onClick={() => setMoreInfoToggle(false)} />
                <img src={DarkCloseBtn} className="dark" onClick={() => setMoreInfoToggle(false)} />
              </div>
  
              <div className="col-10 col-sm-12">
                <p className="p font-bold font-18">{t('Price Summary')}</p>
              </div>
  
            </div>
  
            <div className="row mt-4">
              <div className="col-sm-8 col-8">
                <p className="green-text lg-textt fon-fold" style={{fontSize:"14px"}}>
                {
                    totalPass?.Leg[0]?.Segements[0]?.departureAirport
                      ?.cityName
                  }{" "} To {' '}
                 {
                    totalPass?.Leg[0]?.Segements[
                      totalPass?.Leg[0]?.Segements?.length - 1
                    ]?.arrivalAirport?.cityName
                  }{' '}
                   ({totalPass.tripType === 0 ? t("One Way") : t("Round Trip")})</p>
                <p className="mt-1 lg-textt" style={{fontSize:"14px"}}>{t('Fare')}</p>
                <p className="mt-1 lg-textt" style={{fontSize:"14px"}}>{t('Taxes')}</p>
                {totalPass.OriginalPrice - totalPass.TotalPrice > 0 &&
                <p className="orange-text mt-1 lg-text" style={{fontSize:"14px"}}>{t('Discount')}</p>
                }
              </div>
  
              <div className="col-4 col-sm-4 text-right">
              <p className="green-text lg-textt" style={{fontSize:"14px"}}>{process.env.REACT_APP_CURRENCY} {' '} {totalCharges && totalCharges
                    ? totalCharges
                    : bookingDetails.price}</p>
                {/* <p className="mt-1">SAR 415</p>
                <p className="mt-1">SAR 469</p>
                <p className="orange-text mt-1">SAR 65</p> */}
                {/* {getItinerariesCopy && getItinerariesCopy[0] === null
                    ? totalPass?.passengers?.map((passenger, i) => {
                        return (
                          <>
                            <p className="mt-1">{process.env.REACT_APP_CURRENCY} {' '} {Number(passenger.price.totalFareAmount).toFixed(
                                2
                              )}
                              </p>
                            <p className="mt-1">
                            {process.env.REACT_APP_CURRENCY}{' '}
                              {Number(passenger.price.totalTaxAmount * passenger.qty).toFixed(2)}
                            </p>
                          </>
                        );
                      })
                    : getItinerariesCopy[0]?.passengers?.map((passenger, i) => {
                        return (
                          <>
                              <p className="mt-1">{passenger.price.totalFareAmount}</p>
                            <p className="mt-1">{process.env.REACT_APP_CURRENCY} {' '} {passenger.price.totalTaxAmount * passenger.qty}</p>
                          </>
                        );
                      })} */}
  
  
  <p className="mt-1 lg-textt" style={{fontSize:"14px"}}>{process.env.REACT_APP_CURRENCY} {calcTotalFare(totalPass.passengers)}</p>
  <p className="mt-1 lg-textt" style={{fontSize:"14px"}}>{process.env.REACT_APP_CURRENCY} {calcTotalTax(totalPass.passengers)}</p>
  {totalPass.OriginalPrice - totalPass.TotalPrice > 0 &&
                <p className="mt-1 orange-text lg-textt" style={{fontSize:"14px"}}>{process.env.REACT_APP_CURRENCY} {' '} {Number(totalPass.OriginalPrice - totalPass.TotalPrice).toFixed(2)}</p> 
  }     
              </div>
            </div>
          </div>
          ) : null}
        </div>
        <div className="row pt-4 top-border-shadow ">
          <div className="col-sm-1 col-1">
          <p className="font-14" onClick={InfoBox}>
              <img
                src={moreInfoToggle === false ?  MobArrowUpLight : MobArrowDownLight}
                style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                className="light more-info-icon ml-2"
                alt="more-info-light"
              />
              <img
                src={moreInfoToggle === false ?  MobArrowUpDark : MobileArrowDown}
                style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                className="dark ml-2"
                alt="more-info-dark"
              />
            </p>
          </div>
          <div className="col-sm-6 col-6">
            <h2 className="font-bold font-24">{currency} {totalCharges}</h2>
            <p className="font-12">
              {t("Price for")} {totalPassengers} {t("Person")}
            </p>
          </div>

          <div className="col-sm-5 col-5 text-right">
            {window.location.pathname.split("/")[2] ===
            "payment_details" ? 
            <button
            aria-label="Make Payment"
            className="payment-btn-mob"
            style={{fontSize:"14px"}}
            onClick={makePayment}
          >
            {t("Make Payment Button")}
          </button>

          //    : 
          //   totalPass && totalPass?.BookingContact?.email !== null && totalPass?.BookingContact?.phone !== null ?
          //   <button
          //   aria-label="Make Payment"
          //   className="payment-btn-mob"
          //   onClick={makePayment}
          // >
          //   {t("Make Payment Button")}
          // </button>
          // :
          // email === null || !validator.isEmail(email) || number === null || number?.length < 5
          // ?
        //   <button
        //   aria-label="Make Payment"
        //   className="payment-btn-mob"
        //   onClick={() => makePaymentButton()}
        // >
        //   {t("Make Payment Button")}
        // </button>
          // :
          //   emailError || emailValue === null || numberValue === null || numberValue?.length < 5 ?
          //   null
            :
              <button
                aria-label="Make Payment"
                className="payment-btn-mob"
                onClick={makePayment}
                style={{fontSize:"14px"}}
              >
                {t("Make Payment Button")}
              
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
