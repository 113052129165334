import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function Sidebar({ pricingInfo, passengerInfo }) {
  const {
    bookingDetails,
    totalCharges,
    showHepar,
    hepstarData,
    currency,
    setTotalCharges,
    setShowHepar,
    discountedAmount,
    lang,locale
  } = useGlobals();

  let Tax = "";
  let ServiceCharge = "";
  let Discount = "";
  let TotalPrice = "";

  let sumOfPrice = "";

  sumOfPrice = passengerInfo?.paymentDetails?.amount;

  let totalPassengers =
    pricingInfo.adultQty + pricingInfo.childQty + pricingInfo.infantsQty;

  useEffect(() => {
    if (passengerInfo?.IsInsured === true) {
      setShowHepar(true);
    }
  }, [passengerInfo]);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  useEffect(() => {
    if (lang === "en") {
      changeLanguage("en");
    }
    if (lang === "ar") {
      changeLanguage("ar");
    }
  }, [lang]);

  return (
    <div>
      <div className="row sidebar-border dps-none">
        <div className="col-sm-12 col-lg-12 col-md-12 ">
          <div className="row sidebar-header">
            <div className="col-sm-7 col-md-7 col-lg-7">
              <h5 className="font-bold font-16">{t("Booking Summary")}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 p-3">
              <h6 className="font-12 font-bold">{t("Base Fare")}</h6>
            </div>
          </div>

          <div className="row px-1">
            <div className="col-sm-6 col-md-6 col-lg-6">
              {pricingInfo.adultQty > 0 && (
                <p className="font-12 mob-font-11 dark-clr-black ">{`${t(
                  "Adult"
                )}(s) x ${pricingInfo.adultQty}`}</p>
              )}
              {pricingInfo?.childQty > 0 && (
                <p className="font-12 mob-font-11 dark-clr-black ">{`${t(
                  "Child"
                )}(s) x ${pricingInfo.childQty}`}</p>
              )}
              {pricingInfo?.infantsQty > 0 && (
                <p className="font-12 mob-font-11 dark-clr-black ">{`${t(
                  "Infant"
                )}(s) x ${pricingInfo.infantsQty}`}</p>
              )}
              <p></p>
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                  : "col-sm-6 col-md-6 col-lg-6 text-right"
              }
            >
              <p>
                {pricingInfo.adultPrice
                  ? `${currency} ${pricingInfo.adultPrice}`
                  : ""}
                <br />
                {pricingInfo.childPrice
                  ? `${currency} ${pricingInfo.childPrice}`
                  : ""}
                <br />
                {pricingInfo.infantPrice
                  ? `${currency} ${pricingInfo.infantPrice}`
                  : ""}
              </p>
            </div>
          </div>

          <div className="row px-1 pt-2 ">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <p>{t("Taxes & Fees")}</p>
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                  : "col-sm-6 col-md-6 col-lg-6 text-right"
              }
            >
              <p>
                {currency} {Number(pricingInfo.taxPrice).toFixed(2)}
              </p>
            </div>
          </div>

          <div className="row px-3">
            <div className="col-sm-12 border-bottom p-2"></div>
          </div>

          {showHepar ? (
            <div className="row mob-top-m0 mt-3 px-1 bg-skyblue myDIV">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <h6 className="font-12 font-bold dark-clr-black ">
                  {t("Cancellation Charges")}
                </h6>
              </div>

              <div
                className={
                  locale === "ar"
                    ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                    : "col-sm-6 col-md-6 col-lg-6 text-right"
                }
              >
                <p className="font-12 mob-font-11 mob-top-22 dark-clr-black text-orange">
                  {currency} {Number(passengerInfo?.InsuranceAmount).toFixed(2)}
                </p>
              </div>
            </div>
          ) : null}

          {discountedAmount && discountedAmount > 0 ? (
            <div>
              <div className="row px-3 bg-skyblue dps-none ">
                <div className="col-sm-12 border-bottom "></div>
              </div>
              <div className="row px-1 py-2 bg-skyblue ">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <p className="font-bold text-orange">{t("Discount")}</p>
                </div>

                <div
                  className={
                    locale === "ar"
                      ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                      : "col-sm-6 col-md-6 col-lg-6 text-right"
                  }
                >
                  <p className="font-bold text-orange mob-top-22">
                    {currency} {Number(discountedAmount).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row px-3">
            <div className="col-sm-12 border-bottom "></div>
          </div>

          <div className="row px-1 py-2">
            <div className="col-sm-6">
              <h5 className="font-bold font-16">{t("Total Fare")}</h5>
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                  : "col-sm-6 col-md-6 col-lg-6 text-right"
              }
            >
              <h5 className="font-bold text-green">
                {currency} {sumOfPrice}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="row sidebar-border mt-4">
        <div className="col-sm-12 col-lg-12 col-md-12 ">
          <div className="row sidebar-header">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h5 className="payment-details font-16 font-bold">
                {t("Payment Details")}
              </h5>
            </div>
          </div>

          <div className="row mob-padding-x">
            <div className="col-sm-12 col-md-12 col-lg-12 p-3">
              <h6 className="font-12 font-bold text-black">{t("Paid By")}</h6>
            </div>
          </div>

          <div className="row px-1 mob-padding-x">
            <div className="col-sm-12 col-md-12 col-lg-12 mob-padding-x2">
              <span
                className="font-16 text-black"
                dir={locale === "ar" ? "ltr" : "ltr"}
                style={
                  locale === "ar" ? { textAlign: "right" } : { textAlign: "left" }
                }
              >
                {passengerInfo?.paymentDetails?.creditCard}
              </span>
              <br />
              <p className="border-bottom-dashed pt-2"></p>
            </div>
          </div>

          <div className="row mob-padding-x">
            <div className="col-sm-12 col-md-12 col-lg-12 p-3">
              <h6 className="font-12 font-bold text-black">{t("Amount")}</h6>
            </div>
          </div>

          <div className="row px-1 mob-padding-x">
            <div className="col-sm-12 col-md-12 col-lg-12 mob-padding-x2">
              <p className="font-16 text-black">
                {passengerInfo?.paymentDetails?.currency}{" "}
                {passengerInfo?.paymentDetails?.amount}
              </p>
              <p className="border-bottom-dashed pt-2"></p>
            </div>
          </div>

          <div className="row mob-padding-x">
            <div className="col-sm-12 col-md-12 col-lg-12 p-3">
              <h6 className="font-12 font-bold text-black">
                {t("Payment Ref")}.
              </h6>
            </div>
          </div>

          <div className="row px-1 mob-padding-x ">
            <div className="col-sm-12 col-md-12 col-lg-12 mob-padding-x2">
              <p className="font-16 text-black">
                {passengerInfo?.paymentDetails?.paymentReference}
              </p>
              <p className="border-bottom-dashed pt-2"></p>
            </div>
          </div>

          <div className="row mob-padding-x">
            <div className="col-sm-12 col-md-12 col-lg-12 p-3">
              <h6 className="font-12 font-bold text-black">
                {t("Payment Date")}
              </h6>
            </div>
          </div>

          <div className="row px-1 mb-3 mob-padding-x2">
            <div className="col-sm-12 col-md-12 col-lg-12 mob-padding-x2">
              <p className="font-16 text-black">
                {String(passengerInfo?.paymentDetails?.paymentDate).substring(
                  0,
                  10
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
