import { React, useState, useEffect } from "react";

import BackToTop from "../../src/img/BackToTopButton.svg";
import BackToTopArabic from "../../src/img/backToTopArabic.png";
import { useGlobals } from "../contexts/globalContexts";
import "../../src/css/flight_confirmation.css";

function BackToTopButton() {
  const [toggle, showToggle] = useState(false);
  const { lang, locale } = useGlobals();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        showToggle(true);
      } else {
        showToggle(false);
      }
    });
  }, []);

  const ScrollToTop = () => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <img
        id="backBtnID"
        src={locale === "ar" ? BackToTopArabic : BackToTop}
        className="dps-none print_none"
        onClick={ScrollToTop}
        style={
          locale === "ar"
            ? {
                position: "fixed",
                bottom: "0",
                left: "0",
                zIndex: 99,
                cursor: "pointer",
              }
            : {
                position: "fixed",
                bottom: "0",
                right: "0",
                zIndex: 99,
                cursor: "pointer",
              }
        }
      />
    </div>
  );
}

export default BackToTopButton;
