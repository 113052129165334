import "../../css/privacy_policy.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

function PrivacyPolicy() {
  const { lang, locale, setLocale } = useGlobals();
  const navigate = useNavigate();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add("sub_page");
    document.getElementById("root").classList.add("page");
    if(!isMobile){
    document.getElementById("root").classList.add("search_results");
    }
  }, []);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        // navigate(`/en/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");

        setLocale("ar");
        // navigate(`/ar/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, []);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div dir={locale === "ar" ? "rtl" : "ltr"}>
      <div className="row top_bg">
        <div
          className={
            locale === "ar"
              ? "col-md-12 col-lg-12 title_header_arabic"
              : "col-md-12 col-lg-12 title_header"
          }
        >
          <h1>{t("Privacy Policy Heading")}</h1>
        </div>
        <hr className="line_break" />
      </div>
      <section className="container-fluid privacy_policy">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 mb-4 mt-4 px-10">
              <p>
                {t("Top Content")}
                <br />
                {t("Top Content 1")}
              </p>
              <h3>{t("Traveller information collection")}</h3>
              <p>
                {t("Traveller information collection Paragraph 1")}
                <br />
                {t("Traveller information collection Paragraph 2")}
                <br />
                {t("Traveller information collection Paragraph 3")}
                <br />
                {t("Traveller information collection Paragraph 4")}
                <br />
                {t("Traveller information collection Paragraph 5")}
                <br />
              </p>
              <h3>{t("Sharing information")} </h3>
              <p>
                {t("Sharing information Point 1")}
                <br />
                {t("Sharing information Point 2")}
                <br />
                {t("Sharing information Point 3")}
                <br />
                {t("Sharing information Point 4")}
                <br />
                {t("Sharing information Point 5")}
                <br />
                {t("Sharing information Point 6")}
                <br />
                {t("Sharing information Point 7")}
                <br />
              </p>
              <h3>{t("Children Privacy")} </h3>
              <p>{t("Children Privacy Description")}</p>
              <h3>{t("Data accuracy")}</h3>
              <p>
                {t("Data accuracy Point 1")} <br />
                {t("Data accuracy Point 2")} <br />
                {t("Data accuracy Point 3")} <br />
              </p>
              <h3>{t("Changes to this privacy Policy")}</h3>
              <p>{t("Changes to this privacy Policy description")}</p>
              <h3> {t("External links")} </h3>
              <p>{t("External links description")}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
