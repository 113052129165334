import { createContext, useContext, useEffect, useState } from "react";
import { addDays } from "date-fns";
import { matchRoutes, useLocation } from "react-router-dom"
export const GlobalContext = createContext();


const GlobalContextProvider = (props) => {
  
  const [searchId, setSearchId] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [isFlightDetailsOpen, setIsFlightDetailsOpen] = useState(false);

  const [searchResultsError, setSearchResultsError] = useState(false)

  const [dates, setDates] = useState([
    {
      startDate: addDays(new Date(), 1),
      endDate: addDays(new Date(), 2),
      key: 'selection'
    },
  ]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [onewaydate, setOneWayDate] = useState(
    new Date(addDays(new Date(), 1))
  );

  const [directFlight, setDirectFlight] = useState(false);
  const [flexibleDates, setFlexibleDates] = useState(false);
  const [preferedAirLine, setPreferedAirLine] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [fromAirport, setFromAirport] = useState("");
  const [toAirport, setToAirport] = useState("");
  const [fromAirportValue , setFromAirportValue] = useState('');
  const [toAirportValue , setToAirportValue] = useState('');
  const [partner] = useState(2);
  const [device] = useState(1);
  const [cabin, setCabin] = useState(5);

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [makePaymentBtn , setMakePaymentBtn] = useState(false);

  const [array, setArray] = useState([]);
  const [tapPublicKey, setTapPublicKey] = useState();

  const [filtersArray , setFiltersArray] = useState({
    "Ref" : false,
    "NonRef" : false,
    "NonStop" : false,
    "OneStop" : false,
    "TwoStop" : false,
    "dEarlyMorning" : false,
    "dAfternoon" : false,
    "dEvening" : false,
    "dNight" : false,
    "aEarlyMorning" : false,
    "aAfternoon" : false,
    "aEvening" : false,
    "aNight" : false,
   });

  const [passengers, setPassengers] = useState({
    adultCount: adults,
    childrenCount: children,
    infantsCount: infants,
  });

  let currencyValue;
  // if(window.location.hostname === "sa.eilago.com"){
  //  currencyValue = "SAR";
  // }
  // else if(window.location.hostname === "ae.eilago.com" || window.location.hostname === "uatae.eilago.com"){
  //  currencyValue = "AED";
  // }
  // else if(window.location.hostname === "bh.eilago.com" || window.location.hostname === "uatbh.eilago.com"){
  //  currencyValue = "BAH";
  // }
  // else{
  //  currencyValue = "SAR";
  // }


  const [tripType, setTripType] = useState(1);
  const [locale, setLocale] = useState('en');
  const [lang, setLang] = useState('en');
  const [currency, setCurr] = useState(process.env.REACT_APP_CURRENCY);
  const [legs, setLegs] = useState([
    {
      LegId: 0,
      departureCode: fromAirport,
      arrivalCode: toAirport,
      departureDate: fromDate, //toISOString
    },
    {
      LegId: 1,
      departureCode: toAirport,
      arrivalCode: fromAirport,
      departureDate: toDate, //toISOString
    },
  ]);
  const [sessions] = useState({
    id: "1",
    userType: "Visitor",
    userID: "1",
  });
  const [utmParameters] = useState({
    utm_source: "utm_source",
    utm_medium: "utm_medium",
    utm_campaign: "utm_campaign",
    utm_term: { fromAirport, toAirport },
    utm_content: "utm_content",
  });

  const [priceFilter, setPriceFilter] = useState();
  const [stopsFilter, setStopsFilter] = useState();

  const [mobileMenu, setMobileMenu] = useState(false);

  const [priceSortingValue, setPriceSortingValue] = useState("High");
  const [cuponCodeVal, setCuponCodeVal] = useState('');

  const [durationSorting, setDurationSorting] = useState('Long');
  const [arrivalSorting, setArrivalSorting] = useState();
  const [departureSorting, setDepartureSorting] = useState();

  const [bookingDetails, setBookingDetails] = useState({});
  const [totalCharges, setTotalCharges] = useState();

  const [hepstarData, setHepstarData] = useState({
    totalHepstarAmount : 0,
  });
  const [hepstarDetails , setHepstarDetails] = useState({});
  const [showHepar, setShowHepar] = useState(false);

  const [contactDetails, setContactDetails] = useState({});
  const [hepstarPolicyNumber, setHepstarPolicyNumber] = useState();
  const [isDisabled, setDisable] = useState(false);
  const [bookingRef, setBookingRef] = useState();
  const [totalPass, setTotalPass] = useState();
  const [codeUpdatedValue , setCodeUpdatedValue] = useState(process.env.REACT_APP_COUNTRY_CODE)
  const [docType, setDocType] = useState("P");


  const [passengerData, setpassengerData] = useState([
    {
      firstName: null,
      middleName: null,
      lastName: null,
      docType: "P",
      dayDOB: null,
      monthDOB: null,
      yearDOB: null,
      passportNo: null,
      gender: null,
      countryResidence: null,
      passportIssueCountry: null,
      passportExpiryDay: null,
      passportExpiryMonth: null,
      passportExpiryYear: null,
      freqNo: "",
      freqNoOptional: "",
    },
  ]);

  const [createBookingApiData, setCreateBookingApiData] = useState([]);
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [nameError, setNameError] = useState();
  const [bdyError, setBdyError] = useState();
  const [passportNoError, setPassportNoError] = useState();
  const [countryError, setCountryError] = useState();
  const [passportExpError, setPassportExpError] = useState();
  const [genderError, setGenderError] = useState();

  const [resetAllFilters, setResetAllFilters] = useState(false);
  const [resetAllFiltersApply, setResetAllFiltersApply] = useState(false);

  const [bookingId, setBookingId] = useState();

  const [isLoading, setIsloading] = useState(false);

  const [noBookingsMsg, setNoBookingsMsg] = useState(false);
  const [itenraryDetails, setItenraryDetails] = useState({});

  const [paymentError, setPaymentError] = useState();

  const [pricingInfo , setPricingInfo] = useState({});
  const [priceSummary, setPriceSummary] = useState({});
  const [moreInfoToggle , setMoreInfoToggle] = useState(false);
  const [isOpenPage , setIsOpenPage] = useState(false);
  const [callContactAPIState , setCallContactAPIState] = useState(false);
  const [insurance , setInsurance] = useState(false);
  const [code, setCode] = useState("966");
  const [tap , setTap] = useState(null);
  const [tapPayConfig, setTapPayConfig] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [hepstarCheckDisbale, setHepstarCheckDisable] = useState(true);
  const [hepstarCalled , setHepstarCalled] = useState(false);
  const [passengerAdded, setPassengerAdded] = useState(false);
  const [item1 , setItem1] = useState();
  const [item2 , setItem2] = useState();
  const [item3, setItem3] = useState();
  const [item4, setItem4] = useState();
  const [item5, setItem5] = useState();
  const [checkedValue , setCheckedValue] = useState(true);
  const [noThanks , setNoThanks] = useState(false);
  const [buyHepstar , setBuyHepstar] = useState(false);
  const [isHepstarChecked, setIsHepstarChecked] = useState(false);
  const [bookingErrorMsg, setBookingErrorMsg] = useState();
  const [hepstarResult , setHepstarResult] = useState(false);
  const [showErrorPage , setShowErrorPage] = useState(false);
  const [getItinerariesCopy , setItinerariesCopy] = useState([null]);
  const [discountedAmount , setDiscountedAmount] = useState(null);

  const [tapPayment , setTapPayment] = useState(false);
  const [eradPayment, setEradPayment] = useState(false);
  const [provideID, setProviderId] = useState();

  const [filterMargin, setFilterMargin] = useState(true);

  const [segArray, setSegArray] = useState([]);
 const [taxPrice, setTaxPrice] = useState();

 const [utmMed ,setUtmMed] = useState();
  

  let siteURLS

//   if(window.location.hostname === "sa.eilago.com"){
//     siteURLS = {
//       flightURL: "https://sa.flights.eilago.com/api",
//       pgURL: "https://sa.payment.eilago.com/api",
//       inURL: "https://sa.insurance.eilago.com/api",
//       bookingURL: "https://sa.booking.eilago.com/api",
//       staticContentURL: "https://sa.elastic.eilago.com",
//       tapURL: "https://api.tap.company/v2",
    
//     }
//   }
//   else {
//    siteURLS = {
//     flightURL: "https://uatfl2.eilago.com/api",
//     staticContentURL: "https://uat-es.eilago.com",
//     pgURL: "https://uatpg2.eilago.com/api",
//     inURL: "https://uatin2.eilago.com/api",
//     bookingURL: "https://uatbk2.eilago.com/api",
//     tapURL: "https://api.tap.company/v2",
  
//   }

// }

let localePath = window.location.pathname.split('/');
  const selectLanguage = (e) => {
    e.preventDefault();
      setLang(e.currentTarget.dataset.lang);
      document.documentElement.lang = e.currentTarget.dataset.lang;
   
  };

  const selectCurrency = (e) => {
    e.preventDefault();
    setCurr(e.currentTarget.dataset.currency);
    document.getElementById("selected_currency").innerHTML =
      e.currentTarget.innerHTML;
  };

  const createLeg = (fromAirport, toAirport, dates, tripType, onewaydate) => {
    if (tripType === 1) {
      setLegs([
        {
          LegId: 0,
          departureCode: fromAirport,
          arrivalCode: toAirport,
          // departureDate: dates[0].startDate.toISOString(), //toISOString
          departureDate: new Date(dates[0]?.startDate?.getTime() - dates[0]?.startDate?.getTimezoneOffset() * 60000)
        },
        {
          LegId: 1,
          departureCode: toAirport,
          arrivalCode: fromAirport,
          // departureDate: dates[0].endDate.toISOString(), //toISOString
          departureDate: new Date(dates[0]?.endDate?.getTime() - dates[0]?.endDate?.getTimezoneOffset() * 60000)
        },
      ]);
    }
    if (tripType === 0) {
      setLegs([
        {
          LegId: 0,
          departureCode: fromAirport,
          arrivalCode: toAirport,
          // departureDate: onewaydate,
          departureDate: new Date(onewaydate?.getTime() - onewaydate?.getTimezoneOffset() * 60000)
        },
      ]);
    }
  };

  const createPassengers = (adults, children, infants) => {
    setPassengers({
      adultCount: adults,
      childrenCount: children,
      infantsCount: infants,
    });
  };

  const createCabin = (cabin) => {
    setCabin(Number(cabin));
  };


  useEffect(()=>{
    if(isFlightDetailsOpen === true){document.body.classList.add('stop_overflow');}
    else{document.body.classList.remove('stop_overflow')}
  }, [isFlightDetailsOpen])

  return (
    <GlobalContext.Provider
      value={{
        lang,
        selectLanguage,
        currency,
        selectCurrency,
        setCurr,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        dates, setDates,
        createLeg,
        directFlight,
        setDirectFlight,
        flexibleDates,
        setFlexibleDates,
        preferedAirLine,
        setPreferedAirLine,
        fromAirport,
        setFromAirport,
        toAirport,
        setToAirport,
        from,
        setFrom,
        to,
        setTo,
        partner,
        device,
        cabin,
        setCabin,
        createCabin,
        adults, setAdults,
        children, setChildren,
        infants, setInfants,
        passengers,
        setPassengers,
        createPassengers,
        tripType,
        setTripType,
        legs,
        setLegs,
        sessions,
        utmParameters,
        searchId,
        setSearchId,
        searchResults,
        setSearchResults,
        searchResultsError, setSearchResultsError,
        isFlightDetailsOpen, 
        setIsFlightDetailsOpen,
        onewaydate,
        setOneWayDate,
        searchType,
        setSearchType,
        priceFilter,
        setPriceFilter,
        stopsFilter,
        setStopsFilter,
        mobileMenu,
        setMobileMenu,
        priceSortingValue,
        setPriceSortingValue,
        durationSorting,
        setDurationSorting,
        arrivalSorting,
        setArrivalSorting,
        departureSorting,
        setDepartureSorting,
        bookingDetails,
        setBookingDetails,
        totalCharges,
        setTotalCharges,
        showHepar,
        setShowHepar,
        hepstarData,
        setHepstarData,
        contactDetails,
        setContactDetails,
        hepstarPolicyNumber,
        setHepstarPolicyNumber,
        isDisabled,
        setDisable,
        passengerData,
        setpassengerData,
        createBookingApiData,
        setCreateBookingApiData,
        emailError,
        setEmailError,
        phoneError,
        setPhoneError,
        nameError,
        setNameError,
        bdyError,
        setBdyError,
        passportNoError,
        setPassportNoError,
        countryError,
        setCountryError,
        passportExpError,
        setPassportExpError,
        resetAllFilters,
        setResetAllFilters,
        resetAllFiltersApply,
        setResetAllFiltersApply,
        genderError,
        setGenderError,
        isLoading,
        setIsloading,
        bookingId,
        setBookingId,
        bookingRef,
        setBookingRef,
        noBookingsMsg,
        setNoBookingsMsg,
        siteURLS,
        itenraryDetails,
        setItenraryDetails,
        paymentError,
        setPaymentError,
        pricingInfo , setPricingInfo,
        priceSummary, setPriceSummary,
        moreInfoToggle , setMoreInfoToggle,
        totalPass, setTotalPass,
        isOpenPage , setIsOpenPage,
        callContactAPIState , setCallContactAPIState,
        insurance , setInsurance,
        code, setCode,
        hepstarDetails , setHepstarDetails,
        codeUpdatedValue , setCodeUpdatedValue,
        tap, setTap,
        tapPayConfig, setTapPayConfig,
        isChecked, setIsChecked,
        hepstarCheckDisbale, setHepstarCheckDisable,
        hepstarCalled , setHepstarCalled,
        passengerAdded, setPassengerAdded,
        item1 , setItem1 , item2, setItem2, item3, setItem3, item4, setItem4 , item5, setItem5,
        checkedValue , setCheckedValue,
        noThanks , setNoThanks, buyHepstar , setBuyHepstar,
        isHepstarChecked, setIsHepstarChecked,
        bookingErrorMsg, setBookingErrorMsg,
        hepstarResult , setHepstarResult,
        showErrorPage , setShowErrorPage,
        getItinerariesCopy , setItinerariesCopy,
        discountedAmount , setDiscountedAmount,
        locale, setLocale,
        tapPayment , setTapPayment,
        eradPayment, setEradPayment,
        provideID, setProviderId,
        segArray, setSegArray,
        filterMargin, setFilterMargin,
        taxPrice, setTaxPrice,
        utmMed ,setUtmMed,
        fromAirportValue , setFromAirportValue, toAirportValue , setToAirportValue,
        docType, setDocType, filtersArray , setFiltersArray,
        makePaymentBtn , setMakePaymentBtn,
        cuponCodeVal, setCuponCodeVal, array, setArray, tapPublicKey, setTapPublicKey
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
export const useGlobals = () => useContext(GlobalContext);

export default GlobalContextProvider;
