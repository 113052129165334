import Sa from '../img/sa.svg'
import Bh from '../img/bh.svg'
import Pk from '../img/pk.svg'

import '../css/no_flights_found.css'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useGlobals } from '../contexts/globalContexts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'


function SearchResultsError({fromAirport , toAirport , fromDate, toDate, departureFrom , arrivalTo, tripType}) {
    const navigate = useNavigate();

    const {setSearchId, setFromDate, setToDate,setShowErrorPage, lang, locale, setLocale ,onewaydate } = useGlobals();

    const [showDropDown, setShowDropDown] = useState(false);

    const showPhoneNumbers = (e) => {
        e.preventDefault()
        setShowDropDown(current => !current)
    }

    const openEmail = () => {
    window.location = "mailto:support@eilago.com";
    }

    
    let localePath;
    localePath = window.location.pathname.split('/');

    const goHome = () => {
        setSearchId('');
        setShowErrorPage(false);
        if(localePath[1] === "en"){
            navigate('/en');
        }
        else{

            navigate('/');
        }
        
    }

    const { t, i18n, ready } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
      };
  
    useEffect(() => {

    
      setTimeout(() => {
       if(localePath[1] === "en"){
         changeLanguage('en');
         setLocale('en');
    
         document.documentElement.setAttribute("lang", 'en');
       }
       else{
         changeLanguage('ar');
        setLocale('ar');

        document.documentElement.setAttribute("lang", 'ar');
       }
      }, 500)
     }, [])
    

    return (
        <div className='search_has_error'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 logo">
                        <img src="../img/eilago_logo_dark.svg" alt="" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 logo_light">
                        <img src="../img/d/eilago_logo_light.svg" alt="" />
                    </div>
                </div>
                <div className="row content">
                    <div className="col-12 col-sm-12 col-md-12 content_top">
                        <p>{t('Sorry No Flight')}</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 sad_bird">
                            <img src="../img/sad_bird.svg" alt="" />
                        </div>
                    </div>
                    {locale === "en" ?
                    <div className="col-12 col-sm-12 col-md-12 content_mid">
                    <h1> {departureFrom?.split(',')[0]} <strong> {fromAirport} </strong> - {arrivalTo?.split(',')[0]} <strong> {toAirport} </strong></h1>
                  
                    {tripType === 1 ? 
                        <p>{`${String(fromDate).substring(0,15)} - ${String(toDate).substring(0,15)}`}</p>
                    :
                        <p>{`${String(onewaydate).substring(0,15)}`}</p>
                    }
                    
                </div>
                :
                <div className="col-12 col-sm-12 col-md-12 content_mid">
                        <h1> {arrivalTo?.split(',')[0]} <strong> {toAirport} </strong> - {departureFrom?.split(',')[0]} <strong> {fromAirport} </strong></h1>
                      
                        {tripType === 1  ? 
                            <p>{`${String(toDate).substring(0,15)} - ${String(fromDate).substring(0,15)}`}</p>
                        :
                            <p>{`${String(onewaydate).substring(0,15)}`}</p>
                        }
                        
                    </div>

                    }
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 try_again">
                        <button aria-label='Try Again' onClick={goHome}>
                            <span className="btn_text"> {t('Try Again Button')} </span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className='container pl-0 pr-0 has_buttons'>
                        <div className="col-12 col-sm-12 col-md-12 sorry ml-0 mr-0">
                            <p> {t('Reach Us')} </p>
                        </div>
                        <div className='row ml-0 mr-0'>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 phone-btn pl-0 pr-0">
                                <button aria-label='call +966 11520 4955' onClick={isMobile ? (e) => { showPhoneNumbers(e) } : () => { }}>
                                    <span className="ph_btn_text">+966 11520 4955</span>
                                </button>
                                <ul className={showDropDown ? 'show_numbers' : ''}>
                                    <li>
                                        <a href="tel:+966 11520 4955">
                                            <span><img src={Sa} alt="" /></span>
                                            <span>
                                                <small>Saudi Arabia</small>
                                                <small>+966 11520 4955</small>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+973 1330 2121">
                                            <span><img src={Bh} alt="" /></span>
                                            <span>
                                                <small>Bahrain</small>
                                                <small>+973 1330 2121</small>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+92 5127 28821">
                                            <span><img src={Pk} alt="" /></span>
                                            <span>
                                                <small>Pakistan</small>
                                                <small>+92 5127 28821</small>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 email-btn pl-0 pr-0">
                                <button aria-label='email support@eilago.com' type='button' onClick={()=>{openEmail()}}>
                                    <span className="email_btn_text"> support@eilago.com </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 bg_cloud_center" />
                </div>
            </div>
        </div>
    );
}

export default SearchResultsError;