import AirplaneArrival from "../../img/airplane-arrival.svg";
import AirplaneArrivalDark from "../../img/d/Outline_Icons.png";

function AirplaneArrivalIcon() {
    return(
        <div>
             <div className="row mt-5 ">
              <div className="col-12 text-center print_none">
                <img src={AirplaneArrival} className="light" alt="airplane-icon-light" />
                <img src={AirplaneArrivalDark} className="dark" alt="airplane-icon-dark"  />
              </div>
            </div>

        </div>
    )
}

export default AirplaneArrivalIcon