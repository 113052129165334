import { useEffect, useState } from "react";

import { useGlobals } from "../contexts/globalContexts";
import { isMobile } from "react-device-detect";

import SearchForm from "./SearchForm/SearchForm";
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs";
import SearchResultsAirlines from "./SearchResultsAirlines/SearchResultsAirlines";
import SearchSorting from "./SearchSorting/SearchSorting";
import SearchFilters from "./SearchFilters/SearchFilters";
import FlightDetails from "./FlightDetails/FlightDetails";

import "../css/search_result_snippet.css";
import "../css/search_results.css";
import SearchResultSnippet from "./SearchResultSnippet/SearchResultSnippet";
import { useNavigate, useParams } from "react-router-dom";
import ResultSnippetSkeleton from "./SearchResultSnippet/ResultSnippetSkeleton";
import BackToTopButton from "./BackToTopButton";
import SearchResultsError from "./SearchResultsError";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import FlightDetailsPopUp from "./FlightDetailsPopUp/FlightDetailsPopUp";
import BirdLoader from "./BirdLoader/BirdLoader";

function SearchResultsPage() {
  const {
    tripType,
    fromDate,
    toDate,
    toAirport,
    setToAirport,
    fromAirport,
    setFromAirport,
    passengers,
    searchResults,
    searchType,
    priceFilter,
    priceSortingValue,
    durationSorting,
    setDurationSorting,
    setSearchResults,
    arrivalSorting,
    departureSorting,
    siteURLS,
    setSearchType,
    setSearchId,
    isDisabled,
    setDisable,
    isFlightDetailsOpen,
    setIsFlightDetailsOpen,
    stopsCount,
    legs, 
    from,
    to,
    setFrom,
    setTo,
    setIsloading,
    showErrorPage , setShowErrorPage, lang, locale, setLocale, setPriceSortingValue, setTripType,
    setFromDate, setToDate, setDates, dates, onewaydate, setOneWayDate,
    filtersArray , setFiltersArray, setResetAllFiltersApply, resetAllFiltersApply,
    array, setArray
  } = useGlobals();

  const { holdSearchId } = useParams();

  const navigate = useNavigate();

  function editSearch() {
    navigate("/");
  }

  const [renderFlightDetails, setRenderFlightDetails] = useState(false);
  const [getItineraries, setItineraries] = useState();

  const [sortedPrice, setSortedPrice] = useState();

  const [sortedDuration, setSortedDuration] = useState();

  const [birdLoader, setBirdLoader] = useState(true);

  const fixSearchPage = () => {
    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", "background-image:none");
    document
      .getElementsByTagName("main")[0]
      .setAttribute("class", "page search_results");
  };

  const showFlightDetails = (Itineraries) => {
    setRenderFlightDetails(true);
    setIsFlightDetailsOpen(true);
    setItineraries([Itineraries]);
  };

  const [mfilters, setMFilters] = useState(false);
  const [msorting, setSorting] = useState(false);

  const openFilters = () => {
    document.body.scrollTo(0, 0);
    setSorting(false);
    setMFilters((prevCheck) => !prevCheck);
    document.body.style.overflow = "hidden";
  };
  const openSorting = () => {
    document.body.scrollTo(0, 0);
    setMFilters(false);
    setSorting((prevCheck) => !prevCheck);
    document.body.style.overflow = "hidden";
  };

  const closeFiltersSorting = () => {
    setSorting(false);
    setMFilters(false);
    document.body.style = null;
  };

  let fromAirportValue
  let toAirportValue


  const [searchResultsCopy , setSearchResultsCopy] = useState();


  useEffect(() => {
    setDisable(true);
   if(searchResults.length < 1){
    setTimeout(() => {
      //http://0.0.0.0:8080/
      const getResultsFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/GetResults`;
      // const getResultsFrom = `http://localhost:3000/sample.json`
      fetch(getResultsFrom, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          SearchId: holdSearchId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if(!data.responseModel.pricingGroups && data.responseModel.includes("RemainingSeconds")){
          
          }
          if(data.responseModel.pricingGroups.length === 0 || data.responseModel.pricingGroups.length === null || data.responseModel.pricingGroups.length === undefined || data.httpStatusCode !== 200){
          //  setShowErrorPage(true);        
          }
          if (data.responseModel.pricingGroups) {
            setSearchType(data.responseModel.SearchType.Type);
            setSearchId(holdSearchId)
          }
          if (data.responseModel.pricingGroups) {
            let resultsArray = [];
            for (
              let i = 0;
              i < data.responseModel.pricingGroups.length;
              i++
            ) {
              resultsArray.push(data.responseModel.pricingGroups[i]);
              
            }
            setSearchResults(resultsArray);
            setSearchResultsCopy(resultsArray);
            if(resultsArray){
              let newDuration = resultsArray.sort((a, b) =>
      Number(getElapsedTime(a) - Number(getElapsedTime(b)))
      );
    setShortestPrice(newDuration[0]?.Itineraries[0]?.TotalPrice);

    resultsArray?.map((data) => {
    data?.Itineraries.map((itenrary) => {
      if(itenrary.CheapestFlight === true){
        setCheapestPrice(itenrary.TotalPrice);
      }
    })
    })
            }


            // if(!isMobile){
              // setPriceSortingValue("Low");
            // }
            // setCheapestPrice(data?.responseModel?.pricingGroups[0].Itineraries[0].TotalPrice);


            
            setBirdLoader(false);
            if(data?.responseModel?.SearchType.Type === "OneWay"){
              setTripType(0);
              setOneWayDate(new Date(resultsArray[0]?.Itineraries[0]?.Leg[0]?.departureDate))
              setDates([
                {
                  startDate: new Date(resultsArray[0]?.Itineraries[0]?.Leg[0]?.departureDate),
                },
              ]);
              setFromDate(dates[0].startDate);
              document.getElementById("from_date").value = String(
                dates[0].startDate
              ).substring(0, 15);
            }
            else{
              setTripType(1);
              setDates([
                {
                  startDate: new Date(resultsArray[0]?.Itineraries[0]?.Leg[0]?.departureDate),
                  endDate: new Date(resultsArray[0]?.Itineraries[0]?.Leg[1]?.departureDate),
                  key : "selection"
                },
              ]);

              setFromDate(dates[0]?.startDate);
              setToDate(dates[0]?.endDate);
        
              document.getElementById("from_date").value = String(
                dates[0]?.startDate
              )?.substring(0, 15);
              document.getElementById("to_date").value = String(
                dates[0]?.endDate
              )?.substring(0, 15);

            }

          if(!isMobile){
            if(window.location.pathname !== "/" || window.location.pathname !== "/ar"){
              if(from === "" || to === ""){
  setFromAirport(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode)
             setToAirport(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements?.length - 1]?.arrivalAirport?.airportCode)
 
             setFrom(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode)
             setTo(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements?.length - 1]?.arrivalAirport?.airportCode)
             if(!isMobile) {
             document.getElementById('from').value = 
             resultsArray[0]?.Itineraries[0]?.Leg[0].Segements[0]?.departureAirport?.cityName + ', ' +
             resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportCode + ', ' + 
             resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportName + ', ' + 
             resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.countryName
 
             document.getElementById('to').value = 
             resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.cityName + ', ' + 
             resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportCode + ', ' + 
             resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportName + ', ' + 
             resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName
             }
              }
             }
             
          }
           
            setDisable(false);
          } else {
            console.log(data);
          }
        })
        .catch((err) => {
          setTimeout(() => {
            //http://0.0.0.0:8080/
            const getResultsFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/GetResults`;
            // const getResultsFrom = `http://localhost:3000/sample.json`
            fetch(getResultsFrom, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                SearchId: holdSearchId,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                // if(!isMobile){
                // setPriceSortingValue("Low");
                // }
                setBirdLoader(false);
                // setCheapestPrice(data?.responseModel?.pricingGroups[0].Itineraries[0].TotalPrice)
              
                if(!data.responseModel.pricingGroups && data.responseModel.includes("RemainingSeconds")){
                  setShowErrorPage(true);
                  
                }
                if(data.responseModel.pricingGroups.length === 0 || data.responseModel.pricingGroups.length === null || data.responseModel.pricingGroups.length === undefined || data.httpStatusCode !== 200){
                 setShowErrorPage(true);
                }
                if (data.responseModel.pricingGroups) {
                  setSearchType(data.responseModel.SearchType.Type);
                  setSearchId(holdSearchId)
                }
                if (data.responseModel.pricingGroups) {
                  let resultsArray = [];
                  for (
                    let i = 0;
                    i < data.responseModel.pricingGroups.length;
                    i++
                  ) {
                    resultsArray.push(data.responseModel.pricingGroups[i]);
                    
                  }
              
                  setSearchResults(resultsArray);
                  setSearchResultsCopy(resultsArray);

                  if(resultsArray){
                    let newDuration = resultsArray.sort((a, b) =>
            Number(getElapsedTime(a) - Number(getElapsedTime(b)))
            );
          setShortestPrice(newDuration[0]?.Itineraries[0]?.TotalPrice);
      
          resultsArray?.map((data) => {
          data?.Itineraries.map((itenrary) => {
            if(itenrary.CheapestFlight === true){
              setCheapestPrice(itenrary.TotalPrice);
            }
          })
          })
        }

                 if(!isMobile){
                  if(window.location.pathname !== "/" || window.location.pathname !== "/ar"){
                    if(from === "" || to === ""){
                   setFromAirport(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode)
                   setToAirport(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements?.length - 1]?.arrivalAirport?.airportCode)
       
                   setFrom(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode)
                   setTo(resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements[resultsArray[0]?.Itineraries[0]?.Leg[0]?.Segements?.length - 1]?.arrivalAirport?.airportCode)
                   if(!isMobile) {
                   document.getElementById('from').value = 
                   resultsArray[0]?.Itineraries[0]?.Leg[0].Segements[0]?.departureAirport?.cityName + ', ' +
                   resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportCode + ', ' + 
                   resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportName + ', ' + 
                   resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.countryName
       
                   document.getElementById('to').value = 
                   resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.cityName + ', ' + 
                   resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportCode + ', ' + 
                   resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportName + ', ' + 
                   resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName
                   }
                    }
                   }
                 }
                   

                  // setFromAirport(resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportCode)
                  // setToAirport(resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportCode)
      
                  // setFrom(resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportCode)
                  // setTo(resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportCode);

                  if(data?.responseModel?.SearchType.Type === "OneWay"){
                    setTripType(0);
                    setOneWayDate(new Date(resultsArray[0]?.Itineraries[0]?.Leg[0]?.departureDate))
                    setDates([
                      {
                        startDate: new Date(resultsArray[0]?.Itineraries[0]?.Leg[0]?.departureDate),
                      },
                    ]);
                    setFromDate(dates[0].startDate);
                    document.getElementById("from_date").value = String(
                      dates[0].startDate
                    ).substring(0, 15);
                  }
                  else{
                    setTripType(1);
                    setDates([
                      {
                        startDate: new Date(resultsArray[0]?.Itineraries[0]?.Leg[0]?.departureDate),
                        endDate: new Date(resultsArray[0]?.Itineraries[0]?.Leg[1]?.departureDate),
                        key : "selection"
                      },
                    ]);
      
                    setFromDate(dates[0].startDate);
                    setToDate(dates[0].endDate);
              
                    document.getElementById("from_date").value = String(
                      dates[0]?.startDate
                    )?.substring(0, 15);
                    document.getElementById("to_date").value = String(
                      dates[0]?.endDate
                    )?.substring(0, 15);
      
                  }


                  // if(!isMobile) {
                  // document.getElementById('from').value = 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.cityName + ', ' +
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportCode + ', ' + 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.airportName + ', ' + 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[0].departureAirport.countryName
      
                  // document.getElementById('to').value = 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.cityName + ', ' + 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportCode + ', ' + 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportName + ', ' + 
                  // resultsArray[0].Itineraries[0].Leg[0].Segements[resultsArray[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName
                  // }
  
                  
                  setDisable(false);
                  
                } else {
                  console.log(data);
                }
              })
              .catch((err) => {
                // setShowErrorPage(true);
                console.error(err);
              });
          }, 6500);
          console.error(err);
        });
    }, 6500);
   }
    // });
  }, []);

  useEffect(() =>{
    if(searchResults.length > 0){
      setShowErrorPage(false);

    //   let newDuration = searchResults.sort((a, b) =>
    //   Number(getElapsedTime(a) - Number(getElapsedTime(b)))
    //   );
    // setShortestPrice(newDuration[0]?.Itineraries[0]?.TotalPrice);
    // }

    // if(searchResults?.length > 0){
    //   searchResults?.map((data) => {
    // data?.Itineraries.map((itenrary) => {
    //   if(itenrary.CheapestFlight === true){
    //     setCheapestPrice(itenrary.TotalPrice);
    //   }
    // })
    // })
    }
    
  }, [searchResults]);

  useEffect(() =>{
    setShowErrorPage(false);
  }, []);


  useEffect(() => {
    fixSearchPage();
    if (fromAirport && toAirport) {
      document.title = `${fromAirport} - ${toAirport}`;
    } else {
      document.title = "Search Results";
    }
  }, [searchResults, priceFilter]);

  useEffect(() => {
    let searchResultsCopy = JSON.parse(JSON.stringify(searchResults));

    if (priceSortingValue === "Low") {
      let newPrice = searchResultsCopy.sort(
        (a, b) => Number(a.Itineraries[0].TotalPrice) - Number(b.Itineraries[0].TotalPrice)
      );
      setSearchResults(newPrice);
    }
     else if (priceSortingValue === "High") {
      let newPrice = searchResultsCopy.sort(
        (a, b) => Number(b.Itineraries[0].TotalPrice) - Number(a.Itineraries[0].TotalPrice)
      );
      setSearchResults(newPrice);
    }
  }, [priceSortingValue]);




  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    return mins;
  };
  



  const getTotalDuration = (s) => {
    let flight = []
    let totalTime = []
    for (let i = 0; i < s.length; i++) {
      flight.push(s[i].flights[0].elapsedTime)
    }
    for (let j = 0; j < flight.length; j++) {
      totalTime.push(flight[j])
    }
    return convertMinsToHrsMins(totalTime.reduce((a, b) => a + b, 0))
  }



  const getElapsedTime = (result) => {
    let elapsedTime = null;
     elapsedTime = getTotalDuration(result.Itineraries[0].Leg[0].Segements);
    return elapsedTime;
  };


  useEffect(() => {
    let searchResultsCopy = JSON.parse(JSON.stringify(searchResults));

    if (durationSorting === "Short") {
      let newDuration = searchResultsCopy.sort((a, b) =>
      Number(getElapsedTime(a) - Number(getElapsedTime(b)))
      );
      setSearchResults(newDuration);
    }

    if (durationSorting === "Long") {
      let newDuration = searchResultsCopy.sort((a, b) =>
        Number(getElapsedTime(b) - Number(getElapsedTime(a)))
      );

      setSearchResults(newDuration);
    }
  }, [durationSorting]);



  useEffect(() => {
    let searchResultsCopy = JSON.parse(JSON.stringify(searchResults));

    if (arrivalSorting === "Early") {
      let newArrival = searchResultsCopy.sort((a, b) =>
        Number(getArrivalTime(a) - Number(getArrivalTime(b)))
      );

      setSearchResults(newArrival);
    }

    if (arrivalSorting === "Late") {
      let newArrival = searchResultsCopy.sort((a, b) =>
        Number(getArrivalTime(b) - Number(getArrivalTime(a)))
      );
      setSearchResults(newArrival);
    }
  }, [arrivalSorting]);

  const getArrivalTime = (results) => {
    let arrivalTime = null;

    results.Itineraries.forEach((itenrary) => {

          arrivalTime = itenrary.Leg[0].Segements[0].flights[0].arrivalTime

          arrivalTime = arrivalTime.substring(0, 8);
          arrivalTime = arrivalTime.split(":");
          arrivalTime =
            parseInt(arrivalTime[0], 10) * 60 * 60 +
            parseInt(arrivalTime[1], 10) * 60 +
            parseInt(arrivalTime[2], 10);

    });
    return arrivalTime;
  };

  useEffect(() => {
    let searchResultsCopy = JSON.parse(JSON.stringify(searchResults));

    if (departureSorting === "Early") {
      let newArrival = searchResultsCopy.sort((a, b) =>
        Number(getDepartureTime(a) - Number(getDepartureTime(b)))
      );
      setSearchResults(newArrival);
    }

    if (departureSorting === "Late") {
      let newArrival = searchResultsCopy.sort((a, b) =>
        Number(getDepartureTime(b) - Number(getDepartureTime(a)))
      );
      setSearchResults(newArrival);
    }

  }, [departureSorting]);

  const getDepartureTime = (results) => {
    let departureTime = null;
    results.Itineraries.forEach((itenrary) => {
  
          departureTime = itenrary.Leg[0].Segements[0].flights[0].departureTime;
     
          departureTime = departureTime.substring(0, 8);
          departureTime = departureTime.split(":");
          departureTime =
            parseInt(departureTime[0], 10) * 60 * 60 +
            parseInt(departureTime[1], 10) * 60 +
            parseInt(departureTime[2], 10);
    
    });
    return departureTime;
  };


  
  const { t, i18n, ready } = useTranslation();


  let localePath;
  localePath = window.location.pathname.split('/');
  useEffect(() => {

    document.getElementsByClassName('language')[0].style.display = 'none';
    }, []);


    useEffect(() => {
      if(localePath[1] === "en"){
        setLocale('en');
        changeLanguage('en');
      }
      else{
        setLocale('ar');
        changeLanguage('ar');
      }
    },[])

  const {local} = useParams();

 

  useEffect(() => {
    
    

    setTimeout(() => {
     if(localePath[1] === "en"){
       changeLanguage('en');
       setLocale('en');
       document.documentElement.setAttribute("lang", 'en');
       navigate(`/en/${localePath[2]}/${localePath[3]}`)
     }
     else{
       changeLanguage('ar');
      setLocale('ar');
      document.documentElement.setAttribute("lang", 'ar');
      navigate(`/ar/${localePath[2]}/${localePath[3]}`)
     }
    }, 500)
   }, [])


   useEffect(() => {
    if(searchResults.length > 0){
      setBirdLoader(false);
    }
    else{
      setBirdLoader(true);
    }
   }, [])

   useEffect(() => {
    if(searchResults.length === 0){
      setBirdLoader(true);
    }
    if(searchResults.length > 0){
      setBirdLoader(false);
    }

   }, [searchResults]);



  


   const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
let filteredResults;


const [applyFiltersTrue , setApplyFiltersTrue] = useState(false);
const [appliedFilterCount , setAppliedFilterCount] = useState(0);
let count;
let trueValues;
useEffect(() => {
 count = Object.values(filtersArray).filter(item => item !== false).length;
    setAppliedFilterCount(count);    
  // console.log(filtersArray);
}, [filtersArray, array]);

useEffect(() =>{
  if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
    setTimeout(() => {
      setPriceSortingValue("Low");
      document.querySelectorAll('[data-refundable]').forEach((fareType, e) => {
        // fareType.classList.add("show_all");
        // setPriceSortingValue('Low');
        fareType.classList.remove('hideee');
        if(array?.length < 1){
        fareType.classList.remove('hidee');
        fareType.classList.remove('hide');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
      }
       });
    }, 1000)
  }

}, [filtersArray, array]);

useEffect(() => {
  if(searchResults.length > 0){
  
      setPriceSortingValue('High');
  }
}, []);



// console.log(resetAllFiltersApply);

useEffect(() => {
if(count === 0){
  setFiltersArray({
    "Ref" : false,
    "NonRef" : false,
    "NonStop" : false,
    "OneStop" : false,
    "TwoStop" : false,
    "dEarlyMorning" : false,
    "dAfternoon" : false,
    "dEvening" : false,
    "dNight" : false,
    "aEarlyMorning" : false,
    "aAfternoon" : false,
    "aEvening" : false,
    "aNight" : false,
   });
   if(resetAllFiltersApply === true){
    setResetAllFiltersApply(false);
   }
   else {
    setResetAllFiltersApply(true);
   }



if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
 
  if(resetAllFiltersApply === true){
    setResetAllFiltersApply(false);
   }
   else {
    setResetAllFiltersApply(true);
   }
  document.querySelectorAll('[data-refundable]').forEach((fareType, e) => {
    // fareType.classList.add("show_all");
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
   });


   document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
    // dataTime.classList.add("show_all");
    dataTime.classList.remove("hide_itinerary");
   })
 }
}
}, [appliedFilterCount]);

// console.log(appliedFilterCount);

  useEffect(() => {
    

    if(count === 0 && array?.length === 0){
    
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
       
        // setApplyFiltersTrue(false);
        document.querySelectorAll('[data-refundable]').forEach((fareType, e) => {
          fareType.classList.add("show_all");
          fareType.classList.remove('hidee');
          fareType.classList.remove('hideee');
          
        });
        
        
        document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
      
          // dataTime.classList.add("show_all");
          dataTime.classList.remove("hide_itinerary");
        })
        setResetAllFiltersApply(true);
  }
    }
    
    if(count > 0){ 

    
      setActiveCheapest(false);
      setActiveDuration(false);
       document.querySelectorAll('[data-refundable]').forEach((fareType, e) => {
      
         fareType.classList.add("show_all");
         fareType.classList.remove('hideee');
         if(fareType.classList.contains('hidee')){
          fareType.classList.remove('hidee');
         }
      
        //  if(fareType.parentElement){
        //   fareType.parentElement.classList.remove('hideee');
        // }
        //  console.log(fareType);
         if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
          // setApplyFiltersTrue(false);
          setResetAllFiltersApply(true);
    }
    
        //  FareType
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
          if(fareType.dataset.refundable === "ref"){
            fareType.classList.add("show_all");
            fareType.classList.remove('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.remove('hideee');
            }
          }
          else{
            fareType.classList.remove('show_all');
            fareType.classList.add('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.add('hideee');
            }
          }
        
        }
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){

          if(fareType.dataset.refundable === "0"){
            fareType.classList.add("show_all");
            fareType.classList.remove('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.remove('hideee');
            }
          }
          else{
            fareType.classList.remove('show_all');
            fareType.classList.add('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.add('hideee');
            }
          }
        }
        if(filtersArray?.Ref === true && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
          if(fareType.dataset.refundable === "ref" && fareType.dataset.refundable === "0"){
            fareType.classList.add("show_all");
            fareType.classList.remove('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.remove('hideee');
            }
          }
          
        }  


         // Stops
         if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
          
          if(fareType.dataset.stopsSame === "0"){
            fareType.classList.add("show_all");
            fareType.classList.remove('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.remove('hideee');
            }
          }
          else{
            fareType.classList.remove('show_all');
            fareType.classList.add('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.add('hideee');
            }
          }
        }
        if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
          if(fareType.dataset.stopsSame === "1"){
            fareType.classList.add("show_all");
            fareType.classList.remove('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.remove('hideee');
            }
          }
          else{
            fareType.classList.remove('show_all');
            fareType.classList.add('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.add('hideee');
            }
          }
        }
        if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false) {
          if(fareType.dataset.stopsSame === "2"){
            fareType.classList.add("show_all");
            fareType.classList.remove('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.remove('hideee');
            }
          }
          else{
            fareType.classList.remove('show_all');
            fareType.classList.add('hideee');
            if(fareType.parentElement){
              fareType.parentElement.classList.add('hideee');
            }
          }
        }


        // Ref and Stops
      if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    
        if(fareType.dataset.stopsSame === "0" && fareType.dataset.refundable === "ref"){
          fareType.classList.add('show_all');
          fareType.classList.remove('hideee');
          if(fareType.parentElement){
            fareType.parentElement.classList.remove('hideee');
          }
        }
        else{
          fareType.classList.remove('show_all');
          fareType.classList.add('hideee');
          if(fareType.parentElement){
            fareType.parentElement.classList.add('hideee');
          }
        }
        }
      if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    
        if(fareType.dataset.stopsSame === "1" && fareType.dataset.refundable === "ref"){
          fareType.classList.add('show_all');
          fareType.classList.remove('hideee');
          if(fareType.parentElement){
            fareType.parentElement.classList.remove('hideee');
          }
        }
        else{
          fareType.classList.remove('show_all');
          fareType.classList.add('hideee');
          if(fareType.parentElement){
            fareType.parentElement.classList.add('hideee');
          }
        }
    }
  
      if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      
        if(fareType.dataset.stopsSame === "2" && fareType.dataset.refundable === "ref"){
          fareType.classList.add('show_all');
          fareType.classList.remove('hideee');
          if(fareType.parentElement){
            fareType.parentElement.classList.remove('hideee');
          }
        }
        else{
          fareType.classList.remove('show_all');
          fareType.classList.add('hideee');
          if(fareType.parentElement){
            fareType.parentElement.classList.add('hideee');
          }
        }
    }

// Non-Ref and Stops

if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.stopsSame === "0" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
  }
  else{
    fareType.classList.remove('show_all');
    fareType.classList.add('hideee');
  }
}
if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.stopsSame === "1" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
  }
  else{
    fareType.classList.remove('show_all');
    fareType.classList.add('hideee');
  }
}
if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.stopsSame === "2" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
  }
  else{
    fareType.classList.remove('show_all');
    fareType.classList.add('hideee');
  }
}


  // REF and Non-Ref and Stops

  if(filtersArray?.Ref === true && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.stopsSame === "0"){
      if(fareType.dataset.refundable === "ref" && fareType.dataset.refundable === "0"){
      fareType.classList.add("show_all");
      fareType.classList.remove('hideee');
      }
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
    }
  }  

  if(filtersArray?.Ref === true && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.stopsSame === "1"){
      if(fareType.dataset.refundable === "ref" && fareType.dataset.refundable === "0"){
      fareType.classList.add("show_all");
      fareType.classList.remove('hideee');
      }
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
    }
  } 

  if(filtersArray?.Ref === true && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.stopsSame === "2"){
      if(fareType.dataset.refundable === "ref" && fareType.dataset.refundable === "0"){
      fareType.classList.add("show_all");
      fareType.classList.remove('hideee');
      }
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
    }
  } 


  // Departure Time


  if(filtersArray?.dEarlyMorning === "14340" && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.departureTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      // fareType.parentElement.add('hideee');
      fareType.classList.remove('show_all');
      fareType.classList.add('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
   }   

   if(filtersArray?.dAfternoon === "43140" && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
       
    }
    else{
      fareType.classList.remove('show_all');
      fareType.classList.add('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
  }
   } 

   if(filtersArray?.dEvening === "57540" && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.remove('show_all');
      fareType.classList.add('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
   } 

   
   if(filtersArray?.dNight === "86340" && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
     if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00"){
       fareType.classList.add('show_all');
       fareType.classList.remove('hideee');
       if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

       document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:59:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
      }
      else{
        fareType.classList.remove('show_all');
        fareType.classList.add('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
    } 


// console.log(filtersArray);
     //  Arrival Time


     if(filtersArray?.aEarlyMorning === '14340' && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
       if(fareType.dataset.arrivalTime < "03:58:00"){
         fareType.classList.add('show_all');
         fareType.classList.remove('hideee');
         if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
         
         document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
           if(dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.remove('hide_itinerary');
            dataTime.classList.add('show_all');
          }
          else{
            dataTime.classList.add('hide_itinerary');
            dataTime.classList.remove('show_all');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
     }



     if(filtersArray?.aAfternoon === '43140' && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){

      if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
    
        
        document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
            else{

            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
     } 
    

     if(filtersArray?.aEvening === '57540' && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');

          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
            dataTime.classList.remove('hide_itinerary');
            dataTime.classList.add('show_all');
          }
          else{
            dataTime.classList.add('hide_itinerary');
            dataTime.classList.remove('show_all');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
     } 

     
     if(filtersArray?.aNight === '86340' && filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
     }   


     // Ref and Departure Time  
   if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "ref"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');

        if(dataTime.dataset.departureTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "ref"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "ref"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === true && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "ref"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
    document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:59:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}



 // Non-Ref and Departure Time  
 if(filtersArray?.NonRef === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.Ref === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');

    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.departureTimee < "03:59:00" ){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
    
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.NonRef === true && filtersArray?.dAfternoon === "43140" && filtersArray?.Ref === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false  && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');

    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');

    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.NonRef === true && filtersArray?.dEvening === "57540" && filtersArray?.Ref === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "0"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');

  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }

  document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
    dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
    if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}

if(filtersArray?.NonRef === true && filtersArray?.dNight === "86340" && filtersArray?.Ref === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "0"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');

  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }

  document.querySelectorAll('[data-departure-timee]').forEach((dataTime, e) => {
    dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
    if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:59:00"){
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}



   // Ref and Arrival Time  
   if(filtersArray?.Ref === true && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.arrivalTime === "ref"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');

      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.arrivalTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === true && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');

      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === true && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');

    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === true && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false  && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');

    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

 // Non-Ref and Arrival Time  
   if(filtersArray?.NonRef === true && filtersArray?.aEarlyMorning === "14340"  && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.arrivalTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.NonRef === true && filtersArray?.aAfternoon === "43140"  && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
        if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.NonRef === true && filtersArray?.aEvening === "57540" && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.NonRef === true && filtersArray?.aNight === "86340" && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');

    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
      if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


    // Ref and Departure Time and Non-Stop
    if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){

    if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }

  // Departure Time and Non-Stop
  if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00"  && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00"  && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');

    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


  
    // Ref and Departure Time and One-Stop
    if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
    if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }

  
    //Departure Time and One-Stop
    if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime < "03:58:00"  && fareType.dataset.stopsSame === "1"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00"  && fareType.dataset.stopsSame === "1"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
  if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');

      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }
  
  if(filtersArray?.Ref === false && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
    if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }

   // Ref and Departure Time and Two-Stop
   if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === ""){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === true && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


   // Departure Time and Two-Stop
   if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.stopsSame === "2"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.stopsSame === ""){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}




    // Ref and Arrival Time and Non-Stop
    if(filtersArray?.Ref === true && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === true && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false  && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
    if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }



    // Arrival Time and Non-Stop
    if(filtersArray?.Ref === false && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === false && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00"  && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
  if(filtersArray?.Ref === false && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }
  
  if(filtersArray?.Ref === false && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false  && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
    if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }

  
    // Ref and Arrival Time and One-Stop
    if(filtersArray?.Ref === true && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === true && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }
  
  if(filtersArray?.Ref === true && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false  && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
    if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
  }


  // Arrival Time and One-Stop
  if(filtersArray?.Ref === false && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee < "03:59:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00"  && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false  && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

   // Ref and Arrival Time and Two-Stop
   if(filtersArray?.Ref === true && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === true && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === ""){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === true && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === true && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


 // Arrival Time and Two-Stop
 if(filtersArray?.Ref === false && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime < "03:58:00"  && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00"  && fareType.dataset.stopsSame === ""){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false  && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00"  && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}

if(filtersArray?.Ref === false && filtersArray?.aNight === "86340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}
  



   // Non-Ref and Departure Time and Non-Stop
   if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "0"  &&  fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

  if(filtersArray?.Ref == false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "0"  && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dNight === "86340" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}





  // Non-Ref and Departure Time and One-Stop
  if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dNight === "86340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}




 // Non-Ref and Departure Time and Two-Stop
 if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === ""){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.departureTimee < "15:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}

if(filtersArray?.Ref === false && filtersArray?.dNight === "86340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee > "15:59:00" && dataTime.dataset.departureTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}




  // Non-Ref and Arrival Time and Non-Stop
  if(filtersArray?.Ref === false && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.aNight === "86340" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


  // Non-Ref and Arrival Time and One-Stop
  if(filtersArray?.Ref === false && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


if(filtersArray?.Ref === false && filtersArray?.aNight === "86340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false  && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
  
  if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

 // Non-Ref and Arrival Time and Two-Stop
 if(filtersArray?.Ref === false && filtersArray?.aEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true  && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.aAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false  && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === ""){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.aEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dNight === false){
if(fareType.dataset.arrivalTime > "11:59:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}

if(filtersArray?.Ref === false && filtersArray?.aNight === "86340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false){
if(fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.arrivalTimee > "15:59:00" && dataTime.dataset.arrivalTimee < "23:59:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}




    // Ref and Departure Time and Non-Stop and Arrival Time
    if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.arrivalTime > "03:58:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00" && dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }

  if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dAfternoon === false  && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.arrivalTime > "11:58:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === true  && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEvening === false && filtersArray.dAfternoon === false  && filtersArray.dNight === "86340"){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "15:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "23:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


   // Ref and Departure Time and ONe-Stop and Arrival Time
   if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.arrivalTime > "03:58:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00" && dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dAfternoon === false  && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.arrivalTime > "11:58:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === true  && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEvening === false && filtersArray.dAfternoon === false  && filtersArray.dNight === "86340"){
if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "1"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }

  document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
    if(dataTime.dataset.departureTimee < "15:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "23:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}


 // Ref and Departure Time and Two-Stop and Arrival Time
 if(filtersArray?.Ref === true && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === true && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.arrivalTime > "03:58:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00" && dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }

}

if(filtersArray?.Ref === true && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dAfternoon === false  && filtersArray.dNight === false){
if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.arrivalTime > "11:58:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }

  document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
    if(dataTime.dataset.departureTimee < "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}

if(filtersArray?.Ref === true  && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEvening === false && filtersArray.dAfternoon === false  && filtersArray.dNight === "86340"){
if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "2"){
fareType.classList.add('show_all');
fareType.classList.remove('hideee');
if(fareType.parentElement){
  fareType.parentElement.classList.remove('hideee');
}

document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
  if(dataTime.dataset.departureTimee < "15:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "23:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
    dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
  }
  else{
    dataTime.classList.remove('show_all');
    dataTime.classList.add('hide_itinerary');
    }
 })
}
else{
fareType.classList.add('hideee');
fareType.classList.remove('show_all');
if(fareType.parentElement){
  fareType.parentElement.classList.add('hideee');
}
}
}





    // Non-Ref and Departure Time and Non-Stop and Arrival Time
    if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }
  
    if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dNight === false){
      if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.arrivalTime > "03:58:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
        fareType.classList.add('show_all');
        fareType.classList.remove('hideee');
        if(fareType.parentElement){
          fareType.parentElement.classList.remove('hideee');
        }

        document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
          if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00" && dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
            dataTime.classList.add('show_all');
            dataTime.classList.remove('hide_itinerary');
          }
          else{
            dataTime.classList.remove('show_all');
            dataTime.classList.add('hide_itinerary');
            }
         })
      }
      else{
        fareType.classList.add('hideee');
        fareType.classList.remove('show_all');
        if(fareType.parentElement){
          fareType.parentElement.classList.add('hideee');
        }
      }
  }

  if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dAfternoon === false  && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.arrivalTime > "11:58:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false  && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEvening === false && filtersArray.dAfternoon === false  && filtersArray.dNight === "86340"){
  if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "15:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "23:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}


   // Non-Ref and Departure Time and ONe-Stop and Arrival Time
   if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

  if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dNight === false){
    if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.arrivalTime > "03:58:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
      fareType.classList.add('show_all');
      fareType.classList.remove('hideee');
      if(fareType.parentElement){
        fareType.parentElement.classList.remove('hideee');
      }

      document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
        if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00" && dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
          dataTime.classList.add('show_all');
          dataTime.classList.remove('hide_itinerary');
        }
        else{
          dataTime.classList.remove('show_all');
          dataTime.classList.add('hide_itinerary');
          }
       })
    }
    else{
      fareType.classList.add('hideee');
      fareType.classList.remove('show_all');
      if(fareType.parentElement){
        fareType.parentElement.classList.add('hideee');
      }
    }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dAfternoon === false  && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.arrivalTime > "11:58:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false  && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEvening === false && filtersArray.dAfternoon === false  && filtersArray.dNight === "86340"){
if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "1"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }

  document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
    if(dataTime.dataset.departureTimee < "15:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "23:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}


 // Non-Ref and Departure Time and Two-Stop and Arrival Time
 if(filtersArray?.Ref === false && filtersArray?.dEarlyMorning === "14340" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime < "03:58:00" && fareType.dataset.arrivalTime < "03:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dAfternoon === "43140" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dNight === false){
  if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:58:00" && fareType.dataset.arrivalTime > "03:58:00" && fareType.dataset.arrivalTime < "11:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
    fareType.classList.add('show_all');
    fareType.classList.remove('hideee');
    if(fareType.parentElement){
      fareType.parentElement.classList.remove('hideee');
    }

    document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
      if(dataTime.dataset.departureTimee < "03:58:00" && dataTime.dataset.arrivalTimee < "03:58:00" && dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
        dataTime.classList.add('show_all');
        dataTime.classList.remove('hide_itinerary');
      }
      else{
        dataTime.classList.remove('show_all');
        dataTime.classList.add('hide_itinerary');
        }
     })
  }
  else{
    fareType.classList.add('hideee');
    fareType.classList.remove('show_all');
    if(fareType.parentElement){
      fareType.parentElement.classList.add('hideee');
    }
  }
}

if(filtersArray?.Ref === false && filtersArray?.dEvening === "57540" && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dAfternoon === false  && filtersArray.dNight === false){
if(fareType.dataset.departureTime > "11:59:00" && fareType.dataset.departureTime < "15:58:00" && fareType.dataset.arrivalTime > "11:58:00" && fareType.dataset.arrivalTime < "15:58:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
  fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }

  document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
    if(dataTime.dataset.departureTimee < "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "11:59:00" && dataTime.dataset.arrivalTimee < "15:58:00"){
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
}
else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
}
}

if(filtersArray?.Ref === false  && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEvening === false && filtersArray.dAfternoon === false  && filtersArray.dNight === "86340"){
if(fareType.dataset.departureTime > "15:59:00" && fareType.dataset.departureTime < "23:59:00" && fareType.dataset.arrivalTime > "15:59:00" && fareType.dataset.arrivalTime < "23:59:00" && fareType.dataset.refundable === "0" && fareType.dataset.stopsSame === "2"){
fareType.classList.add('show_all');
fareType.classList.remove('hideee');
if(fareType.parentElement){
  fareType.parentElement.classList.remove('hideee');
}

document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
  if(dataTime.dataset.departureTimee < "15:59:00" && dataTime.dataset.arrivalTimee < "15:58:00" && dataTime.dataset.departureTimee > "23:59:00" && dataTime.dataset.arrivalTimee < "23:58:00"){
    dataTime.classList.add('show_all');
    dataTime.classList.remove('hide_itinerary');
  }
  else{
    dataTime.classList.remove('show_all');
    dataTime.classList.add('hide_itinerary');
    }
 })
}
else{
fareType.classList.add('hideee');
fareType.classList.remove('show_all');
if(fareType.parentElement){
  fareType.parentElement.classList.add('hideee');
}
}
}

// Departure Afternoon, Arrival Afternoon, Ref and Non Stop
if(filtersArray?.Ref === true  && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false  && filtersArray.aAfternoon !== false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dAfternoon !== false  && filtersArray.dNight === false){

  if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:59:00" && fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:59:00" && fareType.dataset.refundable === "ref" && fareType.dataset.stopsSame === "0"){
    fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
  
  document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
    if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00" && dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
 
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
  }
  else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
  }
  }

// Departure Afternoon, Arrival Afternoon, Ref
if(filtersArray?.Ref === true  && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false  && filtersArray.aAfternoon !== false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEvening === false && filtersArray.dAfternoon !== false  && filtersArray.dNight === false){

  if(fareType.dataset.departureTime > "03:59:00" && fareType.dataset.departureTime < "11:59:00" && fareType.dataset.arrivalTime > "03:59:00" && fareType.dataset.arrivalTime < "11:59:00" && fareType.dataset.refundable === "ref"){
    fareType.classList.add('show_all');
  fareType.classList.remove('hideee');
  if(fareType.parentElement){
    fareType.parentElement.classList.remove('hideee');
  }
  
  document.querySelectorAll('[data-arrival-timee]').forEach((dataTime, e) => {
    if(dataTime.dataset.departureTimee > "03:59:00" && dataTime.dataset.departureTimee < "11:58:00" && dataTime.dataset.arrivalTimee > "03:59:00" && dataTime.dataset.arrivalTimee < "11:58:00"){
 
      dataTime.classList.add('show_all');
      dataTime.classList.remove('hide_itinerary');
    }
    else{
      dataTime.classList.remove('show_all');
      dataTime.classList.add('hide_itinerary');
      }
   })
  }
  else{
  fareType.classList.add('hideee');
  fareType.classList.remove('show_all');
  if(fareType.parentElement){
    fareType.parentElement.classList.add('hideee');
  }
  }
  }  
    
  



    // filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false
      })

    
    
      if(array && array?.length > 0){
    // Ref and Airline
    if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
      document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
        if(itineraries.parentElement){
          itineraries.parentElement.classList.remove('hideee');
        } 
        itineraries.classList.add('show_all');
        itineraries.classList.remove('hideee');
          if (array.includes(itineraries.dataset.airline) && itineraries.dataset.refundable === "ref") {
            itineraries.classList.add("show_all");
            itineraries.classList.remove("hideee");
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            } 
          } 
          else {
            itineraries.classList.remove("show_all");
            itineraries.classList.add("hideee");
            if(itineraries.parentElement){
              itineraries.parentElement.classList.add('hideee');
            }
          }
        
      });
    }
    
    // Non Ref and Airline
    if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
      document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            if(itineraries.parentElement){
        itineraries.parentElement.classList.remove('hideee');
      } 
      itineraries.classList.add('show_all');
      itineraries.classList.remove('hideee');
    
          if (array.includes(itineraries.dataset.airline) && itineraries.dataset.refundable === "0") {
            itineraries.classList.add("show_all");
            itineraries.classList.remove("hide");
          } 
          else {
            itineraries.classList.remove("show_all");
            itineraries.classList.add("hide");
            if(itineraries.parentElement){
              itineraries.parentElement.classList.add('hideee');
            }
          }
        
      });
    }
    
       // Non-Stop and Airline
       if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          if(itineraries.parentElement){
            itineraries.parentElement.classList.remove('hideee');
          } 
          itineraries.classList.add('show_all');
          itineraries.classList.remove('hideee');
    
            if (array.includes(itineraries.dataset.airline) && itineraries.dataset.stopsSame === "0") {
              itineraries.classList.add("show_all");
              itineraries.classList.remove("hide");
            } 
            else {
              itineraries.classList.remove("show_all");
              itineraries.classList.add("hide");
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
        });
      }
    
      // One-Stop and Airline
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          if(itineraries.parentElement){
            itineraries.parentElement.classList.remove('hideee');
          } 
          itineraries.classList.add('show_all');
          itineraries.classList.remove('hideee');
    
            if (array.includes(itineraries.dataset.airline) && itineraries.dataset.stopsSame === "1") {
              itineraries.classList.add("show_all");
              itineraries.classList.remove("hide");
            } 
            else {
              itineraries.classList.remove("show_all");
              itineraries.classList.add("hide");
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
          
        });
      }
    
      // Two-Stop and Airline
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          if(itineraries.parentElement){
            itineraries.parentElement.classList.remove('hideee');
          } 
          itineraries.classList.add('show_all');
          itineraries.classList.remove('hideee');
    
            if (array.includes(itineraries.dataset.airline) && itineraries.dataset.stopsSame === "2") {
              itineraries.classList.add("show_all");
              itineraries.classList.remove("hide");
            } 
            else {
              itineraries.classList.remove("show_all");
              itineraries.classList.add("hide");
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
          
        });
      }
    
        // Departure Emorning and Airline
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
           itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          if(itineraries.dataset.departureTime < "03:58:00" &&  array.includes(itineraries.dataset.airline)){
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          }
          else{
            itineraries.classList.remove('show_all');
            itineraries.classList.add('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.add('hideee');
            }
          }
          
        });
      }
    
      // Departure Afternoon and Airline
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          if(itineraries.dataset.departureTime > "03:59:00" && itineraries.dataset.departureTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          }
          else{
            itineraries.classList.remove('show_all');
            itineraries.classList.add('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.add('hideee');
            }
          }
          
        });
      }
    
      // Departure Evening and Airline
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          itineraries.classList.add('show_all');
          itineraries.classList.remove('hideee');
          if(itineraries.parentElement){
            itineraries.parentElement.classList.remove('hideee');
          }
          if(itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          }
          else{
            itineraries.classList.remove('show_all');
            itineraries.classList.add('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.add('hideee');
            }
          }
          
        });
      }
    
      // Departure Night and Airline
      if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
        document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
          itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          if(itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:59:00" && array.includes(itineraries.dataset.airline)){
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
          }
          else{
            itineraries.classList.remove('show_all');
            itineraries.classList.add('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.add('hideee');
            }
          }
          
        });
      }
    
    
          // Arrival Emorning and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
               itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              if(itineraries.dataset.arrivalTime < "03:58:00" &&  array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
        
          // Arrival Afternoon and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              if(itineraries.dataset.arrivalTime > "03:59:00" && itineraries.dataset.arrivalTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
        
          // Arrival Evening and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
        
          // Arrival Night and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:59:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
          // Ref, Non-Stop and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
          // Ref, One-Stop and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
            // Ref, Two-Stop and Airline
            if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
              document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
                if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && array.includes(itineraries.dataset.airline)){
                  itineraries.classList.add('show_all');
                  itineraries.classList.remove('hideee');
                  if(itineraries.parentElement){
                    itineraries.parentElement.classList.remove('hideee');
                  }
                }
                else{
                  itineraries.classList.remove('show_all');
                  itineraries.classList.add('hideee');
                  if(itineraries.parentElement){
                    itineraries.parentElement.classList.add('hideee');
                  }
                }
                
              });
            }
         
         // Non-Ref, Non-Stop and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        } 
        
        // Non-Ref, One-Stop and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        } 
    
         // Non-Ref, Two-Stop and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        } 
    
        // Ref, Non-Stop, Departure EMorning and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Departure EMorning and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Departure EMorning and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
        // Ref, Non-Stop, Departure Afternoon and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime > "03:58:00" && itineraries.dataset.departureTime < "11:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Departure Afternoon and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime > "03:58:00" && itineraries.dataset.departureTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Departure Afternoon and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime > "03:58:00" && itineraries.dataset.departureTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
          // Ref, Non-Stop, Departure Evening and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Departure Evening and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Departure Evening and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
            // Ref, Non-Stop, Departure Night and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Departure Night and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:59:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Departure Night and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
         // Non-Ref, Non-Stop, Departure EMorning and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Departure EMorning and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Non-Ref, Two-Stop, Departure EMorning and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === "14340" && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }  
    
    
            // Non-Ref, Non-Stop, Departure Afternoon and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime > "03:58:00" && itineraries.dataset.departureTime < "11:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Departure Afternoon and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime > "03:58:00" && itineraries.dataset.departureTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Non-Ref, Two-Stop, Departure Afternoon and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === "43140" && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime > "03:58:00" && itineraries.dataset.departureTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
                // Non-Ref, Non-Stop, Departure Evening and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Departure Evening and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          //Non-Ref, Two-Stop, Departure Evening and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === "57540" && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime > "11:59:00" && itineraries.dataset.departureTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
                  // Non-Ref, Non-Stop, Departure Night and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Departure Night and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:59:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Departure Night and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === "86340" ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.departureTime > "15:59:00" && itineraries.dataset.departureTime < "23:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
        
        // Ref, Non-Stop, Arrival EMorning and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Arrival EMorning and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Arrival EMorning and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
        // Ref, Non-Stop, Arrival Afternoon and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime > "03:58:00" && itineraries.dataset.arrivalTime < "11:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Arrival Afternoon and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime > "03:58:00" && itineraries.dataset.arrivalTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Arrival Afternoon and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime > "03:58:00" && itineraries.dataset.arrivalTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
          // Ref, Non-Stop, Arrival Evening and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Arrival Evening and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, Arrival Evening and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
            // Ref, Non-Stop, Arrival Night and Airline
        if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Ref, One-Stop, Arrival Night and Airline
         if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:59:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Ref, Two-Stop, arriavl Night and Airline
          if(filtersArray?.Ref === true && filtersArray?.NonRef === false && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "ref" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
         // Non-Ref, Non-Stop, Arrival EMorning and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Arrival EMorning and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Non-Ref, Two-Stop, Arrival EMorning and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === "14340" && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime < "03:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }  
    
    
            // Non-Ref, Non-Stop, Arrival Afternoon and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime > "03:58:00" && itineraries.dataset.arrivalTime < "11:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Departure Afternoon and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime > "03:58:00" && itineraries.dataset.arrivalTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Non-Ref, Two-Stop, Departure Afternoon and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === "43140" && filtersArray.aEvening === false && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime > "03:58:00" && itineraries.dataset.arrivalTime < "11:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
                // Non-Ref, Non-Stop, Departure Evening and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Departure Evening and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          //Non-Ref, Two-Stop, Departure Evening and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === "57540" && filtersArray.aNight === false && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime > "11:59:00" && itineraries.dataset.arrivalTime < "15:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }
    
    
                  // Non-Ref, Non-Stop, Departure Night and Airline
        if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === true && filtersArray.OneStop === false && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "0" && itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:58:00" &&  array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
         // Non-Ref, One-Stop, Departure Night and Airline
         if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === true && filtersArray.TwoStop === false && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
          document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
            itineraries.classList.add('show_all');
            itineraries.classList.remove('hideee');
            if(itineraries.parentElement){
              itineraries.parentElement.classList.remove('hideee');
            }
            if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "1" && itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:59:00" && array.includes(itineraries.dataset.airline)){
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
            }
            else{
              itineraries.classList.remove('show_all');
              itineraries.classList.add('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.add('hideee');
              }
            }
            
          });
        }
    
          // Non-Ref, Two-Stop, Departure Night and Airline
          if(filtersArray?.Ref === false && filtersArray?.NonRef === true && filtersArray?.NonStop === false && filtersArray.OneStop === false && filtersArray.TwoStop === true && filtersArray.aEarlyMorning === false && filtersArray.aAfternoon === false && filtersArray.aEvening === false && filtersArray.aNight === "86340" && filtersArray.dEarlyMorning === false && filtersArray.dAfternoon === false && filtersArray.dEvening === false && filtersArray.dNight === false ){
           
            document.querySelectorAll("[data-price]").forEach((itineraries, e) => {
              itineraries.classList.add('show_all');
              itineraries.classList.remove('hideee');
              if(itineraries.parentElement){
                itineraries.parentElement.classList.remove('hideee');
              }
              if(itineraries.dataset.refundable === "0" && itineraries.dataset.stopsSame === "2" && itineraries.dataset.arrivalTime > "15:59:00" && itineraries.dataset.arrivalTime < "23:58:00" && array.includes(itineraries.dataset.airline)){
                itineraries.classList.add('show_all');
                itineraries.classList.remove('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.remove('hideee');
                }
              }
              else{
                itineraries.classList.remove('show_all');
                itineraries.classList.add('hideee');
                if(itineraries.parentElement){
                  itineraries.parentElement.classList.add('hideee');
                }
              }
              
            });
          }  
    
    
      }
    
    


    }  


  }, [filtersArray, array]);


  // useEffect(() => {
  //   if(searchResultsCopy?.length > 0){
  //     setPriceSortingValue('Low');
  //   }
  // }, [searchResultsCopy]);
 

const [cheapestPrice, setCheapestPrice] = useState('');
const [shortestPrice, setShortestPrice] = useState('');



// console.log(searchResults);

const topFilters = (e) => {
  // if(isMobile){
if(e === "Departure" && filtersArray?.dAfternoon === false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "dAfternoon" : "43140",
    "aAfternoon" : false,
    }));
}

if(e === "Departure" && filtersArray?.dAfternoon !== false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "dAfternoon" : false,
    "aAfternoon" : false,
    }));
}

if(e === "Arrival" && filtersArray?.aAfternoon === false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "aAfternoon" : "43140",
    "dAfternoon" : false,
    }));
}

if(e === "Arrival" && filtersArray?.aAfternoon !== false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "aAfternoon" : false,
    "dAfternoon" : false,
    }));
}

if(e === "NonStop" && filtersArray?.NonStop === false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "NonStop" : true,
    "OneStop" : false,
    "TwoStop" : false
    }));
}

if(e === "NonStop" && filtersArray?.NonStop !== false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "NonStop" : false,
    }));
}

if(e === "Ref" && filtersArray?.Ref === false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "Ref" : true,
    }));
}

if(e === "Ref" && filtersArray?.Ref !== false){
  setDurationSorting('');
  setPriceSortingValue('');
  setFiltersArray((prevFilters) => ({
    ...prevFilters,
    "Ref" : false,
    }));
}

  // }
}


const [activeCheapest, setActiveCheapest] = useState(false);
const [activeDuration , setActiveDuration] = useState(false);

const callActiveFunction = (e) => {
  if(isMobile){
  if(e === "Cheapest"){
    setActiveCheapest(true);
    setActiveDuration(false);
      setTimeout(() => {
        setFiltersArray((prevFilters) => ({
          "Ref" : false,
          "NonRef" : false,
          "NonStop" : false,
          "OneStop" : false,
          "TwoStop" : false,
          "dEarlyMorning" : false,
          "dAfternoon" : false,
          "dEvening" : false,
          "dNight" : false,
          "aEarlyMorning" : false,
          "aAfternoon" : false,
          "aEvening" : false,
          "aNight" : false,
          }));
        setDurationSorting('');
        setPriceSortingValue('High');
      }, 1000)
    setArray();
  }
  if(e === "Duration"){
    setActiveDuration(true);
    setActiveCheapest(false);
    setDurationSorting('Short');
    setFiltersArray((prevFilters) => ({
      "Ref" : false,
      "NonRef" : false,
      "NonStop" : false,
      "OneStop" : false,
      "TwoStop" : false,
      "dEarlyMorning" : false,
      "dAfternoon" : false,
      "dEvening" : false,
      "dNight" : false,
      "aEarlyMorning" : false,
      "aAfternoon" : false,
      "aEvening" : false,
      "aNight" : false,
      }));
      setArray();
  }
}
}


const [filterCount, setFilterCount] = useState(0);

useEffect(() => {
  if(appliedFilterCount > 0 && array === undefined || array?.length < 1){
    setFilterCount(appliedFilterCount);
  }
  if(appliedFilterCount < 1 && array === undefined && array?.length > 0){
    setFilterCount(array?.length)
  }
  if(appliedFilterCount > 0 && array !== undefined || array?.length > 0){
    setFilterCount(appliedFilterCount + array?.length);
  }

  if((appliedFilterCount === 0 && array === undefined) || (appliedFilterCount === 0 && array?.length < 1 )){
    setFilterCount(0);
  }

}, [array, appliedFilterCount, filtersArray])


useEffect(() => {
  window.dataLayer = window.dataLayer || [];

  if(from && to && fromDate){
      window?.dataLayer?.push({

        event: 'page_view',

        page_location: `${window.location.host}${window.location.pathname}`,

        page_title: 'Flight Search',

        origin: from,

        destination: to,

        departure_date: String(fromDate).substring(4,16),

        arrival_date: tripType === 1 ? String(toDate).substring(4,16) : "" ,

      });
    }    
}, [])

// console.log(window.dataLayer);


  return (
    <>
    {showErrorPage === true ?
      <SearchResultsError fromAirport={fromAirport} toAirport={toAirport} fromDate={fromDate} toDate={toDate} departureFrom={from} arrivalTo={to} tripType={tripType}  />
      :
      null
}
{isMobile ? (
        <div className="m_search_query_title">
          <button
            type="button"
            className="big_back_button"
            aria-label="Back"
            onClick={() => {
              window.history.back();
              setIsFlightDetailsOpen(false);
            }}
          ></button>
          <div className="m_search_query_row">
            <div className="m_search_query_flight">
              {searchResults.length !== 0 ? <strong>{searchResults[0].Itineraries[0].Leg[0].Segements[0].departureAirport.cityName} </strong> : <strong>{fromAirport}</strong>}
              {fromAirport && toAirport ? <span>
                {tripType === 1
                  ? String.fromCharCode(8651)
                  : String.fromCharCode(8594)}
                  {/*  */}
              </span> : null}
              {searchResults.length !== 0 ? <strong>{searchResults[0].Itineraries[0].Leg[0].Segements[searchResults[0].Itineraries[0].Leg[0].Segements.length - 1].arrivalAirport.cityName}</strong> : <strong>{toAirport}</strong>}
            </div>
            <div className="m_search_query_dates_passengers">
              <small>
                {fromDate ? new Date(fromDate)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") : null}
                {fromDate ? ` - ` : null}

                {tripType === 1 && toDate ? new Date(toDate).toDateString().split(" ").slice(1).join(" ") : null}
                {tripType === 1 && toDate ? ` - ` : null}

                {passengers.adultCount ? passengers.adultCount + passengers.childrenCount + passengers.infantsCount : ''} {passengers.adultCount < 2 ? 'Traveler' : 'Travelers'}
              </small>
            </div>
          </div>
          <button type="button" className="edit_form_button" onClick={()=>editSearch()}></button>
        </div>
      ) : null}
      {isMobile ? <div className="tab_group">
        <button type="button" className={activeCheapest ?  "tab_group_btn tab_group_btn_active" : "tab_group_btn"}
        onClick={() => {callActiveFunction('Cheapest') }}
        >
          <span className={activeCheapest ? "green-text" : ""}>Cheapest</span>
          <strong className={activeCheapest ? "green-text" : ""}>{cheapestPrice ? process.env.REACT_APP_CURRENCY : null} {' '} {cheapestPrice}</strong>
        </button>
        {/* <button type="button" className="tab_group_btn">
          <span>Recommended</span>
          <strong>SAR 765</strong>
        </button> */}
        <button type="button" className={activeDuration ? "tab_group_btn tab_group_btn_active" : "tab_group_btn" }
        onClick={() => {callActiveFunction('Duration')}}
        >
          <span className={activeDuration ? "green-text" : ""}>Shortest</span>
          <strong className={activeDuration ? "green-text" : ""}>{shortestPrice ? process.env.REACT_APP_CURRENCY : null} {shortestPrice}</strong>
        </button>
      </div> : ''}
      {isMobile ? 
      <div className="filter_tabs">
      {/* <button type="button">
          <strong>Checked baggage included</strong>
        </button> */}
        <button type="button" className={filtersArray?.dAfternoon !== false ? "active_morning morning" :"morning"}
        onClick={() => {
          topFilters('Departure');
        }}
        >
          <strong>Departure Time</strong>
          <span>04:00 - 11:59</span>
        </button>
        <button type="button" className={filtersArray?.aAfternoon !== false ? "active_morning morning" :"morning"}
        onClick={() => {
          topFilters('Arrival');
        }}
        >
          <strong>Arrival Time</strong>
          <span>04:00 - 11:59</span>
        </button>
        <button type="button" className={filtersArray?.NonStop === true ? "active " :""}
        onClick={() => {
          topFilters('NonStop');
        }}
        >
          <strong>Non-Stop</strong>
        </button>
        <button type="button" className={filtersArray?.Ref === true ? "active" :""}
        onClick={() => {
          topFilters('Ref');
        }}
        >
          <strong>Refundable</strong>
        </button>
        <button className="filter_tabs_more_button" onClick={() => {
              openFilters();
              // setFiltersArray({
              //   "Ref" : false,
              //   "NonRef" : false,
              //   "NonStop" : false,
              //   "OneStop" : false,
              //   "TwoStop" : false,
              //   "dEarlyMorning" : false,
              //   "dAfternoon" : false,
              //   "dEvening" : false,
              //   "dNight" : false,
              //   "aEarlyMorning" : false,
              //   "aAfternoon" : false,
              //   "aEvening" : false,
              //   "aNight" : false,
              //  });
            }}>
          <span>+</span> More Filters
        </button>
      </div>
      : ''}
      {!isMobile ? <SearchForm isDisabled={isDisabled} /> : null}
      {/* {!isMobile && birdLoader === true ?
    <BirdLoader fromAirport={fromAirport} toAirport={toAirport} fromDate={fromDate} toDate={toDate} departureFrom={from} arrivalTo={to} tripType={tripType}  />
    :
    null  
    } */}
      {!isMobile ? (
        <BreadCrumbs fromAirport={fromAirport} toAirport={toAirport} />
      ) : null}
      {!isMobile ? (
        <SearchResultsAirlines searchResults={searchResults} />
      ) : null}
      {renderFlightDetails && (
        <FlightDetailsPopUp
        // <FlightDetails
          renderFlightDetails={renderFlightDetails}
          setRenderFlightDetails={setRenderFlightDetails}
          getItineraries={getItineraries}
        />
      )}
      <section className="container result_section" dir={locale === "ar" ? "rtl" : "ltr"} >
        <div className="row">
          <div className="col-12 col-md-12 col-lg-3">
            <SearchFilters
              searchResults={searchResults}
              closeFiltersSorting={closeFiltersSorting}
              mfilters={mfilters}
              openFilters={openFilters}
              msorting={msorting}
              openSorting={openSorting}
              handleFilterChange={handleFilterChange}
              appliedFilterCount={appliedFilterCount}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-9 pl-0 pr-0">
            <SearchSorting
              closeFiltersSorting={closeFiltersSorting}
              msorting={msorting}
              openSorting={openSorting}
              mfilters={mfilters}
              openFilters={openFilters}
              
            />
            <div className="the_results">
              { 
              searchResults?.length !== 0 &&
                priceFilter[0] !== 0 &&
                priceFilter[1] !== 0
                ? searchResults
                  ?.filter((searchResult) => {
                    return (
                      searchResult.price >= priceFilter[0] &&
                      searchResult.price <= priceFilter[1]
                    );
                  })
                  .map((searchResult) => {
                    return (
                      <SearchResultSnippet
                        showFlightDetails={showFlightDetails}
                        searchType={searchType}
                        searchResult={searchResult}
                        key={searchResult.groupKey}
                        getItineraries={getItineraries}
                      />
                    );
                  })
                : <ResultSnippetSkeleton searchResults={searchResults}  />}
            </div>
          </div>
        </div>
      </section>

      <BackToTopButton />

      {isMobile ? (
        <div className="m_filter_tabs">
          <button
            type="button"
            aria-label="Filters"
            className="m_filter_btn"
            id="filters_button"
            onClick={() => {
              openFilters();
            }}
          >
            <svg viewBox="0 0 44 44">
              <g transform="translate(-53 -84)">
                <g transform="translate(67 98)">
                  <g transform="translate(0.292 0.292)">
                    <path
                      className="a"
                      d="M15.917.5H.5L7.2,8.6v7.313l2.011-2.011V8.6Z"
                      transform="translate(-0.5 -0.5)"
                    ></path>
                  </g>
                  <rect className="b" width="16" height="16"></rect>
                </g>
                <rect
                  className="b"
                  width="44"
                  height="44"
                  transform="translate(53 84)"
                ></rect>
              </g>
            </svg>
            {t('Filter')} {' '}
            {/* {appliedFilterCount > 0 && array?.length < 1 ? <span style={{backgroundColor:"orange", borderRadius:"50%", padding:"3px"}}>{appliedFilterCount}</span> : appliedFilterCount > 0 && array?.length > 0 ? <span style={{backgroundColor:"orange", borderRadius:"50%", padding:"3px"}}>{appliedFilterCount + array?.length}</span> : appliedFilterCount < 1 && array?.length > 0 ? <span style={{backgroundColor:"orange", borderRadius:"50%", padding:"3px"}}>{array?.length}</span> : null}  */}
         {filterCount > 0 ?
          <span style={{backgroundColor:"orange", borderRadius:"50%", padding:"3px"}}>{filterCount}</span>
          :
          ' '
         }
          </button>
          <button
            type="button"
            aria-label="Sorting"
            className="m_sort_btn"
            id="sort_button"
            onClick={() => {
              openSorting();
            }}
          >
            <svg viewBox="0 0 44 44">
              <g transform="translate(-221 -75)">
                <rect
                  className="a"
                  width="44"
                  height="44"
                  transform="translate(221 75)"
                ></rect>
                <g transform="translate(-15736.5 162)">
                  <line
                    className="b"
                    x2="12"
                    transform="translate(15970.5 -64.5)"
                  ></line>
                  <path
                    className="c"
                    d="M0,0H19"
                    transform="translate(15970.5 -70.5)"
                  ></path>
                  <line
                    className="b"
                    x2="8"
                    transform="translate(15970.5 -58.5)"
                  ></line>
                </g>
              </g>
            </svg>
            {t('Sorting')}
          </button>
        </div>
      ) : null}
     
    </>
  );
}

export default SearchResultsPage;
