import "../../css/contact_us.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Saudi from "../../img/sa.svg";
import Pakistan from "../../img/pk.svg";
import Bahrain from "../../img/bh.svg";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function ContactUs() {
  const { lang, locale, setLocale } = useGlobals();
  const navigate = useNavigate();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("Feedback");
  const [bookingRef, setBookingRef] = useState("");
  const [travelDate, setTravelDate] = useState("");
  const [description, setDescription] = useState("");

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const submitBtn = (e) => {
    e.preventDefault();
    fetch(`https://uatfl2.eilago.com/api/ContactUs/AddContactUs`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        bookingRef: bookingRef,
        name: name,
        email: email,
        category: category,
        travelDate: travelDate,
        description: description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSuccess(data.responseModel);
      })
      .catch((err) => {
        console.log(err.message);
        setError(err.message);
      });
  };

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        // navigate(`/en/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");
        setLocale("ar");
        // navigate(`/ar/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 1000);
  }, []);
  

  return (
    <div className="contact_us">
      <div className="row top_bg">
        <div
          className={
            locale === "ar"
              ? "col-md-12 col-lg-12 title_header_arabic"
              : "col-md-12 col-lg-12 title_header"
          }
        >
          <h1>{t("Contact Us")}</h1>
        </div>
        <hr className="line_break" />
      </div>

      <div
        className="container mt-5 px-10"
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8  ">
            <main>
              <form onSubmit={submitBtn} className="contact_form">
                <div className="container">
                  <div className="row">
                    <div className="col col-12 mb-4">
                      <p className={locale === "ar" ? "text-left-arabic" : ""}>
                        * {t("All fields are required")}
                      </p>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="name">
                        <label
                          className={
                            locale === "ar"
                              ? "text-left-arabic email-label"
                              : "email-label"
                          }
                          htmlFor="name"
                        >
                          {t("Name")} *
                        </label>
                        <br />
                        <input
                          type="text"
                          id="name"
                          className="name-input"
                          placeholder={t("Name")}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="email">
                        <label
                          className={
                            locale === "ar"
                              ? "text-left-arabic email-label"
                              : "email-label"
                          }
                          htmlFor="email"
                        >
                          {t("Email")} *
                        </label>
                        <br />
                        <input
                          type="email"
                          id="email"
                          className="email-input"
                          placeholder={t("Email")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label
                        htmlFor="main-category"
                        className={
                          locale === "ar"
                            ? "text-left-arabic category-label"
                            : "category-label"
                        }
                      >
                        {" "}
                        {t("Select Category")} *
                      </label>
                      <br />
                      <select
                        className="main-category"
                        id="main-category"
                        style={
                          locale === "ar"
                            ? { paddingRight: "40px" }
                            : { paddingRight: "0px" }
                        }
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      >
                        <option disabled="disabled">
                          {t("Select Category")}
                        </option>
                        <option value="feedback">{t("Feedback")}</option>
                        <option value="support">{t("Support Dropdown")}</option>
                        <option value="others">{t("Others Dropdown")}</option>
                      </select>
                    </div>

                    <div className="col-md-6 mb-4">
                      <label
                        htmlFor="sub-category"
                        className={
                          locale === "ar"
                            ? "text-left-arabic sub-category-label"
                            : "sub-category-label"
                        }
                      >
                        {" "}
                        {t("Select Sub Category")} *
                      </label>
                      <br />
                      <select
                        className="sub-category"
                        id="sub-category"
                        placeholder="Sub Category"
                        style={
                          locale === "ar"
                            ? { paddingRight: "40px" }
                            : { paddingRight: "0px" }
                        }
                      >
                        <option disabled="disabled">
                          {t("Select Sub Category")}
                        </option>
                        <option value="feedback">{t("Feedback")}</option>
                        <option value="support">{t("Support Dropdown")}</option>
                        <option value="others">{t("Others Dropdown")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="booking-ref">
                        <label
                          className={
                            locale === "ar"
                              ? "text-left-arabic booking-ref-label"
                              : "booking-ref-label"
                          }
                          htmlFor="ref-no"
                        >
                          {t("Booking Ref No")} *
                        </label>
                        <br />
                        <input
                          type="text"
                          id="ref-no"
                          className="booking-ref-input"
                          placeholder={t("Booking Ref No")}
                          value={bookingRef}
                          onChange={(e) => setBookingRef(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="travel-date">
                        <label
                          className={
                            locale === "ar"
                              ? "text-left-arabic travel-date-label"
                              : "travel-date-label"
                          }
                          htmlFor="travel-date"
                        >
                          {t("Travel Date")} *
                        </label>
                        <br />
                        <input
                          type="date"
                          id="travel-date"
                          className="travel-date-input"
                          placeholder={t("Travel Date")}
                          value={travelDate}
                          onChange={(e) => setTravelDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="description">
                        <label
                          className={
                            locale === "ar"
                              ? "text-left-arabic your-message-label"
                              : "your-message-label"
                          }
                          htmlFor="your-message"
                        >
                          {t("Your Message")} *
                        </label>
                        <br />
                        <textarea
                          name="description"
                          id="your-message"
                          cols="30"
                          rows="5"
                          style={
                            locale === "ar"
                              ? { paddingRight: "15px" }
                              : { paddingRight: "0px" }
                          }
                          placeholder={t("Message Description")}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 mb-4">
                      <div className="btn">
                        <button className="send-btn" type="submit">
                          {" "}
                          {t("Send")}{" "}
                        </button>
                      </div>
                    </div>

                    {success !== "" ? (
                      <div className="col col-12">
                        <div className="success">{success}</div>
                      </div>
                    ) : null}
                    {error !== "" ? (
                      <div className="col col-12">
                        <div className="error">{error}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </form>
            </main>
          </div>

          <div
            className="col-sm-12 col-md-12 col-lg-4  side-nav"
            dir={locale === "ar" ? "rtl" : "ltr"}
          >
            <nav className="side_nav">
              <p>{t("Self-Help")}</p>
              <ul>
                <li className="li_options">
                  <Link to={`/${locale}/about_us`}> {t("About Us")} </Link>
                </li>
                <li className="li_options active">
                  <Link to={`/${locale}/contact_us`}> {t("Contact Us")} </Link>
                </li>
                <li className="li_options ">
                  <Link to={`/${locale}/faq`}> {t("FAQ")} </Link>
                </li>
                <li className="li_options">
                  <Link to={`/${locale}/terms_conditions`}>
                    {" "}
                    {t("Terms Conditions")}{" "}
                  </Link>
                </li>
                <li className="li_options">
                  <Link to={`/${locale}/privacy_policy`}>
                    {" "}
                    {t("Privacy Policy")}{" "}
                  </Link>
                </li>
              </ul>
            </nav>

            <div className="support_nav">
              <div className="header_dropdown">
                <ul>
                  <li>
                    <a href="tel:+966 11520 4955">
                      <span
                        style={
                          locale === "ar"
                            ? { float: "right" }
                            : { float: "right" }
                        }
                      >
                        <img src={Saudi} alt="" />
                      </span>
                      <span>
                        <small>{t("Saudi Arabia")}</small>
                        <small dir="ltr">+966 11520 4955</small>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+973 1330 2121">
                      <span
                        style={
                          locale === "ar"
                            ? { float: "right" }
                            : { float: "right" }
                        }
                      >
                        <img src={Bahrain} alt="" />
                      </span>
                      <span>
                        <small>{t("Bahrain")}</small>
                        <small dir="ltr">+973 1330 2121</small>
                      </span>
                    </a>
                  </li>
                  <li className="pakistan">
                    <a href="tel:+92 5127 28821">
                      <span
                        style={
                          locale === "ar"
                            ? { float: "right" }
                            : { float: "right" }
                        }
                      >
                        <img src={Pakistan} alt="" />
                      </span>
                      <span>
                        <small>{t("Pakistan")}</small>
                        <small dir="ltr">+92 5127 28821</small>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactUs;
