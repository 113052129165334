import { isMobile } from 'react-device-detect';

import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";

import '../../css/header.css'
import { useGlobals } from "../../contexts/globalContexts"
import { Link } from 'react-router-dom';
import { useSSR, useTranslation } from 'react-i18next';
import CloseIcon from "../../img/close-btn.svg";
import CloseIconD from "../../img/d/big_close_icon.svg"
import { useEffect, useState } from 'react';
import AlertIcon from "../../img/alert.png"


function Header({ currency, setLang, setCurrency }) {
    const {mobileMenu, setMobileMenu,lang,locale } = useGlobals()

    const openMobileNav = () => { setMobileMenu(prevCheck => !prevCheck) }

    const [show, setShow] = useState(true);

    const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);

  };

  let path;
  useEffect(() => {
    setTimeout(() => {
      if (locale === "en") {
        changeLanguage("en");
        document.documentElement.setAttribute("lang", "en");
      }
      if (locale === "ar") {
        changeLanguage("ar");
        // navigate('/ar');
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, [locale]);

    return (
        <>
        
        <header dir={locale === "ar" ? "rtl" : "ltr"}>
            {/* {isMobile ? <Link to="/my_bookings">
                <button aria-label="My Bookings" type="button" className="my_bookings">
                <span className="badge">0</span>
            </button> 
            </Link>: null} */}
            <Logo />
            {isMobile ? <button aria-label="Navigation" onClick={() => openMobileNav()} type="button" className={mobileMenu ? 'mobile_menu_icon close' : 'mobile_menu_icon'} id="mobile_menu_icon" tabIndex={1}>
            </button> : null}
            {/* nav */}
            <Navigation lang={lang} currency={currency} setLang={setLang} setCurrency={setCurrency} />
        </header>

        </>
    );
}

export default Header;