import Steps from "../../img/step2.svg";
import StepsArabic from "../../img/step2Arabic.png";
import StepsDark from "../../img/d/step2.svg";
import { useGlobals } from "../../contexts/globalContexts";

function TopSteps() {
  const {lang, locale} = useGlobals();
  return (
    <div>
      <div className="container-fluid dps-none">
        <div className="row mb-3">
          <div className="col-sm-2 col-2"></div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-8 text-center">
            <img src={locale === "en" ? Steps : StepsArabic} className="light" alt="top-steps-light" />
            <img src={locale === "en" ? StepsDark : StepsArabic} className="dark" alt="top-steps-dark" />
          </div>

          <div className="col-sm-2 col-2"></div>
        </div>
      </div>

      <div className="row dps-none">
        <div className="col-12 border-bottom" ></div>
      </div>
    </div>
  );
}

export default TopSteps;
