import { useGlobals } from "../../contexts/globalContexts";

function ResultSnippetSkeleton({ searchResult }) {
    const { tripType } = useGlobals();
    return (
        <div>
            <div className="search_result_snippet">
                <div className="itinerary">
                    <div className="badges_and_promos">
                        <div className="badges">
                            <span className="badge refundable skeleton">Refundable</span>
                        </div>
                    </div>
                    <div className="flight_row">
                        <div className="flight_row_col">
                            <div className="leg">
                                <div className="airline skeleton" style={{backgroundColor : 'transparent'}}>
                                    <div className="airline_logo" style={{backgroundColor : 'transparent'}} />
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                                <div className="duration_and_stops" style={{paddingTop : '30px'}}>
                                    <div className="duration">
                                        <div className="total_duration_stops skeleton">02h 40m - 1 Stops</div></div>
                                    
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                            </div>
                            {tripType === 1 ? <div className="leg">
                                <div className="airline skeleton" style={{backgroundColor : 'transparent'}}>
                                    <div className="airline_logo" style={{backgroundColor : 'transparent'}} />
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                                <div className="duration_and_stops" style={{paddingTop : '30px'}}>
                                    <div className="duration">
                                        <div className="total_duration_stops skeleton">02h 40m - 1 Stops</div></div>
                                    
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                            </div> : <div className="leg"></div>}
                        </div>
                        <div className="flight_row_col">
                            <div className="price" style={{paddingLeft : '30px'}}>
                                <div className="the_old_price skeleton" style={{marginBottom : '15px'}}>SAR 2276</div>
                                <div className="the_price skeleton">2266</div>
                            </div>
                        </div>
                    </div>
                    <div className="desk_actions" style={{backgroundColor : 'transparent'}}>
                        <div className="flight_details skeleton">
                            <button type="button" style={{backgroundColor : 'transparent', backgroundImage:'none', border: '0px'}}>Flight Details?</button></div>
                        
                        <div className="cta skeleton" style={{backgroundColor : 'transparent'}}>
                            <button type="button" style={{backgroundColor : 'transparent'}}>Book Now</button>
                        </div>
                    </div>
                    <div className="price_row">
                        <div className="badges">
                            <span className="refundable skeleton">Non - Refundable</span>
                            <div className="baggage">
                                <div className="price skeleton">
                                    <div className="price_striken">SAR 680</div>
                                    <div className="price_now">SAR 680</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search_result_snippet">
                <div className="itinerary">
                    <div className="badges_and_promos">
                        <div className="badges">
                            <span className="badge refundable skeleton">Refundable</span>
                        </div>
                    </div>
                    <div className="flight_row">
                        <div className="flight_row_col">
                            <div className="leg">
                                <div className="airline skeleton" style={{backgroundColor : 'transparent'}}>
                                    <div className="airline_logo" style={{backgroundColor : 'transparent'}} />
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                                <div className="duration_and_stops" style={{paddingTop : '30px'}}>
                                    <div className="duration">
                                        <div className="total_duration_stops skeleton">02h 40m - 1 Stops</div></div>
                                    
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                            </div>
                            {tripType === 1 ? <div className="leg">
                                <div className="airline skeleton" style={{backgroundColor : 'transparent'}}>
                                    <div className="airline_logo" style={{backgroundColor : 'transparent'}} />
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                                <div className="duration_and_stops" style={{paddingTop : '30px'}}>
                                    <div className="duration">
                                        <div className="total_duration_stops skeleton">02h 40m - 1 Stops</div></div>
                                    
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                            </div> : <div className="leg"></div>}
                        </div>
                        <div className="flight_row_col">
                            <div className="price" style={{paddingLeft : '30px'}}>
                                <div className="the_old_price skeleton" style={{marginBottom : '15px'}}>SAR 2276</div>
                                <div className="the_price skeleton">2266</div>
                            </div>
                        </div>
                    </div>
                    <div className="desk_actions" style={{backgroundColor : 'transparent'}}>
                        <div className="flight_details skeleton">
                            <button type="button" style={{backgroundColor : 'transparent', backgroundImage:'none', border: '0px'}}>Flight Details?</button></div>
                        
                        <div className="cta skeleton" style={{backgroundColor : 'transparent'}}>
                            <button type="button" style={{backgroundColor : 'transparent'}}>Book Now</button>
                        </div>
                    </div>
                    <div className="price_row">
                        <div className="badges">
                            <span className="refundable skeleton">Non - Refundable</span>
                            <div className="baggage">
                                <div className="price skeleton">
                                    <div className="price_striken">SAR 680</div>
                                    <div className="price_now">SAR 680</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search_result_snippet">
                <div className="itinerary">
                    <div className="badges_and_promos">
                        <div className="badges">
                            <span className="badge refundable skeleton">Refundable</span>
                        </div>
                    </div>
                    <div className="flight_row">
                        <div className="flight_row_col">
                            <div className="leg">
                                <div className="airline skeleton" style={{backgroundColor : 'transparent'}}>
                                    <div className="airline_logo" style={{backgroundColor : 'transparent'}} />
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                                <div className="duration_and_stops" style={{paddingTop : '30px'}}>
                                    <div className="duration">
                                        <div className="total_duration_stops skeleton">02h 40m - 1 Stops</div></div>
                                    
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                            </div>
                            {tripType === 1 ? <div className="leg">
                                <div className="airline skeleton" style={{backgroundColor : 'transparent'}}>
                                    <div className="airline_logo" style={{backgroundColor : 'transparent'}} />
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                                <div className="duration_and_stops" style={{paddingTop : '30px'}}>
                                    <div className="duration">
                                        <div className="total_duration_stops skeleton">02h 40m - 1 Stops</div></div>
                                    
                                </div>
                                <div className="the_airport">
                                    <div className="the_airport_code skeleton">DXB</div>
                                    <div className="the_info">
                                        <strong className="skeleton">Terminal 1</strong>
                                        <span className="skeleton">Tue, Dec 20, 2022</span>
                                    </div>
                                </div>
                            </div> : <div className="leg"></div>}
                        </div>
                        <div className="flight_row_col">
                            <div className="price" style={{paddingLeft : '30px'}}>
                                <div className="the_old_price skeleton" style={{marginBottom : '15px'}}>SAR 2276</div>
                                <div className="the_price skeleton">2266</div>
                            </div>
                        </div>
                    </div>
                    <div className="desk_actions" style={{backgroundColor : 'transparent'}}>
                        <div className="flight_details skeleton">
                            <button type="button" style={{backgroundColor : 'transparent', backgroundImage:'none', border: '0px'}}>Flight Details?</button></div>
                        
                        <div className="cta skeleton" style={{backgroundColor : 'transparent'}}>
                            <button type="button" style={{backgroundColor : 'transparent'}}>Book Now</button>
                        </div>
                    </div>
                    <div className="price_row">
                        <div className="badges">
                            <span className="refundable skeleton">Non - Refundable</span>
                            <div className="baggage">
                                <div className="price skeleton">
                                    <div className="price_striken">SAR 680</div>
                                    <div className="price_now">SAR 680</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultSnippetSkeleton;