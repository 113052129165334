import React, { useState, useEffect } from "react";
import { useGlobals } from "../../contexts/globalContexts";
import "../../css/payment_details.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import XMLParser from "react-xml-parser";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";
import TopSteps from "./TopSteps";
import MobLeftArrow from "../../img/mob-left-right-arrow.svg";
import MobileArrowDown from "../../img/arrow_down.svg";
import Sidebar from "./Sidebar";
import Form from "./Form";
// import Footer from "./Footer";
import Footer from "../PassengerInformation/Footer";
import BackToTopButton from "../BackToTopButton";
import "../../css/dialog_modal.css";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import FlightDetails from "../FlightDetails/FlightDetails";
import FlightDetailsPopUp from "../FlightDetailsPopUp/FlightDetailsPopUp";

function PaymentDetails() {
  const navigate = useNavigate();

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return dt;
  };

  const {
    bookingDetails,
    hepstarPolicyNumber,
    showHepar,
    passengerDetails,
    passengerData,
    passengers,
    setCreateBookingApiData,
    hepstarData,
    contactDetails,
    fromDate,
    toDate,
    totalCharges,
    setHepstarPolicyNumber,
    bookingId,
    isLoading,
    setIsloading,
    siteURLS,
    itenraryDetails,
    setItenraryDetails,
    pricingInfo,
    setPricingInfo,
    priceSummary,
    setPriceSummary,
    totalPass,
    setTotalPass,
    hepstarCalled,
    setDiscountedAmount,
    lang,
    locale,
    setMoreInfoToggle,
    setLocale,
    setCurr,
    setIsFlightDetailsOpen
  } = useGlobals();
  // const [flightDetails, showFlightDetails] = useState(false);


  let localePath;
  localePath = window.location.pathname.split("/");

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        //  navigate(`/en/${localePath[2]}/${localePath[3]}`)
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");
        setLocale("ar");
        navigate(`/ar/${localePath[2]}/${localePath[3]}`);
        // document.documentElement.setAttribute("lang", 'ar');
      }
    }, 500);
  }, []);

 

  const [passData, setPassData] = useState();

  const { BookingRef } = useParams();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  let DOB = null;

  let itinerary;

  let adultQty = "";
  let childQty = "";
  let infantsQty = "";
  let adultPrice = "";
  let childPrice = "";
  let infantPrice = "";
  let adultTax = "";
  let infantTax = "";
  let childTax = "";
  let price = "";
  let taxAmount = "";

  const [customerEmail, setCustomerEmail] = useState();

  useEffect(() => {
    localStorage.setItem("Locale", locale);

    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }
    document.title = "Payment Details";

    if (itenraryDetails.length === undefined) {
      fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/GetItineraryDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BookingRef: BookingRef,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatusCode === 200) {
            setCurr(data.responseModel.passengers[0].price.currency);
            setCustomerEmail(data.responseModel.BookingContact.email);

            itinerary = data.responseModel;
            setTotalPass(itinerary);

            
            window.dataLayer = window.dataLayer || [];

            window. dataLayer.push({

              event: 'checkout_progress',
          
              ecommerce: {
          
                currency: process.env.REACT_APP_CURRENCY,
          
                checkout_step: 2,
          
                checkout_option: 'Payment'
          
              }
          
            });

            // console.log(window.dataLayer);




            if (
              data?.responseModel?.passengers[0]?.RevenueModel?.Discount
            ) {
              setDiscountedAmount(
                data?.responseModel?.passengers[0]?.RevenueModel?.Discount[0]
                  ?.DiscountAmount
              );
            } else {
              setDiscountedAmount(0);
            }

            

            if (itinerary && itinerary?.passengers[0]) {
              adultQty = itinerary?.passengers[0]?.qty;
              adultPrice =
                adultQty * itinerary?.passengers[0]?.price?.totalFareAmount;
              adultTax =
                itinerary?.passengers[0]?.price?.totalTaxAmount * adultQty;
            }
            if (itinerary && itinerary?.passengers[1]?.type === "CNN") {
              childQty = itinerary?.passengers[1]?.qty;
              childPrice =
                childQty * itinerary?.passengers[1]?.price?.totalFareAmount;
              childTax =
                itinerary?.passengers[1]?.price.totalTaxAmount * childQty;
            }
            if (itinerary && itinerary?.passengers[1]?.type === "INF") {
              infantsQty = itinerary?.passengers[1].qty;
              infantPrice =
                infantsQty * itinerary?.passengers[1]?.price?.totalFareAmount;
              infantTax =
                itinerary?.passengers[1]?.price?.totalTaxAmount * infantsQty;
            }
            if (itinerary && itinerary?.passengers[2]?.type === "INF") {
              infantsQty = itinerary?.passengers[2]?.qty;
              infantPrice =
                infantsQty * itinerary?.passengers[2]?.price?.totalFareAmount;
              infantTax =
                itinerary?.passengers[2]?.price?.totalTaxAmount * infantsQty;
            }

            setPricingInfo({
              adultQty: adultQty,
              adultPrice: adultPrice,
              adultTax: adultTax,
              childQty: childQty,
              childPrice: childPrice,
              childTax: childTax,
              infantsQty: infantsQty,
              infantPrice: infantPrice,
              infantTax: infantTax,
              price: adultPrice + childPrice + infantPrice,
              taxPrice: adultTax + childTax + infantTax,
            });
            price = adultPrice + childPrice + infantPrice;
            taxAmount = adultTax + childTax + infantTax;
            setPriceSummary({
              price:
                Number(adultPrice) + Number(childPrice) + Number(infantPrice),
              taxPrice: Number(adultTax) + Number(childTax) + Number(infantTax),
              // totalPrice: data.responseModel.passengers[0].price.totalPrice,
              totalPrice: data.responseModel.TotalPrice,
            });

            if (itinerary.Leg[1]) {
              setItenraryDetails({
                ItineraryId: itinerary.ItineraryId,
                itineraryKey: itinerary.ItineraryKey,
                GoingDepartureAirport:
                  itinerary.Leg[0].Segements[0].departureAirport.airportName,
                goingDepartureTime:
                  itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                    " "
                  )[0],
                goingDepartureTerminal:
                  itinerary.Leg[0].Segements[0].departureAirport.Terminal,
                goingDepartureDate: getFormattedDate(
                  itinerary.Leg[0].departureDate
                ),
                goingArrivalAirport:
                  itinerary.Leg[1].Segements[0].departureAirport.airportName,
                GoingDepartureAirportCode:
                  itinerary.Leg[0].Segements[0].departureAirport.airportCode,
                GoingArrivalCity:
                  itinerary.Leg[0].Segements[0].arrivalAirport.cityName,
                GoingArrivalAirportCode:
                  itinerary.Leg[0].Segements[0].arrivalAirport.airportCode,
                goingArrivalTime:
                  itinerary.Leg[1].Segements[0].flights[0].arrivalTime.split(
                    " "
                  )[0],
                GoingAirlineLogo:
                  "../../img/airlines/" +
                  itinerary.Leg[0].Segements[0].flights[0].carrier.marketing +
                  ".png",
                goingArrivalTerminal:
                  itinerary.Leg[1].Segements[0].departureAirport.Terminal,
                goingDepartureCity:
                  itinerary.Leg[0].Segements[0].departureAirport.cityName,
                ReturnDepartureCity:
                  itinerary.Leg[0].Segements[0].departureAirport.cityName,
                goingArrivalAirport:
                  itinerary.Leg[0].Segements[0].arrivalAirport.airportName,
                ReturnDepartureAirport:
                  itinerary.Leg[1].Segements[0].departureAirport.airportName,
                ReturnDepartureDate: getFormattedDate(
                  itinerary.Leg[1].departureDate
                ),
                ReturnDepartureTime:
                  itinerary.Leg[1].Segements[0].flights[0].departureTime.split(
                    " "
                  )[0],
                ReturnDepartureTerminal:
                  itinerary.Leg[1].Segements[0].departureAirport.Terminal,
                ReturnArrivalAirport:
                  itinerary.Leg[1].Segements[
                    itinerary.Leg[1].Segements.length - 1
                  ].arrivalAirport.airportName,
                ReturnArrivalTime:
                  itinerary.Leg[1].Segements[
                    itinerary.Leg[1].Segements.length - 1
                  ].flights[0].arrivalTime.split(" ")[0],
                ReturnArrivalTerminal:
                  itinerary.Leg[1].Segements[
                    itinerary.Leg[1].Segements.length - 1
                  ].arrivalAirport.Terminal,
                ReturnArrivalDate: getFormattedDate(
                  itinerary.Leg[1].departureDate
                ),
                ReturnDepartureCity:
                  itinerary.Leg[1].Segements[0].departureAirport.cityName,
                ReturnDepartureAirportCode:
                  itinerary.Leg[1].Segements[0].departureAirport.airportCode,
                ReturnArrivalCity:
                  itinerary.Leg[1].Segements[0].arrivalAirport.cityName,
                ReturnArrivalAirportCode:
                  itinerary.Leg[1].Segements[0].arrivalAirport.airportCode,
                price: itinerary.passengers[0].price.totalPrice,
                taxPrice: itinerary.passengers[0].price.totalTaxAmount,
                TripType: "RoundTrip",
                goingDepartureCountry:
                  itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
                goingArrivalCountry:
                  itinerary.Leg[0].Segements[0].departureAirport.countryCode,
                ReturnDepartureCountry:
                  itinerary.Leg[1].Segements[0].departureAirport.countryCode,
                ReturnArrivalCountry:
                  itinerary.Leg[1].Segements[0].arrivalAirport.countryCode,
                ReturnAirlineLogo:
                  "../../img/airlines/" +
                  itinerary.Leg[1].Segements[0].flights[0].carrier.marketing +
                  ".png",
              });
            } else {
              setItenraryDetails({
                ItineraryId: itinerary.ItineraryId,
                itineraryKey: itinerary.ItineraryKey,
                GoingDepartureAirport:
                  itinerary.Leg[0].Segements[0].departureAirport.airportCode,
                goingDepartureTime:
                  itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                    " "
                  )[0],
                goingDepartureTerminal:
                  itinerary.Leg[0].Segements[0].departureAirport.Terminal,
                goingDepartureDate: getFormattedDate(
                  itinerary.Leg[0].departureDate
                ),
                goingArrivalAirport:
                  itinerary.Leg[0].Segements[0].arrivalAirport.airportCode,
                goingArrivalTime:
                  itinerary.Leg[0].Segements[0].flights[0].arrivalTime.split(
                    " "
                  )[0],
                goingArrivalTerminal:
                  itinerary.Leg[0].Segements[0].arrivalAirport.Terminal,
                price: itinerary.passengers[0].price.totalPrice,
                taxPrice: itinerary.passengers[0].price.totalTaxAmount,
                TripType: "OneWay",
                goingDepartureCountry:
                  itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
                goingArrivalCountry:
                  itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
                GoingDepartureAirportCode:
                  itinerary.Leg[0].Segements[0].departureAirport.airportCode,
                GoingArrivalCity:
                  itinerary.Leg[0].Segements[0].departureAirport.cityName,
                GoingArrivalAirportCode:
                  itinerary.Leg[0].Segements[0].arrivalAirport.airportCode,
                GoingAirlineLogo:
                  "../../img/airlines/" +
                  itinerary.Leg[0].Segements[0].flights[0].carrier.marketing +
                  ".png",
                goingDepartureCity:
                  itinerary.Leg[0].Segements[0].departureAirport.cityName,
                ReturnDepartureCity:
                  itinerary.Leg[0].Segements[0].departureAirport.cityName,
                goingArrivalAirport:
                  itinerary.Leg[0].Segements[0].arrivalAirport.airportName,
              });
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  let token = "";
  let res = "";

  let change = "";

  let isInsured = "";
  useEffect(() => {
    if (showHepar) {
      isInsured = true;
    } else {
      isInsured = false;
    }
  });
  

  useEffect(() => {
    change = window.addEventListener("storage", () => {
      token = localStorage.getItem("token");
      if (token) {
        setIsloading(true);
        
        fetch(`${process.env.REACT_APP_PG_URL}/Payment/CreateAuthAndCharge`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            BookingRef: BookingRef,
            Token: token,
            IsInsured: hepstarCalled,
            Local : locale
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem("Locale", locale);
            if (data.hasError === false) {
              window.location.href = `${data.responseModel.redirectUrl}`;
              setTimeout(() => {
                if (
                  window.location.host === "localhost:3000" ||
                  window.location.host === "uatui.eilago.com" ||
                  window.location.host === "sa.eilago.com"
                ) {
                  window.location.href = `${data.responseModel.redirectUrl}`;
                }
              }, 7500);
            }
            if (data.httpStatusCode === 400) {
              setModalMsg(data.error.message);
              setIsOpen(true);
              setIsloading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }, [change]);


  const makePayment = () => {

    setMoreInfoToggle(false);
    // document.querySelector("#tap-btn").click();
    // document.getElementById("tap-btn").click();
    document.getElementById("payment_iframe").contentWindow.document.getElementById("tap-btn").click();
   
  };

  const [modalMsg, setModalMsg] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const { t, i18n, ready } = useTranslation();

  

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);


  const [renderFlightDetails, setRenderFlightDetails] = useState(false);
  const [getItineraries, setItineraries] = useState();

  const showFlightDetails = (Itineraries) => {
    document
    .getElementsByTagName("body")[0]
    .scrollTo({ top: 10, behavior: "smooth" });
    setRenderFlightDetails(true);
    setIsFlightDetailsOpen(true);

    setItineraries([totalPass]);
  };

  return (
    <div className="payment_details">
      <TopSteps />

      {renderFlightDetails && (
        <FlightDetailsPopUp 
        // <FlightDetails
          renderFlightDetails={renderFlightDetails}
          setRenderFlightDetails={setRenderFlightDetails}
          getItineraries={getItineraries}
          totalCharges={totalCharges}
          pricingInfo={pricingInfo}
          totalPass={totalPass}
          // departureAirport={departureAirportValue}
        />
      )}

      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {
          <div className="msg">
            <p>{modalMsg}</p>
          </div>
        }

        <div className="row bottom_align1">
          <div className="col-lg-12 text-center">
            <button
              onClick={() => {
                closeModal();
              }}
              className="button "
            >
              {t("Go Back Button")}
            </button>
          </div>
        </div>
      </ReactModal>

      <div className="container dls-none mob-container mt-4" dir={locale === "ar" ? "rtl" : "ltr"}>
      <div className="row mobile_header mob-top-m-none dls-none ">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-7 col-7">
                <p style={{fontSize:"12px"}} className="text-white">Total to be paid:</p>
                <h2 className="font-bold text-white" style={{fontSize:"27px"}}> {process.env.REACT_APP_CURRENCY} {totalCharges}</h2>
              </div>

              <div className="col-lg-5 text-right col-5 vertically-align-center">
                {/* <img
                  src={MobileArrowDown}
                  onClick={() => showFlightDetails(!flightDetails)}
                  alt="mob-arrow-down"
                /> */}
                <span style={{fontSize:"14px"}} className=" text-white underlined-text" onClick={() => showFlightDetails(totalPass)}>{t("Booking Summary")}</span>
              </div>
            </div>
          </div>
        </div>

        {/* {flightDetails ? (
          <div className="row flight-details px-3 dls-none">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-6">
                  <span className="font-bold font-13">
                    {itenraryDetails.goingDepartureDate}
                  </span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-2">
                  <img
                    src={itenraryDetails.GoingAirlineLogo}
                    style={{ height: "30px", width: "30px" }}
                    alt="going-airline-log"
                  />
                </div>

                <div className="col-5">
                  <span className="font-bold font-16">
                    {String(itenraryDetails.goingDepartureTime).substring(0, 5)}{" "}
                    - {String(itenraryDetails.goingArrivalTime).substring(0, 5)}
                  </span>
                  <p className="font-10">{itenraryDetails.goingAirline}</p>
                </div>

                <div className="col-5 text-right">
                  <p className="font-10">
                    {itenraryDetails.goingDepartureDate},{" "}
                    {itenraryDetails.GoingDepartureAirport} -{" "}
                    {itenraryDetails.TripType === "RoundTrip" &&
                      totalPass?.Leg[1]?.Segements[0]?.departureAirport
                        .airportName}
                    {itenraryDetails.TripType === "OneWay" &&
                      totalPass?.Leg[0]?.Segements[
                        totalPass?.Leg[0].Segements.length - 1
                      ].arrivalAirport.airportName}
                  </p>
                  <p className="font-10 font-bold text-green-mob">
                    {itenraryDetails.GoingStop > 0 ? null : t("Non-Stop")}
                  </p>
                </div>
              </div>

              {itenraryDetails.TripType === "RoundTrip" ? (
                <div>
                  <div className="row">
                    <div className="col-6">
                      <span className="font-bold font-13">
                        {itenraryDetails.ReturnDepartureDate}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-2">
                      <img
                        src={itenraryDetails.ReturnAirlineLogo}
                        style={{ height: "30px", width: "30px" }}
                        alt="return-airline-logo"
                      />
                    </div>

                    <div className="col-5">
                      <span className="font-bold font-16">
                        {String(itenraryDetails.ReturnDepartureTime).substring(
                          0,
                          5
                        )}{" "}
                        -{" "}
                        {String(itenraryDetails.ReturnArrivalTime).substring(
                          0,
                          5
                        )}
                      </span>
                      <p className="font-10">{itenraryDetails.ReturnAirline}</p>
                    </div>

                    <div className="col-5 text-right">
                      <p className="font-10">
                        {itenraryDetails.ReturnDepartureDate},{" "}
                        {itenraryDetails.ReturnDepartureAirport} -{" "}
                        {itenraryDetails.ReturnArrivalAirport}
                      </p>
                      <p className="font-10 font-bold text-green-mob">
                        {itenraryDetails.ReturnStop > 0 ? null : t("Non-Stop")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null} */}
      </div>

      {isLoading ? (
        <div className="loader__Overlay">
          <div className="loader"></div>
        </div>
      ) : null}

      <div className="container mt-4 " dir={locale === "ar" ? "rtl" : "ltr"}>
        <div className="row column-gap mob-mb-122">
          <div className="col-sm-8 col-md-8 col-lg-8">
            <Form BookingRef={BookingRef} customerEmail={customerEmail} />
            <div className="row mb-5 mt-4 dps-none">
              <div
                className="col-sm-12 col-lg-12 col-md-12 text-right"
                style={{ margin: "0px" }}
              >
                <button className="button" onClick={makePayment}
                id="iframe-btn"
                aria-label="Make Payment"
                style={{ marginBottom: "120px" }}
                >
                 {t('Make Payment Button')}
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-3 col-md-3 col-lg-3 ">
            <Sidebar pricingInfo={pricingInfo} />
          </div>
        </div>
      </div>

      <BackToTopButton />

      <Footer makePayment={makePayment} totalPass={totalPass} />
      {/* <Footer /> */}
    </div>
  );
}

export default PaymentDetails;
