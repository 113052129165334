import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import "../../css/top_deals.css";

function TopDeals() {
  const { lang, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <section className="container recent_searches">
        <div>
            <h2>Recent Searches</h2>
        </div>
      <ol>
        <li>
            <div>
                <strong>Riyadh - Jeddah</strong>
                <p>5 May - 10 May</p>
            </div>
        </li>
        <li>
            <div>
                <strong>Riyadh - Jeddah</strong>
                <p>5 May - 10 May</p>
            </div>
        </li>
        <li>
            <div>
                <strong>Riyadh - Jeddah</strong>
                <p>5 May - 10 May</p>
            </div>
        </li>
        <li>
            <div>
                <strong>Riyadh - Jeddah</strong>
                <p>5 May - 10 May</p>
            </div>
        </li>
        <li>
            <div>
                <strong>Riyadh - Jeddah</strong>
                <p>5 May - 10 May</p>
            </div>
        </li>
      </ol>
    </section>
  );
}

export default TopDeals;
