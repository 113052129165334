import HyperStarApi from "./HyperStarAPI";
import { useGlobals } from "../../contexts/globalContexts";
import FreeStickerCircle from "../../img/free-sticker-circle.svg";
import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { format } from "date-fns";
import validator from "validator";
import { useLocation, useParams } from "react-router-dom";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import ReactModal from "react-modal";
import "../../css/dialog_modal.css";
import { useTranslation } from "react-i18next";

function FreeCancellation({
  email,
  number,
  totalPass,
  insurance,
  setInsurance,
  callHepstarPassengers,
}) {
  const {
    setShowHepar,
    showHepar,
    bookingDetails,
    setHepstarData,
    currency,
    setDisable,
    passengerData,
    fromDate,
    toDate,
    passengers,
    ContactDetails,
    emailError,
    setEmailError,
    phoneError,
    setPhoneError,
    nameError,
    setNameError,
    bdyError,
    setBdyError,
    setPassportNoError,
    passportNoError,
    countryError,
    setCountryError,
    passportExpError,
    setPassportExpError,
    genderError,
    setGenderError,
    isLoading,
    setIsloading,
    siteURLS,
    hepstarData,
    hepstarDetails,
    setHepstarDetails,
    isChecked,
    setIsChecked,
    item1,
    setItem1,
    item2,
    setItem2,
    item3,
    setItem3,
    item4,
    setItem4,
    item5,
    setItem5,
    hepstarResult,
    setHepstarResult,
    lang,
    locale,utmMed
  } = useGlobals();

  const { BookingRef } = useParams();

  const userName = "eilago-client@hepstar.com";
  const userPassword = "APEJ197nao112";

  let hepstarCurrency = "SAR";

  const searchResult = useLocation().search;
  const _SearchID = new URLSearchParams(searchResult).get("SearchId");

  let adults = passengers.adultCount;
  let childs = passengers.childrenCount;
  let infants = passengers.infantsCount;
  let totalPassengers;
  if (utmMed === "meta") {
    totalPassengers = totalPass?.PassengerDetails?.length;
  } else {
    totalPassengers =
      passengers.adultCount +
      passengers.childrenCount +
      passengers.infantsCount;
  }

  let tmp = [];

  for (let i = 1; i <= totalPassengers; i++) {
    tmp.push(i);
  }

  const [callAPI, setCallAPI] = useState(false);

  const [disableHepstar, setDisableHepstar] = useState(false);
  const [modalMsg, setModalMsg] = useState();

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (insurance === true) {
      setIsChecked(true);
      setShowHepar(true);
      setHepstarData({
        totalHepstarAmount: totalPass?.InsuranceAmount,
      });
    }
  }, [totalPass]);

  const [isBuy, setIsBuy] = useState(false);

  useEffect(() => {
    setHepstarResult(false);
  }, []);

  const callHepstar = () => {
  
    setIsloading(true);
    if(utmMed !== "meta"){
      console.log('free');
      fetch(`${process.env.REACT_APP_IN_URL}/Insurance/GetPrice`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BookingRef: BookingRef,
          IsBuy: true,
          // IsBuy: isBuy,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setHepstarResult(true);
          if (insurance === true || isBuy === true) {
            setShowHepar(true);
            setIsChecked(true);
          }
          setIsChecked(true);
          setShowHepar(true);
  
          if (data.httpStatusCode === 200) {
            if (
              data?.responseModel?.responseParameters?.packageSize?.package
                ?.pricedProduct?.productPriceBreakdown.priceDetail[1]?.type
            ) {
              setHepstarData({
                totalHepstarAmount:
                  data?.responseModel?.responseParameters?.packageSize?.package
                    ?.pricedProduct?.productPriceBreakdown.priceDetail[1]
                    ?.totalAmount,
              });
            } else {
              setHepstarData({
                totalHepstarAmount:
                  data?.responseModel?.responseParameters?.packageSize?.package
                    ?.pricedProduct?.productPriceBreakdown.priceDetail[0]
                    ?.baseAmount,
              });
            }
            if (
              data?.responseModel?.responseParameters?.packageSize?.package
                ?.pricedProduct?.productInformation?.productDisplay?.description
            ) {
              setHepstarDetails({
                description:
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.description,
                productName:
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.productname,
                text: data.responseModel.responseParameters.packageSize.package
                  .pricedProduct.productInformation.productDisplay.highlight.text,
              });
              if (
                data?.responseModel?.responseParameters?.packageSize?.package
                  ?.pricedProduct?.productInformation?.productDisplay?.details
                  ?.item1
              ) {
                setItem1(
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.details.item1
                );
              }
              if (
                data?.responseModel?.responseParameters?.packageSize?.package
                  ?.pricedProduct?.productInformation?.productDisplay?.details
                  ?.item2
              ) {
                setItem2(
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.details.item2
                );
              }
              if (
                data?.responseModel?.responseParameters?.packageSize?.package
                  ?.pricedProduct?.productInformation?.productDisplay?.details
                  ?.item3
              ) {
                setItem3(
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.details.item3
                );
              }
              if (
                data?.responseModel?.responseParameters?.packageSize?.package
                  ?.pricedProduct?.productInformation?.productDisplay?.details
                  ?.item4
              ) {
                setItem4(
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.details.item4
                );
              }
              if (
                data?.responseModel?.responseParameters?.packageSize?.package
                  ?.pricedProduct?.productInformation?.productDisplay?.details
                  ?.item5
              ) {
                setItem5(
                  data.responseModel.responseParameters.packageSize.package
                    .pricedProduct.productInformation.productDisplay.details.item5
                );
              }
            }
  
            setInsurance(true);
          } else {
            setIsloading(false);
            setInsurance(false);
          }
          setIsloading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (event) => {
    setCount(count + 1);
    if (event.target.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    if (isLoading === true) {
      isLoading(false);
    }
  }

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      {isLoading ? (
        <div className="loader__Overlay">
          <div className="loader"></div>
        </div>
      ) : null}

      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {
          <div className="msg">
            <p>{modalMsg}</p>
          </div>
        }

        <div className="row bottom_align1">
          <div className="col-lg-12 text-center">
            <button
              onClick={() => {
                closeModal();
              }}
              className="button "
            >
              {t("Continue Button")}
            </button>
          </div>
        </div>
      </ReactModal>

      <div className="row mt-4 mob-px-2 " style={{ marginBottom: "30px" }}>
        {hepstarResult === true && (
          <div className="col-sm-12 col-12 col-md-12 col-lg-12 form-border mb-2">
            <div className="row ">
              <div className="col-sm-2 col-2 col-md-1 col-lg-1 vertically-align-center">
                <img
                  src={FreeStickerCircle}
                  className="free-sticker-mob"
                  alt="free-sticker-mob"
                />
              </div>

              <div className="col-8 col-md-8 col-lg-8 mt-3 mob-mt-0">
                <p className="font-bold font-18 mob-font-12">
                  {hepstarDetails && hepstarDetails.productName
                    ? hepstarDetails.productName
                    : "Free Cancellation Guaranteed"}
                </p>
                <p className="font-13 mt-1">
                  {hepstarDetails && hepstarDetails.description
                    ? hepstarDetails.description
                    : "Receive 90% refund if you cancel your booking."}{" "}
                </p>
              </div>
              <div
                className={
                  locale === "ar"
                    ? "col-3 mt-3 mob-mt-0 text-right-arabic dps-none"
                    : "col-3 mt-3 mob-mt-0 text-right dps-none"
                }
              >
                {isChecked === true && (
                  <span className="text-orange font-bold font-18 mob-font-12">
                    {hepstarData.totalHepstarAmount
                      ? `${currency} ${hepstarData.totalHepstarAmount}`
                      : `${currency} ${totalPass?.InsuranceAmount}`}
                  </span>
                )}
                <br />
                <span className="font-10">
                  {t("For")} {totalPassengers} {t("Person")} (s) {t("Only")}
                </span>
              </div>

              <div className="col-6 mt-3 mob-mt-0 text-right dls-none">
                {isChecked === true && (
                  <span className="text-orange font-bold font-18 mob-font-12">
                    {hepstarData.totalHepstarAmount
                      ? `${currency} ${hepstarData.totalHepstarAmount}`
                      : `${currency} ${totalPass?.InsuranceAmount}`}
                  </span>
                )}
              </div>
            </div>

            {isChecked === true ? (
              <HyperStarApi
                hepstarDetails={hepstarDetails}
                item1={item1}
                item2={item2}
                item3={item3}
                item4={item4}
                item5={item5}
                callHepstarPassengers={callHepstarPassengers}
              />
            ) : null}
          </div>
        )}
      </div>
      <div style={{ marginBottom: "60px" }} className="dls-none"></div>
    </div>
  );
}

export default FreeCancellation;
