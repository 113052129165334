import "../../css/footer.css";

import SmallBird from "../../img/small_bird.svg";
import AppStore from "../../img/app_store_logo.svg";
import PlayStore from "../../img/play_store_logo.svg";
import CcLogoDark from "../../img/d/cc_logos.svg";
import CcLogoLight from "../../img/cc_logos.svg";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function Footer() {
  const { lang, locale } = useGlobals();

  const [year, setYear] = useState("");

  useEffect(() => {
    const d = new Date();
    setYear(d.getFullYear());
  });

  const { t, i18n, ready } = useTranslation();

  return (
    <footer className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <img src={SmallBird} className="small_bird" alt="" />
            <p>{t("Mobile App Text")}</p>
            <span>
              <a href="#">
                <img src={AppStore} alt="" />
              </a>
              <a href="#">
                <img src={PlayStore} alt="" />
              </a>
            </span>
          </div>
          <div className="col-12">
            <ul>
              <li>
                <Link to={`/${locale}/terms_conditions`}>
                  {t("Terms Conditions")}
                </Link>
              </li>
              <li>
                <Link to={`/${locale}/privacy_policy`}>
                  {t("Privacy Policy")}
                </Link>
              </li>
              <li>
                <Link to={`/${locale}/faq`}>{t("FAQ")}</Link>
              </li>
              <li>
                <Link to={`/${locale}/contact_us`}>{t("Contact Us")}</Link>
              </li>
            </ul>
          </div>
          <div
            className="col-12"
            style={isMobile ? { marginBottom: "90px" } : null}
          >
            <img src={CcLogoLight} alt="" className="light" />
            <img src={CcLogoDark} alt="" className="dark" />
            <cite>
              {t("Copyrights")} © {year} {t("Rights")}
            </cite>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
