import { React, useEffect, useState } from "react";
import "../../css/flight_confirmation.css";
import { isMobile } from "react-device-detect";
import TopSteps from "./TopSteps";
import BookingConfirmation from "./BookingConfirmation";
import MobileBookigConfirmation from "./MobileBookingConfirmation";
import Sidebar from "./Sidebar";
import MobileIcons from "./MobileIcons";
import WebIcons from "./WebIcons";
import PassengerDetails from "./PassengerDetails";
import BaggageInformation from "./BaggageInformation";
import AirplaneDepartureIcon from "./AirplaneDepartureIcon";
import DepartureDetails from "./DepartureDetails";
import AirplaneArrivalIcon from "./AirplaneArrivalIcon";
import ArrivalDetails from "./ArrivalDetails";
import BackToTopButton from "../BackToTopButton";
import { useGlobals } from "../../contexts/globalContexts";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { format } from "date-fns";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import "../../css/search_result_snippet.css";
import "../../css/search_results.css";
import { useTranslation } from "react-i18next";

function FlightConfirmation() {
  const { BookingRef } = useParams();
  const {
    bookingDetails,
    isLoading,
    siteURLS,
    setIsloading,
    setBookingDetails,
    pricingInfo,
    setPricingInfo,
    isOpenPage,
    setIsOpenPage,
    lang,
    setDiscountedAmount,
    locale,
    setLocale,
    setCurr,
  } = useGlobals();

  const navigate = useNavigate();

  const [passengerInfo, setPassengerInfo] = useState();

  const [passengerCount, setPassengerCount] = useState();

  const [pixelImg, setPixelImg] = useState();

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }
    document.title = "Booking Confirmation";
  }, []);

  const [hideTopSection, setHideTopSection] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("booking_details")) {
      document.title = "Booking Details";
      setHideTopSection(true);
    }
    if (window.history.length < 3) {
    }
  }, []);

  const [click_id, setClickId] = useState();
  const [totalPriceForPixel, setTotalPriceForPixel] = useState();
  const [passengerQty, setPassengerQty] = useState();
  const [currency, setCurrency] = useState();
  const [route, setRoute] = useState();

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return dt;
  };

  let adultQty = "";
  let childQty = "";
  let infantsQty = "";
  let adultPrice = "";
  let childPrice = "";
  let infantPrice = "";
  let adultTax = "";
  let infantTax = "";
  let childTax = "";
  let price = "";

  useEffect(() => {
    setIsloading(true);
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/GetItineraryDetails`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: BookingRef,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCurr(data.responseModel.passengers[0].price.currency);
        let itinerary = data.responseModel;

        setIsloading(false);

        if (itinerary && itinerary?.passengers[0]) {
          adultQty = itinerary?.passengers[0].qty;
          adultPrice =
            adultQty * itinerary?.passengers[0]?.price?.totalFareAmount;
          adultTax = itinerary?.passengers[0]?.price?.totalTaxAmount * adultQty;
        }
        if (itinerary && itinerary?.passengers[1]?.type === "CNN") {
          childQty = itinerary?.passengers[1]?.qty;
          childPrice =
            childQty * itinerary?.passengers[1]?.price?.totalFareAmount;
          childTax = itinerary?.passengers[1]?.price?.totalTaxAmount * childQty;
        }
        if (itinerary && itinerary?.passengers[1]?.type === "INF") {
          infantsQty = itinerary?.passengers[1]?.qty;
          infantPrice =
            infantsQty * itinerary?.passengers[1]?.price?.totalFareAmount;
          infantTax =
            itinerary?.passengers[1]?.price?.totalTaxAmount * infantsQty;
        }
        if (itinerary && itinerary?.passengers[2]?.type === "INF") {
          infantsQty = itinerary?.passengers[2]?.qty;
          infantPrice =
            infantsQty * itinerary?.passengers[2]?.price?.totalFareAmount;
          infantTax =
            itinerary?.passengers[2]?.price?.totalTaxAmount * infantsQty;
        }

        setPricingInfo({
          adultQty: adultQty,
          adultPrice: adultPrice,
          adultTax: adultTax,
          childQty: childQty,
          childPrice: childPrice,
          childTax: childTax,
          infantsQty: infantsQty,
          infantPrice: infantPrice,
          infantTax: infantTax,
          price: adultPrice + childPrice + infantPrice,
          taxPrice: adultTax + childTax + infantTax,
        });

        if (data?.responseModel.passengers[0].RevenueModel.Discount !== null) {
          setDiscountedAmount(
            data.responseModel.passengers[0].RevenueModel.Discount[0]
              .DiscountAmount * data.responseModel.PassengerDetails.length
          );
        } else {
          setDiscountedAmount(0);
        }

        if (itinerary?.Leg[1]) {
          setBookingDetails({
            ItineraryId: itinerary.ItineraryId,
            itineraryKey: itinerary.ItineraryKey,
            GoingDepartureAirport:
              itinerary.Leg[0].Segements[0].departureAirport.airportName,
            goingAirline:
              itinerary.Leg[0].Segements[0].flights[0].carrier.operating,
            goingDepartureTime:
              itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                " "
              )[0],
            goingDepartureTerminal:
              itinerary.Leg[0].Segements[0].departureAirport.Terminal,
            goingDepartureDate: getFormattedDate(
              itinerary.Leg[0].departureDate
            ),
            goingArrivalAirport:
              itinerary.Leg[1].Segements[0].departureAirport.airportName,
            goingArrivalTime:
              itinerary.Leg[1].Segements[0].flights[0].arrivalTime.split(
                " "
              )[0],
            goingArrivalTerminal:
              itinerary.Leg[1].Segements[0].departureAirport.Terminal,
            ReturnDepartureAirport:
              itinerary.Leg[1].Segements[0].departureAirport.airportName,
            ReturnAirline:
              itinerary.Leg[1].Segements[0].flights[0].carrier.operating,
            ReturnDepartureDate: getFormattedDate(
              itinerary.Leg[1].departureDate
            ),
            ReturnDepartureTime:
              itinerary.Leg[1].Segements[0].flights[0].departureTime.split(
                " "
              )[0],
            ReturnDepartureTerminal:
              itinerary.Leg[1].Segements[0].departureAirport.Terminal,
            ReturnArrivalAirport:
              itinerary.Leg[1].Segements[itinerary.Leg[1].Segements.length - 1]
                .arrivalAirport.airportName,
            ReturnArrivalTime:
              itinerary.Leg[1].Segements[
                itinerary.Leg[1].Segements.length - 1
              ].flights[0].arrivalTime.split(" ")[0],
            ReturnArrivalTerminal:
              itinerary.Leg[1].Segements[itinerary.Leg[1].Segements.length - 1]
                .arrivalAirport.Terminal,
            ReturnArrivalDate: getFormattedDate(itinerary.Leg[1].departureDate),
            price: itinerary.passengers[0].price.totalPrice,
            taxPrice: itinerary.passengers[0].pqprice.totalTaxAmount,
            baseFareAmount: itinerary.passengers[0].pqprice.baseFareAmount,
            GoingStop: itinerary.Leg[0].NoOfStops,
            ReturnStop: itinerary.Leg[1].NoOfStops,
            TripType: "RoundTrip",
            Passengers: itinerary.passengers[0].qty,
            GoingAirlineLogo:
            `${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier.operating}.png`,
            GoingFlightNo:
              itinerary.Leg[0].Segements[0].flights[0].carrier
                .operatingFlightNumber,
            ReturnFlightNo:
              itinerary.Leg[1].Segements[0].flights[0].carrier
                .operatingFlightNumber,
            ReturnAirlineLogo:
            `${window.location.origin}/img/airlines/${itinerary.Leg[1].Segements[0].flights[0].carrier.operating}.png`,
            goingDepartureCountry:
              itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
            goingArrivalCountry:
              itinerary.Leg[0].Segements[0].departureAirport.countryCode,
            ReturnDepartureCountry:
              itinerary.Leg[1].Segements[0].departureAirport.countryCode,
            ReturnArrivalCountry:
              itinerary.Leg[1].Segements[0].arrivalAirport.countryCode,
            ReturnTotalTime: convertMinsToHrsMins(
              itinerary.Leg[1].Segements[itinerary.Leg[1].Segements.length - 1]
                .flights[0].elapsedTime
            ),
            GoingTotalTime: convertMinsToHrsMins(
              itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1]
                .flights[0].elapsedTime
            ),
          });
        } else {
          setBookingDetails({
            ItineraryId: itinerary?.ItineraryId,
            itineraryKey: itinerary.ItineraryKey,
            GoingDepartureAirport:
              itinerary.Leg[0].Segements[0].arrivalAirport.airportName,
            goingAirline:
              itinerary.Leg[0].Segements[0].flights[0].carrier.operating,
            goingDepartureTime:
              itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                " "
              )[0],
            goingDepartureTerminal:
              itinerary.Leg[0].Segements[0].departureAirport.Terminal,
            goingDepartureDate: getFormattedDate(
              itinerary.Leg[0].departureDate
            ),
            goingArrivalAirport:
              itinerary.Leg[0].Segements[0].departureAirport.airportName,
            goingArrivalTime:
              itinerary.Leg[0].Segements[0].flights[0].arrivalTime.split(
                " "
              )[0],
            goingArrivalTerminal:
              itinerary.Leg[0].Segements[0].departureAirport.Terminal,
            price: itinerary.passengers[0].price.totalPrice,
            taxPrice: itinerary.passengers[0].pqprice.totalTaxAmount,
            baseFareAmount: itinerary.passengers[0].pqprice.baseFareAmount,
            GoingStop: itinerary.Leg[0].NoOfStops,
            TripType: "OneWay",
            Passengers: itinerary.passengers[0].qty,
            GoingAirlineLogo:
            `${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier.operating}.png`,
            GoingFlightNo:
              itinerary.Leg[0].Segements[0].flights[0].carrier
                .operatingFlightNumber,
            goingDepartureCountry:
              itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
            goingArrivalCountry:
              itinerary.Leg[0].Segements[0].departureAirport.countryCode,
            GoingTotalTime: convertMinsToHrsMins(
              itinerary.Leg[0].Segements[itinerary.Leg[0].Segements.length - 1]
                .flights[0].elapsedTime
            ),
          });
        }
        if (data) {
          setPassengerInfo(itinerary);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const generatePDF = () => {
    let pdfHeight;
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme:dark)").matches
    ) {
      document.getElementById("flight_confirmation").style.backgroundColor =
        "#374b5f";
    }
    document.getElementById("passengerDetail").style.marginBottom = "100%";
    document.getElementById("Web_icons").style.display = "none";
    document.getElementById("backBtnID").style.display = "none";

    htmlToImage
      .toPng(document.getElementById("flight_confirmation"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${BookingRef}.pdf`);
      });

    setTimeout(() => {
      document.getElementById("passengerDetail").style.marginBottom = "0%";
      document.getElementById("Web_icons").style.display = "block";
      document.getElementById("backBtnID").style.display = "block";
      document.getElementsByTagName("body")[0].style.backgroundImage = "none";
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme:dark)").matches
      ) {
        document.getElementById("flight_confirmation").style.backgroundColor =
          "transparent";
      }
    }, 1000);
    setTimeout(() => {
      // navigate(`/${locale}/flight_confirmation/${BookingRef}`)
      window.location.reload();
    } ,3000)
  };

  const inputRef = useRef();
  const printDocument = useReactToPrint({
    content: () => inputRef.current,
  });

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        navigate(`/en/${localePath[2]}/${localePath[3]}`);
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");
        setLocale("ar");
        navigate(`/ar/${localePath[2]}/${localePath[3]}`);
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, []);

  console.log(locale);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div
      className="flight_confirmation"
      id="flight_confirmation"
      ref={inputRef}
      dir={locale === "ar" ? "rtl" : "ltr"}
    >
      <main className="print_page">
        {hideTopSection === false ? (
          <div>
            <TopSteps className="top_steps" />

            <BookingConfirmation
              passengerInfo={passengerInfo}
              generatePDF={generatePDF}
              printDocument={printDocument}
            />

            <MobileBookigConfirmation
              generatePDF={generatePDF}
              printDocument={printDocument}
            />
          </div>
        ) : (
          <hr className="line_break" />
        )}

        {isLoading ? <div className="loader"></div> : null}

        <div className="container">
          <div className="row mt-3 column-gap">
            <div className="col-sm-12 col-lg-8 col-md-12 mt-4">
              <AirplaneDepartureIcon />

              <DepartureDetails passengerInfo={passengerInfo} />

              {bookingDetails.TripType === "RoundTrip" && (
                <div>
                  <AirplaneArrivalIcon />

                  <ArrivalDetails passengerInfo={passengerInfo} />
                </div>
              )}

              <BaggageInformation passengerInfo={passengerInfo} />

              <PassengerDetails
                passengerInfo={passengerInfo}
                passengerCount={passengerCount}
                pricingInfo={pricingInfo}
              />

              <WebIcons
                generatePDF={generatePDF}
                printDocument={printDocument}
              />
            </div>

            <div className=" col-sm-12 col-lg-3 col-md-12 mt-4">
              <Sidebar
                pricingInfo={pricingInfo}
                passengerInfo={passengerInfo}
              />
            </div>
          </div>
        </div>

        <BackToTopButton />
      </main>
    </div>
  );
}

export default FlightConfirmation;
