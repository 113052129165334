import "../../css/search_filters.css";
import { isMobile } from "react-device-detect";
import Slider from "rc-slider";
import Stops from "./Stops";
import PriceRange from "./PriceRange";
import FareType from "./FareType";
import Airlines from "./Airlines";
import TimeOfDay from "./TimeOfDay";
import { useGlobals } from "../../contexts/globalContexts";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function SearchFilters({
  searchResults,
  closeFiltersSorting,
  msorting,
  openSorting,
  mfilters,
  openFilters,
  appliedFilterCount,
}) {
  const {
    resetAllFilters,
    setResetAllFiltersApply,
    resetAllFiltersApply,
    lang,
    locale,
    filterMargin, setFilterMargin,
    setFiltersArray, filtersArray, setArray, setPriceSortingValue
  } = useGlobals();

  const { t, i18n, ready } = useTranslation();


  const callResetAllFilters = () => {
      setResetAllFiltersApply(true);
      setFiltersArray({
        "Ref" : false,
        "NonRef" : false,
        "NonStop" : false,
        "OneStop" : false,
        "TwoStop" : false,
        "dEarlyMorning" : false,
        "dAfternoon" : false,
        "dEvening" : false,
        "dNight" : false,
        "aEarlyMorning" : false,
        "aAfternoon" : false,
        "aEvening" : false,
        "aNight" : false,
       });
       setArray([]);
       setTimeout(() => {
        setResetAllFiltersApply(false);
        setPriceSortingValue("High");
       }, 2000)
  }
  

  return (
    <aside
      className={!isMobile ? "search_filters" : "search_filters"}
      id="search_filters"
      style={mfilters ? { display: "block" } : null}
    >
      {isMobile ? (
        <>
          <button
            aria-label="Close"
            type="button"
            className="big_close_button"
            id="close_search_filters"
            onClick={() => closeFiltersSorting()}
          />
          <button
            aria-label="Back"
            type="button"
            className="big_back_button"
            id="back_search_filters"
            onClick={() => closeFiltersSorting()}
          />
          <h2 style={isMobile ? { display: "block" } : { display: "none" }}>
            {t("Search Results")}
          </h2>
          <div className={filterMargin === true ? "m_filter_tabs mt_60" : "m_filter_tabs"}>
            <button
              aria-label="Open Filters"
              type="button"
              className={mfilters ? "m_filter_btn active" : "m_filter_btn"}
              onClick={() => {
                openFilters();
                setFilterMargin(true);
              }}
            >
              <svg viewBox="0 0 44 44">
                <g transform="translate(-53 -84)">
                  <g transform="translate(67 98)">
                    <g transform="translate(0.292 0.292)">
                      <path
                        className="a"
                        d="M15.917.5H.5L7.2,8.6v7.313l2.011-2.011V8.6Z"
                        transform="translate(-0.5 -0.5)"
                      />
                    </g>
                    <rect className="b" width={16} height={16} />
                  </g>
                  <rect
                    className="b"
                    width={44}
                    height={44}
                    transform="translate(53 84)"
                  />
                </g>
              </svg>
              {t("Filter")}
              
            </button>
            <button
              aria-label="Sort"
              type="button"
              className={msorting ? "m_sort_btn active" : "m_sort_btn"}
              onClick={() => {
                openSorting();
                setFilterMargin(false);
              }}
            >
              <svg viewBox="0 0 44 44">
                <g transform="translate(-221 -75)">
                  <rect
                    className="a"
                    width={44}
                    height={44}
                    transform="translate(221 75)"
                  />
                  <g transform="translate(-15736.5 162)">
                    <line
                      className="b"
                      x2={12}
                      transform="translate(15970.5 -64.5)"
                    />
                    <path
                      className="c"
                      d="M0,0H19"
                      transform="translate(15970.5 -70.5)"
                    />
                    <line
                      className="b"
                      x2={8}
                      transform="translate(15970.5 -58.5)"
                    />
                  </g>
                </g>
              </svg>
              {t("Sorting")}
            </button>
          </div>
        </>
      ) : null}
      <>
        <Stops />
        <FareType />
        <PriceRange />
        <TimeOfDay />
        <Airlines />
        {resetAllFilters === true && isMobile && (
          <>
           <button
          aria-label="Apply"
          type="button"
          className="apply_button_small"
          onClick={() => {
            closeFiltersSorting();
          }}
        >
          {t("Apply Btn")}
        </button>
          <button
            aria-label="Apply"
            type="button"
            className="reset_button"
            id="reset-btn"
            onClick={() => { callResetAllFilters();
            }}
          >
            {t("Reset All")}
          </button>
          </>
        )}
        {resetAllFilters === true && !isMobile && (
          <div className="row">
            <div className="col-lg-12 ">
              <span
                className="reset"
                id="reset-btn"
                onClick={() => { callResetAllFilters();
                }}
              >
                {t("Reset All")}
              </span>
            </div>
          </div>
        )}
      </>
      {isMobile && resetAllFilters === false ? (
        <button
          aria-label="Apply"
          type="button"
          className="apply_button"
          onClick={() => {
            closeFiltersSorting();
          }}
        >
          {t("Apply Btn")}
        </button>
      ) : null}
    </aside>
  );
}

export default SearchFilters;
