import { useGlobals } from "../../contexts/globalContexts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

function TimeOfDay() {
  const {
    searchResults,
    resetAllFiltersApply,
    setResetAllFiltersApply,
    setResetAllFilters,
    lang,
    locale,
    setFiltersArray, filtersArray
  } = useGlobals();

  const [toggle, setToggle] = useState(false);
  const [toggleArrival, setToggleArrival] = useState(false);

  const getDepartureTime = (results) => {
    let departureTime = null;
    results.Itineraries.forEach((itenrary) => {
      itenrary.Leg[0].Segements.forEach((newSeg) => {
        departureTime = newSeg.flights[0].departureTime;
        departureTime = departureTime.substring(0, 8);
        departureTime = departureTime.split(":");
        departureTime =
          parseInt(departureTime[0], 10) * 60 * 60 +
          parseInt(departureTime[1], 10) * 60 +
          parseInt(departureTime[2], 10);
      });
    });
    return departureTime;
  };

  const selectedTime = (t, e) => {
    setResetAllFilters(true);
    setToggle(true);

    t = t.split(":");
    t =
      parseInt(t[0], 10) * 60 * 60 +
      parseInt(t[1], 10) * 60 +
      parseInt(t[2], 10);

      // console.log(t);

      setFiltersArray((prevFilters) => ({
        ...prevFilters,
        "dEarlyMorning" : t < 14341 ? "14340" : false,
        "dAfternoon" : t > 14341 && t < 43142 ? "43140" : false,
        "dEvening" : t > 43143 &&  t < 57541  ? "57540" : false,
        "dNight" : t > 57542 && t < 86341 ? "86340" : false,
        "aEarlyMorning" : false,
        "aAfternoon" : false,
        "aEvening" : false,
        "aNight" : false,

    }))

    let timeOptionList = document.querySelectorAll(
      ".filter_section.departure_arrival_times ul li button"
    );

    for (let i = 0; i < timeOptionList.length; i++) {
      timeOptionList[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");

    // document.querySelectorAll("[data-departure-time]").forEach((time, e) => {
    //   let newTime = time.dataset.departureTime.substring(0, 8);

    //   newTime = newTime.split(":");
    //   newTime =
    //     parseInt(newTime[0], 10) * 60 * 60 +
    //     parseInt(newTime[1], 10) * 60 +
    //     parseInt(newTime[2], 10);

    //   if (newTime > 1 && newTime < t) {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else if (newTime > 14341 && newTime < t) {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else if (newTime > 43141 && newTime < t) {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else if (newTime > 57541 && newTime < t) {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else {
    //     time.classList.add("hide");
    //     time.classList.remove("show_all");
    //   }
    // });
  };

  const selectedArrivalTime = (t, e) => {
    setResetAllFilters(true);
    setToggleArrival(true);

    t = t.split(":");
    t =
      parseInt(t[0], 10) * 60 * 60 +
      parseInt(t[1], 10) * 60 +
      parseInt(t[2], 10);
    
  // console.log(t);

    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      "aEarlyMorning" : t < 14341 ? "14340" : false,
      "aAfternoon" : t > 14341 && t < 43142 ? "43140" : false,
      "aEvening" : t > 43143 &&  t < 57541  ? "57540" : false,
      "aNight" : t > 57542 && t < 86341 ? "86340" : false,
      "dEarlyMorning" : false,
      "dAfternoon" : false,
      "dEvening" : false,
      "dNight" : false,
  }))

    let timeOptionList = document.querySelectorAll(
      ".filter_section.arrival_times ul li button"
    );
    for (let i = 0; i < timeOptionList.length; i++) {
      timeOptionList[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
    // document.querySelectorAll("[data-arrival-time]").forEach((time, e) => {
    //   time.classList.add("hide");

    //   let newTime = time.dataset.arrivalTime.substring(0, 8);
    //   newTime = newTime.split(":");
    //   newTime =
    //     parseInt(newTime[0], 10) * 60 * 60 +
    //     parseInt(newTime[1], 10) * 60 +
    //     parseInt(newTime[2], 10);

    //   if (newTime > 1 && newTime < t) {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else if (newTime > 14341 && newTime < t) {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else if (newTime > 43141 && newTime < t) {
    //     console.log("Evening");
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else if (newTime > 57541 && newTime < t) {
    //     console.log("Night");
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   } else {
    //     time.classList.add("hide");
    //     time.classList.remove("show_all");
    //   }
    // });
  };

  const ResetDepartureFilters = () => {
    setToggle(!true);
    // let timeOptionList = document.querySelectorAll(
    //   ".filter_section.departure_arrival_times ul li button"
    // );
    // for (let i = 0; i < timeOptionList.length; i++) {
    //   timeOptionList[i].classList.remove("active");

    //   document.querySelectorAll("[data-departure-time]").forEach((time, e) => {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   });
    // }
    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      "dEarlyMorning" : false,
      "dAfternoon" : false,
      "dEvening" : false,
      "dNight" : false
  }))
  // console.log('depart')
  };

  const ResetArrivalFilters = () => {
    setToggleArrival(!true);
    // let timeOptionList = document.querySelectorAll(
    //   ".filter_section.departure_arrival_times ul li button"
    // );
    // for (let i = 0; i < timeOptionList.length; i++) {
    //   timeOptionList[i].classList.remove("active");

    //   document.querySelectorAll("[data-arrival-time]").forEach((time, e) => {
    //     time.classList.add("show_all");
    //     time.classList.remove("hide");
    //   });
    // }
    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      "aEarlyMorning" : false,
      "aAfternoon" : false,
      "aEvening" : false,
      "aNight" : false
  }))
  };

  useEffect(() => {
    if (resetAllFiltersApply === true) {
      let timeOptionList = document.querySelectorAll(
        ".filter_section.departure_arrival_times ul li button"
      );

      for (let i = 0; i < timeOptionList.length; i++) {
        timeOptionList[i].classList.remove("active");

        document.querySelectorAll("[data-arrival-time]").forEach((time, e) => {
          time.classList.add("show_all");
          time.classList.remove("hide");
        });
      }
      setResetAllFiltersApply(false);
      setToggleArrival(!true);
      setResetAllFilters(false);
      ResetDepartureFilters();
      ResetArrivalFilters();
    }
  }, [resetAllFiltersApply]);

  useEffect(() => {
    if (resetAllFiltersApply === true) {
      let timeOptionList = document.querySelectorAll(
        ".filter_section.departure_arrival_times ul li button"
      );

      for (let i = 0; i < timeOptionList.length; i++) {
        timeOptionList[i].classList.remove("active");

        document
          .querySelectorAll("[data-departure-time]")
          .forEach((time, e) => {
            time.classList.add("show_all");
            time.classList.remove("hide");
          });
      }
      setResetAllFiltersApply(false);
      setToggle(!true);
      setResetAllFilters(false);
    }
  }, [resetAllFiltersApply]);

  useEffect(() =>{
    if(filtersArray.dAfternoon !== false){
      setToggle(true);
    }
    if(filtersArray.aAfternoon !== false){
      setToggleArrival(true);
    }
  }, [filtersArray]);


  const { t, i18n, ready } = useTranslation();

  return (
    <>
      <div
        className={
          locale === "ar"
            ? "filter_section departure_arrival_times_arabic"
            : "filter_section departure_arrival_times"
        }
      >
        <div className="row">
          <div className="col-lg-9 col-9">
            <strong className={searchResults.length === 0 ? "skeleton" : ""}>
              {t("Departure From")}{" "}
              {searchResults.length != 0
                ? searchResults[0].Itineraries[0].Leg[0].Segements[0]
                    .departureAirport.cityName
                : null}
            </strong>
          </div>
          {toggle && (
            <div className="col-lg-3 col-3 text-right reset">
              <strong
                className={searchResults.length === 0 ? "skeleton" : ""}
                onClick={ResetDepartureFilters}
              >
                {t("Reset")}
              </strong>
            </div>
          )}
        </div>
        <ul>
          <li>
            <button
               style={
                locale === "en" ?
                isMobile ? { width: "170px" } : { width: "100%" }
                :
                isMobile ? { width: "170px" } : { width: "97%" }
              }
              className={
                searchResults.length === 0 ? "skeleton" : filtersArray.dEarlyMorning !== false ? "active early_morning" : "early_morning"
              }
              onClick={(e) => {
                selectedTime("03:59:00", e);
              }}
            >
              <strong>{t("Early Morning")}</strong>
              <span>00:00 - 03:59</span>
            </button>
          </li>
          <li>
            <button
               style={
                locale === "en" ?
                isMobile ? { width: "170px" } : { width: "100%" }
                :
                isMobile ? { width: "170px" } : { width: "97%" }
              }
              className={searchResults.length === 0 ? "skeleton" : filtersArray.dAfternoon !== false ? "active morning" : "morning"}
              onClick={(e) => {
                selectedTime("11:59:00", e);
              }}
            >
              <strong>{t("Morning")}</strong>
              <span>04:00 - 11:59</span>
            </button>
          </li>
          <li>
            <button
              style={isMobile ? { width: "170px" } : { width: "100%" }}
              className={searchResults.length === 0 ? "skeleton" : filtersArray.dEvening !== false ? "active aftrenoon" : "afternoon"}
              onClick={(e) => {
                selectedTime("15:59:00", e);
              }}
            >
              <strong>{t("Afternoon")}</strong>
              <span>12:00 - 15:59</span>
            </button>
          </li>
          <li>
            <button
              style={isMobile ? { width: "170px" } : { width: "100%" }}
              className={searchResults.length === 0 ? "skeleton" : filtersArray.dNight !== false ? "active evening" : "evening"}
              onClick={(e) => {
                selectedTime("23:59:00", e);
              }}
            >
              <strong>{t("Evening")}</strong>
              <span>16:00 - 23:59</span>
            </button>
          </li>
        </ul>
      </div>
      <div
        className={
          locale === "ar"
            ? "filter_section departure_arrival_times_arabic arrival_times"
            : "filter_section departure_arrival_times arrival_times"
        }
      >
        <div className="row">
          <div className="col-lg-8 col-9">
            <strong className={searchResults.length === 0 ? "skeleton" : ""}>
              {t("Arrival To")}{" "}
              {searchResults.length != 0
                ? searchResults[0].Itineraries[0].Leg[0].Segements[
                    searchResults[0].Itineraries[0].Leg[0].Segements.length - 1
                  ].arrivalAirport.cityName
                : null}
            </strong>
          </div>
          {toggleArrival && (
            <div className="col-lg-4 col-3 text-right reset">
              <strong
                className={searchResults.length === 0 ? "skeleton" : ""}
                onClick={ResetArrivalFilters}
              >
                Reset
              </strong>
            </div>
          )}
        </div>
        <ul>
          <li>
            <button
              style={
                locale === "en" ?
                isMobile ? { width: "170px" } : { width: "100%" }
                :
                isMobile ? { width: "170px" } : { width: "97%" }
              }
              className={
                searchResults.length === 0 ? "skeleton" : filtersArray.aEarlyMorning !== false ? "active early_morning" : "early_morning"
              }
              onClick={(e) => {
                selectedArrivalTime("03:59:00", e);
              }}
            >
              <strong>{t("Early Morning")}</strong>
              <span>00:00 - 03:59</span>
            </button>
          </li>
          <li>
            <button
             style={
              locale === "en" ?
              isMobile ? { width: "170px" } : { width: "100%" }
              :
              isMobile ? { width: "170px" } : { width: "97%" }
            }
              className={searchResults.length === 0 ? "skeleton" : filtersArray.aAfternoon !== false ? "active morning" : "morning"}
              onClick={(e) => {
                selectedArrivalTime("11:59:00", e);
              }}
            >
              <strong>{t("Morning")}</strong>
              <span>04:00 - 11:59</span>
            </button>
          </li>
          <li>
            <button
              style={isMobile ? { width: "170px" } : { width: "100%" }}
              className={searchResults.length === 0 ? "skeleton" : filtersArray.aEvening !== false ? "active afternoon" :  "afternoon"}
              onClick={(e) => {
                selectedArrivalTime("15:59:00", e);
              }}
            >
              <strong>{t("Afternoon")}</strong>
              <span>12:00 - 15:59</span>
            </button>
          </li>
          <li>
            <button
              style={isMobile ? { width: "170px" } : { width: "100%" }}
              className={searchResults.length === 0 ? "skeleton" : filtersArray.aNight !== false ? "active evening" : "evening"}
              onClick={(e) => {
                selectedArrivalTime("23:59:00", e);
              }}
            >
              <strong>{t("Evening")}</strong>
              <span>16:00 - 23:59</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default TimeOfDay;