import "../../css/terms_conditions.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { isMobile } from "react-device-detect";

function TermsConditions() {
  const { lang, locale, setLocale } = useGlobals();
  const navigate = useNavigate();

  useEffect(() => {
    // document.querySelector("#root > header").style.display = "block !important";
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
    document.body.classList.add("sub_page");
    document.getElementById("root").classList.add("page");
    // document.getElementById("root").classList.add("search_results");

    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", "background-image:none !important");
      if(!isMobile){
    document
      .getElementsByTagName("main")[0]
      .setAttribute("class", "page search_results");
      }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add("sub_page");
    document.getElementById("root").classList.add("page");
    if(!isMobile){
    document.getElementById("root").classList.add("search_results");
    }
  }, []);

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        // navigate(`/en/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");

        setLocale("ar");
        // navigate(`/ar/${localePath[2]}`);
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, []);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  return (
    <div dir={locale === "ar" ? "rtl" : "ltr"}>

      <div className="row top_bg">
        <div
          className={
            locale === "ar"
              ? "col-md-12 col-lg-12 title_header_arabic"
              : "col-md-12 col-lg-12 title_header"
          }
        >
          <h1>{t("Main Heading")}</h1>
        </div>
        <hr className="line_break" />
      </div>
      <section className="container-fluid terms_conditions">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 mb-4 px-10">
              <h3> {t("Main Heading")} </h3>
              <p>{t("Paragaraph1")}</p>
              <h3>{t("Binding agreement")}</h3>
              <p>{t("Paragraph2")}</p>
              <p>
                {t("Binding Agreement Point 1")} <br />
                {t("Binding Agreement Point 2")}
                <br />
                {t("Binding Agreement Point 3")}
              </p>
              <h3>{t("Website Usage")}</h3>
              <p>
                {t("Website Usage Text")}
                {t("Website Usage Point 1")} <br />
                {t("Website Usage Point 2")} <br />
                {/* {t("Website Usage Point 3")} <br /> */}
                {t("Website Usage Point 4")}
                <br />
                {t("Website Usage Point 5")}
                <br />
              </p>
              <h3>{t("Refund Policy")}</h3>
              <p>
                {t("Refund Policy Point 1")}
                <br />
                {t("Refund Policy Point 2")}
                <br />
                {t("Refund Policy Point 3")}
              </p>
              <h3>{t("Prohibited activites")}</h3>
              <p>{t("Prohibited activites Text")}</p>
              <p>
                {t("Prohibited activities Point 1")} <br />
                {t("Prohibited activities Point 2")} <br />
                {t("Prohibited activities Point 3")} <br />
                {t("Prohibited activities Point 4")} <br />
                {t("Prohibited activities Point 5")} <br />
                {t("Prohibited activities Point 6")} <br />
                {t("Prohibited activities Point 7")} <br />
                {t("Prohibited activities Point 8")} <br />
              </p>
              <h3>{t("Liability Desclaimer")}</h3>
              <p>
                {t("Liability Desclaimer Point 1")} <br />
                {t("Liability Desclaimer Point 2")}
                <br />
                {t("Liability Desclaimer Point 3")}
                <br />
              </p>
              <h3>{t("Indemnification")}</h3>
              <p>{t("Indemnification Point 1")}</p>
              <p>
                {t("Indemnification Point 2")} <br />
                {t("Indemnification Point 3")}
                <br />
                {t("Indemnification Point 4")}
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default TermsConditions;
