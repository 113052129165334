import { useGlobals } from "../../contexts/globalContexts";

import MobArrowDownLight from "../../img/arrow_down_black.svg";
import { Link } from "react-router-dom";
import MobileArrowDown from "../../img/arrow_down.svg";
import { useEffect, useRef, useState } from "react";
import Countries from "../../Countries.json";
import { isMobile } from "react-device-detect";
import ReactFlagsSelect from "react-flags-select";
import Customlabels from "../../CustomLabels.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

function ContactDetails({
  onEmailChange,
  emailValue,
  onCodeChange,
  codeValue,
  onNumberChange,
  numberValue,
  callContactAPI,
  igivenName,
  totalPass,
  itinerary,
  pricingInfo,
  handleSubmit,
  numberError,
  setNumberError,
  elemID
}) {
  const {
    passengers,
    passengerData,
    setpassengerData,
    passportNoError,
    emailError,
    setEmailError,
    phoneError,
    nameError,
    bdyError,
    countryError,
    passportExpError,
    genderError,
    itenraryDetails,
    passengerDetails,
    contactData,
    settotalPass,
    setNameError,
    code,
    setCode,
    codeUpdatedValue,
    setCodeUpdatedValue,
    hepstarCheckDisbale,
    setHepstarCheckDisable,
    lang,
    locale,
    docType, setDocType, submitBtn
  } = useGlobals();

  let year = new Date().getFullYear();

  const [error, setError] = useState(false);


  let adults = passengers.adultCount;
  let childs = passengers.childrenCount;
  let infants = passengers.infantsCount;
  let totalPassengers =
    Number(pricingInfo.adultQty) +
    Number(pricingInfo.childQty) +
    Number(pricingInfo.infantsQty);

  let tmp = [];
  for (let i = 1; i <= totalPassengers; i++) {
    tmp.push(i);
  }

  let bdyYears = [];
  for (let i = 1900; i <= year; i++) {
    bdyYears.push(i);
  }

  let docText = "Passport Number";

  const handleFirstNameError = (e) => {
    const value = e.target.value;
    // console.log(value);
    if(value === "" || value === null){

    }
  };


  const handleKeyDown = (event) => {
    // console.log(event.target.value);
    validate(event);
  };


  const regex = /[A-Za-z]/;
  var regexPassport = new RegExp("^[a-zA-Z0-9_]+$");
  function validate(e) {

        const chars = e.target.value.split("");
        const char = chars.pop();

        if (!regex.test(char)) {
          e.target.value = chars.join("");
          if (
            document.activeElement.tagName.toLowerCase() === "input" &&
            !document.activeElement.classList.contains("email-input")
            &&
            !document.activeElement.nextElementSibling.classList.contains("radioElem")

          )
          {
          document.activeElement.nextElementSibling.innerHTML =
            "Please use english only";
          }
      }
      // else{
      //   document.activeElement.nextElementSibling.innerHTML =
      //       " ";
      // }
      if(regex.test(char)){
        document.activeElement.nextElementSibling.innerHTML =
            "";
      }
      // else {
      //   if(!document.activeElement.nextElementSibling.classList.contains("radioElem"))
      //   {
      //   document.activeElement.nextElementSibling.innerHTML = " ";
      //   }
      // }
  }

  const validatePassport = (e) => {
    if(document.activeElement.classList.contains('passport-input')){
      const chars = e.target.value.split("");
        const char = chars.pop();
        if (!regexPassport.test(char)) {
          e.target.value = chars.join("");
          document.activeElement.nextElementSibling.innerHTML =
            "Special Characters are not allowed";
        }
    }
  }

  let change;
  useEffect(() => {
    document.activeElement.onpaste = (e) => e.preventDefault();
    change = document.activeElement.addEventListener("input", (evt) => {
          
      document.activeElement.addEventListener("input", validatePassport);
      if (!document.activeElement.classList.contains("email-input")) {
      
              // document.activeElement.addEventListener("input", validate);
             // console.log(document.activeElement.value);
      }
    });

  }, [change]);
 
  useEffect(() => {
    // console.log(elemID);
    if(elemID){
      setTimeout(() => {
        document.getElementById(elemID).blur();
      }, 1000);
      setTimeout(() => {
        document.getElementById(elemID).focus();
      }, 2000)
    }


  }, [elemID]);

let selectedMonth;
 let date = new Date();
  let month = date.getMonth()+1;
  let diffMonths;

  const [diffMonth, setDiffMonth] = useState();


  // console.log(diffMonths);




  // Errors
  const [fName0, setFName0] = useState();
  const [fName1, setFName1] = useState();
  const [fName2, setFName2] = useState();
  const [fName3, setFName3] = useState();
  const [fName4, setFName4] = useState();
  const [fName5, setFName5] = useState();
  const [fName6, setFName6] = useState();
  const [fName7, setFName7] = useState();
  const [fName8, setFName8] = useState();

  const [lName0, setLName0] = useState();
  const [lName1, setLName1] = useState();
  const [lName2, setLName2] = useState();
  const [lName3, setLName3] = useState();
  const [lName4, setLName4] = useState();
  const [lName5, setLName5] = useState();
  const [lName6, setLName6] = useState();
  const [lName7, setLName7] = useState();
  const [lName8, setLName8] = useState();

  const [gender0, setGender0] = useState();
  const [gender1, setGender1] = useState();
  const [gender2, setGender2] = useState();
  const [gender3, setGender3] = useState();
  const [gender4, setGender4] = useState();
  const [gender5, setGender5] = useState();
  const [gender6, setGender6] = useState();
  const [gender7, setGender7] = useState();
  const [gender8, setGender8] = useState();


  const [dob0, setDOB0] = useState();
  const [dob1, setDOB1] = useState();
  const [dob2, setDOB2] = useState();
  const [dob3, setDOB3] = useState();
  const [dob4, setDOB4] = useState();
  const [dob5, setDOB5] = useState();
  const [dob6, setDOB6] = useState();
  const [dob7, setDOB7] = useState();
  const [dob8, setDOB8] = useState();


  const [mob0, setMOB0] = useState();
  const [mob1, setMOB1] = useState();
  const [mob2, setMOB2] = useState();
  const [mob3, setMOB3] = useState();
  const [mob4, setMOB4] = useState();
  const [mob5, setMOB5] = useState();
  const [mob6, setMOB6] = useState();
  const [mob7, setMOB7] = useState();
  const [mob8, setMOB8] = useState();


  const [yob0 , setYOB0] = useState();
  const [yob1 , setYOB1] = useState();
  const [yob2 , setYOB2] = useState();
  const [yob3 , setYOB3] = useState();
  const [yob4 , setYOB4] = useState();
  const [yob5 , setYOB5] = useState();
  const [yob6 , setYOB6] = useState();
  const [yob7 , setYOB7] = useState();
  const [yob8 , setYOB8] = useState();

  const [passport0, setPassport0] = useState();
  const [passport1, setPassport1] = useState();
  const [passport2, setPassport2] = useState();
  const [passport3, setPassport3] = useState();
  const [passport4, setPassport4] = useState();
  const [passport5, setPassport5] = useState();
  const [passport6, setPassport6] = useState();
  const [passport7, setPassport7] = useState();
  const [passport8, setPassport8] = useState();



  const [dop0, setDOP0] = useState();
  const [dop1, setDOP1] = useState();
  const [dop2, setDOP2] = useState();
  const [dop3, setDOP3] = useState();
  const [dop4, setDOP4] = useState();
  const [dop5, setDOP5] = useState();
  const [dop6, setDOP6] = useState();
  const [dop7, setDOP7] = useState();
  const [dop8, setDOP8] = useState();


  const [mop0, setMOP0] = useState();
  const [mop1, setMOP1] = useState();
  const [mop2, setMOP2] = useState();
  const [mop3, setMOP3] = useState();
  const [mop4, setMOP4] = useState();
  const [mop5, setMOP5] = useState();
  const [mop6, setMOP6] = useState();
  const [mop7, setMOP7] = useState();
  const [mop8, setMOP8] = useState();


  const [yop0 , setYOP0] = useState();
  const [yop1 , setYOP1] = useState();
  const [yop2 , setYOP2] = useState();
  const [yop3 , setYOP3] = useState();
  const [yop4 , setYOP4] = useState();
  const [yop5 , setYOP5] = useState();
  const [yop6 , setYOP6] = useState();
  const [yop7 , setYOP7] = useState();
  const [yop8 , setYOP8] = useState();


  // Errors End


  const onBlurFunction = (e, id, ind) => {

    if(e.target.value.length < 1){
      if(id == "email"){
        setEmailError(t('Please fill out this field'));
        document.getElementById('email').style.borderColor = "red";
      }
      if(id === "number"){
        setNumberError(t('Please fill out this field'));
        document.getElementById('number').style.borderColor = "red";
      }

      if(id == 'fName0')
      {
        setFName0(t('Please fill out this field'));
        document.getElementById('fName0').style.borderColor = "red";
      
      }
      if(id == 'fName1')
      {
        setFName1(t('Please fill out this field'));
        document.getElementById('fName1').style.borderColor = "red";
      
      }
      if(id == 'fName2')
      {
        setFName2(t('Please fill out this field'));
        document.getElementById('fName2').style.borderColor = "red";
       
      }
      if(id == 'fName3')
      {
        setFName3(t('Please fill out this field'));
        document.getElementById('fName3').style.borderColor = "red";
     
      }
      if(id == 'fName4')
      {
        setFName4(t('Please fill out this field'));
        document.getElementById('fName4').style.borderColor = "red";
       
      }
      if(id == 'fName5')
      {
        setFName5(t('Please fill out this field'));
        document.getElementById('fName5').style.borderColor = "red";
  
      }
      if(id == 'fName6')
      {
        setFName6(t('Please fill out this field'));
        document.getElementById('fName6').style.borderColor = "red";
       
      }
      if(id == 'fName7')
      {
        setFName7(t('Please fill out this field'));
        document.getElementById('fName7').style.borderColor = "red";
      
      }
      if(id == 'fName8')
      {
        setFName8(t('Please fill out this field'));
        document.getElementById('fName8').style.borderColor = "red";
       
      }
    }
    if(e.target.value.length > 1){
      if(id === "number"){
        setNumberError('');
        document.getElementById('number').style.borderColor = "green";
      }


      if(id == 'fName0')
      {
        setFName0('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName1')
      {
        setFName1('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName2')
      {
        setFName2('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName3')
      {
        setFName3('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName4')
      {
        setFName4('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName5')
      {
        setFName5('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName6')
      {
        setFName6('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName7')
      {
        setFName7('');
        document.getElementById('fName0').style.borderColor = "green";
      }
      if(id == 'fName8')
      {
        setFName8('');
        document.getElementById('fName0').style.borderColor = "green";
      }
    }

    // LastName
    if(e.target.value.length < 1){
      if(id == 'lName0')
      {
        setLName0(t('Please fill out this field'));
        document.getElementById('lName0').style.borderColor = "red";
        
      }
      if(id == 'lName1')
      {
        setLName1(t('Please fill out this field'));
        document.getElementById('lName1').style.borderColor = "red";
       
      }
      if(id == 'lName2')
      {
        setLName2(t('Please fill out this field'));
        document.getElementById('lName2').style.borderColor = "red";
        
      }
      if(id == 'lName3')
      {
        setLName3(t('Please fill out this field'));
        document.getElementById('lName3').style.borderColor = "red";
      }
      if(id == 'lName4')
      {
        setLName4(t('Please fill out this field'));
        document.getElementById('lName4').style.borderColor = "red";
      }
      if(id == 'lName5')
      {
        setLName5(t('Please fill out this field'));
        document.getElementById('lName5').style.borderColor = "red";
      }
      if(id == 'lName6')
      {
        setLName6(t('Please fill out this field'));
        document.getElementById('lName6').style.borderColor = "red";
      }
      if(id == 'lName7')
      {
        setLName7(t('Please fill out this field'));
        document.getElementById('lName7').style.borderColor = "red";
      }
      if(id == 'lName8')
      {
        setLName8(t('Please fill out this field'));
        document.getElementById('lName8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'lName0')
      {
        setLName0('');
        document.getElementById('lName0').style.borderColor = "green";
      }
      if(id == 'lName1')
      {
        setLName1('');
        document.getElementById('lName1').style.borderColor = "green";
      }
      if(id == 'lName2')
      {
        setLName2('');
        document.getElementById('lName2').style.borderColor = "green";
      }
      if(id == 'lName3')
      {
        setLName3('');
        document.getElementById('lName3').style.borderColor = "green";
      }
      if(id == 'lName4')
      {
        setLName4('');
        document.getElementById('lName4').style.borderColor = "green";
      }
      if(id == 'lName5')
      {
        setLName5('');
        document.getElementById('lName5').style.borderColor = "green";
      }
      if(id == 'lName6')
      {
        setLName6('');
        document.getElementById('lName6').style.borderColor = "green";
      }
      if(id == 'lName7')
      {
        setLName7('');
        document.getElementById('lName7').style.borderColor = "green";
      }
      if(id == 'lName8')
      {
        setLName8('');
        document.getElementById('lName8').style.borderColor = "green";
      }
    }

    // Gender
    if(e.target.value.length < 1){
      if(id == 'gender0')
      {
        setGender0(t('Please fill out this field'));
        document.getElementById('gender0').style.borderColor = "red";
        
      }
      if(id == 'gender1')
      {
        setGender1(t('Please fill out this field'));
        document.getElementById('gender1').style.borderColor = "red";
      }
      if(id == 'gender2')
      {
        setGender2(t('Please fill out this field'));
        document.getElementById('gender2').style.borderColor = "red";
      }
      if(id == 'gender3')
      {
        setGender3(t('Please fill out this field'));
        document.getElementById('gender3').style.borderColor = "red";
      }
      if(id == 'gender4')
      {
        setGender4(t('Please fill out this field'));
        document.getElementById('gender4').style.borderColor = "red";
      }
      if(id == 'gender5')
      {
        setGender5(t('Please fill out this field'));
        document.getElementById('gender5').style.borderColor = "red";
      }
      if(id == 'gender6')
      {
        setGender6(t('Please fill out this field'));
        document.getElementById('gender6').style.borderColor = "red";
      }
      if(id == 'gender7')
      {
        setGender7(t('Please fill out this field'));
        document.getElementById('gender7').style.borderColor = "red";
      }
      if(id == 'gender8')
      {
        setGender8(t('Please fill out this field'));
        document.getElementById('gender8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'gender0')
      {
        setGender0('');
        document.getElementById('gender0').style.borderColor = "green";
      }
      if(id == 'gender1')
      {
        setGender1('');
        document.getElementById('gender1').style.borderColor = "green";
      }
      if(id == 'gender2')
      {
        setGender2('');
        document.getElementById('gender1').style.borderColor = "green";
      }
      if(id == 'gender3')
      {
        setGender3('');
        document.getElementById('gender3').style.borderColor = "green";
      }
      if(id == 'gender4')
      {
        setGender4('');
        document.getElementById('gender4').style.borderColor = "green";
      }
      if(id == 'gender5')
      {
        setGender5('');
        document.getElementById('gender5').style.borderColor = "green";
      }
      if(id == 'gender6')
      {
        setGender6('');
        document.getElementById('gender6').style.borderColor = "green";
      }
      if(id == 'gender7')
      {
        setGender7('');
        document.getElementById('gender7').style.borderColor = "green";
      }
      if(id == 'gender8')
      {
        setGender8('');
        document.getElementById('gender8').style.borderColor = "green";
      }
    }


    //DOB
    if(e.target.value.length < 1){

      if(id == 'dayOfBirth0')
      {
        setDOB0(t('Please fill out day'));
        document.getElementById('dayOfBirth0').style.borderColor = "red";
        
      }
      if(id == 'dayOfBirth1')
      {
        setDOB1(t('Please fill out day'));
        document.getElementById('dayOfBirth1').style.borderColor = "red";
      }
      if(id == 'dayOfBirth2')
      {
        setDOB2(t('Please fill out day'));
        document.getElementById('dayOfBirth2').style.borderColor = "red";
      }
      if(id == 'dayOfBirth3')
      {
        setDOB3(t('Please fill out day'));
        document.getElementById('dayOfBirth3').style.borderColor = "red";
      }
      if(id == 'dayOfBirth4')
      {
        setDOB4(t('Please fill out day'));
        document.getElementById('dayOfBirth4').style.borderColor = "red";
      }
      if(id == 'dayOfBirth5')
      {
        setDOB5(t('Please fill out day'));
        document.getElementById('dayOfBirth5').style.borderColor = "red";
      }
      if(id == 'dayOfBirth6')
      {
        setDOB6(t('Please fill out day'));
        document.getElementById('dayOfBirth6').style.borderColor = "red";
      }
      if(id == 'dayOfBirth7')
      {
        setDOB7(t('Please fill out day'));
        document.getElementById('dayOfBirth7').style.borderColor = "red";
      }
      if(id == 'dayOfBirth8')
      {
        setDOB8(t('Please fill out day'));
        document.getElementById('dayOfBirth8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
  
      if(id == 'dayOfBirth0')
      {
        setDOB0('');
        document.getElementById('dayOfBirth0').style.borderColor = "green";
      }
      if(id == 'dayOfBirth1')
      {
        setDOB1('');
        document.getElementById('dayOfBirth1').style.borderColor = "green";
      }
      if(id == 'dayOfBirth2')
      {
        setDOB2('');
        document.getElementById('dayOfBirth2').style.borderColor = "green";
      }
      if(id == 'dayOfBirth3')
      {
        setDOB3('');
        document.getElementById('dayOfBirth3').style.borderColor = "green";
      }
      if(id == 'dayOfBirth4')
      {
        setDOB4('');
        document.getElementById('dayOfBirth4').style.borderColor = "green";
      }
      if(id == 'dayOfBirth5')
      {
        setDOB5('');
        document.getElementById('dayOfBirth5').style.borderColor = "green";
      }
      if(id == 'dayOfBirth6')
      {
        setDOB6('');
        document.getElementById('dayOfBirth6').style.borderColor = "green";
      }
      if(id == 'dayOfBirth7')
      {
        setDOB7('');
        document.getElementById('dayOfBirth7').style.borderColor = "green";
      }
      if(id == 'dayOfBirth8')
      {
        setDOB8('');
        document.getElementById('dayOfBirth8').style.borderColor = "green";
      }
    } 

    // MOB

    if(e.target.value.length < 1){
      if(id == 'monthOfBirth0')
      {
        setMOB0(t('Please fill out month'));
        document.getElementById('monthOfBirth0').style.borderColor = "red";
        
      }
      if(id == 'monthOfBirth1')
      {
        setMOB1(t('Please fill out month'));
        document.getElementById('monthOfBirth1').style.borderColor = "red";
      }
      if(id == 'monthOfBirth2')
      {
        setMOB2(t('Please fill out month'));
        document.getElementById('monthOfBirth2').style.borderColor = "red";
      }
      if(id == 'monthOfBirth3')
      {
        setMOB3(t('Please fill out month'));
        document.getElementById('monthOfBirth3').style.borderColor = "red";
      }
      if(id == 'monthOfBirth4')
      {
        setMOB4(t('Please fill out month'));
        document.getElementById('monthOfBirth4').style.borderColor = "red";
      }
      if(id == 'monthOfBirth5')
      {
        setMOB5(t('Please fill out month'));
        document.getElementById('monthOfBirth5').style.borderColor = "red";
      }
      if(id == 'monthOfBirth6')
      {
        setMOB6(t('Please fill out month'));
        document.getElementById('monthOfBirth6').style.borderColor = "red";
      }
      if(id == 'monthOfBirth7')
      {
        setMOB7(t('Please fill out month'));
        document.getElementById('monthOfBirth7').style.borderColor = "red";
      }
      if(id == 'monthOfBirth8')
      {
        setMOB8(t('Please fill out month'));
        document.getElementById('monthOfBirth8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'monthOfBirth0')
      {
        setMOB0('');
        document.getElementById('monthOfBirth0').style.borderColor = "green";
      }
      if(id == 'monthOfBirth1')
      {
        setMOB1('');
        document.getElementById('monthOfBirth1').style.borderColor = "green";
      }
      if(id == 'monthOfBirth2')
      {
        setMOB2('');
        document.getElementById('monthOfBirth2').style.borderColor = "green";
      }
      if(id == 'monthOfBirth3')
      {
        setMOB3('');
        document.getElementById('monthOfBirth3').style.borderColor = "green";
      }
      if(id == 'monthOfBirth4')
      {
        setMOB4('');
        document.getElementById('monthOfBirth4').style.borderColor = "green";
      }
      if(id == 'monthOfBirth5')
      {
        setMOB5('');
        document.getElementById('monthOfBirth5').style.borderColor = "green";
      }
      if(id == 'monthOfBirth6')
      {
        setMOB6('');
        document.getElementById('monthOfBirth6').style.borderColor = "green";
      }
      if(id == 'monthOfBirth7')
      {
        setMOB7('');
        document.getElementById('monthOfBirth7').style.borderColor = "green";
      }
      if(id == 'monthOfBirth8')
      {
        setMOB8('');
        document.getElementById('monthOfBirth8').style.borderColor = "green";
      }
    } 

    // YOB
    if(e.target.value.length < 1){
      if(id == 'yearOfBirth0')
      {
        setYOB0(t('Please fill out year'));
        document.getElementById('yearOfBirth0').style.borderColor = "red";
        
      }
      if(id == 'yearOfBirth1')
      {
        setYOB1(t('Please fill out year'));
        document.getElementById('yearOfBirth1').style.borderColor = "red";
      }
      if(id == 'yearOfBirth2')
      {
        setYOB2(t('Please fill out year'));
        document.getElementById('yearOfBirth2').style.borderColor = "red";
      }
      if(id == 'yearOfBirth3')
      {
        setYOB3(t('Please fill out year'));
        document.getElementById('yearOfBirth3').style.borderColor = "red";
      }
      if(id == 'yearOfBirth4')
      {
        setYOB4(t('Please fill out year'));
        document.getElementById('yearOfBirth4').style.borderColor = "red";
      }
      if(id == 'yearOfBirth5')
      {
        setYOB5(t('Please fill out year'));
        document.getElementById('yearOfBirth5').style.borderColor = "red";
      }
      if(id == 'yearOfBirth6')
      {
        setYOB6(t('Please fill out year'));
        document.getElementById('yearOfBirth6').style.borderColor = "red";
      }
      if(id == 'yearOfBirth7')
      {
        setYOB7(t('Please fill out year'));
        document.getElementById('yearOfBirth7').style.borderColor = "red";
      }
      if(id == 'yearOfBirth8')
      {
        setYOB8(t('Please fill out year'));
        document.getElementById('yearOfBirth8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'yearOfBirth0')
      {
        setYOB0('');
        document.getElementById('yearOfBirth0').style.borderColor = "green";
      }
      if(id == 'yearOfBirth1')
      {
        setYOB1('');
        document.getElementById('yearOfBirth1').style.borderColor = "green";
      }
      if(id == 'yearOfBirth2')
      {
        setYOB2('');
        document.getElementById('yearOfBirth2').style.borderColor = "green";
      }
      if(id == 'yearOfBirth3')
      {
        setYOB3('');
        document.getElementById('yearOfBirth3').style.borderColor = "green";
      }
      if(id == 'yearOfBirth4')
      {
        setYOB4('');
        document.getElementById('yearOfBirth4').style.borderColor = "green";
      }
      if(id == 'yearOfBirth5')
      {
        setYOB5('');
        document.getElementById('yearOfBirth5').style.borderColor = "green";
      }
      if(id == 'yearOfBirth6')
      {
        setYOB6('');
        document.getElementById('yearOfBirth6').style.borderColor = "green";
      }
      if(id == 'yearOfBirth7')
      {
        setYOB7('');
        document.getElementById('yearOfBirth7').style.borderColor = "green";
      }
      if(id == 'yearOfBirth8')
      {
        setYOB8('');
        document.getElementById('yearOfBirth8').style.borderColor = "green";
      }
    } 
    
    let pExpiry0, pExpiry1, pExpiry2, pExpiry3, pExpiry4, pExpiry5, pExpiry6, pExpiry7, pExpiry8;

    // Passport 
    if(e.target.value.length < 1){
      if(id == 'Passport Expiry0' || id == 'National Identity Card Expiry0' || id == "رقم جواز السفر0" || id == "بطاقة الهوية الوطنية0")
      {
        setPassport0(t('Please fill out this field'));
       if(id === "Passport Expiry0" || id === "Passport Number0"){
        document.getElementById('Passport Number0').style.borderColor = "red";
       }
       else if(id === "رقم جواز السفر0"){
        document.getElementById('رقم جواز السفر0').style.borderColor = "red";
       }
       else if(id === "بطاقة الهوية الوطنية0"){
        document.getElementById('بطاقة الهوية الوطنية0').style.borderColor = "red";
       }
       else{
        document.getElementById('National Identity Card0').style.borderColor = "red";
       }
        
      }
      if(id == 'Passport Expiry1' || id == 'National Identity Card Expiry1'  || id == "بطاقة الهوية الوطنية1" || id == "رقم جواز السفر1")
      {
        setPassport1(t('Please fill out this field'));
        if(id === "Passport Expiry1"){
        
          document.getElementById('Passport Number1').style.borderColor = "red";
         }
         else if(id === "رقم جواز السفر1"){
          document.getElementById('رقم جواز السفر1').style.borderColor = "red";
         }
         else if(id === "بطاقة الهوية الوطنية1"){
          document.getElementById('بطاقة الهوية الوطنية1').style.borderColor = "red";
         }
         else{
      
          document.getElementById('National Identity Card1').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry2' || id == 'National Identity Card Expiry2' || id == "بطاقة الهوية الوطنية2" || id == "رقم جواز السفر2")
      {
        setPassport2(t('Please fill out this field'));
        if(id === "Passport Expiry2"){
          document.getElementById('Passport Number2').style.borderColor = "red";
         }
         else if(id === "رقم جواز السفر2"){
          document.getElementById('رقم جواز السفر2').style.borderColor = "red";
         }
         else if(id === "بطاقة الهوية الوطنية2"){
          document.getElementById('بطاقة الهوية الوطنية2').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card2').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry3' || id == 'National Identity Card Expiry3' || id == "بطاقة الهوية الوطنية3" || id == "رقم جواز السفر3")
      {
        setPassport3(t('Please fill out this field'));
        if(id === "Passport Expiry3"){
          document.getElementById('Passport Number3').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card3').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry4' || id == 'National Identity Card Expiry4' || id == "بطاقة الهوية الوطنية4" || id == "رقم جواز السفر4")
      {
        setPassport4(t('Please fill out this field'));
        if(id === "Passport Expiry4"){
          document.getElementById('Passport Number4').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card4').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry5' || id == 'National Identity Card Expiry5' || id == "بطاقة الهوية الوطنية5" || id == "رقم جواز السفر5")
      {
        setPassport5(t('Please fill out this field'));
        if(id === "Passport Expiry5"){
          document.getElementById('Passport Number5').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card5').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry6' || id == 'National Identity Card Expiry6' || id == "بطاقة الهوية الوطنية6" || id == "رقم جواز السفر6")
      {
        setPassport6(t('Please fill out this field'));
        if(id === "Passport Expiry6"){
          document.getElementById('Passport Number6').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card6').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry7' || id == 'National Identity Card Expiry7' || id == "بطاقة الهوية الوطنية7" || id == "رقم جواز السفر7")
      {
        setPassport7(t('Please fill out this field'));
        if(id === "Passport Expiry7"){
          document.getElementById('Passport Number7').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card7').style.borderColor = "red";
         }
      }
      if(id == 'Passport Expiry8' || id == 'National Identity Card Expiry8' || id == "بطاقة الهوية الوطنية8" || id == "رقم جواز السفر8")
      {
        setPassport8(t('Please fill out this field'));
        if(id === "Passport Expiry8"){
          document.getElementById('Passport Number8').style.borderColor = "red";
         }
         else{
          document.getElementById('National Identity Card8').style.borderColor = "red";
         }
      }
    }
    if(e.target.value.length > 1){
      if(id == 'Passport Expiry0' || id == 'National Identity Card Expiry0')
      {
        setPassport0('');
        if(id === "Passport Expiry0"){
          document.getElementById('Passport Number0').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card0').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry1' || id == 'National Identity Card Expiry1')
      {
        setPassport1('');
        if(id === "Passport Expiry1"){
          document.getElementById('Passport Number1').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card1').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry2' || id == 'National Identity Card Expiry2')
      {
        setPassport2('');
        if(id === "Passport Expiry2"){
          document.getElementById('Passport Number2').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card2').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry3' || id == 'National Identity Card Expiry3')
      {
        setPassport3('');
        if(id === "Passport Expiry3"){
          document.getElementById('Passport Number3').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card3').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry4' || id == 'National Identity Card Expiry4')
      {
        setPassport4('');
        if(id === "Passport Expiry4"){
          document.getElementById('Passport Number4').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card4').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry5' || id == 'National Identity Card Expiry5')
      {
        setPassport5('');
        if(id === "Passport Expiry5"){
          document.getElementById('Passport Number5').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card5').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry6' || id == 'National Identity Card Expiry6')
      {
        setPassport6('');
        if(id === "Passport Expiry6"){
          document.getElementById('Passport Number6').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card6').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry7' || id == 'National Identity Card Expiry7')
      {
        setPassport7('');
        if(id === "Passport Expiry7"){
          document.getElementById('Passport Number7').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card7').style.borderColor = "green";
         }
      }
      if(id == 'Passport Expiry8' || id == 'National Identity Card8')
      {
        setPassport8('');
        if(id === "Passport Expiry8"){
          document.getElementById('Passport Number8').style.borderColor = "green";
         }
         else{
          document.getElementById('National Identity Card8').style.borderColor = "green";
         }
      }
    }

    

    //DOP
    if(e.target.value.length < 1){
      if(id == 'dayOfPassport0')
      {
        setDOP0(t('Please fill out day'));
        document.getElementById('dayOfPassport0').style.borderColor = "red";
        
      }
      if(id == 'dayOfPassport1')
      {
        setDOP1(t('Please fill out day'));
        document.getElementById('dayOfPassport1').style.borderColor = "red";
      }
      if(id == 'dayOfPassport2')
      {
        setDOP2(t('Please fill out day'));
        document.getElementById('dayOfPassport2').style.borderColor = "red";
      }
      if(id == 'dayOfPassport3')
      {
        setDOP3(t('Please fill out day'));
        document.getElementById('dayOfPassport3').style.borderColor = "red";
      }
      if(id == 'dayOfPassport4')
      {
        setDOP4(t('Please fill out day'));
        document.getElementById('dayOfPassport4').style.borderColor = "red";
      }
      if(id == 'dayOfPassport5')
      {
        setDOP5(t('Please fill out day'));
        document.getElementById('dayOfPassport5').style.borderColor = "red";
      }
      if(id == 'dayOfPassport6')
      {
        setDOP6(t('Please fill out day'));
        document.getElementById('dayOfPassport6').style.borderColor = "red";
      }
      if(id == 'dayOfPassport7')
      {
        setDOP7(t('Please fill out day'));
        document.getElementById('dayOfPassport7').style.borderColor = "red";
      }
      if(id == 'dayOfPassport8')
      {
        setDOP8(t('Please fill out day'));
        document.getElementById('dayOfPassport8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'dayOfPassport0')
      {
        
        setDOP0('');
        document.getElementById('dayOfPassport0').style.borderColor = "green";
      }
      if(id == 'dayOfPassport1')
      {
        setDOP1('');
        document.getElementById('dayOfPassport1').style.borderColor = "green";
      }
      if(id == 'dayOfPassport2')
      {
        setDOP2('');
        document.getElementById('dayOfPassport2').style.borderColor = "green";
      }
      if(id == 'dayOfPassport3')
      {
        setDOP3('');
        document.getElementById('dayOfPassport3').style.borderColor = "green";
      }
      if(id == 'dayOfPassport4')
      {
        setDOP4('');
        document.getElementById('dayOfPassport4').style.borderColor = "green";
      }
      if(id == 'dayOfPassport5')
      {
        setDOP5('');
        document.getElementById('dayOfPassport5').style.borderColor = "green";
      }
      if(id == 'dayOfPassport6')
      {
        setDOP6('');
        document.getElementById('dayOfPassport6').style.borderColor = "green";
      }
      if(id == 'dayOfPassport7')
      {
        setDOP7('');
        document.getElementById('dayOfPassport7').style.borderColor = "green";
      }
      if(id == 'dayOfPassport8')
      {
        setDOP8('');
        document.getElementById('dayOfPassport8').style.borderColor = "green";
      }
    } 

    // MOP

    if(e.target.value.length < 1){
      if(id == 'monthOfPassport0')
      {
        setMOP0(t('Please fill out month'));
        document.getElementById('monthOfPassport0').style.borderColor = "red";
        
      }
      if(id == 'monthOfPassport1')
      {
        setMOP1(t('Please fill out month'));
        document.getElementById('monthOfPassport1').style.borderColor = "red";
      }
      if(id == 'monthOfPassport2')
      {
        setMOP2(t('Please fill out month'));
        document.getElementById('monthOfPassport2').style.borderColor = "red";
      }
      if(id == 'monthOfPassport3')
      {
        setMOP3(t('Please fill out month'));
        document.getElementById('monthOfPassport3').style.borderColor = "red";
      }
      if(id == 'monthOfPassport4')
      {
        setMOP4(t('Please fill out month'));
        document.getElementById('monthOfPassport4').style.borderColor = "red";
      }
      if(id == 'monthOfPassport5')
      {
        setMOP5(t('Please fill out month'));
        document.getElementById('monthOfPassport5').style.borderColor = "red";
      }
      if(id == 'monthOfPassport6')
      {
        setMOP6(t('Please fill out month'));
        document.getElementById('monthOfPassport6').style.borderColor = "red";
      }
      if(id == 'monthOfPassport7')
      {
        setMOP7(t('Please fill out month'));
        document.getElementById('monthOfPassport7').style.borderColor = "red";
      }
      if(id == 'monthOfPassport8')
      {
        setMOP8(t('Please fill out month'));
        document.getElementById('monthOfPassport8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'monthOfPassport0')
      {
         selectedMonth = document.getElementById('monthOfPassport0').value;
         diffMonths = parseInt(selectedMonth - month);
        //  console.log(diffMonths);
        setDiffMonth(diffMonths);
        setMOP0('');
        document.getElementById('monthOfPassport0').style.borderColor = "green";
      }
      if(id == 'monthOfPassport1')
      {
        selectedMonth = document.getElementById('monthOfPassport1').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP1('');
        document.getElementById('monthOfPassport1').style.borderColor = "green";
      }

      if(id == 'monthOfPassport2')
      {
        selectedMonth = document.getElementById('monthOfPassport2').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP2('');
        document.getElementById('monthOfPassport2').style.borderColor = "green";
      }
      if(id == 'monthOfPassport3')
      {
        selectedMonth = document.getElementById('monthOfPassport3').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP3('');
        document.getElementById('monthOfPassport3').style.borderColor = "green";
      }
      if(id == 'monthOfPassport4')
      {
        selectedMonth = document.getElementById('monthOfPassport4').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP4('');
        document.getElementById('monthOfPassport4').style.borderColor = "green";
      }
      if(id == 'monthOfPassport5')
      {
        selectedMonth = document.getElementById('monthOfPassport5').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP5('');
        document.getElementById('monthOfPassport5').style.borderColor = "green";
      }
      if(id == 'monthOfPassport6')
      {
        selectedMonth = document.getElementById('monthOfPassport6').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP6('');
        document.getElementById('monthOfPassport6').style.borderColor = "green";
      }
      if(id == 'monthOfPassport7')
      {
        selectedMonth = document.getElementById('monthOfPassport7').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP7('');
        document.getElementById('monthOfPassport7').style.borderColor = "green";
      }
      if(id == 'monthOfPassport8')
      {
        selectedMonth = document.getElementById('monthOfPassport8').value;
        diffMonths = parseInt(selectedMonth - month);
        setDiffMonth(diffMonths);
        setMOP8('');
        document.getElementById('monthOfPassport8').style.borderColor = "green";
      }
    } 

    // YOB
    if(e.target.value.length < 1){
      if(id == 'yearOfPassport0')
      {
        setYOP0(t('Please fill out year'));
        document.getElementById('yearOfPassport0').style.borderColor = "red";
        
      }
      if(id == 'yearOfPassport1')
      {
        setYOP1(t('Please fill out year'));
        document.getElementById('yearOfPassport1').style.borderColor = "red";
       
      }
      if(id == 'yearOfPassport2')
      {
        setYOP2(t('Please fill out year'));
        document.getElementById('yearOfPassport2').style.borderColor = "red";
      }
      if(id == 'yearOfPassport3')
      {
        setYOP3(t('Please fill out year'));
        document.getElementById('yearOfPassport3').style.borderColor = "red";
      }
      if(id == 'yearOfPassport4')
      {
        setYOB4(t('Please fill out year'));
        document.getElementById('yearOfPassport4').style.borderColor = "red";
      }
      if(id == 'yearOfPassport5')
      {
        setYOP5(t('Please fill out year'));
        document.getElementById('yearOfPassport5').style.borderColor = "red";
      }
      if(id == 'yearOfPassport6')
      {
        setYOP6(t('Please fill out year'));
        document.getElementById('yearOfPassport6').style.borderColor = "red";
      }
      if(id == 'yearOfPassport7')
      {
        setYOP7(t('Please fill out year'));
        document.getElementById('yearOfPassport7').style.borderColor = "red";
      }
      if(id == 'yearOfPassport8')
      {
        setYOP8(t('Please fill out year'));
        document.getElementById('yearOfPassport8').style.borderColor = "red";
      }
    }
    if(e.target.value.length > 1){
      if(id == 'yearOfPassport0')
      {
          setYOP0('');
          document.getElementById('yearOfPassport0').style.borderColor = "green";
      //   }
      }
      // }
      if(id == 'yearOfPassport1')
      {
        setYOP1('');
        document.getElementById('yearOfPassport1').style.borderColor = "green";
      }
      if(id == 'yearOfPassport2')
      {
        setYOP2('');
        document.getElementById('yearOfPassport2').style.borderColor = "green";
      }
      if(id == 'yearOfPassport3')
      {
        setYOP3('');
        document.getElementById('yearOfPassport3').style.borderColor = "green";
      }
      if(id == 'yearOfPassport4')
      {
        setYOP4('');
        document.getElementById('yearOfPassport4').style.borderColor = "green";
      }
      if(id == 'yearOfPassport5')
      {
        setYOP5('');
        document.getElementById('yearOfPassport5').style.borderColor = "green";
      }
      if(id == 'yearOfPassport6')
      {
        setYOP6('');
        document.getElementById('yearOfPassport6').style.borderColor = "green";
      }
      if(id == 'yearOfPassport7')
      {
        setYOP7('');
        document.getElementById('yearOfPassport7').style.borderColor = "green";
      }
      if(id == 'yearOfPassport8')
      {
        setYOP8('');
        document.getElementById('yearOfPassport8').style.borderColor = "green";
      }
    } 





    let fName0Value, fName1Value, fName2Value, fName3Value, fName4Value, fName5Value, fName6Value, fName7Value, fName8Value;
    let lName0Value, lName1Value, lName2Value, lName3Value, lName4Value, lName5Value, lName6Value, lName7Value, lName8Value;


    if(document.getElementById('fName0')){
     fName0Value = document.getElementById('fName0').value.toLowerCase();
    }
    if(document.getElementById('fName1')){
       fName1Value = document.getElementById('fName1').value.toLowerCase();
    }
    if(document.getElementById('fName2')){
      fName2Value = document.getElementById('fName2').value.toLowerCase();
    }
    if(document.getElementById('fName3')){
      fName3Value = document.getElementById('fName3').value.toLowerCase();
    }
    if(document.getElementById('fName4'))
    {
      fName4Value = document.getElementById('fName4').value.toLowerCase();
    }
    if(document.getElementById('fName5')){
      fName5Value = document.getElementById('fName5').value.toLowerCase();
    }
    if(document.getElementById('fName6')){
      fName6Value = document.getElementById('fName6').value.toLowerCase();
    }
    if(document.getElementById('fName7')){
      fName7Value = document.getElementById('fName7').value.toLowerCase();
    }
    if(document.getElementById('fName8')){
      fName8Value = document.getElementById('fName8').value.toLowerCase();
    }

    // Last Name
    if(document.getElementById('lName0')){
       lName0Value = document.getElementById('lName0').value.toLowerCase();
    }
    if(document.getElementById('lName1')){
       lName1Value = document.getElementById('lName1').value.toLowerCase();
    }
    if(document.getElementById('lName2')){
      lName2Value = document.getElementById('lName2').value.toLowerCase();
    }
    if(document.getElementById('lName3')){
      lName3Value = document.getElementById('lName3').value.toLowerCase();
    }
    if(document.getElementById('lName4')){ 
      lName4Value = document.getElementById('lName4').value.toLowerCase();
    }
    if(document.getElementById('lName5')){
      lName5Value = document.getElementById('lName5').value.toLowerCase();
    }
    if(document.getElementById('lName6')){
      lName6Value = document.getElementById('lName6').value.toLowerCase();
    }
    if(document.getElementById('lName7')){ 

      lName7Value = document.getElementById('lName7').value.toLowerCase();
    } 
    if(document.getElementById('lName8')){
     lName8Value = document.getElementById('lName8').value.toLowerCase();
    }

    let fullName0, fullName1, fullName2, fullName3, fullName4, fullName5, fullName6, fullName7, fullName8;
    fullName0 = fName0Value + lName0Value;
    fullName1 = fName1Value + lName1Value;
    fullName2 = fName2Value + lName2Value;
    fullName3 = fName3Value + lName3Value;
    fullName4 = fName4Value + lName4Value;
    fullName5 = fName5Value + lName5Value;
    fullName6 = fName6Value + lName6Value;
    fullName7 = fName7Value + lName7Value;
    fullName8 = fName8Value + lName8Value;
    


  

    if(fullName0 && fullName1){
      if(fullName0 === fullName1){
        setLName1('Name already exists');
        document.getElementById('lName1').style.borderColor = "red";
        document.getElementById('lName1').focus();
      }
    }

    if(fullName0 && fullName2){
      if(fullName0 === fullName2){
        setLName2('Name already exists');
        document.getElementById('lName2').style.borderColor = "red";
        document.getElementById('lName2').focus();
      }
    }

    if(fullName0 && fullName2){
      if(fullName0 === fullName2){
        setLName2('Name already exists');
        document.getElementById('lName2').style.borderColor = "red";
        document.getElementById('lName2').focus();
      }
    }
    if(fullName0 && fullName3){
      if(fullName0 === fullName3){
        setLName3('Name already exists');
        document.getElementById('lName3').style.borderColor = "red";
        document.getElementById('lName3').focus();
      }
    }

    if(fullName0 && fullName4){
      if(fullName0 === fullName4){
        setLName4('Name already exists');
        document.getElementById('lName4').style.borderColor = "red";
        document.getElementById('lName4').focus();
      }
  
    }

    if(fullName0 && fullName5){
      if(fullName0 === fullName5){
        setLName5('Name already exists');
        document.getElementById('lName5').style.borderColor = "red";
        document.getElementById('lName5').focus();
      }
    }

    if(fullName0 && fullName6){
      if(fullName0 === fullName6){
        setLName6('Name already exists');
        document.getElementById('lName6').style.borderColor = "red";
        document.getElementById('lName6').focus();
      }
    }
    if(fullName0 && fullName7){
      if(fullName0 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName0 && fullName8){
      if(fullName0 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 2 pax

    if(fullName1 && fullName2){
      if(fullName1 === fullName2){
        setLName2('Name already exists');
        document.getElementById('lName2').style.borderColor = "red";
        document.getElementById('lName2').focus();
      }
    }
    if(fullName1 && fullName3){
      if(fullName1 === fullName3){
        setLName3('Name already exists');
        document.getElementById('lName3').style.borderColor = "red";
        document.getElementById('lName3').focus();
      }
    }

    if(fullName1 && fullName4){
      if(fullName1 === fullName4){
        setLName4('Name already exists');
        document.getElementById('lName4').style.borderColor = "red";
        document.getElementById('lName4').focus();
      }
    }

    if(fullName1 && fullName5){
      if(fullName1 === fullName5){
        setLName5('Name already exists');
        document.getElementById('lName5').style.borderColor = "red";
        document.getElementById('lName5').focus();
      }
    }

    if(fullName1 && fullName6){
      if(fullName1 === fullName6){
        setLName6('Name already exists');
        document.getElementById('lName6').style.borderColor = "red";
        document.getElementById('lName6').focus();
      }
    }
    if(fullName1 && fullName7){
      if(fullName1 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName1 && fullName8){
      if(fullName1 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 3 Pax
    if(fullName2 && fullName3){
      if(fullName2 === fullName3){
        setLName3('Name already exists');
        document.getElementById('lName3').style.borderColor = "red";
        document.getElementById('lName3').focus();
      }
    }

    if(fullName2 && fullName4){
      if(fullName2 === fullName4){
        setLName4('Name already exists');
        document.getElementById('lName4').style.borderColor = "red";
        document.getElementById('lName4').focus();
      }
    }

    if(fullName2 && fullName5){
      if(fullName2 === fullName5){
        setLName5('Name already exists');
        document.getElementById('lName5').style.borderColor = "red";
        document.getElementById('lName5').focus();
      }
    }

    if(fullName2 && fullName6){
      if(fullName2 === fullName6){
        setLName6('Name already exists');
        document.getElementById('lName6').style.borderColor = "red";
        document.getElementById('lName6').focus();
      }
    }
    if(fullName2 && fullName7){
      if(fullName2 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName2 && fullName8){
      if(fullName2 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 4 Pax
    if(fullName3 && fullName4){
      if(fullName3 === fullName4){
        setLName4('Name already exists');
        document.getElementById('lName4').style.borderColor = "red";
        document.getElementById('lName4').focus();
      }
    }

    if(fullName3 && fullName5){
      if(fullName3 === fullName5){
        setLName5('Name already exists');
        document.getElementById('lName5').style.borderColor = "red";
        document.getElementById('lName5').focus();
      }
    }

    if(fullName3 && fullName6){
      if(fullName3 === fullName6){
        setLName6('Name already exists');
        document.getElementById('lName6').style.borderColor = "red";
        document.getElementById('lName6').focus();
      }
    }
    if(fullName3 && fullName7){
      if(fullName3 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName3 && fullName8){
      if(fullName3 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 5 Pax
    if(fullName4 && fullName5){
      if(fullName4 === fullName5){
        setLName5('Name already exists');
        document.getElementById('lName5').style.borderColor = "red";
        document.getElementById('lName5').focus();
      }
    }

    if(fullName4 && fullName6){
      if(fullName4 === fullName6){
        setLName6('Name already exists');
        document.getElementById('lName6').style.borderColor = "red";
        document.getElementById('lName6').focus();
      }
    }
    if(fullName4 && fullName7){
      if(fullName4 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName4 && fullName8){
      if(fullName4 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 6 Pax
    if(fullName5 && fullName6){
      if(fullName5 === fullName6){
        setLName6('Name already exists');
        document.getElementById('lName6').style.borderColor = "red";
        document.getElementById('lName6').focus();
      }
    }
    if(fullName5 && fullName7){
      if(fullName5 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName5 && fullName8){
      if(fullName5 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 7 pax
    if(fullName6 && fullName7){
      if(fullName6 === fullName7){
        setLName7('Name already exists');
        document.getElementById('lName7').style.borderColor = "red";
        document.getElementById('lName7').focus();
      }
    }
    if(fullName6 && fullName8){
      if(fullName6 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    // 8 Pax
    if(fullName7 && fullName8){
      if(fullName7 === fullName8){
        setLName8('Name already exists');
        document.getElementById('lName8').style.borderColor = "red";
        document.getElementById('lName8').focus();
      }
    }

    let passport0v, passport1v, passport2v, passport3v, passport4v, passport5v, passport6v, passport7v, passport8v;
    if(document.getElementById('Passport Number0')){
      passport0v = document.getElementById('Passport Number0').value;
    }
    if(document.getElementById('Passport Number1')){
      passport1v = document.getElementById('Passport Number1').value;
    }
    if(document.getElementById('Passport Number2')){
      passport2v = document.getElementById('Passport Number2').value;
    }
    if(document.getElementById('Passport Number3')){
      passport3v = document.getElementById('Passport Number3').value;
    }
    if(document.getElementById('Passport Number4')){
      passport4v = document.getElementById('Passport Number4').value;
    }
    if(document.getElementById('Passport Number5')){
      passport5v = document.getElementById('Passport Number5').value;
    }
    if(document.getElementById('Passport Number6')){
      passport6v = document.getElementById('Passport Number6').value;
    }
    if(document.getElementById('Passport Number7')){
      passport7v = document.getElementById('Passport Number7').value;
    }
    if(document.getElementById('Passport Number8')){
      passport8v = document.getElementById('Passport Number8').value;
    }


    let cnic0v, cnic1v, cnic2v, cnic3v, cnic4v, cnic5v, cnic6v, cnic7v, cnic8v;
    if(document.getElementById('National Identity Card0')){
      cnic0v = document.getElementById('National Identity Card0').value;
    }
    if(document.getElementById('National Identity Card1')){
      cnic1v = document.getElementById('National Identity Card1').value;
    }
    if(document.getElementById('National Identity Card2')){
      cnic2v = document.getElementById('National Identity Card2').value;
    }
    if(document.getElementById('National Identity Card3')){
      cnic3v = document.getElementById('National Identity Card3').value;
    }
    if(document.getElementById('National Identity Card4')){
      cnic4v = document.getElementById('National Identity Card4').value;
    }
    if(document.getElementById('National Identity Card5')){
      cnic5v = document.getElementById('National Identity Card5').value;
    }
    if(document.getElementById('National Identity Card6')){
      cnic6v = document.getElementById('National Identity Card6').value;
    }
    if(document.getElementById('National Identity Card7')){
      cnic7v = document.getElementById('National Identity Card7').value;
    }
    if(document.getElementById('National Identity Card8')){
      cnic8v = document.getElementById('National Identity Card8').value;
    }


    if(passport0v && passport1v){
      if(passport0v === passport1v){
        setPassport1('Passport Number already exists');
        document.getElementById('Passport Number1').focus();

      }
    }
    if(passport0v && passport2v){
      if(passport0v === passport2v){
        setPassport2('Passport Number already exists');
        document.getElementById('Passport Number2').focus();

      }
    }
    if(passport0v && passport3v){
      if(passport0v === passport3v){
        setPassport3('Passport Number already exists');
        document.getElementById('Passport Number3').focus();
    
      }
    }
    if(passport0v && passport4v){
      if(passport0v === passport4v){
        setPassport4('Passport Number already exists');
        document.getElementById('Passport Number4').focus();
     
      }
    }

    if(passport0v && passport5v){
      if(passport0v === passport5v){
        setPassport5('Passport Number already exists');
        document.getElementById('Passport Number5').focus();
   
      }
    }
    if(passport0v && passport6v){
      if(passport0v === passport6v){
        setPassport6('Passport Number already exists');
        document.getElementById('Passport Number6').focus();

      }
    }
    if(passport0v && passport7v){
      if(passport0v === passport7v){
        setPassport7('Passport Number already exists');
        document.getElementById('Passport Number7').focus();

      }
    }
    if(passport0v && passport8v){
      if(passport0v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    }

    // 2 PAX
    if(passport1v && passport2v){
      if(passport1v === passport2v){
        setPassport2('Passport Number already exists');
        document.getElementById('Passport Number2').focus();
      }
    }
    if(passport1v && passport3v){
      if(passport1v === passport3v){
        setPassport3('Passport Number already exists');
        document.getElementById('Passport Number3').focus();
      }
    }
    if(passport1v && passport4v){
      if(passport1v === passport4v){
        setPassport4('Passport Number already exists');
        document.getElementById('Passport Number4').focus();
      }
    }

    if(passport1v && passport5v){
      if(passport1v === passport5v){
        setPassport5('Passport Number already exists');
        document.getElementById('Passport Number5').focus();
      }
    }
    if(passport1v && passport6v){
      if(passport1v === passport6v){
        setPassport6('Passport Number already exists');
        document.getElementById('Passport Number6').focus();
      }
    }
    if(passport1v && passport7v){
      if(passport1v === passport7v){
        setPassport7('Passport Number already exists');
        document.getElementById('Passport Number7').focus();
      }
    }
    if(passport1v && passport8v){
      if(passport1v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    } 

    // 3 PAX
    if(passport2v && passport3v){
      if(passport2v === passport3v){
        setPassport3('Passport Number already exists');
        document.getElementById('Passport Number3').focus();
      }
    }
    if(passport2v && passport4v){
      if(passport2v === passport4v){
        setPassport4('Passport Number already exists');
        document.getElementById('Passport Number4').focus();
      }
    }
    if(passport2v && passport5v){
      if(passport2v === passport5v){
        setPassport5('Passport Number already exists');
        document.getElementById('Passport Number5').focus();
      }
    }
    if(passport2v && passport6v){
      if(passport2v === passport6v){
        setPassport6('Passport Number already exists');
        document.getElementById('Passport Number6').focus();
      }
    }
    if(passport2v && passport7v){
      if(passport2v === passport7v){
        setPassport7('Passport Number already exists');
        document.getElementById('Passport Number7').focus();
      }
    }
    if(passport2v && passport8v){
      if(passport2v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
    } 
  }
    // 4 PAX
    if(passport3v && passport4v){
      if(passport3v === passport4v){
        setPassport4('Passport Number already exists');
        document.getElementById('Passport Number4').focus();
      }
    }
    if(passport3v && passport5v){
      if(passport3v === passport5v){
        setPassport5('Passport Number already exists');
        document.getElementById('Passport Number5').focus();
      }
    }
    if(passport3v && passport6v){
      if(passport3v === passport6v){
        setPassport6('Passport Number already exists');
        document.getElementById('Passport Number6').focus();
      }
    }
    if(passport3v && passport7v){
      if(passport3v === passport7v){
        setPassport7('Passport Number already exists');
        document.getElementById('Passport Number7').focus();
      }
    }
    if(passport3v && passport8v){
      if(passport3v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    } 


    // 5 PAX
    if(passport4v && passport5v){
      if(passport4v === passport5v){
        setPassport5('Passport Number already exists');
        document.getElementById('Passport Number5').focus();
      }
    }
    if(passport4v && passport6v){
      if(passport4v === passport6v){
        setPassport6('Passport Number already exists');
        document.getElementById('Passport Number6').focus();
      }
    }
    if(passport4v && passport7v){
      if(passport4v === passport7v){
        setPassport7('Passport Number already exists')
        document.getElementById('Passport Number7').focus();
      }
    }
    if(passport4v && passport8v){
      if(passport4v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    } 


    // 6 PAX
    if(passport5v && passport6v){
      if(passport5v === passport6v){
        setPassport6('Passport Number already exists');
        document.getElementById('Passport Number6').focus();
      }
    }
    if(passport6v && passport7v){
      if(passport6v === passport7v){
        setPassport7('Passport Number already exists');
        document.getElementById('Passport Number7').focus();
      }
    }
    if(passport6v && passport8v){
      if(passport6v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    } 

    // 7 PAX
    if(passport6v && passport7v){
      if(passport6v === passport7v){
        setPassport7('Passport Number already exists');
        document.getElementById('Passport Number7').focus();
      }
    }
    if(passport7v && passport8v){
      if(passport7v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    } 

    // 8 PAX
    if(passport7v && passport8v){
      if(passport7v === passport8v){
        setPassport8('Passport Number already exists');
        document.getElementById('Passport Number8').focus();
      }
    } 


    
    if(cnic0v && cnic1v){
      if(cnic0v === cnic1v){
        setPassport1('CNIC already exists');
        document.getElementById('National Identity Card1').focus();
      }
    }
    if(cnic0v && cnic2v){
      if(cnic0v === cnic2v){
        setPassport2('CNIC already exists');
        document.getElementById('National Identity Card2').focus();
      }
    }
    if(cnic0v && cnic3v){
      if(cnic0v === cnic3v){
        setPassport3('CNIC already exists');
        document.getElementById('National Identity Card3').focus();
      }
    }
    if(cnic0v && cnic4v){
      if(cnic0v === cnic4v){
        setPassport4('CNIC already exists');
        document.getElementById('National Identity Card4').focus();
      }
    }

    if(cnic0v && cnic5v){
      if(cnic0v === cnic5v){
        setPassport5('CNIC already exists');
        document.getElementById('National Identity Card5').focus();
      }
    }
    if(cnic0v && cnic6v){
      if(cnic0v === cnic6v){
        setPassport6('CNIC already exists');
        document.getElementById('National Identity Card6').focus();
      }
    }
    if(cnic0v && cnic7v){
      if(cnic0v === cnic7v){
        setPassport7('CNIC already exists');
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic0v && cnic8v){
      if(cnic0v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    }

    // 2 PAX
    if(cnic1v && cnic2v){
      if(cnic1v === cnic2v){
        setPassport2('CNIC already exists');
        document.getElementById('National Identity Card2').focus();
      }
    }
    if(cnic1v && cnic3v){
      if(cnic1v === cnic3v){
        setPassport3('CNIC already exists');
        document.getElementById('National Identity Card3').focus();
      }
    }
    if(cnic1v && cnic4v){
      if(cnic1v === cnic4v){
        setPassport4('CNIC already exists');
        document.getElementById('National Identity Card4').focus();
      }
    }

    if(cnic1v && cnic5v){
      if(cnic1v === cnic5v){
        setPassport5('CNIC already exists');
        document.getElementById('National Identity Card5').focus();
      }
    }
    if(cnic1v && cnic6v){
      if(cnic1v === cnic6v){
        setPassport6('CNIC already exists');
        document.getElementById('National Identity Card6').focus();
      }
    }
    if(cnic1v && cnic7v){
      if(cnic1v === cnic7v){
        setPassport7('CNIC already exists');
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic1v && cnic8v){
      if(cnic1v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    } 

    // 3 PAX
    if(cnic2v && cnic3v){
      if(cnic2v === cnic3v){
        setPassport3('CNIC already exists');
        document.getElementById('National Identity Card3').focus();
      }
    }
    if(cnic2v && cnic4v){
      if(cnic2v === cnic4v){
        setPassport4('CNIC already exists');
        document.getElementById('National Identity Card4').focus();
      }
    }
    if(cnic2v && cnic5v){
      if(cnic2v === cnic5v){
        setPassport5('CNIC already exists');
        document.getElementById('National Identity Card5').focus();
      }
    }
    if(cnic2v && cnic6v){
      if(cnic2v === cnic6v){
        setPassport6('CNIC already exists');
        document.getElementById('National Identity Card6').focus();
      }
    }
    if(cnic2v && cnic7v){
      if(cnic2v === cnic7v){
        setPassport7('CNIC already exists');
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic2v && cnic8v){
      if(cnic2v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
    } 
  }
    // 4 PAX
    if(cnic3v && cnic4v){
      if(cnic3v === cnic4v){
        setPassport4('CNIC already exists');
        document.getElementById('National Identity Card4').focus();
      }
    }
    if(cnic3v && cnic5v){
      if(cnic3v === cnic5v){
        setPassport5('CNIC already exists');
        document.getElementById('National Identity Card5').focus();
      }
    }
    if(cnic3v && cnic6v){
      if(cnic3v === cnic6v){
        setPassport6('CNIC already exists');
        document.getElementById('National Identity Card6').focus();
      }
    }
    if(cnic3v && cnic7v){
      if(cnic3v === cnic7v){
        setPassport7('CNIC already exists');
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic3v && cnic8v){
      if(cnic3v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    } 


    // 5 PAX
    if(cnic4v && cnic5v){
      if(cnic4v === cnic5v){
        setPassport5('CNIC already exists');
        document.getElementById('National Identity Card5').focus();
      }
    }
    if(cnic4v && cnic6v){
      if(cnic4v === cnic6v){
        setPassport6('CNIC already exists');
        document.getElementById('National Identity Card6').focus();
      }
    }
    if(cnic4v && cnic7v){
      if(cnic4v === cnic7v){
        setPassport7('CNIC already exists')
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic4v && cnic8v){
      if(cnic4v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    } 


    // 6 PAX
    if(cnic5v && cnic6v){
      if(cnic5v === cnic6v){
        setPassport6('CNIC already exists');
        document.getElementById('National Identity Card6').focus();
      }
    }
    if(cnic5v && cnic7v){
      if(cnic5v === cnic7v){
        setPassport7('CNIC already exists');
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic5v && cnic8v){
      if(cnic5v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    } 

    // 7 PAX
    if(cnic6v && cnic7v){
      if(cnic6v == cnic7v){
        setPassport7('CNIC already exists');
        document.getElementById('National Identity Card7').focus();
      }
    }
    if(cnic7v && cnic8v){
      if(cnic7v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    } 

    // 8 PAX
    if(cnic7v && cnic8v){
      if(cnic7v === cnic8v){
        setPassport8('CNIC already exists');
        document.getElementById('National Identity Card8').focus();
      }
    } 


  }






  const [firstNameError, setFirstNameError] = useState();

  const [lastNameError, setLastNameError] = useState();

  const [disabledInput, setDisabledInput] = useState(false);

  const onFirstNameChange = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setFirstNameError("");
    }
    const re = /^[A-Za-z]+$/;
    if (value.length > 0 && re.test(value) === false) {
      setFirstNameError("Only alphabets are allowed");
      // alert('Only Alphabets are allowed');
    }
    if (value !== null && re.test(value) === true) {
      setFirstNameError("");
      setDisabledInput(false);
    }
  };

  const lastNameChange = (e) => {
    const lastNameValue = e.target.value;
    if (lastNameValue === null) {
      setLastNameError("Must fill this field");
    }
    const re = /^[A-Za-z]+$/;
    if (lastNameValue !== null && re.test(lastNameValue) === false) {
      setLastNameError("Only alphabets are allowed");
      // alert('Only alphabets are allowed');
      e.target.blur();
    }
    if (lastNameValue !== null && re.test(lastNameValue) === true) {
      setLastNameError("");
    }
  };

 
  let adultText = "Adult";
  let childText = "Child";
  let infantText = "Infant";

  let adultArr = [];
  let childArr = [];
  let infantArr = [];

  for (let i = 1; i <= pricingInfo?.adultQty; i++) {
    adultArr.push(i);
  }

  for (let i = 1; i <= pricingInfo?.childQty; i++) {
    childArr.push(i);
  }

  for (let i = pricingInfo?.infantsQty; i >= 1; i--) {
    infantArr.push(i);
  }

  const [showCountries, setShowCountries] = useState(false);

  const displayCountries = () => {
    if (showCountries === true) {
      setShowCountries(false);
    } else {
      setShowCountries(true);
    }
  };

  useEffect(() => {
    setShowCountries(false);
  }, [codeValue]);

  const [selectedPassportCountry, setSelectedPassportCountry] = useState(
    "SA"
  );
  const [showPassportCountries, setShowPassportCountries] = useState(false);
  const [passportFilteredList, setPassportFilteredList] = useState();
  const [getIndex, setIndex] = useState();

  const [selectedNationalCountry, setSelectedNationalCountry] = useState(
    "SA"
  );
  const [showNationalCountries, setShowNationalCountries] = useState(false);
  const [nationalFilteredList, setNationalFilteredList] = useState();
  const [getNationalIndex, setNationalIndex] = useState();

  const showSelectedLabel = true;
  const showSecondarySelectedLabel = true;
  const showOptionLabel = true;
  const showSecondaryOptionLabel = true;
  const searchable = true;

  const customLabels = {
    AF: { secondary: "+93", primary: "Afghanistan" },
    AX: { secondary: "+358", primary: "Aland Islands" },
    AL: { secondary: "+355", primary: "Albania" },
    DZ: { secondary: "+213", primary: "Algeria" },
    AS: { secondary: "+1684", primary: "AmericanSamoa" },
    AD: { secondary: "+376", primary: "Andorra" },
    AO: { secondary: "+244", primary: "Angola" },
    AI: { secondary: "+1264", primary: "Anguilla" },
    AQ: { secondary: "+672", primary: "Antarctica" },
    AG: { secondary: "+1268", primary: "Barbuda" },
    AR: { secondary: "+54", primary: "Argentina" },
    AM: { secondary: "+374", primary: "Armenia" },
    AW: { secondary: "+297", primary: "Aruba" },
    AU: { secondary: "+61", primary: "Australia" },
    AT: { secondary: "+43", primary: "Austria" },
    AZ: { secondary: "+994", primary: "Azerbaijan" },
    BS: { secondary: "+1242", primary: "Bahamas" },
    BH: { secondary: "+973", primary: "Bahrain" },
    BD: { secondary: "+880", primary: "Bangladesh" },
    BB: { secondary: "+1246", primary: "Barbados" },
    BY: { secondary: "+375", primary: "Belarus" },
    BE: { secondary: "+32", primary: "Belgium" },
    BZ: { secondary: "+229", primary: "Belize" },
    BM: { secondary: "+1441", primary: "Bermuda" },
    BT: { secondary: "+975", primary: "Bhtuan" },
    BJ: {secondary : "+229", primary: "Benin"},
    BO: {secondary : "+591", primary: "Bolivia (Plurinational State of)"},
    BA: { secondary: "+387", primary: "Bosnia" },
    BW: { secondary: "+267", primary: "Botswana" },
    BR: { secondary: "+55", primary: "Brazil" },
    BN: { secondary: "+673", primary: "Brunei" },
    BG: { secondary: "+359", primary: "Bulgaria" },
    BF: { secondary: "+226", primary: "Burkina" },
    BI: { secondary: "+257", primary: "Burundi" },
    KH: { secondary: "+855", primary: "Cambodia" },
    CM: { secondary: "+237", primary: "Cameroon" },
    CA: { secondary: "+1", primary: "Canada" },
    CV: { secondary: "+238", primary: "Cape verde" },
    KY: { secondary: "+345", primary: "Cayman Islands" },
    TD: { secondary: "+235", primary: "Chad" },
    CL: { secondary: "+56", primary: "Chile" },
    CN: { secondary: "+86", primary: "China" },
    CF: {secondary : "+236" , primary : "Central African Republic"},
    CI: {secondary : "+225", primary : "Côte d'Ivoire"},
    CW: {secondary : "+599", primary : "Curaçao"},	
    CD: {secondary : "+243", primary : "Congo (the Democratic Republic of the)"},
    CX: { secondary: "+61", primary: "Chirstmas Island" },
    CO: { secondary: "+57", primary: "Colombia" },
    KM: { secondary: "+269", primary: "Comoros" },
    CG: { secondary: "+242", primary: "Congo" },
    CK: { secondary: "+682", primary: "Cook Islands" },
    CR: { secondary: "+506", primary: "Costa Rica" },
    HR: { secondary: "+385", primary: "Croatia" },
    CU: { secondary: "+53", primary: "Cuba" },
    CY: { secondary: "+357", primary: "Cyprus" },

    CZ: {secondary: "+420", primary: "Czechia"},
    DJ: {secondary: "+253", primary : "Djibouti"},
    DO: {secondary: "+1", primary: "Dominican Republic"},
    SV: {secondary : "+503", primary: "El Salvador"},
    GQ: {secondary : "+240", primary : "Equatorial Guinea"},
    FK: {secondary : "+500" , primary: "Falkland Islands"},
    GU: {secondary : "+1", primary : "Guam"},
    GT: {secondary : "+502", primary : "Guatemala"},
    GG: {secondary : "+44", primary : "Guernsey"},
    GN: {secondary : "+224", primary: "Guinea"},
    GW: {secondary : "+245", primary : "Guinea-Bissau"},
    IM: {secondary : "+44", primary : "Isle of Man"},
    KI: {secondary : "+686", primary : "Kiribati"},
    KR: {secondary : "+82" , primary : "South Korea"},
    LS: {secondary : "+266", primary : "Lesotho"},
    LY: {secondary : "+218", primary : "Libya"},
    LI: {secondary : "+423", primary : "Liechtenstein"},
    LT: {secondary : "+370" , primary : "Lithuania"},
    MK: {secondary : "+389", primary : "Republic of Macedonia"},
    MG: {secondary : "+261", primary : "Madagascar"},
    MH: {secondary : "+692", primary : "Marshall Islands"},
    MQ: {secondary : "+596", primary : "Martinique"},
    MU: {secondary : "+230", primary : "Mauritius"},
    FM: {secondary : "+691", primary: "Micronesia"},
    MS: {secondary : "+1", primary : "Montserrat"},
    MZ: {secondary : "+258" , primary : "Mozambique"},
    NR: {secondary : "+674", primary : "Nauru"},
    NI: {secondary : "+505", primary : "Nicaragua"},
    NU: {secondary : "+683", primary : "Niue"},
    MP: {secondary : "+1", primary : "Northern Mariana Islands"},
    PS: {secondary : "+970", primary : "Palestine"},
    PG: {secondary : "+675", primary : "Papua New Guinea"},
    PE: {secondary : "+51", primary : "Peru"},
    PN: {secondary : "+64", primary : "Pitcairn"},
    PR: {secondary : "+1", primary : "Puerto Rico"},
    RW: {secondary : "+250", primary : "Rwanda"},
    LC: {secondary : "+1", primary : "Saint Lucia"},
    SM: {secondary : "+378", primary : "San Marino"},
    ST: {secondary : "+239", primary : "Sao Tome and Principe"},
    SC: {secondary : "+248", primary : "Seychelles"},
    SL: {secondary : "+232", primary : "Sierra Leone"},
    SX: {secondary : "+721", primary : "Sint Maarten"},
    SK: {secondary : "+421", primary : "Slovakia"},
    SI: {secondary : "+386", primary : "Slovenia"},
    SB: {secondary : "+677", primary : "Solomon Islands"},
    SS: {secondary : "+211", primary : "South Sudan"},
    SR: {secondary : "+597" , primary : "Suriname"},
    SZ: {secondary : "+268", primary : "Swaziland"},
    TZ: {secondary : "+255", primary : "Tanzania"},
    TG: {secondary : "+228", primary : "Togo"},
    TK: {secondary : "+690", primary : "Tokelau"},
    TO: {secondary : "+676", primary : "Tonga"},
    TT: {secondary : "+1", primary : "Trinidad and Tobago"},
    TC: {secondary : "+1", primary : "Turks and Caicos Islands"},
    TV: {secondary : "+688", primary : "Tuvalu"},
    UY: {secondary : "+598", primary : "Uruguay"},
    VU: {secondary : "+678", primary : "Vanuatu"},
    VE: {secondary : "+58", primary : "Venezuela"},
    VI: {secondary : "+1", primary : "Virgin Islands"},
    KN: {secondary : "+1" , primary : "Saint Kitts and Nevis"},
    NF: {secondary : "+672" , primary : "Norfolk Island"},
    MR: {secondary : "+222" , primary : "Mauritania"},
    LA: {secondary : "+856", primary : "Lao People's Democratic Republic"},
    IO: {secondary : "+246", primary : "British Indian Ocean Territory"},
    YE: {secondary : "+967", primary : "Yemen"},

    DK: { secondary: "+45", primary: "Denmark" },
    DM: { secondary: "+1767", primary: "Dominica" },
    EC: { secondary: "+593", primary: "Ecuador" },
    EG: { secondary: "+20", primary: "Egypt" },
    ER: { secondary: "+291", primary: "Eritrea" },
    EE: { secondary: "+372", primary: "Estonia" },
    ET: { secondary: "+251", primary: "Ethopia" },
    FO: { secondary: "+298", primary: "Farore Islands" },
    FJ: { secondary: "+679", primary: "Fijji" },
    FI: { secondary: "+358", primary: "Finland" },
    FR: { secondary: "+33", primary: "France" },
    PF: { secondary: "+689", primary: "French Polynesia" },
    GA: { secondary: "+241", primary: "Gabon" },
    GM: { secondary: "+220", primary: "Gambia" },
    GE: { secondary: "+995", primary: "Gerogia" },
    DE: { secondary: "+49", primary: "Germany" },
    GH: { secondary: "+233", primary: "Ghana" },
    GI: { secondary: "+350", primary: "Gibraltar" },
    GR: { secondary: "+30", primary: "Greece" },
    GL: { secondary: "+299", primary: "GreenLand" },
    GD: { secondary: "+1473", primary: "Grenada" },
    GY: { secondary: "+595", primary: "Guyana" },
    HT: { secondary: "+509", primary: "Hiati" },
    HN: { secondary: "+504", primary: "Honduras" },
    HK: { secondary: "+852", primary: "Hong Kong" },
    HU: { secondary: "+36", primary: "Hungary" },
    IS: { secondary: "+354", primary: "Iceland" },
    IN: { secondary: "+91", primary: "India" },
    ID: { secondary: "+62", primary: "Indonesia" },
    IR: { secondary: "+98", primary: "Iran" },
    IQ: { secondary: "+964", primary: "Iraq" },
    IE: { secondary: "+353", primary: "Ireland" },
    IL: { secondary: "+972", primary: "Israel" },
    IT: { secondary: "+39", primary: "Italy" },
    JM: { secondary: "+1876", primary: "Jamaica" },
    JP: { secondary: "+81", primary: "Japan" },
    JE: { secondary: "+44", primary: "Jersey" },
    JO: { secondary: "+962", primary: "Jordan" },
    KZ: { secondary: "+77", primary: "Kazakhstan" },
    KE: { secondary: "+254", primary: "kenya" },
    KP: { secondary: "+850", primary: "Korea" },
    KW: { secondary: "+965", primary: "Kuwait" },
    KG: { secondary: "+996", primary: "Kyrgyzstan" },
    LV: { secondary: "+371", primary: "Latvia" },
    LB: { secondary: "+961", primary: "Lebanon" },
    LR: { secondary: "+231", primary: "Liberiya" },
    LU: { secondary: "+352", primary: "Luxembourg" },
    MO: { secondary: "+853", primary: "Macao" },
    MW: { secondary: "+265", primary: "Malawi" },
    MY: { secondary: "+60", primary: "Malaysia" },
    MV: { secondary: "+960", primary: "Maldives" },
    ML: { secondary: "+223", primary: "Mali" },
    MT: { secondary: "+356", primary: "Malta" },
    MD: { secondary: "+373", primary: "Moldava" },
    MX: { secondary: "+52", primary: "Mexico" },
    MC: { secondary: "+377", primary: "Monaco" },
    MN: { secondary: "+976", primary: "Mongolia" },
    ME: { secondary: "+382", primary: "Montenegro" },
    MA: { secondary: "+212", primary: "Morocco" },
    MM: { secondary: "+95", primary: "Myanmar" },
    NA: { secondary: "+264", primary: "Namibia" },
    NP: { secondary: "+977", primary: "Nepal" },
    NL: { secondary: "+31", primary: "Netherlands" },
    NZ: { secondary: "+64", primary: "New Zealand" },
    NE: { secondary: "+227", primary: "Niger" },
    NG: { secondary: "+234", primary: "Nigeria" },
    NO: { secondary: "+47", primary: "Norway" },
    OM: { secondary: "+968", primary: "Oman" },
    PK: { secondary: "+92", primary: "Pakistan" },
    PW: { secondary: "+680", primary: "Palau" },
    AW: { secondary: "+970", primary: "Palestin" },
    PA: { secondary: "+507", primary: "Panama" },
    PY: { secondary: "+595", primary: "Paraguay" },
    PH: { secondary: "+63", primary: "Philipinnes" },
    PL: { secondary: "+48", primary: "Poland" },
    PT: { secondary: "+351", primary: "Portugal" },
    QA: { secondary: "+974", primary: "Qatar" },
    RO: { secondary: "+40", primary: "Romania" },
    RU: { secondary: "+7", primary: "Russia" },
    WS: { secondary: "+685", primary: "Samoa" },
    SA: { secondary: "+966", primary: "Saudi Arabia" },
    SN: { secondary: "+221", primary: "Senegal" },
    RS: { secondary: "+381", primary: "Serbia" },
    SG: { secondary: "+65", primary: "Singapore" },
    SO: { secondary: "+252", primary: "Somalia" },
    ZA: { secondary: "+27", primary: "South Africa" },
    ES: { secondary: "+34", primary: "Spain" },
    LK: { secondary: "+94", primary: "Sri Lanka" },
    SD: { secondary: "+249", primary: "Sudan" },
    SE: { secondary: "+46", primary: "Swedan" },
    CH: { secondary: "+41", primary: "Switzerland" },
    SY: { secondary: "+963", primary: "Syria" },
    TW: { secondary: "+886", primary: "Taiwan" },
    TJ: { secondary: "+992", primary: "Tajiksatn" },
    TH: { secondary: "+66", primary: "Thailand" },
    TN: { secondary: "+216", primary: "Tunisia" },
    TR: { secondary: "+90", primary: "Turkey" },
    TM: { secondary: "+993", primary: "Turkmenistan" },
    UG: { secondary: "+256", primary: "Uganda" },
    UA: { secondary: "+380", primary: "Ukarina" },
    AE: { secondary: "+971", primary: "United Arab Emirates" },
    GB: { secondary: "+44", primary: "United Kingdom" },
    US: { secondary: "+1", primary: "United States" },
    UZ: { secondary: "+998", primary: "Uzbekistan" },
    VN: { secondary: "+84", primary: "Vitenam" },
    YM: { secondary: "+967", primary: "Yemen" },
    ZM: { secondary: "+297", primary: "Zambia" },
    ZW: { secondary: "+263", primary: "Zimbawbe" },
  };

  const [passportIssue0, setPassportIssue0] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue1, setPassportIssue1] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue2, setPassportIssue2] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue3, setPassportIssue3] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue4, setPassportIssue4] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue5, setPassportIssue5] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue6, setPassportIssue6] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue7, setPassportIssue7] = useState(process.env.REACT_APP_COUNTRY);
  const [passportIssue8, setPassportIssue8] = useState(process.env.REACT_APP_COUNTRY);

  const [nationCountry0, setNationCountry0] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry1, setNationCountry1] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry2, setNationCountry2] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry3, setNationCountry3] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry4, setNationCountry4] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry5, setNationCountry5] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry6, setNationCountry6] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry7, setNationCountry7] = useState(process.env.REACT_APP_COUNTRY);
  const [nationCountry8, setNationCountry8] = useState(process.env.REACT_APP_COUNTRY);


  const callPassportIssue = (ind, val) => {
    if (ind == 0) {
      setPassportIssue0(val);
    }
    if (ind == 1) {
      setPassportIssue1(val);
    }
    if (ind == 2) {
      setPassportIssue2(val);
    }
    if (ind == 3) {
      setPassportIssue3(val);
    }
    if (ind == 4) {
      setPassportIssue4(val);
    }
    if (ind == 5) {
      setPassportIssue5(val);
    }
    if (ind == 6) {
      setPassportIssue6(val);
    }
    if (ind == 7) {
      setPassportIssue7(val);
    }
    if (ind == 8) {
      setPassportIssue8(val);
    }
  };

  const callNationalCountry = (ind, val) => {
    if (ind == 0) {
      setNationCountry0(val);
    }
    if (ind == 1) {
      setNationCountry1(val);
    }
    if (ind == 2) {
      setNationCountry2(val);
    }
    if (ind == 3) {
      setNationCountry3(val);
    }
    if (ind == 4) {
      setNationCountry4(val);
    }
    if (ind == 5) {
      setNationCountry5(val);
    }
    if (ind == 6) {
      setNationCountry6(val);
    }
    if (ind == 7) {
      setNationCountry7(val);
    }
    if (ind == 8) {
      setNationCountry8(val);
    }
  };

  const checkSelectedVal = (code) => {
    if (code === "AF") {
      setCodeUpdatedValue("+93");
    } else if (code === "AX") {
      setCodeUpdatedValue("+358");
    } else if (code === "AL") {
      setCodeUpdatedValue("+355");
    } else if (code === "DZ") {
      setCodeUpdatedValue("+213");
    } else if (code === "AS") {
      setCodeUpdatedValue("+1684");
    } else if (code === "AD") {
      setCodeUpdatedValue("+376");
    } else if (code === "AO") {
      setCodeUpdatedValue("+244");
    } else if (code === "AI") {
      setCodeUpdatedValue("+1264");
    } else if (code === "AQ") {
      setCodeUpdatedValue("+672");
    } else if (code === "AG") {
      setCodeUpdatedValue("+1268");
    } else if (code === "AR") {
      setCodeUpdatedValue("+54");
    } else if (code === "AW") {
      setCodeUpdatedValue("+297");
    } else if (code === "AU") {
      setCodeUpdatedValue("+61");
    } else if (code === "AT") {
      setCodeUpdatedValue("+43");
    } else if (code === "AZ") {
      setCodeUpdatedValue("+994");
    } else if (code === "BS") {
      setCodeUpdatedValue("+1242");
    } else if (code === "BH") {
      setCodeUpdatedValue("+973");
    } else if (code === "BD") {
      setCodeUpdatedValue("+880");
    } else if (code === "BB") {
      setCodeUpdatedValue("+1246");
    } else if (code === "BY") {
      setCodeUpdatedValue("+375");
    } else if (code === "BE") {
      setCodeUpdatedValue("+32");
    } else if (code === "BZ") {
      setCodeUpdatedValue("+501");
    } else if (code === "KH") {
      setCodeUpdatedValue("+855");
    } else if (code === "CM") {
      setCodeUpdatedValue("+237");
    } else if (code === "CA") {
      setCodeUpdatedValue("+1");
    } else if (code === "CV") {
      setCodeUpdatedValue("+238");
    } else if (code === "KY") {
      setCodeUpdatedValue("+345");
    } else if (code === "CF") {
      setCodeUpdatedValue("+236");
    } else if (code === "CL") {
      setCodeUpdatedValue("+56");
    } else if (code === "CN") {
      setCodeUpdatedValue("+86");
    } else if (code === "CX") {
      setCodeUpdatedValue("+61");
    } else if (code === "CO") {
      setCodeUpdatedValue("+57");
    } else if (code === "KM") {
      setCodeUpdatedValue("+269");
    } else if (code === "CG") {
      setCodeUpdatedValue("+242");
    } else if (code === "CK") {
      setCodeUpdatedValue("+682");
    } else if (code === "CR") {
      setCodeUpdatedValue("+506");
    } else if (code === "HR") {
      setCodeUpdatedValue("+385");
    } else if (code === "CU") {
      setCodeUpdatedValue("+53");
    } else if (code === "CY") {
      setCodeUpdatedValue("+357");
    } else if (code === "DK") {
      setCodeUpdatedValue("+45");
    } else if (code === "DM") {
      setCodeUpdatedValue("+1767");
    } else if (code === "EC") {
      setCodeUpdatedValue("+593");
    } else if (code === "EG") {
      setCodeUpdatedValue("+20");
    } else if (code === "ER") {
      setCodeUpdatedValue("+291");
    } else if (code === "EE") {
      setCodeUpdatedValue("+372");
    } else if (code === "ET") {
      setCodeUpdatedValue("+251");
    } else if (code === "FO") {
      setCodeUpdatedValue("+298");
    } else if (code === "FJ") {
      setCodeUpdatedValue("+679");
    } else if (code === "FR") {
      setCodeUpdatedValue("+33");
    } else if (code === "PF") {
      setCodeUpdatedValue("+689");
    } else if (code === "GA") {
      setCodeUpdatedValue("+241");
    } else if (code === "GM") {
      setCodeUpdatedValue("+220");
    } else if (code === "GE") {
      setCodeUpdatedValue("+995");
    } else if (code === "DE") {
      setCodeUpdatedValue("+49");
    } else if (code === "GH") {
      setCodeUpdatedValue("+233");
    } else if (code === "GI") {
      setCodeUpdatedValue("+350");
    } else if (code === "GR") {
      setCodeUpdatedValue("+30");
    } else if (code === "GL") {
      setCodeUpdatedValue("+299");
    } else if (code === "GD") {
      setCodeUpdatedValue("+1473");
    } else if (code === "GY") {
      setCodeUpdatedValue("+595");
    } else if (code === "HT") {
      setCodeUpdatedValue("+509");
    } else if (code === "HN") {
      setCodeUpdatedValue("+504");
    } else if (code === "HK") {
      setCodeUpdatedValue("+852");
    } else if (code === "HU") {
      setCodeUpdatedValue("+36");
    } else if (code === "IS") {
      setCodeUpdatedValue("+354");
    } else if (code === "IN") {
      setCodeUpdatedValue("+91");
    } else if (code === "ID") {
      setCodeUpdatedValue("+62");
    } else if (code === "IR") {
      setCodeUpdatedValue("+98");
    } else if (code === "IQ") {
      setCodeUpdatedValue("+964");
    } else if (code === "IE") {
      setCodeUpdatedValue("+353");
    } else if (code === "IL") {
      setCodeUpdatedValue("+972");
    } else if (code === "IT") {
      setCodeUpdatedValue("+39");
    } else if (code === "JM") {
      setCodeUpdatedValue("+1876");
    } else if (code === "JP") {
      setCodeUpdatedValue("+81");
    } else if (code === "JE") {
      setCodeUpdatedValue("+44");
    } else if (code === "JO") {
      setCodeUpdatedValue("+962");
    } else if (code === "KZ") {
      setCodeUpdatedValue("+77");
    } else if (code === "KE") {
      setCodeUpdatedValue("+254");
    } else if (code === "KP") {
      setCodeUpdatedValue("+850");
    } else if (code === "KQ") {
      setCodeUpdatedValue("+965");
    } else if (code === "KG") {
      setCodeUpdatedValue("+996");
    } else if (code === "LV") {
      setCodeUpdatedValue("+371");
    } else if (code === "LB") {
      setCodeUpdatedValue("+961");
    } else if (code === "LR") {
      setCodeUpdatedValue("+231");
    } else if (code === "LU") {
      setCodeUpdatedValue("+352");
    } else if (code === "MO") {
      setCodeUpdatedValue("+853");
    } else if (code === "MW") {
      setCodeUpdatedValue("+265");
    } else if (code === "MY") {
      setCodeUpdatedValue("+60");
    } else if (code === "MV") {
      setCodeUpdatedValue("+960");
    } else if (code === "ML") {
      setCodeUpdatedValue("+223");
    } else if (code === "MT") {
      setCodeUpdatedValue("+356");
    } else if (code === "MX") {
      setCodeUpdatedValue("+52");
    } else if (code === "MD") {
      setCodeUpdatedValue("+373");
    } else if (code === "MC") {
      setCodeUpdatedValue("+377");
    } else if (code === "MN") {
      setCodeUpdatedValue("+976");
    } else if (code === "ME") {
      setCodeUpdatedValue("+382");
    } else if (code === "MA") {
      setCodeUpdatedValue("+212");
    } else if (code === "MM") {
      setCodeUpdatedValue("+95");
    } else if (code === "NA") {
      setCodeUpdatedValue("+264");
    } else if (code === "NP") {
      setCodeUpdatedValue("+977");
    } else if (code === "NL") {
      setCodeUpdatedValue("+31");
    } else if (code === "NZ") {
      setCodeUpdatedValue("+64");
    } else if (code === "NE") {
      setCodeUpdatedValue("+227");
    } else if (code === "NG") {
      setCodeUpdatedValue("+234");
    } else if (code === "NO") {
      setCodeUpdatedValue("+47");
    } else if (code === "OM") {
      setCodeUpdatedValue("+968");
    } else if (code === "PK") {
      setCodeUpdatedValue("+92");
    } else if (code === "PW") {
      setCodeUpdatedValue("+680");
    } else if (code === "PS") {
      setCodeUpdatedValue("+970");
    } else if (code === "PA") {
      setCodeUpdatedValue("+507");
    } else if (code === "PY") {
      setCodeUpdatedValue("+595");
    } else if (code === "PH") {
      setCodeUpdatedValue("+63");
    } else if (code === "PL") {
      setCodeUpdatedValue("+48");
    } else if (code === "PT") {
      setCodeUpdatedValue("+351");
    } else if (code === "QA") {
      setCodeUpdatedValue("+974");
    } else if (code === "RO") {
      setCodeUpdatedValue("+40");
    } else if (code === "RU") {
      setCodeUpdatedValue("+7");
    } else if (code === "WS") {
      setCodeUpdatedValue("+685");
    } else if (code === "SA") {
      setCodeUpdatedValue("+966");
    } else if (code === "SN") {
      setCodeUpdatedValue("+221");
    } else if (code === "RS") {
      setCodeUpdatedValue("+381");
    } else if (code === "SG") {
      setCodeUpdatedValue("+65");
    } else if (code === "SO") {
      setCodeUpdatedValue("+252");
    } else if (code === "ZA") {
      setCodeUpdatedValue("+27");
    } else if (code === "ES") {
      setCodeUpdatedValue("+34");
    } else if (code === "LK") {
      setCodeUpdatedValue("+94");
    } else if (code === "SD") {
      setCodeUpdatedValue("+249");
    } else if (code === "SE") {
      setCodeUpdatedValue("+46");
    } else if (code === "CH") {
      setCodeUpdatedValue("+41");
    } else if (code === "SY") {
      setCodeUpdatedValue("+963");
    } else if (code === "TW") {
      setCodeUpdatedValue("+886");
    } else if (code === "TJ") {
      setCodeUpdatedValue("+992");
    } else if (code === "TH") {
      setCodeUpdatedValue("+66");
    } else if (code === "TN") {
      setCodeUpdatedValue("+216");
    } else if (code === "TR") {
      setCodeUpdatedValue("+90");
    } else if (code === "TM") {
      setCodeUpdatedValue("+993");
    } else if (code === "UG") {
      setCodeUpdatedValue("+256");
    } else if (code === "UA") {
      setCodeUpdatedValue("+380");
    } else if (code === "AE") {
      setCodeUpdatedValue("+971");
    } else if (code === "GB") {
      setCodeUpdatedValue("+44");
    } else if (code === "US") {
      setCodeUpdatedValue("+1");
    } else if (code === "UZ") {
      setCodeUpdatedValue("+998");
    } else if (code === "VN") {
      setCodeUpdatedValue("+84");
    } else if (code === "YE") {
      setCodeUpdatedValue("+967");
    } else if (code === "ZM") {
      setCodeUpdatedValue("+260");
    } else if (code === "ZW") {
      setCodeUpdatedValue("+263");
    } 
   else if (code === "CZ") {
    setCodeUpdatedValue("+420");
  }
  else if (code === "DJ") {
    setCodeUpdatedValue("+253");
  }else if (code === "DO") {
    setCodeUpdatedValue("+1");
  }else if (code === "ZW") {
    setCodeUpdatedValue("+263");
  }else if (code === "SV") {
    setCodeUpdatedValue("+503");
  }else if (code === "GQ") {
    setCodeUpdatedValue("+240");
  }else if (code === "FK") {
    setCodeUpdatedValue("+500");
  }else if (code === "GU") {
    setCodeUpdatedValue("+1");
  }else if (code === "GT") {
    setCodeUpdatedValue("+502");
  }else if (code === "GG") {
    setCodeUpdatedValue("+44");
  }
  else if (code === "GN") {
    setCodeUpdatedValue("+224");
  }
  else if (code === "GW") {
    setCodeUpdatedValue("+245");
  }
  else if (code === "IM") {
    setCodeUpdatedValue("+44");
  }
  else if (code === "KI") {
    setCodeUpdatedValue("+686");
  }
  else if (code === "KR") {
    setCodeUpdatedValue("+82");
  }
  else if (code === "LS") {
    setCodeUpdatedValue("+266");
  }
  else if (code === "LY") {
    setCodeUpdatedValue("+218");
  }
  else if (code === "LI") {
    setCodeUpdatedValue("+423");
  }
  else if (code === "LT") {
    setCodeUpdatedValue("+370");
  }
  else if (code === "MK") {
    setCodeUpdatedValue("+389");
  }
  else if (code === "MG") {
    setCodeUpdatedValue("+261");
  }
  else if (code === "MH") {
    setCodeUpdatedValue("+692");
  }
  else if (code === "MQ") {
    setCodeUpdatedValue("+596");
  }
  else if (code === "MU") {
    setCodeUpdatedValue("+230");
  }
  else if (code === "FM") {
    setCodeUpdatedValue("+691");
  }
  else if (code === "MS") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "MZ") {
    setCodeUpdatedValue("+258");
  }
  else if (code === "NR") {
    setCodeUpdatedValue("+674");
  }
  else if (code === "NI") {
    setCodeUpdatedValue("+505");
  }
  else if (code === "NU") {
    setCodeUpdatedValue("+683");
  }
  else if (code === "MP") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "PS") {
    setCodeUpdatedValue("+970");
  }
  else if (code === "PG") {
    setCodeUpdatedValue("+675");
  }
  else if (code === "PE") {
    setCodeUpdatedValue("+51");
  }
  else if (code === "PN") {
    setCodeUpdatedValue("+64");
  }
  else if (code === "PR") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "RW") {
    setCodeUpdatedValue("+250");
  }
  else if (code === "LC") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "SM") {
    setCodeUpdatedValue("+378");
  }
  else if (code === "ST") {
    setCodeUpdatedValue("+239");
  }
  else if (code === "SC") {
    setCodeUpdatedValue("+248");
  }
  else if (code === "SL") {
    setCodeUpdatedValue("+232");
  }
  else if (code === "SX") {
    setCodeUpdatedValue("+721");
  }
  else if (code === "SK") {
    setCodeUpdatedValue("+421");
  }
  else if (code === "SI") {
    setCodeUpdatedValue("+386");
  }
  else if (code === "SB") {
    setCodeUpdatedValue("+677");
  }
  else if (code === "SS") {
    setCodeUpdatedValue("+211");
  }
  else if (code === "SR") {
    setCodeUpdatedValue("+597");
  }
  else if (code === "SZ") {
    setCodeUpdatedValue("+268");
  }
  else if (code === "TZ") {
    setCodeUpdatedValue("+255");
  }
  else if (code === "TG") {
    setCodeUpdatedValue("+228");
  }
  else if (code === "TK") {
    setCodeUpdatedValue("+690");
  }
  else if (code === "TO") {
    setCodeUpdatedValue("+676");
  }
  else if (code === "TT") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "TC") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "TV") {
    setCodeUpdatedValue("+688");
  }
  else if (code === "UY") {
    setCodeUpdatedValue("+598");
  }
  else if (code === "VU") {
    setCodeUpdatedValue("+678");
  }
  else if (code === "VE") {
    setCodeUpdatedValue("+58");
  }
  else if (code === "VG") {
    setCodeUpdatedValue("+1");
  }
  else if (code === "YE") {
    setCodeUpdatedValue("+967");
  }

    else {
      setCodeUpdatedValue(process.env.REACT_APP_COUNTRY_CODE);
    }
  };

  // const [docType, setDocType] = useState("P");

  const setDocTypeText = (e) => {
    setDocType(e.target.value);
  };

  const { t, i18n, ready } = useTranslation();

  const [codeNewValue, setNewCodeValue] = useState();

  useEffect(() => {}, [codeNewValue]);

  const [activeClass0 , setActiveClass0] = useState("Mr");
  const [activeClass1 , setActiveClass1] = useState("Mr");
  const [activeClass2 , setActiveClass2] = useState('Mr');
  const [activeClass3 , setActiveClass3] = useState('Mr');
  const [activeClass4 , setActiveClass4] = useState('Mr');
  const [activeClass5 , setActiveClass5] = useState('Mr');
  const [activeClass6 , setActiveClass6] = useState('Mr');
  const [activeClass7 , setActiveClass7] = useState('Mr');
  const [activeClass8 , setActiveClass8] = useState('Mr');

  
  useEffect(() => {
    // PAx 1
    if(totalPass?.PassengerDetails[0]?.type === "ADT"){
    if(totalPass?.PassengerDetails[0]?.gender === "male"){
      setActiveClass0('Mr');
    }
    if(totalPass?.PassengerDetails[0].gender === "female"){
      setActiveClass0('Mrs');
    }
  }
  if(totalPass?.PassengerDetails[0]?.type === "C09" || totalPass?.PassengerDetails[0]?.type === "INF"){
    if(totalPass?.PassengerDetails[0]?.gender === "male"){
      setActiveClass0('Mr');
    }
    if(totalPass?.PassengerDetails[0].gender === "female"){
      setActiveClass0('Miss');
    }
  }
    // PAX 2
    if(totalPass?.PassengerDetails[1]?.type === "ADT"){
      if(totalPass?.PassengerDetails[1]?.gender === "male"){
        setActiveClass1('Mr');
      }
      if(totalPass?.PassengerDetails[1].gender === "female"){
        setActiveClass1('Mrs');
      }
    }
    if(totalPass?.PassengerDetails[1]?.type === "C09" || totalPass?.PassengerDetails[1]?.type === "INF"){
      if(totalPass?.PassengerDetails[1]?.gender === "male"){
        setActiveClass1('Mr');
      }
      if(totalPass?.PassengerDetails[1].gender === "female"){
        setActiveClass1('Miss');
      }
  }
    // PAX 3
    if(totalPass?.PassengerDetails[2]?.type === "ADT"){
      if(totalPass?.PassengerDetails[2]?.gender === "male"){
        setActiveClass2('Mr');
      }
      if(totalPass?.PassengerDetails[2].gender === "female"){
        setActiveClass2('Mrs');
      }
    }
    if(totalPass?.PassengerDetails[2]?.type === "C09" || totalPass?.PassengerDetails[2]?.type === "INF"){
      if(totalPass?.PassengerDetails[2]?.gender === "male"){
        setActiveClass2('Mr');
      }
      if(totalPass?.PassengerDetails[2].gender === "female"){
        setActiveClass2('Miss');
      }
  }
  // PAX 4
  if(totalPass?.PassengerDetails[3]?.type === "ADT"){
    if(totalPass?.PassengerDetails[3]?.gender === "male"){
      setActiveClass3('Mr');
    }
    if(totalPass?.PassengerDetails[3].gender === "female"){
      setActiveClass3('Mrs');
    }
  }
  if(totalPass?.PassengerDetails[3]?.type === "C09" || totalPass?.PassengerDetails[3]?.type === "INF"){
    if(totalPass?.PassengerDetails[3]?.gender === "male"){
      setActiveClass3('Mr');
    }
    if(totalPass?.PassengerDetails[3].gender === "female"){
      setActiveClass3('Miss');
    }
}
// PAX 4
if(totalPass?.PassengerDetails[4]?.type === "ADT"){
  if(totalPass?.PassengerDetails[4]?.gender === "male"){
    setActiveClass4('Mr');
  }
  if(totalPass?.PassengerDetails[4].gender === "female"){
    setActiveClass4('Mrs');
  }
}
if(totalPass?.PassengerDetails[4]?.type === "C09" || totalPass?.PassengerDetails[4]?.type === "INF"){
  if(totalPass?.PassengerDetails[4]?.gender === "male"){
    setActiveClass4('Mr');
  }
  if(totalPass?.PassengerDetails[4].gender === "female"){
    setActiveClass4('Miss');
  }
}
// PAX 5
if(totalPass?.PassengerDetails[5]?.type === "ADT"){
  if(totalPass?.PassengerDetails[5]?.gender === "male"){
    setActiveClass5('Mr');
  }
  if(totalPass?.PassengerDetails[5].gender === "female"){
    setActiveClass5('Mrs');
  }
}
if(totalPass?.PassengerDetails[5]?.type === "C09" || totalPass?.PassengerDetails[5]?.type === "INF"){
  if(totalPass?.PassengerDetails[5]?.gender === "male"){
    setActiveClass5('Mr');
  }
  if(totalPass?.PassengerDetails[5].gender === "female"){
    setActiveClass5('Miss');
  }
}
// PAX 3
if(totalPass?.PassengerDetails[6]?.type === "ADT"){
  if(totalPass?.PassengerDetails[6]?.gender === "male"){
    setActiveClass6('Mr');
  }
  if(totalPass?.PassengerDetails[6].gender === "female"){
    setActiveClass6('Mrs');
  }
}
if(totalPass?.PassengerDetails[6]?.type === "C09" || totalPass?.PassengerDetails[6]?.type === "INF"){
  if(totalPass?.PassengerDetails[6]?.gender === "male"){
    setActiveClass6('Mr');
  }
  if(totalPass?.PassengerDetails[6].gender === "female"){
    setActiveClass6('Miss');
  }
}

// PAX 7
if(totalPass?.PassengerDetails[7]?.type === "ADT"){
  if(totalPass?.PassengerDetails[7]?.gender === "male"){
    setActiveClass7('Mr');
  }
  if(totalPass?.PassengerDetails[7].gender === "female"){
    setActiveClass7('Mrs');
  }
}
if(totalPass?.PassengerDetails[7]?.type === "C09" || totalPass?.PassengerDetails[7]?.type === "INF"){
  if(totalPass?.PassengerDetails[7]?.gender === "male"){
    setActiveClass7('Mr');
  }
  if(totalPass?.PassengerDetails[7].gender === "female"){
    setActiveClass7('Miss');
  }
}
// PAX 3
if(totalPass?.PassengerDetails[8]?.type === "ADT"){
  if(totalPass?.PassengerDetails[8]?.gender === "male"){
    setActiveClass8('Mr');
  }
  if(totalPass?.PassengerDetails[8].gender === "female"){
    setActiveClass8('Mrs');
  }
}
if(totalPass?.PassengerDetails[8]?.type === "C09" || totalPass?.PassengerDetails[8]?.type === "INF"){
  if(totalPass?.PassengerDetails[8]?.gender === "male"){
    setActiveClass8('Mr');
  }
  if(totalPass?.PassengerDetails[8].gender === "female"){
    setActiveClass8('Miss');
  }
}

  }, [totalPass]);
  

  const activeFunction = (e, index) => {
    // setActiveClass0(e);
    if(index === 0){
      setActiveClass0(e);
    }
    if(index === 1){
      setActiveClass1(e);
    }
    if(index === 2){
      setActiveClass2(e);
    }
    if(index === 3){
      setActiveClass3(e);
    }
    if(index === 4){
      setActiveClass4(e);
    }
    if(index === 5){
      setActiveClass5(e);
    }
    if(index === 6){
      setActiveClass6(e);
    }
    if(index === 7){
      setActiveClass7(e);
    }
    if(index === 8){
      setActiveClass8(e);
    }
  }

    

  // console.log(diffMonths);


  return (
    <div>
     
      {/* <div className="row dls-none px-4">
        <div className="col-12 border-bottom"></div>
      </div> */}

      <div className="row  mt-4 dps-none">
        <div className="col-sm-5 col-md-5 col-lg-5">
          <h4>{t("Passenger Details")}</h4>
        </div>

        <div
          className={
            locale === "ar"
              ? "col-sm-7 col-md-7 col-lg-7 text-right-arabic"
              : "col-sm-7 col-md-7 col-lg-7 text-right"
          }
        >
          <p className="font-12">{t("English Statement")}</p>
        </div>
      </div>

      <div className="row px-3 dls-none">
        <div className="col-sm-12 mt-4">
          <h2 className="font-16 font-bold">{t("Enter Traveler Details")} </h2>
        </div>
      </div>

      <form onSubmit={handleSubmit} id="my_form">
        {tmp.map((i, index) => {
          return (
            <div key={index}>
              <div className="row dls-none px-3">
                <div className="col-12 col-md-8 col-lg-8 mt-3">
                  {totalPass?.PassengerDetails?.map((data, ind) => {
                    return (
                      <div key={ind}>
                        {ind === index && (
                          <h2 className="mb-3 passenger-text font-bold text-green" id={data.type}>
                            {data.type === "ADT" && t("Adult")}{" "}
                            {data.type === "ADT" &&
                              adultArr.map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {i === ind && i + 1}
                                  </span>
                                );
                              })}
                            {data.type === "CNN" && t("Child")}{" "}
                            {data.type === "CNN" &&
                              childArr.slice(0, 1).map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {String(
                                      `${index + 1 - pricingInfo.adultQty}`
                                    )}
                                  </span>
                                );
                              })}
                            {data.type === "C09" && t("Child")}{" "}
                            {data.type === "C09" &&
                              childArr.slice(0, 1).map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {String(
                                      `${index + 1 - pricingInfo.adultQty}`
                                    )}
                                  </span>
                                );
                              })}
                            {data.type === "INF" && t("Infant")}{" "}
                            {data.type === "INF" &&
                              infantArr.slice(0, 1).map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {String(
                                      `${
                                        index +
                                        1 -
                                        (pricingInfo.adultQty +
                                          pricingInfo.childQty)
                                      }`
                                    )}
                                  </span>
                                );
                              })}
                          </h2>
                        )}
                      </div>
                    );
                  })}

                  {/* <div className="flex">
                    <input
                      type="radio"
                      value={"P"}
                      onChange={(e) => {
                        setDocTypeText(e);
                        setpassengerData([
                          ...passengerData.slice(0, index),
                          {
                            ...passengerData[index],
                            docType: e.target.value,
                          },
                          ...passengerData.slice(index + 1),
                        ]);
                      }}
                      name={`${index}_doc`}
                      id={`${index}_Pass`}
                      checked={isMobile ? "checked" : ""}
                    />
                    <label htmlFor="radio1" className="label">
                      {t("Passport")}
                    </label>

                    <input
                      type="radio"
                      name={`${index}_doc`}
                      value={"I"}
                      onChange={(e) => {
                        setDocTypeText(e);
                        setpassengerData([
                          ...passengerData.slice(0, index),
                          {
                            ...passengerData[index],
                            docType: e.target.value,
                          },
                          ...passengerData.slice(index + 1),
                        ]);
                      }}
                      id={`${index}_ID`}
                      className="radio "
                    />
                    <label htmlFor="radio2" className="label">
                      {t("National Identity Card")}
                    </label>
                  </div> */}
                </div>
              </div>

              <div className="row mt-4 ">
                <div className="col-sm-12 col-md-12 col-lg-12 mob-form-border form-border">
                  {totalPass?.PassengerDetails?.map((data, ind) => {
                    return (
                      <div key={ind + 1}>
                        {ind === index && (
                          <h2 className="mb-3 passenger-text font-bold text-green dps-none" id={data.type}>
                            {data.type === "ADT" && t("Adult")}{" "}
                            {data.type === "ADT" &&
                              adultArr.map((data, i) => {
                                return (
                                  <span className="text-green" key={i + 1}>
                                    {i === ind && i + 1}
                                  </span>
                                );
                              })}
                            {data.type === "CNN" && t("Child")}{" "}
                            {data.type === "CNN" &&
                              childArr.slice(0, 1).map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {String(
                                      `${index + 1 - pricingInfo.adultQty}`
                                    )}
                                  </span>
                                );
                              })}
                            {data.type === "C09" && t("Child")}{" "}
                            {data.type === "C09" &&
                              childArr.slice(0, 1).map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {String(
                                      `${index + 1 - pricingInfo.adultQty}`
                                    )}
                                  </span>
                                );
                              })}
                            {data.type === "INF" && t("Infant")}{" "}
                            {data.type === "INF" &&
                              infantArr.slice(0, 1).map((data, i) => {
                                return (
                                  <span className="text-green" key={i}>
                                    {String(
                                      `${
                                        index +
                                        1 -
                                        (pricingInfo.adultQty +
                                          pricingInfo.childQty)
                                      }`
                                    )}
                                  </span>
                                );
                              })}
                          </h2>
                        )}
                      </div>
                    );
                  })}

{/* Name fields */}

{isMobile ?
<div className="row ">

{totalPass?.PassengerDetails?.map((data, ind) => {
                    return (
                      <div key={ind + 1}>
                        {ind === index && (
                          <h2 className="mb-3 passenger-text font-bold text-green" id={data.type}>
                            {data.type === "ADT" ?

                            <>
                            <div className="col-sm-12 button-row " id={`gender_${index}`}>
<p
className={
  index === 0 && activeClass0 === "Mr" ? "gender-button-active" :
  index === 1 && activeClass1 === "Mr"  ? "gender-button-active" :
  index === 2 && activeClass2 === "Mr"  ? "gender-button-active" :
  index === 3 && activeClass3 === "Mr"  ? "gender-button-active" :
  index === 4 && activeClass4 === "Mr"  ? "gender-button-active" :
  index === 5 && activeClass5 === "Mr"  ? "gender-button-active" :
  index === 6 && activeClass6 === "Mr"  ? "gender-button-active" : 
  index === 7 && activeClass7 === "Mr" ? "gender-button-active" :
  index === 8 && activeClass8 === "Mr" ? "gender-button-active"
   : "gender-button"

}
 onClick={(e) => {
  setpassengerData([
    ...passengerData.slice(0, index),
    {
      ...passengerData[index],
      gender: "Male",
    },
    ...passengerData.slice(index + 1),
  ]);
  activeFunction("Mr", index);
}}
>{t('Mr')}</p>
<p
className={
  index === 0 && activeClass0 === "Mrs" ? "gender-button-active" :
  index === 1 && activeClass1 === "Mrs"  ? "gender-button-active" :
  index === 2 && activeClass2 === "Mrs"  ? "gender-button-active" :
  index === 3 && activeClass3 === "Mrs"  ? "gender-button-active" :
  index === 4 && activeClass4 === "Mrs"  ? "gender-button-active" :
  index === 5 && activeClass5 === "Mrs"  ? "gender-button-active" :
  index === 6 && activeClass6 === "Mrs"  ? "gender-button-active" : 
  index === 7 && activeClass7 === "Mrs" ? "gender-button-active" :
  index === 8 && activeClass8 === "Mrs" ? "gender-button-active"
   : "gender-button"

}
 onClick={(e) => {
  setpassengerData([
    ...passengerData.slice(0, index),
    {
      ...passengerData[index],
      gender: "female",
    },
    ...passengerData.slice(index + 1),
  ]);
  activeFunction("Mrs", index);
}}
>{t('Mrs')}</p>
<p
className={
  index === 0 && activeClass0 === "Miss" ? "gender-button-active" :
  index === 1 && activeClass1 === "Miss"  ? "gender-button-active" :
  index === 2 && activeClass2 === "Miss"  ? "gender-button-active" :
  index === 3 && activeClass3 === "Miss"  ? "gender-button-active" :
  index === 4 && activeClass4 === "Miss"  ? "gender-button-active" :
  index === 5 && activeClass5 === "Miss"  ? "gender-button-active" :
  index === 6 && activeClass6 === "Miss"  ? "gender-button-active" : 
  index === 7 && activeClass7 === "Miss" ? "gender-button-active" :
  index === 8 && activeClass8 === "Miss" ? "gender-button-active"
   : "gender-button"

}
 onClick={(e) => {
  setpassengerData([
    ...passengerData.slice(0, index),
    {
      ...passengerData[index],
      gender: "female",
    },
    ...passengerData.slice(index + 1),
  ]);
  activeFunction("Miss", index);
}}
>{t('Miss')}</p>
</div>
                            </>

                            :
                            <div className="col-sm-12 button-row " id={`gender_${index}`}>
<p
className={
  index === 0 && activeClass0 === "Mr" ? "gender-button-active-ms" :
  index === 1 && activeClass1 === "Mr"  ? "gender-button-active-ms" :
  index === 2 && activeClass2 === "Mr"  ? "gender-button-active-ms" :
  index === 3 && activeClass3 === "Mr"  ? "gender-button-active-ms" :
  index === 4 && activeClass4 === "Mr"  ? "gender-button-active-ms" :
  index === 5 && activeClass5 === "Mr"  ? "gender-button-active-ms" :
  index === 6 && activeClass6 === "Mr"  ? "gender-button-active-ms" : 
  index === 7 && activeClass7 === "Mr" ? "gender-button-active-ms" :
  index === 8 && activeClass8 === "Mr" ? "gender-button-active-ms"
   : "gender-button-ms"

}
 onClick={(e) => {
  setpassengerData([
    ...passengerData.slice(0, index),
    {
      ...passengerData[index],
      gender: "Male",
    },
    ...passengerData.slice(index + 1),
  ]);
  activeFunction("Mr", index);
}}
>{t('Mstr')}</p>
<p
className={
  index === 0 && activeClass0 === "Miss" ? "gender-button-active-ms" :
  index === 1 && activeClass1 === "Miss"  ? "gender-button-active-ms" :
  index === 2 && activeClass2 === "Miss"  ? "gender-button-active-ms" :
  index === 3 && activeClass3 === "Miss"  ? "gender-button-active-ms" :
  index === 4 && activeClass4 === "Miss"  ? "gender-button-active-ms" :
  index === 5 && activeClass5 === "Miss"  ? "gender-button-active-ms" :
  index === 6 && activeClass6 === "Miss"  ? "gender-button-active-ms" : 
  index === 7 && activeClass7 === "Miss" ? "gender-button-active-ms" :
  index === 8 && activeClass8 === "Miss" ? "gender-button-active-ms"
   : "gender-button-ms"

}
 onClick={(e) => {
  setpassengerData([
    ...passengerData.slice(0, index),
    {
      ...passengerData[index],
      gender: "female",
    },
    ...passengerData.slice(index + 1),
  ]);
  activeFunction("Miss", index);
}}
>{t('Miss')}</p>
</div>
}
                          
                          </h2>
                        )}
                      </div>
                    );
                  })}
</div>
:
null
}

                  <div className="row mt-5 ">
                    <div className="col-sm-4 mob-top-m-none">
                      <label className="label">{t("PAX First Name")} *</label>
                      <br />

                      <input
                        type="text"
                        name={`fName${index}`}
                        id={`fName${index}`}
                        aria-hidden="true"
                        placeholder={t("PAX First Name")}
                        pattern="^[A-Za-z]*"
                        title="Username should be only in english"
                        required="true"
                        // onInvalid={e => e.target.setCustomValidity('Your custom message')} 
                        // onBlur={(e) => handleFirstNameError(e)}
                        onBlur={(e, id, ind) => onBlurFunction(e,`fName${index}`, `${index}`) }
                        // onKeyUp={(e, id, ind) => onBlurFunction(e,`fName${index}`, `${index}`) }
                        defaultValue={
                          totalPass
                            ? totalPass?.PassengerDetails[index]?.givenName
                            : i.firstName
                        }
                        // onKeyDown={(e) => checkEng(e)}
                        onKeyUp={(e) => handleKeyDown(e)}
                        onChange={(e) => {
                          // onFirstNameChange(e);
                          handleKeyDown(e)
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              firstName: e.target.value.replace(/[0-9]/g, ""),
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        className="text-input mt-2"
                      />
                      {
                        `fName${index}` == 'fName0' ? 
                        <span className="fill-error">{fName0}</span>
                        :
                        `fName${index}` == 'fName1' ? 
                        <span className="fill-error">{fName1}</span>
                        :
                        `fName${index}` == 'fName2' ? 
                        <span className="fill-error">{fName2}</span>
                        :
                        `fName${index}` == 'fName3' ? 
                        <span className="fill-error">{fName3}</span>
                        :
                        `fName${index}` == 'fName4' ? 
                        <span className="fill-error">{fName4}</span>
                        :
                        `fName${index}` == 'fName5' ? 
                        <span className="fill-error">{fName5}</span>
                        :
                        `fName${index}` == 'fName6' ? 
                        <span className="fill-error">{fName6}</span>
                        :
                        `fName${index}` == 'fName7' ? 
                        <span className="fill-error">{fName7}</span>
                        :
                        `fName${index}` == 'fName8' ? 
                        <span className="fill-error">{fName8}</span>
                        :
                        null
                      }

{!isMobile ?
    <span class="error" aria-live="polite">t('Please fill out this field'))</span>
    :
    null
  }
                    </div>

                    <div className="col-sm-4 mob-mt-1">
                      <label className="label">{t("PAX Middle Name")}</label>
                      <br />

                      <input
                        type="text"
                        placeholder={t("PAX Middle Name")}
                        name={`mName${index}`}
                        id={`mName${index}`}
                        aria-hidden="true"
                        defaultValue={
                          totalPass
                            ? totalPass?.PassengerDetails[index]?.middleName
                            : i.middleName
                        }
                        onKeyUp={(e) => handleKeyDown(e)}
                        onChange={(e) => {
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              middleName: e.target.value,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        className="text-input mt-2"
                      />
                    </div>

                    <div className="col-sm-4 mob-mt-2">
                      <label className="label">{t("PAX Last Name")} *</label>
                      <br />

                      <input
                        type="text"
                        placeholder={t("PAX Last Name")}
                        name={`lName${index}`}
                        id={`lName${index}`}
                        onKeyUp={(e) => handleKeyDown(e)}
                        onBlur={(e, id, ind) => onBlurFunction(e,`lName${index}`, `${index}`) }
                        // onKeyUp={(e, id, ind) => onBlurFunction(e,`lName${index}`, `${index}`) }
                        aria-hidden="true"
                        required="true"
                        pattern="^[A-Za-z]*"
                        title="Username should be only in english"
                        // aria-required="true"
                        defaultValue={
                          totalPass
                            ? totalPass?.PassengerDetails[index]?.surName
                            : i.lastName
                        }
                        // onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => {
                          // lastNameChange(e);\
                          handleKeyDown(e)
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              lastName: e.target.value.replace(/[0-9]/g, ""),
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        className="text-input mt-2 form-control"
                      />


{
                        `lName${index}` == 'lName0' ? 
                        <span className="fill-error">{lName0}</span>
                        :
                        `lName${index}` == 'lName1' ? 
                        <span className="fill-error">{lName1}</span>
                        :
                        `lName${index}` == 'lName2' ? 
                        <span className="fill-error">{lName2}</span>
                        :
                        `lName${index}` == 'lName3' ? 
                        <span className="fill-error">{lName3}</span>
                        :
                        `lName${index}` == 'lName4' ? 
                        <span className="fill-error">{lName4}</span>
                        :
                        `lName${index}` == 'lName5' ? 
                        <span className="fill-error">{lName5}</span>
                        :
                        `lName${index}` == 'lName6' ? 
                        <span className="fill-error">{lName6}</span>
                        :
                        `lName${index}` == 'lName7' ? 
                        <span className="fill-error">{lName7}</span>
                        :
                        `lName${index}` == 'lName8' ? 
                        <span className="fill-error">{lName8}</span>
                        :
                        null
                      }


                      {!isMobile ?
    <span className="error" aria-live="polite">t('Please fill out this field'))</span>
    :
    null
  }
  {/* {lastNameError && <p className="errorr">{lastNameError}</p>} */}
                    </div>
                  </div>

<div className={isMobile ? "row" : "row mt-4"}>
                    {!isMobile ?
                      <div className="col-sm-4 ">
                      <label className="label">{t("Gender")} *</label>
                      <select
                        className="text-input mt-2"
                        name={`gender${index}`}
                        id={`gender${index}`}
                        aria-hidden="true"
                        onBlur={(e, id, ind) => onBlurFunction(e,`gender${index}`, `${index}`) }
                        onKeyUp={(e, id, ind) => onBlurFunction(e,`gender${index}`, `${index}`) }
                        defaultValue={
                          totalPass
                            ? totalPass?.PassengerDetails[index]?.gender
                            : i.gender
                        }
                        onChange={(e) => {
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              gender: e.target.value,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        required
                      >
                        <option value="">
                          {t("Select")} {t("Gender")}
                        </option>
                        <option value="male">{t("Male")}</option>
                        <option value="female">{t("Female")}</option>
                      </select>

                      {
                        `gender${index}` == 'gender0' ? 
                        <span className="fill-error">{gender0}</span>
                        :
                        `gender${index}` == 'gender1' ? 
                        <span className="fill-error">{gender1}</span>
                        :
                        `gender${index}` == 'gender2' ? 
                        <span className="fill-error">{gender2}</span>
                        :
                        `gender${index}` == 'gender3' ? 
                        <span className="fill-error">{gender3}</span>
                        :
                        `gender${index}` == 'gender4' ? 
                        <span className="fill-error">{gender4}</span>
                        :
                        `gender${index}` == 'gender5' ? 
                        <span className="fill-error">{gender5}</span>
                        :
                        `gender${index}` == 'gender6' ? 
                        <span className="fill-error">{gender6}</span>
                        :
                        `gender${index}` == 'gender7' ? 
                        <span className="fill-error">{gender7}</span>
                        :
                        `gender${index}` == 'gender8' ? 
                        <span className="fill-error">{gender8}</span>
                        :
                        null
                      }


                      {!isMobile ?
    <span class="error" aria-live="polite">t('Please fill out this field'))</span>
    :
    null
  }
                    </div>
                    :
                    null
                    }

                    <div className="col-sm-4 mob-mt-2 dps-nonee">
                      <label className="label">{t("Date Of Birth")} *</label>
                      <div className="input-group mt-2">
                        <select
                          name={`dayOfBirth${index}`}
                          id={`dayOfBirth${index}`}
                          aria-hidden="true"
                          value={
                            totalPass &&
                            totalPass?.PassengerDetails[index]?.dateOfBirth !==
                              "0001-01-01T00:00:00"
                              ? totalPass?.PassengerDetails[
                                  index
                                ]?.dateOfBirth?.substring(8, 10)
                              : i.dayDOB
                          }
                          onBlur={(e, id, ind) => onBlurFunction(e,`dayOfBirth${index}`, `${index}`) }
                          // onKeyUp={(e, id, ind) => onBlurFunction(e,`dayOfBirth${index}`, `${index}`) }
                          onChange={(e) => {
                            setpassengerData([
                              ...passengerData.slice(0, index),
                              {
                                ...passengerData[index],
                                dayDOB: e.target.value,
                              },
                              ...passengerData.slice(index + 1),
                            ]);
                          }}
                          className={
                            locale === "ar"
                              ? "date no-right-border-arabic select text-center email-input"
                              : "date no-right-border select text-center email-input"
                          }
                          required
                        >
                          <option value="">{t("DD")}</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>

        

                        {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Date</span>
    :
    null
  } */}

                        <select
                        style={{padding: "3px"}}
                          className={
                            locale === "ar"
                              ? "date no-right-border-arabic no-left-border-arabic select text-center email-input"
                              : "date no-right-border no-left-border select text-center email-input"
                          }
                          name={`monthOfBirth${index}`}
                          id={`monthOfBirth${index}`}
                          aria-hidden="true"
                          value={
                            totalPass &&
                            totalPass?.PassengerDetails[index]?.dateOfBirth !==
                              "0001-01-01T00:00:00"
                              ? totalPass?.PassengerDetails[
                                  index
                                ]?.dateOfBirth.substring(5, 7)
                              : i.monthDOB
                          }
                          onBlur={(e, id, ind) => onBlurFunction(e,`monthOfBirth${index}`, `${index}`) }
                          onChange={(e) => {
                            setpassengerData([
                              ...passengerData.slice(0, index),
                              {
                                ...passengerData[index],
                                monthDOB: e.target.value,
                              },
                              ...passengerData.slice(index + 1),
                            ]);
                          }}
                          required
                        >
                          <option value="">{t("MM")}</option>
                          <option value="01">{t("January")}</option>
                          {document.getElementById(`dayOfBirth${index}`) && document.getElementById(`dayOfBirth${index}`).value > 28
                            ?
                            null
                            :
                          <option value="02">{t("February")}</option>
                          }
                          <option value="03">{t("March")}</option>
                          <option value="04">{t("April")}</option>
                          <option value="05">{t("May")}</option>
                          <option value="06">{t("June")}</option>
                          <option value="07">{t("July")}</option>
                          <option value="08">{t("August")}</option>
                          <option value="09">{t("September")}</option>
                          <option value="10">{t("October")}</option>
                          <option value="11">{t("November")}</option>
                          <option value="12">{t("December")}</option>
                        </select>

                        {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Month</span>
    :
    null
  } */}

                        {totalPass?.PassengerDetails?.map((data, ind) => {
                          return (
                            ind === index &&
                            data.type === "ADT" && (
                              <>
                              <select
                                name={`yearOfBirth${index}`}
                                id={`yearOfBirth${index}`}
                                aria-hidden="true"
                                defaultValue={
                                  totalPass &&
                                  totalPass?.PassengerDetails[index]
                                    ?.dateOfBirth !== "0001-01-01T00:00:00"
                                    ? totalPass?.PassengerDetails[
                                        index
                                      ]?.dateOfBirth?.substring(0, 4)
                                    : i.yearDOB
                                }
                                onBlur={(e, id, ind) => onBlurFunction(e,`yearOfBirth${index}`, `${index}`) }
                                onChange={(e) => {
                                  setpassengerData([
                                    ...passengerData.slice(0, index),
                                    {
                                      ...passengerData[index],
                                      yearDOB: e.target.value,
                                    },
                                    ...passengerData.slice(index + 1),
                                  ]);
                                }}
                                placeholder="YYYY"
                                className={
                                  locale === "ar"
                                    ? "date select year-arabicc text-center left-simple-border-arabic email-input"
                                    : "date select yearr text-center left-simple-border email-input"
                                }
                                key={ind}
                                required
                              >
                                <option value="">{t("YYYY")}</option>
                                <option value="1940">1940</option>
                                <option value="1941">1941</option>
                                <option value="1942">1942</option>
                                <option value="1943">1943</option>
                                <option value="1944">1944</option>
                                <option value="1945">1945</option>
                                <option value="1946">1946</option>
                                <option value="1947">1947</option>
                                <option value="1948">1948</option>
                                <option value="1949">1949</option>
                                <option value="1950">1950</option>
                                <option value="1951">1951</option>
                                <option value="1952">1952</option>
                                <option value="1953">1953</option>
                                <option value="1954">1954</option>
                                <option value="1955">1955</option>
                                <option value="1956">1956</option>
                                <option value="1957">1957</option>
                                <option value="1958">1958</option>
                                <option value="1959">1959</option>
                                <option value="1960">1960</option>
                                <option value="1961">1961</option>
                                <option value="1962">1962</option>
                                <option value="1963">1963</option>
                                <option value="1964">1964</option>
                                <option value="1965">1965</option>
                                <option value="1966">1966</option>
                                <option value="1967">1967</option>
                                <option value="1968">1968</option>
                                <option value="1969">1969</option>
                                <option value="1970">1970</option>
                                <option value="1971">1971</option>
                                <option value="1972">1972</option>
                                <option value="1973">1973</option>
                                <option value="1974">1974</option>
                                <option value="1975">1975</option>
                                <option value="1976">1976</option>
                                <option value="1977">1977</option>
                                <option value="1978">1978</option>
                                <option value="1979">1979</option>
                                <option value="1980">1980</option>
                                <option value="1981">1981</option>
                                <option value="1982">1982</option>
                                <option value="1983">1983</option>
                                <option value="1984">1984</option>
                                <option value="1985">1985</option>
                                <option value="1986">1986</option>
                                <option value="1987">1987</option>
                                <option value="1988">1988</option>
                                <option value="1989">1989</option>
                                <option value="1990">1990</option>
                                <option value="1991">1991</option>
                                <option value="1992">1992</option>
                                <option value="1993">1993</option>
                                <option value="1994">1994</option>
                                <option value="1995">1995</option>
                                <option value="1996">1996</option>
                                <option value="1997">1997</option>
                                <option value="1998">1998</option>
                                <option value="1999">1999</option>
                                <option value="2000">2000</option>
                                <option value="2001">2001</option>
                                <option value="2002">2002</option>
                                <option value="2003">2003</option>
                                <option value="2004">2004</option>
                                <option value="2005">2005</option>
                                <option value="2006">2006</option>
                                <option value="2007">2007</option>
                                <option value="2008">2008</option>
                                <option value="2009">2009</option>
                                
                              </select>

                              {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Year</span>
    :
    null
  } */}
                              </>
                              
                              
                            )
                          );
                        })}

                        {totalPass?.PassengerDetails?.map((data, ind) => {
                          return (
                            ind === index &&
                            data.type === "CNN" && (
                             <>
                              <select
                                name={`yearOfBirth${index}`}
                                id={`yearOfBirth${index}`}
                                aria-hidden="true"
                                required
                                defaultValue={
                                  totalPass &&
                                  totalPass.PassengerDetails[index]
                                    .dateOfBirth !== "0001-01-01T00:00:00"
                                    ? totalPass?.PassengerDetails[
                                        index
                                      ]?.dateOfBirth?.substring(0, 4)
                                    : i.yearDOB
                                }
                                onChange={(e) => {
                                  setpassengerData([
                                    ...passengerData.slice(0, index),
                                    {
                                      ...passengerData[index],
                                      yearDOB: e.target.value,
                                    },
                                    ...passengerData.slice(index + 1),
                                  ]);
                                }}
                                placeholder="YYYY"
                                className={
                                  locale === "ar"
                                    ? "date select year-arabicc text-center left-simple-border-arabic email-input"
                                    : "date select yearr text-center left-simple-border email-input"
                                }
                                key={ind}
                              >
                                <option value="">{t("YYYY")}</option>
                                <option value="2010">2010</option>
                                <option value="2011">2011</option>
                                <option value="2012">2012</option>
                                <option value="2013">2013</option>
                                <option value="2014">2014</option>
                                <option value="2015">2015</option>
                                <option value="2016">2016</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                              </select>
                              {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Year</span>
    :
    null
  } */}
                              </>
                            )
                          );
                        })}

                        {totalPass?.PassengerDetails?.map((data, ind) => {
                          return (
                            ind === index &&
                            data.type === "C09" && (
                              <>
                              <select
                                name={`yearOfBirth${index}`}
                                id={`yearOfBirth${index}`}
                                aria-hidden="true"
                                required
                                defaultValue={
                                  totalPass &&
                                  totalPass.PassengerDetails[index]
                                    .dateOfBirth !== "0001-01-01T00:00:00"
                                    ? totalPass?.PassengerDetails[
                                        index
                                      ]?.dateOfBirth?.substring(0, 4)
                                    : i.yearDOB
                                }
                                onChange={(e) => {
                                  setpassengerData([
                                    ...passengerData.slice(0, index),
                                    {
                                      ...passengerData[index],
                                      yearDOB: e.target.value,
                                    },
                                    ...passengerData.slice(index + 1),
                                  ]);
                                }}
                                placeholder="YYYY"
                                className={
                                  locale === "ar"
                                    ? "date select year-arabicc text-center left-simple-border-arabic email-input"
                                    : "date select yearr text-center left-simple-border email-input"
                                }
                                key={ind}
                              >
                                <option value="">{t("YYYY")}</option>
                                <option value="2010">2010</option>
                                <option value="2011">2011</option>
                                <option value="2012">2012</option>
                                <option value="2013">2013</option>
                                <option value="2014">2014</option>
                                <option value="2015">2015</option>
                                <option value="2016">2016</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                              </select>
                              {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Year</span>
    :
    null
  } */}
                              </>
                            )
                          );
                        })}

                        {totalPass?.PassengerDetails?.map((data, ind) => {
                          return (
                            ind === index &&
                            data.type === "INF" && (
                              <>
                            
                              <select
                                name={`yearOfBirth${index}`}
                                id={`yearOfBirth${index}`}
                                aria-hidden="true"
                                required
                                defaultValue={
                                  totalPass &&
                                  totalPass.PassengerDetails[index]
                                    .dateOfBirth !== "0001-01-01T00:00:00"
                                    ? totalPass?.PassengerDetails[
                                        index
                                      ]?.dateOfBirth?.substring(0, 4)
                                    : i.yearDOB
                                }
                                onChange={(e) => {
                                  setpassengerData([
                                    ...passengerData.slice(0, index),
                                    {
                                      ...passengerData[index],
                                      yearDOB: e.target.value,
                                    },
                                    ...passengerData.slice(index + 1),
                                  ]);
                                }}
                                placeholder="YYYY"
                                className={
                                  locale === "ar"
                                    ? "date select year-arabicc text-center left-simple-border-arabic email-input"
                                    : "date select yearr text-center left-simple-border email-input"
                                }
                                key={ind}
                              >
                                <option value="">{t("YYYY")}</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                              </select>
                              {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Year</span>
    :
    null
  } */}
                              </>
                            )
                          );
                        })}
                      </div>
                      {/* {bdyError ? <p className="error">{bdyError}</p> : null} */}
                      {/* <p className="error-msg">t('Please fill out this field'))</p> */}

                   
                      {
                        `dayOfBirth${index}` == 'dayOfBirth0' ? 
                        <span className="fill-error">{dob0}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth1' ? 
                        <span className="fill-error">{dob1}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth2' ? 
                        <span className="fill-error">{dob2}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth3' ? 
                        <span className="fill-error">{dob3}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth4' ? 
                        <span className="fill-error">{dob4}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth5' ? 
                        <span className="fill-error">{dob5}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth6' ? 
                        <span className="fill-error">{dob6}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth7' ? 
                        <span className="fill-error">{dob7}</span>
                        :
                        `dayOfBirth${index}` == 'dayOfBirth8' ? 
                        <span className="fill-error">{dob8}</span>
                        :
                        null
                      }

                          
{
                        `monthOfBirth${index}` == 'monthOfBirth0' ? 
                        <span className="fill-error">{mob0}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth1' ? 
                        <span className="fill-error">{mob1}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth2' ? 
                        <span className="fill-error">{mob2}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth3' ? 
                        <span className="fill-error">{mob3}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth4' ? 
                        <span className="fill-error">{mob4}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth5' ? 
                        <span className="fill-error">{mob5}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth6' ? 
                        <span className="fill-error">{mob6}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth7' ? 
                        <span className="fill-error">{mob7}</span>
                        :
                        `monthOfBirth${index}` == 'monthOfBirth8' ? 
                        <span className="fill-error">{mob8}</span>
                        :
                        null
                      }

{
                        `yearOfBirth${index}` == 'yearOfBirth0' ? 
                        <span className="fill-error">{yob0}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth1' ? 
                        <span className="fill-error">{yob1}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth2' ? 
                        <span className="fill-error">{yob2}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth3' ? 
                        <span className="fill-error">{yob3}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth4' ? 
                        <span className="fill-error">{yob4}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth5' ? 
                        <span className="fill-error">{yob5}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth6' ? 
                        <span className="fill-error">{yob6}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth7' ? 
                        <span className="fill-error">{yob7}</span>
                        :
                        `yearOfBirth${index}` == 'yearOfBirth8' ? 
                        <span className="fill-error">{yob8}</span>
                        :
                        null
                      } 
                    </div>


                    <div className={isMobile ? "col-sm-4 mob-mt-2 relative-position" : "col-sm-4 mob-mt- relative-position"}>
                      {isMobile ? (
                        <label className="label">{t("Nationality")} *</label>
                      ) : (
                        <label className="label">
                          {t("Country Of Residence")} *
                        </label>
                      )}

                      <ReactFlagsSelect
                        id={`nationality_${index}`}
                        className="mt-2"
                        required
                        name={`nationality_${index}`}
                        placeholder={
                          isMobile
                            ? t("Nationality")
                            : t("Country Of Residence")
                        }
                        searchable
                        onSelect={(code) => {
                          callNationalCountry(index, code);
                          setSelectedNationalCountry(code);
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              countryResidence: code,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        selected={
                          totalPass &&
                          totalPass?.PassengerDetails[index]
                            ?.tblPassengerDocument?.nationalityCountry
                            ? totalPass?.PassengerDetails[index]
                                ?.tblPassengerDocument?.nationalityCountry
                            : index === 0
                            ? nationCountry0
                            : index === 1
                            ? nationCountry1
                            : index === 2
                            ? nationCountry2
                            : index === 3
                            ? nationCountry3
                            : index === 4
                            ? nationCountry4
                            : index === 5
                            ? nationCountry5
                            : index === 6
                            ? nationCountry6
                            : index === 7
                            ? nationCountry7
                            : index === 8
                            ? nationCountry8
                            : selectedNationalCountry
                        }
                      />
                    </div>
                    </div>
                  


                    <div className="row dls-none ">
                    <div className="col-sm-11 col-11 mob-mt-2">
                      <h2 className="font-16 font-bold">
                        {docType === "P"
                          ? t("Passport Details")
                          : t("National Identity Card")}
                      </h2>
                    </div>

                    {/* <div className="col-lg-1 col-sm-1 text-right col-1 mob-mt-1 vertically-align-center">
                      <img
                        src={MobArrowDownLight}
                        className="light"
                        alt="arrow-down-light"
                      />
                      <img
                        src={MobileArrowDown}
                        className="dark"
                        alt="arrow-down-dark"
                      />
                    </div> */}
                  </div>

                  <div className="row mt-4 dps-none">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <label className="label">
                        {t("Type Of Travel Document")} *
                      </label>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 flex">
                      <input
                        type="radio"
                        defaultValue={"P"}
                        onChange={(e) => {
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              docType: e.target.value,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        name={`${index}_doc`}
                        id={`${index}_Pass`}
                        className="email"
                        defaultChecked
                      />
                      <label
                        htmlFor={`${index}_Pass`}
                        className="label email radioElem"
                        style={
                          locale === "ar"
                            ? { marginRight: "10px", marginTop: "1px" }
                            : { marginRight: "0px", marginTop: "1px" }
                        }
                      >
                        {t("Passport")}
                      </label>
                      <input
                        type="radio"
                        name={`${index}_doc`}
                        defaultValue={"I"}
                        onChange={(e) => {
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              docType: e.target.value,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        id={`${index}_ID`}
                        className={
                          locale === "ar"
                            ? "radio radio_margin_left_arabic email"
                            : "radio radio_margin_left email"
                        }
                      />
                      <label
                        htmlFor={`${index}_ID`}
                        className="label  email radioElem"
                        style={{ marginTop: "1px"}}
                      >
                        {t("National Identity Card")}
                      </label>
                    </div>
                  </div>

                  {isMobile &&

<div className="row mt-3 dls-none">

<div className="col-sm-12 ">
<select
                        className="text-input "
                        onChange={(e) => {
                          setDocTypeText(e);
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              docType: e.target.value,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        required
                        name={`${index}_doc`}
                        id={`${index}_doc`}
                        style={locale === "en" ? {
                          width: "100%",
                          // padding: "5px",
                          borderRadius: "5px",
                          height: "40px",
                          backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
                          backgroundPositionX: "98%",
                          backgroundRepeat:"no-repeat",
                          backgroundPositionY:"right",
                          backgroundPositionY:"center",
                        }
                      :
                      {
                        width: "100%",
                        // padding: "5px",
                        borderRadius: "5px",
                        height: "40px",
                        backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
                        backgroundPositionX: "2%",
                        backgroundRepeat:"no-repeat",
                        backgroundPositionY:"left",
                        backgroundPositionY:"center",
                      }
                      }
                      >
                        {/* <option value="">
                          {t("Select")} {t("Document")}
                        </option> */}
                        <option value="P">{t("Passport")}</option>
                        <option value="I">{t("National Identity Card")}</option>
                      </select>
 </div> 

</div>
                  }


<div className="row mt-4">   

<div className="col-sm-4 ">
                      <label className="label">
                        {passengerData && passengerData[index]?.docType === "I"
                          ? (docText = t("National Identity Card"))
                          : (docText = t("Passport Number"))}{" "}
                        *
                      </label>
                      <input
                        type="text"
                        placeholder={docText}
                        name={`${docText}${index}`}
                        id={`${docText}${index}`}
                        aria-hidden="true"
                        onBlur={(e, id, ind) => onBlurFunction(e,`${docText}${index}`, `${index}`) }
                        required
                        // pattern="/^[0-9a-zA-Z]+$/"
                        // pattern="[A-Za-z]{1,25}"
                        title="Must contain english letters or numeric values"
                        defaultValue={
                          totalPass
                            ? totalPass?.PassengerDetails[index]
                                ?.tblPassengerDocument?.number
                            : i.passportNo
                        }
                        
                        onChange={(e) => {
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              passportNo: e.target.value,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        className="text-input mt-2 email-input passport-input"
                      />
                    
{
                        `${docText}${index}` == 'Passport Number0' || 
                        `${docText}${index}` == 'National Identity Card0' ?
                        <span className="fill-error">{passport0}</span>
                        :
                        `${docText}${index}` == 'Passport Number1' || 
                        `${docText}${index}` == 'National Identity Card1' ?
                        <span className="fill-error">{passport1}</span>
                        :
                        `${docText}${index}` == 'Passport Number2' || 
                        `${docText}${index}` == 'National Identity Card2' ?
                        <span className="fill-error">{passport2}</span>
                        :
                        `${docText}${index}` == 'Passport Number3' || 
                        `${docText}${index}` == 'National Identity Card3' ?
                        <span className="fill-error">{passport3}</span>
                        :
                        `${docText}${index}` == 'Passport Number4' || 
                        `${docText}${index}` == 'National Identity Card4' ?
                        <span className="fill-error">{passport4}</span>
                        :
                        `${docText}${index}` == 'Passport Number5' || 
                        `${docText}${index}` == 'National Identity Card5' ?
                        <span className="fill-error">{passport5}</span>
                        :
                        `${docText}${index}` == 'Passport Number6' || 
                        `${docText}${index}` == 'National Identity Card6' ?
                        <span className="fill-error">{passport6}</span>
                        :
                        `${docText}${index}` == 'Passport Number7' || 
                        `${docText}${index}` == 'National Identity Card7' ?
                        <span className="fill-error">{passport7}</span>
                        :
                        `${docText}${index}` == 'Passport Number8' || 
                        `${docText}${index}` == 'National Identity Card8' ?
                        <span className="fill-error">{passport8}</span>
                        :
                        null
                      }


                                           {!isMobile ?
    <span class="error" aria-live="polite">t('Please fill out this field'))</span>
    :
    null
  }
                    </div>



                        <div className="col-sm-4 mob-mt-2 relative-position">
                      <label className="label">{t("Issuing Country")} *</label>
                      <ReactFlagsSelect
                        id={`IssueCountry_${index}`}
                        placeholder={t("Issuing Country")}
                        className="mt-2"
                        required
                        name={`IssueCountry_${index}`}
                        searchable
                        onSelect={(code) => {
                          callPassportIssue(index, code);
                          setSelectedPassportCountry(code);
                          setpassengerData([
                            ...passengerData.slice(0, index),
                            {
                              ...passengerData[index],
                              passportIssueCountry: code,
                            },
                            ...passengerData.slice(index + 1),
                          ]);
                        }}
                        selected={
                          totalPass &&
                          totalPass?.PassengerDetails[index]
                            ?.tblPassengerDocument?.issueCountry
                            ? totalPass?.PassengerDetails[index]
                                ?.tblPassengerDocument?.issueCountry
                            : // :  selectedPassportCountry}
                            index === 0
                            ? passportIssue0
                            : index === 1
                            ? passportIssue1
                            : index === 2
                            ? passportIssue2
                            : index === 3
                            ? passportIssue3
                            : index === 4
                            ? passportIssue4
                            : index === 5
                            ? passportIssue5
                            : index === 6
                            ? passportIssue6
                            : index === 7
                            ? passportIssue7
                            : index === 8
                            ? passportIssue8
                            : selectedPassportCountry
                        }
                      />
                    </div>

                    <div className="col-sm-4 mob-mt-2">
                      <label className="label">
                        {passengerData[index]?.docType === "I"
                          ? (docText = t("National Identity Card Expiry"))
                          : (docText = t("Passport Expiry"))}
                      </label>
                      <div className="input-group mt-2">
                        <select
                          name={`dayOfPassport${index}`}
                          id={`dayOfPassport${index}`}
                          aria-hidden="true"
                          required
                          defaultValue={
                            totalPass
                              ? totalPass?.PassengerDetails[
                                  index
                                ]?.tblPassengerDocument?.expirationDate?.substring(
                                  8,
                                  10
                                )
                              : i.passportExpiryDay
                          }
                          // ref={inputRef}
                          onBlur={(e, id, ind) => onBlurFunction(e,`dayOfPassport${index}`, `${index}`) }
                          onChange={(e) => {
                            setpassengerData([
                              ...passengerData.slice(0, index),
                              {
                                ...passengerData[index],
                                passportExpiryDay: e.target.value,
                              },
                              ...passengerData.slice(index + 1),
                            ]);
                          }}
                          className={
                            locale === "ar"
                              ? "date no-right-border-arabic select text-center email-input"
                              : "date no-right-border select text-center email-input"
                          }
                        >
                          <option value="">{t("DD")}</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                        {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Date</span>
    :
    null
  } */}
                        <select
                        style={{padding: "3px"}}
                          className={
                            locale === "ar"
                              ? "date no-right-border-arabic no-left-border-arabic select text-center email-input"
                              : "date no-right-border no-left-border select text-center email-input"
                          }
                          required
                          name={`monthOfPassport${index}`}
                          id={`monthOfPassport${index}`}
                          aria-hidden="true"
                          // ref={inputRef}
                          onBlur={(e, id, ind) => onBlurFunction(e,`monthOfPassport${index}`, `${index}`) }
                          defaultValue={
                            totalPass
                              ? totalPass.PassengerDetails[
                                  index
                                ]?.tblPassengerDocument?.expirationDate?.substring(
                                  5,
                                  7
                                )
                              : i.passportExpiryMonth
                          }
                          onChange={(e) => {
                            setpassengerData([
                              ...passengerData.slice(0, index),
                              {
                                ...passengerData[index],
                                passportExpiryMonth: e.target.value,
                              },
                              ...passengerData.slice(index + 1),
                            ]);
                          }}
                        >
                          <option value="">{t("MM")}</option>
                          <option value="01">{t("January")}</option>
                          {document.getElementById(`dayOfPassport${index}`) && document.getElementById(`dayOfPassport${index}`).value > 28
                            ?
                            null
                            :
                          <option value="02">{t("February")}</option>
                          }
                          <option value="03">{t("March")}</option>
                          <option value="04">{t("April")}</option>
                          <option value="05">{t("May")}</option>
                          <option value="06">{t("June")}</option>
                          <option value="07">{t("July")}</option>
                          <option value="08">{t("August")}</option>
                          <option value="09">{t("September")}</option>
                          <option value="10">{t("October")}</option>
                          <option value="11">{t("November")}</option>
                          <option value="12">{t("December")}</option>
                        </select>
                        {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Month</span>
    :
    null
  } */}

                        <select
                          required
                          name={`yearOfPassport${index}`}
                          id={`yearOfPassport${index}`}
                          aria-hidden="true"
                          // ref={inputRef}
                          onBlur={(e, id, ind) => onBlurFunction(e,`yearOfPassport${index}`, `${index}`) }
                          defaultValue={
                            totalPass
                              ? totalPass?.PassengerDetails[
                                  index
                                ]?.tblPassengerDocument?.expirationDate?.substring(
                                  0,
                                  4
                                )
                              : i.passportExpiryYear
                          }
                          onChange={(e) => {
                            setpassengerData([
                              ...passengerData.slice(0, index),
                              {
                                ...passengerData[index],
                                passportExpiryYear: e.target.value,
                              },
                              ...passengerData.slice(index + 1),
                            ]);
                            setHepstarCheckDisable(false);
                          }}
                          placeholder="YYYY"
                          className={
                            locale === "ar"
                              ? "left-simple-border-arabic date year-arabicc select text-centeremail-input"
                              : "left-simple-border date yearr select text-center email-input"
                          }
                        >
                          <option value="">{t("YYYY")}</option>
                          {diffMonth > 6 ?
                          <option value="2023">2023</option>
                          :
                          null
                          }
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                          <option value="2028">2028</option>
                          <option value="2029">2029</option>
                          <option value="2030">2030</option>
                          <option value="2031">2031</option>
                          <option value="2032">2032</option>
                          <option value="2033">2033</option>
                          <option value="2034">2034</option>
                          <option value="2035">2035</option>
                        </select>
                        {/* {!isMobile ?
    <span
     className="error-select" aria-live="polite"
    >Please Select Year</span>
    :
    null
  } */}
                      </div>
                      
                      {/* {passportExpError ? ( */}
                        {/* // <p className="error">{passportExpError}</p> */}
                      {/* // ) : null} */}
                      {
                        `dayOfPassport${index}` == 'dayOfPassport0' ? 
                        <span className="fill-error">{dop0}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport1' ? 
                        <span className="fill-error">{dop1}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport2' ? 
                        <span className="fill-error">{dop2}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport3' ? 
                        <span className="fill-error">{dop3}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport4' ? 
                        <span className="fill-error">{dop4}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport5' ? 
                        <span className="fill-error">{dop5}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport6' ? 
                        <span className="fill-error">{dop6}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport7' ? 
                        <span className="fill-error">{dop7}</span>
                        :
                        `dayOfPassport${index}` == 'dayOfPassport8' ? 
                        <span className="fill-error">{dop8}</span>
                        :
                        null
                      }

                          
{
                        `monthOfPassport${index}` == 'monthOfPassport0' ? 
                        <span className="fill-error">{mop0}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport1' ? 
                        <span className="fill-error">{mop1}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport2' ? 
                        <span className="fill-error">{mop2}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport3' ? 
                        <span className="fill-error">{mop3}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport4' ? 
                        <span className="fill-error">{mop4}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport5' ? 
                        <span className="fill-error">{mop5}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport6' ? 
                        <span className="fill-error">{mop6}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport7' ? 
                        <span className="fill-error">{mop7}</span>
                        :
                        `monthOfPassport${index}` == 'monthOfPassport8' ? 
                        <span className="fill-error">{mop8}</span>
                        :
                        null
                      }

{
                        `yearOfPassport${index}` == 'yearOfPassport0' ? 
                        <span className="fill-error">{yop0}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport1' ? 
                        <span className="fill-error">{yop1}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport2' ? 
                        <span className="fill-error">{yop2}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport3' ? 
                        <span className="fill-error">{yop3}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport4' ? 
                        <span className="fill-error">{yop4}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport5' ? 
                        <span className="fill-error">{yop5}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport6' ? 
                        <span className="fill-error">{yop6}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport7' ? 
                        <span className="fill-error">{yop7}</span>
                        :
                        `yearOfPassport${index}` == 'yearOfPassport8' ? 
                        <span className="fill-error">{yop8}</span>
                        :
                        null
                      }
                    </div>

                   
                    </div>

           
                </div>
              </div>
            </div>
          );
        })}


<button
          type="submit"
          className="btn"
          id="submit-btn"
          style={{ display: "none" }}
          // onClick={submitFunction}
        >
          Submit
        </button>
      </form>


      <div className={isMobile ? "row" : "row mob-mt-2 mt-4"}>
        <div className="col-sm-6 col-md-6 col-lg-6 mob-px-3">
          <h4 className="mob-white-heading ">{t("Contact Details")}</h4>
        </div>
        <div
          className={
            locale === "ar"
              ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic dps-none"
              : "col-sm-6 col-md-6 col-lg-6 text-right dps-none"
          }
        >
          <p className="font-12">
            {t("Agree Statement")}{" "}
            <span className="underlined-text font-12">
              <Link to={`/${locale}/privacy_policy`} target="_blank">
                {t("Privacy Policy")}
              </Link>
            </span>{" "}
            <span className="underlined-text font-12">
              <Link to={`/${locale}/terms_conditions`} target="_blank">
                {t("T&C")}
              </Link>
            </span>
          </p>
        </div>
      </div>

      <div className="row mob-top-m-none mt-4 ">
        <div className="col-sm-12 col-md-12 col-lg-12 form-border mob-form-border mob-top-m-none">
          <div className="row ">
            <div className="col-sm-6">
              <label className="label dps-none">
                <span>{t("PAX Email Label")} *</span>
              </label>
              <br className="dps-none" />

              <input
                type="email"
                id="email"
                placeholder={t("Your Email Address")}
                className="text-input mt-2 email-input email"
                defaultValue={totalPass ? totalPass?.BookingContact?.email : emailValue}
                onChange={onEmailChange}
                onBlur={(e, id, ind) => onBlurFunction(e, "email", "email")}
                // required
                autoComplete="off"
              />
              {emailError && <span className="errorr">{emailError}</span>}
            </div>

            <div className="col-sm-6 mob-mt-1 relative-position">
              <label className="label dps-none">
                {t("PAX Contact Number Label")} *
              </label>
              <br className="dps-none" />
              <div className="input-group mt-2">
                <ReactFlagsSelect
                  id="code"
                  placeholder={
                    totalPass && totalPass?.BookingContact?.countryCode !== null
                      ? totalPass?.BookingContact?.countryCode
                      : codeUpdatedValue
                  }
                  className="countryCode select"
                  selected={codeUpdatedValue}
                  onSelect={(e) => checkSelectedVal(e)}
                  showSelectedLabel={showSelectedLabel}
                  showSecondarySelectedLabel={showSecondarySelectedLabel}
                  showOptionLabel={showOptionLabel}
                  showSecondaryOptionLabel={showSecondaryOptionLabel}
                  customLabels={customLabels}
                  searchable={searchable}
                />

                {
                // emailError || emailValue === null ? (
                //   <input
                //     type="number"
                //     min={0}
                //     max={99999999999999}
                //     placeholder={t("Your Contact Number")}
                //     autoComplete="off"
                //     className={
                //       locale === "ar"
                //         ? "text-input left-simple-border-arabic number email-input"
                //         : "text-input left-simple-border number email-input"
                //     }
                //     defaultValue={
                //       totalPass ? totalPass.BookingContact.phone : numberValue
                //     }
                //     onChange={onNumberChange}
                //     onBlur={callContactAPI}
                //     disabled
                //     title="Please Enter valid email address first"
                //     style={{ backgroundColor: "white", cursor: "not-allowed" }}
                //   />
                // ) : 
                (

                  <input
                    type="number"
                    min={0}
                    max={99999999999999}
                    placeholder={t("Your Contact Number")}
                    id="number"
                    className={
                      locale === "ar"
                        ? "text-input left-simple-border-arabic number email-input"
                        : "text-input left-simple-border number email-input"
                    }
                    defaultValue={
                      totalPass ? totalPass?.BookingContact?.phone : numberValue
                    }
                    onChange={onNumberChange}
                    // onBlur={callContactAPI}
                    onBlur={(e, id , ind) => onBlurFunction(e, "number", "number")}
                  />
                )
                }
              </div>
              {/* {phoneError ? <p className="error">{phoneError}</p> : null} */}
              {<span className="errorr">{numberError}</span>}
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default ContactDetails;
