import "./css/bootstrap-grid.css";
import "./css/common.css";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import { BrowserRouter as Router, Routes, Route, Navigate, createBrowserRouter, useNavigate } from "react-router-dom";
import HomePage from "./Components/HomePage";
import GlobalContextProvider, { useGlobals } from "./contexts/globalContexts";
import SearchResultsPage from "./Components/SearchResultsPage";
import PassengerInformation from "./Components/PassengerInformation/PassengerInformation";
import PaymentDetails from "./Components/PaymentInformation/PaymentDetails";
import FlightConfirmation from "./Components/FlightConfirmation/FlightConfirmation";
import RefrencePrompt from "./Components/MyBookings/RefrencePropmt"
import CapturePayment from "./Components/CapturePayment";
import ErrorPage from "./Components/ErrorPage";
import ProcessBooking from "./Components/ProcessBooking";
import TermsConditions from './Components/StaticPages/TermsConditions';
import PrivacyPolicy from './Components/StaticPages/PrivacyPolicy';
import FaqPage from './Components/StaticPages/FaqPage';
import ContactUs from './Components/StaticPages/ContactUs';
import AboutUsPage from "./Components/StaticPages/AboutUsPage";
import DealsPage from "./Components/StaticPages/DealsPage";
import Confirmation from "./Components/MyBookings/Confirmation"
import DealsDetailPage from "./Components/StaticPages/DealsDetailPage";
import DealsPageTwo from "./Components/StaticPages/DealsPageTwo";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CancelAnyTime from "./Components/StaticPages/CancelAnyTime"
import { changeLanguage } from "i18next";




function App() {

  const {isOpenPage, lang, setLang , locale, setLocale} = useGlobals();
  const supportedLanguages = ["en", "ar"];

  const { t, i18n, ready } = useTranslation();

  // let localePath = window.location.pathname.split('/');

  // const changeLanguage = (locale) => {
  //   i18n.changeLanguage(locale);
  // };

  // useEffect(() => {
  // //  setTimeout(() => {
  //   // console.log(localePath);
  //   if(localePath[1] === "en"){
  //     setLocale('en');
  //     changeLanguage('en');
  //     document.documentElement.setAttribute("lang", 'en');
  //     console.log(i18n.language);
  //   }
  //   else{
  //     setLocale('ar');
  //     changeLanguage('ar');
  //     console.log('Ar')
  //     document.documentElement.setAttribute("lang", 'ar');
  //   }
  // //  }, 500);
  // }, []);

  return (
    // <GlobalContextProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/:local" element={<HomePage />}></Route>
          <Route path="/:lang/search_results/:holdSearchId" element={<SearchResultsPage />}></Route>
          <Route
            path="/:lang/passenger_information/:BookingRef"
            element={<PassengerInformation />}
          ></Route>
            <Route
            exact
            path="/:lang/passenger_information/:BookingRef/:search"
            element={<PassengerInformation />}
          ></Route>
          <Route exact path="/:lang/payment_details/:BookingRef" element={<PaymentDetails />}></Route>
          {/* <Route
          path="flight_confirmation/:BookingRef"
          element={<FlightConfirmation />}
          ></Route> */}
          <Route
          path="/:lang/flight_confirmation/:BookingRef"
          element={<FlightConfirmation />}
          ></Route>
          <Route path="/:locale/my_bookings" element={<RefrencePrompt />} ></Route>
          <Route path="/:locale/booking_details/:BookingRef" element={<Confirmation />} ></Route>
          <Route path="capturepayment/:locale" element={<CapturePayment />} ></Route>
          <Route path="/:locale/error_page/:BookingRef" element={<ErrorPage />} ></Route>
          <Route path="processbooking" element={<ProcessBooking />}></Route>
          <Route exact path={`/:locale/terms_conditions`} element={<TermsConditions />}></Route>
          <Route exact path="/:locale/privacy_policy" element={<PrivacyPolicy />}></Route>
          <Route exact path="/:locale/faq" element={<FaqPage />}></Route>
          <Route exact path="/:locale/contact_us" element={<ContactUs />}></Route>
          <Route exact path="/:locale/about_us" element={<AboutUsPage/>}></Route>
          <Route exact path="/:locale/cancel_any_time" element={<CancelAnyTime/>}></Route>
          <Route exact path="/:locale/deals" element={<DealsPage/>}></Route>
          <Route exact path="/:locale/deal_details/:deal_id" element={<DealsDetailPage />}></Route>
          <Route exact path="deal_three" element={<DealsPageTwo/>}></Route>

        </Routes>
        <Footer />
      </Router>
      
    // </GlobalContextProvider>
  );
}

export default App;

// use helmet for changes to the document head https://github.com/nfl/react-helmet