import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { useGlobals } from "../../contexts/globalContexts";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import XMLParser from "react-xml-parser";
import validator from "validator";
import { format } from "date-fns";
import ReactModal from "react-modal";

import MobLeftArrow from "../../img/mob-left-right-arrow.svg";
import MobileArrowDown from "../../img/arrow_down.svg";
import TopSteps from "./TopSteps";
import Sidebar from "./Sidebar";
import ContactDetails from "./ContactDetails";
import FreeCancellation from "./FreeCancellation";
import Footer from "./Footer";
import BackToTopButton from "../BackToTopButton";
import "../../css/dialog_modal.css";
import * as Ladda from "ladda";
import "../../css/passenger_information.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import FlightDetails from "../FlightDetails/FlightDetails";
import { click } from "@testing-library/user-event/dist/click";
import FlightDetailsPopUp from "../FlightDetailsPopUp/FlightDetailsPopUp";
import SpinnerLoader from "../Spinner/SpinnerLoader";
import SpinnerImg from "../../Components/Spinner/SpinnerImg.svg"


function PassengerInformation() {
  let navigate = useNavigate();
  const {
    bookingDetails,
    showHepar,
    setContactDetails,
    hepstarData,
    totalCharges,
    setHepstarPolicyNumber,
    isDisabled,
    passengers,
    passengerData,
    fromDate,
    toDate,
    emailError,
    setEmailError,
    phoneError,
    setPhoneError,
    nameError,
    setNameError,
    bdyError,
    setBdyError,
    setPassportNoError,
    passportNoError,
    countryError,
    setCountryError,
    passportExpError,
    setPassportExpError,
    genderError,
    setGenderError,
    searchId,
    isLoading,
    setIsloading,
    setDisable,
    bookingId,
    setBookingId,
    setCreateBookingApiData,
    createBookingApiData,
    noBookingsMsg,
    setNoBookingsMsg,
    siteURLS,
    itenraryDetails,
    setItenraryDetails,
    setBookingRef,
    tripType,
    setPassengerDetails,
    passengerDetails,
    contactData,
    setContactData,
    newData,
    setNewData,
    pricingInfo,
    setPricingInfo,
    priceSummary,
    setPriceSummary,
    totalPass,
    setTotalPass,
    callContactAPIState,
    setCallContactAPIState,
    insurance,
    setInsurance,
    code,
    setCode,
    codeUpdatedValue,
    setCodeUpdatedValue,
    tap,
    setTap,
    tapPayConfig,
    setTapPayConfig,
    hepstarCalled,
    setHepstarCalled,
    passengerAdded,
    setPassengerAdded,
    setHepstarCheckDisable,
    setCheckedValue,
    setHepstarData,
    setHepstarDetails,
    setItem1,
    setItem2,
    setItem3,
    setItem4,
    setItem5,
    isHepstarChecked,
    setIsHepstarChecked,
    noThanks,
    setNoThanks,
    buyHepstar,
    setBuyHepstar,
    setHepstarResult,
    isBuy,
    setShowHepar,
    setIsChecked,
    hepstarResult,
    getItinerariesCopy,
    setItinerariesCopy,
    discountedAmount,
    setDiscountedAmount,
    lang,
    locale,
    setLocale,
    setMoreInfoToggle,
    setCurr,
    setIsFlightDetailsOpen,
    utmMed,
    setUtmMed,
    setTripType,
    docType, setDocType,
    makePaymentBtn , setMakePaymentBtn,
    cuponCodeVal, setCuponCodeVal, tapPublicKey, setTapPublicKey, taxPrice
  } = useGlobals();

  useEffect(() => {
    if (!isMobile) {
      document
        .getElementsByTagName("body")[0]
        .setAttribute("style", "background-image:none");
      document
        .getElementsByTagName("main")[0]
        .setAttribute("class", "page search_results");
    }
  }, []);

  let { BookingRef } = useParams();

  

  const [searchID, setSearchID] = useState();

  const searchResult = useLocation().search;
  const _SearchID = new URLSearchParams(searchResult).get("SearchId");

  const [apiCalled, setApiCalled] = useState(false);

  const InputRef = useRef();

  const search = useLocation().search;
  const search_id = new URLSearchParams(search).get("SearchId");
  const itinerary_key = new URLSearchParams(search).get("ItineraryKey");
  let utm_medium = new URLSearchParams(search).get("utm_medium");

  useEffect(() => {
    setUtmMed(utm_medium);
  }, [utm_medium]);

  const [givenName, setIGivenName] = useState();

  const [displayHyper, setDisplayHyper] = useState(false);
  //
  // const [flightDetails, showFlightDetails] = useState(false);
  const [email, setEmail] = useState(null);
  // const [code, setCode] = useState("966");
  const [number, setNumber] = useState(null);

  const [apiRun, setApiRun] = useState(false);

  let contactNumber = [code, number];
  contactNumber = contactNumber.toString();
  contactNumber = parseFloat(contactNumber.replace(/,/g, ""));

  const [modalMsg, setModalMsg] = useState("");

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const [totalPassengerCount, setTotalPassengerCount] = useState(1);

  const getTheDate = (date) => {
    var parts = date?.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return dt;
  };

  const [hideButtons, setHideButtons] = useState(false);

  const [igivenName, setIgivenName] = useState();

  let itinerary;

  let adultQty = "";
  let childQty = "";
  let infantsQty = "";
  let adultPrice = "";
  let childPrice = "";
  let infantPrice = "";
  let adultTax = "";
  let infantTax = "";
  let childTax = "";
  let price = "";
  let taxAmount = "";

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // const [utmMed ,setUtmMed] = useState();
  let utmMedVal;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.classList.add("sub_page");
      document.getElementById("root").classList.add("page");
      document.getElementById("root").classList.add("search_results");
    }

    document.title = "Passenger Information";

    setIsloading(true);
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/GetItineraryDetails`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: BookingRef,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.httpStatusCode === 200) {
          setUtmMed(data.responseModel.utmParams.medium);
          if(data?.responseModel?.utmParams?.medium === "meta"){
            setItineraries([data?.responseModel]);
            if(isMobile){
              showFlightDetails();
            }
          }
          // setUtmMed('meta');
      
          if(data?.responseModel?.CouponModel?.Code){
            setCuponCodeVal(data?.responseModel?.CouponModel?.Code);
            setCuponAvailable(true);
          }
          utmMedVal = data.responseModel.utmParams.medium;
          setCurr(data.responseModel.passengers[0].price.currency);
          setBookingRef(BookingRef);
          validateItneraryAPI();
          itinerary = data.responseModel;
          setItineraries([data?.responseModel]);
          if(!isMobile){
            setIsloading(false);
          }
          if(data?.responseModel?.utmParams?.medium !== "meta"){
            setIsloading(false);
          }
        
        
          setTotalPass(itinerary);
          setApiCalled(true);
          if (data.responseModel.IsInsured === true) {
            setInsurance(true);
          }
          if (data.responseModel.Leg.length === 2) {
            setTripType(1);
          } else {
            setTripType(0);
          }

          if (data.responseModel.PassengerDetails[0].givenName !== null) {
            setShowButtons(true);
          } else {
            setShowButtons(false);
          }

          setTotalPassengerCount(data.responseModel.PassengerDetails.length);

          if (itinerary && itinerary?.passengers[0]) {
            adultQty = itinerary?.passengers[0]?.qty;
            adultPrice =
              adultQty * itinerary?.passengers[0]?.price?.totalFareAmount;
            adultTax =
              itinerary?.passengers[0]?.price?.totalTaxAmount * adultQty;
          }
          if (itinerary && itinerary?.passengers[1]?.type === "CNN") {
            childQty = itinerary?.passengers[1]?.qty;
            childPrice =
              childQty * itinerary?.passengers[1]?.price?.totalFareAmount;
            childTax =
              itinerary?.passengers[1]?.price.totalTaxAmount * childQty;
          }
          if (itinerary && itinerary?.passengers[1]?.type === "INF") {
            infantsQty = itinerary?.passengers[1].qty;
            infantPrice =
              infantsQty * itinerary?.passengers[1]?.price?.totalFareAmount;
            infantTax =
              itinerary?.passengers[1]?.price?.totalTaxAmount * infantsQty;
          }
          if (itinerary && itinerary?.passengers[2]?.type === "INF") {
            infantsQty = itinerary?.passengers[2]?.qty;
            infantPrice =
              infantsQty * itinerary?.passengers[2]?.price?.totalFareAmount;
            infantTax =
              itinerary?.passengers[2]?.price?.totalTaxAmount * infantsQty;
          }

          setPricingInfo({
            adultQty: adultQty,
            adultPrice: Number(adultPrice).toFixed(2),
            adultTax: adultTax,
            childQty: childQty,
            childPrice: Number(childPrice).toFixed(2),
            childTax: childTax,
            infantsQty: infantsQty,
            infantPrice: Number(infantPrice).toFixed(2),
            infantTax: infantTax,
            price: adultPrice + childPrice + infantPrice,
            taxPrice: adultTax + childTax + infantTax,
          });
          price = adultPrice + childPrice + infantPrice;
          taxAmount = adultTax + childTax + infantTax;
          setPriceSummary({
            price:
              Number(adultPrice) + Number(childPrice) + Number(infantPrice),
            taxPrice: Number(adultTax) + Number(childTax) + Number(infantTax),
            totalPrice: data.responseModel.TotalPrice,
          });
          if (
            data?.responseModel.passengers[0].RevenueModel.Discount !== null
          ) {
            setDiscountedAmount(
              data?.responseModel?.passengers[0]?.RevenueModel?.Discount[0]
                ?.DiscountAmount * data?.responseModel?.PassengerDetails?.length
            );
          } else {
            setDiscountedAmount(0);
          }

          if (itinerary?.Leg[1]) {
            setItenraryDetails({
              ItineraryId: itinerary.ItineraryId,
              itineraryKey: itinerary.ItineraryKey,
              GoingDepartureAirport:
                itinerary.Leg[0].Segements[0].departureAirport.airportName,
              goingDepartureTime:
                itinerary.Leg[0].Segements[0].flights[0].departureTime.split(
                  " "
                )[0],
              goingDepartureTerminal:
                itinerary.Leg[0].Segements[0].departureAirport.Terminal,
              goingDepartureDate: getFormattedDate(
                itinerary.Leg[0].departureDate
              ),
              goingArrivalAirport:
                itinerary?.Leg[1]?.Segements[0].departureAirport.airportName,
              GoingDepartureAirportCode:
                itinerary.Leg[0].Segements[0].departureAirport.airportCode,
              GoingArrivalCity:
                itinerary.Leg[0].Segements[0].arrivalAirport.cityName,
              GoingArrivalAirportCode:
                itinerary.Leg[0].Segements[0].arrivalAirport.airportCode,
              goingArrivalTime:
                itinerary?.Leg[1]?.Segements[0].flights[0].arrivalTime.split(
                  " "
                )[0],
              GoingAirlineLogo: `${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier.marketing}.png`,
              goingArrivalTerminal:
                itinerary?.Leg[1]?.Segements[0].departureAirport.Terminal,
              goingDepartureCity:
                itinerary.Leg[0].Segements[0].departureAirport.cityName,
              ReturnDepartureCity:
                itinerary.Leg[0].Segements[0].departureAirport.cityName,
              goingArrivalAirport:
                itinerary?.Leg[0]?.Segements[0].arrivalAirport.airportName,
              ReturnDepartureAirport:
                itinerary?.Leg[1]?.Segements[0].departureAirport.airportName,
              ReturnDepartureDate: getFormattedDate(
                itinerary?.Leg[1]?.departureDate
              ),
              ReturnDepartureTime:
                itinerary?.Leg[1]?.Segements[0].flights[0].departureTime.split(
                  " "
                )[0],
              ReturnDepartureTerminal:
                itinerary?.Leg[1]?.Segements[0].departureAirport.Terminal,
              ReturnArrivalAirport:
                itinerary?.Leg[1]?.Segements[
                  itinerary?.Leg[1]?.Segements.length - 1
                ].arrivalAirport.airportName,
              ReturnArrivalTime:
                itinerary?.Leg[1]?.Segements[
                  itinerary?.Leg[1]?.Segements.length - 1
                ].flights[0].arrivalTime.split(" ")[0],
              ReturnArrivalTerminal:
                itinerary?.Leg[1]?.Segements[
                  itinerary?.Leg[1]?.Segements.length - 1
                ].arrivalAirport.Terminal,
              ReturnArrivalDate: getFormattedDate(
                itinerary.Leg[1].departureDate
              ),
              ReturnDepartureCity:
                itinerary?.Leg[1]?.Segements[0].departureAirport.cityName,
              ReturnDepartureAirportCode:
                itinerary?.Leg[1]?.Segements[0].departureAirport.airportCode,
              ReturnArrivalCity:
                itinerary?.Leg[1]?.Segements[0].arrivalAirport.cityName,
              ReturnArrivalAirportCode:
                itinerary?.Leg[1]?.Segements[0].arrivalAirport.airportCode,
              price: itinerary?.passengers[0]?.price.totalPrice,
              taxPrice: itinerary?.passengers[0]?.price.totalTaxAmount,
              TripType: "RoundTrip",
              goingDepartureCountry:
                itinerary?.Leg[0]?.Segements[0].arrivalAirport.countryCode,
              goingArrivalCountry:
                itinerary?.Leg[0]?.Segements[0].departureAirport.countryCode,
              ReturnDepartureCountry:
                itinerary?.Leg[1]?.Segements[0].departureAirport.countryCode,
              ReturnArrivalCountry:
                itinerary?.Leg[1]?.Segements[0].arrivalAirport.countryCode,
              ReturnAirlineLogo: `${window.location.origin}/img/airlines/${itinerary.Leg[1].Segements[0].flights[0].carrier.marketing}.png`,
            });
          } else {
            setItenraryDetails({
              ItineraryId: itinerary.ItineraryId,
              itineraryKey: itinerary.ItineraryKey,
              GoingDepartureAirport:
                itinerary?.Leg[0]?.Segements[0].departureAirport.airportCode,
              goingDepartureTime:
                itinerary?.Leg[0]?.Segements[0].flights[0].departureTime.split(
                  " "
                )[0],
              goingDepartureTerminal:
                itinerary.Leg[0].Segements[0].departureAirport.Terminal,
              goingDepartureDate: getFormattedDate(
                itinerary?.Leg[0]?.departureDate
              ),
              goingArrivalAirport:
                itinerary?.Leg[0]?.Segements[0].arrivalAirport.airportCode,
              goingArrivalTime:
                itinerary?.Leg[0]?.Segements[0].flights[0].arrivalTime.split(
                  " "
                )[0],
              goingArrivalTerminal:
                itinerary.Leg[0].Segements[0].arrivalAirport.Terminal,
              price: itinerary.passengers[0].price.totalPrice,
              taxPrice: itinerary.passengers[0].price.totalTaxAmount,
              TripType: "OneWay",
              goingDepartureCountry:
                itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
              goingArrivalCountry:
                itinerary.Leg[0].Segements[0].arrivalAirport.countryCode,
              GoingDepartureAirportCode:
                itinerary.Leg[0].Segements[0].departureAirport.airportCode,
              GoingArrivalCity:
                itinerary.Leg[0].Segements[0].departureAirport.cityName,
              GoingArrivalAirportCode:
                itinerary.Leg[0].Segements[0].arrivalAirport.airportCode,
              GoingAirlineLogo: `${window.location.origin}/img/airlines/${itinerary.Leg[0].Segements[0].flights[0].carrier.marketing}.png`,
              goingDepartureCity:
                itinerary.Leg[0].Segements[0].departureAirport.cityName,
              ReturnDepartureCity:
                itinerary.Leg[0].Segements[0].departureAirport.cityName,
              goingArrivalAirport:
                itinerary.Leg[0].Segements[0].arrivalAirport.airportName,
            });
          }
          if (
            data.responseModel.PassengerDetails[0].tblPassengerDocument !== null
          ) {
            // setContactData({
            //   iemail: data.responseModel.BookingContact.email,
            //   icountryCode: data.responseModel.BookingContact.countryCode,
            //   iphone: data.responseModel.BookingContact.phone,
            // });
            // setNewData(data.responseModel);
          }
        } else {
          setIsloading(false);
          navigate(-1);
          setNoBookingsMsg(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [hideContinue, setHideContinue] = useState(false);
  const [hideContinueB, setHideContinueB] = useState(false);

  let holdSearchId;

  const validateItneraryAPI = () => {
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/ValidateItinerary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: BookingRef,
        Mode: "B",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        if (utm_medium === "meta" || utmMed === "meta") {
          const getIdFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/Search`;

          let payloadToGetSearchId = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              directFlight: data.requestModel.directFlight,
              flexibleDates: data.requestModel.flexibleDates,
              preferedAirLine: "",
              tripType: data.requestModel.tripType,
              partner: 2,
              device: 0,
              cabin: data.requestModel.cabin,
              passengers: data.requestModel.passengers,
              locale: locale.toUpperCase(),
              currencyCode: data.requestModel.currencyCode,
              legs: data.requestModel.legs,
              sessions: {
                id: "0",
                userType: "Visitor",
                userID: "0",
              },
              utmParameters: {
                source: isMobile ? "MobileWeb" : "Web",
                medium: "Eilago",
                campaign: "Direct",
                term:
                  data?.requestModel?.tripType === 1
                    ? data?.requestModel?.legs[0]?.departureCode +
                      "," +
                      data?.requestModel?.legs[0]?.arrivalCode +
                      "-" +
                      data?.requestModel?.legs[1]?.departureCode +
                      "," +
                      data?.requestModel?.legs[1]?.arrivalCode
                    : data?.requestModel?.legs[0]?.departureCode +
                      "," +
                      data?.requestModel?.legs[0]?.arrivalCode,
                content: "website",
              },
            }),
          };

          // setIsloading(true);
          fetch(getIdFrom, payloadToGetSearchId)
            .then((response) => response.json())
            .then((data) => {
              holdSearchId = data.responseModel.searchId;
              setSearchID(data.responseModel.searchId);
              // console.log('Data', data);
            });
        }

        if (utm_medium !== "meta" || utmMed !== "meta") {
          fetch(`${process.env.REACT_APP_IN_URL}/Insurance/GetPrice`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              BookingRef: BookingRef,
              IsBuy: false,
              // IsBuy: isBuy,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (insurance === true || isBuy === true) {
                setShowHepar(true);
                setIsChecked(true);
              }
              setIsChecked(true);
              setShowHepar(true);

              if (data.httpStatusCode === 200) {
                if (
                  data?.responseModel?.responseParameters?.packageSize?.package
                    ?.pricedProduct?.productPriceBreakdown.priceDetail[1]
                    ?.type ||
                  data?.responseModel?.responseParameters?.packageSize?.package
                    ?.pricedProduct?.productPriceBreakdown.priceDetail[0]
                    ?.baseAmount
                ) {
                  setHepstarResult(true);
                } else {
                  setHepstarModal(false);
                }
                if (
                  data?.responseModel?.responseParameters?.packageSize?.package
                    ?.pricedProduct?.productPriceBreakdown.priceDetail[1]?.type
                ) {
                  setHepstarData({
                    totalHepstarAmount:
                      data?.responseModel?.responseParameters?.packageSize
                        ?.package?.pricedProduct?.productPriceBreakdown
                        .priceDetail[1]?.totalAmount,
                  });
                } else {
                  setHepstarData({
                    totalHepstarAmount:
                      data?.responseModel?.responseParameters?.packageSize
                        ?.package?.pricedProduct?.productPriceBreakdown
                        .priceDetail[0]?.baseAmount,
                  });
                }
                if (
                  data?.responseModel?.responseParameters?.packageSize?.package
                    ?.pricedProduct?.productInformation?.productDisplay
                    ?.description
                ) {
                  setHepstarDetails({
                    description:
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay
                        .description,
                    productName:
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay
                        .productname,
                    text: data.responseModel.responseParameters.packageSize
                      .package.pricedProduct.productInformation.productDisplay
                      .highlight.text,
                  });
                  if (
                    data?.responseModel?.responseParameters?.packageSize
                      ?.package?.pricedProduct?.productInformation
                      ?.productDisplay?.details?.item1
                  ) {
                    setItem1(
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay.details
                        .item1
                    );
                  }
                  if (
                    data?.responseModel?.responseParameters?.packageSize
                      ?.package?.pricedProduct?.productInformation
                      ?.productDisplay?.details?.item2
                  ) {
                    setItem2(
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay.details
                        .item2
                    );
                  }
                  if (
                    data?.responseModel?.responseParameters?.packageSize
                      ?.package?.pricedProduct?.productInformation
                      ?.productDisplay?.details?.item3
                  ) {
                    setItem3(
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay.details
                        .item3
                    );
                  }
                  if (
                    data?.responseModel?.responseParameters?.packageSize
                      ?.package?.pricedProduct?.productInformation
                      ?.productDisplay?.details?.item4
                  ) {
                    setItem4(
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay.details
                        .item4
                    );
                  }
                  if (
                    data?.responseModel?.responseParameters?.packageSize
                      ?.package?.pricedProduct?.productInformation
                      ?.productDisplay?.details?.item5
                  ) {
                    setItem5(
                      data.responseModel.responseParameters.packageSize.package
                        .pricedProduct.productInformation.productDisplay.details
                        .item5
                    );
                  }
                }

                setInsurance(true);
              } else {
                setIsloading(false);
                setInsurance(false);
              }
            })
            .catch((err) => console.log(err));
        }
        if (data.hasError === true && data.httpStatusCode === 200) {
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (
          data.hasError === true &&
          data.httpStatusCode === 202 &&
          data.error.errorCode === "Code 006" &&
          data.error.message === "No Record Found" &&
          data.error.errors[0] === "The flight you are trying to book is no longer available. Please select from other options."
        ) {

          setHideButtons(true);
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (
          data.hasError === true &&
          data.httpStatusCode === 202 &&
          data.error.errorCode === "Code 006" &&
          data.error.message === "No Record Found" &&
          data.error.errors[0] !== "The flight you are trying to book is no longer available. Please select from other options."
        ) {
    
          setHideButtons(false);
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (
          data.hasError === true &&
          data.httpStatusCode === 202 &&
          data.error.errorCode === "Code:004" &&
          data.error.message === "Unable to Process Request at the moment."
        ) {
          setHideButtons(true);
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (data.hasError === true && data.httpStatusCode === 202) {
         
          setHideContinue(false);
          openModal();
          setModalMsg(data.error.errors[0]);
          setItinerariesCopy([
            data?.responseModel?.pricingGroups[0]?.Itineraries[0],
          ]);
          // Price Change Code starts
          if (
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.RevenueModel?.Discount
          ) {
            setDiscountedAmount(
              data.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.RevenueModel?.Discount[0]
                ?.DiscountAmount * data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.qty
            );
          } else {
            setDiscountedAmount(0);
          }
          if(data?.responseModel?.pricingGroups[0]?.Itineraries[0]){
            // setTotalPass(data?.responseModel?.pricingGroups[0]?.Itineraries4[0]);

            // setTotalPass(data?.responseModel?.pricingGroups[0]?.Itineraries[0]);
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]
          ) {
            adultQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[0]?.qty;
            adultPrice =
              adultQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[0]?.price?.totalFareAmount;
            adultTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[0]?.price?.totalTaxAmount * adultQty;
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[1]
              ?.type === "CNN"
          ) {
            childQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.qty;
            childPrice =
              childQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalFareAmount;
            childTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalTaxAmount * childQty;
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[1]
              ?.type === "INF"
          ) {
            infantsQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.qty;
            infantPrice =
              infantsQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalFareAmount;
            infantTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalTaxAmount * infantsQty;
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[2]
              ?.type === "INF"
          ) {
            infantsQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[2]?.qty;
            infantPrice =
              infantsQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[2]?.price?.totalFareAmount;
            infantTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[2]?.price?.totalTaxAmount * infantsQty;
          }

          setPricingInfo({
            adultQty: adultQty,
            adultPrice: adultPrice,
            adultTax: adultTax,
            childQty: childQty,
            childPrice: childPrice,
            childTax: childTax,
            infantsQty: infantsQty,
            infantPrice: infantPrice,
            infantTax: infantTax,
            price: adultPrice + childPrice + infantPrice,
            taxPrice: adultTax + childTax + infantTax,
          });
          price = adultPrice + childPrice + infantPrice;
          taxAmount = adultTax + childTax + infantTax;
          setPriceSummary({
            price:
              Number(adultPrice) + Number(childPrice) + Number(infantPrice),
            taxPrice: Number(adultTax) + Number(childTax) + Number(infantTax),
            totalPrice:
              data?.responseModel?.pricingGroups[0]?.price,
          });
          // Price Change Code ends
        }
      })
      .catch((err) => console.log(err));
  };


  const validateItneraryAPIBMode = () => {
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/ValidateItinerary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: BookingRef,
        Mode: "B",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (utm_medium === "meta" || utmMed === "meta") {
          const getIdFrom = `${process.env.REACT_APP_FLIGHT_URL}/Flight/Search`;

          let payloadToGetSearchId = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              directFlight: data.requestModel.directFlight,
              flexibleDates: data.requestModel.flexibleDates,
              preferedAirLine: "",
              tripType: data.requestModel.tripType,
              partner: data.requestModel.partner,
              device: data.requestModel.device,
              cabin: data.requestModel.cabin,
              passengers: data.requestModel.passengers,
              locale: locale.toUpperCase(),
              currencyCode: data.requestModel.currencyCode,
              legs: data.requestModel.legs,
              sessions: {
                id: "0",
                userType: "Visitor",
                userID: "0",
              },
              utmParameters: {
                source: isMobile ? "MobileWeb" : "Web",
                medium: "meta",
                campaign: "Direct",
                term:
                  data.requestModel.tripType === 1
                    ? data.requestModel.legs[0].departureCode +
                      "," +
                      data.requestModel.legs[0].arrivalCode +
                      "-" +
                      data.requestModel.legs[1].departureCode +
                      "," +
                      data.requestModel.legs[1].arrivalCode
                    : data.requestModel.legs[0].departureCode +
                      "," +
                      data.requestModel.legs[0].arrivalCode,
                content: "website",
              },
            }),
          };

          // setIsloading(true);
          fetch(getIdFrom, payloadToGetSearchId)
            .then((response) => response.json())
            .then((data) => {
              holdSearchId = data.responseModel.searchId;
              setSearchID(data.responseModel.searchId);
              // console.log('Data', data);
            });
        }
        if (data.hasError === true && data.httpStatusCode === 200) {
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (
          data.hasError === true &&
          data.httpStatusCode === 202 &&
          data.error.errorCode === "Code 006" &&
          data.error.message === "No Record Found" &&
          data.error.errors[0] === "The flight you are trying to book is no longer available. Please select from other options."
        ) {

          setHideButtons(true);
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (
          data.hasError === true &&
          data.httpStatusCode === 202 &&
          data.error.errorCode === "Code 006" &&
          data.error.message === "No Record Found" &&
          data.error.errors[0] !== "The flight you are trying to book is no longer available. Please select from other options."
        ) {
    
          setHideButtons(false);
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (
          data.hasError === true &&
          data.httpStatusCode === 202 &&
          data.error.errorCode === "Code:004" &&
          data.error.message === "Unable to Process Request at the moment."
        ) {
          setHideButtons(true);
          openModal();
          setModalMsg(data.error.errors[0]);
        }
        if (data.hasError === true && data.httpStatusCode === 202) {
          setHideContinueB(true);
          openModal();
          setModalMsg(data.error.errors[0]);

          if (data?.responseModel?.pricingGroups[1]?.Itineraries[0]) {
            setItinerariesCopy([
              data?.responseModel?.pricingGroups[1]?.Itineraries[0],
            ]);

            setTotalPass(data?.responseModel?.pricingGroups[1]);
            if (
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.RevenueModel?.Discount
            ) {
              setDiscountedAmount(
                data.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.RevenueModel?.Discount[0]
                  ?.DiscountAmount * data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.qty
              );
            } else {
              setDiscountedAmount(0);
            }
            if(data?.responseModel?.pricingGroups[0]?.Itineraries[0]){
              // setTotalPass(data?.responseModel?.pricingGroups[0]?.Itineraries4[0]);
  
              // setTotalPass(data?.responseModel?.pricingGroups[0]?.Itineraries[0]);
            }
            if (
              data &&
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]
            ) {
              adultQty =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[0]?.qty;
              adultPrice =
                adultQty *
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[0]?.price?.totalFareAmount;
              adultTax =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[0]?.price?.totalTaxAmount * adultQty;
            }
            if (
              data &&
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[1]
                ?.type === "CNN"
            ) {
              childQty =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[1]?.qty;
              childPrice =
                childQty *
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[1]?.price?.totalFareAmount;
              childTax =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[1]?.price?.totalTaxAmount * childQty;
            }
            if (
              data &&
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[1]
                ?.type === "INF"
            ) {
              infantsQty =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[1]?.qty;
              infantPrice =
                infantsQty *
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[1]?.price?.totalFareAmount;
              infantTax =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[1]?.price?.totalTaxAmount * infantsQty;
            }
            if (
              data &&
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[2]
                ?.type === "INF"
            ) {
              infantsQty =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[2]?.qty;
              infantPrice =
                infantsQty *
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[2]?.price?.totalFareAmount;
              infantTax =
                data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                  ?.passengers[2]?.price?.totalTaxAmount * infantsQty;
            }
  
            setPricingInfo({
              adultQty: adultQty,
              adultPrice: adultPrice,
              adultTax: adultTax,
              childQty: childQty,
              childPrice: childPrice,
              childTax: childTax,
              infantsQty: infantsQty,
              infantPrice: infantPrice,
              infantTax: infantTax,
              price: adultPrice + childPrice + infantPrice,
              taxPrice: adultTax + childTax + infantTax,
            });
            price = adultPrice + childPrice + infantPrice;
            taxAmount = adultTax + childTax + infantTax;
            setPriceSummary({
              price:
                Number(adultPrice) + Number(childPrice) + Number(infantPrice),
              taxPrice: Number(adultTax) + Number(childTax) + Number(infantTax),
              totalPrice:
                data?.responseModel?.pricingGroups[0]?.price,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const callContactAPI = () => {
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Booking/AddBookingContact`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        BookingRef: BookingRef,
        Email: email,
        Phone: `${number}`,
        CountryCode: `${codeUpdatedValue}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCallContactAPIState(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (email || contactNumber) {
      setContactDetails({
        Email: email,
        ContactNumber: contactNumber,
      });
    }
  }, [email, contactNumber]);

  let adults = passengers.adultCount;
  let childs = passengers.childrenCount;
  let infants = passengers.infantsCount;
  let totalPassengers;
  if (utmMed === "meta") {
    totalPassengers = totalPass?.PassengerDetails?.length;
  } else {
    totalPassengers =
      passengers.adultCount +
      passengers.childrenCount +
      passengers.infantsCount;
  }

  // console.log(utm_medium);

  let tmp = [];

  for (let i = 0; i < totalPassengerCount; i++) {
    tmp.push(i);
  }

  useEffect(() => {
    if (email !== null) {
      if (!validator.isEmail(email)) {
        setEmailError("Please enter valid Email address");
      } else {
        setEmailError("");
        document.getElementById('email').style.borderColor = "green";
      }
    }
  }, [email]);

  useEffect(() => {
    if (
      email === null ||
      email.length < 1 ||
      (!validator.isEmail(email) && code === null && number === null)
    ) {
      setDisable(true);
    }
  }, []);
  useEffect(() => {
    if (email !== null && number?.length > 5) {
      setDisable(false);
    }
    if (number?.length < 5) {
      setDisable(true);
    }
    if (email === null || !validator.isEmail(email) || number === null) {
      setDisable(true);
    }
  }, [email, number]);

  useEffect(() => {
    if (totalPass && totalPass?.BookingContact?.email !== null) {
      setEmail(totalPass?.BookingContact?.email);
      setDisable(false);
    }
  }, [totalPass]);

  const createPassenger = () => {
    tmp.map((dataa, i) => {
      let newDate = new Date();
      let DOB = `${passengerData[i].yearDOB}-${passengerData[i].monthDOB}-${passengerData[i].dayDOB}`;
      let date1 = new Date(DOB);
      let date2 = new Date();
      let diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);

      let age = "";
      if (diffDays > 6205) {
        age = "ADT";
      }
      if (diffDays > 730 && diffDays < 6205) {
        age = "C09";
      }
      if (diffDays < 730) {
        age = "INF";
      }

      setCreateBookingApiData((oldArray) => [
        ...oldArray,
        {
          BookingRef: BookingRef,
          DateOfBirth: `${passengerData[i].yearDOB}-${passengerData[i].monthDOB}-${passengerData[i].dayDOB}`,
          GivenName: passengerData[i].firstName,
          SurName: passengerData[i].lastName,
          Gender: passengerData[i].gender ? passengerData[i].gender : "Male",
          Type: age,
          Documents: [
            {
              ExpirationDate: `${passengerData[i].passportExpiryYear}-${passengerData[i].passportExpiryMonth}-${passengerData[i].passportExpiryDay}`,
              Number: passengerData[i].passportNo,
              Type: passengerData[i].docType ? passengerData[i].docType : "P",
              IssueCountry: passengerData[i].passportIssueCountry
                ? passengerData[i].passportIssueCountry
                : process.env.REACT_APP_COUNTRY,
              NationalityCountry: passengerData[i].countryResidence
                ? passengerData[i].countryResidence
                : process.env.REACT_APP_COUNTRY,
            },
          ],
        },
      ]);
    });
  };

  const [errorModal, setErrorModal] = useState(false);

  let updatedData;

  const [isMakePaymentSelected, setIsMakePaymentSelected] = useState(false);

  const [oldData, setOldData] = useState();

  useEffect(() => {
    if (createBookingApiData.length > 0 && isMakePaymentSelected) {
      let tdm = createBookingApiData.filter(
        (v, i, a) =>
          a.findLastIndex(
            (v2) =>
              v2.DateOfBirth === v.DateOfBirth &&
              v2.givenName === v.givenName &&
              v2.SurName === v.SurName 
          ) === i
      );

      updatedData = tdm;

      setOldData(updatedData);
      addPassengerAPI();
      setIsMakePaymentSelected(false);
    }
    // });
  }, [createBookingApiData]);

  const [hepstarModal, setHepstarModal] = useState(false);

  const addPassengerAPI = () => {
    setIsloading(true);
    callContactAPI();

    setTimeout(() => {
      fetch(`${process.env.REACT_APP_FLIGHT_URL}/Booking/AddPassengerDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedData),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.httpStatusCode === 200) {
            setIsloading(false);
            navigate(`/${locale}/payment_details/${BookingRef}`);
          } else if (data.httpStatusCode === 400) {
            setCreateBookingApiData("");
            setIsMakePaymentSelected(false);
            // createPassenger();
            setIsloading(false);
            setIsOpen(true);
            setErrorModal(true);
            setModalMsg(data.error.message);
          } else {
            setIsloading(false);
            setErrorModal(true);
            setModalMsg(data.error.message);
          }
        })
        .catch((err) => console.log(err));
    }, 1000);
  };
  let diffDays;

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    height: "200px",
    width: "200px",
    margin: "0 auto",
    border: "0px",
    zIndex: 9999,
  };

  const [showButtons, setShowButtons] = useState(false);

  const [numberError, setNumberError] = useState("");

  let diffDays1 = 181;
  let diffDays2 = 181;
  let diffDays3 = 181;
  let diffDays4 = 181;
  let diffDays5 = 181;
  let diffDays6 = 181;
  let diffDays7 = 181;
  let diffDays8 = 181;
  let uName0;
  let uName1;
  let uName2;
  let uName3;
  let uName4;
  let uName5;
  let uName6;
  let uName7;
  let uName8;
  let uName9;
  let uPassport0;
  let uPassport1;
  let uPassport2;
  let uPassport3;
  let uPassport4;
  let uPassport5;
  let uPassport6;
  let uPassport7;
  let uPassport8;
  let uPassport9;

  const [passportValidation, setPassportValidation] = useState(false);
  const arrayVal = [];

  const [passChecked, setPassChecked] = useState(false);

 



  const handleSubmit = (e) => {
    e.preventDefault();
    let passYear;
    let passMonth;
    let passDay;
    let expiryDate;
    let date1;
    let date2;
 
    


    if (document.getElementById("dayOfPassport0")?.value) {
      passYear = document.getElementById(`yearOfPassport0`).value;
      passMonth = document.getElementById(`monthOfPassport0`).value;
      passDay = document.getElementById(`dayOfPassport0`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport1")?.value) {
      passYear = document.getElementById(`yearOfPassport1`).value;
      passMonth = document.getElementById(`monthOfPassport1`).value;
      passDay = document.getElementById(`dayOfPassport1`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays1 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays1 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport2")?.value) {
      passYear = document.getElementById(`yearOfPassport2`).value;
      passMonth = document.getElementById(`monthOfPassport2`).value;
      passDay = document.getElementById(`dayOfPassport2`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays2 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays2 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport3")?.value) {
      passYear = document.getElementById(`yearOfPassport3`).value;
      passMonth = document.getElementById(`monthOfPassport3`).value;
      passDay = document.getElementById(`dayOfPassport3`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays3 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays3 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport4")?.value) {
      passYear = document.getElementById(`yearOfPassport4`).value;
      passMonth = document.getElementById(`monthOfPassport4`).value;
      passDay = document.getElementById(`dayOfPassport4`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays4 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays4 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport5")?.value) {
      passYear = document.getElementById(`yearOfPassport5`).value;
      passMonth = document.getElementById(`monthOfPassport5`).value;
      passDay = document.getElementById(`dayOfPassport5`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays5 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays5 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport6")?.value) {
      passYear = document.getElementById(`yearOfPassport6`).value;
      passMonth = document.getElementById(`monthOfPassport6`).value;
      passDay = document.getElementById(`dayOfPassport6`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays6 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays6 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport7")?.value) {
      passYear = document.getElementById(`yearOfPassport7`).value;
      passMonth = document.getElementById(`monthOfPassport7`).value;
      passDay = document.getElementById(`dayOfPassport7`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays7 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays7 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (document.getElementById("dayOfPassport8")?.value) {
      passYear = document.getElementById(`yearOfPassport8`).value;
      passMonth = document.getElementById(`monthOfPassport8`).value;
      passDay = document.getElementById(`dayOfPassport8`).value;

      expiryDate = `${passYear}-${passMonth}-${passDay}`;
      date1 = new Date(expiryDate);
      date2 = new Date();

      diffDays8 = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays8 < 180) {
        setErrorModal(true);
        setIsOpen(true);
        setModalMsg(t("Passport Expiry Msg"));
      }
    }

    if (
      diffDays > 180 &&
      diffDays1 > 180 &&
      diffDays2 > 180 &&
      diffDays3 > 180 &&
      diffDays4 > 180 &&
      diffDays5 > 180 &&
      diffDays6 > 180 &&
      diffDays7 > 180 &&
      diffDays8 > 180
    ) {
      if (emailError || email === null) {
        var elemId = document.getElementById("email");
        elemId.focus();
        setEmailError("Please enter valid email address");
      } else if (number === null || number?.length < 5) {
        document.getElementById("number").focus();
        setNumberError("Please fill out this field");
      } else if (number !== null || number?.length > 5) {
        setNumberError("");
      }
      if (
        !emailError &&
        email !== null &&
        number !== null &&
        number?.length > 5 
        // && totalPassports === true
      ) {
        createPassenger();
        setIsMakePaymentSelected(true);
      }
  
    }

    
  };


  const [elemID, setElemID] = useState();

  const [submitBtn, setSubmitBtn] = useState(false);

  const makePayment = () => {
    document.getElementById("submit-btn").click();
    // console.log(document.activeElement.id);
    setSubmitBtn(true);
    setElemID(document.activeElement.id);
    if (!isMobile) {
      let focused = document.activeElement;
      var elem = document.getElementById(document.activeElement.id);
      if (document?.activeElement?.value?.length === 0) {
        if (elem.nextElementSibling !== null) {
          elem.nextElementSibling.style.display = "block";
        }
      }
    }
  };

  let change;



  const regex = /[A-Za-z]/;
  function validate(e) {
    if (
      document.activeElement.tagName.toLowerCase() === "input" &&
      !document.activeElement.classList.contains("email-input")
    ) {
      if (!isMobile) {
        const chars = e.target.value.split("");
        const char = chars.pop();
        if (!regex.test(char)) {
          e.target.value = chars.join("");
          document.activeElement.nextElementSibling.classList.remove("error");
          document.activeElement.nextElementSibling.classList.add("errorr");
          // document.activeElement.nextElementSibling.innerHTML =
          //   "Please use english only";
        }
      }
    }
  }

  useEffect(() => {
    if (number !== null && number?.length > 5) {
      setNumberError("");
    }
  }, [number]);

  const callHepstarPassengers = () => {
    setHepstarCalled(false);
    setCheckedValue(false);
  };


  let route = window.location.pathname.split("/");

  const goBackFunction = () => {
    setIsOpen(true);
    // setErrorModal(true);
    if (utmMed === "meta" || utm_medium) {
      navigate(`/${locale}/search_results/${searchID}`);
    } else {
      navigate(-1);
    }
  };

  const paymentNavigate = () => {
    if (utmMed !== "meta") {
      fetch(`${process.env.REACT_APP_IN_URL}/Insurance/GetPrice`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BookingRef: BookingRef,
          IsBuy: false,
        }),
      })
        .then((res) => res.json())
        .then((data) => {})
        .catch((err) => console.log(err));
    }
    navigate(`/${locale}/payment_details/${BookingRef}`);
  };

  const callHepstarFun = (e) => {
    setIsHepstarChecked(true);
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    let val = e.target.value;
    if (val === "Yes") {
      setBuyHepstar(true);
      setHepstarCalled(true);
      setNoThanks(false);
      closeModal();
    } else {
      setBuyHepstar(false);
      setHepstarCalled(false);
      setNoThanks(true);
      closeModal();
    }
  };

  const { t, i18n, ready } = useTranslation();

  let localePath;
  localePath = window.location.pathname.split("/");

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    setTimeout(() => {
      if (localePath[1] === "en") {
        changeLanguage("en");
        setLocale("en");
        //  navigate(`/en/${localePath[2]}/${localePath[3]}`)
        document.documentElement.setAttribute("lang", "en");
      } else {
        changeLanguage("ar");
        setLocale("ar");
        navigate(`/ar/${localePath[2]}/${localePath[3]}`);
        // document.documentElement.setAttribute("lang", 'ar');
      }
    }, 500);
  }, []);

  useEffect(() => {
    document.getElementsByClassName("language")[0].style.display = "none";
  }, []);

  const [renderFlightDetails, setRenderFlightDetails] = useState(false);
  const [getItineraries, setItineraries] = useState();

  const showFlightDetails = (Itineraries) => {
    // console.log(Itineraries);
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 10, behavior: "smooth" });
    setRenderFlightDetails(true);
    setIsFlightDetailsOpen(true);

    setItineraries([totalPass]);
  };

  useEffect(() => {
    if (totalPass?.BookingContact?.phone) {
      setNumber(totalPass?.BookingContact?.phone);
    }
  }, [totalPass]);
  

  // const [cuponCodeVal, setCuponCodeVal] = useState('');
  const [cuponErr, setCuponErr] = useState('');
  const [couponSuccess, setCouponSuccess] = useState('');

  const [cuponAvailable, setCuponAvailable] = useState(false);


  const [spinerLoader, setSpinerLoader] = useState(false);


  const CuponCodeAPI = () => {
    setSpinerLoader(true);
    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/ApplyCouponCode`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        bookingRef: BookingRef,
        couponCode: cuponCodeVal,
        applyCode : cuponAvailable === true ? false : true
      }),
    })
      .then((res) => res.json())
      .then((data) => { 
        if(data.status === 400){
          setCuponErr(data?.errors.CouponCode[0]);
        }
        setSpinerLoader(false);
        if(data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.CouponModel){
          setCuponAvailable(false);
          // setCuponCodeVal('');
        }
        if(data?.httpStatusCode === 204){
          setCuponErr(data.error.errors[0]);
          setCouponSuccess('');
        }
        if(data?.httpStatusCode !== 204 && data?.httpStatusCode !== 200 && data?.status !== 400){
          setCuponErr("No Record Found");
          setCouponSuccess('');
        }
        if(data?.httpStatusCode === 200){
          setCouponSuccess(data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.CouponModel?.Message);
        if(data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.CouponModel){
          if(cuponAvailable){
            setCuponAvailable(false);
          }
          else{
            setCuponAvailable(true);
          }
        }
        else{
          setCuponAvailable(false);
       
        }
          setCuponErr('');
          setTimeout(() => {
            setCouponSuccess('');
          }, 5000)
          if (
            data?.responseModel?.pricingGroups[0].Itineraries[0].passengers[0].RevenueModel.Discount !== null
          ) {
            setDiscountedAmount(
              data.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]?.RevenueModel?.Discount[0]
                ?.DiscountAmount * data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.PassengerDetails?.length
            );
          } else {
            setDiscountedAmount(0);
          }

          setTotalPass(data?.responseModel?.pricingGroups[0].Itineraries[0]);
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[0]
          ) {
            adultQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[0]?.qty;
            adultPrice =
              adultQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[0]?.price?.totalFareAmount;
            adultTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[0]?.price?.totalTaxAmount * adultQty;
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[1]
              ?.type === "CNN"
          ) {
            childQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.qty;
            childPrice =
              childQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalFareAmount;
            childTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalTaxAmount * childQty;
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[1]
              ?.type === "INF"
          ) {
            infantsQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.qty;
            infantPrice =
              infantsQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalFareAmount;
            infantTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[1]?.price?.totalTaxAmount * infantsQty;
          }
          if (
            data &&
            data?.responseModel?.pricingGroups[0]?.Itineraries[0]?.passengers[2]
              ?.type === "INF"
          ) {
            infantsQty =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[2]?.qty;
            infantPrice =
              infantsQty *
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[2]?.price?.totalFareAmount;
            infantTax =
              data?.responseModel?.pricingGroups[0]?.Itineraries[0]
                ?.passengers[2]?.price?.totalTaxAmount * infantsQty;
          }

          setPricingInfo({
            adultQty: adultQty,
            adultPrice: adultPrice,
            adultTax: adultTax,
            childQty: childQty,
            childPrice: childPrice,
            childTax: childTax,
            infantsQty: infantsQty,
            infantPrice: infantPrice,
            infantTax: infantTax,
            price: adultPrice + childPrice + infantPrice,
            taxPrice: adultTax + childTax + infantTax,
          });
          price = adultPrice + childPrice + infantPrice;
          taxAmount = adultTax + childTax + infantTax;
          setPriceSummary({
            price:
              Number(adultPrice) + Number(childPrice) + Number(infantPrice),
            taxPrice: Number(adultTax) + Number(childTax) + Number(infantTax),
            totalPrice:
              data?.responseModel?.pricingGroups[0]?.priceInformation
                ?.totalPrice,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    setTimeout(() => {
    fetch(`${process.env.REACT_APP_PG_URL}/Payment/GetPaymentGatewayConfiguration`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        currencyCode: process.env.REACT_APP_CURRENCY,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.httpStatusCode === 200) {
          // setEradPayment(false);
          // setTapPayment(true);
          if(data?.responseModel[0].Name === "TAPPAY"){
            setTapPublicKey(data.responseModel[0].Configs[0].Value);
          }
          else if(data?.responseModel[0].Name === "TAPPAY"){
            setTapPublicKey(data.responseModel[1].Configs[0].Value);
          }

          // if (data.responseModel[0].Name === "ERADPAY") {
          //   setProviderId(data.responseModel[0].Id);
          //   setEradPayDetails({
          //     token: data.responseModel[0].Configs[0].Value,
          //     copyURL: data.responseModel[0].Configs[1].Value,
          //     redirectURL:
          //       data.responseModel[0].Configs[2].Value +
          //       "/" +
          //       BookingRef +
          //       "/" +
          //       localePath[1] +
          //       "?providerID=" +
          //       2,
          //     mode: data.responseModel[0].Configs[3].Value,
          //   });
          // }
          //   else if(data.responseModel[1].Name === "ERADPAY"){
          //     setProviderId(data.responseModel[1].Id);
          //   setEradPayDetails({
          //     "token" : data.responseModel[1].Configs[0].Value,
          //     "copyURL" : data.responseModel[1].Configs[1].Value,
          //     "redirectURL" : data.responseModel[1].Configs[2].Value+'/'+BookingRef+'/'+localePath[1]+'?providerID='+2,
          //     "mode" : data.responseModel[1].Configs[3].Value,
          //   })
          // }
        }
      })
      .catch((err) => console.log(err));
    }, 7000);
  }, []);


  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
     if(totalCharges > 1 && taxPrice > 1){
      window.dataLayer.push({

        event: 'begin_checkout',
    
        ecommerce: {
    
          items: [
    
            {
    
              item_name: 'Flight Ticket',
    
              item_id: BookingRef,
    
              item_category: 'Flight',
              
              item_price: pricingInfo.price,
    
              price: totalCharges,

              discount: discountedAmount,
    
              quantity: 1
    
            }
    
          ],
    
          currency: process.env.REACT_APP_CURRENCY
    
        },
    
        transaction_id: BookingRef,
    
        affiliation: totalPass?.Leg[0]?.Segements[0]?.flights[0]?.carrier?.marketingFlightName,
    
        value: totalCharges,
    
        tax: taxPrice,
    
        coupon: cuponCodeVal ? cuponCodeVal : "",
    
        item_brand: totalPass?.Leg[0]?.Segements[0]?.flights[0]?.carrier?.marketingFlightName,
    
        cabin_class: totalPass?.Leg[0]?.Segements[0]?.cabinCode,
    
        passenger_count: totalPassengerCount
    
      });
     }
  
  }, [totalCharges]);

  // console.log(window.dataLayer);

  return (
    <div>
      <TopSteps />

      {renderFlightDetails && (
        <FlightDetailsPopUp
          // <FlightDetails
          renderFlightDetails={renderFlightDetails}
          setRenderFlightDetails={setRenderFlightDetails}
          getItineraries={getItineraries}
          totalCharges={totalCharges}
          pricingInfo={pricingInfo}
          totalPass={totalPass}
          // departureAirport={departureAirportValue}
        />
      )}

      {hepstarResult === true && hepstarModal === true ? (
        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          {
            <div className="msg">
              <p
                style={
                  locale === "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                {modalMsg}
              </p>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <ul className="d-flex">
                  <li className="mr-2">
                    <label className="form_checkbox label">
                      <span>{t("No Hepstar")}</span>
                      <input
                        type="radio"
                        name="hepstar"
                        checked={noThanks}
                        value="No"
                        onChange={(e) => callHepstarFun(false)}
                      />
                      <span
                        className="checkmark"
                        style={locale === "ar" ? { right: "0" } : { left: "0" }}
                      ></span>
                    </label>
                  </li>

                  <li className="mr-2">
                    <label className="form_checkbox label">
                      <span>{t("Yes Buy Hepstar")}</span>
                      <input
                        type="radio"
                        name="hepstar"
                        checked={hepstarCalled}
                        value="Yes"
                        onChange={(e) => callHepstarFun(true)}
                      />
                      <span
                        className="checkmark"
                        style={locale === "ar" ? { right: "0" } : { left: "0" }}
                      ></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          }
        </ReactModal>
      ) : null}

      {isLoading ? (
        <div className="loader__Overlay">
          <div className="loader"></div>
        </div>
      ) : null}

      {hideButtons ? (
        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          {
            <div className="msg">
              <p>{modalMsg}</p>
            </div>
          }

          <div className="row bottom_align1">
            <div className="col-lg-12 text-center">
              <button
                onClick={() => {
                  closeModal();
                  goBackFunction();
                  setHideContinue(false);
                }}
                className="button "
              >
                {t("Go Back Button")}
              </button>
            </div>
          </div>
        </ReactModal>
      ) : null}
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        {
          <div className="msg">
            <p>{modalMsg}</p>
          </div>
        }

        {
          <div className="row bottom_align1">
            <div className="col-lg-6 align_left">
              <button
                onClick={() => {
                  closeModal();
                  // navigate(`/${locale}/search_results/${searchId}`);
                  goBackFunction();
                  setHideContinue(false);
                }}
                className="button "
              >
                {t("Go Back Button")}
              </button>
            </div>

            <div className="col-lg-6 align_right">
              <button
                onClick={() => {
                  validateItneraryAPIBMode();
                  closeModal();
                }}
                className="button "
              >
                {t("Continue Button")}
              </button>
            </div>
          </div>
        }
      </ReactModal>

      {errorModal ? (
        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          {
            <div className="msg">
              <p>{modalMsg}</p>
            </div>
          }

          <div className="row bottom_align1">
            <div className="col-lg-12 text-center">
              <button
                onClick={() => {
                  closeModal();
                }}
                className="button "
              >
                {t("Continue Button")}
              </button>
            </div>
          </div>
        </ReactModal>
      ) : null}

      <div id="main"></div>
      <div
        className="container mob-mt-0 mt-4"
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <div className="row mobile_header mob-top-m-none dls-none ">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-lg-7 col-7">
                <span className="font-bold font-14  text-white">
                  {itenraryDetails.goingDepartureCity}
                </span>
                <img src={MobLeftArrow} className="mx-2 " alt="left-arrow" />
                <span className="font-bold font-14 pb-2 text-white">
                  {itenraryDetails?.TripType === "RoundTrip" &&
                    totalPass?.Leg[1]?.Segements[0]?.departureAirport
                      ?.cityName}{" "}
                  {itenraryDetails?.TripType === "OneWay" &&
                    totalPass?.Leg[0]?.Segements[
                      totalPass?.Leg[0]?.Segements?.length - 1
                    ]?.arrivalAirport?.cityName}{" "}
                </span>
                <br />

                <span className="list-inline mt-1">
                  {itenraryDetails.TripType === "RoundTrip" ? (
                    <span className="font-12 text-white">
                      {itenraryDetails?.goingDepartureDate} -{" "}
                      {itenraryDetails?.ReturnDepartureDate}
                    </span>
                  ) : (
                    <span className="font-12 text-white">
                      {itenraryDetails?.goingDepartureDate}
                    </span>
                  )}
                </span>
              </div>

              <div className="col-lg-5 text-right col-5 vertically-align-center">
                {/* <img
                  src={MobileArrowDown}
                  onClick={() => showFlightDetails(!flightDetails)}
                  alt="mob-arrow-down"
                /> */}
                {/* <span className="font-10 text-white underlined-text" onClick={() => showFlightDetails(!flightDetails)}>{t("Flight Details")}</span> */}
                <span
                  style={{ fontSize: "14px" }}
                  className=" text-white underlined-text"
                  onClick={() => showFlightDetails(totalPass)}
                >
                  {t("Booking Summary")}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* {flightDetails ? (
          <div className="row flight-details px-3 dls-none">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-6">
                  <span className="font-bold font-13">
                    {itenraryDetails.goingDepartureDate}
                  </span>
                </div>

              </div>

              <div className="row mt-3">
                <div className="col-2">
                  <img
                    src={itenraryDetails.GoingAirlineLogo}
                    style={{ height: "30px", width: "30px" }}
                    alt="going-airline-log"
                  />
                </div>

                <div className="col-5">
                  <span className="font-bold font-16">
                    {String(itenraryDetails.goingDepartureTime).substring(0, 5)}{" "}
                    - {String(itenraryDetails.goingArrivalTime).substring(0, 5)}
                  </span>
                  <p className="font-10">{itenraryDetails.goingAirline}</p>
                </div>

                <div className="col-5 text-right">
                  <p className="font-10">
                    {itenraryDetails.goingDepartureDate},{" "}
                    {itenraryDetails.GoingDepartureAirport} -{" "}
  
                {itenraryDetails.TripType === "RoundTrip" &&
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport.airportName}
                {itenraryDetails.TripType === "OneWay" &&
                  totalPass?.Leg[0]?.Segements[
                    totalPass?.Leg[0].Segements.length - 1
                  ].arrivalAirport.airportName}
                  </p>
                  <p className="font-10 font-bold text-green-mob">
                    {itenraryDetails.GoingStop > 0 ? null : t('Non-Stop')}
                  </p>
                </div>
              </div>

              {itenraryDetails.TripType === "RoundTrip" ? (
                <div>
                  <div className="row">
                    <div className="col-6">
                      <span className="font-bold font-13">
                        {itenraryDetails.ReturnDepartureDate}
                      </span>
                    </div>

                  </div>

                  <div className="row mt-3">
                    <div className="col-2">
                      <img
                        src={itenraryDetails.ReturnAirlineLogo}
                        style={{ height: "30px", width: "30px" }}
                        alt="return-airline-logo"
                      />
                    </div>

                    <div className="col-5">
                      <span className="font-bold font-16">
                        {String(itenraryDetails.ReturnDepartureTime).substring(
                          0,
                          5
                        )}{" "}
                        -{" "}
                        {String(itenraryDetails.ReturnArrivalTime).substring(
                          0,
                          5
                        )}
                      </span>
                      <p className="font-10">{itenraryDetails.ReturnAirline}</p>
                    </div>

                    <div className="col-5 text-right">
                      <p className="font-10">
                        {itenraryDetails.ReturnDepartureDate},{" "}
                        {itenraryDetails.ReturnDepartureAirport} -{" "}
                        {itenraryDetails.ReturnArrivalAirport}
                      </p>
                      <p className="font-10 font-bold text-green-mob">
      
                        {itenraryDetails.ReturnStop > 0 ? null : t('Non-Stop')}
      
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null} */}

{utmMed === "meta" ? null :
<>
        <div className="row pt-3 px-3 dls-none">
          <div className="col-sm-12">
            <h2 className="font-16 font-bold">{t('Promo Code')}</h2>
          </div>
        </div>

        <div className="row pt-3 px-3 dls-none">
          <div className="col-8">
            <input
              type="text"
              placeholder={t('Coupon Code')}
              className="text-input email-input"
              onChange={(e) => setCuponCodeVal(e.target.value)}
              value={cuponCodeVal}
            />
          </div>
          <div className="col-4">
          {spinerLoader === true
          ?
          <img src={SpinnerImg} style={{height:"38px"}} />
          :
          cuponAvailable === false ?
              <button className="apply-btn-mob" onClick={CuponCodeAPI}>
                {spinerLoader === true ?
                <SpinnerLoader />
                :
                 t('Apply Btn')
                }
                 </button>
              :
            <button className="remove-btn-mob" onClick={() => CuponCodeAPI()}>
               {spinerLoader === true ?
                <SpinnerLoader />
                :
                t('Remove')
                }
             
              </button>
          }
          </div>
          <div className="col-12 col-lg-12 col-sm-12">
              <span className="errorr">{cuponErr}</span>
              <span className="success">{couponSuccess}</span>
            </div>
        </div>

        {/* <div className="row pt-3 px-3 dls-none">
          <div className="col-sm-12 col-12">
            <hr />
          </div>
        </div> */}
 </>       
}

        {/* <button className="ladda-button" data-style="expand-right">Submit</button> */}

        <div className="wrapper">
          <div className="row column-gap">
            <div className="col-sm-8 col-md-8 col-lg-8 ">
              <ContactDetails
                onEmailChange={(e) => setEmail(e.target.value)}
                emailValue={email}
                onCodeChange={(e) => setCode(e.target.value)}
                codeValue={code}
                onNumberChange={(e) => setNumber(e.target.value)}
                numberValue={number}
                passportNoError={passportNoError}
                callContactAPI={callContactAPI}
                givenName={givenName}
                totalPass={totalPass}
                itinerary={itinerary}
                pricingInfo={pricingInfo}
                handleSubmit={handleSubmit}
                numberError={numberError}
                elemID={elemID}
                submitBtn={submitBtn}
                setNumberError={setNumberError}
              />

              <div>
                {utmMed === "meta" ? null : (
                  <FreeCancellation
                    showHyperButton={() => setDisplayHyper(!displayHyper)}
                    email={email}
                    contactNumber={contactNumber}
                    number={number}
                    totalPass={totalPass}
                    insurance={insurance}
                    setInsurance={setInsurance}
                    callHepstarPassengers={callHepstarPassengers}
                  />
                )}

                <div className="row mb-5 mt-4 dps-none">
                  <div
                    className={
                      locale === "ar"
                        ? "col-sm-12 col-lg-12 col-md-12 text-right-arabic"
                        : "col-sm-12 col-lg-12 col-md-12 text-right"
                    }
                  >
                    {totalPass &&
                    totalPass?.PassengerDetails[0]?.givenName !== null &&
                    totalPass?.BookingContact?.email !== null ? (
                      <button
                        className={"button mb_120"}
                        onClick={() => {
                          paymentNavigate();
                        }}
                        style={{ marginBottom: "120px" }}
                        aria-label="Make Payment"
                      >
                        {t("Make Payment Button")}
                      </button>
                    ) : (
                      <button
                        // className={
                        //   isDisabled
                        //     ? "button disabled mb_120"
                        //     : "button mb_120"
                        // }
                        className={"button mb_120"}
                        // disabled={isDisabled}
                        id="passenger-btn"
                        title="Please fill out all missing fields"
                        onClick={() => {
                          makePayment();
                        }}
                        style={{ marginBottom: "120px" }}
                        aria-label="Make Paymentt"
                      >
                        {t("Make Payment Button")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 ">
              <Sidebar
                showHyperButton={() => setDisplayHyper(!displayHyper)}
                displayHyper={displayHyper}
                totalPass={totalPass}
                itenraryDetails={itenraryDetails}
                itinerary={itinerary}
                pricingInfo={pricingInfo}
                priceSummary={priceSummary}
                CuponCodeAPI={CuponCodeAPI}
                cuponErr={cuponErr}
                setCuponErr={setCuponErr}
                utmMed = {utmMed}
                couponSuccess={couponSuccess}
                cuponAvailable={cuponAvailable}
                spinerLoader={spinerLoader}
              />
            </div>
          </div>
        </div>
      </div>

      <BackToTopButton />

      {totalPass && totalPass?.PassengerDetails[0]?.givenName !== null ? (
        <Footer
          totalPassengers={totalPassengerCount}
          makePayment={() => {
            navigate(`/${locale}/payment_details/${BookingRef}`);
          }}
          displayHyper={displayHyper}
          totalPass={totalPass}
          itenraryDetails={itenraryDetails}
          itinerary={itinerary}
          pricingInfo={pricingInfo}
          priceSummary={priceSummary}
        />
      ) : (
        <Footer
          emailError={emailError}
          emailValue={email}
          numberValue={number}
          email={email}
          number={number}
          totalPass={totalPass}
          totalPassengers={totalPassengerCount}
          makePayment={() => {
            makePayment();
          }}
          displayHyper={displayHyper}
          itenraryDetails={itenraryDetails}
          itinerary={itinerary}
          pricingInfo={pricingInfo}
          priceSummary={priceSummary}
        />
      )}
    </div>
  );
}

export default PassengerInformation;
