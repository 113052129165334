import { changeLanguage } from "i18next";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../contexts/globalContexts";
import Searchform from "../SearchForm/SearchForm";

function Home() {
  const { lang, locale, setLocale } = useContext(GlobalContext);
  const navigate = useNavigate();

  const { local } = useParams();

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);

  };

  let localePath = window.location.pathname.split("/");

  useEffect(() => {
    if (lang === "en") {
      navigate("/");
    }
    if (lang === "ar") {
      navigate("/ar");
    }
  }, [lang]);

  useEffect(() => {
    if (local === "ar") {
      navigate(`/${local}`);
      setLocale("ar");
    } else {
      setLocale("en");
    }
  }, [local]);

  useEffect(() => {
    setTimeout(() => {
      if (locale === "en") {
        changeLanguage("en");

        document.documentElement.setAttribute("lang", "en");
      }
      if (locale === "ar") {
        changeLanguage("ar");
        // navigate('/ar');
        document.documentElement.setAttribute("lang", "ar");
      }
    }, 500);
  }, [locale]);

  let langVal = document.getElementsByTagName("html")[0].getAttribute("lang");


  return (
    <section
      className="container-fluid home_page"
      dir={langVal === "en" ? "ltr" : "rtl"}
    >
      <div className="container home_page_text">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <hgroup>
              <h1>{t("Heading")}</h1>
              <p>{t(`Description`)}</p>
            </hgroup>
          </div>
        </div>
      </div>
      <Searchform />
    </section>
  );
}

export default Home;
