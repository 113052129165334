import RecieptGreen from "../../img/receipt_green.svg";
import RecieptGreenArabic from "../../img/receipt-bottom-arabic.svg";
import RGreenWhite from "../../img/d/receipt-green-white.svg";
import RGreenWhiteArabic from "../../img/d/receipt-bottom-arabic.svg";
import PrintGreen from "../../img/print_green.svg";
import PrintGreenArabic from "../../img/print-bottom-arabic.svg";
import PGreenWhite from "../../img/d/print-green-white.svg";
import PGreenWhiteArabic from "../../img/d/print-bottom-arabic.svg";
import DownloadGreen from "../../img/download-green.svg";
import DownloadGreenArabic from "../../img/download-bottom-arabic.svg";
import DGreenWhite from "../../img/d/download-green-white.svg";
import DGreenWhiteArabic from "../../img/d/download-light-arabic-bottom.svg";
import { useTranslation } from "react-i18next";
import DDarkBottomArabic from "../../img/download-dark-bottom-arabic.svg";
import { useEffect } from "react";
import { useGlobals } from "../../contexts/globalContexts";

function WebIcons({generatePDF, printDocument}) {
const {lang, locale} = useGlobals();

  const { t, i18n, ready } = useTranslation();


  return (
    <div>
      <div className="row mt-5 mb-5 dps-none print_none" id="Web_icons">
        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
          <img src={locale === "en" ? RecieptGreen : RecieptGreenArabic} className={locale === "ar" ? "green-section-images light cursor-pointer" : "green-section-images mr-2 light cursor-pointer"} onClick={printDocument} />
          <img src={locale === "en" ? RGreenWhite : RGreenWhiteArabic} className={locale === "ar" ? "dark cursor-pointer" : "dark mr-2 cursor-pointer"} onClick={printDocument} />
          <img src={locale === "en" ? PrintGreen : PrintGreenArabic} className={locale === "ar" ? "green-section-images light cursor-pointer" : "green-section-images mr-2 light cursor-pointer"} onClick={printDocument} />
          <img src={locale === "en" ? PGreenWhite : PGreenWhiteArabic} className={locale === "ar" ? "dark cursor-pointer" : "dark mr-2 cursor-pointer"} onClick={printDocument} />
          <img src={locale === "en" ? DGreenWhite : DGreenWhiteArabic} className={locale === "ar" ? "green-section-images light cursor-pointer" : "green-section-images light cursor-pointer"} onClick={generatePDF} />
          <img src={locale === "en" ? DownloadGreen : DDarkBottomArabic} className="dark cursor-pointer" onClick={generatePDF} />
        </div>
      </div>
    </div>
  );
}

export default WebIcons;
