import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import { format } from "date-fns";
import "../../css/flight_details.css";
import { useTranslation } from "react-i18next";
import MoreInfoIcon from "../../img/d/more-info-icon.svg"
import { isMobile } from "react-device-detect";
import EditPen from "../../img/edit_pen.svg"
import RedCross from "../../img/red_cross.svg"
import GoBackArrow from "../../img/Go_back_arrow.svg";
import GreenTick from "../../img/green_tick.svg";
import MobArrowDownLight from "../../img/arrow_down_black.svg";
import MobileArrowDown from "../../img/arrow_down.svg";
import MobArrowUpLight from "../../img/arrow-top-light.svg";
import MobArrowUpDark from "../../img/arrow-top-dark.svg";
import CloseBtn from "../../img/close-btn.svg";
import DarkCloseBtn from "../../img/Group 7713.svg";
import GoBackArabic from "../../img/Go_back_arabic.svg";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'

function FlightDetailsPopUp({
  setRenderFlightDetails,
  getItineraries,
  showBaggageInfoTab,
  pricingInfo,
  totalCharges,
  totalPass,
  departureAirport,
}) {
  let navigate = useNavigate();

  const {
    bookingDetails,
    siteURLS,
    setIsloading,
    searchId,
    setBookingRef,
    setIsFlightDetailsOpen,
    getItinerariesCopy,
    setItinerariesCopy,
    lang,
    locale,
    segArray,
    setSegArray,
    currency, tripType
  } = useGlobals();
  const [itineraryTab, setItineraryTab] = useState(true);
  const [baggageTab, setBaggageTab] = useState(false);
  const [baggageTab2, setBaggageTab2] = useState(false);
  const [fareTab, setFareTab] = useState(false);
  const [fareTab2, setFareTab2] = useState(false);
  
  // console.log(baggageTab2);



  const [itinArr, setItinArr] = useState([]);

  const showItineraryTab = () => {
    setItineraryTab((current) => !current);
    setBaggageTab(false);
    setFareTab(false);
  };
  const showBaggageTab = () => {
    setBaggageTab((current) => !current);
    setItineraryTab(false);
    setFareTab(false);
    setBaggageTab2(false);
  };
  const showFareTab = () => {
    setFareTab((current) => !current);
    setItineraryTab(false);
    setBaggageTab(false);
  };
  const showFareTab2 = () => {
    setFareTab2((current) => !current);
    setItineraryTab(false);
    setBaggageTab(false);
    showFareTab(false);
    setBaggageTab2(false);
  };

  const showBaggageTab2 = () => {
    setBaggageTab2((current) => !current);
    setItineraryTab(false);
    setFareTab(false);
    setBaggageTab(false);
  }


  let BookingRef = "";

  const [ moreInfoToggle,
    setMoreInfoToggle] = useState(false);



  const BookNow = () => {
    localePath = window.location.pathname.split("/");
    if (
      localePath[2] === "passenger_information" ||
      localePath[2] === "payment_details"
    ) {
      setRenderFlightDetails(false);
      setIsloading(false);
    }

else{

    document.body.classList.remove("stop_overflow");
    document.getElementById("root").classList.remove("search_results");
    document.getElementById("root").classList.add("passenger_information");

    setIsloading(true);

    window.dataLayer = window.dataLayer || [];


        window?.dataLayer?.push({

          'event': 'eec_data_load',
        
          'language': locale, // Language
        
          'utm_source': isMobile ? "MobileWeb" : "Web", // Existing Data in System
        
          'user_id':0, // Hashed Unique User ID, for logged-in Users only
        
          'user_type': 'Visitor', // Registered User
        
         
        
          'flight_originid': getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode,
        
          'flight_destid': getItineraries[0]?.Leg[0]?.Segements[getItineraries[0]?.Leg[0].Segements?.length -1]?.arrivalAirport?.airportCode,
        
          'flight_startdate': getItineraries[0]?.Leg[0]?.departureDate,
        
          'flight_enddate': getItineraries[0]?.Leg[getItineraries[0]?.Leg?.length - 1]?.departureDate,
        
          'flight_pagetype': 'searchresults',
        
          'flight_totalvalue': getItineraries[0]?.TotalPrice,
        
         
        
            'ecommerce': {
        
                'currencyCode': getItineraries[0]?.passengers[0]?.price?.currency,
        
                'impressions': [{
        
                    'name': 'Flight Booking',
        
                    'id': `${getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode} - ${getItineraries[0]?.Leg[0]?.Segements[getItineraries[0]?.Leg[0].Segements?.length -1]?.arrivalAirport?.airportCode} - ${getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode}` ,
        
                    'price': getItineraries[0]?.TotalPrice,
        
                    'brand': '',
        
                    'category': 'Flight',
        
                    'list': 'Flight Search',
        
                    'position': 1
        
                }],
        
                'detail': {
        
                    'actionField': {
        
                        'list': 'Flight Search'
        
                    },
        
                    'products': [{
        
                        'name': 'Flight Booking',
        
                        'id': `${getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode} - ${getItineraries[0]?.Leg[0]?.Segements[getItineraries[0]?.Leg[0].Segements?.length -1]?.arrivalAirport?.airportCode} - ${getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport?.airportCode}`,
        
                        'price': getItineraries[0]?.TotalPrice,
        
                        'category': 'Flight'
        
                    }]
        
                }
        
            }
        
        });
        // console.log(window.dataLayer);
      

    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/SaveItinerary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SearchId: searchId,
        ItineraryKey: bookingDetails.itineraryKey,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.responseModel.bookingRef) {
          setBookingRef(data.responseModel.bookingRef);
          BookingRef = data.responseModel.bookingRef;
        }
        setIsloading(false);

        navigate(`/${locale}/passenger_information/${BookingRef}`);
      })
      .catch((err) => console.log(err));
    }
  };

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: 'short',
    });
    return dt;
  };

  const getFormattedTime = (time) => {};

  const makeLowerCase = (s) => {
    return s?.toLowerCase();
  };

  const closeFlightDetails = () => {
    setRenderFlightDetails(false);
    setIsFlightDetailsOpen(false);
    setIsFlightDetailsOpen(false);
  };

  const [singleItrr, setSingleItrr] = useState([]);

  const makeItiniraryArray = () => {
  let bigItinArr = [];
  let bigItinArrr = [];
  if(!isMobile)
  {
    for (let i = 0; i < getItineraries[0].Leg.length; i++) {
      for (let j = 0; j < getItineraries[0].Leg[i].Segements.length; j++) {
       if(tripType === 0){
        bigItinArr.push({
          timeTest: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          date: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time_elapsed: [
            getItineraries[0].Leg[i].Segements[j].flights[0].elapsedTime,
          ],
          airport_code: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportCode,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportCode,
          ],
          airport_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportName,
          ],
          city_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.cityName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.cityName,
          ],
          country: [
            getItineraries[0].Leg[0].Segements[j].departureAirport.countryName,
            getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName,
          ],
          airline_code: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
          ],
          flight_number: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
          ],
          aircraft_make: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          aircraft_model: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          cabin_class: [
            getItineraries[0].Leg[i].Segements[j].cabinCode,
            getItineraries[0].Leg[i].Segements[j].cabinCode,
          ],
          arrival_next_day: [
            getItineraries[0].Leg[i].Segements[j].flights[0]
              .arrivalDateAdjustment,
          ],
          baggage: [
            getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
          ],
          baggageB: [
            getItineraries[0]?.passengers[0]?.baggage[1]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[1]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[1]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[1]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[1]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[1]?.pieceCount,
          ],
        });
       }
       else if(tripType === 0 && _SearchID){
          bigItinArr.push({
            timeTest: [
              getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
              getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
            ],
            time: [
              getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
              getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
            ],
            date: [
              getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
              getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
            ],
            time_elapsed: [
              getItineraries[0].Leg[i].Segements[j].flights[0].elapsedTime,
            ],
            airport_code: [
              getItineraries[0].Leg[i].Segements[j].departureAirport.airportCode,
              getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportCode,
            ],
            airport_name: [
              getItineraries[0].Leg[i].Segements[j].departureAirport.airportName,
              getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportName,
            ],
            city_name: [
              getItineraries[0].Leg[i].Segements[j].departureAirport.cityName,
              getItineraries[0].Leg[i].Segements[j].arrivalAirport.cityName,
            ],
            country: [
              getItineraries[0].Leg[0].Segements[j].departureAirport.countryName,
              getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName,
            ],
            airline_code: [
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
            ],
            flight_number: [
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier
                .operatingFlightNumber,
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier
                .operatingFlightNumber,
            ],
            aircraft_make: [
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            ],
            aircraft_model: [
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            ],
            cabin_class: [
              getItineraries[0].Leg[i].Segements[j].cabinCode,
              getItineraries[0].Leg[i].Segements[j].cabinCode,
            ],
            arrival_next_day: [
              getItineraries[0].Leg[i].Segements[j].flights[0]
                .arrivalDateAdjustment,
            ],
            baggage: [
              getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
              getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
              getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
              getItineraries[0]?.passengers[0]?.baggage[j]?.description[0],
              getItineraries[0]?.passengers[0]?.baggage[j]?.description[1],
              getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
            ],
            baggageB: [
              getItineraries[0]?.passengers[0]?.baggage[1]?.provisionType,
              getItineraries[0]?.passengers[0]?.baggage[1]?.weight,
              getItineraries[0]?.passengers[0]?.baggage[1]?.unit,
              getItineraries[0]?.passengers[0]?.baggage[1]?.description[0],
              getItineraries[0]?.passengers[0]?.baggage[1]?.description[1],
              getItineraries[0]?.passengers[0]?.baggage[1]?.pieceCount,
            ],
          });
         
       }
       else{
        bigItinArr.push({
          timeTest: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          date: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time_elapsed: [
            getItineraries[0].Leg[i].Segements[j].flights[0].elapsedTime,
          ],
          airport_code: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportCode,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportCode,
          ],
          country: [
            getItineraries[0].Leg[0].Segements[j].departureAirport.countryName,
            getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName,
          ],
          airport_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportName,
          ],
          city_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.cityName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.cityName,
          ],
          airline_code: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
          ],
          flight_number: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
          ],
          aircraft_make: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          aircraft_model: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          cabin_class: [
            getItineraries[0].Leg[i].Segements[j].cabinCode,
            getItineraries[0].Leg[i].Segements[j].cabinCode,
          ],
          arrival_next_day: [
            getItineraries[0].Leg[i].Segements[j].flights[0]
              .arrivalDateAdjustment,
          ],
          baggage: [
            getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
          ],
          baggageB: [
            getItineraries[0]?.passengers[0]?.baggage[2]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[2]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[2]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[2]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[2]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[2]?.pieceCount,
          ],
        });
       }
      }
    }
    setItinArr(bigItinArr);
  }

  if(isMobile){
    if(tripType === 0)
    {
      for(let j=0; j < getItineraries[0]?.Leg[0]?.Segements?.length; j++){
            bigItinArr.push({
              timeTest: [
                getItineraries[0].Leg[0].Segements[j].flights[0].departureDateTime,
                getItineraries[0].Leg[0].Segements[j].flights[0].arrivalDateTime,
              ],
              lastItem: [
                getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].flights[0].arrivalDateTime,
                getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportName,
                getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.cityName,
                getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName,
              ],
              arrivalDetails: [
                getItineraries[0].Leg[0].Segements[j].flights[0].arrivalTime,
              ],
              country: [
                getItineraries[0].Leg[0].Segements[j].departureAirport.countryName,
                getItineraries[0].Leg[0].Segements[j].arrivalAirport.countryName,
              ],
              time: [
                getItineraries[0].Leg[0].Segements[j].flights[0].departureDateTime,
                getItineraries[0].Leg[0].Segements[j].flights[0].arrivalDateTime,
              ],
              date: [
                getItineraries[0].Leg[0].Segements[j].flights[0].departureDateTime,
                getItineraries[0].Leg[0].Segements[j].flights[0].arrivalDateTime,
              ],
              time_elapsed: [
                getItineraries[0].Leg[0].Segements[j].flights[0].elapsedTime,
              ],
              airport_code: [
                getItineraries[0].Leg[0].Segements[j].departureAirport.airportCode,
                getItineraries[0].Leg[0].Segements[j].arrivalAirport.airportCode,
              ],
              airport_name: [
                getItineraries[0].Leg[0].Segements[j].departureAirport.airportName,
                getItineraries[0].Leg[0].Segements[j].arrivalAirport.airportName,
              ],
              city_name: [
                getItineraries[0].Leg[0].Segements[j].departureAirport.cityName,
                getItineraries[0].Leg[0].Segements[j].arrivalAirport.cityName,
              ],
              airline_code: [
                getItineraries[0].Leg[0].Segements[j].flights[0].carrier.marketing,
                getItineraries[0].Leg[0].Segements[j].flights[0].carrier.marketing,
              ],
              flight_number: [
                getItineraries[0].Leg[0].Segements[j].flights[0].carrier
                  .operatingFlightNumber,
                getItineraries[0].Leg[0].Segements[j].flights[0].carrier
                  .operatingFlightNumber,
              ],
              aircraft_make: [
                getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
                getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
              ],
              aircraft_model: [
                getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
                getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
              ],
              cabin_class: [
                getItineraries[0].Leg[0].Segements[j].cabinCode,
                getItineraries[0].Leg[0].Segements[j].cabinCode,
              ],
              arrival_next_day: [
                getItineraries[0].Leg[0].Segements[j].flights[0]
                  .arrivalDateAdjustment,
              ],
              baggage: [
                getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
                getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
                getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
                getItineraries[0]?.passengers[0]?.baggage[j]?.description[0]?.substring(getItineraries[0]?.passengers[0]?.baggage[j]?.description[0]?.indexOf("/") + 1),
                getItineraries[0]?.passengers[0]?.baggage[j]?.description[1]?.substring(getItineraries[0]?.passengers[0]?.baggage[j]?.description[1]?.indexOf("/") + 1),
                getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
              ],
              baggageB: [
                getItineraries[0]?.passengers[0]?.baggage[1]?.provisionType,
                getItineraries[0]?.passengers[0]?.baggage[1]?.weight,
                getItineraries[0]?.passengers[0]?.baggage[1]?.unit,
                getItineraries[0]?.passengers[0]?.baggage[1]?.description[0]?.substring(getItineraries[0]?.passengers[0]?.baggage[2]?.description[0]?.indexOf("/") + 1),
                getItineraries[0]?.passengers[0]?.baggage[1]?.description[1]?.substring(getItineraries[0]?.passengers[0]?.baggage[2]?.description[1]?.indexOf("/") + 1),
                getItineraries[0]?.passengers[0]?.baggage[1]?.pieceCount,
              ],
            });
        }
      setItinArr(bigItinArr);
    }
if(tripType === 1 && getItineraries[0]?.Leg[0])
      {
        for(let j=0; j < getItineraries[0]?.Leg[0]?.Segements?.length; j++){
              bigItinArr.push({
                timeTest: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].departureDateTime,
                  getItineraries[0].Leg[0].Segements[j].flights[0].arrivalDateTime,
                ],
                lastItem: [
                  getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].flights[0].arrivalDateTime,
                  getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.airportName,
                  getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.cityName,
                  getItineraries[0].Leg[0].Segements[getItineraries[0].Leg[0].Segements.length - 1].arrivalAirport.countryName,
                  getItineraries[0]?.Leg[0]?.Segements[j].flights[0].departureDateTime,
                ],
                country: [
                  // getItineraries[0].Leg[1] ?
                  // getItineraries[0]?.Leg[1]?.Segements[j]?.departureAirport?.countryName :
                  getItineraries[0]?.Leg[0]?.Segements[j]?.departureAirport?.countryName,
                  // getItineraries[0]?.Leg[1] ?
                  // getItineraries[0]?.Leg[1]?.Segements[j]?.arrivalAirport?.countryName :
                  getItineraries[0]?.Leg[0]?.Segements[j]?.arrivalAirport?.countryName,
                ],
                arrivalDetails: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].arrivalTime,
                ],
                time: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].departureDateTime,
                  getItineraries[0].Leg[0].Segements[j].flights[0].arrivalDateTime,
                ],
                date: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].departureDateTime,
                  getItineraries[0].Leg[0].Segements[j].flights[0].arrivalDateTime,
                ],
                time_elapsed: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].elapsedTime,
                ],
                airport_code: [
                  getItineraries[0].Leg[0].Segements[j].departureAirport.airportCode,
                  getItineraries[0].Leg[0].Segements[j].arrivalAirport.airportCode,
                ],
                airport_name: [
                  getItineraries[0].Leg[0].Segements[j].departureAirport.airportName,
                  getItineraries[0].Leg[0].Segements[j].arrivalAirport.airportName,
                ],
                city_name: [
                  getItineraries[0].Leg[0].Segements[j].departureAirport.cityName,
                  getItineraries[0].Leg[0].Segements[j].arrivalAirport.cityName,
                ],
                airline_code: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].carrier.marketing,
                  getItineraries[0].Leg[0].Segements[j].flights[0].carrier.marketing,
                ],
                flight_number: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].carrier
                    .operatingFlightNumber,
                  getItineraries[0].Leg[0].Segements[j].flights[0].carrier
                    .operatingFlightNumber,
                ],
                aircraft_make: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
                  getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
                ],
                aircraft_model: [
                  getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
                  getItineraries[0].Leg[0].Segements[j].flights[0].equipmentCode,
                ],
                cabin_class: [
                  getItineraries[0].Leg[0].Segements[j].cabinCode,
                  getItineraries[0].Leg[0].Segements[j].cabinCode,
                ],
                arrival_next_day: [
                  getItineraries[0].Leg[0].Segements[j].flights[0]
                    .arrivalDateAdjustment,
                ],
                baggage: [
                  getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
                  getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
                  getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
                  getItineraries[0]?.passengers[0]?.baggage[j]?.description[0]?.substring(getItineraries[0]?.passengers[0]?.baggage[j]?.description[0]?.indexOf("/") + 1),
                  getItineraries[0]?.passengers[0]?.baggage[j]?.description[1]?.substring(getItineraries[0]?.passengers[0]?.baggage[j]?.description[1]?.indexOf("/") + 1),
                  getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
                ],
                baggageB: [
                  getItineraries[0]?.passengers[0]?.baggage[2]?.provisionType,
                  getItineraries[0]?.passengers[0]?.baggage[2]?.weight,
                  getItineraries[0]?.passengers[0]?.baggage[2]?.unit,
                  getItineraries[0]?.passengers[0]?.baggage[2]?.description[0]?.substring(getItineraries[0]?.passengers[0]?.baggage[2]?.description[0]?.indexOf("/") + 1),
                  getItineraries[0]?.passengers[0]?.baggage[2]?.description[1]?.substring(getItineraries[0]?.passengers[0]?.baggage[2]?.description[1]?.indexOf("/") + 1),
                  getItineraries[0]?.passengers[0]?.baggage[2]?.pieceCount,
                ],
              });
          }
        setItinArr(bigItinArr);
      }



      // for (let i = 0; i < getItineraries[0].Leg.length; i++) {
   
     if(tripType === 1){
      for (let j = 0; j < getItineraries[0]?.Leg[1]?.Segements?.length; j++) {
        bigItinArrr.push({
          timeTest: [
            getItineraries[0].Leg[1].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[1].Segements[j].flights[0].arrivalDateTime,
          ],
          time: [
            getItineraries[0].Leg[1].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[1].Segements[j].flights[0].arrivalDateTime,
          ],

          lastItem: [
            getItineraries[0]?.Leg[1]?.Segements[getItineraries[0]?.Leg[1]?.Segements?.length - 1]?.flights[0]?.arrivalDateTime,
            getItineraries[0]?.Leg[1]?.Segements[getItineraries[0]?.Leg[1]?.Segements?.length - 1]?.arrivalAirport?.airportName,
            getItineraries[0]?.Leg[1]?.Segements[getItineraries[0]?.Leg[1]?.Segements?.length - 1]?.arrivalAirport?.cityName,
            getItineraries[0]?.Leg[1]?.Segements[getItineraries[0]?.Leg[1]?.Segements?.length - 1]?.arrivalAirport?.countryName,
            getItineraries[0]?.Leg[1]?.Segements[j]?.flights[0]?.departureDateTime,
          ],
          arrivalDetails: [
            getItineraries[0]?.Leg[0]?.Segements[j]?.flights[0]?.arrivalTime,
          ],
          
          country: [
            getItineraries[0].Leg[1].Segements[j].departureAirport.countryName,
            getItineraries[0].Leg[1].Segements[j].arrivalAirport.countryName,
          ],
          date: [
            getItineraries[0].Leg[1].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[1].Segements[j].flights[0].arrivalDateTime,
          ],
          time_elapsed: [
            getItineraries[0].Leg[1].Segements[j].flights[0].elapsedTime,
          ],
          airport_code: [
            getItineraries[0].Leg[1].Segements[j].departureAirport.airportCode,
            getItineraries[0].Leg[1].Segements[j].arrivalAirport.airportCode,
          ],
          airport_name: [
            getItineraries[0].Leg[1].Segements[j].departureAirport.airportName,
            getItineraries[0].Leg[1].Segements[j].arrivalAirport.airportName,
          ],
          city_name: [
            getItineraries[0].Leg[1].Segements[j].departureAirport.cityName,
            getItineraries[0].Leg[1].Segements[j].arrivalAirport.cityName,
          ],
          airline_code: [
            getItineraries[0].Leg[1].Segements[j].flights[0].carrier.marketing,
            getItineraries[0].Leg[1].Segements[j].flights[0].carrier.marketing,
          ],
          flight_number: [
            getItineraries[0].Leg[1].Segements[j].flights[0].carrier
              .operatingFlightNumber,
            getItineraries[0].Leg[1].Segements[j].flights[0].carrier
              .operatingFlightNumber,
          ],
          aircraft_make: [
            getItineraries[0].Leg[1].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[1].Segements[j].flights[0].equipmentCode,
          ],
          aircraft_model: [
            getItineraries[0].Leg[1].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[1].Segements[j].flights[0].equipmentCode,
          ],
          cabin_class: [
            getItineraries[0].Leg[1].Segements[j].cabinCode,
            getItineraries[0].Leg[1].Segements[j].cabinCode,
          ],
          arrival_next_day: [
            getItineraries[0].Leg[1].Segements[j].flights[0]
              .arrivalDateAdjustment,
          ],
          baggage: [
            getItineraries[0]?.passengers[0]?.baggage[1]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[1]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[1]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[1]?.description[0]?.substring(getItineraries[0]?.passengers[0]?.baggage[1]?.description[0]?.indexOf("/") + 1),
            getItineraries[0]?.passengers[0]?.baggage[1]?.description[1]?.substring(getItineraries[0]?.passengers[0]?.baggage[1]?.description[1]?.indexOf("/") + 1),
            getItineraries[0]?.passengers[0]?.baggage[1]?.pieceCount,
          ],
          baggageB: [
            getItineraries[0]?.passengers[0]?.baggage[2]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[2]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[2]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[2]?.description[0]?.substring(getItineraries[0]?.passengers[0]?.baggage[2]?.description[0]?.indexOf("/") + 1),
            getItineraries[0]?.passengers[0]?.baggage[2]?.description[1]?.substring(getItineraries[0]?.passengers[0]?.baggage[2]?.description[1]?.indexOf("/") + 1),
            getItineraries[0]?.passengers[0]?.baggage[2]?.pieceCount,
          ],
        });
      }
      // }
      setSingleItrr(bigItinArrr);
     }

  }

  };

  const calcPassengersQty = (psngrArray) => {
    let totalPsngrs = 0;
    for (let i = 0; i < psngrArray?.length; i++) {
      totalPsngrs += psngrArray[i].qty;
    }
    return totalPsngrs;
  };

  useEffect(() => {
    makeItiniraryArray();
    if (showBaggageInfoTab === true) {
      setItineraryTab(false);
      setBaggageTab(true);
      setFareTab(false);
    }
  }, []);

  const { holdSearchId } = useParams();

  let pathName = window.location.pathname.split("/");
  pathName = pathName[2];

  const searchResult = useLocation().search;
  const _SearchID = new URLSearchParams(searchResult).get("SearchId");

  const { t, i18n, ready } = useTranslation();

  let localePath;
  const [text, setText] = useState(t('Review your trip'));
  useEffect(() => {
     localePath = window.location.pathname.split("/");
    if (
      localePath[2] === "passenger_information" ||
      localePath[2] === "payment_details"
    ) {
    
      setText(t('Review your trip'));
    }
  }, []);


const scrollFunc = () => {
    const body = document.querySelector('#root');

    body.scrollIntoView({ top: 0, behavior: 
      'smooth' 
    });

}

const getID =(id, val) => {
  // console.log(id);
  // console.log(val);
}

const [mobItenTab, setMobItenTab] = useState(true);



const activeFareTab = () => {
  setFareTab(true);
  setMobItenTab(false);
  setFareTab2(false);
  setBaggageTab(false);
  setBaggageTab2(false);
  // showFareTab();
  document.getElementsByTagName('body')[0].scrollTo({ top: 0, behavior: 
    'smooth' 
  });
  setText(t('Cancel & date change'));
  document.getElementById('bookNowBtn').style.display = "none";
}


const activeFareTab2 = () => {
  setFareTab2(true);
  setMobItenTab(false);
  setFareTab(false);
  setBaggageTab(false);
  setBaggageTab2(false);
  document.getElementsByTagName('body')[0].scrollTo({ top: 0, behavior: 
    'smooth' 
  });
  setText(t('Cancel & date change'));
  document.getElementById('bookNowBtn').style.display = "none";

}


useEffect(() => {
  if(document.getElementById('note1') && document.getElementById('note2')){
    document.getElementById('note1').style.display = "none";
    document.getElementById('note2').style.display = "none";
  }

  setTimeout(() => {
    if(document.getElementById('note1') && document.getElementById('note2')){
      document.getElementById('note1').style.display = "block";
      document.getElementById('note2').style.display = "block";
    }
  }, [1000])

  
}, [fareTab, fareTab2]);

const ActiveBaggageTab = () => {

  setFareTab(false);
  setMobItenTab(false);
  setFareTab2(false);
  setBaggageTab(true);
  setBaggageTab2(false);

//   showBaggageTab();
  document
  .getElementsByClassName("flight_detail_mobile_header")[0]
  .scrollTo({ top: 0, behavior: "smooth" });
  setText(t('Baggage Details'));
  document.getElementById('bookNowBtn').style.display = "none";
}


const ActiveBaggageTab2 = () => {
  setFareTab(false);
  setMobItenTab(false);
  setFareTab2(false);
  setBaggageTab2(true);
  setBaggageTab(false);

  setText(t('Baggage Details'));
  document.getElementById('bookNowBtn').style.display = "none";
}


const closeFareTab = (id) => {

  setFareTab(false);
  setMobItenTab(true);
  setFareTab2(false);
  setBaggageTab(false);
  setBaggageTab2(false);
  document.getElementById('bookNowBtn').style.display = "block";

  document
  .getElementsByClassName("flight_detail_mobile_header")[0]
  .scrollTo({ top: 0, behavior: "smooth" });
  setText(t('Review your trip'));
  setTimeout(() => {
    window.location.href = "#cancel1";
   }, 5);
   setTimeout(() => {
    let path = window.location.pathname.split('#');
         navigate(path[0]);
       }, 2000) 
}

const closeFareTab2 = (id) => {

  setFareTab(false);
  setMobItenTab(true);
  setFareTab2(false);
  setBaggageTab(false);
  setBaggageTab2(false);

  document.getElementById('bookNowBtn').style.display = "block";

  document
  .getElementsByClassName("flight_detail_mobile_header")[0]
  .scrollTo({ top: 0, behavior: "smooth" });
  setText(t('Review your trip'));
  setTimeout(() => {
    window.location.href = "#cancel2";
   }, 100)
   setTimeout(() => {
let path = window.location.pathname.split('#');
     navigate(path[0]);
   }, 2000)
}

const closeBaggageTab = (id) => {

  setFareTab(false);
  setMobItenTab(true);
  setFareTab2(false);
  setBaggageTab(false);
  setBaggageTab2(false);
  document.getElementById('bookNowBtn').style.display = "block";

  document
  .getElementsByClassName("flight_detail_mobile_header")[0]
  .scrollTo({ top: 0, behavior: "smooth" });
  setText(t('Review your trip'));

  setTimeout(() => {
    window.location.href = "#baggage1";
    // let path = window.location.pathname.split('#');
    //  navigate(path[0]);
   }, 0)
   setTimeout(() => {
    let path = window.location.pathname.split('#');
         navigate(path[0]);
       }, 2000)
}

const closeBaggageTab2 = (id) => {
  setFareTab(false);
  setMobItenTab(true);
  setFareTab2(false);
  setBaggageTab(false);
  setBaggageTab2(false);
  document.getElementById('bookNowBtn').style.display = "block";

  document
  .getElementsByClassName("flight_detail_mobile_header")[0]
  .scrollTo({ top: 0, behavior: "smooth" });
  setText(t('Review your trip'));

  setTimeout(() => {
   window.location.href = "#baggage2";
    // let path = window.location.pathname.split('#');
    //  navigate(path[0]);
   }, 1);
   setTimeout(() => {
    let path = window.location.pathname.split('#');
         navigate(path[0]);
       }, 2000) 
}



const getTotalDuration = (s) => {
  let flight = [];
  let totalTime = [];
  for (let i = 0; i < s.length; i++) {
    flight.push(s[i]?.flights[0]?.elapsedTime);
  }
  for (let j = 0; j < flight?.length; j++) {
    totalTime.push(flight[j]);
  }
  return convertMinsToHrsMins(totalTime.reduce((a, b) => a + b, 0));
};


const [totalFare, setTotalFare] = useState();
const [totalTax , setTotalTax] = useState();



const calcTotalFare = (psngrArray) => {
  let totalfare = 0;
  for (let i = 0; i < psngrArray?.length; i++) {
    totalfare += psngrArray[i].price.totalFareAmount;
  }
  return Number(totalfare).toFixed(2);
};

const calcTotalTax = (psngrArray) => {
  let totalTax = 0;
  for (let i = 0; i < psngrArray?.length; i++) {
    totalTax += psngrArray[i].price.totalTaxAmount;
  }
  return Number(totalTax).toFixed(2);
};


// console.log(itinArr);

// console.log(bookingDetails);



const waitingTime = (val1, val2) => {
 let val_1 = val1.split(':')[0];
 let m_val_1 = val1.split(':')[1];
 let val_2 = val2.split(':')[0];
 let m_val_2 = val2.split(':')[1];

 let hrs = parseInt(val2) - parseInt(val1);
 let minutes = parseInt(m_val_2) - parseInt(m_val_1);
 console.log(val1);
 console.log(val2);
//  console.log(hrs, minutes);
// if(hrs < 0){
  // hrs = hrs?.replace(/-/g, '');
// }
 let newTime = `${hrs}h : ${minutes}m`;
 return newTime;
           
}

  return (
    <div className="flight_detials_popup" dir={locale === "ar" ? "rtl" : "ltr"}>
      

      {isMobile &&
      <div className="flight_detail_body">
 
 {mobItenTab === true &&
 <div style={locale === "ar" && tripType !== 1 ? {marginBottom:"100px"} : {marginBottom : "50px"}}>
      <div className="flight_detials_pop_cta" dir={locale === "ar" ? "rtl" : "ltr"}>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8 col-7">
          <h2> 

            {
                  getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport
                    ?.cityName
                }{" "} {t('to') + ' '}
               {
                  getItineraries[0]?.Leg[0]?.Segements[
                    getItineraries[0]?.Leg[0]?.Segements?.length - 1
                  ]?.arrivalAirport?.cityName
                }
                </h2>
          <p>{t('Total Duration')}{' '}
          {getTotalDuration(getItineraries[0]?.Leg[0]?.Segements)} 
            
             {/* {convertMinsToHrsMins(getItineraries[0]?.Leg[0]?.Segements[
                    getItineraries[0]?.Leg[0]?.Segements?.length - 1
                  ]?.flights[0].elapsedTime)} */}
                  </p>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-5 text-right">
            <p>
            {calcPassengersQty(getItineraries[0]?.passengers)} {t("Traveler")}
                {calcPassengersQty(getItineraries[0]?.passengers) > 1
                  ? "s"
                  : null}
            </p>
            <p style={{marginTop:"7px"}}>
            {holdSearchId
                  ? bookingDetails?.goingDepartureDate
                  : getFormattedDate(
                      totalPass?.Leg[0]?.Segements[0]?.flights[0]?.departureDateTime
                        ?.split(" ")[0]
                        .substring(0, 10)
                    )}
                    </p>
          </div>
        </div>
      </div>   

      
        
      <div className="flight_detials_itinerary" style={{marginTop:"150px"}}>
        {itinArr.map((itinAr, index) => {
              return (
                <>
                <div className="fd_row" key={index}>
                  <div className="fd_col">
                    {locale === "en" ? (
                      <strong>
                        {itinAr.timeTest[0].split(" ")[0].substring(16, 11)} 

                      </strong>
                    ) : (
                      <strong>
                        {itinAr.timeTest[0].split(" ")[0].substring(16, 11)}
                      </strong>
                    )}
                    <br />
                    <span>{getFormattedDate(itinAr.date[0]).substring(5,17)}</span>
                    {/* <span className="leg_time">
                      {convertMinsToHrsMins(itinAr.time_elapsed[0])}
                    </span> */}
                    <br />
                    <div>
                    <span className="leg_time mt-5">
                      {convertMinsToHrsMins(itinAr.time_elapsed[0])}
                    </span>
                    <br />
                    <br/>
                    <br />
                    {
                      <strong>{itinAr.arrivalDetails[0].substring(0, 5) !== itinAr.timeTest[0].split(" ")[0].substring(16, 11) ? itinAr.arrivalDetails[0].substring(0, 5) : null}</strong>
                    }
                    <br />
                    <span>{getFormattedDate(itinAr.date[0]).substring(5,17)}</span>
                    <br />
                      </div>
                  </div>
                  <div className="fd_col point">&nbsp;</div>
                  <div className="fd_col">
                    <strong style={{ position: "relative" }}>
                      {itinAr.airport_name[0]} ({itinAr.airport_code[0]}) 
                      {/* →{" "}
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]}) */}
                      {itinAr.arrival_next_day[0] > 0 ? (
                        <span
                          className="nextDayArrival"
                          style={locale === "en" ? {
                            top: "auto",
                            left: "auto",
                            verticalAlign: "text-top",
                            display: "inline-block",
                            lineHeight: "1",
                            margin: "-2.5px 5px 0 5px",
                          }
                        :  
                        {
                          top: "auto",
                          left: "auto",
                          verticalAlign: "text-top",
                          display: "inline-block",
                          lineHeight: "1",
                          margin: "-2.5px 35px 0 5px",
                        }
                        }
                          title={
                            `Arrival after + ` +
                            itinAr.arrival_next_day[0] +
                            ` Day`
                          }
                        >
                          {locale === "en" && "+"}
                          {itinAr.arrival_next_day[0]}
                          {locale === "ar" && "+"}
                        </span>
                      ) : (
                        ""
                      )}
                    </strong>
                    <br />
                    <span className="muted">{itinAr.city_name[0]}, {itinAr.country[0]}</span>
                    <br />
                    <span>
                      {itinAr.arrival_next_day[0] > 0
                        ? `${t("Next Day Arrival")} \n`
                        : ""}
                    </span>
                    <br />
                    {_SearchID ? (
                      <div
                        className="fd_a_logo"
                        style={{
                          backgroundImage:
                            "url(/img/airlines/" +
                            itinAr.airline_code[0] +
                            ".png)",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="fd_a_logo"
                        style={{
                          backgroundImage:
                          `url(${window.location.origin}/img/airlines/${itinAr.airline_code[0]}.png)`,
                            // "url(../../img/airlines/" +
                            // itinAr.airline_code[0] +
                            // ".png)",
                        }}
                      ></div>
                    )}

                    <span className="logo-descp">
                      {/* {itinAr.airline_code[0]}  */}
                      {itinAr.airline_code[0]}-
                      {itinAr.flight_number[0]}
                      <br />
                     {t(itinAr.cabin_class[0])}
                     <br />
                     {itinAr.aircraft_model[0]}
                    </span>
                    <br />
                    <br />
                    <strong>
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]}) 
                      </strong>
                      <br />
                    <span className="muted" >{itinAr.city_name[1]}, {itinAr.country[1]}</span>
                  </div>
                </div>
                {/* {getItineraries[0]?.Leg[0]?.Segements.length > 0 &&
                 getItineraries[0]?.Leg[0]?.Segements[getItineraries[0]?.Leg[0]?.Segements.len]?.departureAirport
                    ?.airportName && */}
          
          <a href="#baggage1" ></a>
          {itinAr.lastItem[1] !== itinAr.airport_name[1] ?
                <div className="fd_row" >
                  
                  <div className="fd_col">
                  {/* <p className="waitingTime">{waitingTime(itinAr?.timeTest[1]?.split(" ")[0]?.substring(16, 11), itinArr[1]?.timeTest[0]?.split(" ")[0]?.substring(16, 11))}</p> */}
                  {/* <p className="waitingTime">{waitingTime(itinAr?.timeTest[1]?.split(" ")[0]?.substring(16, 11), itinArr[1]?.lastItem[4]?.split(" ")[0]?.substring(16, 11))}</p> */}
                  </div>
                  <div className="fd_col point">
                    &nbsp;
                  </div>
                  <div className="fd_col">
                  <p className="waitingTime">{t('Waiting Time in')} {' '} {itinAr.city_name[1]} </p>
                  </div>
                </div>
                :
                <div className="fd_row">
                  <div className="fd_col">
                   
                  </div>
                  <div className="fd_col point">
                    &nbsp;
                  </div>
                  <div className="fd_col">
                  </div>
                </div>
        }



        {/* } */}
       {/* {getItineraries[0].Leg[0].Segements.length < 1 &&
                  <div className="fd_row">
                  <div className="fd_col">

                  </div>
                  <div className="fd_col point">

                  </div>
                  <div className="fd_col">
                  <strong style={{ position: "relative" }}>
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]}) 
                  </strong>
                  </div>
                </div>
                } */}
               </>
              );
            })} 
      </div>  


      <div className="flight_details_baggage" id="baggage1" dir={locale === "ar" ? "rtl" : "ltr"}>
      <div className="row">
        <div className="col-sm-2 col-md-2 col-lg-2 col-2  text-center">
        <img
                        className="icon"
                        src={window.location.origin + "/img/travel-bag-1.svg"}
                        alt=""
                      />            
        </div>

        <div className="col-sm-4 col-md-4 col-lg-4 col-4">
        <p className="p green-text font-bold font-17" id="cancel1">{t('Baggage')}</p>  
        <a href="#cancel1"></a>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-6 text-right">
        <img
                        className="icon"
                        onClick={() => {ActiveBaggageTab()
                        }}
                        src={MoreInfoIcon}
                        alt=""
                      />     
        </div>
      </div>
      {
                            itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[1] > 0 ||
                            itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[3] !== null && itinArr[0]?.baggage[5] > 0 ?
      <div className="row mt-3">
        <div className="col-sm-2 col-2  text-center">
          <img src={GreenTick} style={{height:"24px"}} />
        </div>

        <div className="col-10 col-sm-10 mt-1">
        {
                            itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[1] > 0 ||
                            itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[3] !== null && itinArr[0]?.baggage[5] > 0 ?
                            <span>
                         <span className="font-14">
                              {itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[1] && itinArr[0]?.baggage[1] > 1
                                ? t(itinArr[0]?.baggage[1]) + " " + itinArr[0]?.baggage[2] + " Checkin Baggage"
                                : 
                                itinArr[0]?.baggage[3] ?
                                makeLowerCase(itinArr[0]?.baggage[3]) + " Checkin Baggage" 
                                :
                                null
                                }
                                {" "}
                               
                            </span>    
                                <br />
                                {/* <span className="muted font-11" style={{opacity:"0.5"}}>
                                {itinArr[0]?.city_name[0]}, {" "}
                                {
                  getItineraries[0]?.Leg[0]?.Segements[
                    getItineraries[0]?.Leg[0]?.Segements?.length - 1
                  ]?.arrivalAirport?.cityName
                }
                              
                              {" "}
                              </span> */}
                              <span className="muted font-11" style={{opacity:"0.5"}}>{
                                
  itinArr[0]?.baggage[5] > 0 && itinArr[0]?.baggage[5] < 2 ?
  itinArr[0]?.baggage[5] + " " + t("Piece Only")
  :
  itinArr[0]?.baggage[5] > 1 ?
     itinArr[0]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                            
                                </span>
                          :
                          null
                            }
        </div>


      </div>  
      :
      null }

      <div className="row mt-2">
      <div className="col-sm-2 col-2  text-center">
          <img src={GreenTick} style={{height:"24px"}} />
        </div>
{
  itinArr[0]?.baggageB[1] > 0 || itinArr[0]?.baggageB[2] != null || itinArr[0]?.baggageB[3] || itinArr[0]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  itinArr[0]?.baggageB[1] > 0 || itinArr[0]?.baggageB[2] != null ?
  itinArr[0]?.baggageB[1] + " " + makeLowerCase(itinArr[0]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
     itinArr[0]?.baggageB[3] || itinArr[0]?.baggageB[3] !== undefined 
     ? makeLowerCase(itinArr[0]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[0]?.baggageB[5] > 0 && itinArr[0]?.baggageB[5] < 2 ?
  itinArr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[0]?.baggageB[5] > 1 ?
     itinArr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  itinArr[0]?.baggageB[5] > 0 ?
  itinArr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[0]?.baggageB[5] > 1 ?
     itinArr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}        




        </div>
      </div>


      <div className="flight_details_pop_baggage" id="cancel3" >
      <div className="row">
        <div className="col-sm-2 col-md-2 col-lg-2 col-2 text-center">
        <img
                        className="icon"
                        src={EditPen}
                        alt=""
                        style={{height:"20px"}}
                      />  
                      <a href="#cancel3"></a>          
        </div>

        <div className="col-sm-8 col-md-8 col-lg-8 col-8">
        <p className="p  green-text font-bold font-17">{t('Cancel & date change')}</p>  
        </div>

        <div className="col-sm-2 col-md-2 col-lg-2 col-2 text-right">
        <img
                        className="icon"
                        src={MoreInfoIcon}
                        alt=""
                        onClick={() => {
                          activeFareTab();
                          getID('cancel1', true);
                        }}
                      />     
        </div>
      </div>

      <div className="row mt-3">
        <div className={locale === "en" ? "col-sm-2 col-2 text-center " : "col-sm-2 col-2 text-center "}>
          <img src={getItineraries[0]?.passengers[0]?.refundability ===
            "REFUNDABLE" ? GreenTick : RedCross} style={{height:"24px"}} />
          <br />
          <img src={RedCross} className="mt-3" style={{height:"24px"}} />
        </div>

        <div className="col-10 col-sm-10">
          <p style={{fontSize:"16px", marginTop:"5px"}}>
          {getItineraries[0]?.passengers[0]?.refundability ===
            "REFUNDABLE" ? (
              <span style={{fontSize:"16px"}}>{t("Refundable")}</span>
            ) : (
              <span style={{fontSize:"16px"}}>{t('Non-refundable fare')}</span>
            )}
            </p>
          <p style={{fontSize:"16px", marginTop:"23px"}}>{t('Non-changeable fare')}</p>
        </div>
      </div>  
      </div>
</div>
  }
      {fareTab === true ?
      <div className="flight_detials_fare" id="cancellation" style={{height:"auto", marginBottom:"50px"}}>
        <div className="fd_row">
        <a href="#cancellation1"></a>
              <strong>{t("Fare Rules")}</strong>
              <div className="fd_col">
                <ul>
                  <li>
                    <div>{t("Cancellation Fee")}</div>
                    {/* <div /> */}
                  </li>
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                       }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.goingArrivalAirport
                        :
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                        ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }
                    </div>
                    <div>
                      {currency} 110.00 {t("Cancellation Before Flight")}
                    </div>
                  </li>
                  {/* <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails.goingArrivalAirport
                        : totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : 
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                            ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                        }{" "}
                    </div>
                    <div>
                      {currency} 220.00 {t("Cancellation After Flight")}
                    </div>
                  </li> */}
                </ul>
                <p>{t("No Show Charges")}</p>
              </div>
            </div>
      <div className="fd_row">
        <div className="fd_col">
          <ul>
            <li>
              <div>{t("Date Change Fee")}</div>
              <div />
            </li>
            <li>
              <div>
                {holdSearchId
                ?
                bookingDetails?.GoingDepartureAirport
                :
                 totalPass?.Leg[0]?.Segements[0]?.departureAirport
                      ?.airportCode
                }{" "}
                -{" "}
                {holdSearchId 
                  ?
                  bookingDetails?.goingArrivalAirport
                  :
                  tripType === 1 ?
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport
                      ?.airportCode
                   :
                   totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                   ?.airportCode   
                  }
              </div>
              <div>
                {currency} 110.00 {t("Date Change Fee Description")}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p className="note" id="note1">
        <strong>{t("Please Note")}: </strong>
        {t("Note Description1")}.
      </p>
      <p className="note" id="note2">{t("Note Description2")}.</p>
    </div>
    :
    null
      }

{fareTab2 === true ?
      <div className="flight_detials_fare" id="cancellation2" style={{height:"auto", marginBottom:"50px"}}>
        <div className="fd_row">
        <a href="#cancellation2" ></a>
              <strong>{t("Fare Rules")}</strong>
              <div className="fd_col">
                <ul>
                  <li>
                    <div>{t("Cancellation Fee")}</div>
                    {/* <div /> */}
                  </li>
                  {/* <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                       }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.goingArrivalAirport
                        :
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                        ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }
                    </div>
                    <div>
                      {currency} 110.00 {t("Cancellation Before Flight")}
                    </div>
                  </li> */}
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails.goingArrivalAirport
                        : totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : 
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                            ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                        }{" "}
                    </div>
                    <div>
                      {currency} 220.00 {t("Cancellation After Flight")}
                    </div>
                  </li>
                </ul>
                <p>{t("No Show Charges")}</p>
              </div>
            </div>
      <div className="fd_row">
        <div className="fd_col">
          <ul>
            <li>
              <div>{t("Date Change Fee")}</div>
              <div />
            </li>
            <li>
              <div>
                {holdSearchId
                ?
                bookingDetails?.GoingDepartureAirport
                :
                 totalPass?.Leg[0]?.Segements[0]?.departureAirport
                      ?.airportCode
                }{" "}
                -{" "}
                {holdSearchId 
                  ?
                  bookingDetails?.goingArrivalAirport
                  :
                  tripType === 1 ?
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport
                      ?.airportCode
                   :
                   totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                   ?.airportCode   
                  }
              </div>
              <div>
                {currency} 110.00 {t("Date Change Fee Description")}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p className="note" id="note1">
        <strong>{t("Please Note")}: </strong>
        {t("Note Description1")}.
      </p>
      <p className="note" id="note2">{t("Note Description2")}.</p>
    </div>
    :
    null
      }

      {
        // tripType === 1 && fareTab === false && fareTab2 === false ?
        tripType === 1 && mobItenTab === true ?
        <div>
        <div className="flight_detials_pop_ctaa">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
          <h2>

             {
                  getItineraries[0]?.Leg[1]?.Segements[0]?.departureAirport
                    ?.cityName
                }{" "} {t('to') + ' '} 
               {
                  getItineraries[0]?.Leg[1]?.Segements[
                    getItineraries[0]?.Leg[1]?.Segements?.length - 1
                  ]?.arrivalAirport?.cityName
                }
                </h2>
          <p>{t('Total Duration')} {' '}
          {getTotalDuration(getItineraries[0].Leg[1].Segements)} 
                  </p>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
            <p>
            {calcPassengersQty(getItineraries[0]?.passengers)} {t("Traveler")}
                {calcPassengersQty(getItineraries[0]?.passengers) > 1
                  ? "s"
                  : null}

            </p>
            <p style={{marginTop:"7px"}}>
            {holdSearchId
                  ? bookingDetails?.ReturnDepartureDate
                  : getFormattedDate(
                      totalPass?.Leg[1]?.Segements[0]?.flights[0]?.departureDateTime
                        ?.split(" ")[0]
                        .substring(0, 10)
                    )}
                    </p>
          </div>
        </div>
      </div>   
        

      <div className="flight_detials_itinerary">
            {singleItrr.map((itinAr, index) => {
              return (
                <>
                <div className="fd_row" key={index}>
                  <div className="fd_col">
                    {locale === "en" ? (
                      <strong>
                        {itinAr.timeTest[0].split(" ")[0].substring(16, 11)} 

                      </strong>
                    ) : (
                      <strong>
                        {itinAr.timeTest[0].split(" ")[0].substring(16, 11)}
                      </strong>
                    )}
                    <br />
                    <span>{getFormattedDate(itinAr.date[0]).substring(5,17)}</span>
                    {/* <span className="leg_time">
                      {convertMinsToHrsMins(itinAr.time_elapsed[0])}
                    </span> */}
                    <br />
                    <div>
                    <span className="leg_time mt-5">
                      {convertMinsToHrsMins(itinAr.time_elapsed[0])}
                    </span>
                    <br />
                    <br/>
                    <br />
                    <strong>{itinAr.timeTest[1].split(" ")[0].substring(16, 11)}</strong>
                    <br />
                    <span>{getFormattedDate(itinAr.date[0]).substring(5,17)}</span>
                    <br />
                      </div>
                  </div>
                  <div className="fd_col point">&nbsp;</div>
                  <div className="fd_col">
                    <strong style={{ position: "relative" }}>
                      {itinAr.airport_name[0]} ({itinAr.airport_code[0]}) 
                      {/* →{" "}
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]}) */}
                      {itinAr.arrival_next_day[0] > 0 ? (
                        <span
                          className="nextDayArrival"
                          style={locale === "en" ? {
                            top: "auto",
                            left: "auto",
                            verticalAlign: "text-top",
                            display: "inline-block",
                            lineHeight: "1",
                            margin: "-2.5px 5px 0 5px",
                          }
                        :  
                        {
                          top: "auto",
                          left: "auto",
                          verticalAlign: "text-top",
                          display: "inline-block",
                          lineHeight: "1",
                          margin: "-2.5px 35px 0 5px",
                        }
                        }
                          title={
                            `Arrival after + ` +
                            itinAr.arrival_next_day[0] +
                            ` Day`
                          }
                        >
                          {locale === "en" && "+"}
                          {itinAr.arrival_next_day[0]}
                          {locale === "ar" && "+"}
                        </span>
                      ) : (
                        ""
                      )}
                    </strong>
                    <br />
                    <span className="muted">{itinAr.city_name[0]}, {itinAr.country[0]}</span>
                    <br />
                    <span>
                      {itinAr.arrival_next_day[0] > 0
                        ? `${t("Next Day Arrival")} \n`
                        : ""}
                    </span>
                    <br />
                    {_SearchID ? (
                      <div
                        className="fd_a_logo"
                        style={{
                          backgroundImage:
                            "url(/img/airlines/" +
                            itinAr.airline_code[0] +
                            ".png)",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="fd_a_logo"
                        style={{
                          backgroundImage:
                          `url(${window.location.origin}/img/airlines/${itinAr.airline_code[0]}.png)`,
                            // "url(../../img/airlines/" +
                            // itinAr.airline_code[0] +
                            // ".png)",
                        }}
                      ></div>
                    )}

                    <span className="logo-descp">
                      {/* {itinAr.airline_code[0]}  */}
                      {itinAr.airline_code[0]}-
                      {itinAr.flight_number[0]}
                      <br />
                     {t(itinAr.cabin_class[0])}
                     <br />
                     {itinAr.aircraft_model[0]}
                    </span>
                    <br />
                    <br />
                    <strong>
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]}) 
                      </strong>
                      <br />
                    <span className="muted" >{itinAr.city_name[1]}, {itinAr.country[1]}</span>
                    
                      <br />
                  </div>
                </div>
                {/* {getItineraries[0]?.Leg[0]?.Segements.length > 0 &&
                 getItineraries[0]?.Leg[0]?.Segements[getItineraries[0]?.Leg[0]?.Segements.len]?.departureAirport
                    ?.airportName && */}
          
          <a href="#baggage2"></a>
          {itinAr.lastItem[1] !== itinAr.airport_name[1] ?
                <div className="fd_row">
                  <div className="fd_col">
                  {/* <p className="waitingTime">{waitingTime(itinAr?.timeTest[1]?.split(" ")[0]?.substring(16, 11), singleItrr[1]?.timeTest[0]?.split(" ")[0]?.substring(16, 11))}</p> */}
                  {/* <p className="waitingTime">{waitingTime(itinAr?.timeTest[1]?.split(" ")[0]?.substring(16, 11), singleItrr[1]?.lastItem[4]?.split(" ")[0]?.substring(16, 11))}</p> */}
                  </div>
                  <div className="fd_col point">
                    &nbsp;
                  </div>
                  <div className="fd_col">
                  <p className="waitingTime">{t('Waiting Time in')} {' '} {itinAr.city_name[1]} </p>
                  </div>
                </div>
                :
                <div className="fd_row">
                  <div className="fd_col">
                   
                  </div>
                  <div className="fd_col point">
                    &nbsp;
                  </div>
                  <div className="fd_col">
                  </div>
                </div>
        }



        {/* } */}
       {/* {getItineraries[0].Leg[0].Segements.length < 1 &&
                  <div className="fd_row">
                  <div className="fd_col">

                  </div>
                  <div className="fd_col point">

                  </div>
                  <div className="fd_col">
                  <strong style={{ position: "relative" }}>
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]}) 
                  </strong>
                  </div>
                </div>
                } */}
               </>
              );
            })}
      </div>  


      <div className="flight_details_baggage" id="baggage2">
      <div className="row">
        <div className="col-sm-2 col-md-2 col-lg-2 col-2 text-center">
        <img
                        className="icon"
                        src={window.location.origin + "/img/travel-bag-1.svg"}
                        alt=""
                      />            
        </div>

        <div className="col-sm-4 col-md-4 col-lg-4 col-4">
        <p className="p font-bold green-text font-17" id="baggage2">{t('Baggage')}</p>  
        <a href="#baggage2"></a>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-6 text-right">
        <img
                        className="icon"
                        onClick={() => {ActiveBaggageTab2();
                          getID('baggage2', true);
                        }}
                        src={MoreInfoIcon}
                        alt=""
                      />     
        </div>
      </div>
      {
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[1] > 0 ||
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[3] !== null && singleItrr[0]?.baggage[5] > 0 &&
      <div className="row mt-3">
        <div className="col-sm-2 col-2  text-center">
          <img src={GreenTick} style={{height:"24px"}} />
        </div>

        <div className="col-10 col-sm-10">

                             {
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[1] > 0 ||
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[3] !== null && singleItrr[0]?.baggage[5] > 0 ?
                        <span>
                        <span className="font-14">
                              {/* {singleItrr[0]?.baggage[0] === "A" &&  singleItrr[0]?.baggage[1] > 0 
                                ? t(singleItrr[0]?.baggage[1]) + " " + singleItrr[0]?.baggage[2] + "Checkin Baggage"
                                : null}{" "} */}
                                {/* {makeLowerCase(singleItrr[0]?.baggage[3]) + " Checkin Baggage"} */}
                                {singleItrr[0]?.baggage[3] ? makeLowerCase(singleItrr[0]?.baggage[3]) + " Checkin Baggage" : null}
                                <br />
                           </span>     
                                {/* <span className="muted font-11" style={{opacity:"0.5"}}>
                                {singleItrr[0]?.city_name[0]}, {" "}
                              {
                                // singleItrr?.length city_name[
                                //   singleItrr[1]?.city_name.length - 1
                                // ]
                                singleItrr[0].lastItem[2]
                              }{" "}
                                </span> */}
                                <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[0]?.baggage[5] > 0 && singleItrr[0]?.baggage[5] < 2  ?
  singleItrr[0]?.baggage[5] + " " + t("Piece Only")
  :
  singleItrr[0]?.baggage[5] > 1 ?
  singleItrr[0]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                            
                                </span>
                          :
                          null
                            }
        </div>
      </div>  
}
      <div className="row mt-2">
      <div className="col-sm-2 col-2  text-center">
          <img src={GreenTick} style={{height:"24px"}} />
        </div>

        {
  singleItrr[0]?.baggageB[1] > 0 || singleItrr[0]?.baggageB[2] != null || singleItrr[0]?.baggageB[3] || singleItrr[0]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  singleItrr[0]?.baggageB[1] > 0 || singleItrr[0]?.baggageB[2] != null ?
  singleItrr[0]?.baggageB[1] + " " + makeLowerCase(singleItrr[0]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  singleItrr[0]?.baggageB[3] || singleItrr[0]?.baggageB[3] !== undefined 
     ? makeLowerCase(singleItrr[0]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[0]?.baggageB[5] > 0 && singleItrr[0]?.baggageB[5] < 2  ?
  singleItrr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[0]?.baggageB[5] > 1 ?
  singleItrr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  singleItrr[0]?.baggageB[5] > 0 ?
  singleItrr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[0]?.baggageB[5] > 1 ?
  singleItrr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}    
        </div>

    
      </div>
      


      <div className="flight_details_pop_baggage" style={{marginBottom:"120px"}} id="cancel2">
      <div className="row">
        <div className="col-sm-2 col-md-2 col-lg-2 col-2 text-center">
        <a href="#cancel2"></a>   
        <img
                        className="icon"
                        src={EditPen}
                        style={{height:"20px"}}
                        alt=""
                      />   
         <a href="#cancel2"></a>                   
        </div>

        <div className="col-sm-8 col-md-8 col-lg-8 col-8">
        <p className="p font-bold green-text">{t('Cancel & date change')}</p>  
        </div>

        <div className="col-sm-2 col-md-2 col-lg-2 col-2 text-right">
        <img
                        className="icon"
                        src={MoreInfoIcon}
                        alt=""
                        onClick={() => {
                          activeFareTab2();
                          getID('cancel2', true);
                        }}
                      />     
        </div>
      </div>

      <div className="row mt-3">
        <div className={locale === "en" ? "col-sm-2 col-2 text-center " : "col-sm-2 col-2 text-center "}>
          <img src={getItineraries[0]?.passengers[0]?.refundability ===
            "REFUNDABLE" ? GreenTick : RedCross} style={{height:"24px"}} />
          <br />
          <img src={RedCross} className="mt-3" style={{height:"24px"}} />
        </div>

        <div className="col-10 col-sm-10">
          <p style={{fontSize:"16px", marginTop:"5px"}}>
          {getItineraries[0]?.passengers[0]?.refundability ===
            "REFUNDABLE" ? (
              <span style={{fontSize:"16px"}}>{t("Refundable")}</span>
            ) : (
              <span style={{fontSize:"16px"}}>{t('Non-refundable fare')}</span>
            )}
            </p>
          <p style={{fontSize:"16px", marginTop:"23px"}}>{t('Non-changeable fare')}</p>
        </div>
      </div>  
      </div>


      {fareTab ?
      <div className="flight_detials_fare" >
        <div className="fd_row">
              <strong>{t("Fare Rules")}</strong>
              <div className="fd_col">
                <ul>
                  <li>
                    <div>{t("Cancellation Fee")}</div>
                    <div />
                  </li>
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : totalPass?.Leg[1]?.Segements[0]?.departureAirport
                            ?.airportCode
                       }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.goingArrivalAirport
                        :
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                        ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }
                    </div>
                    <div>
                      {currency} 110.00 {t("Cancellation Before Flight")}
                    </div>
                  </li>
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails.goingArrivalAirport
                        : totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : 
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                            ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                        }{" "}
                    </div>
                    <div>
                      {currency} 220.00 {t("Cancellation After Flight")}
                    </div>
                  </li>
                </ul>
                <p>{t("No Show Charges")}</p>
              </div>
            </div>
      <div className="fd_row">
        <div className="fd_col">
          <ul>
            <li>
              <div>{t("Date Change Fee")}</div>
              <div />
            </li>
            <li>
              <div>
                {holdSearchId
                ?
                bookingDetails?.GoingDepartureAirport
                :
                 totalPass?.Leg[0]?.Segements[0]?.departureAirport
                      ?.airportCode
                }{" "}
                -{" "}
                {holdSearchId 
                  ?
                  bookingDetails?.goingArrivalAirport
                  :
                  tripType === 1 ?
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport
                      ?.airportCode
                   :
                   totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                   ?.airportCode   
                  }
              </div>
              <div>
                {currency} 110.00 {t("Date Change Fee Description")}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p>
        <strong>{t("Please Note")}: </strong>
        {t("Note Description1")}.
      </p>
      <p>{t("Note Description2")}.</p>
    </div>
    :
    null
      }
        </div>
        :
        null

      }



{baggageTab === true ? (
  <div >
  <div className="row" style={{marginTop:"80px", paddingLeft:"20px"}}>
    <div className="col-sm-12 col-12">
      <h2 className="font-17 font-bold">{itinArr[0]?.city_name[0]} to {itinArr[0]?.city_name[1]}</h2>
     
    </div>

  </div>
    <div className="flight_details_baggage" dir={locale === "ar" ? "rtl" : "ltr"}
          
            >
      {
                                  itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[1] > 0 ||
                                  itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[3] !== null && itinArr[0]?.baggage[5] > 0 ?
            <div className="row mt-1">
              <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              <div className="col-10 col-sm-10 mt-1">
              {
                                  itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[1] > 0 ||
                                  itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[3] !== null && itinArr[0]?.baggage[5] > 0 ?
                                  <span>
                               <span className="font-14">
                                    {itinArr[0]?.baggage[0] === "A" && itinArr[0]?.baggage[1] && itinArr[0]?.baggage[1] > 1
                                      ? t(itinArr[0]?.baggage[1]) + " " + itinArr[0]?.baggage[2] + " Checkin Baggage"
                                      : 
                                      itinArr[0]?.baggage[3] ?
                                      makeLowerCase(itinArr[0]?.baggage[3]) + " Checkin Baggage" 
                                      :
                                      null
                                      }{" "}
                                      {/* {makeLowerCase(itinArr[0]?.baggage[3])} */}
                                  </span>    
                                      <br />
                                      {/* <span className="muted font-11" style={{opacity:"0.5"}}>
                                      {itinArr[0]?.city_name[0]}, {" "}
                                      {
                        itinArr[0]?.city_name[1]
                      }
                                    
                                    {" "}
                                    </span> */}
                                     <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[0]?.baggage[5] > 0 && itinArr[0]?.baggage[5] < 2 ?
  itinArr[0]?.baggage[5] + " " + t("Piece Only")
  :
  itinArr[0]?.baggage[5] > 1 ?
  itinArr[0]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                                  
                                      </span>
                                :
                                null
                                  }
              </div>
      
      
            </div>  
            :
            null
}

      
            <div className="row mt-2">
            <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              {
  itinArr[0]?.baggageB[1] > 0 || itinArr[0]?.baggageB[2] != null || itinArr[0]?.baggageB[3] || itinArr[0]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  itinArr[0]?.baggageB[1] > 0 || itinArr[0]?.baggageB[2] != null ?
  itinArr[0]?.baggageB[1] + " " + makeLowerCase(itinArr[0]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  itinArr[0]?.baggageB[3] || itinArr[0]?.baggageB[3] !== undefined 
     ? makeLowerCase(itinArr[0]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[0]?.baggageB[5] > 0 && itinArr[0]?.baggageB[5] < 2  ?
  itinArr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[0]?.baggageB[5] > 1 ?
  itinArr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  itinArr[0]?.baggageB[5] > 0 ?
  itinArr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[0]?.baggageB[5] > 1 ?
  itinArr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}   
      
      
            </div>
     </div>

   {itinArr[1] &&
   <>
    <div className="row" style={{marginTop:"30px", paddingLeft:"20px"}}>
    <div className="col-sm-12 col-12">
      <h2 className="font-17 font-bold">{itinArr[1]?.city_name[0]} to {itinArr[1]?.city_name[1]}</h2>
    </div>

  </div>
    <div className="flight_details_baggage" dir={locale === "ar" ? "rtl" : "ltr"}
          
            >
                   {
                                  itinArr[1]?.baggage[0] === "A" && itinArr[1]?.baggage[1] > 0 ||
                                  itinArr[1]?.baggage[0] === "A" && itinArr[1]?.baggage[3] !== null && itinArr[1]?.baggage[5] > 0 ?  
            <div className="row mt-1">
              <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              <div className="col-10 col-sm-10 mt-1">
              {
                                  itinArr[1]?.baggage[0] === "A" && itinArr[1]?.baggage[1] > 0 ||
                                  itinArr[1]?.baggage[0] === "A" && itinArr[1]?.baggage[3] !== null && itinArr[1]?.baggage[5] > 0 ?
                                  <span>
                               <span className="font-14">
                                    {itinArr[1]?.baggage[0] === "A" && itinArr[1]?.baggage[1] && itinArr[1]?.baggage[1] > 1
                                      ? t(itinArr[1]?.baggage[1]) + " " + makeLowerCase(itinArr[1]?.baggage[2]) + " Checkin Baggage"
                                      : 
                                      itinArr[1]?.baggage[3] ?
                                      makeLowerCase(itinArr[1]?.baggage[3]) + " Checkin Baggage" 
                                      :
                                      null
                                      }{" "}
                                      {/* {makeLowerCase(itinArr[1]?.baggage[3])} */}
                                  </span>    
                                      <br />
                                      <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[1]?.baggage[5] > 0 && itinArr[1]?.baggage[5] < 2 ?
  itinArr[1]?.baggage[5] + " " + t("Piece Only")
  :
  itinArr[1]?.baggage[5] > 1 ?
  itinArr[1]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                                  
                                      </span>
                                :
                                null
                                  }
              </div>
      
      
            </div>  
            :
            null }
      
            <div className="row mt-2">
            <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              {
  itinArr[1]?.baggageB[1] > 0 || itinArr[1]?.baggageB[2] != null || itinArr[1]?.baggageB[3] || itinArr[1]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  itinArr[1]?.baggageB[1] > 0 || itinArr[1]?.baggageB[2] != null ?
  itinArr[1]?.baggageB[1] + " " + makeLowerCase(itinArr[1]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  itinArr[1]?.baggageB[3] || itinArr[1]?.baggageB[3] !== undefined 
     ? makeLowerCase(itinArr[1]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[1]?.baggageB[5] > 0 && itinArr[1]?.baggageB[5] < 2 ?
  itinArr[1]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[1]?.baggageB[5] > 1 ?
  itinArr[1]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
itinArr[1]?.baggageB[5] > 0 ?
  itinArr[1]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[1]?.baggageB[5] > 1 ?
  itinArr[1]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}   
      
      
      
            </div>
     </div>
   </>
   }  

{itinArr[2] &&
   <>
    <div className="row" style={{marginTop:"30px", paddingLeft:"20px"}}>
    <div className="col-sm-12 col-12">
      <h2 className="font-17 font-bold">{itinArr[2]?.city_name[0]} to {itinArr[2]?.city_name[1]}</h2>
    </div>

  </div>
    <div className="flight_details_baggage" style={{marginBottom:"100px"}} dir={locale === "ar" ? "rtl" : "ltr"}
          
            >
           {
                                  itinArr[2]?.baggage[0] === "A" && itinArr[2]?.baggage[1] > 0 ||
                                  itinArr[2]?.baggage[0] === "A" && itinArr[2]?.baggage[3] !== null && itinArr[2]?.baggage[5] > 0 ?
            <div className="row mt-1">
              <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              <div className="col-10 col-sm-10 mt-1">
              {
                                  itinArr[2]?.baggage[0] === "A" && itinArr[2]?.baggage[1] > 0 ||
                                  itinArr[2]?.baggage[0] === "A" && itinArr[2]?.baggage[3] !== null && itinArr[2]?.baggage[5] > 0 ?
                                  <span>
                               <span className="font-14">
                                    {itinArr[2]?.baggage[0] === "A" && itinArr[2]?.baggage[1] && itinArr[2]?.baggage[1] > 1
                                      ? t(itinArr[2]?.baggage[1]) + " " + makeLowerCase(itinArr[2]?.baggage[2]) + " Checkin Baggage"
                                      // : null}{" "}
                                      : 
                                      itinArr[2]?.baggage[3] ?
                                      makeLowerCase(itinArr[2]?.baggage[3]) + " Checkin Baggage" 
                                      :
                                      null
                                      }{" "}
                                      {/* {makeLowerCase(itinArr[2]?.baggage[3])} */}
                                  </span>    
                                      <br />
                                      <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[2]?.baggage[5] > 0 && itinArr[2]?.baggage[5] < 2  ?
  itinArr[2]?.baggage[5] + " " + t("Piece Only")
  :
  itinArr[2]?.baggage[5] > 1 ?
  itinArr[2]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                                  
                                      </span>
                                :
                                null
                                  }
              </div>
      
      
            </div>  
           : null
                                }
      
            <div className="row mt-2">
            <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
              {
  itinArr[2]?.baggageB[1] > 0 || itinArr[2]?.baggageB[2] != null || itinArr[2]?.baggageB[3] || itinArr[2]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  itinArr[2]?.baggageB[1] > 0 || itinArr[2]?.baggageB[2] != null ?
  itinArr[2]?.baggageB[1] + " " + makeLowerCase(itinArr[2]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  itinArr[2]?.baggageB[3] || itinArr[2]?.baggageB[3] !== undefined 
     ? makeLowerCase(itinArr[2]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  itinArr[2]?.baggageB[5] > 0 && itinArr[2]?.baggageB[5] < 2  ?
  itinArr[2]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[2]?.baggageB[5] > 1 ?
  itinArr[2]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  itinArr[2]?.baggageB[5] > 0 ?
  itinArr[2]?.baggageB[5] + " " + t("Piece Only")
  :
  itinArr[2]?.baggageB[5] > 1 ?
  itinArr[2]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}   
      
      
      
            </div>
     </div>
   </>
   }  
</div>
) : null }


{baggageTab2 === true ? (
  <div >

{tripType === 1 ?
  <div>
  <div className="row" style={{marginTop:"80px", paddingLeft:"20px"}}>
    <div className="col-sm-12 col-12">
      <h2 className="font-17 font-bold">{singleItrr[0]?.city_name[0]} to {singleItrr[0]?.city_name[1]}</h2>
    </div>

  </div>

  <div className="flight_details_baggage" dir={locale === "ar" ? "rtl" : "ltr"}
            >
        {
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[1] > 0 ||
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[3] !== null && singleItrr[0]?.baggage[5] > 0 ?
            <div className="row mt-1">
              <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              <div className="col-10 col-sm-10">

                             {
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[1] > 0 ||
                            singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[3] !== null && singleItrr[0]?.baggage[5] > 0 ?
                        <span>
                        <span className="font-14">
                              {singleItrr[0]?.baggage[0] === "A" && singleItrr[0]?.baggage[1] && singleItrr[0]?.baggage[1] > 1
                                ? t(singleItrr[0]?.baggage[1]) + " " + makeLowerCase(singleItrr[0]?.baggage[2]) + "Checkin Baggage"
                                // : null}{" "}
                                // {makeLowerCase(singleItrr[0]?.baggage[3])}
                                : 
                                singleItrr[0]?.baggage[3] ?
                                makeLowerCase(singleItrr[0]?.baggage[3]) + " Checkin Baggage" 
                                :
                                null
                                }{" "}
                                <br />
                           </span>     
                           <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[0]?.baggage[5] > 0 && singleItrr[0]?.baggage[5] < 2  ?
  singleItrr[0]?.baggage[5] + " " + t("Piece Only")
  :
  singleItrr[0]?.baggage[5] > 1 ?
  singleItrr[0]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                            
                                </span>
                          :
                          null
                            }
        </div>
      
      
            </div>  

            :
            null}
      
            <div className="row mt-2">
            <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              {
  singleItrr[0]?.baggageB[1] > 0 || singleItrr[0]?.baggageB[2] != null || singleItrr[0]?.baggageB[3] || singleItrr[0]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  singleItrr[0]?.baggageB[1] > 0 || singleItrr[0]?.baggageB[2] != null ?
  singleItrr[0]?.baggageB[1] + " " + makeLowerCase(singleItrr[0]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  singleItrr[0]?.baggageB[3] || singleItrr[0]?.baggageB[3] !== undefined 
     ? makeLowerCase(singleItrr[0]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[0]?.baggageB[5] > 0 && singleItrr[0]?.baggageB[5] < 2 ?
  singleItrr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[0]?.baggageB[5] > 1 ?
  singleItrr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  singleItrr[0]?.baggageB[5] > 0 ?
  singleItrr[0]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[0]?.baggageB[5] > 1 ?
  singleItrr[0]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}   
      
      
      
            </div>
            </div>
  </div>  
  :
  null
  
  }

 {singleItrr[1] &&
 <>
  <div className="row" style={{marginTop:"30px", paddingLeft:"20px"}}>
    <div className="col-sm-12 col-12">
      <h2 className="font-17 font-bold">{singleItrr[1]?.city_name[0]} to {singleItrr[1]?.city_name[1]}</h2>
    </div>

  </div>

  <div className="flight_details_baggage" dir={locale === "ar" ? "rtl" : "ltr"}
            >
                {
                            singleItrr[1]?.baggage[0] === "A" && singleItrr[1]?.baggage[1] > 0 ||
                            singleItrr[1]?.baggage[0] === "A" && singleItrr[1]?.baggage[3] !== null && singleItrr[1]?.baggage[5] > 0 ?     
            <div className="row mt-1">
              <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              <div className="col-10 col-sm-10">

                             {
                            singleItrr[1]?.baggage[0] === "A" && singleItrr[1]?.baggage[1] > 0 ||
                            singleItrr[1]?.baggage[0] === "A" && singleItrr[1]?.baggage[3] !== null && singleItrr[1]?.baggage[5] > 0 ?
                        <span>
                        <span className="font-14">
                              {singleItrr[1]?.baggage[0] === "A" && singleItrr[1]?.baggage[1] && singleItrr[1]?.baggage[1] > 1
                                ? t(singleItrr[1]?.baggage[1]) + " " + makeLowerCase(singleItrr[1]?.baggage[2]) + "Checkin Baggage"
                                // : null}{" "}
                                // {makeLowerCase(singleItrr[1]?.baggage[3])}
                                : 
                                singleItrr[1]?.baggage[3] ?
                                makeLowerCase(singleItrr[1]?.baggage[3]) + " Checkin Baggage" 
                                :
                                null
                                }{" "}
                                <br />
                           </span>     
                           <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[1]?.baggage[5] > 0 && singleItrr[1]?.baggage[5] < 2 ?
  singleItrr[1]?.baggage[5] + " " + t("Piece Only")
  :
  singleItrr[1]?.baggage[5] > 1 ?
  singleItrr[1]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                            
                                </span>
                          :
                          null
                            }
        </div>
      
      
            </div>  
            :
            null }
      
            <div className="row mt-2">
            <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              {
  singleItrr[1]?.baggageB[1] > 0 || singleItrr[1]?.baggageB[2] != null || singleItrr[1]?.baggageB[3] || singleItrr[1]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  singleItrr[1]?.baggageB[1] > 0 || singleItrr[1]?.baggageB[2] != null ?
  singleItrr[1]?.baggageB[1] + " " + makeLowerCase([1]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  singleItrr[1]?.baggageB[3] || singleItrr[1]?.baggageB[3] !== undefined 
     ? makeLowerCase(singleItrr[1]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[1]?.baggageB[5] > 0 && singleItrr[1]?.baggageB[5] < 2 ?
  singleItrr[1]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[1]?.baggageB[5] > 1 ?
  singleItrr[1]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  singleItrr[1]?.baggageB[5] > 0 ?
  singleItrr[1]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[1]?.baggageB[5] > 1 ?
  singleItrr[1]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}   
      
      
            </div>
            </div>
 
 </>

 } 


 
{singleItrr[2] &&
 <>
  <div className="row" style={{marginTop:"30px", paddingLeft:"20px"}}>
    <div className="col-sm-12 col-12">
      <h2 className="font-17 font-bold">{singleItrr[2]?.city_name[0]} to {singleItrr[2]?.city_name[1]}</h2>
    </div>

  </div>

  <div className="flight_details_baggage" style={{marginBottom:"100px"}} dir={locale === "ar" ? "rtl" : "ltr"}
            >
         {
                            singleItrr[2]?.baggage[0] === "A" && singleItrr[2]?.baggage[1] > 0 ||
                            singleItrr[2]?.baggage[0] === "A" && singleItrr[2]?.baggage[3] !== null && singleItrr[2]?.baggage[5] > 0 ?
            <div className="row mt-1">
              <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              <div className="col-10 col-sm-10">

                             {
                            singleItrr[2]?.baggage[0] === "A" && singleItrr[2]?.baggage[1] > 0 ||
                            singleItrr[2]?.baggage[0] === "A" && singleItrr[2]?.baggage[3] !== null && singleItrr[2]?.baggage[5] > 0 ?
                        <span>
                        <span className="font-14">
                              {singleItrr[2]?.baggage[0] === "A" && singleItrr[2]?.baggage[1] && singleItrr[2]?.baggage[1] > 1
                                ? t(singleItrr[2]?.baggage[1]) + " " + makeLowerCase(singleItrr[2]?.baggage[2]) + "Checkin Baggage"
                                // : null}{" "}
                                // {makeLowerCase(singleItrr[2]?.baggage[3])}
                                : 
                                singleItrr[2]?.baggage[3] ?
                                makeLowerCase(singleItrr[2]?.baggage[3]) + " Checkin Baggage" 
                                :
                                null
                                }{" "}
                                <br />
                           </span>     
                           <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[2]?.baggage[5] > 0 && singleItrr[2]?.baggage[5] < 2 ?
  singleItrr[2]?.baggage[5] + " " + t("Piece Only")
  :
  singleItrr[2]?.baggage[5] > 1 ?
  singleItrr[2]?.baggage[5] + " " + t("Pieces")
  :
  null
}
  </span>
                            
                                </span>
                          :
                          null
                            }
        </div>
      
      
            </div>  
            :
            null }
      
            <div className="row mt-2">
            <div className="col-sm-2 col-2  text-center">
                <img src={GreenTick} style={{height:"24px"}} />
              </div>
      
              {
  singleItrr[2]?.baggageB[1] > 0 || singleItrr[2]?.baggageB[2] != null || singleItrr[2]?.baggageB[3] || singleItrr[2]?.baggageB[3] !== undefined ?
  <div className="col-10 col-sm-10">
  <span className="font-14">
 {
  singleItrr[2]?.baggageB[1] > 0 || singleItrr[2]?.baggageB[2] != null ?
  singleItrr[2]?.baggageB[1] + " " + makeLowerCase(singleItrr[2]?.baggageB[2]) + " " +  t("Hand Carry Baggage")
  :
  singleItrr[2]?.baggageB[3] || singleItrr[2]?.baggageB[3] !== undefined 
     ? makeLowerCase(singleItrr[2]?.baggageB[3]) + " " +  t("Hand Carry Baggage")
     :
     null
     }
  </span>
  <br />
  <span className="muted" style={{opacity:"0.5", fontSize:"11px"}}>
{
  singleItrr[2]?.baggageB[5] > 0 && singleItrr[2]?.baggageB[5] < 2 ?
  singleItrr[2]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[2]?.baggageB[5] > 1 ?
  singleItrr[2]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>

  </div>
  :
<div className="col-10 col-sm-10">
  <span className="font-14">
  {
  singleItrr[2]?.baggageB[5] > 0 ?
  singleItrr[2]?.baggageB[5] + " " + t("Piece Only")
  :
  singleItrr[2]?.baggageB[5] > 1 ?
  singleItrr[2]?.baggageB[5] + " " + t("Pieces")
  :
  null
}
  </span>
</div>  

}   
      
      
            </div>
            </div>
 
 </>

 } 

  </div>
            
      ) : null}




      <div className="flight_details_footer">

      {moreInfoToggle === true &&
  <div className="booking_summary">
          <div className="row">
            <div className="col-sm-2 col-2">
              <img src={CloseBtn} className="light" onClick={() => setMoreInfoToggle(false)} />
              <img src={DarkCloseBtn} className="dark" onClick={() => setMoreInfoToggle(false)} />
            </div>

            <div className="col-10 col-sm-12">
              <p className="p font-bold font-18">{t('Price Summary')}</p>
            </div>

          </div>

          <div className="row mt-4">
            <div className="col-sm-8 col-8">
              <p className="green-text font-bold font-14">
              {
                  getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport
                    ?.cityName
                }{" "} To {' '}
               {
                  getItineraries[0]?.Leg[0]?.Segements[
                    getItineraries[0]?.Leg[0]?.Segements?.length - 1
                  ]?.arrivalAirport?.cityName
                }{' '}
                 ({tripType === 0 ? t('One Way') : t("Round Trip")})</p>
              <p className="mt-1 font-14">{t('Fare')}</p>
              <p className="mt-1 font-14">{t('Taxes')}</p>
              {getItineraries[0].OriginalPrice - getItineraries[0].TotalPrice > 0 &&
              <p className="orange-text mt-1 font-14">{t('Discount')}</p>
              }
            </div>

            <div className="col-4 col-sm-4 text-right">
            <p className="green-text font-bold">{process.env.REACT_APP_CURRENCY} {' '} {totalCharges && totalCharges
                  ? totalCharges
                  : bookingDetails.price}</p>
              {/* <p className="mt-1">SAR 415</p>
              <p className="mt-1">SAR 469</p>
              <p className="orange-text mt-1">SAR 65</p> */}
              {/* {getItinerariesCopy && getItinerariesCopy[0] === null
                  ? getItineraries[0]?.passengers?.map((passenger, i) => {
                      return (
                        <>
                          <p className="mt-1">{process.env.REACT_APP_CURRENCY} {' '} {Number(passenger.price.totalFareAmount).toFixed(
                              2
                            )}
                            </p>
                          <p className="mt-1">
                          {process.env.REACT_APP_CURRENCY}{' '}
                            {Number(passenger.price.totalTaxAmount * passenger.qty).toFixed(2)}
                          </p>
                        </>
                      );
                    })
                  : getItinerariesCopy[0]?.passengers?.map((passenger, i) => {
                      return (
                        <>
                            <p className="mt-1">{passenger.price.totalFareAmount}</p>
                          <p className="mt-1">{process.env.REACT_APP_CURRENCY} {' '} {passenger.price.totalTaxAmount * passenger.qty}</p>
                        </>
                      );
                    })} */}


<p className="mt-1">{process.env.REACT_APP_CURRENCY} {calcTotalFare(getItineraries[0].passengers)}</p>
<p className="mt-1 ">{process.env.REACT_APP_CURRENCY} {calcTotalTax(getItineraries[0].passengers)}</p>
{getItineraries[0].OriginalPrice - getItineraries[0].TotalPrice > 0 &&
              <p className="mt-1 orange-text">{process.env.REACT_APP_CURRENCY} {' '} {Number(getItineraries[0].OriginalPrice - getItineraries[0].TotalPrice).toFixed(2)}</p> 
}     
            </div>
          </div>
  </div>
}



        <div className="row footer-padding">
          <div className="col-1 col-sm-1">
              <img
                src={moreInfoToggle === false ?  MobArrowUpLight : MobArrowDownLight}
                style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                className="light more-info-icon ml-2"
                alt="more-info-light"
                onClick={() => setMoreInfoToggle(!moreInfoToggle) }
              />
              <img
                src={moreInfoToggle === false ?  MobArrowUpDark : MobileArrowDown}
                style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                className="dark ml-2"
                alt="more-info-dark"
                onClick={() => setMoreInfoToggle(!moreInfoToggle) }
              />
            </div>
          <div className={pathName === "passenger_information" ||
              pathName === "payment_details" ? "col-lg-5 col-sm-5 col-md-5 col-6" : "col-6 col-sm-5"} style={{marginLeft:"5px"}}>
          <p className="dps-none">Total (incl. VAT)</p>
          <h2>{process.env.REACT_APP_CURRENCY} {' '} {totalCharges && totalCharges
                  ? totalCharges
                  : bookingDetails.price}</h2>
          <p className="dls-none">
          {t("Price for")}{" "}
                {calcPassengersQty(getItineraries[0]?.passengers)} {t("Person")}
                {calcPassengersQty(getItineraries[0]?.passengers) > 1
                  ? "s"
                  : null}
          </p>
          </div>

          <div className="col-lg-5 col-sm--5 col-md-5 col-4 text-right">
            <button
            id="bookNowBtn"
             onClick={BookNow}
             className="bookNowBtn"
            >{t('Continue')}</button>
          </div>
        </div>
        </div>
         
        <button
          type="button"
          className="close"
          onClick={() => closeFlightDetails()}
        >
          {" "}
          ✕{" "}
        </button>


        <div className="flight_detail_mobile_header">
          {fareTab === false && baggageTab === false && baggageTab2 === false && fareTab2 === false
          ?
          <button
            aria-label="Search Results"
            type="button"
            onClick={() => {
              setRenderFlightDetails(false);
              setIsFlightDetailsOpen(false);
            }}
          />
          :
          baggageTab === true ?
          <button
          aria-label="Search Results"
          type="button"
          onClick={() => {
            closeBaggageTab('baggage1');
          }}
        />
        :
        baggageTab2 === true ?
        <button
        aria-label="Search Results"
        type="button"
        onClick={() => {
          closeBaggageTab2('baggage2');

        }}
      />
        :
        fareTab === true ?
          <button
          aria-label="Search Results"
          type="button"
          onClick={() => {
            closeFareTab('cancel1');

          }}
        />
        :
        <button
          aria-label="Search Results"
          type="button"
          onClick={() => {
            closeFareTab2('cancel2');
          }}
        />
          }
          
          <span style={{color:"white"}}>{text}</span>
        </div>

      </div>
      }









      {!isMobile &&
      <div className="flight_detail_body">
      <div className="flight_detials_cta">
        <div className="flight_row_col">
          <div className="desk_info">
            <small>{t("Flight Details")}</small>
            <strong>
              {
                getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport
                  ?.cityName
              }{" "}
              {bookingDetails?.GoingDepartureAirport} -{" "}
              {
                getItineraries[0]?.Leg[0]?.Segements[
                  getItineraries[0]?.Leg[0]?.Segements?.length - 1
                ]?.arrivalAirport?.cityName
              }{" "}
              {bookingDetails?.goingArrivalAirport}
            </strong>
            <span>
            {tripType === 1 && locale === "ar"
              ?
              <>
              
               {holdSearchId
                ? bookingDetails?.ReturnDepartureDate
                : getFormattedDate(
                    totalPass?.Leg[1]?.Segements[0]?.flights[0]?.arrivalDateTime
                      ?.split(" ")[0]
                      .substring(0, 10)
                  )}
                  {' '} — {" "}
              </>
              :
              null
              }  

              {holdSearchId
                ? bookingDetails?.goingDepartureDate
                : getFormattedDate(
                    totalPass?.Leg[0]?.Segements[0]?.flights[0]?.departureDateTime
                      ?.split(" ")[0]
                      .substring(0, 10)
                  )}
              {tripType === 1 && locale === "en"
              ?
              <>
              {' '} — {" "}
               {holdSearchId
                ? bookingDetails?.ReturnDepartureDate
                : getFormattedDate(
                    totalPass?.Leg[1]?.Segements[0]?.flights[0]?.arrivalDateTime
                      ?.split(" ")[0]
                      .substring(0, 10)
                  )}
              </>
              :
              null
              }    
            </span>
          </div>
          <div className="price">
            <small className="total_price">{t("Total price")}</small>
            <div className="the_price">
              <sup>{currency}</sup>{" "}
              {totalCharges && totalCharges
                ? totalCharges
                : bookingDetails.price}
            </div>
            <small>
              {t("Price for")}{" "}
              {calcPassengersQty(getItineraries[0]?.passengers)} {t("Person")}
              {calcPassengersQty(getItineraries[0]?.passengers) > 1
                ? "s"
                : null}
            </small>
          </div>
          <div className="cta">
            {getItineraries[0]?.passengers[0]?.refundability ===
            "REFUNDABLE" ? (
              <div className="refundable">{t("Refundable")}</div>
            ) : (
              <div className="refundable"> </div>
            )}
            {pathName === "passenger_information" ||
            pathName === "payment_details" ? null : (
              <button
                aria-label="Book Now"
                type="button"
                onClick={BookNow}
                className="bookNowBtn"
              >
                {t("Book Now Button")}
              </button>
            )}
          </div>
        </div>
      </div>
      <button
        type="button"
        className="close"
        onClick={() => closeFlightDetails()}
      >
        {" "}
        ✕{" "}
      </button>
      <div className="flight_detail_mobile_header">
        <button
          aria-label="Search Results"
          type="button"
          onClick={() => {
            setRenderFlightDetails(false);
            setIsFlightDetailsOpen(false);
          }}
        />
        <span>{text}</span>
      </div>
      <div className="tabs">
        <ul>
          <li
            onClick={() => {
              showItineraryTab();
            }}
            className={itineraryTab ? "active" : ""}
          >
            {t("Itenrary")}
          </li>
          <li
            onClick={() => {
              showFareTab();
            }}
            className={fareTab ? "active" : ""}
          >
            {t("Fare")}
          </li>
          <li
            onClick={() => {
              showBaggageTab();
            }}
            className={baggageTab ? "active" : ""}
          >
            {t("Baggage")}
          </li>
        </ul>
      </div>
      {itineraryTab ? (
        <div className="flight_detials_itinerary">
          {itinArr.map((itinAr, index) => {
            return (
              <div className="fd_row" key={index}>
                <div className="fd_col">
                  {locale === "en" ? (
                    <strong>
                      {itinAr.timeTest[0].split(" ")[0].substring(16, 11)} →{" "}
                      {itinAr.timeTest[1].split(" ")[0].substring(16, 11)}
                    </strong>
                  ) : (
                    <strong>
                      {itinAr.timeTest[0].split(" ")[0].substring(16, 11)} ←{" "}
                      {itinAr.timeTest[1].split(" ")[0].substring(16, 11)}
                    </strong>
                  )}
                  <br />
                  <span>{getFormattedDate(itinAr.date[0]).substring(5,17)}</span>
                  <span className="leg_time">
                    {convertMinsToHrsMins(itinAr.time_elapsed[0])}
                  </span>
                </div>
                <div className="fd_col point">&nbsp;</div>
                <div className="fd_col">
                  <strong style={{ position: "relative" }}>
                    {itinAr.airport_name[0]} ({itinAr.airport_code[0]}) →{" "}
                    {itinAr.airport_name[1]} ({itinAr.airport_code[1]})
                    {itinAr.arrival_next_day[0] > 0 ? (
                      <span
                        className="nextDayArrival"
                        style={{
                          top: "auto",
                          left: "auto",
                          verticalAlign: "text-top",
                          display: "inline-block",
                          lineHeight: "1",
                          margin: "-2.5px 5px 0 5px",
                        }
                     
                      }
                        title={
                          `Arrival after + ` +
                          itinAr.arrival_next_day[0] +
                          ` Day`
                        }
                      >
                        {locale === "en" && "+"}
                        {itinAr.arrival_next_day[0]}
                        {locale === "ar" && "+"}
                      </span>
                    ) : (
                      ""
                    )}
                  </strong>
                  <br />
                 
                  <span className="muted">{itinAr.city_name[0]}, {itinAr?.country[0]}</span>
                 
                  <br />
                  <span>
                    {itinAr.arrival_next_day[0] > 0
                      ? `${t("Next Day Arrival")} \n`
                      : ""}
                  </span>
                  <br />
                  {_SearchID ? (
                    <div
                      className="fd_a_logo"
                      style={{
                        backgroundImage:
                          "url(/img/airlines/" +
                          itinAr.airline_code[0] +
                          ".png)",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="fd_a_logo"
                      style={{
                        backgroundImage:
                        `url(${window.location.origin}/img/airlines/${itinAr.airline_code[0]}.png)`,
                          // "url(../../img/airlines/" +
                          // itinAr.airline_code[0] +
                          // ".png)",
                      }}
                    ></div>
                  )}

                  <span className="logo-descp">
                    {/* {itinAr.airline_code[0]}  */}
                    {itinAr.airline_code[0]}-
                    {itinAr.flight_number[0]}
                  </span>
                  <br />
                  {_SearchID ? (
                    <img
                      className="icon"
                      src="/img/airplane-departure.svg"
                      alt=""
                    />
                  ) : (
                    <img
                      className="icon"
                      src={window.location.origin + "/img/airplane-departure.svg"}
                      alt=""
                    />
                  )}
                  <span>
                    {itinAr.aircraft_model[0]} {t(itinAr.cabin_class[0])}
                  </span>
                  <br />
                  {_SearchID ? (
                    <img
                      className="icon"
                      src={window.location.origin + "/img/travel-bag-1.svg"}
                      alt=""
                    />
                  ) : (
                    <img
                      className="icon"
                      src={window.location.origin + "/img/travel-bag-1.svg"}
                      alt=""
                    />
                  )}
                  <span>
                    {itinAr.baggage[0] === "A" && itinAr?.baggage[1] > 0 ||
                    itinAr.baggage[0] === "A" && itinAr?.baggage[3] !== null && itinAr.baggage[5] > 0
                      ? t("Checkin Baggage")
                      : t("Carry On Baggage")}{" "}
                  </span>
                  <br />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {baggageTab ? (
        <div className="flight_detials_baggage">
          <div className="fd_row">
            <strong>{t("Checkin Baggage")}</strong>
            <div>
              <h3 />
              <div>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t("Airline")}</th>
                        <th>{t("Sector")}</th>
                        <th>{t("Baggage Details")}</th>
                        <th>
                          {t("Weight")} / {t("Piece Count")}
                        </th>
                      </tr>
                      {itinArr.map((itinAr, index) => {
                        return (
                          <>
                          {
                          itinAr.baggage[0] === "A" && itinAr?.baggage[1] > 0 ||
                          itinAr.baggage[0] === "A" && itinAr?.baggage[3] !== null && itinAr.baggage[5] > 0 ?
                          <tr key={index}>
                          <td>
                            {_SearchID ? (
                              <img
                                src={
                                  "/img/airlines/" +
                                  itinAr.airline_code[0] +
                                  ".png"
                                }
                                alt={itinAr.airline_code[0]}
                                width="24px"
                              ></img>
                            ) : (
                              <img
                                src={window.location.origin +
                                  "/img/airlines/" +
                                  itinAr.airline_code[0] +
                                  ".png"
                                }
                                alt={itinAr.airline_code[0]}
                                width="24px"
                              ></img>
                            )}
                          </td>
                          <td>
                            {" "}
                            {itinAr.airport_code[0]} -{" "}
                            {
                              itinAr.airport_code[
                                itinAr.airport_code.length - 1
                              ]
                            }{" "}
                          </td>
                          <td>
                            {itinAr.baggage[0] === "A" &&
                             t("Checkin Baggage")
                            //  :
                            //  t("Carry On Baggage")
                            }
                          </td>
                          <td>
                            {itinAr.baggage[0] === "A" && itinAr.baggage[1] && itinAr.baggage[1] > 1
                              ? t(itinAr?.baggage[1])
                              : null}{" "}
                            {itinAr.baggage[0] === "A" && itinAr.baggage[2] && itinAr.baggage[1] > 1
                              ? t(itinAr?.baggage[2])
                              : null}{" "}
                            {/* {itinAr.baggage[3] === null &&
                            itinAr.baggageB[3] === null
                              ? null
                              : itinAr.baggage[3] !== null
                              ? makeLowerCase(itinAr?.baggage[3])
                              : makeLowerCase(itinAr?.baggageB[3])} */}
                              {makeLowerCase(itinAr?.baggage[3])}
                            {itinAr.baggage[0] === "A" && itinAr?.baggage[3] !== null ? "\n" : null}
                            {/* {itinAr.baggage[4] === null &&
                            itinAr.baggageB[4] === null
                              ? null
                              : itinAr.baggage[4] !== null
                              ? makeLowerCase(t(itinAr?.baggage[4]))
                              : makeLowerCase(t(itinAr?.baggageB[4]))} */}
                              {itinAr.baggage[0] === "A" &&
                              makeLowerCase(t(itinAr?.baggage[4]))}
                              {'\n'}
                              {itinAr.baggage[0] === "A" && itinAr.baggage[5] > 0 && itinAr.baggage[5] < 2
                                 ? "\n" + itinAr.baggage[5] + " " + t("Piece")
                                : 
                                itinAr.baggage[5] > 1 ?
                                itinAr.baggage[5] + " " + t("Pieces")
                              :
                              null}
                              {/* {itinAr.baggage[5] != 0
                              ? itinAr.baggage[5] > 0 && itinAr.baggage[5] < 2
                                ? "\n" + itinAr.baggage[5] + " " + t("Piece")
                                : itinAr.baggage[5] + " " + t("Pieces")
                              : itinAr.baggageB[5] > 0 && itinAr.baggageB[5] < 2
                              ? itinAr.baggageB[5] + " " + t("Piece")
                              : itinAr.baggageB[5] === undefined ?
                              null 
                              :
                              itinAr.baggageB[5] + " " + t("Pieces")
                              } */}
                          </td>
                        </tr>
                        :
                        null
                          }
                          {itinAr.baggageB[0] === "B" && 
<tr key={index+1}>
<td>
 {_SearchID ? (
   <img
     src={
       "/img/airlines/" +
       itinAr.airline_code[0] +
       ".png"
     }
     alt={itinAr.airline_code[0]}
     width="24px"
   ></img>
 ) : (
   <img
     src={window.location.origin + 
       "/img/airlines/" +
       itinAr.airline_code[0] +
       ".png"
     }
     alt={itinAr.airline_code[0]}
     width="24px"
   ></img>
 )}
</td>
<td>
 {" "}
 {itinAr.airport_code[0]} -{" "}
 {
   itinAr.airport_code[
     itinAr.airport_code.length - 1
   ]
 }{" "}
</td>
<td>
 {itinAr.baggageB[0] === "B" &&
    t("Carry On Baggage")}
</td>
<td>
 {itinAr.baggageB[1] && itinAr.baggageB[1] > 1
   ? t(itinAr?.baggageB[1])
   : null}{" "}
   
 {itinAr.baggageB[2] && itinAr.baggageB[1] > 1
   ? t(itinAr?.baggageB[2])
   : null}{" "}
   {itinAr.baggageB[2] && itinAr.baggageB[1] > 1 ? '\n' : null}
 {/* {itinAr.baggage[3] === null &&
 itinAr.baggageB[3] === null
   ? null
   : itinAr.baggage[3] !== null
   ? makeLowerCase(itinAr?.baggage[3])
   : makeLowerCase(itinAr?.baggageB[3])} */}
    
 {itinAr.baggageB[3] !== null &&
   makeLowerCase(itinAr?.baggageB[3])}
 {itinAr?.baggageB[3] !== null ? "\n" : null}
 {/* {itinAr.baggage[4] === null &&
 itinAr.baggageB[4] === null
   ? null
   : itinAr.baggage[4] !== null
   ? makeLowerCase(t(itinAr?.baggage[4]))
   : makeLowerCase(t(itinAr?.baggageB[4]))} */}
   {itinAr.baggageB[4] !== null &&
   makeLowerCase(itinAr?.baggageB[4])}
   {itinAr?.baggageB[4] !== null ? '\n' : null}
   {/* {itinAr.baggage[5] != 0
   ? itinAr.baggage[5] > 0 && itinAr.baggage[5] < 2
     ? "\n" + itinAr.baggage[5] + " " + t("Piece")
     : itinAr.baggage[5] + " " + t("Pieces")
   : itinAr.baggageB[5] > 0 && itinAr.baggageB[5] < 2
   ? itinAr.baggageB[5] + " " + t("Piece")
   : itinAr.baggageB[5] === undefined ?
   null 
   :
   itinAr.baggageB[5] + " " + t("Pieces")
   } */}
     {
   itinAr.baggageB[5] > 0 && itinAr.baggageB[5] < 2
   ? itinAr.baggageB[5] + " " + t("Piece")
   : itinAr.baggageB[5] === undefined ?
   null 
   :
   itinAr.baggageB[5] > 1 ?
   itinAr.baggageB[5] + " " + t("Pieces")
   :
   null
   }
</td>
</tr>
                          }
                         
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <p>{t("Baggage Section Description")}</p>
            </div>
          </div>
        </div>
      ) : null}
      {fareTab ? (
        <div className="flight_detials_fare">
          <div className="fd_row">
            <strong>{t("Fare Breakup")}</strong>
            <div className="fd_col">
              <ul>
                {getItinerariesCopy && getItinerariesCopy[0] === null
                  ? getItineraries[0]?.passengers?.map((passenger, i) => {
                      return (
                        <li key={i}>
                          <div>
                            {locale === "en" ? passenger.qty : ""}
                            {passenger.type === "ADT" ? " Adult" : null}{" "}
                            {passenger.type === "CNN" ? " Child" : null}{" "}
                            {passenger.type === "INF" ? " Infant" : null}(
                            {passenger.qty} x{" "}
                            {Number(passenger.price.totalFareAmount).toFixed(
                              2
                            )}
                            )
                          </div>
                          <div>
                            {passenger.price.currency}{" "}
                            {passenger.qty *
                              Number(passenger.price.totalFareAmount).toFixed(
                                2
                              )}
                          </div>
                          <br />
                          <div>{t("Taxes & Fees")}</div>
                          <div>
                            {currency}{" "}
                            {Number(passenger.price.totalTaxAmount * passenger.qty).toFixed(2)}
                          </div>
                        </li>
                      );
                    })
                  : getItinerariesCopy[0]?.passengers?.map((passenger, i) => {
                      return (
                        <li key={i}>
                          <div>
                            {passenger.qty}
                            {passenger.type === "ADT"
                              ? t("Adult")
                              : null}{" "}
                            {passenger.type === "CNN" ? t("Child") : null}{" "}
                            {passenger.type === "INF" ? t("Infant") : null}(
                            {passenger.qty} x{" "}
                            {passenger.price.totalFareAmount})
                          </div>
                          <div>
                            {passenger.price.currency}{" "}
                            {passenger.qty * passenger.price.totalFareAmount}
                          </div>
                          <br />
                          <div>{t("Taxes & Fees")}</div>
                          <div>
                            {currency}{" "}
                            {passenger.price.totalTaxAmount * passenger.qty}
                          </div>
                        </li>
                      );
                    })}
                <li className="total_price">
                  <div>{t("Total Fare")}</div>
                  <div>
                    {currency}{" "}
                    {totalCharges
                      ? totalCharges
                      : getItineraries[0].TotalPrice}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="fd_row">
            <strong>{t("Fare Rules")}</strong>
            <div className="fd_col">
              <ul>
                <li>
                  <div>{t("Cancellation Fee")}</div>
                  <div />
                </li>
                <li>
                  <div>
                    {holdSearchId
                    ?
                    bookingDetails?.GoingDepartureAirport
                      : totalPass?.Leg[0]?.Segements[0]?.departureAirport
                          ?.airportCode
                     }{" "}
                    -{" "}
                    {holdSearchId
                    ?
                    bookingDetails?.goingArrivalAirport
                      :
                      tripType === 1 ?
                      totalPass?.Leg[1]?.Segements[0]?.departureAirport
                      ?.airportCode
                      :
                      totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                      ?.airportCode
                      }
                  </div>
                  <div>
                    {currency} 110.000 {t("Cancellation Before Flight")}
                  </div>
                </li>
                {tripType === 1 ?
                <li>
                <div>
                  {holdSearchId
                  ?
                  bookingDetails.goingArrivalAirport
                    : totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                    ?.airportCode
                    }{" "}
                  -{" "}
                  {holdSearchId
                  ?
                  bookingDetails?.GoingDepartureAirport
                    : 
                    tripType === 1 ?
                    totalPass?.Leg[1]?.Segements[0]?.departureAirport
                        ?.airportCode
                    :
                    totalPass?.Leg[0]?.Segements[0]?.departureAirport
                        ?.airportCode
                    }{" "}
                </div>
                <div>
                  {currency} 220.000 {t("Cancellation After Flight")}
                </div>
              </li>
            :
            " "  
            }
              </ul>
              <p>{t("No Show Charges")}</p>
            </div>
          </div>
          <div className="fd_row">
            <div className="fd_col">
              <ul>
                <li>
                  <div>{t("Date Change Fee")}</div>
                  <div />
                </li>
                <li>
                  <div>
                    {holdSearchId
                    ?
                    bookingDetails?.GoingDepartureAirport
                    :
                     totalPass?.Leg[0]?.Segements[0]?.departureAirport
                          ?.airportCode
                    }{" "}
                    -{" "}
                    {holdSearchId 
                      ?
                      bookingDetails?.goingArrivalAirport
                      :
                      tripType === 1 ?
                      totalPass?.Leg[1]?.Segements[0]?.departureAirport
                          ?.airportCode
                       :
                       totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                       ?.airportCode   
                      }
                  </div>
                  <div>
                    {currency} 110.000 {t("Date Change Fee Description")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p>
            <strong>{t("Please Note")}: </strong>
            {t("Note Description1")}.
          </p>
          <p>{t("Note Description2")}.</p>
        </div>
      ) : null}
    </div>
      }
    </div>
  );
}

export default FlightDetailsPopUp;
