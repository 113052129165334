import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function Passengers({
  passengerBox,
  setPassengerBox,
  openPassengerBox,
  useOutsideAlerter,
  adults,
  children,
  infants,
  addAdults,
  addChildren,
  addInfants,
  removeAdults,
  removeChildren,
  removeInfants,
  createCabin,
}) {
  const { passengers, setPassengers, cabin, setCabin, lang, locale } =
    useGlobals();

  let passengerBoxStyle = {
    display: "block",
    position: "absolute",
    top: "80px",
    zIndex: "3",
    height: "auto",
    right: "0",
    left: "auto",
  };

  const [cabinType, setCabinType] = useState("Economy");

  const handleCabinInput = (e) => {
    setCabin(e.target.value);
    if (cabin === 5) {
      setCabinType("Economy");
    }
    if (cabin === 3) {
      setCabinType("Business");
    }
    if (cabin === 1) {
      setCabinType("First");
    }
    createCabin(e.target.dataset.code);
  };

  const flightsPassengersRef = useRef(null);
  useOutsideAlerter(flightsPassengersRef);

  useEffect(() => {}, [cabinType]);

  const { t, i18n, ready } = useTranslation();

  return (
    <li className="passengers_class" ref={flightsPassengersRef}>
      <label
        htmlFor="passengers_class_input"
        style={locale === "ar" ? { marginLeft: "48%" } : { marginLeft: "0" }}
      >
        {t("Passenger_Class")}
      </label>
      <input
        type="text"
        value={`${
          passengers.adultCount +
          passengers.childrenCount +
          passengers.infantsCount
        } ${t("Traveler")}, ${cabin === 5 ? t("Economy") : ""}${
          cabin === 3 ? t("Business") : ""
        }${cabin === 1 ? t("First") : ""}`}
        onClick={openPassengerBox}
        id="passengers_class_input"
        readOnly
        style={
          locale === "ar" ? { paddingRight: "40px" } : { paddingRight: "0px" }
        }
      />

      <div
        className={passengerBox ? "passenger_class is_open" : "passenger_class"}
        id="passenger_class"
        style={
          locale === "ar"
            ? { left: "0", right: "auto" }
            : { left: "auto", right: "0" }
        }
      >
        <button
          aria-label="Close"
          type="button"
          className="big_close_button"
          onClick={() => {
            setPassengerBox(false);
          }}
        />
        <h2>{t("Traveler Class")}</h2>
        <input
          type="text"
          placeholder="1 Traveler, Economy_m"
          value={`${
            passengers.adultCount +
            passengers.childrenCount +
            passengers.infantsCount
          } ${t("Traveler")}, ${cabin === 5 ? t("Economy") : ""}${
            cabin === 3 ? t("Business") : ""
          }${cabin === 1 ? t("First") : ""}`}
          onClick={openPassengerBox}
          id="passengers_class_input_m"
          readOnly
        />
        <ul>
          <li>
            <h3
              style={
                locale === "ar"
                  ? { textAlignt: "right" }
                  : { textAlign: "left" }
              }
            >
              {t("Travelers")}
            </h3>
          </li>
          <li>
            <div className="passenger_label">
              {t("Adults")} <br />({t("Adults Age")})
            </div>
            <div className="passenger_input" dir="ltr">
              <button aria-label="Remove" type="button" onClick={removeAdults}>
                -
              </button>
              <input
                type="number"
                placeholder={0}
                value={adults}
                min="1"
                max="9"
                id="adults"
                name="adults"
                readOnly
              
              />
              <button aria-label="Add" type="button" onClick={addAdults}>
                +
              </button>
            </div>
          </li>
          <li>
            <div className="passenger_label">
              {t("Children")} <br />({t("Children Age")})
            </div>
            <div className="passenger_input" dir="ltr">
              <button
                aria-label="Remove"
                type="button"
                onClick={removeChildren}
              >
                -
              </button>
              <input
                type="number"
                placeholder={0}
                id="children"
                value={children}
                min="1"
                max="9"
                name="children"
                readOnly
              />
              <button aria-label="Add" type="button" onClick={addChildren}>
                +
              </button>
            </div>
          </li>
          <li>
            <div className="passenger_label">
              {t("Infants")} <br />({t("Infants Age")})
            </div>
            <div className="passenger_input" dir="ltr">
              <button aria-label="Remove" type="button" onClick={removeInfants}>
                -
              </button>
              <input
                type="number"
                placeholder={0}
                id="infants"
                value={infants}
                min="1"
                max="8"
                name="infants"
                readOnly
              />
              <button aria-label="Add" type="button" onClick={addInfants}>
                +
              </button>
            </div>
          </li>
        </ul>
        <hr />
        <ul>
          <li>
            <h3
              style={
                locale === "ar"
                  ? { textAlignt: "right" }
                  : { textAlign: "left" }
              }
            >
              {t("Travel Class")}
            </h3>
          </li>
          <li>
            <div className="class_label">
              <label htmlFor="economy_class">{t("Economy")}</label>
            </div>
            <div className="class_input">
              <div className="radio_container">
                <input
                  data-code="5"
                  type="radio"
                  id="economy_class"
                  defaultChecked={cabin === 5 ? "checked" : ""}
                  defaultValue="Economy"
                  name="flight_class"
                  onClick={(e) => handleCabinInput(e)}
                />
                <span className="radio_checkmark" />
                <label htmlFor="economy_class">{t("Economy")}</label>
              </div>
            </div>
          </li>
          <li>
            <div className="class_label">
              <label htmlFor="business_class">{t("Business")}</label>
            </div>
            <div className="class_input">
              <div className="radio_container">
                <input
                  data-code="3"
                  type="radio"
                  id="business_class"
                  defaultChecked={cabin === 3 ? "checked" : ""}
                  defaultValue="Business"
                  name="flight_class"
                  onClick={(e) => handleCabinInput(e)}
                />
                <span className="radio_checkmark" />
                <label htmlFor="business_class">{t("Business")}</label>
              </div>
            </div>
          </li>
          <li>
            <div className="class_label">
              <label htmlFor="first_class">{t("First")}</label>
            </div>
            <div className="class_input">
              <div className="radio_container">
                <input
                  data-code="1"
                  type="radio"
                  id="first_class"
                  defaultChecked={cabin === 1 ? "checked" : ""}
                  defaultValue="First"
                  name="flight_class"
                  onClick={(e) => handleCabinInput(e)}
                />
                <span className="radio_checkmark" />
                <label htmlFor="first_class">{t("First")}</label>
              </div>
            </div>
          </li>
          <li>
            <button
              aria-label="Apply"
              type="button"
              className="apply_button"
              onClick={() => {
                setPassengerBox(false);
              }}
            >
              {t("Apply Btn")}
            </button>
          </li>
        </ul>
        <small style={locale === "ar" ? { right: "15px" } : { left: "15px" }}>
          * {t("upto 9 passengers are allowed")}
        </small>
      </div>
    </li>
  );
}

export default Passengers;
