import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function Stops() {
  const {
    searchResults,
    stopsFilter,
    setStopsFilter,
    setResetAllFilters,
    resetAllFiltersApply,
    setResetAllFiltersApply,
    setSearchResults,
    directFlight,
    lang,
    locale,
    setFiltersArray,
    filtersArray
  } = useGlobals();

  const [maxStops, setMaxStops] = useState();
  const [minStops, setMinStops] = useState();

  const [toggle, setToggle] = useState(false);

  const calculateStops = () => {
    let maxStops = [];
    for (let i = 0; i < searchResults.length; i++) {
      for (let j = 0; j < searchResults[i].Itineraries.length; j++) {
        for (let k = 0; k < searchResults[i].Itineraries[j].Leg.length; k++) {
          maxStops.push(searchResults[i].Itineraries[j].Leg[k].NoOfStops);
        }
      }
    }

    setMaxStops(Math.max(...maxStops));
    setMinStops(Math.min(...maxStops));
  };

  // console.log(minStops);

  const [matchStop, setMatchStops] = useState();
  const [searchResultCopy, setSearchResultCopy] = useState([]);
  let resultCopy = [];

  const selectStops = (s, e) => {
    setResetAllFilters(true);
    setStopsFilter(s);
    setToggle(true);
    let stopsOptionList = document.querySelectorAll(
      ".filter_section.stops ul li button"
    );
    for (let i = 0; i < stopsOptionList.length; i++) {
      stopsOptionList[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
    

    document.querySelectorAll("[data-stops-same]").forEach((stops, e) => {
      if(s === 0){
      setFiltersArray((prevFilters) => ({
        ...prevFilters,
        "NonStop" : true,
        "OneStop" : false,
        "TwoStop" : false,
        }))
      } 
      if(s === 1){
        setFiltersArray((prevFilters) => ({
          ...prevFilters,
          "NonStop" : false,
          "OneStop" : true,
          "TwoStop" : false,
          }))
        } 
        if(s === 2){
          setFiltersArray((prevFilters) => ({
            ...prevFilters,
            "NonStop" : false,
            "OneStop" : false,
            "TwoStop" : true,
            }))
          } 
      // if (s === 0 && stops.dataset.stopsSame === "0") {
      //   stops.classList.remove("hide");
      //   stops.classList.add("show_all");
      // } else if (s === 1 && stops.dataset.stopsSame === "1") {
      //   stops.classList.remove("hide");
      //   stops.classList.add("show_all");
      // } else if (s === 2 && stops.dataset.stopsSame === "2") {
      //   stops.classList.remove("hide");
      //   stops.classList.add("show_all");
      // } else if (s === 3 && stops.dataset.stopsSame === "3") {
      //   stops.classList.remove("hide");
      //   stops.classList.add("show_all");
      // } else {
      //   stops.classList.add("hide");
      //   stops.classList.remove("show_all");
      // }
    });
  };

  useEffect(() => {
    if (searchResults.length !== 0) {
      calculateStops();
    }
  }, [searchResults, stopsFilter]);

  useEffect(() => {
    if (resetAllFiltersApply === true) {
      let stopsOptionList = document.querySelectorAll(
        ".filter_section.stops ul li button"
      );
      for (let i = 0; i < stopsOptionList.length; i++) {
        stopsOptionList[i].classList.remove("active");
      }

      document.querySelectorAll("[data-stops-same]").forEach((stops, e) => {
        stops.classList.add("show_all");
        stops.classList.remove("hide");
      });
      setResetAllFiltersApply(false);
      setToggle(!true);
      setResetAllFilters(false);
    }
    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      "NonStop" : false,
      "OneStop" : false,
      "TwoStop" : false,
      }))
  }, [resetAllFiltersApply]);

  const ResetStopFilters = (s, e) => {
    setToggle(!true);
    let stopsOptionList = document.querySelectorAll(
      ".filter_section.stops ul li button"
    );
    for (let i = 0; i < stopsOptionList.length; i++) {
      stopsOptionList[i].classList.remove("active");
    }

    // document.querySelectorAll("[data-stops]").forEach((stops, e) => {
    //   stops.classList.add("show_all");
    //   stops.classList.remove("hide");
    // });
    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      "NonStop" : false,
      "OneStop" : false,
      "TwoStop" : false,
      }))
  };

  const { t, i18n, ready } = useTranslation();

  return (
    <>
      {searchResults.length !== 0 ? (
        <div className="filter_section stops">
          <div className="row">
            <div className="col-lg-6 col-9">
              <strong>{t("Stops")}</strong>
            </div>
            {toggle === true || filtersArray?.NonStop === true || filtersArray?.OneStop === true || filtersArray?.TwoStop === true ? 
              <div className="col-lg-5 col-3 text-right reset">
                <strong onClick={ResetStopFilters}>{t("Reset")}</strong>
              </div>
              :
              null
            }
          </div>
          <ul>
            {minStops === 0 ? (
              <li>
                <button
                  type="button"
                  className={filtersArray?.NonStop === true ? "active" : ""}
                  onClick={(e) => {
                    selectStops(0, e);
                  }}
                >
                  <strong>{t("Non-Stop")}</strong>
                </button>
              </li>
            ) : null}
            {minStops === 1 || maxStops === 1 || maxStops > 1 ?
            <li>
            <button
              type="button"
              className={filtersArray?.OneStop === true ? "active" : ""}
              onClick={(e) => {
                selectStops(1, e);
                
              }}
            >
              <strong>1 {t("Stop")}</strong>
            </button>
          </li>
          :
          null

            }

            {maxStops > 2 ?
                <li>
                <button
                  type="button"
                  className={filtersArray?.TwoStop === true ? "active" : ""}
                  onClick={(e) => {
                    selectStops(3, e);
                  }}
                >
                  {/* <strong>{maxStops} {maxStops > 1 ? t('Stops') : t('Stop') }</strong> */}
                  <strong>2+ {maxStops > 1 ? t("Stops") : t("Stop")}</strong>
                </button>
              </li>
              :
              maxStops > 1 && maxStops === 2 ?
              <li>
                <button
                  type="button"
                  onClick={(e) => {
                    selectStops(2, e);
                  }}
                >
                  {/* <strong>{maxStops} {maxStops > 1 ? t('Stops') : t('Stop') }</strong> */}
                  <strong>2 {maxStops > 1 ? t("Stops") : t("Stop")}</strong>
                </button>
              </li>
              :
              null

            }
           
          </ul>
        </div>
      ) : (
        <div className="filter_section stops">
          <strong className="skeleton">{t("Stops")}</strong>
          <ul>
            <li>
              <button className="skeleton" type="button">
                &nbsp;
              </button>
            </li>
            <li>
              <button className="skeleton" type="button">
                &nbsp;
              </button>
            </li>
            <li>
              <button className="skeleton" type="button">
                &nbsp;
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

export default Stops;
