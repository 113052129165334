import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import { format } from "date-fns";
import "../../css/flight_details.css";
import { useTranslation } from "react-i18next";

function FlightDetails({
  setRenderFlightDetails,
  getItineraries,
  showBaggageInfoTab,
  pricingInfo,
  totalCharges,
  totalPass,
  departureAirport,
}) {
  let navigate = useNavigate();

  const {
    bookingDetails,
    siteURLS,
    setIsloading,
    searchId,
    setBookingRef,
    setIsFlightDetailsOpen,
    getItinerariesCopy,
    setItinerariesCopy,
    lang,
    locale,
    segArray,
    setSegArray,
    currency, tripType
  } = useGlobals();
  const [itineraryTab, setItineraryTab] = useState(true);
  const [baggageTab, setBaggageTab] = useState(false);
  const [fareTab, setFareTab] = useState(false);

  const [itinArr, setItinArr] = useState([]);

  const showItineraryTab = () => {
    setItineraryTab((current) => !current);
    setBaggageTab(false);
    setFareTab(false);
  };
  const showBaggageTab = () => {
    setBaggageTab((current) => !current);
    setItineraryTab(false);
    setFareTab(false);
  };
  const showFareTab = () => {
    setFareTab((current) => !current);
    setItineraryTab(false);
    setBaggageTab(false);
  };

  let BookingRef = "";

  const BookNow = () => {
    document.body.classList.remove("stop_overflow");
    document.getElementById("root").classList.remove("search_results");
    document.getElementById("root").classList.add("passenger_information");

    setIsloading(true);

    fetch(`${process.env.REACT_APP_FLIGHT_URL}/Flight/SaveItinerary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SearchId: searchId,
        ItineraryKey: bookingDetails.itineraryKey,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.responseModel.bookingRef) {
          setBookingRef(data.responseModel.bookingRef);
          BookingRef = data.responseModel.bookingRef;
        }
        setIsloading(false);

        navigate(`/${locale}/passenger_information/${BookingRef}`);
      })
      .catch((err) => console.log(err));
  };

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}h ${m}m`;
  };

  const getTheDate = (date) => {
    var parts = date.slice(0, -1).split("T");
    var onlyDate = parts[0];
    return onlyDate;
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return dt;
  };

  const getFormattedTime = (time) => {};

  const makeLowerCase = (s) => {
    return s?.toLowerCase();
  };

  const closeFlightDetails = () => {
    setRenderFlightDetails(false);
    setIsFlightDetailsOpen(false);
    setIsFlightDetailsOpen(false);
  };


  const makeItiniraryArray = () => {
    let bigItinArr = [];
    for (let i = 0; i < getItineraries[0].Leg.length; i++) {
      for (let j = 0; j < getItineraries[0].Leg[i].Segements.length; j++) {
       if(tripType === 0){
        bigItinArr.push({
          timeTest: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          date: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time_elapsed: [
            getItineraries[0].Leg[i].Segements[j].flights[0].elapsedTime,
          ],
          airport_code: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportCode,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportCode,
          ],
          airport_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportName,
          ],
          city_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.cityName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.cityName,
          ],
          airline_code: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
          ],
          flight_number: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
          ],
          aircraft_make: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          aircraft_model: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          cabin_class: [
            getItineraries[0].Leg[i].Segements[j].cabinCode,
            getItineraries[0].Leg[i].Segements[j].cabinCode,
          ],
          arrival_next_day: [
            getItineraries[0].Leg[i].Segements[j].flights[0]
              .arrivalDateAdjustment,
          ],
          baggage: [
            getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
          ],
          baggageB: [
            getItineraries[0]?.passengers[0]?.baggage[1]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[1]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[1]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[1]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[1]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[1]?.pieceCount,
          ],
        });
       }
       else if(tripType === 0 && _SearchID){
          bigItinArr.push({
            timeTest: [
              getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
              getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
            ],
            time: [
              getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
              getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
            ],
            date: [
              getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
              getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
            ],
            time_elapsed: [
              getItineraries[0].Leg[i].Segements[j].flights[0].elapsedTime,
            ],
            airport_code: [
              getItineraries[0].Leg[i].Segements[j].departureAirport.airportCode,
              getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportCode,
            ],
            airport_name: [
              getItineraries[0].Leg[i].Segements[j].departureAirport.airportName,
              getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportName,
            ],
            city_name: [
              getItineraries[0].Leg[i].Segements[j].departureAirport.cityName,
              getItineraries[0].Leg[i].Segements[j].arrivalAirport.cityName,
            ],
            airline_code: [
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
            ],
            flight_number: [
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier
                .operatingFlightNumber,
              getItineraries[0].Leg[i].Segements[j].flights[0].carrier
                .operatingFlightNumber,
            ],
            aircraft_make: [
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            ],
            aircraft_model: [
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
              getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            ],
            cabin_class: [
              getItineraries[0].Leg[i].Segements[j].cabinCode,
              getItineraries[0].Leg[i].Segements[j].cabinCode,
            ],
            arrival_next_day: [
              getItineraries[0].Leg[i].Segements[j].flights[0]
                .arrivalDateAdjustment,
            ],
            baggage: [
              getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
              getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
              getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
              getItineraries[0]?.passengers[0]?.baggage[j]?.description[0],
              getItineraries[0]?.passengers[0]?.baggage[j]?.description[1],
              getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
            ],
            baggageB: [
              getItineraries[0]?.passengers[0]?.baggage[1]?.provisionType,
              getItineraries[0]?.passengers[0]?.baggage[1]?.weight,
              getItineraries[0]?.passengers[0]?.baggage[1]?.unit,
              getItineraries[0]?.passengers[0]?.baggage[1]?.description[0],
              getItineraries[0]?.passengers[0]?.baggage[1]?.description[1],
              getItineraries[0]?.passengers[0]?.baggage[1]?.pieceCount,
            ],
          });
         
       }
       else{
        bigItinArr.push({
          timeTest: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          date: [
            getItineraries[0].Leg[i].Segements[j].flights[0].departureDateTime,
            getItineraries[0].Leg[i].Segements[j].flights[0].arrivalDateTime,
          ],
          time_elapsed: [
            getItineraries[0].Leg[i].Segements[j].flights[0].elapsedTime,
          ],
          airport_code: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportCode,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportCode,
          ],
          airport_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.airportName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.airportName,
          ],
          city_name: [
            getItineraries[0].Leg[i].Segements[j].departureAirport.cityName,
            getItineraries[0].Leg[i].Segements[j].arrivalAirport.cityName,
          ],
          airline_code: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier.marketing,
          ],
          flight_number: [
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
            getItineraries[0].Leg[i].Segements[j].flights[0].carrier
              .operatingFlightNumber,
          ],
          aircraft_make: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          aircraft_model: [
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
            getItineraries[0].Leg[i].Segements[j].flights[0].equipmentCode,
          ],
          cabin_class: [
            getItineraries[0].Leg[i].Segements[j].cabinCode,
            getItineraries[0].Leg[i].Segements[j].cabinCode,
          ],
          arrival_next_day: [
            getItineraries[0].Leg[i].Segements[j].flights[0]
              .arrivalDateAdjustment,
          ],
          baggage: [
            getItineraries[0]?.passengers[0]?.baggage[j]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[j]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[j]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[j]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[j]?.pieceCount,
          ],
          baggageB: [
            getItineraries[0]?.passengers[0]?.baggage[2]?.provisionType,
            getItineraries[0]?.passengers[0]?.baggage[2]?.weight,
            getItineraries[0]?.passengers[0]?.baggage[2]?.unit,
            getItineraries[0]?.passengers[0]?.baggage[2]?.description[0],
            getItineraries[0]?.passengers[0]?.baggage[2]?.description[1],
            getItineraries[0]?.passengers[0]?.baggage[2]?.pieceCount,
          ],
        });
       }
      }
    }
    setItinArr(bigItinArr);
  };

  const calcPassengersQty = (psngrArray) => {
    let totalPsngrs = 0;
    for (let i = 0; i < psngrArray.length; i++) {
      totalPsngrs += psngrArray[i].qty;
    }
    return totalPsngrs;
  };

  useEffect(() => {
    makeItiniraryArray();
    if (showBaggageInfoTab === true) {
      setItineraryTab(false);
      setBaggageTab(true);
      setFareTab(false);
    }
  }, []);

  const { holdSearchId } = useParams();

  let pathName = window.location.pathname.split("/");
  pathName = pathName[1];

  const searchResult = useLocation().search;
  const _SearchID = new URLSearchParams(searchResult).get("SearchId");

  const { t, i18n, ready } = useTranslation();

  let localePath;
  const [text, setText] = useState(t('Search Results'));
  useEffect(() => {
     localePath = window.location.pathname.split("/");
    if (
      localePath[2] === "passenger_information" ||
      localePath[2] === "payment_details"
    ) {
      document.getElementsByClassName("bookNowBtn")[0].style.display = "none";
      setText(t('Back to Itinerary'));
    }
  }, []);

    // setItinArr(bigItinArr);
// console.log(itinArr);
// console.log(totalPass);
// console.log(getItineraries[0]);
console.log(tripType);

  return (
    <div className="flight_detials_popup" dir={locale === "ar" ? "rtl" : "ltr"}>
      <div className="flight_detail_body">
        <div className="flight_detials_cta">
          <div className="flight_row_col">
            <div className="desk_info">
              <small>{t("Flight Details")}</small>
              <strong>
                {
                  getItineraries[0]?.Leg[0]?.Segements[0]?.departureAirport
                    ?.cityName
                }{" "}
                {bookingDetails?.GoingDepartureAirport} -{" "}
                {
                  getItineraries[0]?.Leg[0]?.Segements[
                    getItineraries[0]?.Leg[0]?.Segements?.length - 1
                  ]?.arrivalAirport?.cityName
                }{" "}
                {bookingDetails?.goingArrivalAirport}
              </strong>
              <span>
                —{" "}
                {holdSearchId
                  ? bookingDetails?.goingDepartureDate
                  : getFormattedDate(
                      totalPass?.Leg[0]?.Segements[0]?.flights[0]?.departureDateTime
                        ?.split(" ")[0]
                        .substring(0, 10)
                    )}
              </span>
            </div>
            <div className="price">
              <small className="total_price">{t("Total price")}</small>
              <div className="the_price">
                <sup>{currency}</sup>{" "}
                {totalCharges && totalCharges
                  ? totalCharges
                  : bookingDetails.price}
              </div>
              <small>
                {t("Price for")}{" "}
                {calcPassengersQty(getItineraries[0]?.passengers)} {t("Person")}
                {calcPassengersQty(getItineraries[0]?.passengers) > 1
                  ? "s"
                  : null}
              </small>
            </div>
            <div className="cta">
              {getItineraries[0]?.passengers[0]?.refundability ===
              "REFUNDABLE" ? (
                <div className="refundable">{t("Refundable")}</div>
              ) : (
                <div className="refundable"> </div>
              )}
              {pathName === "passenger_information" ||
              pathName === "payment_details" ? null : (
                <button
                  aria-label="Book Now"
                  type="button"
                  onClick={BookNow}
                  className="bookNowBtn"
                >
                  {t("Book Now Button")}
                </button>
              )}
            </div>
          </div>
        </div>
        <button
          type="button"
          className="close"
          onClick={() => closeFlightDetails()}
        >
          {" "}
          ✕{" "}
        </button>
        <div className="flight_detail_mobile_header">
          <button
            aria-label="Search Results"
            type="button"
            onClick={() => {
              setRenderFlightDetails(false);
              setIsFlightDetailsOpen(false);
            }}
          />
          <span>{text}</span>
        </div>
        <div className="tabs">
          <ul>
            <li
              onClick={() => {
                showItineraryTab();
              }}
              className={itineraryTab ? "active" : ""}
            >
              {t("Itenrary")}
            </li>
            <li
              onClick={() => {
                showFareTab();
              }}
              className={fareTab ? "active" : ""}
            >
              {t("Fare")}
            </li>
            <li
              onClick={() => {
                showBaggageTab();
              }}
              className={baggageTab ? "active" : ""}
            >
              {t("Baggage")}
            </li>
          </ul>
        </div>
        {itineraryTab ? (
          <div className="flight_detials_itinerary">
            {itinArr.map((itinAr, index) => {
              return (
                <div className="fd_row" key={index}>
                  <div className="fd_col">
                    {locale === "en" ? (
                      <strong>
                        {itinAr.timeTest[0].split(" ")[0].substring(16, 11)} →{" "}
                        {itinAr.timeTest[1].split(" ")[0].substring(16, 11)}
                      </strong>
                    ) : (
                      <strong>
                        {itinAr.timeTest[0].split(" ")[0].substring(16, 11)} ←{" "}
                        {itinAr.timeTest[1].split(" ")[0].substring(16, 11)}
                      </strong>
                    )}
                    <br />
                    <span>{getFormattedDate(itinAr.date[0])}</span>
                    <span className="leg_time">
                      {convertMinsToHrsMins(itinAr.time_elapsed[0])}
                    </span>
                  </div>
                  <div className="fd_col point">&nbsp;</div>
                  <div className="fd_col">
                    <strong style={{ position: "relative" }}>
                      {itinAr.airport_name[0]} ({itinAr.airport_code[0]}) →{" "}
                      {itinAr.airport_name[1]} ({itinAr.airport_code[1]})
                      {itinAr.arrival_next_day[0] > 0 ? (
                        <span
                          className="nextDayArrival"
                          style={{
                            top: "auto",
                            left: "auto",
                            verticalAlign: "text-top",
                            display: "inline-block",
                            lineHeight: "1",
                            margin: "-2.5px 5px 0 5px",
                          }}
                          title={
                            `Arrival after + ` +
                            itinAr.arrival_next_day[0] +
                            ` Day`
                          }
                        >
                          {locale === "en" && "+"}
                          {itinAr.arrival_next_day[0]}
                          {locale === "ar" && "+"}
                        </span>
                      ) : (
                        ""
                      )}
                    </strong>
                    <br />
                    <span className="muted">{itinAr.city_name[0]}</span>
                    <br />
                    <span>
                      {itinAr.arrival_next_day[0] > 0
                        ? `${t("Next Day Arrival")} \n`
                        : ""}
                    </span>
                    <br />
                    {_SearchID ? (
                      <div
                        className="fd_a_logo"
                        style={{
                          backgroundImage:
                            "url(/img/airlines/" +
                            itinAr.airline_code[0] +
                            ".png)",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="fd_a_logo"
                        style={{
                          backgroundImage:
                          `url(${window.location.origin}/img/airlines/${itinAr.airline_code[0]}.png)`,
                            // "url(../../img/airlines/" +
                            // itinAr.airline_code[0] +
                            // ".png)",
                        }}
                      ></div>
                    )}

                    <span>
                      {/* {itinAr.airline_code[0]}  */}
                      {itinAr.airline_code[0]}-
                      {itinAr.flight_number[0]}
                    </span>
                    <br />
                    {_SearchID ? (
                      <img
                        className="icon"
                        src="/img/airplane-departure.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className="icon"
                        src={window.location.origin + "/img/airplane-departure.svg"}
                        alt=""
                      />
                    )}
                    <span>
                      {itinAr.aircraft_model[0]} {t(itinAr.cabin_class[0])}
                    </span>
                    <br />
                    {_SearchID ? (
                      <img
                        className="icon"
                        src={window.location.origin + "/img/travel-bag-1.svg"}
                        alt=""
                      />
                    ) : (
                      <img
                        className="icon"
                        src={window.location.origin + "/img/travel-bag-1.svg"}
                        alt=""
                      />
                    )}
                    <span>
                      {itinAr.baggage[0] === "A" && itinAr?.baggage[1] > 0 ||
                      itinAr.baggage[0] === "A" && itinAr?.baggage[3] !== null && itinAr.baggage[5] > 0
                        ? t("Checkin Baggage")
                        : t("Carry On Baggage")}{" "}
                    </span>
                    <br />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {baggageTab ? (
          <div className="flight_detials_baggage">
            <div className="fd_row">
              <strong>{t("Checkin Baggage")}</strong>
              <div>
                <h3 />
                <div>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <th>{t("Airline")}</th>
                          <th>{t("Sector")}</th>
                          <th>{t("Baggage Details")}</th>
                          <th>
                            {t("Weight")} / {t("Piece Count")}
                          </th>
                        </tr>
                        {itinArr.map((itinAr, index) => {
                          return (
                            <>
                            {
                            itinAr.baggage[0] === "A" && itinAr?.baggage[1] > 0 ||
                            itinAr.baggage[0] === "A" && itinAr?.baggage[3] !== null && itinAr.baggage[5] > 0 ?
                            <tr key={index}>
                            <td>
                              {_SearchID ? (
                                <img
                                  src={
                                    "/img/airlines/" +
                                    itinAr.airline_code[0] +
                                    ".png"
                                  }
                                  alt={itinAr.airline_code[0]}
                                  width="24px"
                                ></img>
                              ) : (
                                <img
                                  src={window.location.origin +
                                    "/img/airlines/" +
                                    itinAr.airline_code[0] +
                                    ".png"
                                  }
                                  alt={itinAr.airline_code[0]}
                                  width="24px"
                                ></img>
                              )}
                            </td>
                            <td>
                              {" "}
                              {itinAr.airport_code[0]} -{" "}
                              {
                                itinAr.airport_code[
                                  itinAr.airport_code.length - 1
                                ]
                              }{" "}
                            </td>
                            <td>
                              {itinAr.baggage[0] === "A" &&
                               t("Checkin Baggage")
                              //  :
                              //  t("Carry On Baggage")
                              }
                            </td>
                            <td>
                              {itinAr.baggage[0] === "A" && itinAr.baggage[1] && itinAr.baggage[1] > 1
                                ? t(itinAr?.baggage[1])
                                : null}{" "}
                              {itinAr.baggage[0] === "A" && itinAr.baggage[2] && itinAr.baggage[1] > 1
                                ? t(itinAr?.baggage[2])
                                : null}{" "}
                              {/* {itinAr.baggage[3] === null &&
                              itinAr.baggageB[3] === null
                                ? null
                                : itinAr.baggage[3] !== null
                                ? makeLowerCase(itinAr?.baggage[3])
                                : makeLowerCase(itinAr?.baggageB[3])} */}
                                {makeLowerCase(itinAr?.baggage[3])}
                              {itinAr.baggage[0] === "A" && itinAr?.baggage[3] !== null ? "\n" : null}
                              {/* {itinAr.baggage[4] === null &&
                              itinAr.baggageB[4] === null
                                ? null
                                : itinAr.baggage[4] !== null
                                ? makeLowerCase(t(itinAr?.baggage[4]))
                                : makeLowerCase(t(itinAr?.baggageB[4]))} */}
                                {itinAr.baggage[0] === "A" &&
                                makeLowerCase(t(itinAr?.baggage[4]))}
                                {'\n'}
                                {itinAr.baggage[0] === "A" && itinAr.baggage[5] > 0 && itinAr.baggage[5] < 2
                                   ? "\n" + itinAr.baggage[5] + " " + t("Piece")
                                  : 
                                  itinAr.baggage[5] > 1 ?
                                  itinAr.baggage[5] + " " + t("Pieces")
                                :
                                null}
                                {/* {itinAr.baggage[5] != 0
                                ? itinAr.baggage[5] > 0 && itinAr.baggage[5] < 2
                                  ? "\n" + itinAr.baggage[5] + " " + t("Piece")
                                  : itinAr.baggage[5] + " " + t("Pieces")
                                : itinAr.baggageB[5] > 0 && itinAr.baggageB[5] < 2
                                ? itinAr.baggageB[5] + " " + t("Piece")
                                : itinAr.baggageB[5] === undefined ?
                                null 
                                :
                                itinAr.baggageB[5] + " " + t("Pieces")
                                } */}
                            </td>
                          </tr>
                          :
                          null
                            }
                            {itinAr.baggageB[0] === "B" && 
 <tr key={index+1}>
 <td>
   {_SearchID ? (
     <img
       src={
         "/img/airlines/" +
         itinAr.airline_code[0] +
         ".png"
       }
       alt={itinAr.airline_code[0]}
       width="24px"
     ></img>
   ) : (
     <img
       src={window.location.origin + 
         "/img/airlines/" +
         itinAr.airline_code[0] +
         ".png"
       }
       alt={itinAr.airline_code[0]}
       width="24px"
     ></img>
   )}
 </td>
 <td>
   {" "}
   {itinAr.airport_code[0]} -{" "}
   {
     itinAr.airport_code[
       itinAr.airport_code.length - 1
     ]
   }{" "}
 </td>
 <td>
   {itinAr.baggageB[0] === "B" &&
      t("Carry On Baggage")}
 </td>
 <td>
   {itinAr.baggageB[1] && itinAr.baggageB[1] > 1
     ? t(itinAr?.baggageB[1])
     : null}{" "}
     
   {itinAr.baggageB[2] && itinAr.baggageB[1] > 1
     ? t(itinAr?.baggageB[2])
     : null}{" "}
     {itinAr.baggageB[2] && itinAr.baggageB[1] > 1 ? '\n' : null}
   {/* {itinAr.baggage[3] === null &&
   itinAr.baggageB[3] === null
     ? null
     : itinAr.baggage[3] !== null
     ? makeLowerCase(itinAr?.baggage[3])
     : makeLowerCase(itinAr?.baggageB[3])} */}
      
   {itinAr.baggageB[3] !== null &&
     makeLowerCase(itinAr?.baggageB[3])}
   {itinAr?.baggageB[3] !== null ? "\n" : null}
   {/* {itinAr.baggage[4] === null &&
   itinAr.baggageB[4] === null
     ? null
     : itinAr.baggage[4] !== null
     ? makeLowerCase(t(itinAr?.baggage[4]))
     : makeLowerCase(t(itinAr?.baggageB[4]))} */}
     {itinAr.baggageB[4] !== null &&
     makeLowerCase(itinAr?.baggageB[4])}
     {itinAr?.baggageB[4] !== null ? '\n' : null}
     {/* {itinAr.baggage[5] != 0
     ? itinAr.baggage[5] > 0 && itinAr.baggage[5] < 2
       ? "\n" + itinAr.baggage[5] + " " + t("Piece")
       : itinAr.baggage[5] + " " + t("Pieces")
     : itinAr.baggageB[5] > 0 && itinAr.baggageB[5] < 2
     ? itinAr.baggageB[5] + " " + t("Piece")
     : itinAr.baggageB[5] === undefined ?
     null 
     :
     itinAr.baggageB[5] + " " + t("Pieces")
     } */}
       {
     itinAr.baggageB[5] > 0 && itinAr.baggageB[5] < 2
     ? itinAr.baggageB[5] + " " + t("Piece")
     : itinAr.baggageB[5] === undefined ?
     null 
     :
     itinAr.baggageB[5] > 1 ?
     itinAr.baggageB[5] + " " + t("Pieces")
     :
     null
     }
 </td>
</tr>
                            }
                           
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <p>{t("Baggage Section Description")}</p>
              </div>
            </div>
          </div>
        ) : null}
        {fareTab ? (
          <div className="flight_detials_fare">
            <div className="fd_row">
              <strong>{t("Fare Breakup")}</strong>
              <div className="fd_col">
                <ul>
                  {getItinerariesCopy && getItinerariesCopy[0] === null
                    ? getItineraries[0]?.passengers?.map((passenger, i) => {
                        return (
                          <li key={i}>
                            <div>
                              {locale === "en" ? passenger.qty : ""}
                              {passenger.type === "ADT" ? " Adult" : null}{" "}
                              {passenger.type === "CNN" ? " Child" : null}{" "}
                              {passenger.type === "INF" ? " Infant" : null}(
                              {passenger.qty} x{" "}
                              {Number(passenger.price.totalFareAmount).toFixed(
                                2
                              )}
                              )
                            </div>
                            <div>
                              {passenger.price.currency}{" "}
                              {passenger.qty *
                                Number(passenger.price.totalFareAmount).toFixed(
                                  2
                                )}
                            </div>
                            <br />
                            <div>{t("Taxes & Fees")}</div>
                            <div>
                              {currency}{" "}
                              {Number(passenger.price.totalTaxAmount * passenger.qty).toFixed(2)}
                            </div>
                          </li>
                        );
                      })
                    : getItinerariesCopy[0]?.passengers?.map((passenger, i) => {
                        return (
                          <li key={i}>
                            <div>
                              {passenger.qty}
                              {passenger.type === "ADT"
                                ? t("Adult")
                                : null}{" "}
                              {passenger.type === "CNN" ? t("Child") : null}{" "}
                              {passenger.type === "INF" ? t("Infant") : null}(
                              {passenger.qty} x{" "}
                              {passenger.price.totalFareAmount})
                            </div>
                            <div>
                              {passenger.price.currency}{" "}
                              {passenger.qty * passenger.price.totalFareAmount}
                            </div>
                            <br />
                            <div>{t("Taxes & Fees")}</div>
                            <div>
                              {currency}{" "}
                              {passenger.price.totalTaxAmount * passenger.qty}
                            </div>
                          </li>
                        );
                      })}
                  <li className="total_price">
                    <div>{t("Total Fare")}</div>
                    <div>
                      {currency}{" "}
                      {totalCharges
                        ? totalCharges
                        : getItineraries[0].TotalPrice}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="fd_row">
              <strong>{t("Fare Rules")}</strong>
              <div className="fd_col">
                <ul>
                  <li>
                    <div>{t("Cancellation Fee")}</div>
                    <div />
                  </li>
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                       }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.goingArrivalAirport
                        :
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                        ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }
                    </div>
                    <div>
                      {currency} 110.000 {t("Cancellation Before Flight")}
                    </div>
                  </li>
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails.goingArrivalAirport
                        : totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                        ?.airportCode
                        }{" "}
                      -{" "}
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                        : 
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                            ?.airportCode
                        :
                        totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                        }{" "}
                    </div>
                    <div>
                      {currency} 220.000 {t("Cancellation After Flight")}
                    </div>
                  </li>
                </ul>
                <p>{t("No Show Charges")}</p>
              </div>
            </div>
            <div className="fd_row">
              <div className="fd_col">
                <ul>
                  <li>
                    <div>{t("Date Change Fee")}</div>
                    <div />
                  </li>
                  <li>
                    <div>
                      {holdSearchId
                      ?
                      bookingDetails?.GoingDepartureAirport
                      :
                       totalPass?.Leg[0]?.Segements[0]?.departureAirport
                            ?.airportCode
                      }{" "}
                      -{" "}
                      {holdSearchId 
                        ?
                        bookingDetails?.goingArrivalAirport
                        :
                        tripType === 1 ?
                        totalPass?.Leg[1]?.Segements[0]?.departureAirport
                            ?.airportCode
                         :
                         totalPass?.Leg[0]?.Segements[0]?.arrivalAirport
                         ?.airportCode   
                        }
                    </div>
                    <div>
                      {currency} 110.000 {t("Date Change Fee Description")}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <p>
              <strong>{t("Please Note")}: </strong>
              {t("Note Description1")}.
            </p>
            <p>{t("Note Description2")}.</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FlightDetails;
