import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../css/about.css";
import { GlobalContext } from "../../contexts/globalContexts";

import EilagoLogoDark from "../../img/d/eilago_logo_dark.svg";
import EilagoLogoLight from "../../img/eilago_logo_dark.svg";

const About = () => {
  const { selectCurrency, lang, locale } = useContext(GlobalContext);
  const { t, i18n, ready } = useTranslation();

  return (
    <section className="container-fluid about">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4">
            <img
              src={EilagoLogoLight}
              alt=""
              className="light margin_left_arabic"
            />
            <img
              src={EilagoLogoDark}
              alt=""
              className="dark margin_left_arabic"
            />
          </div>
          <div className="col-12 col-md-12 col-lg-8">
            <h2
              style={
                locale === "ar" ? { textAlign: "Right" } : { textAlign: "left" }
              }
            >
              {t("About Eilago")}
            </h2>
            <p
              style={
                locale === "ar" ? { textAlign: "Right" } : { textAlign: "left" }
              }
            >
              {t("About Eilago Description Section1")}
            </p>
            <Link
              to={`/${locale}/about_us`}
              style={
                locale === "ar" ? { float: "Right" } : { textAlign: "left" }
              }
            >
              {t("Learn More")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
