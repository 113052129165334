import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import "../../css/top_deals.css";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";

function TopDeals() {
  const { lang, locale } = useGlobals();
  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  let localePath;
  localePath = window.location.pathname.split("/");


  const [allTopDeals, setAllTopDeals] = useState([]);

  useEffect(() => {

    fetch(`${process.env.REACT_APP_PROMOTIONS}/CMS/GetFeaturedPromotions`)
      .then((response) => response.json())
      .then((data) => {
        let deals = []
        setAllTopDeals(data.responseModel)
      })

  }, [])


  return (
    <section className="container top_deals">
      {isMobile ? <div className="top_deals_header">
        {allTopDeals?.length !== 0 ? <h2>Featured Deals</h2> : ''}
        {allTopDeals?.length !== 0 ? <Link to={`/${locale}/deals`}>
          {t("View More")}
        </Link> : ''}
      </div> : ''}
      {isMobile ? <Carousel 
        axis="horizontal" 
        autoPlay={true} 
        // centerMode = {true}
        centerMode = {allTopDeals?.length >= 3 ? true : false}
        centerSlidePercentage={90} 
        selectedItem={1} 
        infiniteLoop={true}
        >

        {allTopDeals?.map((allTopDeal) => (
          <div key={allTopDeal.id}>
            <Link to={`/${locale}/deal_details/${allTopDeal.id}`}>
              <div className="deal_image">
                {locale === 'en' ? <img src={allTopDeal.tileImageURL} /> : <img src={allTopDeal.tileImageURLArabic} />}
              </div>
              <div className="deal_text">
                <strong>{locale === 'en' ? allTopDeal.tileTitle : allTopDeal.tileTitleArabic}</strong>
                <p>{locale === 'en' ? allTopDeal.tileSubTitle : allTopDeal.tileSubTitleArabic}</p>
              </div>
            </Link>
          </div>
        ))}
      </Carousel> :
        <Carousel
          axis="horizontal" autoPlay={true}
          selectedItem={0}
          centerMode = {true}
          centerSlidePercentage={33.33}
          // infiniteLoop={allTopDeals?.length < 2 ? false : true} 
        >
          {allTopDeals?.map((allTopDeal) => (
            <div key={allTopDeal.id}>
              <Link to={`/${locale}/deal_details/${allTopDeal.id}`}>
                <div className="deal_image">
                  {locale === 'en' ? <img src={allTopDeal.tileImageURL} /> : <img src={allTopDeal.tileImageURLArabic} />}
                </div>
                <div className="deal_text">
                  <strong>{locale === 'en' ? allTopDeal.tileTitle : allTopDeal.tileTitleArabic}</strong>
                  <p>{locale === 'en' ? allTopDeal.tileSubTitle : allTopDeal.tileSubTitleArabic}</p>
                </div>
              </Link>
            </div>
          ))}
        </Carousel>
      }

    </section>
  );
}

export default TopDeals;
