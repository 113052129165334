import { useGlobals } from "../../contexts/globalContexts";
import "../../css/search_sort.css";
import { isMobile } from "react-device-detect";
import { useState, useEffect } from "react";
import PriceSorting from "./PriceSorting";
import DurationSorting from "./DurationSorting";
import ArrivalSorting from "./ArrivalSorting";
import DepartureSorting from "./DepartureSorting";
import { useTranslation } from "react-i18next";
import SortingTickW from "../../img/sorting_tick_w.svg"
import SortingTickD from "../../img/d/sorting_tick_d.svg"

function SearchSorting({
  closeFiltersSorting,
  msorting,
  openSorting,
  mfilters,
  openFilters,
}) {
  const { searchResults, lang, locale, filterMargin, setFilterMargin, setPriceSortingValue, priceSortingValue,
    durationSorting, setDurationSorting, setDepartureSorting, departureSorting, setArrivalSorting, arrivalSorting
  } = useGlobals();

  const countResults = (s) => {
    let x = 0;
    for (let i = 0; i < s.length; i++) {
      x += s[i].Itineraries.length;
    }
    return x;
  };

  const { t, i18n, ready } = useTranslation();

  // useEffect(() => {
  //   if(isMobile){
  //     if(priceSortingValue === "Low"){
  //       setDurationSorting('');
  //       setDepartureSorting('');
  //       setArrivalSorting('');
  //     }
  //     if(durationSorting === "Shortest"){
  //       setPriceSortingValue('');
  //       setDepartureSorting('');
  //       setArrivalSorting('');
  //     }
  //     if(departureSorting === "Early"){
  //       setPriceSortingValue('');
  //       setArrivalSorting('');
  //       setDurationSorting('');
  //       if(departureSorting === "Late"){
  //         setDepartureSorting('Early');
  //       }
  //     }
  //     if(departureSorting === "Late"){
  //       setPriceSortingValue('');
  //       setArrivalSorting('');
  //       setDurationSorting('');
  //       if(departureSorting === "Early"){
  //         setDepartureSorting('Late');
  //       }
  //     }
  //     if(arrivalSorting === "Early"){
  //       setPriceSortingValue('');
  //       setDurationSorting('');
  //       setDepartureSorting('');
  //       if(arrivalSorting === "Late"){
  //         setArrivalSorting('Early');
  //       }
  //     }
  //     if(arrivalSorting === "Late"){
  //       setPriceSortingValue('');
  //       setDurationSorting('');
  //       setDepartureSorting('');
  //       if(arrivalSorting === "Early"){
  //         setArrivalSorting('Late');
  //       }
  //     }
  //     console.log('Price', priceSortingValue);
  //     console.log('Duration', durationSorting);
  //     console.log('Dep', departureSorting);
  //     console.log('Arrival', arrivalSorting);
  //   }
  // }, [priceSortingValue, durationSorting, departureSorting, arrivalSorting]);

  return (
    <section
      className="search_sort"
      id="search_sort"
      style={msorting ? { display: "block" } : null}
    >
      <button
        type="button"
        className="big_close_button"
        id="close_search_sort"
        onClick={() => closeFiltersSorting()}
      />
      <button
        type="button"
        className="big_back_button"
        id="back_search_sort"
        onClick={() => closeFiltersSorting()}
      />
       <h2 style={isMobile ? { display: "block" } : { display: "none" }}>
            {t("Search Results")}
          </h2>
      {isMobile ? (
        <div className={filterMargin === true ? "m_filter_tabs" : "m_filter_tabs"}>
          <button
            type="button"
            className={mfilters ? "m_filter_btn active" : "m_filter_btn"}
            onClick={() => {
              openFilters();
              setFilterMargin(true);
            }}
          >
            {/* svg */}
            <svg viewBox="0 0 44 44">
              <g transform="translate(-53 -84)">
                <g transform="translate(67 98)">
                  <g transform="translate(0.292 0.292)">
                    <path
                      className="a"
                      d="M15.917.5H.5L7.2,8.6v7.313l2.011-2.011V8.6Z"
                      transform="translate(-0.5 -0.5)"
                    />
                  </g>
                  <rect className="b" width={16} height={16} />
                </g>
                <rect
                  className="b"
                  width={44}
                  height={44}
                  transform="translate(53 84)"
                />
              </g>
            </svg>
            {t("Filter")}
          </button>
          <button
            type="button"
            className={msorting ? "m_sort_btn active" : "m_sort_btn"}
            onClick={() => {
              openSorting();
              setFilterMargin(false)
            }}
          >
            {/* svg */}
            <svg viewBox="0 0 44 44">
              <g transform="translate(-221 -75)">
                <rect
                  className="a"
                  width={44}
                  height={44}
                  transform="translate(221 75)"
                />
                <g transform="translate(-15736.5 162)">
                  <line
                    className="b"
                    x2={12}
                    transform="translate(15970.5 -64.5)"
                  />
                  <path
                    className="c"
                    d="M0,0H19"
                    transform="translate(15970.5 -70.5)"
                  />
                  <line
                    className="b"
                    x2={8}
                    transform="translate(15970.5 -58.5)"
                  />
                </g>
              </g>
            </svg>
            {t("Sorting")}
          </button>
        </div>
      ) : null}
      <div
        className={
          searchResults.length !== 0 ? "sort_item" : "sort_item skeleton"
        }
      >
        {searchResults.length != 0 && locale === "en" ? (
          <strong>
            {countResults(searchResults)}{" "}
            {searchResults.length > 1 ? t("Results") : t("Result")}
          </strong>
        ) : null}
       {!isMobile ?
        <ul>
        <li>
          <span>{t("Departure")}</span>
          <DepartureSorting />
        </li>
        <li>
          <span>{t("Arrival")}</span>
          <ArrivalSorting />
        </li>
        <li>
          <span>{t("Duration")}</span>
          <DurationSorting />
        </li>
        <li>
          <span>{t("Price")}</span>
          <PriceSorting />
        </li>
      </ul>
      :
      <ul className="sorting_list">
        <li onClick={() => {setPriceSortingValue('Low'); setDurationSorting(''); setDepartureSorting(''); setArrivalSorting('') }}>
          {priceSortingValue === "Low" &&
          <>
         <img src={SortingTickD} className="dark" />
         <img src={SortingTickW} className="light" /> 
         </>
          }
         {t('Lowest Price First')}
        </li>
        <li onClick={() => {setPriceSortingValue(''); setDurationSorting('Short'); setDepartureSorting(''); setArrivalSorting('')  }}>
          {durationSorting === "Short" &&
          <>
        <img src={SortingTickD} className="dark" />
         <img src={SortingTickW} className="light" /> 
         </>
          }
          {t('Shortest Duration First')}
        </li>
        <li onClick={() => {setPriceSortingValue(''); setDurationSorting(''); setDepartureSorting('Early'); setArrivalSorting('') }}>
          {departureSorting === "Early" &&
          <>
        <img src={SortingTickD} className="dark" />
         <img src={SortingTickW} className="light" /> 
         </>
          }
          {t('Earliest Departure First')}
        </li>
        <li onClick={() => {setPriceSortingValue(''); setDurationSorting(''); setDepartureSorting('Late'); setArrivalSorting('') }}>
        {departureSorting === "Late" &&
          <>
        <img src={SortingTickD} className="dark" />
         <img src={SortingTickW} className="light" /> 
        </>
         }
          {t('Latest Departure First')}
        </li>
        <li onClick={() => {setPriceSortingValue(''); setDurationSorting(''); setDepartureSorting(''); setArrivalSorting('Early') }}>
          {arrivalSorting === "Early" &&
          <>
        <img src={SortingTickD} className="dark" />
         <img src={SortingTickW} className="light" /> 
         </>
          }
          {t('Earliest Return First')}
        </li>
        <li onClick={() => {setPriceSortingValue(''); setDurationSorting(''); setDepartureSorting(''); setArrivalSorting('Late') }}>
          {arrivalSorting === "Late" &&
          <>
        <img src={SortingTickD} className="dark" />
         <img src={SortingTickW} className="light" /> 
         </>
          }
          {t('Latest Return First')}
        </li>
      </ul>
      
       }
      </div>
      {isMobile ? (
        <button
          type="button"
          className="apply_button"
          onClick={() => {
            closeFiltersSorting();
          }}
        >
          {t("Apply Btn")}
        </button>
      ) : null}
    </section>
  );
}

export default SearchSorting;
