import { useGlobals } from "../../contexts/globalContexts";

import RightLeftArrowDark from "../../img/right_left_arrow.svg";
import RightLeftArrow from "../../img/arrow-both.svg";
import RightArrow from "../../img/sidebar-arrow-right.svg";
import RightArrowDark from "../../img/right_arrow.svg";
import ArrowLeft from "../../img/sidebar-arrow-left.svg";
import ArrowLeftDark from "../../img/left_arrow.svg";
import { useEffect, useState } from "react";
import FlightDetails from "../FlightDetails/FlightDetails";
import { useTranslation } from "react-i18next";

function Sidebar({ showHyperButton, displayHyper, itinerary, pricingInfo }) {
  const {
    bookingDetails,
    totalCharges,
    setTotalCharges,
    showHepar,
    hepstarData,
    currency,
    itenraryDetails,
    passengers,
    priceSummary,
    isMobile,
    moreInfoToggle,
    totalPass,
    setTotalPass,
    setShowHepar,
    setIsFlightDetailsOpen,
    hepstarCalled,
    discountedAmount,
    lang,
    locale,
    setLocale,
  } = useGlobals();

  let Tax = "";
  let ServiceCharge = "";
  let Discount = "";
  let TotalPrice = "";

  let price = "";
  let adults = passengers.adultCount;
  let childs = passengers.childrenCount;
  let infants = passengers.infantsCount;
  let totalPassengers =
    passengers.adultCount + passengers.childrenCount + passengers.infantsCount;

  let adultQty = "";
  let childQty = "";
  let infantsQty = "";
  let adultPrice = "";
  let childPrice = "";
  let infantPrice = "";
  let adultTax = "";
  let infantTax = "";
  let childTax = "";

  let taxPrice;

  if (totalPass && totalPass.passengers[0]) {
    adultQty = totalPass.passengers[0].qty;
    adultPrice = adultQty * totalPass.passengers[0].price.totalFareAmount;
    adultTax = totalPass.passengers[0].price.totalTaxAmount * adultQty;
  }
  if (totalPass && totalPass.passengers[1]) {
    childQty = totalPass.passengers[1].qty;
    childPrice = childQty * totalPass.passengers[1].price.totalFareAmount;
    childTax = totalPass.passengers[1].price.totalTaxAmount * childQty;
  }
  if (totalPass && totalPass.passengers[2]) {
    infantsQty = totalPass.passengers[2].qty;
    infantPrice = infantsQty * totalPass.passengers[2].price.totalFareAmount;
    infantTax = totalPass.passengers[2].price.totalTaxAmount * infantsQty;
  }

  price = Number(priceSummary.price).toFixed(2);
  taxPrice = priceSummary.taxPrice;

  useEffect(() => {
    if (totalPass?.IsInsured === true) {
      setShowHepar(true);
    }
  }, [totalPass]);

  useEffect(() => {
    // TotalPrice = Number(price) + Number(taxPrice);
    TotalPrice = Number(priceSummary.totalPrice);

    if (hepstarCalled === true) {
      let value;
      if (hepstarData?.totalHepstarAmount) {
        value = hepstarData.totalHepstarAmount;
      } else {
        value = Number(totalPass?.InsuranceAmount).toFixed(2);
      }
      TotalPrice = Number(TotalPrice) + Number(value);
      TotalPrice = Number(TotalPrice).toFixed(2);
      setTotalCharges(Number(TotalPrice).toFixed(2));
    } else {
      setTotalCharges(Number(TotalPrice).toFixed(2));
    }
  });

  const [renderFlightDetails, setRenderFlightDetails] = useState(false);
  const [getItineraries, setItineraries] = useState();

  const showFlightDetails = (Itineraries) => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 10, behavior: "smooth" });
    setRenderFlightDetails(true);
    setIsFlightDetailsOpen(true);
    setItineraries([totalPass]);
  };

  const getFormattedDate = (date) => {
    let dt = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return dt;
  };

  const [departureAirportValue, setDepartureAirportValue] = useState();
  useEffect(() => {
    if (itenraryDetails.TripType === "RoundTrip") {
      setDepartureAirportValue(
        totalPass?.Leg[1]?.Segements[0]?.departureAirport?.cityCode
      );
    }
    if (itenraryDetails.TripType === "OneWay") {
      setDepartureAirportValue(
        totalPass?.Leg[0]?.Segements[totalPass?.Leg[0].Segements.length - 1]
          ?.arrivalAirport.cityCode
      );
    }
  }, [totalPass]);

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      {renderFlightDetails && (
        <FlightDetails
          renderFlightDetails={renderFlightDetails}
          setRenderFlightDetails={setRenderFlightDetails}
          getItineraries={getItineraries}
          totalCharges={totalCharges}
          pricingInfo={pricingInfo}
          totalPass={totalPass}
          departureAirport={departureAirportValue}
        />
      )}
      <div
        className={itenraryDetails.price ? "row sidebar-border" : "skeleton"}
      >
        <div className="col-sm-12 col-lg-12 col-md-12 ">
          <div className="row sidebar-header top-border-radius dps-none">
            <div className="col-sm-7 col-md-7 col-lg-7">
              <h5 className="font-16 font-semibold">{t("Booking Summary")}</h5>
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-5 col-md-5 col-lg-5 text-right-arabic"
                  : "col-sm-5 col-md-5 col-lg-5 text-right"
              }
            >
              <h5
                className="underlined-text text-small"
                onClick={() => showFlightDetails(totalPass)}
              >
                {t("Flight Details")}
              </h5>
            </div>
          </div>

          <div className="row mt-4 dps-none">
            <div
              className={
                locale === "ar"
                  ? "col-sm-5 col-md-5 col-lg-5 text-right-arabic p-1"
                  : "col-sm-5 col-md-5 col-lg-5 text-right p-1"
              }
            >
              <h6 className="font-12 font-bold">
                {totalPass?.Leg[0].Segements[0].departureAirport.cityName}{" "}
                {totalPass?.Leg[0].Segements[0].departureAirport.airportCode}
              </h6>
            </div>

            {itenraryDetails.TripType === "RoundTrip" ?
            <div className="col-sm-2 col-md-2 col-lg-2">
              <img
                src={RightLeftArrowDark}
                className="dark"
                alt="right-left-arrow-light"
              />
              <img
                src={RightLeftArrow}
                className="light"
                alt="right-left-arrow-dark"
              />
            </div>
      :
      <div className="col-sm-2 col-md-2 col-lg-2">
      <img src={locale === "en" ? RightArrowDark : ArrowLeftDark} className="dark" />
      <img src={locale === "en" ? RightArrow : ArrowLeft} className="light" />
      </div>
            }

            <div className="col-sm-5 col-md-5 col-lg-5 p-1">
              <h6 className="font-12 font-bold">
                {itenraryDetails.TripType === "RoundTrip" &&
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                {itenraryDetails.TripType === "RoundTrip" &&
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport.airportCode}
                {itenraryDetails.TripType === "OneWay" &&
                  totalPass?.Leg[0]?.Segements[
                    totalPass?.Leg[0].Segements.length - 1
                  ]?.arrivalAirport.cityName}{" "}
                {itenraryDetails.TripType === "OneWay" &&
                  totalPass?.Leg[0]?.Segements[
                    totalPass?.Leg[0].Segements.length - 1
                  ]?.arrivalAirport.airportCode}
              </h6>
            </div>
          </div>

          <div className="row mt-4 dps-none">
            <div
              className={
                locale === "ar"
                  ? "col-sm-5 col-md-5 col-lg-5 text-right-arabic p-1"
                  : "col-sm-5 col-md-5 col-lg-5 text-right p-1"
              }
            >
              <h6 className="font-12 font-normal">
                {itenraryDetails.goingDepartureCity}{" "}
                {itenraryDetails.GoingDepartureAirportCode}
              </h6>
              <p className="font-12">
                {String(itenraryDetails.goingDepartureTime).substring(0, 5)}{" "}
                {/* {itenraryDetails.goingDepartureDate} */}
                {getFormattedDate(
                  totalPass?.Leg[0]?.Segements[0]?.flights[0]?.departureDateTime
                    ?.split(" ")[0]
                    .substring(0, 10)
                )}
              </p>
              <p className="font-12">
                {totalPass?.Leg[0].Segements[0].departureAirport.airportName}
              </p>
              <p className="font-12">
                {t("Terminal")} {itenraryDetails.goingDepartureTerminal}
              </p>
            </div>

            <div className="col-sm-2 col-md-2 col-lg-2 vertically-align-center">
            
              <img src={locale === "en" ? RightArrow : ArrowLeft} className="light" alt="right-arrow-light" />
              <img
                src={locale === "en" ? RightArrowDark : ArrowLeftDark}
                className="dark"
                alt="right-arrow-dark"
              />
            </div>

            <div className="col-sm-5 col-md-5 col-lg-5 p-1">
              <h6 className="font-12 font-normal">
                {itenraryDetails.TripType === "RoundTrip" &&
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport
                    .cityName}{" "}
                {itenraryDetails.TripType === "RoundTrip" &&
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport.airportCode}
                {itenraryDetails.TripType === "OneWay" &&
                  totalPass?.Leg[0]?.Segements[
                    totalPass?.Leg[0].Segements.length - 1
                  ].arrivalAirport.cityName}{" "}
                {itenraryDetails.TripType === "OneWay" &&
                  totalPass?.Leg[0]?.Segements[
                    totalPass?.Leg[0].Segements.length - 1
                  ].arrivalAirport.airportCode}
              </h6>
              <p className="font-12">
                {itenraryDetails.TripType === "RoundTrip" &&
                  String(
                    totalPass?.Leg[0]?.Segements[
                      totalPass?.Leg[0].Segements.length - 1
                    ]?.flights[0]?.arrivalTime
                  ).substring(0, 5)}{" "}
                {itenraryDetails.TripType === "OneWay" &&
                  String(
                    totalPass?.Leg[0]?.Segements[
                      totalPass?.Leg[0].Segements.length - 1
                    ]?.flights[0]?.arrivalTime.substring(0, 5)
                  )}{" "}
                {getFormattedDate(
                  totalPass?.Leg[0]?.Segements[0]?.flights[0]?.arrivalDateTime
                    ?.split(" ")[0]
                    .substring(0, 10)
                )}
              </p>
              <p className="font-12">
                {itenraryDetails.TripType === "RoundTrip" &&
                  totalPass?.Leg[1]?.Segements[0]?.departureAirport.airportName}
                {itenraryDetails.TripType === "OneWay" &&
                  totalPass?.Leg[0]?.Segements[
                    totalPass?.Leg[0].Segements.length - 1
                  ].arrivalAirport.airportName}
              </p>
              <p className="font-12">
                {t("Terminal")} {itenraryDetails.goingArrivalTerminal}
              </p>
            </div>
          </div>

          {itenraryDetails.TripType === "RoundTrip" && (
            <div>
              <div className="row p-10 dps-none">
                <div className="col-sm-12 col-md-12 col-lg-12 border-bottom-dashed"></div>
              </div>

              <div className="row dps-none">
                {/* <div
                  className={
                    locale === "ar"
                      ? "col-sm-5 col-md-5 col-lg-5 text-right-arabic p-1"
                      : "col-sm-5 col-md-5 col-lg-5 text-right p-1"
                  }
                >
                  <h6 className="font-12 font-normal">
                    {itenraryDetails.ReturnDepartureCity}{" "}
                    {itenraryDetails.ReturnDepartureAirportCode}
                  </h6>
                  <p className="font-12">
                    {String(itenraryDetails.ReturnDepartureTime).substring(
                      0,
                      5
                    )}{" "}
                    {getFormattedDate(
                      totalPass?.Leg[1]?.Segements[0]?.flights[0]?.departureDateTime
                        ?.split(" ")[0]
                        .substring(0, 10)
                    )}
                  </p>
                  <p className="font-12">
                    {itenraryDetails.ReturnDepartureAirport}
                  </p>
                  <p className="font-12">
                    {t("Terminal")} {itenraryDetails.ReturnDepartureTerminal}
                  </p>
                </div> */}

                {/* <div className="col-sm-2 col-md-2 col-lg-2 vertically-align-center">
              
                     <img
                    src={locale === "en" ? ArrowLeft : RightArrow}
                    className="light"
                    alt="left-arrow-light"
                  />
                  <img
                    src={locale === "en" ? ArrowLeftDark : RightArrowDark}
                    className="dark"
                    alt="left-arrow-dark"
                  />
                </div> */}

                <div className={locale === "ar" ?
                "col-sm-5 col-md-5 col-lg-5 p-1 text-right-arabic"
              :
              "col-sm-5 col-md-5 col-lg-5 p-1 text-right"
              }>
                  <h6 className="font-12 font-normal">
                    {itenraryDetails.TripType === "RoundTrip" &&
                      totalPass?.Leg[0]?.Segements[0]?.departureAirport
                        .cityName}{" "}
                    {itenraryDetails.TripType === "RoundTrip" &&
                      totalPass?.Leg[0]?.Segements[0]?.departureAirport
                        .airportCode}
                    {itenraryDetails.TripType === "OneWay" &&
                      totalPass?.Leg[0]?.Segements[
                        totalPass?.Leg[0].Segements.length - 1
                      ].arrivalAirport.cityName}{" "}
                    {itenraryDetails.TripType === "OneWay" &&
                      totalPass?.Leg[0]?.Segements[
                        totalPass?.Leg[0].Segements.length - 1
                      ].arrivalAirport.airportCode}
                  </h6>
                  <p className="font-12">
                    {String(itenraryDetails.ReturnArrivalTime).substring(0, 5)}{" "}
                    {getFormattedDate(
                      totalPass?.Leg[1].Segements[
                        totalPass.Leg[1].Segements.length - 1
                      ].flights[
                        totalPass.Leg[1].Segements[0].flights.length - 1
                      ].arrivalDateTime
                    )}
                  </p>
                  <p className="font-12">
                    {itenraryDetails.ReturnArrivalAirport}
                  </p>
                  <p className="font-12">
                    {t("Terminal")} {itenraryDetails.ReturnArrivalTerminal}
                  </p>
                </div>

                <div className="col-sm-2 col-md-2 col-lg-2 vertically-align-center">
              
              <img
             src={locale === "en" ? ArrowLeft : RightArrow}
             className="light"
             alt="left-arrow-light"
           />
           <img
             src={locale === "en" ? ArrowLeftDark : RightArrowDark}
             className="dark"
             alt="left-arrow-dark"
           />
         </div>


         <div
                  className={
                    locale === "ar"
                      ? "col-sm-5 col-md-5 col-lg-5  p-1"
                      : "col-sm-5 col-md-5 col-lg-5  p-1"
                  }
                >
                  <h6 className="font-12 font-normal">
                    {itenraryDetails.ReturnDepartureCity}{" "}
                    {itenraryDetails.ReturnDepartureAirportCode}
                  </h6>
                  <p className="font-12">
                    {String(itenraryDetails.ReturnDepartureTime).substring(
                      0,
                      5
                    )}{" "}
                    {getFormattedDate(
                      totalPass?.Leg[1]?.Segements[0]?.flights[0]?.departureDateTime
                        ?.split(" ")[0]
                        .substring(0, 10)
                    )}
                  </p>
                  <p className="font-12">
                    {itenraryDetails.ReturnDepartureAirport}
                  </p>
                  <p className="font-12">
                    {t("Terminal")} {itenraryDetails.ReturnDepartureTerminal}
                  </p>
                </div>

              </div>
            </div>
          )}

          {/* <div className="row sidebar-header dps-none">
            <div className="col-sm-8">
              <input
                type="text"
                placeholder="Cupon Code"
                className="text-input"
              />
            </div>
            <div className="col-sm-4">
              <button className="apply-btn">Apply</button>
            </div>
          </div> */}

          <div className="row px-3 dps-none">
            <div className="col-sm-12 border-bottom p-2"></div>
          </div>

          {/* {moreInfoToggle === true ? (
            <div className="loader__Overlay_sidebar ">
              <div
                className="row bg-skyblue dls-none"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "30px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="col-sm-12 col-md-12 col-12 col-lg-12 p-3">
                  <h6 className="font-12 font-bold dark-clr-black ">
                    {t("Base Fare")}
                  </h6>
                </div>

                <div className="col-sm-7 col-md-6 col-lg-6">
                  <p className="font-12 mob-font-11 dark-clr-black ">
                    {totalPass?.passengers.map((data, i) => {
                      return (
                        <p
                          className="font-12 mob-font-11 dark-clr-black "
                          key={i + 1}
                        >
                          {data.type === "ADT" &&
                            `${t("Adult")}(s) x ${data.qty}`}
                          {data.type === "CNN" &&
                            `${t("Child")}(s) x ${data.qty}`}
                          {data.type === "INF" &&
                            `${t("Infant")}(s) x ${data.qty}`}
                        </p>
                      );
                    })}
                  </p>
                </div>

                <div
                  className={
                    locale === "ar"
                      ? "col-sm-5 col-md-6 col-lg-6 text-right-arabic"
                      : "col-sm-5 col-md-6 col-lg-6 text-right"
                  }
                >
                  <p className="font-12 mob-font-11 mob-top-22 dark-clr-black">
                    {currency} {pricingInfo?.adultPrice}
                    <br />
                    {pricingInfo?.childQty > 0 && pricingInfo?.childPrice
                      ? `${currency} ${pricingInfo.childPrice}`
                      : ""}
                    {pricingInfo?.childQty > 0 && pricingInfo?.childPrice ? (
                      <br />
                    ) : (
                      ""
                    )}
                    {pricingInfo?.infantsQty > 0 && pricingInfo?.infantPrice
                      ? `${currency} ${pricingInfo?.infantPrice}`
                      : ""}
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 ">
                  <p className="font-12 mob-font-11 dark-clr-black">
                    {t("Taxes & Fees")}
                  </p>
                </div>

                <div
                  className={
                    locale === "ar"
                      ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                      : "col-sm-6 col-md-6 col-lg-6 text-right"
                  }
                >
                  <p className="font-12 mob-font-11 mob-top-22 dark-clr-black">
                    {currency} {taxPrice}
                  </p>
                </div>

                {showHepar && hepstarCalled === true ? (
                  <div>
                    <div className="col-sm-6 col-md-6 col-lg-6 text-orange">
                      <p className="font-12 mob-font-11  text-orange">
                        {t("Cancellation Charges")}
                      </p>
                    </div>

                    <div
                      className={
                        locale === "ar"
                          ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                          : "col-sm-6 col-md-6 col-lg-6 text-right"
                      }
                    >
                      <p className="font-12 mob-font-11 mob-top-22 text-orange ">
                        {currency} {hepstarData.totalHepstarAmount}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>

              {discountedAmount && discountedAmount > 0 ? (
                <div>
                  <div className="row px-3 bg-skyblue dps-none ">
                    <div className="col-sm-12 border-bottom "></div>
                  </div>
                  <div className="row px-1 py-2 bg-skyblue ">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <p className="font-bold text-orange">{t("Discount")}</p>
                    </div>

                    <div
                      className={
                        locale === "ar"
                          ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                          : "col-sm-6 col-md-6 col-lg-6 text-right"
                      }
                    >
                      <p className="font-bold text-orange mob-top-22">
                        {currency} {Number(discountedAmount).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null} */}
          <div
            className="row bg-skyblue dps-none"
            style={{ paddingTop: "10px" }}
          >
            <div className="col-sm-5 col-md-6 col-6 col-lg-6 p-3">
              <h6 className="font-12 font-bold dark-clr-black ">
                {t("Base Fare")}{" "}
              </h6>
            </div>
          </div>

          <div className="row px-1 bg-skyblue dps-none">
            <div className="col-sm-6 col-md-6 col-lg-6">
              {totalPass?.passengers.map((data, i) => {
                return (
                  <p
                    className="font-12 mob-font-11 dark-clr-black "
                    key={i + 1}
                  >
                    {data.type === "ADT" && `${t("Adult")}(s) x ${data.qty}`}
                    {data.type === "CNN" && `${t("Child")}(s) x ${data.qty}`}
                    {data.type === "INF" && `${t("Infant")}(s) x ${data.qty}`}
                  </p>
                );
              })}
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                  : "col-sm-6 col-md-6 col-lg-6 text-right"
              }
            >
              <p className="font-12 mob-font-11 mob-top-22 dark-clr-black">
                {currency} {Number(pricingInfo?.adultPrice).toFixed(2)}
                <br />
                {pricingInfo?.childQty > 0
                  ? `${currency} ${Number(pricingInfo.childPrice).toFixed(2)}`
                  : ""}
                {pricingInfo?.childQty > 0 && pricingInfo?.childPrice ? (
                  <br />
                ) : (
                  ""
                )}
                {pricingInfo?.infantsQty > 0
                  ? `${currency} ${Number(pricingInfo?.infantPrice).toFixed(2)}`
                  : ""}
              </p>
            </div>
          </div>

          <div className="row px-1 pt-2 bg-skyblue dps-none">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <p className="font-12 mob-font-11 dark-clr-black">
                {t("Taxes & Fees")}
              </p>
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                  : "col-sm-6 col-md-6 col-lg-6 text-right"
              }
            >
              <p className="font-12 mob-font-11 mob-top-22 dark-clr-black">
                {currency} {taxPrice}
              </p>
            </div>
          </div>

          {hepstarCalled === true ? (
            <div>
              <div className="row px-3 bg-skyblue dps-none ">
                <div className="col-sm-12 border-bottom "></div>
              </div>
              <div className="row mob-top-m0 mt-2 mb-2 dps-none px-1 bg-skyblue myDIV">
                <div className="col-sm-6 col-md-6 col-lg-6 ">
                  <p className="font-12 mob-font-11 dark-clr-black text-orange">
                    {t("Cancellation Charges")}
                  </p>
                </div>

                <div
                  className={
                    locale === "ar"
                      ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                      : "col-sm-6 col-md-6 col-lg-6 text-right"
                  }
                >
                  <p className="font-12 mob-font-11 mob-top-22 text-orange dark-clr-black">
                    {currency} {hepstarData.totalHepstarAmount}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {discountedAmount && discountedAmount > 0 ? (
            <div>
              <div className="row px-3 bg-skyblue dps-none ">
                <div className="col-sm-12 border-bottom "></div>
              </div>
              <div className="row px-1 py-2 bg-skyblue dps-none ">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <p className="font-bold text-orange">{t("Discount")}</p>
                </div>

                <div
                  className={
                    locale === "ar"
                      ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                      : "col-sm-6 col-md-6 col-lg-6 text-right"
                  }
                >
                  <p className="font-bold text-orange mob-top-22">
                    {currency} {Number(discountedAmount).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row px-3 bg-skyblue dps-none ">
            <div className="col-sm-12 border-bottom "></div>
          </div>

          <div className="row px-1 py-2 bg-skyblue dps-none">
            <div className="col-sm-6">
              <h5 className="font-bold font-16 ">{t("Total Fare")}</h5>
            </div>

            <div
              className={
                locale === "ar"
                  ? "col-sm-6 col-md-6 col-lg-6 text-right-arabic"
                  : "col-sm-6 col-md-6 col-lg-6 text-right"
              }
            >
              <h5 className="font-bold text-green mob-top-22">
                {currency} {totalCharges}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
