import AirplaneDeparture from "../../img/airplane-departure.png";
import AirplaneDepartureDark from "../../img/d/Outline_Icons.svg";

function AirplaneDepartureIcon() {
    return(
        <div>
            <div className="row">
              <div className="col-12 text-center print_none">
                <img src={AirplaneDeparture} className="light" alt="airplane-icon-light" />
                <img src={AirplaneDepartureDark} className="dark" alt="airplane-icon-dark"  />
              </div>
            </div>
        </div>
    )
}

export default AirplaneDepartureIcon