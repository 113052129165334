import { useEffect } from "react";
import Why from './Why/Why';
import RecentSearches from './RecentSearches/RecentSearches';
import TopDeals from './TopDeals/Topdeals';

import About from './About/About';
import Home from './Home/Home';
import Subscribe from "./Subscribe/Subscribe";
import '../css/homepage.css'
import { useGlobals } from "../contexts/globalContexts";
import { isMobile } from "react-device-detect";

function HomePage() {

    const {lang, locale} = useGlobals();
    
    const fixSearchPage = () =>{
        document.getElementsByTagName('body')[0].removeAttribute('style','background-image:none')
        document.getElementsByTagName('main')[0].removeAttribute('class','page search_results')
        document.body.classList.remove('sub_page')
        document.getElementById('root').classList.remove('page')
        document.getElementById('root').classList.remove('search_results')
    }

    useEffect(() => {
        fixSearchPage()
        document.getElementsByClassName('language')[0].style.display = 'block';
        
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event: 'page_view',

        page_location: window.location.host,

        page_title: 'Homepage',

        language: locale,

        utm_source: isMobile ? "MobileWeb" : "Web",

        currency: process.env.REACT_APP_CURRENCY
     });
    }, [])

    
    // console.log(window.dataLayer);

    

    
    return (
        <>
            <Home />
            {/* <RecentSearches /> */}
            <TopDeals />
            <Subscribe />
            <Why />
            <About />
        </>
    );
}

export default HomePage;