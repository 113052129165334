import Steps from "../../img/steps.svg";
import StepsDark from "../../img/d/steps.svg";
import StepsArabic from "../../img/step1Arabic.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useGlobals } from "../../contexts/globalContexts";

function TopSteps() {
  const { lang, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <div className="container-fluid dps-none">
        <div className="row mb-3">
          <div className="col-sm-2 col-2 header-left-confetii"></div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-8 text-center">
            <img
              src={locale === "en" ? Steps : StepsArabic}
              className="light"
              alt="top-steps-light"
            />
            <img
              src={locale === "en" ? StepsDark : StepsArabic}
              className="dark"
              alt="top-steps-dark"
            />
          </div>

          <div className="col-sm-2 col-2 header-right-confetii"></div>
        </div>
      </div>

      <div className="row dps-none">
        <div className="col-12 border-bottom"></div>
      </div>
    </div>
  );
}

export default TopSteps;
