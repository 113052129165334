import Sa from "../../img/sa.svg";
import Bh from "../../img/bh.svg";
import Pk from "../../img/pk.svg";

import Currency from "../Currency/Currency";
import Language from "../Language/Language";

import { isMobile } from "react-device-detect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobals } from "../../contexts/globalContexts";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

function Navigation({ currency, setLang, setCurrency }) {
  const { mobileMenu, lang, setMobileMenu, locale, setLocale } = useGlobals();
  const [showDropDown, setShowDropDown] = useState(false);

  const showPhoneNumbers = (e) => {
    e.preventDefault();
    setShowDropDown((current) => !current);
  };

  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();
  const { local } = useParams();

  return (
    <nav className={mobileMenu ? null : "close"} id="main_navigation">
      {locale === "ar" ? (
        <ul className="main_nav_arabic main_nav">
          <li>
            <Link to="/" onClick={() => setMobileMenu(false)}>
              {t(`Flights`)}
            </Link>
            <a href="#" className="hotel_link">
              {t(`Hotels`)}
            </a>
          </li>

          <li>
            <Link
              to={`/${locale}/my_bookings`}
              onClick={() => setMobileMenu(false)}
            >
              {t(`My Bookings`)}
            </Link>
          </li>
          <li>
            <Link
              to={`${locale}/contact_us`}
              onClick={() => setMobileMenu(false)}
            >
              {t(`Support`)}
            </Link>
          </li>
          <li className="header_dropdown" >
            <a
              href={isMobile ? "#" : "tel:+973 1 3302121"}
              onClick={
                isMobile
                  ? (e) => {
                      showPhoneNumbers(e);
                    }
                  : () => {}
              }
            >
              <span>
                <img
                  // src={Sa}
                  src={process.env.REACT_APP_COUNTRY === "BH" ? Bh : Sa}
                  alt=""
                  style={
                    isMobile ? { marginLeft: "1px" } : { marginLeft: "0px" }
                  }
                />
              </span>
              <span className="header_text" dir="ltr">
             {process.env.REACT_APP_NUMBER}
              </span>
            </a>
            <ul className={showDropDown ? "show_numbers" : ""}>
              <li>
                <a href={`tel:+966 11520 4955`}>
                  <span>
                    <img src={Sa} alt="" />
                  </span>
                  <span>
                    <p
                      // style={
                      //   locale === "ar"
                      //     ? { marginTop: "-9px" }
                      //     : { marginTop: "0px" }
                      // }
                    >
                      {t(`Saudi Arabia`)}
                    </p>
                    <p dir="ltr" >{`+966 11520 4955`}</p>
                  </span>
                </a>
              </li>
              <li>
                <a href="tel:+973 1330 2121">
                  <span>
                    <img src={Bh} alt="" />
                  </span>
                  <span>
                    <p>{t(`Bahrain`)}</p>
                    <p dir="ltr" >+973 1330 2121</p>
                  </span>
                </a>
              </li>
              <li style={{ display: "none" }}>
                <a href="tel:+973 1330 2121">
                  <span>
                    <img src={Bh} alt="" />
                  </span>
                  <span>
                    <p>{t(`Bahrain`)}</p>
                    <p dir="ltr" >+973 1330 2121</p>
                  </span>
                </a>
              </li>
              <li>
                <a href="tel:+92 5127 28821">
                  <span>
                    <img src={Pk} alt="" />
                  </span>
                  <span>
                    <p>{t(`Pakistan`)}</p>
                    <p dir="ltr" >+92 5127 28821</p>
                  </span>
                </a>
              </li>
            </ul>
          </li>
          <Currency currency={currency} setCurrency={setCurrency} />
          <Language lang={lang} setLang={setLang} />
        </ul>
      ) : (
        <ul className="main_nav">
          <li>
            <Link to="/" onClick={() => setMobileMenu(false)}>
              {t(`Flights`)}
            </Link>

            <a href="#" className="hotel_link">
              {t(`Hotels`)}
            </a>
          </li>

          <li>
            <Link
              to={`/${locale}/my_bookings`}
              onClick={() => setMobileMenu(false)}
            >
              {t(`My Bookings`)}
            </Link>
          </li>
          <li>
            <Link
              to={`${locale}/contact_us`}
              onClick={() => setMobileMenu(false)}
            >
              {t(`Support`)}
            </Link>
          </li>
          <li className="header_dropdown" >
            <a
              href={isMobile ? "#" : "tel:+973 1 3302121"}
              onClick={
                isMobile
                  ? (e) => {
                      showPhoneNumbers(e);
                    }
                  : () => {}
              }
            >
              <span>
                <img src={process.env.REACT_APP_COUNTRY === "BH" ? Bh : Sa } alt="" />
              </span>
              <span >{process.env.REACT_APP_NUMBER}</span>
            </a>
            <ul className={showDropDown ? "show_numbers" : ""}>
              
              <li>
                <a href="tel:+966 11520 4955">
                  <span>
                    <img src={Sa} alt="" />
                  </span>
                  <span>
                    <small
                      style={
                        locale === "ar"
                          ? { marginTop: "-12px" }
                          : { marginTop: "0px" }
                      }
                    >
                      {t(`Saudi Arabia`)}
                    </small>
                    <small
                      style={
                        locale === "ar"
                          ? { marginTop: "-8px"}
                          : { marginTop: "0px"}
                      }
                    >
                      +966 11520 4955
                    </small>
                  </span>
                </a>
              </li>
              <li>
                <a href="tel:+973 1330 2121">
                  <span>
                    <img src={Bh} alt="" />
                  </span>
                  <span>
                    <small
                      style={
                        locale === "ar"
                          ? { marginTop: "-12px" }
                          : { marginTop: "0px" }
                      }
                    >
                      {t(`Bahrain`)}
                    </small>
                    <small
                      style={
                        locale === "ar"
                          ? { marginTop: "-8px"}
                          : { marginTop: "0px"}
                      }
                    >
                      +973 1330 2121
                    </small>
                  </span>
                </a>
              </li>
              <li>
                <a href="tel:+92 5127 28821">
                  <span>
                    <img src={Pk} alt="" />
                  </span>
                  <span>
                    <small
                      style={
                        locale === "ar"
                          ? { marginTop: "-12px" }
                          : { marginTop: "0px" }
                      }
                    >
                      {t(`Pakistan`)}
                    </small>
                    <small
                      style={
                        locale === "ar"
                          ? { marginTop: "-8px" }
                          : { marginTop: "0px"}
                      }
                    >
                      +92 5127 28821
                    </small>
                  </span>
                </a>
              </li>
            </ul>
          </li>
          <Currency currency={currency} setCurrency={setCurrency} />
          <Language lang={lang} setLang={setLang} />
        </ul>
      )}
      <ul className="support_nav">
        <li>
          <Link
            to={`/${locale}/terms_conditions`}
            onClick={() => setMobileMenu(false)}
          >
            {t("Terms Conditions")}
          </Link>
        </li>
        <li>
          <Link
            to={`/${locale}/privacy_policy`}
            onClick={() => setMobileMenu(false)}
          >
            {t("Privacy Policy")}
          </Link>
        </li>
        <li>
          <Link to={`/${locale}/faq`} onClick={() => setMobileMenu(false)}>
            {t("FAQ")}
          </Link>
        </li>
        <li>
          <Link
            to={`/${locale}/contact_us`}
            onClick={() => setMobileMenu(false)}
          >
            {t("Contact Us")}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
