import { useGlobals } from "../../contexts/globalContexts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function PriceSorting() {
  const { searchResults, setPriceSortingValue, lang, priceSortingValue } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  return (
    <div>
      <ul>
      <li>
          <label className="form_radio">
            {t("Low to High")}
            <input
              type="radio"
              name="price"
              defaultChecked
              onChange={() => setPriceSortingValue("Low")}
            />
            <span className="checkmark" />
          </label>
        </li>
        <li>
          <label className="form_radio">
            {t("High to Low")}
            <input
              type="radio"
              name="price"
              onChange={() => setPriceSortingValue("High")}
            />
            <span className="checkmark" />
          </label>
        </li>
    
      </ul>
    </div>
  );
}

export default PriceSorting;
