import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";

function FareType({handleFilterChange}) {
  const {
    searchResults,
    setResetAllFilters,
    resetAllFiltersApply,
    lang,
    locale, setSearchResults, setResetAllFiltersApply,
    filtersArray , setFiltersArray, resetAllFilters
  } = useGlobals();

  const [fareType, setFareType] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  const [refValue, setRefValue] = useState("REFUNDABLE");
  const [refState, setRefState] = useState();
  const [nonRefValue, setNonRefValue] = useState("NON_REFUNDABLE");
  const [nonRefState, setNonRefState] = useState();

  const makeFareTypeTabs = () => {
    if (searchResults) {
      if (searchResults.length !== 0) {
        setFareType([
          ...new Set(
            searchResults.map(
              (data) => data.Itineraries[0].passengers[0].refundability
            )
          ),
        ]);
      }
    }
  };

  let arr = [];
  const makeFareSelection = (e) => {
    setResetAllFilters(true);
    let selectedFare = e.currentTarget.dataset.aprice;

    var FaresList = document.querySelectorAll(".fare_type ul li");

    for (let i = 0; i < FaresList.length; i++) {
      FaresList[i].classList.remove("active");
    }

    e.currentTarget.classList.toggle("active");

    document.querySelectorAll("[data-refundable]").forEach((fareType, e) => {});
  };

  const styleFareLabel = (f) => {
    t("f");
    if (f === "REFUNDABLE") {
      return t("Refundable");
    }
    if (f === "NON_REFUNDABLE") {
      return t("Non-Refundable");
    }
  };

  useEffect(() => {
    makeFareTypeTabs();
  }, [searchResults]);

  const [array, setArray] = useState([]);

  useEffect(() => {

    if(filtersArray?.NonRef === true){
      if(document.getElementById("NON_REFUNDABLE")){
      document.getElementById("NON_REFUNDABLE").checked = true;
      }
    }
    if(filtersArray?.Ref === true){
      if(document.getElementById("REFUNDABLE")){
      document.getElementById("REFUNDABLE").checked = true;
      }
    }

    if(filtersArray?.NonRef === false){
      if(document.getElementById("NON_REFUNDABLE")){
      document.getElementById("NON_REFUNDABLE").checked = false;
      }
    }
    if(filtersArray?.Ref === false){
      if(document.getElementById("REFUNDABLE")){
      document.getElementById("REFUNDABLE").checked = false;
      }
    }
  }, [filtersArray]);

  const handleChange = (e) => {
   
    if (e.target.value === "REFUNDABLE") {
      setRefValue(e.target.value);
      setRefState(e.target.checked);
      setArray(e.target.value);
    }

    if (e.target.value === "NON_REFUNDABLE") {
      setNonRefValue(e.target.value);
      setNonRefState(e.target.checked);
      setArray(e.target.value);
    }
  };

  const resetFilters = () => {
    setRefState(false);
    setNonRefState(false);
    setRefValue("");
    setNonRefValue("");
  };

  const [showReset, setShowReset] = useState(false);
  useEffect(() => {
    
    setFiltersArray((prevFilters) => ({
      ...prevFilters,
      "Ref" : refState,
      "NonRef" : nonRefState
      }))
    // document.querySelectorAll("[data-refundable]").forEach((fareType, e) => {
    //   fareType.classList.remove("show_all");
    //   fareType.classList.remove("hide");
    //   if (
    //     refState === true &&
    //     refValue === "REFUNDABLE" &&
    //     fareType.dataset.refundable === "ref"
    //   ) {
    //     fareType.classList.add("show_all");
        
    //   }
    //   if (
    //     refState === true &&
    //     refValue === "REFUNDABLE" &&
    //     fareType.dataset.refundable === "0"
    //   ) {
    //     fareType.classList.add("hide");
    //   }
    //   if (
    //     nonRefState === true &&
    //     nonRefValue === "NON_REFUNDABLE" &&
    //     fareType.dataset.refundable === "0"
    //   ) {
    //     fareType.classList.add("show_all");
    //   }
    //   if (
    //     nonRefState === true &&
    //     nonRefValue === "NON_REFUNDABLE" &&
    //     fareType.dataset.refundable === "ref"
    //   ) {
    //     fareType.classList.add("hide");
    //   }
    //   if (refState === true && nonRefState === true) {
    //     fareType.classList.add("show_all");
    //     fareType.classList.remove("hide");
    //   }
    // });
  }, [refState, nonRefState]);

  const resetFunction = () => {
    setArray([]);
    setFareType("");
    setRefState(false);
    setNonRefState(false);
    // if(filtersArray.includes(refState || nonRefState)){

    // }
    setTimeout(() => {
      setFareType([
        ...new Set(
          searchResults.map(
            (data) => data.Itineraries[0].passengers[0].refundability
          )
        ),
      ]);
    }, 100);
  };

  useEffect(() => {
    if(resetAllFiltersApply === true){
      resetFunction();
      if(document.getElementById("REFUNDABLE")){
      document.getElementById("REFUNDABLE").checked = false;
      }
      if(document.getElementById("NON_REFUNDABLE")){
      document.getElementById("NON_REFUNDABLE").checked = false;
      }
    }
  }, [resetAllFiltersApply]);

  const { t, i18n, ready } = useTranslation();

  useEffect(() => {
    // console.log(filtersArray);
  }, [filtersArray])

  return (
    <div
      className={
        locale === "ar"
          ? "filter_section fare_type_arabic"
          : "filter_section fare_type"
      }
    >
      <div className="row">
        <div className="col-lg-9 col-9">
          <strong className={searchResults?.length > 0 ? "" : "skeleton"}>
            {t("Fare Type")}
          </strong>
        </div>

        {array.length > 0 || filtersArray?.Ref === true || filtersArray?.NonRef === true ? (
          <div className="col-lg-3 col-3 text-right text-right-arabic reset">
            <strong onClick={() => resetFunction()}>{t("Reset")}</strong>
          </div>
        ) : null}
      </div>

      {searchResults?.length > 0 ? (
        <ul>
          {fareType &&
            fareType?.map((fare, index) => {
              return (
                <li
                  key={fare + "_" + index}
                  data-aprice={fare}
                  data-acode={fare}
                >
                  <div className={fare.ItineraryId}>
                    <div className="fare_type">
                      <label
                        className={
                          locale === "ar"
                            ? "form_checkbox_arabic"
                            : "form_checkbox"
                        }
                      >
                        {styleFareLabel(fare)}
                        <input
                          type="checkbox"
                          value={fare}
                          id={fare}
                          onChange={(e) => handleChange(e)}
                        
                        />
                        <span
                          className={
                            locale === "ar" ? "checkmark_arabic" : "checkmark"
                          }
                        />
                      </label>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      ) : (
        <ul>
          <li className="skeleton">
            <label className="form_checkbox">
              Refundable
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
          <li className="skeleton">
            <label className="form_checkbox">
              Partially Refundable
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
          <li className="skeleton">
            <label className="form_checkbox">
              Non Refundable
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </li>
        </ul>
      )}
    </div>
  );
}

export default FareType;
