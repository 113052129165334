import "../../css/subscribe.css";
import paperPlaneLight from "../../img/email-send-paper_plane.svg";
import paperPlaneDark from "../../img/d/email-send-paper_plane.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobals } from "../../contexts/globalContexts";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";

function Subscribe() {
  const { lang, locale } = useGlobals();

  const { t, i18n, ready } = useTranslation();

  const { local } = useParams();

  return (
    <section
      className="container subscribe"
      dir={locale === "ar" ? "rtl" : "ltr"}
    >
      <div className="row">
        <div
          className="col-12"
          style={
            locale === "ar" && !isMobile
              ? { paddingRight: "40px" }
              : { paddingRight: "0px" }
          }
        >
          <div className="paper_plane_icon">
            <img
              src={paperPlaneLight}
              width="77"
              height="77"
              alt=""
              className="light"
            />
            <img src={paperPlaneDark} alt="" className="dark" />
          </div>
          <div className="subscribe_text">
            <h2 className={locale === "ar" ? "subcribe_text_right" : ""}>
              {t(`Enjoy Exclusive Benefits`)}
            </h2>
            <p>{t(`Subcribe Description`)}</p>
          </div>
          <div
            className={
              locale === "ar"
                ? "subscribe_form_Arabic subscribe_form"
                : "subscribe_form"
            }
          >
            <form action="#">
              <input type="email" placeholder={t("Your Email Address")} />
              <button
                style={
                  locale === "ar" && !isMobile
                    ? { marginRight: "20px" }
                    : { marginRight: "0px" }
                }
                onClick={(e) => e.preventDefault()}
              >
                {t(`Subcribe`)}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Subscribe;
