import Steps from "../../img/step3.svg";
import StepsArabic from "../../img/step3Arabic.png";
import StepsDark from "../../img/d/step3.svg";
import { useGlobals } from "../../contexts/globalContexts";

function TopSteps() {
  const {lang, locale} = useGlobals();
  return (
    <div>
      <div className="container-fluid dps-none top_steps">
        <div className="row mb-3">
          <div className="col-sm-2 col-2 header-left-confetii"></div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-8 text-center">
            <img src={locale === "en" ? Steps : StepsArabic} className="light" />
            <img src={locale === "en" ? StepsDark : StepsArabic} className="dark" />
          </div>

          <div className="col-sm-2 col-2 header-right-confetii"></div>
        </div>
      </div>
    </div>
  );
}
export default TopSteps;
