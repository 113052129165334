import { useGlobals } from "../../contexts/globalContexts";

import TickMark from "../../img/d/tick_marked.svg";
import VisaCardMob from "../../img/visa_card.svg";
import VisaCardDarkMob from "../../img/d/visa.svg";
import MadaCardMob from "../../img/mada-card.svg";
import MadaCardDarkMob from "../../img/d/meda-card.svg";
import MasterCardMob from "../../img/mastercard-2.svg";
import SecurePayment from "../../img/secure_payment (2).svg";
import SecurePaymentDark from "../../img/d/Secure.svg";
import TrustedPayment from "../../img/trusted.svg";
import TrustedPaymentDark from "../../img/d/Trusted.svg";
import EasyPayment from "../../img/easy_payment.svg";
import EasyPaymentDark from "../../img/d/e-payment.svg";
import TrustedGreen from "../../img/green_trusted.svg";
import CreditCard from "../../img/cc_logos.svg";
import CreditCardDark from "../../img/d/cc_logos.svg";
import ApexCard from "../../img/Amex-logo.png"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

function Form({ BookingRef, customerEmail }) {
  const {
    passengerDetails,
    passengerData,
    totalPass,
    siteURLS,
    tap,
    setTap,
    tapPayConfig,
    setTapPayConfig,
    lang,
    locale,
    setLocale,
    tapPayment,
    setTapPayment,
    eradPayment,
    setEradPayment,
    totalCharges,
    currency,
    provideID,
    setProviderId, tapPublicKey, setTapPublicKey
  } = useGlobals();

  let allPassengers = totalPass?.PassengerDetails;

  const [eradPayDetails, setEradPayDetails] = useState({});

  let bookingRefNo = BookingRef;

  


  let localePath;
  localePath = window.location.pathname.split("/");

  useEffect(() => {
    if(!tapPublicKey){
    fetch(`${process.env.REACT_APP_PG_URL}/Payment/GetPaymentGatewayConfiguration`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        currencyCode: currency,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.httpStatusCode === 200) {
          setEradPayment(false);
          setTapPayment(true);
          if(data?.responseModel[0].Name === "TAPPAY"){
            console.log('testapp');
            setTapPublicKey(data.responseModel[0].Configs[4].Value);
            console.log(data.responseModel[0].Configs[4].Value);
          }

          if (data.responseModel[0].Name === "ERADPAY") {
            setProviderId(data.responseModel[0].Id);
            setEradPayDetails({
              token: data.responseModel[0].Configs[0].Value,
              copyURL: data.responseModel[0].Configs[1].Value,
              redirectURL:
                data.responseModel[0].Configs[2].Value +
                "/" +
                BookingRef +
                "/" +
                localePath[1] +
                "?providerID=" +
                2,
              mode: data.responseModel[0].Configs[3].Value,
            });
          }
            else if(data.responseModel[1].Name === "ERADPAY"){
              setProviderId(data.responseModel[1].Id);
            setEradPayDetails({
              "token" : data.responseModel[1].Configs[0].Value,
              "copyURL" : data.responseModel[1].Configs[1].Value,
              "redirectURL" : data.responseModel[1].Configs[2].Value+'/'+BookingRef+'/'+localePath[1]+'?providerID='+2,
              "mode" : data.responseModel[1].Configs[3].Value,
            })
          }
        }
      })
      .catch((err) => console.log(err));
    }
  }, []);

  const { t, i18n, ready } = useTranslation();

  const [eradPayBtn, setEradPayBtn] = useState(false);
  const [tapPayBtn, setTapPayBtn] = useState(false);

  return (
    <div>
      <div className="row mt-4 dps-none">
        <div className="col-sm-12 col-md-12 col-lg-12 mob-form-border form-border">
          <div className="row">
            <div className="col-sm-4">
              <p className="text-green font-bold font-16">{t("Passengers")}</p>
            </div>

            <div
              className="col-sm-8"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {allPassengers
                ? allPassengers?.map((user, i) => {
                    return (
                      <div
                        style={{ marginBottom: "5px", minWidth: "150px" }}
                        key={i}
                      >
                        <span href="#" className="notification">
                          <p className="font-12 bg-color" key={user.passportNo}>
                            {user.givenName} {user.surName}
                          </p>
                          <img
                            src={TickMark}
                            className="badge"
                            alt="tickmark"
                          />
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 mob-top-margin-none ">
        <div
          className="col-sm-12 col-md-12 col-lg-12 mob-form-border form-border"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="row dps-none">
            <div className="col-sm-12">
              <p className="text-green font-bold font-16">
                {t("Payment Options")}
              </p>
            </div>
          </div>

          <div className={isMobile ? "row mb-5" : "row mt-3 mb-5"} style={isMobile ? {marginTop:"-20px"} : {marginTop:"0px"}} >
            <div className="col-sm-12 d-flex-cards">
            <img src={MadaCardMob} />
            <img src={VisaCardMob} /> 
            <img src={MasterCardMob} />
            <img src={ApexCard} 
            style={{height:"40px", width:"40px"}}
            />
            </div>
          </div>



          <div className="row top-m-0  ">
            <div className="col-sm-12 col-md-12 col-lg-12 col-12 ">
              {
         //   id="payment_iframe" // 
                <iframe
                id="payment_iframe"
                style={{ width: "100%", height: "24vh" }}
                src={`https://www.eilago.com/tapPay.html?BookingRef=${BookingRef}&tap=${tapPublicKey}`}
                className="iframe_styling"
                ></iframe> 
               
               
              //  tapPayment === true && eradPayment === true ? (
                //   <div>
              //     <iframe
              //       id="payment_iframe"
              //       style={{ width: "100%", height: "90%" }}
              //       src={`https://app.erad.co/eradpay?am=${totalCharges}&cu=${currency}&t=${eradPayDetails.token}&fn=John%20Doe&em=${customerEmail}&ph_c=ae&ph_c_d=971&ph_n=555555555&pr_n=flight&pa_id=${bookingRefNo}&w_url=${eradPayDetails.copyURL}&r_url=${eradPayDetails.redirectURL}&m=${eradPayDetails.mode}&pl=e&tp=custom&auth_cm=capture&is_ao=0&auth_t=168&card_form_only=1`}
              //       className="iframe_styling"
              //     ></iframe>
              //   </div>
              // ) : (
              //   allPassengers && (
              //     <iframe
              //       id="payment_iframe"
              //       style={{ width: "100%", height: "40vh" }}
              //       src={`https://app.erad.co/eradpay?am=${totalCharges}&cu=${currency}&t=${eradPayDetails.token}&fn=${allPassengers[0].givenName}%20${allPassengers[0].surName}&em=${customerEmail}&ph_c=ae&ph_c_d=971&ph_n=555555555&pr_n=flight&pa_id=${bookingRefNo}&w_url=${eradPayDetails.copyURL}&r_url=${eradPayDetails.redirectURL}&m=${eradPayDetails.mode}&pl=e&tp=custom&auth_cm=capture&is_ao=0&auth_t=168&card_form_only=1`}
              //       className="iframe_styling"
              //     ></iframe>
              //   )
              // )
              }

              {/* <div className="container">
        <main role="main" className="pb-3">
                     <form id="form-container" method="post" action="/charge">


                <div id="element-container"></div>
                <div id="error-handler" role="alert"></div>
                <div id="success" style={{display: "none;"}}>
                    Success! 
                    <span id="token" style={{display: "none;"}}></span>
                </div>

                <button id="tap-btn">Make Payment</button>
            </form>
            <input type="text" id="count" hidden value="0" />
           

        </main>
    </div> */}
            </div>

            {/* <div className="col-sm-6 col-md-6 col-lg-6 bg-grey form-border dps-none">
              <div className="row column-gap">
                <div className="col-sm-1 col-md-1 col-lg-1">
                  <img src={SecurePayment} className="light" alt="secure-payment-light" />
                  <img src={SecurePaymentDark} className="dark" alt="secure-payment-dark" />
                </div>

                <div className="col-sm-10 col-md-10 col-lg-10">
                  <p className="font-18 font-bold">{t('100% Secure')}</p>
                  <p className="font-11">{t('100% Secure Description')}.</p>
                </div>
              </div>
              <hr className="mt-3 hr-color" />

              <div className="row mt-3 column-gap">
                <div className="col-sm-1 col-md-1 col-lg-1">
                  <img src={TrustedPayment} className="light" alt="trusted-payment-light" />
                  <img src={TrustedPaymentDark} className="dark" alt="trusted-payment-dark" />
                </div>

                <div className="col-sm-10 col-md-10 col-lg-10">
                  <p className="font-18 font-bold">{t('Trusted worldwide')} </p>
                  <p className="font-11">
                    {t('Trusted worldwide Description')}.
                  </p>
                </div>
              </div>
              <hr className="mt-3 hr-color" />

              <div className="row mt-3 column-gap">
                <div className="col-sm-1 col-md-1 col-lg-1">
                  <img src={EasyPayment} className="light" alt="easy-payment-light" />
                  <img src={EasyPaymentDark} className="dark" alt="easy-payment-dark" />
                </div>

                <div className="col-sm-10 col-md-10 col-lg-10">
                  <p className="font-18 font-bold">{t('Easy Payment')} </p>
                  <p className="font-11">
                    {t('Easy Payment Description1')}:
                  </p>

                  <img src={CreditCard} className="mt-3 cc-img-height light" alt="credit-card-light" />
                  <img
                    src={CreditCardDark}
                    className="mt-3 cc-img-height dark"
                    alt="credit-card-dark"
                  />
                  <p className="font-11 mt-3">
                  {t('Easy Payment Description2')}
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          <div className="row pt-4  dls-none">
            <div className="col-2">
              <img src={TrustedGreen} alt="trusted-green" />
            </div>

            <div className="col-10">
              <p className="text-green font-16 font-bold">
                {t("Trusted worldwide")}
              </p>
              <p className="font-13">{t("Trusted worldwide Description")}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
