import Slider from "rc-slider";
import { useGlobals } from "../../contexts/globalContexts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

function PriceRange() {
  const { searchResults, priceFilter, setPriceFilter, lang, locale } =
    useGlobals();

  const [config, setConfig] = useState({
    min: 0,
    max: 0,
  });

  const [priceHistogram, setPriceHistogram] = useState([]);

  const [sliderChanged, setSliderChanged] = useState(false); 

  const onSliderChange = (value) => {
    setPriceFilter([value[0], value[1]]);
    setSliderChanged(true);
  };

  useEffect(() => {
    if(searchResults.length < 1){
      setSliderChanged(false);
    }
  }, [searchResults]);

  const getMinMaxPrices = () => {
    let pricesArray = [];
    for (let i = 0; i < searchResults.length; i++) {
      pricesArray.push(searchResults[i].price);
    }
    setConfig({ min: Math.min(...pricesArray), max: Math.max(...pricesArray) }); // this will set initial values
    setPriceFilter([Math.min(...pricesArray), Math.max(...pricesArray)]); // this will set the range for filters
  };

  const getPriceGraph = () => {
    let pricesGraphArray = [];
    if (searchResults.length !== 0) {
      for (let i = 0; i < searchResults.length; i++) {
        pricesGraphArray.push({
          itinerary: searchResults[i].Itineraries.length,
          price: searchResults[i].price,
        });
      }
      setPriceHistogram(pricesGraphArray);
    }
  };

  useEffect(() => {
    getMinMaxPrices();
    getPriceGraph();
  }, [searchResults]);

  const { t, i18n, ready } = useTranslation();

  const resetFunction = () => {
    let pricesArray = [];
    for (let i = 0; i < searchResults.length; i++) {
      pricesArray.push(searchResults[i].price);
    }
    setConfig({ min: Math.min(...pricesArray), max: Math.max(...pricesArray) }); // this will set initial values
    setPriceFilter([Math.min(...pricesArray), Math.max(...pricesArray)]); 
    setSliderChanged(false);
    document.getElementsByClassName('rc-slider-handle-1')[0].style.left = "0%";
    document.getElementsByClassName('rc-slider-handle-2')[0].style.left = "100%";
    document.getElementsByClassName('rc-slider-track-1')[0].style.left = "0%";
    document.getElementsByClassName('rc-slider-track-1')[0].style.width = "100%";
  }


  return (
    <div
      className={
        searchResults.length !== 0 ? "filter_section slider" : "skeleton"
      }
    >
      {locale === "en" &&
      <div className="row">
        <div className="col-lg-9 col-9">
          <strong className={searchResults?.length > 0 ? "" : "skeleton"}>
          {t("Price")}
          </strong>
        </div>

        {sliderChanged ?
          <div className="col-lg-3 text-right reset col-3">
            <strong onClick={() => resetFunction()}>{t("Reset")}</strong>
          </div>
          :
          null
} 
      
      </div>
}

{locale === "ar" && 
          <div className="row" 
          style={{marginTop:"130px"}}
          >
        <div className="col-lg-3 col-3 ">
          <span className={searchResults?.length > 0 ? "" : "skeleton"}
          style={{marginRight:"-130px"}}>
          {t("Price")}
          </span>
        </div>

        {sliderChanged ?
          <div className="col-lg-9 text-right-arabic reset col-9">
            <span onClick={() => resetFunction()}>{t("Reset")}</span>
          </div>
          :
          null
} 
      
      </div>
}

      
      {searchResults.length !== 0 ? (
        <>
          <div className="price_graph">
            <ul>
              {priceHistogram.length !== 0
                ? priceHistogram.map((priceHisto, index) => {
                    return (
                      <li
                        key={index}
                        style={{
                          width: `calc(100% / ${priceHistogram.length})`,
                          height: `calc(${priceHisto.itinerary} * 10%)`,
                        }}
                        title={`${priceHisto?.itinerary} itineraries available for ${searchResults[0]?.priceInformation?.baseFareCurrency} ${priceHisto?.price}.`}
                      />
                    );
                  })
                : null}
            </ul>
          </div>

          <Slider
            range
            defaultValue={[config.min, config.max]}
            min={config.min}
            max={config.max}
            allowCross={false}
            onChange={(value) => {
              onSliderChange(value);
            }}
          />

          <div className="price_values">
            <div className="min_price">
              {searchResults[0].priceInformation.baseFareCurrency}{" "}
              {priceFilter[0]}
            </div>
            <div className="max_price">
              {searchResults[0].priceInformation.baseFareCurrency}{" "}
              {priceFilter[1]}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default PriceRange;
